import { AxiosPromise } from 'axios';
import request from '../network'
import {  publicAccountRecordList, publicAccountRecordListVO } from './types';



// 公众号记录查询列表
export function getOfficialAccount(query: publicAccountRecordList): AxiosPromise<publicAccountRecordListVO[]> {
  return request({
      url: '/system/officialAccountMessage/getPage',
      method: 'GET',
      params: query,
  })
}

export default {}