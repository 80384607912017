import React, { useEffect, useMemo, useState } from "react";
import style from "./index.module.scss";
import { Chart } from "@antv/g2";

import {
  Button,
  Form,
  Tooltip,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  Divider,
  Empty,
} from "antd";
import { QuestionCircleOutlined, FilterOutlined } from "@ant-design/icons";

import {
  crmTipsMap,
  iconMap,
  permissionMap,
} from "../../assets/public/pageMap";
import { ChannelIcon, TextWithQuestion } from "../MiniStyles";

import dayjs from "dayjs";
import {
  useGetChannelList,
  useGetShoplList,
  useGetMemberCard,
} from "../../assets/public/dataBoard";
import checkPermission from "@/crm/assets/public/checkPermission";
import * as echarts from "echarts";

const { RangePicker } = DatePicker;

// 渠道图标+渠道名称
export const ChannelIconName = ({
  channelType,
  channelName,
}: {
  channelType: string;
  channelName: string;
}) => {
  return (
    <div>
      <ChannelIcon
        channelType={channelType}
        style={{
          width: "16px",
          height: "16px",
          transform: "translateY(-2px)",
        }}
      />
      <span style={{ marginLeft: "2px" }}>{channelName}</span>
    </div>
  );
};

// 左侧标题，右侧时间
export const TimeTitle = ({
  title,
  tips,
  noTime = false,
  onChange,
}: {
  title: string;
  tips?: string;
  noTime?: boolean;
  onChange?: () => void;
}) => {
  return (
    <div className={style["dataBoard_timeTitle"]}>
      <div>
        {title}
        {tips && (
          <Tooltip
            title={<span style={{ whiteSpace: "pre-wrap" }}>{tips}</span>}>
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                ...style,
              }}
            />
          </Tooltip>
        )}
      </div>

      {!noTime && (
        <div className={style["section_right"]}>
          数据更新时间：
          <span style={{ color: "var(--text-font-color4)" }}>
            {dayjs().add(-1, "day").format("YYYY-MM-DD")}
          </span>
        </div>
      )}
    </div>
  );
};

// 标题，左侧标题右侧展开按钮
export const TitleWithExpansion = ({
  title = "",
  search = false,
  children,
  onChange,
  onBtnChange,
}: {
  title: string;
  search?: boolean;
  children?: any;
  onChange: () => void;
  onBtnChange?: () => void;
}) => {
  return (
    <div className={style["dataBoard_titleWithExpansion"]}>
      <div className={style["MiniStyle_TitleWithExpansion"]}>
        <div className={style["title"]}>{title}</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <FilterOutlined className={style["icon"]} onClick={onChange} />
          {search && (
            <>
              <Divider type="vertical" style={{ margin: "3px 16px 0" }} />
              <div className={style["section_right"]}>
                数据更新时间：{dayjs().add(-1, "day").format("YYYY-MM-DD")}
              </div>
            </>
          )}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

// 左侧标题，右侧导出按钮
export const ExportTitle = ({
  title,
  tips,
  permission,
  handExport,
}: {
  title: string;
  tips?: string;
  permission?: string;
  handExport?: () => void;
}) => {
  return (
    <div className={style["dataBoard_timeTitle"]}>
      <div>
        {title}
        {tips && (
          <Tooltip
            title={<span style={{ whiteSpace: "pre-wrap" }}>{tips}</span>}>
            <QuestionCircleOutlined
              style={{
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font5)",
                marginLeft: "6px",
                ...style,
              }}
            />
          </Tooltip>
        )}
      </div>
      {permission && checkPermission(permissionMap.get(permission || "")) && (
        <Button onClick={handExport}>导出</Button>
      )}
    </div>
  );
};

// 图标map
export const IconMap = ({
  type = "",
  style = {},
}: {
  type: string;
  style?: any;
}) => {
  return (
    <img
      src={iconMap.get(type)}
      alt=""
      style={{
        width: "20px",
        height: "20px",
        transform: "translateY(0)",
        ...style,
      }}
    />
  );
};

// 表格数字加千分符
export const TableNumber = ({
  value,
  rate = false,
  precision = 0,
}: {
  value: any;
  rate?: boolean;
  precision?: number;
}) => {
  const num = value ? Number(value) : 0;
  if (rate) {
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        {`${Number(num).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}%`}
      </span>
    );
  } else {
    return (
      <span style={{ whiteSpace: "nowrap" }}>
        {precision === 0
          ? num.toLocaleString()
          : Number(num).toLocaleString("en-US", {
              minimumFractionDigits: precision,
              maximumFractionDigits: precision,
            })}
      </span>
    );
  }
};

// 饼图 echarts
export const PieChart2 = ({
  chartId,
  options = [],
  height = "340px",
  minHeight = "0",
}: {
  chartId: string;
  options: any[];
  height?: string;
  minHeight?: string;
}) => {
  const status =
    options?.length > 0 && options?.filter((e: any) => !!e.num).length > 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFirst, setIsFirst] = useState(true);

  const option = useMemo(
    () => ({
      legend: {
        top: "0",
        left: "0",
        icon: "circle",
      },
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          return `${params?.name}<br/>${params?.marker || ""} ${Number(
            params?.value || 0
          ).toLocaleString()}（${
            Number(params?.data?.rate)
              ? `${Number(params?.data?.rate).toFixed(2)}%`
              : "--"
          }）`;
        },
      },
      color: ["#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16", "#E86452"],
      series: [
        {
          type: "pie",
          radius: "65%",
          data: [...options],
          label: {
            normal: {
              show: true,
              formatter(params: any) {
                return `${params.data.name}\n${Number(
                  params.data?.value || 0
                ).toLocaleString()}`;
              },
              lineHeight: 18,
            },
          },
          labelLine: {
            length: 18,
            length2: 18,
            show: true,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options)]
  );

  useEffect(() => {
    const lineElement = document.getElementById(chartId);
    const myChart = echarts.init(lineElement, "walden", {
      devicePixelRatio: 2,
    }); //1：GMV2：购买人数3：订单数4：客单价5：笔单价
    myChart.clear();
    myChart.setOption(option);
    if (isFirst) {
      resetLine(myChart);
    }
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      setIsFirst(false);
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        setIsFirst(false);
        myChart.resize();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(option), windowWidth]);

  // 重置折线图
  const resetLine = (e: any) => {
    setTimeout(() => {
      e.resize();
    }, 500);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        id={chartId}
        style={{
          width: "100%",
          height,
          minHeight,
          opacity: `${status ? "1" : "0"}`,
        }}
      />
      {!status && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={style["levelChart_empty"]}
        />
      )}
    </div>
  );
};

// 环形图 echarts
export const RingChart2 = ({
  chartId,
  options = [],
  height = "340px",
  minHeight = "0",
}: {
  chartId: string;
  options: any;
  height?: string;
  minHeight?: string;
}) => {
  const status =
    options.list.length > 0 &&
    options.list.filter((e: any) => !!e.rate && Number(e.rate) > 0).length > 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFirst, setIsFirst] = useState(true);

  const option = useMemo(
    () => {
      const list = options?.list?.map((e: any) => ({
        ...e,
        value: Number(options.type === "GMV" ? e.num : e.count) || 0,
        name: e.platformType,
      }));

      return {
        title: {
          show: true,
          text:
            options.type === "GMV"
              ? Number(options.num || 0).toLocaleString()
              : Number(options.count || 0).toLocaleString(), //要显示的文本
          left: "center",
          top: "48%",
          textStyle: {
            color: "#474747",
            fontWeight: "bold",
            fontSize: "20px",
            width: 130,
            overflow: "breakAll",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: (params: any) => {
            if (chartId.split("_chart")[0] === "customerTransaction") {
              return `<div style="display:flex"><div>${
                params?.marker || ""
              }</div><div>${params?.name}：${Number(
                params?.value || 0
              ).toLocaleString()}<br/>占比：${
                params.data.rate || 0
              }%<br/>新客：${Number(
                options.list[params?.dataIndex]?.newCustomer || 0
              ).toLocaleString()}<br/>老客：${Number(
                options.list[params?.dataIndex]?.oldCustomer || 0
              ).toLocaleString()}<br/>会员：${Number(
                options.list[params?.dataIndex]?.member || 0
              ).toLocaleString()}<br/>非会员：${Number(
                options.list[params?.dataIndex]?.nonMember || 0
              ).toLocaleString()}`;
            } else {
              return `${params?.marker || ""} ${params?.name}：${Number(
                params?.value || 0
              ).toLocaleString()}<br/>占比：${
                params.data.rate || 0
              }%<br/>会员新客：${Number(
                options.list[params?.dataIndex]?.newMember || 0
              ).toLocaleString()}<br/>会员老客：${Number(
                options.list[params?.dataIndex]?.oldMember || 0
              ).toLocaleString()}</div></div>`;
            }
          },
        },
        legend: {
          top: "0",
          left: "0",
          icon: "circle",
        },
        color: ["#3BA0FF", "#36CBCB", "#4DCB73", "#A0D911", "#FADB14"],
        series: [
          {
            type: "pie",
            radius: ["40%", "65%"],
            data: [...list],
            label: {
              normal: {
                show: true,
                formatter(params: any) {
                  return `${params.data.name}\n${Number(
                    params.data?.value || 0
                  ).toLocaleString()}`;
                },
                lineHeight: 18,
                // overflow: "breakAll",
              },
            },
            labelLine: {
              length: 4,
              length2: 4,
              show: true,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options)]
  );

  useEffect(() => {
    const lineElement = document.getElementById(chartId);
    const myChart = echarts.init(lineElement, "walden", {
      devicePixelRatio: 2,
    }); //1：GMV2：购买人数3：订单数4：客单价5：笔单价
    myChart.clear();
    myChart.setOption(option);
    if (isFirst) {
      resetLine(myChart);
    }
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      setIsFirst(false);
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        setIsFirst(false);
        myChart.resize();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(option), windowWidth]);

  // 重置折线图
  const resetLine = (e: any) => {
    setTimeout(() => {
      e.resize();
    }, 500);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        id={chartId}
        style={{
          width: "100%",
          height,
          minHeight,
          opacity: `${status ? "1" : "0"}`,
        }}
      />
      {status && (
        <div className={style["dataBoard_pie-title"]}>
          <TextWithQuestion
            black
            title={
              <div className={style["dataBoard_pie-title--font"]}>
                {options?.type || ""}
              </div>
            }
            tips={crmTipsMap.get(
              `${
                chartId.split("_chart")[0] === "customerTransaction"
                  ? `客户交易分析_${options?.type}`
                  : `会员交易分析_${options?.type}`
              }`
            )}
            style={{ color: "var(--text-font-color18)" }}
          />
        </div>
      )}
      {!status && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={style["levelChart_empty"]}
        />
      )}
    </div>
  );
};

//柱形图+折线图 echarts
export const LineBarChart2 = ({
  chartId,
  options = [],
  height = "340px",
  minHeight = "0",
}: {
  chartId: string;
  options: any[];
  height?: string;
  minHeight?: string;
}) => {
  const status = options?.length > 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFirst, setIsFirst] = useState(true);

  const option = useMemo(
    () => {
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          formatter: (params: any) => {
            return `${params[0].axisValue}<br/>
            ${
              params[0]?.marker
                ? `<div style="display:flex;align-items:center">${
                    params[0]?.marker || ""
                  } ${
                    params[0]?.seriesName
                  }：<div style="min-width:100px">${Number(
                    params[0]?.value || 0
                  ).toLocaleString()}</div>同比：${
                    Number(options[params[0]?.dataIndex]?.barRate1)
                      ? `${Number(
                          options[params[0]?.dataIndex]?.barRate1
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}%`
                      : "--"
                  } </div>`
                : ""
            }
            ${
              params[1]?.marker
                ? `<div style="display:flex;align-items:center">${
                    params[1]?.marker || ""
                  } ${
                    params[1]?.seriesName
                  }：<div style="min-width:100px">${Number(
                    params[1]?.value || 0
                  ).toLocaleString()}</div>同比：${
                    Number(options[params[1]?.dataIndex]?.rate1)
                      ? `${Number(
                          Number(options[params[1]?.dataIndex]?.rate1).toFixed(
                            2
                          )
                        ).toLocaleString()}%`
                      : "--"
                  }</div>`
                : ""
            }
           ${
             params[2]?.marker
               ? `<div style="display:flex;align-items:center">${
                   params[2]?.marker || ""
                 } ${
                   params[2]?.seriesName
                 }：<div style="min-width:100px">${Number(
                   params[2]?.value || 0
                 ).toLocaleString()}</div>同比：${
                   Number(options[params[2]?.dataIndex]?.rate2)
                     ? `${Number(
                         Number(options[params[2]?.dataIndex]?.rate2).toFixed(2)
                       ).toLocaleString()}%`
                     : "--"
                 }</div>`
               : ""
           }
          `;
          },
        },
        grid: {
          x: 80,
          y: 35,
          x2: 80,
          y2: 35,
        },
        legend: {
          data: ["销售额", "笔单价", "件单价"],
        },
        xAxis: [
          {
            data: options?.map((e) => e?.time || "") || [],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "销售额",
            alignTicks: true,
            axisLabel: {
              formatter: "{value}",
            },
          },
          {
            type: "value",
            name: "价格",
            alignTicks: true,
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "销售额",
            type: "bar",
            barMaxWidth: 40,
            stack: "a",
            itemStyle: { color: "#5B8FF9" },
            data: options?.map((e) => e?.bar1 || 0) || [],
          },
          {
            name: "笔单价",
            type: "line",
            yAxisIndex: 1,
            itemStyle: { color: "#5AD8A6" },
            data: options?.map((e) => e?.rateNum1 || 0) || [],
          },
          {
            name: "件单价",
            type: "line",
            yAxisIndex: 1,
            itemStyle: { color: "#F6BD16" },
            data: options?.map((e) => e?.rateNum2 || 0) || [],
          },
        ],
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options)]
  );

  useEffect(() => {
    const lineElement = document.getElementById(chartId);
    const myChart = echarts.init(lineElement, "walden", {
      devicePixelRatio: 2,
    }); //1：GMV2：购买人数3：订单数4：客单价5：笔单价
    // echarts.init(document.getElementById(item.id), 'walden',{devicePixelRatio: 2})
    myChart.clear();
    myChart.setOption(option);
    if (isFirst) {
      resetLine(myChart);
    }
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      setIsFirst(false);
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        setIsFirst(false);
        myChart.resize();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(option), windowWidth]);

  // 重置折线图
  const resetLine = (e: any) => {
    setTimeout(() => {
      e.resize();
    }, 500);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        id={chartId}
        style={{
          width: "100%",
          height,
          minHeight,
          opacity: `${status ? "1" : "0"}`,
        }}
      />
      {!status && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={style["levelChart_empty"]}
        />
      )}
    </div>
  );
};

// RFM柱形图+折线图
export const RfmLineBarChart = ({
  chartId,
  options,
}: {
  chartId: string;
  options: any;
}) => {
  useEffect(() => {
    handSetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  const handSetChart = () => {
    const chart = new Chart({
      container: chartId,
      autoFit: true,
      height: 290,
    });

    chart.data(options);

    chart
      .interval()
      .encode("x", "rfmDate")
      .encode("y", "numberOfCustomers")
      .encode("color", () => "客户数")
      .axis("y", { title: "" })
      .axis("x", { title: "" })
      .style("maxWidth", 50);

    chart
      .line()
      .encode("shape", "smooth")
      .encode("x", "rfmDate")
      .encode("y", "percentageOfCustomers")
      .encode("color", () => "客户占比")
      .axis("y", {
        title: "",
        position: "right",
        labelFormatter: (d: any) => (d * 100).toFixed(1) + "%",
      })
      .scale("y", { key: "2", nice: true })
      .scale("series", { independent: true })
      .tooltip((data) => ({
        value: data.percentageOfCustomers
          ? `${(data.percentageOfCustomers * 100).toFixed(1)}%`
          : 0,
      }));
    chart
      .point()
      .encode("x", "rfmDate")
      .encode("y", "percentageOfCustomers")
      .encode("shape", "point")
      .encode("color", "#5AD8A6")
      .scale("y", { key: "2", nice: true })
      .encode("color", () => "客户占比")
      .tooltip(false);
    chart.render();
    // 解决第一次渲染，图标宽度过款问题
    const e = document.createEvent("Event");
    e.initEvent("resize", true, true);
    window.dispatchEvent(e);
  };

  return <div id={chartId} />;
};

// 双堆叠柱形图+双折线图 echarts
export const DoubleLineDoubleBarChart3 = ({
  chartId,
  options = [],
  height = "340px",
  minHeight = "0",
}: {
  chartId: string;
  options: any[];
  height?: string;
  minHeight?: string;
}) => {
  const status = options?.length > 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFirst, setIsFirst] = useState(true);

  const option = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
        formatter: (params: any) => {
          return `${params[0].axisValue}<br/>
          ${
            params[0]?.marker
              ? `${params[0]?.marker || ""} ${params[0].seriesName}：${Number(
                  params[0].value || 0
                ).toLocaleString()}`
              : ""
          }<br/>
          ${
            params[2]?.marker
              ? `${params[2]?.marker || ""} ${params[2].seriesName}：${
                  Number(params[2].value)
                    ? `${Number(params[2].value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}%`
                    : "--"
                }`
              : ""
          }<br/>
          ${
            params[1]?.marker
              ? `${params[1]?.marker || ""} ${params[1].seriesName}：${Number(
                  params[1].value || 0
                ).toLocaleString()}`
              : ""
          }<br/>
          ${
            params[3]?.marker
              ? `${params[3]?.marker || ""} ${params[3].seriesName}：${
                  Number(params[3].value)
                    ? `${Number(params[3].value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}%`
                    : "--"
                }`
              : ""
          }<br/>`;
        },
      },
      grid: {
        x: 80,
        y: 35,
        x2: 80,
        y2: 35,
      },
      legend: {
        data: ["新会首购", "新会未购", "新会首购同比", "新会未购同比"],
      },
      xAxis: [
        {
          data: options?.map((e) => e?.time || "") || [],
          axisPointer: {
            type: "shadow",
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "会员数",
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          name: "同比",
          alignTicks: true,
          axisLabel: {
            // formatter: "{value}%",
            formatter: (value: number) => `${`${value?.toFixed(2)}%` || "--"}`,
          },
        },
      ],
      series: [
        {
          name: "新会首购",
          type: "bar",
          barMaxWidth: 40,
          stack: "a",
          itemStyle: { color: "#5B8FF9" },
          data: options?.map((e) => e?.bar1 || 0) || [],
        },
        {
          name: "新会未购",
          type: "bar",
          barMaxWidth: 40,
          stack: "a",
          itemStyle: { color: "#6ADCAF" },
          data: options?.map((e) => e?.bar2 || 0) || [],
        },
        {
          name: "新会首购同比",
          type: "line",
          yAxisIndex: 1,
          itemStyle: { color: "#5B8FF9" },
          data: options?.map((e) => e?.rate1 || 0) || [],
        },
        {
          name: "新会未购同比",
          type: "line",
          yAxisIndex: 1,
          itemStyle: { color: "#6ADCAF" },
          data: options?.map((e) => e?.rate2 || 0) || [],
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options)]
  );

  useEffect(() => {
    const lineElement = document.getElementById(chartId);
    const myChart = echarts.init(lineElement, "walden", {
      devicePixelRatio: 2,
    }); //1：GMV2：购买人数3：订单数4：客单价5：笔单价
    myChart.clear();
    myChart.setOption(option);
    if (isFirst) {
      resetLine(myChart);
    }
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      setIsFirst(false);
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        setIsFirst(false);
        myChart.resize();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(option), windowWidth]);

  // 重置折线图
  const resetLine = (e: any) => {
    setTimeout(() => {
      e.resize();
    }, 500);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        id={chartId}
        style={{
          width: "100%",
          height,
          minHeight,
          opacity: `${status ? "1" : "0"}`,
        }}
      />
      {!status && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={style["levelChart_empty"]}
        />
      )}
    </div>
  );
};

// 双堆叠柱形图+双折线图 echarts
export const DoubleLineDoubleBarChart4 = ({
  chartId,
  options = [],
  height = "340px",
  minHeight = "0",
}: {
  chartId: string;
  options: any[];
  height?: string;
  minHeight?: string;
}) => {
  const status = options?.length > 0;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isFirst, setIsFirst] = useState(true);

  const option = useMemo(
    () => ({
      tooltip: {
        formatter: (params: any) => {
          return `${params.name}<br/>
          <div style="display:flex;align-items:center">${
            params?.marker || ""
          } ${params.seriesName}：<div style="min-width:100px">${Number(
            params.value || 0
          ).toLocaleString()}</div>同比：${Number(
            options[params.dataIndex][`rate${params.componentIndex + 1}`]
          ).toLocaleString()}（${
            options[params.dataIndex][`rateNum${params.componentIndex + 1}`]
          }%） </div>`;
        },
      },
      grid: {
        x: 80,
        y: 35,
        x2: 80,
        y2: 35,
      },
      legend: {
        data: ["销售额", "已购客户数"],
      },
      xAxis: [
        {
          data: options?.map((e) => e?.time || "") || [],
          axisPointer: {
            type: "shadow",
          },
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "销售额",
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
          },
        },
        {
          type: "value",
          name: "已购客户数",
          alignTicks: true,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          name: "销售额",
          type: "bar",
          barMaxWidth: 40,
          itemStyle: { color: "#5B8FF9" },
          data: options?.map((e) => e?.bar1 || 0) || [],
        },
        {
          name: "已购客户数",
          type: "bar",
          yAxisIndex: 1,
          barMaxWidth: 40,
          itemStyle: { color: "#6ADCAF" },
          data: options?.map((e) => e?.bar2 || 0) || [],
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(options)]
  );

  useEffect(() => {
    const lineElement = document.getElementById(chartId);
    const myChart = echarts.init(lineElement, "walden", {
      devicePixelRatio: 2,
    }); //1：GMV2：购买人数3：订单数4：客单价5：笔单价
    // echarts.init(document.getElementById(item.id), 'walden',{devicePixelRatio: 2})
    myChart.clear();
    myChart.setOption(option);
    if (isFirst) {
      resetLine(myChart);
    }
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      setIsFirst(false);
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        setIsFirst(false);
        myChart.resize();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(option), windowWidth]);

  // 重置折线图
  const resetLine = (e: any) => {
    setTimeout(() => {
      e.resize();
    }, 500);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        id={chartId}
        style={{
          width: "100%",
          height,
          minHeight,
          opacity: `${status ? "1" : "0"}`,
        }}
      />
      {!status && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={style["levelChart_empty"]}
        />
      )}
    </div>
  );
};

// 金字塔图
export const LevelChart = ({
  chartId,
  options,
}: {
  chartId: string;
  options: any[];
}) => {
  useEffect(() => {
    handSetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(options)]);

  const handSetChart = () => {
    const chart = new Chart({
      container: chartId,
      autoFit: true,
      width: 350,
      height: 284,
    });

    chart.coordinate({
      transform: [{ type: "transpose" }],
    });

    chart.data({
      value: options,
    });

    chart.interaction("tooltip", false);

    chart
      .interval()
      .encode("x", "level")
      .encode("y", "width")
      .encode("color", "color")
      .encode("shape", "pyramid")
      .transform({ type: "symmetryY" })
      .scale("x", { padding: 0 })
      .scale("color", { type: "identity" })
      .animate("enter", { type: "fadeIn" })
      .legend(false)
      .axis(false);

    chart.render();
    // 解决第一次渲染，图标宽度过款问题
    const e = document.createEvent("Event");
    e.initEvent("resize", true, true);
    window.dispatchEvent(e);
  };

  return (
    <div className={style["dataBoard_level"]}>
      <div
        id={chartId}
        style={{ transform: "rotate(180deg)", width: "350px" }}
      />
      <div
        className={style["content"]}
        style={{ opacity: `${options.length > 0 ? "1" : "0"}` }}>
        {options.map((item, index) => (
          <div
            key={item.width}
            className={style[`bgColor${index > 2 ? "Blue" : "White"}`]}
            style={{ height: `${252 / options.length}px` }}>
            <div style={{ position: "relative" }}>
              {item.level}
              <div
                className={style["tooltips"]}
                style={{
                  left: `${
                    160 -
                    (153 * (options.length - index)) / options.length +
                    80 / options.length +
                    15
                  }px`,
                }}>
                <div className={style["solid"]} />
                <div>{Number(item.num).toLocaleString()}</div>
                <div className={style["rate"]}>{item.rate}%</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {options.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={style["levelChart_empty"]}
        />
      )}
    </div>
  );
};

// 传入数组返回对应字符串逗号分隔
const getArrString = (list: string[], disabledText: string) => {
  const key: string =
    list.length > 1
      ? list.filter((e: string) => e !== disabledText).join(",") || ""
      : list.join(",");
  const channelKey =
    list.includes(disabledText) &&
    list.findIndex((e: string) => e === disabledText) !== 0
      ? disabledText
      : key;
  return channelKey;
};

// 表格日月年-渠道-店铺搜索部分
export const TableSearch = ({
  onFinish,
  type,
  disabledNum = 1,
  background = "#f5f5f5",
}: {
  onFinish: (e: any) => void;
  disabledNum?: number;
  type: "member" | "customer";
  background?: string;
}) => {
  const options = [
    { label: "日", value: "day" },
    { label: "月", value: "month" },
    { label: "年", value: "year" },
  ];
  const defaultSearchInfo = {
    dateType: "day",
    platformType: "all",
    shopId: "0",
    startDate: "",
    endDate: "",
  };
  const [form] = Form.useForm();
  const [searchInfo, setSearchInfo] = useState<any>(defaultSearchInfo);
  const { channelAllList } = useGetChannelList(type);
  const { shopAllList, getShopList } = useGetShoplList(type);

  useEffect(() => {
    onFinish({
      ...defaultSearchInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 禁用日期范围
  const disabledDate = (current: any, e: any) => {
    // 日
    if (searchInfo.dateType === "day") {
      const yestoday = dayjs().add(-1, "day");
      return (
        (current && current.isAfter(yestoday)) ||
        (e.from && Math.abs(current.diff(e.from, "days")) > 45)
      );
    }
    // 月
    if (searchInfo.dateType === "month") {
      let num = -1 + disabledNum;
      if (dayjs().date() === 1) {
        num -= 1;
      }
      const lastMonth = dayjs().add(num, "month");
      return (
        (current && current.isAfter(lastMonth)) ||
        (e.from && Math.abs(current.diff(e.from, "month")) >= 11)
      );
    }
    // 年
    if (searchInfo.dateType === "year") {
      let num = -1 + disabledNum;
      if (dayjs().date() === 1) {
        num -= 1;
      }
      const lastYear = dayjs().add(num, "year");
      return (
        (current && current.isAfter(lastYear)) ||
        (e.from && Math.abs(current.diff(e.from, "year")) >= 11)
      );
    }
  };

  return (
    <div className={style["dataBoard_tableSearch"]} style={{ background }}>
      <Form
        form={form}
        name="advanced_search"
        style={{ width: "100%" }}
        onFinish={onFinish}>
        <Row gutter={[32, 0]}>
          <Col span={8} style={{ display: "flex", gap: "8px" }}>
            <Form.Item
              label="时间"
              style={{ marginBottom: "16px", flex: "0 1 180px" }}>
              <Radio.Group
                options={options}
                optionType="button"
                value={searchInfo.dateType}
                onChange={(e) =>
                  setSearchInfo({
                    ...searchInfo,
                    dateType: e.target.value,
                    startDate: "",
                    endDate: "",
                  })
                }
                style={{ whiteSpace: "nowrap" }}
              />
            </Form.Item>
            <Form.Item label="" style={{ marginBottom: "16px", flex: "1 1 0" }}>
              <RangePicker
                style={{ width: "100%" }}
                picker={
                  searchInfo.dateType === "day" ? "date" : searchInfo.dateType
                }
                disabledDate={disabledDate}
                value={[
                  searchInfo.startDate ? dayjs(searchInfo.startDate) : null,
                  searchInfo.endDate ? dayjs(searchInfo.endDate) : null,
                ]}
                onChange={(e) => {
                  setSearchInfo({
                    ...searchInfo,
                    startDate:
                      e && e[0] ? dayjs(e[0]).format("YYYY-MM-DD") : "",
                    endDate: e && e[1] ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="渠道" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={channelAllList}
                value={
                  channelAllList.length > 0
                    ? searchInfo.platformType
                      ? searchInfo.platformType.split(",")
                      : []
                    : "全部平台"
                }
                onChange={(platformType) => {
                  const channelKey = getArrString(platformType, "all");
                  getShopList(null, channelKey || "all");
                  setSearchInfo({
                    ...searchInfo,
                    platformType: channelKey,
                    shopId: "0",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="店铺" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={shopAllList}
                value={
                  shopAllList.length > 0
                    ? searchInfo.shopId
                      ? searchInfo.shopId.split(",")
                      : []
                    : "全部店铺"
                }
                onChange={(shopId) => {
                  const shopKey = getArrString(shopId, "0");
                  setSearchInfo({ ...searchInfo, shopId: shopKey });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                getShopList(null, "all");
                setSearchInfo(defaultSearchInfo);
              }}>
              重置
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "8px" }}
              onClick={() =>
                onFinish({
                  ...searchInfo,
                  platformType: searchInfo.platformType.toLowerCase(),
                })
              }>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

// 表格自然月自定义-渠道-店铺搜索部分
// channelAllStatus为true渠道列表是所有的，为false只有全部平台
export const TableSearch2 = ({
  onFinish,
  type,
  background = "#f5f5f5",
  channelAllStatus = true,
}: {
  onFinish: (e: any) => void;
  channelAllStatus?: boolean;
  background?: string;
  type: "member" | "customer";
}) => {
  const options = [
    { label: "自然月", value: "month" },
    { label: "自定义", value: "day" },
  ];
  const defaultMonth =
    dayjs().date() === 1
      ? dayjs().add(-1, "month").format("YYYY-MM")
      : dayjs().format("YYYY-MM");
  const defaultStartDate =
    dayjs().date() === 1
      ? dayjs().add(-1, "month").startOf("month").format("YYYY-MM-DD")
      : dayjs().startOf("month").format("YYYY-MM-DD");
  const defaultEndDate =
    dayjs().date() === 1
      ? dayjs().add(-1, "month").endOf("month").format("YYYY-MM-DD")
      : dayjs().add(-1, "day").isBefore(dayjs().endOf("month"))
      ? dayjs().add(-1, "day").format("YYYY-MM-DD")
      : dayjs().endOf("month").format("YYYY-MM-DD");

  const defaultSearchInfo = {
    dateType: "month",
    platformType: "all",
    shopId: "0",
    startDate: "",
    endDate: "",
    month: defaultMonth,
  };
  const [form] = Form.useForm();
  const [searchInfo, setSearchInfo] = useState<any>(defaultSearchInfo);
  const { channelAllList } = useGetChannelList(type);
  const { shopAllList, getShopList } = useGetShoplList(type);

  useEffect(() => {
    onFinish({
      ...defaultSearchInfo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 禁用日期范围
  const disabledDate = (current: any, e: any) => {
    const yestoday = dayjs().add(-1, "day");
    return (
      (current && current.isAfter(yestoday)) ||
      (e.from && Math.abs(current.diff(e.from, "days")) >= 45)
    );
  };

  return (
    <div className={style["dataBoard_tableSearch"]} style={{ background }}>
      <Form
        form={form}
        name="advanced_search"
        style={{ width: "100%" }}
        onFinish={onFinish}>
        <Row gutter={[32, 0]}>
          <Col span={8} style={{ display: "flex", gap: "8px" }}>
            <Form.Item
              label="时间"
              style={{ marginBottom: "16px", flex: "0 1 200px" }}>
              <Radio.Group
                options={options}
                optionType="button"
                value={searchInfo.dateType}
                onChange={(e) =>
                  setSearchInfo({
                    ...searchInfo,
                    dateType: e.target.value,
                    startDate: e.target.value === "day" ? defaultStartDate : "",
                    endDate: e.target.value === "day" ? defaultEndDate : "",
                    month: e.target.value === "month" ? defaultMonth : "",
                  })
                }
                style={{ whiteSpace: "nowrap" }}
              />
            </Form.Item>
            <Form.Item label="" style={{ marginBottom: "16px", flex: "1 1 0" }}>
              {searchInfo.dateType === "month" ? (
                <DatePicker
                  style={{ width: "100%" }}
                  picker="month"
                  disabledDate={disabledDate}
                  allowClear={false}
                  value={searchInfo.month ? dayjs(searchInfo.month) : null}
                  format="YYYY-MM"
                  onChange={(e) => {
                    setSearchInfo({
                      ...searchInfo,
                      month: dayjs(e).format("YYYY-MM"),
                    });
                  }}
                />
              ) : (
                <RangePicker
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                  allowClear={false}
                  value={[
                    searchInfo.startDate ? dayjs(searchInfo.startDate) : null,
                    searchInfo.endDate ? dayjs(searchInfo.endDate) : null,
                  ]}
                  onChange={(e) =>
                    setSearchInfo({
                      ...searchInfo,
                      startDate:
                        e && e[0] ? dayjs(e[0]).format("YYYY-MM-DD") : "",
                      endDate:
                        e && e[1] ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                    })
                  }
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="渠道" style={{ marginBottom: "16px" }}>
              <Select
                options={
                  channelAllStatus
                    ? channelAllList
                    : [{ value: "all", label: "全部平台" }]
                }
                value={
                  channelAllList.length > 0
                    ? searchInfo.platformType
                    : "全部平台"
                }
                onChange={(platformType) => {
                  getShopList(null, platformType);
                  setSearchInfo({ ...searchInfo, platformType, shopId: "0" });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="店铺" style={{ marginBottom: "16px" }}>
              <Select
                options={shopAllList}
                value={shopAllList.length > 0 ? searchInfo.shopId : "全部店铺"}
                onChange={(shopId) => setSearchInfo({ ...searchInfo, shopId })}
              />
            </Form.Item>
          </Col>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                getShopList(null, "all");
                setSearchInfo(defaultSearchInfo);
              }}>
              重置
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "8px" }}
              onClick={() =>
                onFinish({
                  ...searchInfo,
                  platformType: searchInfo.platformType.toLowerCase(),
                })
              }>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

// 表格日月年-体系-渠道-店铺搜索部分
export const TableSearch3 = ({
  onFinish,
  type,
  background = "#f5f5f5",
  disabledNum = 0,
}: {
  onFinish: (e: any) => void;
  disabledNum?: number;
  background?: string;
  type: "member" | "customer";
}) => {
  const options = [
    { label: "日", value: "day" },
    { label: "月", value: "month" },
    { label: "年", value: "year" },
  ];
  const defaultSearchInfo = {
    dateType: "day",
    memberCardId: "0",
    platformType: "all",
    shopId: "0",
    startDate: "",
    endDate: "",
  };
  const [form] = Form.useForm();
  const [searchInfo, setSearchInfo] = useState<any>(defaultSearchInfo);
  const { memberCardList } = useGetMemberCard(type);
  const { channelAllList, getChannelList } = useGetChannelList(type);
  const { shopAllList, getShopList } = useGetShoplList(type);

  useEffect(() => {
    if (memberCardList.length > 0) {
      // const id = memberCardList[0]?.value || null;
      // setSearchInfo({
      //   ...searchInfo,
      //   memberCardId: id,
      // });
      onFinish({
        ...defaultSearchInfo,
        // memberCardId: id,
      });
      handChangeOptions("0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(memberCardList)]);

  // 更改渠道和店铺options
  const handChangeOptions = (id: any) => {
    getChannelList(id);
    getShopList(id, null);
  };

  // 禁用日期范围
  const disabledDate = (current: any, e: any) => {
    // 日
    if (searchInfo.dateType === "day") {
      const yestoday = dayjs().add(-1, "day");
      return (
        (current && current.isAfter(yestoday)) ||
        (e.from && Math.abs(current.diff(e.from, "days")) > 45)
      );
    }
    // 月
    if (searchInfo.dateType === "month") {
      let num = -1 + disabledNum;
      if (dayjs().date() === 1) {
        num -= 1;
      }
      const lastMonth = dayjs().add(num, "month");
      return (
        (current && current.isAfter(lastMonth)) ||
        (e.from && Math.abs(current.diff(e.from, "month")) >= 11)
      );
    }
    // 年
    if (searchInfo.dateType === "year") {
      let num = -1 + disabledNum;
      if (dayjs().date() === 1) {
        num -= 1;
      }
      const lastYear = dayjs().add(num, "year");
      return (
        (current && current.isAfter(lastYear)) ||
        (e.from && Math.abs(current.diff(e.from, "year")) >= 11)
      );
    }
  };

  return (
    <div className={style["dataBoard_tableSearch"]} style={{ background }}>
      <Form
        form={form}
        name="advanced_search"
        style={{ width: "100%" }}
        onFinish={onFinish}>
        <Row gutter={[32, 0]}>
          <Col span={8} style={{ display: "flex", gap: "8px" }}>
            <Form.Item
              label="时间"
              style={{ marginBottom: "16px", flex: "0 1 180px" }}>
              <Radio.Group
                options={options}
                optionType="button"
                value={searchInfo.dateType}
                onChange={(e) =>
                  setSearchInfo({
                    ...searchInfo,
                    dateType: e.target.value,
                    startDate: "",
                    endDate: "",
                  })
                }
                style={{ whiteSpace: "nowrap" }}
              />
            </Form.Item>
            <Form.Item label="" style={{ marginBottom: "16px", flex: "1 1 0" }}>
              <RangePicker
                style={{ width: "100%" }}
                picker={
                  searchInfo.dateType === "day" ? "date" : searchInfo.dateType
                }
                disabledDate={disabledDate}
                value={[
                  searchInfo.startDate ? dayjs(searchInfo.startDate) : null,
                  searchInfo.endDate ? dayjs(searchInfo.endDate) : null,
                ]}
                onChange={(e) => {
                  setSearchInfo({
                    ...searchInfo,
                    startDate:
                      e && e[0] ? dayjs(e[0]).format("YYYY-MM-DD") : "",
                    endDate: e && e[1] ? dayjs(e[1]).format("YYYY-MM-DD") : "",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="会员体系" style={{ marginBottom: "16px" }}>
              <Select
                options={[
                  { value: "0", label: "全部会员卡" },
                  ...memberCardList,
                ]}
                value={searchInfo.memberCardId}
                onChange={(memberCardId) => {
                  handChangeOptions(memberCardId);
                  setSearchInfo({
                    ...searchInfo,
                    memberCardId,
                    platformType: "all",
                    shopId: "0",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="渠道" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={channelAllList}
                value={
                  channelAllList.length > 0
                    ? searchInfo.platformType
                      ? searchInfo.platformType.split(",")
                      : []
                    : "全部平台"
                }
                onChange={(platformType) => {
                  const channelKey = getArrString(platformType, "all");
                  getShopList(null, channelKey || "all");
                  setSearchInfo({
                    ...searchInfo,
                    platformType: channelKey,
                    shopId: "0",
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="店铺" style={{ marginBottom: "16px" }}>
              <Select
                mode="multiple"
                options={shopAllList}
                value={
                  shopAllList.length > 0
                    ? searchInfo.shopId
                      ? searchInfo.shopId.split(",")
                      : []
                    : "全部店铺"
                }
                onChange={(shopId) => {
                  const shopKey = getArrString(shopId, "0");
                  setSearchInfo({ ...searchInfo, shopId: shopKey });
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={16}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                getChannelList(null)
                getShopList(null, "all");
                setSearchInfo(defaultSearchInfo);
              }}>
              重置
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: "8px" }}
              onClick={() =>
                onFinish({
                  ...searchInfo,
                  platformType: searchInfo.platformType.toLowerCase(),
                })
              }>
              查询
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

// 四行合并表格展开数据处理
export const changeTableList = (list: any[] = [], rowNum = 4) => {
  // const arrItem = new Map();
  let arr: any[] = list?.map((item, index) => {
    if (index % rowNum === rowNum - 1) {
      item.children =
        list[index - rowNum + 1].children?.map((e: any, index: number) => {
          return { ...e };
        }) || null;
      // arrItem.set(index - 3, list[index - 3].children.length);
    }
    return item;
  });
  arr =
    list?.map((item, index) => {
      if (index % rowNum === 0) {
        // item.children = new Array(arrItem.get(index)).fill({});
        item.children = null;
      }
      return item;
    }) || [];

  return arr || [];
};
