//@ts-nocheck
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import ModuleTitle from "@/component/ModuleTitle";
import RFMAnalysisIcon from "@/assets/images/RFMAnalysisIcon.png";
import {
  Input,
  Select,
  DatePicker,
  Button,
  Tooltip,
  message,
  Cascader,
  Checkbox, 
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { QuestionCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { checkPermissions } from "@/utils/utils";
import { BOARD_KEY } from "@/router/Permissions";
import {
  createInsightAnalysis,
  updateInsightAnalysis,
  getInsightAnalysis,
  getPlatforms,
  getShops,
  getProductLines,
} from "@/crm/api/dataBoardApi";
import { PageItem, PageTitle } from '../../../../../component/MiniStyles';

function CreateRfmAnalysis(props: { id?: string }) {
  let location = useLocation();
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [analysisName, setAnalysisName] = useState("");
  const [dataRange, setDataRange] = useState([]);
  const [firstRange, setFirstRange] = useState([]);
  const [dataRangeCopy, setDataRangeCopy] = useState([]);
  const [firstRangeCopy, setFirstRangeCopy] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productListCopy, setProductListCopy] = useState([]);
  const [channelAndShopoptions, setChannelAndShopoptions] = useState([]);
  const [firstChannelOptions, setFirstChannelOptions] = useState([]);
  const [productLineOptions, setProductLineOptions] = useState([]);
  const [date, setDate] = useState({
    startDate: dayjs().subtract(3, "year").subtract(1, "day").startOf("day"),
    endDate: dayjs().subtract(1, "day"),
  });
  const [analysisDateOptions, setAnalysisDateOptions] = useState([
    {
      value: "0",
      label: "自定义",
    },
  ]);
  const handleSubmit = async () => {
    if (analysisName === "") {
      message.warning("请填写分析名称");
      return false;
    }
    if (dataRange.length === 0) {
      message.warning("请选择购买渠道");
      return false;
    }
    if (firstRange.length === 0) {
      message.warning("请选择首次入会渠道");
      return false;
    }
    const channelAndShop = [];
    dataRange.forEach((item) => {
      if (item.length === 1) {
        channelAndShopoptions.forEach((channelItem) => {
          if (channelItem.value === item[0]) {
            channelItem?.children?.forEach((shopItem) => {
              channelAndShop.push({
                platformType: item[0],
                shopId: shopItem.value,
              });
            });
          }
        });
      }
      if (item.length === 2) {
        channelAndShop.push({ platformType: item[0], shopId: item[1] });
      }
    });
    const channelFirst = [];
    firstRange.forEach((item) => {
      if (item.length === 1) {
        channelAndShopoptions.forEach((channelItem) => {
          if (channelItem.value === item[0]) {
            channelItem?.children?.forEach((shopItem) => {
              channelFirst.push({
                platformType: item[0],
                shopId: shopItem.value,
              });
            });
          }
        });
      }
      if (item.length === 2) {
        channelFirst.push({ platformType: item[0], shopId: item[1] });
      }
    });
    // 去重
    const uniqueChannelFirst = Array.from(new Set(channelFirst.map(JSON.stringify))).map(JSON.parse);

    const params = {
      id: location?.state?.id,
      analysisName,
      pitchOnList: [2],
      rfmReport: {
        startDate: dayjs(date.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(date.endDate).format("YYYY-MM-DD"),
        shopList: channelAndShop,
        firstJoinShopList: uniqueChannelFirst,
        productList,
      },
    };
    if (location?.state?.id) {
      await updateInsightAnalysis(params).then((res) => {
        if (res?.code === 200) {
          message.success("修改成功");
          navigate("/DataBoard/Basis/RFMAnalysis");
        }
      });
    } else {
      await createInsightAnalysis(params).then((res) => {
        if (res?.code === 200) {
          message.success("创建成功");
          navigate("/DataBoard/Basis/RFMAnalysis");
        }
      });
    }
  };
  const setSelectOptions = async () => {
    let productLineData = [];
    await getProductLines().then((res) => {
      if (res?.code === 200) {
        setProductLineOptions([
          ...res.data.map((item) => ({
            value: item.productLineId,
            label: item.name,
          })),
        ]);
        setProductListCopy(res.data.map((item) => item.productLineId));
        setProductList(res.data.map((item) => item.productLineId));
        productLineData = res.data;
      }
    });
    await getPlatforms().then((resPlatForm) => {
      if (resPlatForm?.code === 200) {
        const channelOptions = resPlatForm.data.map((item: any) => {
          return {
            label: item.platformName,
            value: item.platformType,
          };
        });
        const firstChannelOptions = resPlatForm.data.map((item: any) => {
          return {
            label: item.platformName,
            value: item.platformType,
          };
        });
        setFirstChannelOptions(firstChannelOptions)

        const allData = resPlatForm.data.map(item => item.platformType);
        const mappedData = allData.map(item => [item]);
        setDataRangeCopy(mappedData);
        setDataRange(mappedData);
        setFirstRangeCopy(mappedData);
        setFirstRange(mappedData);
        getShops().then((resShop) => {
          if (resShop?.code === 200) {
            // res.data数组中的对象根据platformType分组
            const groupedShops = resShop.data.reduce((acc: any, shop: any) => {
              if (!acc[shop.platformType]) {
                acc[shop.platformType] = [];
              }
              acc[shop.platformType].push(shop);
              return acc;
            });
            channelOptions.forEach((item) => {
              if (groupedShops[item.value]) {
                item.children = groupedShops[item.value].map((shopItem) => {
                  return { value: shopItem.shopId, label: shopItem.shopName };
                });
              }
            });
            setChannelAndShopoptions(channelOptions);
            // 编辑
            if (location?.state?.id) {
              getInsightAnalysisDetail(location.state.id, productLineData);
            }
          }
        });
      }
    });
  };
  const onChangeChannelAndShop = (value: string[][]) => {
    setDataRange(value);
  };
  const onChangeFirstChannel = (value: string[][]) => {
    setFirstRange(value);
  };

  const handleReset = () => {
    setAnalysisName("");
    setDataRange(dataRangeCopy);
    setFirstRange(firstRangeCopy);
    setProductList(productListCopy);
    setDate({
      startDate: dayjs().subtract(3, "year").subtract(1, "day").startOf("day"),
      endDate: dayjs().subtract(1, "day"),
    });
  };
  const toBack = () => {
    navigate("/DataBoard/Basis/RFMAnalysis");
  };
  const getInsightAnalysisDetail = async (id: any, productOptions: any) => {
    if (checkPermissions(BOARD_KEY.EVENT_DETAIL)) {
      await getInsightAnalysis(id).then((res) => {
        if (res?.code === 200) {
          setAnalysisName(res.data.analysisName);
          setDate({
            startDate: dayjs(res.data.rfmReport.startDate),
            endDate: dayjs(res.data.rfmReport.endDate),
          });
          const linkageData = [];
          res.data.rfmReport.shopList.forEach((item) =>
            linkageData.push([item.platformType, item.shopId])
          );
          const firstData = [];
          res.data.rfmReport.firstJoinShopList.forEach((item) =>
            firstData.push([item.platformType])
          );
          setDataRange(linkageData);
          setFirstRange(firstData);
          let productLineCheckedArr = [];
          res.data.rfmReport.productList?.forEach(checkedItem => {
            productOptions?.forEach(item => {
              if (item.name === checkedItem) {
                productLineCheckedArr.push(item.productLineId);
              }
            })
          })
          setProductList(productLineCheckedArr);
        }
      });
    }
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().subtract(1, "day").endOf("day");
  };
  useEffect(() => {
    setSelectOptions();
  }, []);
  const handleChange = (value: string) => {};
  const onChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    if (dates) {
      setDate({
        startDate: dayjs(dateStrings[0], "YYYY-MM-DD"),
        endDate: dayjs(dateStrings[1], "YYYY-MM-DD"),
      });
    } else {
    }
  };
  const [selectState, setSelectState] = useState();
  const handleChangeProductLine = (e: any) => {
    setSelectState(e.target.checked);
    if (e.target.checked) {
      setProductList(productLineOptions.map((item) => item.value));
    } else {
      setProductList([]);
    }     
  }

  return (
    <div>
      <div className="headBox">
        <div style={{ display: 'flex', alignItems: 'center', padding: '2px 0px' }}>
          <div className={styles["BackList_title"]} onClick={toBack}>
            <LeftOutlined />
            <span className={styles["BackList_text"]}>返回</span>
          </div>
          <ModuleTitle
            mark="large"
            title={`${location?.state?.id ? "编辑分析" : "新建分析"}`}
          />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.title1}>基本信息</div>
          <div className={styles.form}>
            <div className={styles.row}>
              <PageItem title="分析名称">
                <Input
                  placeholder="请输入分析名称"
                  showCount
                  maxLength={100}
                  value={analysisName}
                  style={{ width: 523 }}
                  onChange={e => setAnalysisName(e.target.value)}
                />
              </PageItem>
            </div>
          </div>
          <div className={styles.title1}>配置分析项</div>
          <div className={styles.form}>
            <div className={styles.row}>
              <PageItem
                title="购买分析"
                require={false}
                tips="RFM分析：对已购人群进行分布分析及细分，帮助开展针对性的分层用户运营。通过收集任意人群在一段时间内对指定商品的购买行为，分析人群在最近购买时长（R）、购买频次（F）、消费金额（M）指标上的分布、并支持通过RFM模型对已购人群进行分层">
                <div className={styles.analysisInfo}>
                  <div style={{ display: 'flex' }}>
                    <img src={RFMAnalysisIcon} alt="" />
                    <span>RFM分析</span>
                  </div>
                  <div className={styles.analysisType}>Speedo</div>
                  {date.startDate && date.endDate && (
                    <div className={styles.analysisDate}>
                      {date.startDate.format('YYYY-MM-DD')} 至 {date.endDate.format('YYYY-MM-DD')}
                    </div>
                  )}
                </div>
              </PageItem>
            </div>
            <div className={styles.row}>
              <div className={styles.pageItem} style={{ flexDirection: 'column' }}>
                <div className={styles.title}>
                  <div>数据范围</div>
                  <div>：</div>
                </div>
                <div className={styles.pageItem}>
                  <div className={styles.title} style={{ width: '300px' }}>
                    <div>购买渠道</div>
                    <div>：</div>
                  </div>
                  <Cascader
                    options={channelAndShopoptions}
                    onChange={onChangeChannelAndShop}
                    multiple
                    maxTagCount="responsive"
                    value={dataRange}
                    style={{ width: 423 }}
                  />
                </div>
                <div className={styles.pageItem}>
                  <div className={styles.title} style={{ width: '300px' }}>
                    <div>首次入会渠道</div>
                    <div>：</div>
                  </div>
                  <Cascader
                    options={firstChannelOptions}
                    onChange={onChangeFirstChannel}
                    multiple
                    maxTagCount="responsive"
                    value={firstRange}
                    style={{ width: 423 }}
                  />
                </div>
                <div className={styles.pageItem}>
                  <div className={styles.title} style={{ width: '300px' }}>
                    <div>产品线</div>
                    <div>：</div>
                  </div>
                  <Select
                    style={{ width: 423 }}
                    allowClear
                    value={productList}
                    mode="multiple"
                    placeholder="请选择产品线"
                    options={productLineOptions}
                    onChange={value => {
                      setProductList(value);
                      if (value.length === productLineOptions.length) {
                        setSelectState(true);
                      } else {
                        setSelectState(false);
                      }
                    }}
                    dropdownRender={allSelectValue =>
                      // {productLineOptions.length > 0 && }
                      productLineOptions.length > 0 ? (
                        <div>
                          <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }}>
                            <Checkbox checked={selectState} onChange={handleChangeProductLine}>
                              全选
                            </Checkbox>
                          </div>
                          {allSelectValue}
                        </div>
                      ) : (
                        allSelectValue
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <PageItem title="分析周期" require={false}>
                <Select value="0" onChange={handleChange} options={analysisDateOptions} style={{ width: 197, marginRight: 8 }} />
                <RangePicker
                  style={{ width: 318 }}
                  allowClear={false}
                  onChange={onChange}
                  defaultValue={[date.startDate, date.endDate]}
                  value={[date.startDate, date.endDate]}
                  disabledDate={disabledDate}
                />
              </PageItem>
            </div>
          </div>
          <div className={styles.btn}>
            <Button
              type="default"
              style={{ marginRight: 8 }}
              onClick={handleReset}
            >
              重 置
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              提 交
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRfmAnalysis;
