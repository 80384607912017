//用户状态
export enum UserIdentity {
  ENABLE = 'STOCK', // 存量
  CHANNEL = 'CHANNEL',  // 停用
  COMMON = 'COMMON',  // 普通
  STAFF = 'STAFF',  // 员工
}

//用户状态
export enum UserIdentityTextEnum {
  ENABLE_TEXT = '存量会员',
  CHANNEL_TEXT = '渠道会员',
  COMMON_TEXT = '普通客户',
  STAFF_TEXT = '员工',
}