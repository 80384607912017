import React, { useState, useEffect } from "react";
import {
  Input,
} from "antd"
import { useLocation } from 'react-router-dom'
import CreateExport from "../../../UserGrouping/GroupingManagement/CreateExport";
const CreateExportMain = () => {
  let location = useLocation();
  const dataList = location.state;
  useEffect(() => {
   
    return () => {

    };
  }, [])

  return (
    <CreateExport dataList={dataList}/>
  );
};

export default CreateExportMain;
