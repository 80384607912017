import { Input, message, Form } from "antd"
import React, { ReactNode, useEffect, useRef, useImperativeHandle } from "react";
import type { InputRef } from 'antd';
// import './underLineInput.css'
import style from './underLineInput.module.scss'

const UnderLineInput = React.forwardRef((props: {
    placeholder?: string,
    prefix?: ReactNode,
    suffix?: ReactNode,
    visibilityToggle?: boolean,
    isPassWord?: boolean,
    fillLoginFormFun?: Function,
    label?: string,
    value?: string,
    editFinish?: Function,
    handleEdit?: Function,
    lostBlur?: Function,
    getBlur?: Function,
    disable?:boolean,
    isPrevent?: boolean,
    showCount?: boolean,
    isLoginPage?: boolean,
}, ref: any) => {
    const inputRef = useRef<InputRef>(null)

    const [accountForm] = Form.useForm();
    // if(inputRef.current){

    // }

    const getFocus = () => {
        inputRef.current!.focus({
            cursor: 'all'
        })
    }

    useImperativeHandle(ref, () => ({
        getFocus
    }))
    useEffect(() => {
        

    }, [])
    const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // if(!props.isPassWord && e.target.value.length > 20){
        //   message.warning('部门名称限制在20个字符以内');
        //   accountForm.setFieldValue('name', e.target.value.substring(0, 20))
        // }
        if (props.handleEdit) {
            props.handleEdit(e.target.value)
        }
        let obj = {} as any;
        if (props.label) {
            obj[props.label] = e.target.value
        }
        if (props.fillLoginFormFun) {
            props.fillLoginFormFun(obj)
        }
    }

    const onPressEnter = (e: any) => {
        if (props.editFinish){
            if (props.isPrevent) {
                props.editFinish(e)
            } else {
                props.editFinish(e.target.value)
            }
        }
    }

    const handleBlur = () => {
        if (props.lostBlur) {
            props.lostBlur()
        }
    }

    const handleFocus = () => {
        if (props.getBlur) {
            props.getBlur()
        }
    }

    const handleKeyDown = (event:any) => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    const loginStyle = {
      padding: props.isLoginPage ? '8px 10px' : '',
      borderRadius: props.isLoginPage ? '8px' : 0,
      backgroundColor: props.isLoginPage ? 'var(--background9)' : 'var(--background1)'
    }

    if (props.isPassWord) {
        return (
            // <Input.Password ref={inputRef} showCount={props.showCount} disabled={props.disable} onFocus={handleFocus} onBlur={handleBlur} onPressEnter={onPressEnter} defaultValue={props.value} onChange={inputChange} placeholder={props.placeholder} prefix={props.prefix} visibilityToggle={props.visibilityToggle} suffix={props.suffix} className={style["lz_underline-input"]} style={{padding: props.isLoginPage? '4px 0px 10px 0px' : ''}} />
            <Input.Password ref={inputRef}  showCount={props.showCount} disabled={props.disable} onFocus={handleFocus} onBlur={handleBlur} onPressEnter={onPressEnter} defaultValue={props.value} onChange={inputChange} placeholder={props.placeholder} prefix={props.prefix} visibilityToggle={props.visibilityToggle} suffix={props.suffix} className={style["lz_underline-input"]} style={loginStyle} />
        );
    } else {
        return (
            <Input ref={inputRef} showCount={props.showCount} onKeyDown={handleKeyDown} disabled={props.disable} onFocus={handleFocus} onBlur={handleBlur} onPressEnter={onPressEnter} defaultValue={props.value} onChange={inputChange} placeholder={props.placeholder} prefix={props.prefix} suffix={props.suffix} className={style["lz_underline-input"]} style={loginStyle} />

            // <Form form={accountForm} className={style["lz_underline-form"]}>
            //     <Form.Item name={'name'}>
            //     </Form.Item>
            // </Form>

        );
    }

})

export default UnderLineInput