import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { 
  ProfileTwoTone,
  CrownTwoTone,
  ApiTwoTone,
  DeploymentUnitOutlined,
  ConsoleSqlOutlined
 } from "@ant-design/icons";
import type { SearchProps } from "antd/es/input/Search";
import styles from "./index.module.scss";
import { DataAccessMethodListApi } from "../../../../../../api/collect/collect";

const { Search } = Input;

const iconStore = ['ProfileTwoTone', 'CrownTwoTone', 'ApiTwoTone', 'DeploymentUnitOutlined', 'ConsoleSqlOutlined'];

const CommercePlatform = (props: { open: boolean, accessMethodList: any, accessMethodListData: any, getAccessMethodListData: Function, getSelected: Function}) => {
  const { open, accessMethodList, accessMethodListData, getAccessMethodListData = () => {},  getSelected = () => {} } = props;

  interface DataType {
    imgUrl: string;
    name: string;
    wayKey: string,
    type: string
  }

  const [sourceData, setSourceData] = useState([] as DataType[]);
  const [selected, setSelected] = useState('');

  const onSearch = (e: string) => {
    getAccessMethodListData(e);
  };

  const selectItem = (val:DataType) => {
    setSelected(val.wayKey);
    // 将选中的id传回给父级
    getSelected(val.wayKey);
  }

  // 获取icon
  const getLabelFunction = (labelItem: string) => {
    switch(labelItem){
      case 'ProfileTwoTone' : return <ProfileTwoTone  />;
      case 'CrownTwoTone' : return <CrownTwoTone />;
      case 'ApiTwoTone' : return <ApiTwoTone />;
      case 'DeploymentUnitOutlined' : return <DeploymentUnitOutlined />;
      case 'ConsoleSqlOutlined' : return <ConsoleSqlOutlined />;
      default: return <></>
    }
  };

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setSourceData([...accessMethodListData]);
  }, [accessMethodListData]);

  useEffect(() => {
    if(!open){
      setSearchValue('');
      setSourceData([...accessMethodList]);
    }
  }, [open]);

  return (
    <div>
      <Search
        value={searchValue}
        allowClear
        placeholder="请输入关键词"
        onSearch={onSearch}
        onChange={(e) => {
          setSearchValue(e.target.value);
          if(!e.target.value){
            setSourceData([...accessMethodList]);
          }
        }}
        style={{ width: "100%" }}
      />
      <div className={styles.platformBox}>
      {
        sourceData.map((val, idx) => (
          <div key={idx} className={`${styles.platformItem} ${selected === val.wayKey ? styles.borderStyle2 : styles.borderStyle1}`} style={{marginRight: (idx + 1) % 3 === 0 ? 0 : '24px'}} onClick={() => selectItem(val)}>
            <div className={styles.image} style={{color: 'var(--text-font-color7)', fontSize: 'var(--text-font13)'}}>
              {
                getLabelFunction(val.imgUrl)
              }
            </div>
            <div className={styles.name}>{val.name}</div>
          </div>
        ))
      }
      </div>
    </div>
  );
};

export default CommercePlatform;
