import { createContext, useEffect, useState } from "react";
import style from "./index.module.scss";
import { Button, Steps, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import PageOne from "./components/PageOne";
import PageTwo from "./components/PageTwo";
import PageThree from "./components/PageThree";
import PageFour from "./components/PageFour";

import { CreateParam } from "../MemberCardList";
import {
  useGetShopList,
  useGetChannelList,
  PageItem,
} from "../../../../component/MiniStyles";

import {
  handCreateMemberCardApi,
  handUpdateMemberCardApi,
  getMemberCardDetailsApi,
  handCheckMemberCardCodeApi,
} from "../../../../api/memberCardList";

const stepList1 = [
  {
    title: "基础信息设置",
    key: "1",
    step: 0,
  },
  {
    title: "等级规则设置",
    key: "2",
    step: 1,
  },
  {
    title: "积分规则设置",
    key: "3",
    step: 2,
  },
  {
    title: "预览/完成",
    key: "4",
    step: 3,
  },
];

const stepList2 = [
  {
    title: "基础信息设置",
    key: "1",
    disabled: true,
    step: 0,
  },
  {
    title: "预览/完成",
    key: "4",
    step: 3,
  },
];

// 页面显示类型
const pageStatusMap = new Map([
  ["create", "新建"],
  ["update", "编辑"],
  ["view", "查看"],
  ["continueCreate", "编辑"],
]);

// 默认的等级item
const defaultGradeListItem = {
  calculateType: 1,
  gradeCode: 1,
  gradeName: "",
  gradeImgUrl: "",
  growthValue: null,
  giveType: 3,
  giveNum: null,
  orderAmount: null,
  orderCount: null,
  pointRate: null,
};

const defaultPageInfo = {
  id: null,
  computeType: 1,
  status: 1,
  // 第一页
  memberCardName: "",
  memberCardCode: "",
  shopList: [],
  remark: "",
  // 第二页
  gradeSetFlag: 1,
  calculateType: 1,
  growthValueConfig: {
    id: null,
    growthValueOtherName: "",
    expireType: 1,
    expireNum: 0,
    expireYear: 0,
    expireDate: "",
    dealType: 1,
    dealGrowthValue: null,
    refundFlag: 0,
    oneMaxFlag: 1,
    oneMaxValue: null,
  },
  orderComputeConfig: {
    id: null,
    computeType: 1,
    computeStartTime: null,
  },
  gradeList: [
    {
      calculateType: 1,
      gradeCode: 1,
      gradeName: "",
      gradeImgUrl: "",
      growthValue: null,
      giveType: 3,
      giveNum: null,
      orderAmount: null,
      orderCount: null,
      pointRate: null,
    },
  ],
  keepGradeFlag: 1,
  keepGradeConfig: {
    id: null,
    amountFlag: 1,
    amountDays: null,
    orderAmount: null,
    goodsFlag: 1,
    goodsDays: null,
    goodsName: "",
  },
  // 第三页
  pointSetFlag: 1,
  pointFlag: 1,
  allowPointNe: 2,
  pointComputeConfig: {
    id: null,
    computeType: 1,
    sendPoint: null,
    pointExpirateRule: 1,
    tradePointExpirateType: 1,
    tradePointRegular: null,
    tradePointRelativeYear: 0,
    tradePointRelativeDate: "",
    nonTradePointExpirateType: 1,
    nonTradePointRegular: null,
    nonTradePointRelativeYear: 0,
    nonTradePointRelativeDate: "",
    sendPointTimeType: 1,
    refundPointFlag: 1,
  },
};

export const MyContext = createContext<any>({});

function CreateMemberCard({
  handBackToList,
  createParams,
}: {
  handBackToList: () => void;
  createParams: CreateParam;
}) {
  const [current, setCurrent] = useState(0);
  const [pageInfo, setPageInfo] = useState<any>(defaultPageInfo);
  const { shopList } = useGetShopList();
  const [selectShopList, setSelectShopList] = useState([]); //回显的时候已选择的店铺，修改时拼在店铺列表后面
  const { showPage, pageStatus } = createParams;
  // const stepList = createParams.computeType === 1 ? stepList1 : stepList2;
  const [stepList, setStepList] = useState<any[]>([]);
  const { getChannelList } = useGetChannelList();
  const [channelNameMap, setChannelNameMap] = useState(new Map());
  const [copyMemberCardCode, setCopyMemberCardCode] = useState("");

  useEffect(() => {
    setCurrent(showPage);
    if (["view", "update", "continueCreate"].includes(pageStatus)) {
      getPageInfo();
    } else {
      if (createParams.computeType === 1) {
        setStepList(stepList1);
      } else {
        setStepList(stepList2);
      }
      setPageInfo({ ...pageInfo, computeType: createParams.computeType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(createParams)]);

  useEffect(() => {
    getChannelOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 获得渠道下拉列表
  const getChannelOptions = async () => {
    const { nameMap } = await getChannelList();
    setChannelNameMap(nameMap);
  };

  // 页面置顶
  const handScrollTop = () => {
    document.getElementsByTagName("main")[1].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // 平滑滚动效果，可选
    });
  };

  // 下一步
  const nextStep = async (status = true) => {
    handScrollTop();
    if (status) {
      if (current === 0) {
        const res = await checkPageOne();
        if (!res) return;
      }
      if (current === 1 && !checkPageTwo()) return;
      if (current === 2 && !checkPageThree()) return;
    } else {
      if (current === 1) {
        handChange("gradeSetFlag", 0);
      } else if (current === 2) {
        handChange("pointSetFlag", 0);
      }
    }
    setCurrent((step) => stepList[step + 1].step);
  };

  // 上一步
  const backStep = () => {
    setCurrent(
      (step) => stepList[stepList.findIndex((e) => e.step === step) - 1].step
    );
    handScrollTop();
  };

  // 第一步校验
  const checkPageOne = async () => {
    const { memberCardName, memberCardCode, shopList } = pageInfo;
    if (!memberCardName) {
      message.error("请填写会员卡名称");
      return false;
    }
    if (!memberCardCode) {
      message.error("请填写体系编码");
      return false;
    }
    const res = await handCheckCode();
    if (!res) return false;
    if (shopList.length === 0) {
      message.error("请选择店铺");
      return false;
    }
    return true;
  };

  // 第二步校验
  const checkPageTwo = () => {
    const { calculateType, gradeList, keepGradeFlag } = pageInfo;
    const {
      expireType,
      expireNum,
      expireYear,
      expireDate,
      dealGrowthValue,
      oneMaxFlag,
      oneMaxValue,
    } = pageInfo.growthValueConfig;
    const { computeType, computeStartTime } = pageInfo.orderComputeConfig;
    const {
      amountDays,
      orderAmount,
      goodsDays,
      goodsName,
      amountFlag,
      goodsFlag,
    } = pageInfo.keepGradeConfig;

    // 按成长值计算时
    if (calculateType === 1) {
      // 成长值有效期
      if (expireType === 1 && !expireNum) {
        message.error("请填写成长值有效期天数");
        return false;
      }
      if (expireType === 2) {
        if (!expireYear && expireYear !== 0) {
          message.error("请填写成长值有效期年份");
          return false;
        }
        if (!expireDate) {
          message.error("请填写成长值有效期日期");
          return false;
        }
      }
      // 基础规则
      if (!dealGrowthValue) {
        message.error("请填写基础规则");
        return false;
      }
      // 单次发放上限
      if (oneMaxFlag === 1 && !oneMaxValue) {
        message.error("请填写单笔交易最高获得成长值");
        return false;
      }
      // 成长值规则设置
      let gradeRuleStatus = false;
      let gradeListStatus = false;
      let sortStatus = false;
      gradeList.forEach((item: any, index: number) => {
        if (item.giveType !== 3 && !item.giveNum) {
          gradeRuleStatus = true;
        }
        if (!item.gradeName || (index > 0 && !item.growthValue)) {
          gradeListStatus = true;
        }
        if (index > 1 && item.growthValue <= gradeList[index - 1].growthValue) {
          sortStatus = true;
        }
      });
      if (gradeListStatus) {
        message.error("请完善等级设置");
        return false;
      }
      if (gradeRuleStatus) {
        message.error("请完善成长值赠送规则");
        return false;
      }
      if (sortStatus) {
        message.error("等级设置升级规则需递增");
        return false;
      }
    }
    // 按订单计算时
    if (calculateType === 2) {
      // 等级计算时间
      if (computeType === 1 && !computeStartTime) {
        message.error("请选择等级计算时间");
        return false;
      }
      // 等级设置规则
      let gradeListStatus = false;
      let sortStatus = false;
      gradeList.forEach((item: any, index: number) => {
        if (
          !item.gradeName ||
          (index > 0 && (!item.orderAmount || !item.orderCount))
        ) {
          gradeListStatus = true;
        }
        if (index > 1 && item.orderAmount <= gradeList[index - 1].orderAmount) {
          sortStatus = true;
        }
        if (index > 1 && item.orderCount <= gradeList[index - 1].orderCount) {
          sortStatus = true;
        }
      });
      if (gradeListStatus) {
        message.error("请完善等级设置");
        return false;
      }
      if (sortStatus) {
        message.error("等级设置升级规则需递增");
        return false;
      }
    }
    // 保级逻辑
    if (keepGradeFlag === 1) {
      // 订单金额保级
      if (amountFlag === 1 && (!amountDays || !orderAmount)) {
        message.error("请填写订单金额保级");
        return false;
      }
      // 订单商品保级
      if (goodsFlag === 1 && (!goodsDays || !goodsName)) {
        message.error("请填写订单商品保级");
        return false;
      }
      // 都不选
      if (amountFlag === 0 && goodsFlag === 0) {
        message.error("请选择保级方式");
        return false;
      }
    }
    handChange("gradeSetFlag", 1);
    return true;
  };

  // 第三步校验
  const checkPageThree = () => {
    const { pointFlag, pointComputeConfig, gradeList } = pageInfo;
    const {
      sendPoint,
      pointExpirateRule,
      tradePointExpirateType,
      tradePointRegular,
      tradePointRelativeYear,
      tradePointRelativeDate,
      nonTradePointExpirateType,
      nonTradePointRegular,
      nonTradePointRelativeYear,
      nonTradePointRelativeDate,
    } = pointComputeConfig;

    if (pointFlag === 1) {
      if (!sendPoint) {
        message.error("请填写交易积分发放");
        return false;
      }
      // 统一设置
      // 固定时间
      if (tradePointExpirateType === 2 && !tradePointRegular) {
        message.error("请填写固定时间积分有效期天数");
        return false;
      }
      // 相对时间
      if (tradePointExpirateType === 3) {
        if (!(tradePointRelativeYear >= 0)) {
          message.error("请填写相对时间有效期年份");
          return false;
        }
        if (!tradePointRelativeDate) {
          message.error("请选择相对时间日期");
          return false;
        }
      }
      // 按交易积分/非交易积分分别设置
      if (pointExpirateRule === 2) {
        // 固定时间
        if (nonTradePointExpirateType === 2 && !nonTradePointRegular) {
          message.error("请填写固定时间积分有效期天数");
          return false;
        }
        // 相对时间
        if (nonTradePointExpirateType === 3) {
          if (!(nonTradePointRelativeYear >= 0)) {
            message.error("请填写相对时间有效期年份");
            return false;
          }
          if (!nonTradePointRelativeDate) {
            message.error("请选择相对时间日期");
            return false;
          }
        }
      }
      // 积分倍数
      let pointStatus = false;
      let sortStatus = false;
      gradeList.forEach((item: any, index: number) => {
        if (!item.pointRate) {
          pointStatus = true;
        }
        if (index > 0 && item.pointRate < gradeList[index - 1].pointRate) {
          sortStatus = true;
        }
      });
      if (pointStatus) {
        message.error("请完善交易积分倍数表格");
        return false;
      }
      if (sortStatus) {
        message.error("等级设置升级规则需递增");
        return false;
      }
    }
    handChange("pointSetFlag", 1);
    return true;
  };

  // 更改data,一层值
  const handChange = (name: string, value: any) => {
    setPageInfo({ ...pageInfo, [name]: value });
  };

  // 更改data,二层值-对象
  const handChangeObj = (
    type: any,
    name: string,
    value: any,
    name2?: string,
    value2?: any,
    name3?: string,
    value3?: any,
    name4?: string,
    value4?: any
  ) => {
    const obj = JSON.parse(JSON.stringify(pageInfo[type]));
    obj[name] = value;
    if (name2) {
      obj[name2] = value2;
    }
    if (name3) {
      obj[name3] = value3;
    }
    if (name4) {
      obj[name4] = value4;
    }
    setPageInfo({ ...pageInfo, [type]: obj });
  };

  // 更改data,二层值-数组
  const handChangeArr = (
    type: string,
    index: number,
    name: string,
    value: any,
    name2?: string,
    value2?: any
  ) => {
    const arr: any[] = JSON.parse(JSON.stringify(pageInfo[type]));
    arr[index][name] = value;
    if (name2) {
      arr[index][name2] = value2;
    }
    setPageInfo({ ...pageInfo, [type]: arr });
  };

  // 增加和删除一个数组
  const changeArrLength = (
    type: string,
    status: "add" | "sub",
    index?: number
  ) => {
    const arr: any[] = JSON.parse(JSON.stringify(pageInfo[type]));
    const obj: any = JSON.parse(JSON.stringify(defaultGradeListItem));
    if (status === "add") {
      obj.calculateType = arr[arr.length - 1].calculateType;
      obj.gradeCode = arr[arr.length - 1].gradeCode + 1;
      arr.push(obj);
    } else {
      arr.splice(Number(index), 1);
    }
    setPageInfo({ ...pageInfo, [type]: arr });
  };

  // 清空积分部分有效期
  const clearPointTime = (value: any) => {
    const obj = {
      ...JSON.parse(JSON.stringify(pageInfo.pointComputeConfig)),
      tradePointExpirateType: 1,
      tradePointRegular: null,
      tradePointRelativeYear: 0,
      tradePointRelativeDate: "",
      nonTradePointExpirateType: 1,
      nonTradePointRegular: null,
      nonTradePointRelativeYear: 0,
      nonTradePointRelativeDate: "",
      pointExpirateRule: value,
    };

    setPageInfo({ ...pageInfo, pointComputeConfig: obj });
  };

  // 判断会员卡编码是否存在
  const handCheckCode = async () => {
    if (pageStatus === "update") return true;
    if (
      pageStatus === "continueCreate" &&
      pageInfo.memberCardCode === copyMemberCardCode
    )
      return true;
    const res = await handCheckMemberCardCodeApi({
      memberCardCode: pageInfo.memberCardCode,
    });
    if (res?.code !== 200 || !res.data) {
      message.error("体系编码已存在");
      handChange("memberCardCode", "");
      return false;
    }
    return true;
  };

  // 提交并创建
  const handCommit = async () => {
    const params = JSON.parse(JSON.stringify(pageInfo));
    params.status =
      params.pointSetFlag === 0 || params.gradeSetFlag === 0
        ? 0
        : createParams.status;
    params.gradeList = params.gradeList.map((item: any, index: number) => ({
      ...item,
      gradeCode: index + 1,
    }));
    let res: any = {};
    if (pageStatus === "create") {
      res = await handCreateMemberCardApi(params);
    } else {
      res = await handUpdateMemberCardApi(params);
    }
    // handUpdateMemberCardApi
    if (res?.code === 200) {
      message.success(`${pageStatus === "update" ? "修改" : "创建"}成功`);
      handBackToList();
    }
  };

  // 获得页面回显
  const getPageInfo = async () => {
    const res = await getMemberCardDetailsApi({ id: createParams.id });
    if (res?.code === 200) {
      // const obj = { ...pageInfo, ...(res?.data || {}) };
      const obj = res?.data || {};
      const objInfo = Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => value != null)
      );
      if (res.data.computeType === 1) {
        setStepList(stepList1);
      } else {
        setStepList(stepList2);
      }
      setCopyMemberCardCode(obj?.memberCardCode || "");
      setSelectShopList(res.data?.shopList || []);
      setPageInfo({ ...pageInfo, ...objInfo });
    } else {
      handBackToList();
    }
  };

  // 页面标题和返回按钮
  const HeaderSection = () => {
    return (
      <div className={style["header-bgd"]}>
        <div className={style["header-icon"]} onClick={handBackToList}>
          <LeftOutlined style={{ marginRight: "6px" }} />
          退出
        </div>
        <div className={style["header-text"]}>
          {pageStatusMap.get(pageStatus)}会员卡
        </div>
      </div>
    );
  };

  // 步骤条
  const StepSection = () => {
    return (
      <div style={{ margin: "44px auto", maxWidth: "1100px" }}>
        {pageStatus !== "view" && (
          <Steps current={current} items={stepList} progressDot />
        )}
      </div>
    );
  };

  return (
    <div className={style["CreateMemberCard-bgd"]}>
      {/* 页面标题 */}
      <HeaderSection />
      {/* 步骤条 */}
      <StepSection />
      {/* 内容 */}
      <MyContext.Provider
        value={{
          data: pageInfo,
          shopList,
          pageStatus,
          channelNameMap,
          selectShopList,
          myContextChange: handChange,
          myContextChangeObj: handChangeObj,
          myContextChangeArr: handChangeArr,
          myContextChangeArrLength: changeArrLength,
          myContextChangeClearPointTime: clearPointTime,
        }}>
        {current === 0 && <PageOne />}
        {current === 1 && <PageTwo />}
        {current === 2 && <PageThree />}
        {current === 3 && <PageFour />}
      </MyContext.Provider>

      {/* 底部按钮 */}
      <div className={style["footer-bgd"]}>
        <PageItem title="">
          {pageStatus === "view" ? (
            <Button onClick={handBackToList}>关闭</Button>
          ) : (
            <>
              {current === 0 ? (
                <Button onClick={handBackToList}>关闭</Button>
              ) : (
                <Button onClick={backStep}>返回上一步</Button>
              )}
              {current < 3 ? (
                <Button
                  type="primary"
                  onClick={() => nextStep()}
                  style={{ margin: "0 9px 0 8px" }}>
                  下一步（{current + 1}/{stepList.length}）
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={handCommit}
                  style={{ margin: "0 9px 0 8px" }}>
                  完成并{pageStatus === "update" ? "修改" : "创建"}
                </Button>
              )}
              {[1, 2].includes(current) &&
                ["create", "continueCreate"].includes(pageStatus) && (
                  <Button type="link" onClick={() => nextStep(false)}>
                    稍后设置本页
                  </Button>
                )}
            </>
          )}
        </PageItem>
      </div>
    </div>
  );
}

export default CreateMemberCard;
