import React, { useState } from 'react';
import { Modal } from 'antd';

const TimePrompt = (props: {
  createPromptOpen: boolean,
  promptValue: string,
  getCreatePromptOpenStatus: Function,
  getConfirmOperate: Function
}) => {

  const { createPromptOpen, promptValue, getCreatePromptOpenStatus = () => {}, getConfirmOperate = () => {} } = props;
  
  // 取消
  const handleCancel = () => {
    getCreatePromptOpenStatus(false);
  };

  // 确定
  const handleOk = () => {
    getConfirmOperate();
  };

  return (
    <div >
      <Modal 
        title="提示"
        width={450}
        open={createPromptOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
      >
        <div style={{height: '60px', paddingTop: '8px', textAlign: 'left', textIndent: '2em'}}>
          {promptValue}
        </div>
      </Modal>
    </div>
  );
}

export default TimePrompt;