import { AxiosPromise } from 'axios';
import request from '../network'
import { DeptVO } from './types';

//获取部门树列表
export function getDeptTreeApi(): AxiosPromise<DeptVO[]> {
    return request({
        url: '/system/user/deptTree',
        method: 'get',
        data: {}
    });
}

//修改部门名称
export function editDeptApi(data: DeptVO) {
    return request({
        url: '/system/dept/edit',
        method: 'post',
        data: data
    });
}

//修改部门名称
export function addDeptApi(data: DeptVO) {
    return request({
        url: '/system/dept',
        method: 'post',
        data: data
    });
}

//修改部门名称
export function addDeptVisAVis(data: DeptVO) {
    return request({
        url: '/system/dept/addDeptVisAVis',
        method: 'post',
        data: data
    });
}

//修改部门名称
export function deptMoveApi(data: DeptVO) {
    return request({
        url: '/system/dept/deptMove',
        method: 'post',
        data: data
    });
}

//删除部门
export function removeApi(deptId: string) {
    return request({
        url: `/system/dept/remove/${deptId}`,
        method: 'get',
        data: {}
    });
}

//获取部门列表
export function getDeptListApi(): AxiosPromise<DeptVO[]> {
    return request({
        url: `/system/dept/list`,
        method: 'get',
        data: {}
    });
}

export default {}