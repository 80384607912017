import { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import {
  Switch,
  Button,
  Divider,
  Col,
  Row,
  Drawer,
  Modal,
  Input,
  Alert,
  message,
  Badge,
  Tooltip,
  Empty,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  CrownOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FilterView from "../../../../component/FilterView";
import { TitleWithExpansion } from "../../../../component/MiniStyles";
import TagList from "../../../../component/TagList";
import { PointIcon } from "../../../../assets/svg/index";
import lzCount from "../../../../assets/images/lzCount.png";
import plateformCount from "../../../../assets/images/plateformCount.png";
import trilateralCount from "../../../../assets/images/trilateralCount.png";
import CreateMemberCard from "../CreateMemberCard";
import { permissionMap } from "../../../../assets/public/pageMap";
import checkPermission from "../../../../assets/public/checkPermission";

import {
  getMemberCardListApi,
  handChangeMemberCardStatusApi,
} from "../../../../api/memberCardList";
import { MemberCardListItem } from "../../../../api/memberCardList/types";

// 新建弹窗
interface CreateCardType {
  url: string;
  title: string;
  sign: string;
  computeType: number;
  text: string;
}

const { confirm } = Modal;

const createCardTypeList = [
  {
    url: lzCount,
    title: "陆泽计算",
    sign: "由陆泽进行计算",
    computeType: 1,
    text: "。可在此系统进行创建、编辑。",
  },
  {
    url: plateformCount,
    title: "平台计算",
    sign: "由各个平台进行计算",
    computeType: 2,
    text: "。系统内无法创建、编辑。",
  },
  {
    url: trilateralCount,
    title: "三方计算",
    sign: "由第三方公司进行计算",
    computeType: 3,
    text: "。系统内无法创建、编辑。",
  },
];

// 搜索
const searchList: searchItem[] = [
  {
    searchType: "string",
    searchComponentType: "input",
    placeHolder: "请输入",
    title: "会员卡名称",
    key: "memberCardName",
  },
  {
    searchType: "string",
    searchComponentType: "input",
    placeHolder: "请输入",
    title: "店铺名称",
    key: "shopName",
  },
];

// 默认搜索内容
const defaultSearchInfo = {
  memberCardName: "",
  shopName: "",
};

// 默认店铺数组
const defaultShopList = {
  JD: [],
  TM: [],
  DY: [],
  WX: [],
  YZ: [],
};

export interface CreateParam {
  computeType: number;
  showPage: number;
  id?: number | null;
  pageStatus: "create" | "update" | "view" | "continueCreate";
  status: number;
}

function MemberCardList() {
  const filterViewRef = useRef<any>(null);
  const [createDrawerStatus, setCreateDrawerStatus] = useState(false);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [deactivateModalStatus, setDeactivateModalStatus] = useState(false);
  const [searchInfo, setSearchInfo] = useState(defaultSearchInfo);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [cardList, setCardList] = useState<MemberCardListItem[]>([]);
  // 开关会员卡的信息
  const [changInfo, setChangeInfo] = useState<any>(null);
  // 列表页向新建页的传参
  const [createParams, setCreateParams] = useState<CreateParam>({
    computeType: 1,
    showPage: 0,
    id: null,
    pageStatus: "create",
    status: 1,
  });

  // 页面置顶
  const handScrollTop = () => {
    document.getElementsByTagName("main")[1].scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // 平滑滚动效果，可选
    });
  };

  // 查询
  const filterFinish = (data: any) => {
    setSearchInfo({ ...data });
    setSearchFlag((e) => !e);
  };

  // 新建会员卡
  const handCreateCard = (id: number) => {
    setCreateParams({
      ...createParams,
      computeType: id,
      showPage: 0,
      pageStatus: "create",
      status: 1,
    });
    setCreateDrawerStatus(false);
    setIsShowCreate(true);
  };

  // 查看页面
  const handOperatePage = (
    id: number,
    pageStatus: "create" | "update" | "view" | "continueCreate",
    showPage: number,
    status: number
  ) => {
    setCreateParams({ ...createParams, id, showPage, pageStatus, status });
    setIsShowCreate(true);
  };

  // 开关会员卡change事件
  const onSwitchChange = (info: any, status: boolean) => {
    if (!status) {
      setChangeInfo(info);
      return setDeactivateModalStatus(true);
    } else {
      if (info.gradeSetFlag === 0) {
        return message.error({
          content: (
            <span>
              <span>请完善等级信息</span>
              <Button type="link">去完善</Button>
            </span>
          ),
          onClick: () =>
            handOperatePage(info.id, "continueCreate", 1, info.status),
        });
      }
      if (info.pointSetFlag === 0) {
        return message.error({
          content: (
            <span>
              <span>请完善积分信息</span>
              <Button type="link">去完善</Button>
            </span>
          ),
          onClick: () =>
            handOperatePage(info.id, "continueCreate", 2, info.status),
        });
      }
      confirm({
        title: "是否确认启用会员卡?",
        icon: <ExclamationCircleFilled />,
        okText: "确认启用",
        onOk() {
          changeMemberStatus(info.id, status ? 1 : 0);
        },
      });
    }
  };

  // 关闭会员卡
  const handCloseMemberCard = (e: any) => {
    if (e.input.value !== "停用当前会员卡") {
      return message.error("请输入“停用当前会员卡”");
    }
    changeMemberStatus();
  };

  // 更改会员卡状态
  const changeMemberStatus = async (id?: number, status?: number) => {
    const params = {
      id: id || changInfo.id,
      status: status || (changInfo.status === 1 ? 0 : 1),
    };
    const res = await handChangeMemberCardStatusApi(params);
    if (res.code === 200) {
      setDeactivateModalStatus(false);
      getCardList();
      message.success(`已${params.status === 1 ? "启用" : "停用"}`);
    }
  };

  // 获得会员卡列表
  const getCardList = async () => {
    const res: any = await getMemberCardListApi({ ...searchInfo });
    if (res?.code === 200) {
      setCardList(res.data);
    } else {
      setCardList([]);
    }
  };

  useEffect(() => {
    handScrollTop();
  }, [isShowCreate]);

  useEffect(() => {
    !isShowCreate && getCardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(searchInfo), isShowCreate, searchFlag]);

  // 会员卡
  const MemberCard = ({ info }: { info: any }) => {
    const iconStyle = { fontSize: "var(--text-font10)", color: "var(--text-font-color18)" };
    const { memberCardName, status, shopList, id } = info;
    const obj = JSON.parse(JSON.stringify(defaultShopList));
    Object.keys(obj).forEach((item) => {
      obj[item] = shopList?.filter((e: any) => e.channelType === item) || [];
    });
    const list = Object.values(obj);

    return (
      <div className={style["card-bgd"]}>
        {/* 头部 */}
        <div className={style["card-header"]}>
          <div>{memberCardName}</div>
          {checkPermission(permissionMap.get("会员卡管理启用")) && (
            <Switch
              value={status === 1}
              checkedChildren="启用"
              unCheckedChildren="停用"
              style={{ width: "70px", flexShrink: "0" }}
              onChange={(e) => onSwitchChange(info, e)}
            />
          )}
        </div>
        {/* 中间 */}
        <div className={style["card-content"]}>
          <div>适用店铺</div>
          {list.map((item: any, index: number) => {
            if (item.length > 0) {
              return (
                <TagList
                  channelType={item[0].channelType}
                  key={`${item[0].channelType}_${index}`}
                  tagList={item}
                  tagId={`${item[0].channelType}_${info.id}`}
                />
              );
            } else return "";
          })}
        </div>
        {/* 底部 */}
        <div className={style["card-footer"]}>
          <Tooltip title="查看">
            <Button
              type="text"
              disabled={!checkPermission(permissionMap.get("会员卡管理列表"))}
              onClick={() => handOperatePage(id, "view", 3, status)}>
              <EyeOutlined style={iconStyle} />
            </Button>
          </Tooltip>
          <Divider type="vertical" style={{ margin: "0", height: "24px" }} />
          <Tooltip title="编辑">
            <Button
              type="text"
              disabled={!checkPermission(permissionMap.get("会员卡管理编辑"))}
              onClick={() => {
                if (info.pointSetFlag === 0 || info.gradeSetFlag === 0) {
                  handOperatePage(id, "continueCreate", 0, status);
                } else {
                  handOperatePage(id, "update", 0, status);
                }
              }}>
              <EditOutlined style={iconStyle} />
            </Button>
          </Tooltip>
          <Divider type="vertical" style={{ margin: "0", height: "24px" }} />
          <Tooltip
            title={`${info.pointSetFlag !== 0 ? "设置" : "完善"}等级信息`}>
            <Badge
              count={
                info.gradeSetFlag === 0 ? (
                  <ExclamationCircleOutlined style={{ color: "var(--label6)" }} />
                ) : (
                  ""
                )
              }
              offset={[-10, 8]}>
              <Button
                type="text"
                disabled={info.gradeSetFlag !== 0}
                onClick={() =>
                  handOperatePage(id, "continueCreate", 1, status)
                }>
                <CrownOutlined style={iconStyle} />
              </Button>
            </Badge>
          </Tooltip>

          <Divider type="vertical" style={{ margin: "0", height: "24px" }} />
          <Tooltip
            title={`${info.pointSetFlag !== 0 ? "设置" : "完善"}积分信息`}>
            <Badge
              count={
                info.pointSetFlag === 0 ? (
                  <ExclamationCircleOutlined style={{ color: "var(--label6)" }} />
                ) : (
                  ""
                )
              }
              offset={[-10, 8]}>
              <Button
                type="text"
                disabled={info.pointSetFlag !== 0}
                onClick={() =>
                  handOperatePage(id, "continueCreate", 2, status)
                }>
                <PointIcon />
              </Button>
            </Badge>
          </Tooltip>
        </div>
      </div>
    );
  };

  // 会员卡列表
  const CardListSection = () => {
    return (
      <div className={style["card-list-bgd"]}>
        {cardList.length > 0 ? (
          <Row gutter={[16, 16]}>
            {cardList.map((item, index) => (
              <Col span={8} key={`${item}-${index}`}>
                <MemberCard info={item} />
              </Col>
            ))}
          </Row>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ marginTop: "20vh" }}
          />
        )}
      </div>
    );
  };

  // 新建页面
  if (isShowCreate) {
    return (
      <CreateMemberCard
        createParams={createParams}
        handBackToList={() => setIsShowCreate(false)}
      />
    );
  }

  // 列表页面
  return (
    <div className={style["MemberCardList-bgd"]}>
      {/* 标题 */}
      <TitleWithExpansion
        title="会员卡列表"
        btnDisabled={cardList.length > 0}
        onChange={() => filterViewRef.current.showFilterView()}
        operaTionTitle="新建会员卡"
        searchPermissions={permissionMap.get("会员卡管理列表")}
        operaTionPermissions={permissionMap.get("会员卡管理新建")}
        onBtnChange={() => setCreateDrawerStatus(!createDrawerStatus)}>
        {/* 搜索部分 */}
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          defaultFormValue={defaultSearchInfo}
        />
      </TitleWithExpansion>
      {/* 会员卡列表部分 */}
      <CardListSection />
      {/* 新建会员卡弹窗 */}
      <CreateCardDrawer
        open={createDrawerStatus}
        onClose={() => setCreateDrawerStatus(!createDrawerStatus)}
        handChoose={handCreateCard}
      />
      {/* 停用会员卡弹窗 */}
      {deactivateModalStatus && (
        <DeactivateModal
          handleOk={handCloseMemberCard}
          onClose={() => setDeactivateModalStatus(false)}
        />
      )}
    </div>
  );
}

// 新建会员卡弹窗
const CreateCardDrawer = ({
  onClose,
  handChoose,
  open,
}: {
  onClose: () => void;
  handChoose: (computeType: number) => void;
  open: boolean;
}) => {
  // 选项
  const ChooseItem = ({ info }: { info: CreateCardType }) => {
    const { title, url, sign, computeType } = info;

    return (
      <div
        className={style[`memberCardList-drawer-chooseItem`]}
        onClick={() => handChoose(computeType)}>
        <img src={url} alt="" />
        <div>
          <div className={style["title"]}>{title}</div>
          <div className={style["text"]}>
            会员等级规则、积分规则
            <span className={style["sign"]}>{sign}</span>
            {info.text}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title="通过什么方式创建会员卡"
      onClose={onClose}
      open={open}
      width={400}>
      {createCardTypeList.map((item, index) => (
        <ChooseItem key={`${item.computeType}-${index}`} info={item} />
      ))}
    </Drawer>
  );
};

// 停用会员卡弹窗
const DeactivateModal = ({
  handleOk,
  onClose,
}: {
  handleOk: (e: any) => void;
  onClose: () => void;
}) => {
  const inputValue = useRef(null);

  return (
    <Modal
      title="停用会员卡"
      open
      onOk={() => handleOk(inputValue.current)}
      okText="停用确认"
      onCancel={onClose}>
      <Alert
        message={
          <span>
            若确认要停用当前会员卡，请在下方输入框输入“
            <span style={{ color: "var(--required)" }}>停用当前会员卡</span>
            ”并点击确认按钮。
          </span>
        }
        type="warning"
        showIcon
        style={{ marginBottom: "16px" }}
      />
      <Input placeholder="请输入" ref={inputValue} />
    </Modal>
  );
};

export default MemberCardList;
