import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  Modal,
  Dropdown,
  Space,
  Select,
  Popconfirm,
  message,
  Switch,
  Tooltip
} from "antd";
import type { TableProps } from "antd";
import { FilterOutlined ,EllipsisOutlined } from "@ant-design/icons";
import ModuleTitle from "../../../../component/ModuleTitle";
import SearchIconInput from "../../../../component/Input/SearchIconInput/searchIconInput";
import FilterView from "../../../../component/FilterView/filterView";
import CreateRole from "./components/CreateRole";
import RoleEmpower from "./components/RoleEmpower";
import ButtonPermissions from '../../../../component/Button/SystemButton';
import { SYS_KEY } from '../../../../router/Permissions';
import {
  RoleStatusEnum,
  RoleStatusTextEnum,
} from "../../../../enums/RoleStatusEnum";
import {
  getRoleList,
  changeStatus,
  roleCountUser,
  removeRole,
} from "../../../../api/role/role";
import { RoleListQuery, RoleListVO } from "../../../../api/role/types";
import styles from "./index.module.scss";
import { checkPermissions } from "@/utils/utils";
import { getTableScroll } from '@/store/index'

const RoleManagement = () => {
  const filterViewRef: any = useRef(null);
  const searchIconInputRef: any = useRef(null);
  //列表中 修改角色状态
  const [tablePopConfirm, setTablePopConfirm] = useState(false)

  // 相应成功标识（防止多次点击）
  const [responseFinished, setResponseFinished] = useState(false);

  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入角色名称或者角色ID",
      title: "角色名称/ID",
      key: "roleName",
    },
    {
      searchType: "number",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "角色状态",
      key: "status",
      options: [
        {
          value: RoleStatusEnum.ENABLE,
          label: RoleStatusTextEnum.ENABLE_TEXT,
        },
        {
          value: RoleStatusEnum.DISABLED,
          label: RoleStatusTextEnum.DISABLED_TEXT,
        },
      ],
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "创建时间",
      key: "createTimeStart-createTimeEnd",
    },
  ];

  interface SearcParamType {
    status: string;
    createTimeStart: string;
    createTimeEnd: string;
    roleName: string;
  }

  const searcParamInt: SearcParamType = {
    status: "",
    createTimeStart: "",
    createTimeEnd: "",
    roleName: "",
  };

  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  // 查询
  const filterFinish = (data: any) => {
    setSearcParam(data);
    getRoleListData({ pageSize: 10, pageNum: 1 }, data, {
      orderByColumn: "createTime",
      isAsc: "desc",
    });
  };

  const [listData, setListData] = useState([] as RoleListVO[]);

  // 编辑角色
  const editItem = (item: RoleListVO) => {
    setFlag("edit");
    setEditData(item);
    setCreatRoleOpen(true);
  };

  // 授权
  const empowerItem = (item: RoleListVO) => {
    setEmpowerOpen(true);
    setEditData(item);
  };

  // 删除
  const deleteItem = async (roleId: number) => {
    if (responseFinished) {
      message.warning('数据正在处理，请勿重复操作');
      return
    }
    setResponseFinished(true);
    const res = await removeRole(String(roleId));
    if (res) {
      getRoleListData(
        { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
        searcParam,
        sortValue
      );
      setResetConfirm(false);
      setResponseFinished(false);
      message.success('删除成功')
    } else {
      setResponseFinished(false);
    }
  };

  const [tableConfig, setTableConfig] = useState({} as any);
  const [selectedTableKey, setSelectedTableKey] = useState(
    null as unknown as number
  );
  const [resetConfirm, setResetConfirm] = useState(false);

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
  };

  //设置标特操作提示框文案
  const setTableConfirmText = (type: string, data: { userCount: number, userGroupCount: number } | any) => {
    switch (type) {
      case "e":
        setTableConfig({
          title: "确定启用此角色吗？",
          okText: "确定",
          cancelText: "取消",
          description: "",
          type,
        });
        break;
      case "s":
        setTableConfig({
          title: "确定停用此角色吗？",
          okText: "确定",
          cancelText: "取消",
          description: () => (
            <div>
              此角色当前已关联账号 <span style={{ color: "var(--text-font-color7)" }}>{data.userCount}</span> 个，用户组 <span style={{ color: "var(--text-font-color7)" }}>{data.userGroupCount}</span> 个
            </div>
          ),
          type,
        });
        break;
      case "d":
        setTableConfig({
          title: "确定删除此角色吗？",
          okText: "确定",
          cancelText: "取消",
          description: () => (
            <div>
              此角色当前已关联账号 <span style={{ color: "var(--text-font-color7)" }}>{data.userCount}</span> 个，用户组 <span style={{ color: "var(--text-font-color7)" }}>{data.userGroupCount}</span> 个
            </div>
          ),
          type,
        });
        break;
      default:
        break;
    }
  };

  const handleConfirmClick = (item: RoleListVO, type: string) => {
    switch (type) {
      case "e":
        changeRoleStatus({
          roleId: item.roleId,
          status: item.status === "0" ? "1" : "0",
        });
        break;
      case "s":
        changeRoleStatus({
          roleId: item.roleId,
          status: item.status === "0" ? "1" : "0",
        });
        break;
      case "d":
        deleteItem(item.roleId);
        break;
      default:
        break;
    }
  };

  const handleCancelClick = (item: RoleListVO, type: string) => {
    setResetConfirm(false);
  };

  const getTableOperationButtonList = (item: RoleListVO) => {
    const store = []

    if (checkPermissions(SYS_KEY.ROLE_SAVE)) {
      store.push({
        key: "empower",
        label: (
          <a
            type="link"
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              empowerItem(item);
            }}
          >
            授权
          </a>
        ),
      })
    }
    if (checkPermissions(SYS_KEY.ROLE_EDIT)) {
      store.push({
        key: "edit",
        label: (
          <a
            type="link"
            style={{ color: item.roleId === 1 ? 'var(--text-font-color10)' : 'var(--text-font-color9)', cursor: item.roleId === 1 ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              if (item.roleId !== 1) {
                editItem(item);
              }
            }}
          >
            编辑
          </a>
        ),
      })
      store.push({
        key: "status",
        label: (
          <a
            style={{ color: item.roleId === 1 ? 'var(--text-font-color10)' : 'var(--text-font-color9)', cursor: item.roleId === 1 ? 'not-allowed' : 'pointer' }}
            onClick={(e) => {
              if (item.roleId !== 1) {
                getRoleCountUser(item, item.status === "0" ? "stop" : "enable");
                e.nativeEvent.stopPropagation();
              }
            }}
          >
            {item.status === "0" ? "停用" : "启用"}
          </a>
        ),
      })
    }
    if (item.status === "1" && checkPermissions(SYS_KEY.ROLE_REMOVE)) {
      store.push({
        key: "delete",
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={(e) => {
              getRoleCountUser(item, 'delete');
              e.nativeEvent.stopPropagation();
            }}
          >
            删除
          </a>
        ),
      });
    }
    return store;
  };

  //获取账号状态
  const getRoleStatus = (status: number) => {
    switch (status) {
      case RoleStatusEnum.ENABLE:
        return RoleStatusTextEnum.ENABLE_TEXT;
      case RoleStatusEnum.DISABLED:
        return RoleStatusTextEnum.DISABLED_TEXT;
      default:
        break;
    }
  };

  // 状态列角色状态修改
  const handleRoleStatusChange = async (value: string, item: RoleListVO) => {
    setTablePopConfirm(true)
    const { data } = await roleCountUser(item.roleId);
    setSelectedTableKey(item.roleId)
    var text = value == '1' ? 's' : 'e'
    setTableConfirmText(text, data)
  };
  //列表中二次确认框 取消之后
  const handleCancelClickPop = () => {
    setTablePopConfirm(false)
    setSelectedTableKey(null as unknown as number)
  }
  //列表中二次确认框 确认之后
  const handleConfirmClickPop = async (item: any, value: any) => {
    var stat = value == 's' ? '1' : '0'
    const param: { roleId: number; status: string } = {
      roleId: item.roleId,
      status: stat,
    };
    changeRoleStatus(param);
  }

  // 启用 & 禁用
  const changeRoleStatus = async (param: {
    roleId: number;
    status: string;
  }) => {
    if (responseFinished) {
      message.warning('数据正在处理，请勿重复操作');
      return
    }
    setResponseFinished(true);
    const res = await changeStatus(param);
    if (res) {
      handleCancelClickPop()
      getRoleListData(
        { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
        searcParam,
        sortValue
      );
      setResetConfirm(false);
      setResponseFinished(false);
    } else {
      setResponseFinished(false);
    }
  };

  const columns: TableProps<RoleListVO>["columns"] = [
    {
      title: "角色ID",
      dataIndex: "roleId",
      key: "roleId",
      width: 100
    },
    {
      title: "角色名称",
      dataIndex: "roleName",
      key: "roleName",
      width: 250
    },
    {
      title: "角色状态",
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (_: any, record: RoleListVO) => (
        <div>
          {/* <Select
            disabled={record.roleId == 1}
            value={getRoleStatus(parseInt(_))}
            style={{ width: "70px", height: "22px" }}
            className={styles[`accountStatusType${_}`]}
            onSelect={(value, options) => {
              handleRoleStatusChange(value, record);
            }}
            options={[
              {
                value: RoleStatusEnum.ENABLE,
                label: RoleStatusTextEnum.ENABLE_TEXT,
                disabled: record.status === '0',
              },
              {
                value: RoleStatusEnum.DISABLED,
                label: RoleStatusTextEnum.DISABLED_TEXT,
                disabled: record.status === '1',
              },
            ]}
          /> */}
          {parseInt(_) === RoleStatusEnum.ENABLE && <Switch checkedChildren={RoleStatusTextEnum.ENABLE_TEXT} unCheckedChildren={RoleStatusTextEnum.DISABLED_TEXT} value={parseInt(_) === RoleStatusEnum.ENABLE} disabled={record.roleId == 1 || !checkPermissions(SYS_KEY.ROLE_EDIT)} onChange={(checked: boolean) => { handleRoleStatusChange(parseInt(_) === RoleStatusEnum.ENABLE ? RoleStatusEnum.DISABLED.toString() : RoleStatusEnum.ENABLE.toString(), record) }} />}
          {parseInt(_) !== RoleStatusEnum.ENABLE && <Switch checkedChildren={RoleStatusTextEnum.ENABLE_TEXT} unCheckedChildren={RoleStatusTextEnum.DISABLED_TEXT} value={parseInt(_) === RoleStatusEnum.ENABLE} disabled={record.roleId == 1 || !checkPermissions(SYS_KEY.ROLE_EDIT)} onChange={(checked: boolean) => { handleRoleStatusChange(parseInt(_) === RoleStatusEnum.ENABLE ? RoleStatusEnum.DISABLED.toString() : RoleStatusEnum.ENABLE.toString(), record) }} />}

          <Popconfirm
            placement="top"
            title={tableConfig.title}
            okText={tableConfig.okText}
            cancelText={tableConfig.cancelText}
            description={tableConfig.description}
            onConfirm={() => handleConfirmClickPop(record, tableConfig.type)}
            onCancel={handleCancelClickPop}
            open={selectedTableKey === record.roleId && tablePopConfirm}
            onPopupClick={(e) => {
              e.stopPropagation();
            }}
          >
          </Popconfirm>
        </div>
      ),
    },
    {
      title: "角色说明",
      dataIndex: "remark",
      key: "remark",
      // ellipsis: true,
      render: (_: any, record: RoleListVO) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      sorter: true,
      width: 250
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
      sorter: true,
      width: 250
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{ items: getTableOperationButtonList(record) }}
            trigger={["click"]}
          >
            <Popconfirm
              placement="right"
              title={tableConfig.title}
              okText={tableConfig.okText}
              cancelText={tableConfig.cancelText}
              description={tableConfig.description}
              onConfirm={() => handleConfirmClick(record, tableConfig.type)}
              onCancel={() => handleCancelClick(record, tableConfig.type)}
              open={selectedTableKey === record.roleId && resetConfirm}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              {record.roleId != 1 && 
                <div 
                    onMouseLeave={handleMouseLeavePoint}
                    onMouseEnter={()=>handleMouseEnterPoint(record)}
                    className={styles[`line-three-point`]}
                    style={{backgroundColor:checkUserId == record.roleId ? iconColorPointBack:''}}
                >
                    <EllipsisOutlined 
                        style={{
                            color: checkUserId == record.roleId ?'var(--text-font-color33)':'var(--text-font-color34)',
                            cursor:'pointer'
                        }}
                    />
                </div>
              }
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "updateTime",
    isAsc: "desc",
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }

    getRoleListData(
      { pageSize: pagination.pageSize, pageNum: pagination.current },
      searcParam,
      sortStore
    );
  };

  // 获取角色列表
  const getRoleListData = async (
    page: { pageSize: number; pageNum: number },
    data: any,
    sort: { orderByColumn: string; isAsc: string }
  ) => {
    const param = {
      roleName: data.roleName,
      status: data.status,
      beginTime: data.createTimeStart,
      endTime: data.createTimeEnd,
      ...page,
      ...sort,
    } as RoleListQuery;
    const { total, rows } = await getRoleList(param);
    setScrollY(getTableScroll())
    setListData(rows);
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
    setSortValue({
      orderByColumn: sort.orderByColumn,
      isAsc: sort.isAsc,
    });
  };

  // 角色关联的用户数量和用户组数量
  const getRoleCountUser = async (item: RoleListVO, f: string) => {
    setSelectedTableKey(item.roleId);
    if (f === 'enable') {
      setTableConfirmText("e", {});
    } else {
      const { data } = await roleCountUser(item.roleId);
      if (f === 'delete') {
        setTableConfirmText("d", data);
      } else {
        setTableConfirmText("s", data);
      };
    };
    setResetConfirm(true);
  };

  // 新建角色
  const [flag, setFlag] = useState("");
  const [creatRoleOpen, setCreatRoleOpen] = useState(false);
  const [editData, setEditData] = useState({} as RoleListVO);

  // 获取新建角色抽屉状态
  const getOpenStatus = (v: boolean) => {
    setCreatRoleOpen(v);
  };

  // 新建/编辑角色成功回调
  const submitSuccess = () => {
    // filterViewRef.current?.showFilterView();
    // searchIconInputRef.current?.packUp();
    if (flag === "create") {
      getRoleListData(
        { pageSize: 10, pageNum: 1 },
        { ...searcParamInt },
        { orderByColumn: "createTime", isAsc: "desc" }
      );
    } else {
      getRoleListData(
        { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
        searcParam,
        sortValue
      );
    }
  };

  // 授权
  const [empowerOpen, setEmpowerOpen] = useState(false);

  //  获取授权抽屉开关状态
  const getEmpowerOpenStatus = (v: boolean) => {
    setEmpowerOpen(v);
  };

  // 授权提交成功回调
  const empowerSubmitSuccess = () => {
    getRoleListData({ pageSize: 10, pageNum: 1 }, searcParam, sortValue);
  };
  //过滤器
  const [iconColor, setIconColor] = useState('var(--text-font-color4)');
  const handleMouseEnter = () => {
    // console.log('鼠标移入')
    setIconColor('var(--text-font-color9)');
  };
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setIconColor('var(--text-font-color4)');
  };
  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i:any) => {
      setCheckUserId(i.roleId)
      setIconColorPointBack('var(--text-font-color35)');
  };
  const handleMouseLeavePoint = () => {
      setCheckUserId('')
      setIconColorPointBack('');
  };
  const [scrollY, setScrollY] = useState("")
  const getHeight = (e: string) => {
    console.log(e);
    setScrollY(e)
  }
  useEffect(() => {
    getRoleListData(
      { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
      searcParam,
      sortValue
    );
    document.addEventListener("click", hidden);

    return () => {
      document.removeEventListener("click", hidden);
    };
  }, []);

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="角色管理" />
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            ref={searchIconInputRef}
            onSearch={(e: string) => {
              const store: SearcParamType = {
                ...searcParamInt,
                roleName: e,
              };
              filterViewRef.current?.resetData();
              setSearcParam(store);
              getRoleListData({ pageSize: 10, pageNum: 1 }, store, {
                orderByColumn: "createTime",
                isAsc: "desc",
              });
            }}
            placeholder="请输入角色名称/ID"
          ></SearchIconInput>
          <Tooltip placement="bottom" title={'过滤器'}>
            <Button
              onClick={() => filterViewRef.current?.showFilterView()}
              icon={
                <FilterOutlined
                  style={{
                    color: iconColor,
                    fontSize: 'var(--text-font7)',
                  }}
                />
              }
              type="text"
              className="click-element"
              style={{ marginRight: '10px' }}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
          </Tooltip>
          <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span>

          <ButtonPermissions
            type="primary"
            onClick={() => {
              setFlag("create");
              setCreatRoleOpen(true);
            }}
            title={`新建角色`}
            permissions={SYS_KEY.ROLE_ADD}
          ></ButtonPermissions>
        </div>
      </div>
      <div className={styles.roleManagement}>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          getHeight={getHeight}
        ></FilterView>
        <div className={styles.tableBox}>
          <Table
            columns={columns}
            dataSource={listData}
            rowKey={record => record.roleId}
            scroll={{ x: 750, y: scrollY }}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
        </div>
        <CreateRole
          creatRoleOpen={creatRoleOpen}
          flag={flag}
          editData={editData}
          getOpenStatus={getOpenStatus}
          submitSuccess={submitSuccess}
        />
        <RoleEmpower
          empowerOpen={empowerOpen}
          editData={editData}
          getEmpowerOpenStatus={getEmpowerOpenStatus}
          empowerSubmitSuccess={empowerSubmitSuccess}
        />
      </div>
    </div>
  );
};

export default RoleManagement;
