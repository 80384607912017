import React, { useState, useEffect, useRef } from "react";
import { Drawer, Button, Space, message, Tree } from "antd";
import type { DrawerProps, TreeDataNode } from "antd";
import { getRouters, authorizations } from "../../../../../../api/role/role";
import { RoleListVO } from "../../../../../../api/role/types";
import styles from "./index.module.scss";
import _ from 'lodash'


const CheckMenu: any = {}

const RoleEmpower = (props: {
  empowerOpen: boolean;
  editData: RoleListVO;
  getEmpowerOpenStatus: Function;
  empowerSubmitSuccess: Function;
}) => {
  const {
    empowerOpen,
    editData,
    getEmpowerOpenStatus = () => { },
    empowerSubmitSuccess = () => { },
  } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);


  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };


  //选择按钮权限处理树结构
  const checkButtonPermission = (checkedKeysValue: any, e: any, arr: any[]) => {
    let item1 = treeData[parseInt(arr[1])];
    let item2;
    let item3
    let item4
    if (item1.children) {
      item2 = item1.children[parseInt(arr[2])]
    }
    if (item2?.children) {
      item3 = item2.children[parseInt(arr[3])]
    }
    if (item3?.children) {
      item4 = item3.children[parseInt(arr[4])]
    }
    const key = item4?.key.toString()
    if (CheckMenu[key || '']) {
      console.log('有查看');
      let number = 0;
      let forthArr = item4?.children as any[]
      if (item4?.children && parseInt(arr[5]) !== 0 && !checkedKeysValue.includes(item4.children[0].key)) {
        checkedKeysValue.push(item4.children[0].key)
      }
      forthArr.forEach((item: any, index: number) => {
        if (checkedKeysValue.includes(item.key)) {
          number++
        }
      })
      if (number > 1) {
        if (item4?.children) {
          item4.children[0].disableCheckbox = true
        }
      } else {
        if (item4?.children) {
          item4.children[0].disableCheckbox = false
        }
      }
    }
    return checkedKeysValue
  }

  const checkParentNode = (checkedKeysValue: any, e: any, arr: any[]) => {
    let item = {};
    if (arr.length === 2) {
      item = treeData[0]
    } else if (arr.length === 3) {
      const item1 = treeData[0]
      item = selectChildrenNode(item1, parseInt(arr[2]))
    } else if (arr.length === 4) {
      const item1 = treeData[0]
      const item2 = selectChildrenNode(item1, parseInt(arr[2]))
      item = selectChildrenNode(item2, parseInt(arr[3]))
    } else if (arr.length === 5) {
      const item1 = treeData[0]
      const item2 = selectChildrenNode(item1, parseInt(arr[2]))
      const item3 = selectChildrenNode(item2, parseInt(arr[3]))
      item = selectChildrenNode(item3, parseInt(arr[4]))
    }
    checkedKeysValue = clearTreeData(item, checkedKeysValue)

    return checkedKeysValue
  }

  const clearTreeData = (item: any, checkedKeysValue: any) => {
    if (CheckMenu[item.key]) {
      if (checkedKeysValue.includes(item.key)) {
        item.children[0].disableCheckbox = true
      } else {
        item.children[0].disableCheckbox = false
        checkedKeysValue = checkedKeysValue.filter((key: string) => key !== item.key && key !== item.children[0].key);
        console.log(item);
      }
    }
    if (item.children) {
      item.children.forEach((itm: any) => {
        checkedKeysValue = clearTreeData(itm, checkedKeysValue)
      })
    }
    // console.log(checkedKeysValue);

    return checkedKeysValue
  }

  const selectChildrenNode = (item: any, index: number) => {
    if (item.children) {
      return item.children[index]
    }
  }

  const onCheck = (checkedKeysValue: any, e: any) => {
    const { pos } = e.node
    const arr: any[] = pos.split('-')
    if (arr.length === 6) {
      checkedKeysValue = checkButtonPermission(checkedKeysValue, e, arr)
    } else {
      checkedKeysValue = checkParentNode(checkedKeysValue, e, arr)
    }
    console.log(checkedKeysValue);

    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  // 关闭抽屉
  const onClose = () => {
    getEmpowerOpenStatus(false);
  };

  // 获取部门参数 
  const [treeData, setTreeData] = useState([] as TreeDataNode[]);
  const getRoutersList = async () => {
    const { data } = await getRouters(editData.roleId);
    const checkedItems: any = data.checkedKeys;
    const items: any = [];
    const arr = _.filter(data.menus, function (o: any) { return o.display === '0'; })
    arr.forEach((v: any, i: number) => {
      v.title = v.label;
      v.key = String(v.id);
      if (checkedItems.length > 0 && checkedItems.includes(v.id) && v.display === '0' && (!v.children || v.children.length < 1)) {
        items.push(v.key);
      };
      if (v.children && v.children.length > 0) {
        v.children = _.filter(v.children, function (o: any) { return o.display === '0'; })
        v.children.forEach((p: any, q: number) => {
          p.title = p.label;
          p.key = `${v.id}-${p.id}`;
          if (checkedItems.length > 0 && checkedItems.includes(p.id) && p.display === '0' && (!p.children || p.children.length < 1)) {
            items.push(`${v.id}-${p.id}`);
          };
          if (p.children && p.children.length > 0) {
            p.children = _.filter(p.children, function (o: any) { return o.display === '0'; })
            p.children.forEach((m: any, n: number) => {
              m.title = m.label;
              m.key = `${v.id}-${p.id}-${m.id}`;
              if (checkedItems.length > 0 && checkedItems.includes(m.id) && m.display === '0' && (!m.children || m.children.length < 1)) {
                items.push(`${v.id}-${p.id}-${m.id}`);
              };
              if (m.children && p.children.length > 0) {
                let hasCheckPermision = false
                let checkIndex = -1
                m.children = _.filter(m.children, function (o: any) { return o.display === '0'; })
                m.children.forEach((s: any, k: number) => {
                  s.title = s.label;
                  s.key = `${v.id}-${p.id}-${m.id}-${s.id}`;
                  if (checkedItems.length > 0 && checkedItems.includes(s.id) && s.display === '0' && (!s.children || s.children.length < 1)) {
                    items.push(`${v.id}-${p.id}-${m.id}-${s.id}`);
                    console.log(m.children[checkIndex]);
                    if (checkIndex > -1)
                      m.children[checkIndex].disableCheckbox = true
                  };
                  if (hasCheckPermision) {
                    CheckMenu[m.key].otherKey.push(s.key)

                  }
                  if (s.title === '查看') {
                    hasCheckPermision = true;
                    CheckMenu[m.key] = {
                      checkKey: s.key,
                      otherKey: [],
                    }
                    checkIndex = k
                  }
                });
              }
            })
          }
        })
      };
    });


    const store: any = [
      {
        title: "全选",
        key: "0",
        children: arr
      }
    ]
    console.log(arr);


    console.log(items);


    setCheckedKeys(items)
    setTreeData(store);
    let defaultExpandedKeys: React.Key[] = [];
    defaultExpandedKeys = ['0'];
    setExpandedKeys(defaultExpandedKeys);
  };

  // 全选
  const selectAll = () => {
    const selectedStore: any = [];
    treeData.forEach((v: any, i: number) => {
      selectedStore.push(v.key);
      if (v.children && v.children.length > 0) {
        v.children.forEach((p: any, q: number) => {
          selectedStore.push(p.key);
          if (p.children && p.children.length > 0) {
            p.children.forEach((m: any, n: number) => {
              selectedStore.push(m.key);
              if (m.children && p.children.length > 0) {
                m.children.forEach((s: any, k: number) => {
                  selectedStore.push(s.key);
                });
              };
            });
          }
        });
      }
    });
    setCheckedKeys(selectedStore);
  };

  // 提交
  const submit = async () => {

    let store1: string[] = [];
    checkedKeys.forEach((v: any, i) => {
      store1 = [...store1, ...String(v).split('-')];
    });
    const store2 = store1.map((str) => {
      return parseInt(str);
    });
    const menuIdsStore = Array.from(new Set(store2));
    const param = {
      roleId: editData.roleId,
      menuIds: menuIdsStore,
    };
    const res = await authorizations(param);
    if (res) {
      message.success("授权成功");
      getEmpowerOpenStatus(false);
      setExpandedKeys([]);
      setCheckedKeys([]);
    };
  };

  useEffect(() => {
    if (empowerOpen) {
      getRoutersList();
    } else {
      setCheckedKeys([]);
      setExpandedKeys([]);
    };
  }, [empowerOpen]);

  return (
    <div>
      <Drawer
        title="授权"
        placement={placement}
        width={400}
        onClose={onClose}
        open={empowerOpen}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button
              type="primary"
              disabled={checkedKeys.length === 0}
              onClick={submit}
            >
              确定
            </Button>
          </Space>
        }
      >
        <Tree
          checkable
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          treeData={treeData}
        />
      </Drawer>
    </div>
  );
};

export default RoleEmpower;
