import { AxiosPromise } from 'axios';
import request from '../network'
import { AccountListQuery,  AccountListQueryVO ,UserListQuery, UserListVO } from './types';

// 获取用户列表
export function getUserList(query: UserListQuery): AxiosPromise<UserListVO[]> {
  return request({
      url: `/label/label/getLabelUserList`,
      method: 'POST',
      data: query,
  })
}
// 获取用户列表 分群
export function getCrowdUserList(query: UserListQuery): AxiosPromise<UserListVO[]> {
  return request({
      url: `/label/crowd/user/details`,
      method: 'POST',
      data: query,
  })
}
// 获取会员卡
export function getMemberCard(): AxiosPromise<any> {
  return request({
      url: `/label/label/getMemberCardProfile`,
      method: 'POST',
  })
}
// 获取会员等级
export function getGradeProfile(query: any): AxiosPromise<any> {
  return request({
      url: `/label/label/getGradeProfile`,
      method: 'POST',
      data: query,
  })
}
// 获取渠道
export function getChannelProfile(): AxiosPromise<any> {
  return request({
      url: `/label/label/getChannelProfile`,
      method: 'POST',
  })
}


// 获取用户组账号列表
export function getAccountList(query: AccountListQuery): AxiosPromise<AccountListQueryVO[]> {
  return request({
      url: '/system/sysUserGroup/selectUserGroupUser',
      method: 'get',
      params: query,
  })
}
// 下载参数选择列表
export function getExportNameList(): AxiosPromise<any> {
  return request({
      url: '/label/crowd/get/export/name',
      method: 'get',
  })
}
// 分群用户明细下载
export function getUploadTask(query: any): AxiosPromise<any> {
  return request({
      url: '/label/crowd/add/upload/task',
      method: 'POST',
      data: query,
  })
}
// 标签用户明细下载
export function getLabelUploadTask(query: any): AxiosPromise<any> {
  return request({
      url: '/label/label/add/upload/task',
      method: 'POST',
      data: query,
  })
}

// 获取下载excel加密方式
export function getEncryptTypeListApi(): AxiosPromise<any> {
  return request({
      url: '/lz-cdp-data-collect/customer/getEncryptTypeList',
      method: 'get',
  })
}


export default {}