import React, { useState, useEffect, useRef ,forwardRef ,useImperativeHandle } from "react";
import {
  Table,
  Button,
  Tooltip,
  Input,
  Modal,
  Dropdown,
  Space,
  Select,
  Collapse,
  message,
  Tag,
  Tabs,
} from "antd";
import { EditOutlined , CopyOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from '@ant-design/icons';
import type { CollapseProps } from 'antd';
import { state } from '../../../../../../store';
import copyToClipboard from "../../../../../../crm/assets/public/clipboard";
import EditMes from "./components/EditUserMes";
import headerImage from '../../../../../../assets/images/headerImage.png';
import VipheadIcon from '../../../../../../assets/images/VipheadIcon.png';
import { UpOutlined , DownOutlined , CheckCircleFilled , MinusCircleFilled } from '@ant-design/icons';
import style from "./index.module.scss";
const { Option } = Select;
const { Search } = Input;
let UserMes = forwardRef((props: {
  peopleUserListData?: any,
  channelMesList?: any[],
  userTypeTabs: string,
}, ref: any) => {
  // 暴露方法给父组件
  useImperativeHandle(ref, () => ({
  }))
  const channelMesList:any = props.channelMesList;
  const peopleUserListData:any = props.peopleUserListData;
  const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId'):'';
  //抽屉  true  开启编辑信息
  const [openEditMes, setOpenEditUserMes] = useState(false);
  const peopleUserList:any = [
    {name:'手机号：',value: props.peopleUserListData.aesPhone},
    // {name:'会员身份',value: ''},
    // {name:'会员等级',value:''},
    // {name:'首次入会渠道',value: ''},
    // {name:'首次入会时间（年）',value: ''},
    // {name:'首次入会时间（月）',value: ''},
    {name:'客户活跃度：',value: props.peopleUserListData.activeType},
    {name:'常用收货省份：',value: props.peopleUserListData.receiveProvince},
    {name:'常用收货城市：',value: props.peopleUserListData.receiveCity},
    {name:'购买间隔：',value: props.peopleUserListData.purchase},
    // {name:'真实姓名：',value: props.peopleUserListData.customerName},
    // {name:'性别：',value: props.peopleUserListData.gender},
    // {name:'年龄：',value: props.peopleUserListData.age},
    // {name:'OneID',value: props.peopleUserListData.oneId},
];
  const setUserType = (key:any) => {
    console.log("setUserType",key);
  }
  // 打开编辑信息抽屉
  const editUserMes = () => {
    // setOpenEditUserMes(true);
  }
  // 关闭编辑信息抽屉
  const closeEditMes = (v: boolean) => {
    setOpenEditUserMes(v);
  };
  // 带有粘贴功能的文本
  const handCopy = (text:any) => {
    copyToClipboard(text)
      .then(() => {
        message.success("复制成功");
      })
      .catch((error) => {
        message.error("复制成功");
      });
  };
  useEffect(() => {
    // getChannelMes()
    return () => {
    };
  }, []);

  return (
    <div className={style.UserMes}>
      <div className={style['left-aside']}>
          <div className={style['left-aside-container']}>
              <div className={style['left_aside_main']}>
                  {!state.user.avatar && <img src={headerImage} alt="" className={style['lzHeader_avatar']} />}
                  {state.user.avatar && <img src={state.user.avatar} alt="" className={style['lzHeader_avatar']}/>}
                  <div className={style['UserDeatil_idText']}>
                    <div style={{maxWidth: '95px'}}>OneID：</div>
                    <Tooltip placement="top" title={oneId}>
                      <div className={style['UserDeatil_idText-oneid']}>{oneId}</div>
                    </Tooltip>
                    {oneId && <CopyOutlined className={style['UserDeatil_Itm-iconCopy']} onClick={()=>handCopy(oneId)}/>}
                  </div>
                  <div style={{marginTop:'9px',display:'flex'}}>
                    {/* 用户身份 */}
                    {props.peopleUserListData.identity && <Tag color="blue">{props.peopleUserListData.identity}</Tag>}
                    {peopleUserListData.customerMemberVoList && <>
                      <Tooltip placement="top" title={props.peopleUserListData.gradeName} >
                        {/* 最高等级会员卡 */}
                      {props.peopleUserListData.gradeName &&<Tag color="blue">
                        <div style={{display:'flex',alignItems:'center',cursor:'pointer'}}>
                          {props.peopleUserListData.gradeImgUrl && <img src={props.peopleUserListData.gradeImgUrl} className={style['UserDeatil_mesImg']} alt="" />}
                          <div className={style['UserDeatil_mes-text']}>{ props.peopleUserListData.gradeName}</div>
                        </div>
                        </Tag>}
                      </Tooltip>
                    </>}
                  </div>
                  {/* 个人信息 */}
                  <div className={style['UserDeatil_ItemBox']}>
                    <div  className={style['UserDeatil_top-mes']}>
                      <div style={{fontWeight:'500px'}}>个人信息</div>
                      {/* <div>
                        <EditOutlined className={style['UserDeatil_changeMes-icon1']} onClick={editUserMes}></EditOutlined>
                      </div> */}
                    </div>
                    {peopleUserList.map((item:any, index:any) => (
                        <div className={style['UserDeatil_Item']} key={index}>
                            <div className={style['UserDeatil_Item-title']}>{item.name}</div>
                            <div className={style['UserDeatil_Item-text']}>{item.value?item.value:'-'}</div>
                        </div>
                    ))}
                  </div>
                  {/* 渠道信息 */}
                  {channelMesList && channelMesList.length > 0 && <div className={style['UserDeatil_ItemBox']}>
                    <div  className={style['UserDeatil_top-mes']}>
                      <div style={{fontWeight:'500px'}}>渠道信息</div>
                    </div>
                    <div style={{marginTop:'10px',width:'100%'}}>
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['0','1','2','3']}
                        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                        // style={{ background: token.colorBgContainer }}
                        items={channelMesList}
                        />
                    </div>
                  </div>}
              </div>
          </div>
          <div>
          </div>
      </div>
      <EditMes
        openEditMes={openEditMes}
        closeEditMes={closeEditMes}
        // refirmTabel={refirmTabel}
        // checkFieldList={checkFieldList}
        // popType={popType}
      />
    </div>
  );
});

export default UserMes;
