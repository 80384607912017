import { useState, useEffect, useRef } from 'react'
import ModuleTitle from "../../../component/ModuleTitle";
import styles from '../DimensionalModel/index.module.scss'
import { DimensionColumnsType } from './type/data'
import { Table, Button, TableColumnsType, TableProps, } from "antd";
import SearchIconInput from '../../../component/Input/SearchIconInput/searchIconInput'
import FilterIcon from '../../../component/FilterIcon/filterIcon'
import FilterView from '../../../component/FilterView/filterView'
import TableItem from './components/TableItem/tableItem'
import { getDimensionListApi } from '../../../api/dimensionModel/dimensionModel'
import { EditableRow, EditableCell } from './components/EditableContext/editable'
import { getTableScroll } from '@/store/index'

// 列表排序
let createSort = '';
let updateSort = ''

type EditableTableProps = Parameters<typeof Table>[0];
interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const DimensionModel = () => {
  const filterViewRef: any = useRef(null);
  const [dimensionList, setDimensionList] = useState([] as DimensionColumnsType[]);
  const [isShowTable, setIsShowTable] = useState(false)
  const [scrollY, setScrollY] = useState("")
  // 分页
  const [pageQuery, setPageQuery] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0
  })

  // 查询入参
  let searchListData: any = {}
  // 搜索条件列表
  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入表名或展示名",
      title: "字段",
      key: "tableNameOrShowName",
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "创建时间",
      key: "createStartTime-createEndTime",
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "更新时间",
      key: "updateStartTime-updateEndTime",
    },
  ]
  const defaultColumns = [
    {
      title: '序号',
      key: 'index',
      dataIndex: 'index',
      width: '88px',
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: '表名',
      key: 'tableNameEn',
      dataIndex: 'tableNameEn',
      width: '200px',
      render: (text: string, record: DimensionColumnsType) => <div onClick={() => checkTable(record)} className={styles.tableName}>{text}</div>,
    },
    {
      title: '展示名',
      key: 'tableNameCn',
      dataIndex: 'tableNameCn',
      // editable: true,
      width: '200px',
    },
    {
      title: '描述',
      key: 'tableDescribe',
      dataIndex: 'tableDescribe',
      // editable: true,
      width: '250px',
    },
    {
      title: '创建人',
      key: 'createBy',
      dataIndex: 'createBy',
      width: '200px',
    },
    {
      title: '创建时间',
      key: 'createTime',
      dataIndex: 'createTime',
      width: '200px',
      sorter: true,
    },
    {
      title: '更新时间',
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: '200px',
      sorter: true,
    },
    {
      title: '操作',
      key: "action",
      width: '88px',
      render: (_: any, record: DimensionColumnsType) => (
        <Button type="link" size='middle' onClick={() => checkTable(record)}>
          查看
        </Button>
      )
    },
  ];
  // const components = {
  //   body: {
  //     row: EditableRow,
  //     cell: EditableCell,
  //   },
  // };
  // const handleSave = (row: DataType) => {
  //   const newData = [...dimensionList];
  //   const index = newData.findIndex((item) => row.key === item.key);
  //   const item = newData[index];
  //   newData.splice(index, 1, {
  //     ...item,
  //     ...row,
  //   });
  //   setDimensionList(newData);
  // };
  // const columns = defaultColumns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record: DataType) => ({
  //       record,
  //       editable: col.editable,
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       handleSave,
  //     }),
  //   };
  // });
  const [currentCheckData, setCurrentCheckData] = useState({} as DimensionColumnsType)
  const checkTable = (info: DimensionColumnsType) => {
    setCurrentCheckData(info)
    setIsShowTable(true)
    
  }

  const handelTableChange = (page: any, filters: any, sort: any) => {
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize
    })
    if (sort.order) {
      if (sort.columnKey === 'createTime') {
        createSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        updateSort = ''
      } else {
        updateSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        createSort = '';
      }
    } else {
      createSort = '';
      updateSort = ''
    }
    
    getUserData({pageNum: page.current, pageSize: page.pageSize});

  }
  const filterFinish = (data: any) => {
    searchListData = data;
    getUserData({pageNum: 1, pageSize: 10})
  }
  // 查询列表
  const getUserData = async(pages: any) => {
    const res = await getDimensionListApi({
      ...pages,
      ...searchListData,
      createOrder: createSort,
      updateOrder: updateSort,
      // modelType: 'DIMENSION',
    })
    if (res) {
      setDimensionList(res.data.rows)
      setPageQuery({
        pageSize: pages.pageSize,
        pageNum: pages.pageNum,
        total: res.data.total,
      });
      setScrollY(getTableScroll())
      // state.tableScrollY = getTableScroll()
      
    }
  }
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  }
  const checkBack = () => {
    setIsShowTable(false)
  }
  const getHeight = (e: string) => {
    setScrollY(e)
  }
  useEffect(() => {
    getUserData({pageNum: 1, pageSize: 10})
  }, [])

  return (
    <div>
    {
      isShowTable? <TableItem info={currentCheckData} checkBack={checkBack} /> : 
        <div>
          <div className='headBox'>
            <ModuleTitle mark="large" title="维度模型" />
            <div className={styles.searchBox}>
              <SearchIconInput
                placeholder="请输入表名称或展示名"
                onSearch={(e: string) => { searchListData.tableNameOrShowName = e; console.log(e, 'eeee'); getUserData({pageNum: 1, pageSize: 10}); filterViewRef.current?.resetData(); }}
              ></SearchIconInput>
              <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
            </div>
          </div>
          <div className='containerBox'>
            <FilterView searchList={searchList} getHeight={getHeight} ref={filterViewRef} finish={filterFinish}></FilterView>
            <div className={styles.tableBox}>
              <Table 
                // columns={columns as ColumnTypes} 
                columns={defaultColumns} 
                scroll={{ x: 1400, y: scrollY}}
                rowKey={record => record.id}
                // components={components}
                dataSource={dimensionList} 
                pagination={{
                  current: pageQuery.pageNum,
                  pageSize: pageQuery.pageSize,
                  total: pageQuery.total,
                  showTotal: (total) => `共${total}条`,
                  showSizeChanger: true,
                }}
                onChange={(page, filters, sort) => handelTableChange(page, filters, sort)} />
            </div>
            </div>
        </div>
    }
    </div>
  )
}
export default DimensionModel