import React, { ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import style from './index.module.scss';
import { Badge, Empty, Pagination, Progress, Select, SelectProps, Tooltip, message } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import TagsChart from './component/TagsChart';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getLabelUserStatApi } from '../../../../../../api/label/label';
import { setStateLabelId } from '../../../../../../store';

let listStatus = 0
let divLength = 0

let TagsData = forwardRef(
  (
    props: {
      labelId?: number | string;
      taskId?: number | string;
      // renewTime?: string;
      renewType?: string;
      calculateStatus?: string | number; //计算状态 0: 未计算 1: 计算中 2: 成功 3: 失败
    },
    ref: any,
  ) => {
    const navigate = useNavigate();

    // 列表数据
    const [dataList, setDataList] = useState([]);
    const [renewTime, setRenewTime] = useState('');

    // 查询条件
    const [listQuery, setListQuery] = useState({
      page: 1,
      pageSize: 10,
      sortType: '', //排序类型 ASC-正序 DESC-倒序
    });

    // 列表数据条数
    const [listTotal, setListTotal] = useState(0);

    const getList = async (
      page: { pageSize: number; page: number ; sortType :string},
    ) => {
      if (listStatus > 0) return;
      listStatus += 1;
      let param = {
        labelId: props.labelId,
        ...page,
      }
      setListQuery( {
        page: page.page,
        pageSize: page.pageSize,
        sortType: page.sortType,
      });
      const res = await getLabelUserStatApi(param);
      if (res) {

        res.data.statInfos.map((item: any) => {
          item.proportion = (item.proportion * 100).toFixed(2)
        })
        listStatus = 0
        setListTotal(res.data.total ?? 0);
        divLength = 0
        res.data.statInfos = res.data.statInfos.filter((item:any) => item.labelValueName )
        res.data.statInfos.map((item: any) => {
          divLength = item.labelValueName.length > divLength ? item.labelValueName.length : divLength
        })

        setDataList(res.data.statInfos ?? []);
        setRenewTime(res.data.renewTime)
      } else {
        listStatus = 0
        setListTotal(0);
        setDataList([]);
        setRenewTime('')
      }
    };

    // 选择显示的标签值范围
    const selectType: SelectProps['options'] = [
      {
        label: '显示全部',
        value: '',
      },
      {
        label: '正序前十项',
        value: 'ASC',
      },
      {
        label: '倒序前十项',
        value: 'DESC',
      },
    ];

    // 下拉选择
    const changeSelectedType = (e: any) => {
      setListQuery({
        ...listQuery,
        sortType: e,
      });
    };

    // 进度条数值
    const customProgress = (percent: any) => {
      return <>{props.calculateStatus == 2 && <span>{percent ? `${Math.round(percent)}%` : ''}</span>}</>;
    };

    const toCorrespondingPage = (item: any) => {
      // 跳转到对应的页面
      navigate(`/tages/ruleTags/tags/userList?type=0&labelId=${props.labelId}&renewTime=${renewTime}&labelValueId=${item.labelValueId}&renewType=${item.renewType}`)
    };

    // 更新查询条件的分页数据
    const upDataListQuery = async (newPage?: number, newPageSize?: number) => {
      setListQuery(prevListQuery => ({
        ...prevListQuery,
        page: newPage ?? prevListQuery.page,
        pageSize: newPageSize ?? prevListQuery.pageSize,
      }));
    };

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index: any) => {
      setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };

    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
      getList
    }));
    //监听或进入调用
    useEffect(() => {
      getList({ pageSize: listQuery.pageSize, page: listQuery.page ,sortType:listQuery.sortType});
    }, [listQuery]);

    return (
      <div className={style['container']}>
        <div className={style['container_title']}>
          <Select defaultValue={listQuery.sortType} options={selectType} style={{ width: 200 }} onChange={changeSelectedType}></Select>

          {props.calculateStatus == 2 && <div>数据更新时间：{renewTime}</div>}
          {props.calculateStatus == 3 && <span style={{ fontSize: 'var(--text-font5)' }}>
            <Badge status="error" text="计算失败" />
          </span>}
          {(Number(props.calculateStatus) < 2) && <div style={{ fontSize: 'var(--text-font5)' }}>
            <Badge color="blue" text="计算中" />
          </div>}
        </div>
        {dataList.length > 0 && props.calculateStatus != 3 &&
          <div className={style['container_list']}>
            {dataList.map((item: any, index: number) => {
              return (
                <div
                  className={style['container_list_item']}
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                // onClick={() => { toCorrespondingPage(item) }}
                >
                  <span className={style['container_list_item_name']} style={{ width: `${divLength * 12 + 10}px` }}>{item.labelValueName}</span>
                  
                    <Progress
                      className={style['container_list_item_progress']}
                      style={{ width: `calc(89% - ${divLength * 12 + 10}px)` }}
                      strokeColor={'#5B8FF9'}
                      strokeLinecap="butt"
                      percent={props.calculateStatus == 2 ? Number(item.proportion) > 0 &&  Number(item.proportion) < 3 ? 3 : item.proportion : 0}
                      size={['100%', 32]}
                      format={percent => customProgress(item.proportion)}
                    />
                  <Link style={{ color: 'var(--text-font-color3)' }} to={`/tages/ruleTags/tags/userList?type=0&labelId=${props.labelId}&renewTime=${renewTime}&labelValueId=${item.labelValueId}&renewType=${props.renewType}&taskId=${props.taskId}`} onClick={() => {
                    localStorage.setItem('setGroupDetail', '');
                    setStateLabelId(Number(props.labelId))
                  }}>
                    {(props.calculateStatus == 2) && (
                      <span style={{ fontSize: 'var(--text-font5)', color: `${hoveredIndex === index ? '#5B8FF9' : ''}`, marginLeft: '10px', cursor: 'pointer',position:'relative',zIndex:'var(--z-index2)' }}>{item.userCount}</span>
                    )}
                  </Link>
                </div>
              );
            })}
          </div>
        }
        {(!dataList || dataList.length < 1 || props.calculateStatus == 3 )&& <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {/* <TagsChart /> */}
        <div className={style['container_pagination']}>
          {listTotal > 0 && (
            <Pagination
              total={listTotal}
              pageSize={listQuery.pageSize}
              current={listQuery.page}
              showSizeChanger
              onChange={upDataListQuery}
              showTotal={total => `共 ${total} 条`}></Pagination>
          )}
        </div>
      </div>
    );
  },
);
export default TagsData;
