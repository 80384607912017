import React, { useEffect, useState } from 'react';
// import './index.css'
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { state, setSelectkeys } from '../../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion';
import { setArrow } from '../../../store';
import busEvent from './../../../utils/bus'
import style from './index.module.scss'


const SubMenu: React.FC = () => {
    const [collapsed] = useState(false);
    const [isArrow, setIsArrow] = useState(true)
    const [openKeys, setOpenKeys] = useState([] as string[])
    const [menuShow, setMenuShow] = useState(true)
    const [hasMenu, setHasMenu] = useState(false)
    const navigate = useNavigate();

    const location = useLocation();
    useEffect(() => {
        busEvent.on('menuSwitch', menuSwitch);
        menuSwitch(Boolean(state.menu[state.currentMenuIndex] && state.menu[state.currentMenuIndex].children))
        setOpenKeys(state.defaultOpenKeys)
    }, [location]);// eslint-disable-line react-hooks/exhaustive-deps
    // 点击菜单项
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        navigate(e.key)
        setSelectkeys([e.key])
    };

    const menuSwitch = (e: boolean) => {
        // if (e !== menuShow) {
        // if (!e) {
        //     setMenuShow(false)
        // } else {
        //     setTimeout(() => {
        //         setMenuShow(true)
        //     }, 300);
        // }
        setHasMenu(e)

        // }
    }

    const handleArrowClick = () => {
        if (isArrow) {
            setMenuShow(false)
        } else {
            setTimeout(() => {
                setMenuShow(true)
            }, 300);
        }
        setArrow(!isArrow)
        setIsArrow(!isArrow)
        busEvent.emit('subMenuArrow', !isArrow);
    }

    useEffect(() => {
        setOpenKeys(state.defaultOpenKeys)
    }, [])

    const openChange = (openKeys: string[]) => {
        setOpenKeys(openKeys)
    }

    return (
        <div>
            {!hasMenu && <div className={style['lz_sub-menu']} style={{ width: 0 }}>
                <Menu style={{ width: '136px', marginLeft: 8 }}
                    defaultSelectedKeys={state.selectedKeys}
                    selectedKeys={state.selectedKeys}
                    // defaultOpenKeys={state.defaultOpenKeys}
                    onOpenChange={openChange}
                    openKeys={openKeys}
                    mode="inline"
                    theme="light"
                    inlineCollapsed={collapsed}
                    items={[]}
                    className={style['lz_sub-menu-container']}
                    onClick={handleMenuClick}
                />
            </div>}
            {
                hasMenu && <motion.div className={style['lz_sub-menu']} animate={{ width: isArrow ? 152 : 16 }} transition={{ duration: 0.3 }}>
                    {menuShow && <span className={style['lz_sub-menu-title']}>{(state.menu && state.menu[state.currentMenuIndex].label) || ''}</span>}
                    {menuShow && <Menu style={{ width: '136px', marginLeft: 8 }}
                        defaultSelectedKeys={state.selectedKeys}
                        selectedKeys={state.selectedKeys}
                        // defaultOpenKeys={state.defaultOpenKeys}
                        onOpenChange={openChange}
                        openKeys={openKeys}
                        mode="inline"
                        theme="light"
                        inlineCollapsed={collapsed}
                        items={state.subMenu}
                        className={style['lz_sub-menu-container']}
                        onClick={handleMenuClick}
                    />}
                    <motion.div className='lz_sub-menu-arrow click-element' onClick={handleArrowClick} animate={{ rotate: isArrow ? 0 : 180 }} whileTap={{
                        rotate: 180,
                    }}>
                        <LeftOutlined style={{ fontSize: 'var(--text-font2)', color: 'var(--text-font-color4)' }} />
                        {/* <RightOutlined /> */}
                    </motion.div>
                </motion.div>
            }

        </div>

    );
}

export default SubMenu;