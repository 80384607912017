import React, { useEffect, useRef, useState } from 'react';
import './index.css'
import { Layout, Dropdown, MenuProps, Space, Divider, theme, Popover, Modal, Input, message, Drawer, Badge, notification, Tabs } from 'antd';
import type { DrawerProps, TabsProps  } from "antd"
import { useNavigate, useLocation } from 'react-router-dom';
import {
  CustomerServiceOutlined,
  BellOutlined,
  CloudDownloadOutlined,
  FileDoneOutlined,
  ScheduleOutlined,
  SettingOutlined,
  DownOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { setMenu, state } from '../../../store';
import UserCenterInfo from './component/UserCenterInfo';
import DownLoadCenter from './component/DownLoadCenter';
import UploadTask from './component/UploadTask';
import { getUserMessageApi, getUpdateMessageApi } from '../../../api/userIntersection/userIntersection';
// import { use } from 'echarts'

const { Header } = Layout;



const LZHeader: React.FC = () => {

  // 下载中心
  const location = useLocation()

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [downLoadCenterOpen, setDownLoadCenterOpen] = useState<boolean>(false);
  const [uploadTask, setUploadTask] = useState([] as any)
  const onClose = () => {
    setDownLoadCenterOpen(false);
  };
  const [userMessageList, setUserMessageList] = useState<any[]>([]);
  const getUserMessage = async () => {
    const res = await getUserMessageApi();
    console.log(res);
    if (res.code === 200) {
      setUserMessageList(res.data)
      if (res.data?.length > 0) {
        let tips = res.data.find((item: any) => item.topic === '激活码到期提醒');
        notification.open({
          message: tips.topic,
          description: tips.message,
          duration: 0,
          onClose: () => { getUpdateMessage(tips.id) }
        });
      }
    }
  };
  const getUpdateMessage = async (id: number) => {
    const res = await getUpdateMessageApi({ messageId: id });
    console.log(res);
    if (res.data) {
      getUserMessage()
    }
  };
  const navigate = useNavigate();
  // const currentLocation = useLocation();
  const settingItem = [
    {
      label: '实施部署',
      key: '1'
    }, {
      label: '安全配置',
      key: '2'
    }, {
      label: '隐私脱敏加密',
      key: '3'
    }, {
      label: '行业模板配置',
      key: '4'
    }, {
      label: (<a onClick={() => { navigate('/systemSettingManagement/accountAuthority/accountManagement') }}>账号权限</a>),
      key: '5'
    }
  ]
  // 消息盒子
  // const openNotification = (userMessageList:any) => {
  //   userMessageList.forEach((item:any) => {
  //     notification.open({
  //       message: item.topic,
  //       description: item.message,
  //       duration: 0,
  //       onClose:()=>{
  //         getUpdateMessage(item.id)
  //       }
  //     });
  //   });
  // };
  
  // 任务中心
  // const taskCenterTitle = 
  // <div>
  //   任务中心
    // <span style={{fontSize: '12px', color: 'var(--text-font-color4)', marginLeft: '5px'}}>包含上传，下载等任务</span>
  // </div>
  const [tabKey, setTabKey] = useState('1')
  const onChange = (key: string) => {
    // console.log(typeof key, '=============================key');
    setTabKey(key)
  };
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '下载任务',
      children: <DownLoadCenter tabKey={tabKey} downLoadCenterOpen={downLoadCenterOpen} />,
    },
    {
      key: '2',
      label: '上传任务',
      children: <UploadTask tabKey={tabKey} downLoadCenterOpen={downLoadCenterOpen} />,
    },
  ];
  useEffect(() => {
    // console.log(state.parentMenuId, 'state.parentMenuId=====================================');
    getUserMessage()
  }, [])

  return (
    <Header className='lzHeader_container' style={{ boxShadow: (location.pathname === '/home/homePage/page' || !state.parentMenuId) ? '' : 'var(--box-shadow1)' }}>
      {/* <CustomerServiceOutlined className='lzHeader_icon' /> 
                        {state.parentMenuId}
            <div>{location.pathname}</div>
          */}
      {/* <div className='lzHeader_icon'>
              <Badge count={userMessageList.length} size="small" >
                <BellOutlined  style={{color:`var(--text-font-color4)`}} onClick={()=>{if(userMessageList.length<=0){return}; openNotification(userMessageList)}} />
              </Badge>
            </div> */}
      <QuestionCircleOutlined className='lzHeader_icon' style={{ cursor: 'pointer' }} onClick={() => window.open('https://lucidata.yuque.com/vuntcs/kniivu', '_blank')} />

      <FileDoneOutlined className='lzHeader_icon' onClick={() => setDownLoadCenterOpen(true)} />
      {// <CloudDownloadOutlined className='lzHeader_icon' onClick={() => setDownLoadCenterOpen(true)} />
      }
      {/* <ScheduleOutlined className='lzHeader_icon' />
            <Dropdown menu={{ items: settingItem }}>
                <SettingOutlined className='lzHeader_icon click-element' />
            </Dropdown> */}
      <div className='click-element lzHeader_userInfo'>
        <UserCenterInfo></UserCenterInfo>
        <DownOutlined style={{ fontSize: 'var(--text-font29)', color: 'var(--text-font-color4)' }} />
      </div>
      <div>
        <Drawer
          title='任务中心'
          placement={placement}
          width={410}
          onClose={onClose}
          open={downLoadCenterOpen}
        >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Drawer>
      </div>
    </Header>
  )
}

export default LZHeader;