import { Input, Tooltip, Button } from "antd"
import React, { useState, useImperativeHandle } from "react";
import { motion } from 'framer-motion';
import {
    SearchOutlined
} from '@ant-design/icons';
import style from './searchIconInput.module.scss'
const SearchIconInput = React.forwardRef((props: {
    onSearch?: Function,
    width?: string,
    clear?:boolean,
    placeholder?: string
}, ref) => {
    const { width } = props;
    const [arrow, setArrow] = useState(false)
    
    const search = (e: any) => {
        if (props.onSearch) {
            props.onSearch(e.target.value)
        }
    }

    const packUp = () => {
        setArrow(false)
    }
    
    useImperativeHandle(ref, () => ({
        packUp
    }))
    const clickSearch = () => {
        setArrow(true)
    }
    const [iconColor, setIconColor] = useState('var(--text-font-color4)')
    const handleMouseEnter = () => {
      // console.log('鼠标移入')
      setIconColor('var(--text-font-color9)')
    }
    const handleMouseLeave = () => {
      // console.log('鼠标移出')
      setIconColor('var(--text-font-color4)')
    }
    return <motion.div animate={{
        // width: arrow ? 215 : 16
        width: arrow ? width ? width : 215 : ''
    }} className={`${style.container}`}>
        {arrow ? 
        <Input onPressEnter={search} 
            placeholder={props.placeholder || '请输入搜索内容'} 
            allowClear={props.clear??false} 
            prefix={<SearchOutlined />} 
            onBlur={() => setArrow(false)}
            autoFocus={arrow}>
        </Input> : 
        <Tooltip placement="bottom" title={'搜索'}>
          <Button onClick={clickSearch} 
            icon={<SearchOutlined style={{ color: iconColor, fontSize: 'var(--text-font7)', }} />}  
            type="text" className="click-element" onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
          </Button>
        </Tooltip>
        }
    </motion.div>
})

export default SearchIconInput