import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Divider, Steps } from 'antd';
import type { CollapseProps } from 'antd';
import { Form, Tooltip, Tag, Select, Input, Button, Space, Table, TableColumnsType } from "antd"
import FilterView from "../../../../../../component/FilterView/filterView";
import { Collapse } from 'antd';
import { motion } from 'framer-motion';
import { FilterOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import style from './index.module.scss'
import {
    getShopList,
    getChannelListApi,
    getOrderInfo,
    getOrderStatusEnum
} from "../../../../../../api/UserInsight/UserInsight";
import orderBuyIcon from "../../../../../../assets/images/order-icon2.png";
import orderBuyNearIcon from "../../../../../../assets/images/order-icon1.png";
import _ from "lodash";

const text = `435435435435`;
let Resume = forwardRef((props: {
}, ref: any) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        getUserOrderList,
        resetFilter
    }))
    const [form] = Form.useForm();
    const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId') : '';
    const [peopleUserOptions, setPeopleUserOptions] = useState([]);
    //渠道数据
    const [channeldData, setChanneldData] = useState([] as any);
    //table 展示状态
    const [tableStatus, setTableStatus] = useState(false as boolean);
    //店铺下拉框数据
    const [shoplistData, setShoplistData] = useState([] as any);
    //总数据
    const [orderInfo, setOrderInfo] = useState({} as any);
    const [peopleUserList, setPeopleUserList] = useState(
        [
            {
                name: '累计消费金额',
                value: 0
            },
            {
                name: '累计下单次数',
                value: 0
            },
            {
                name: '笔单价',
                value: 0
            },
        ] as any
    );

    const filterViewRef: any = useRef(null);
    const [iconColor, setIconColor] = useState('var(--text-font-color4)');
    const handleMouseEnter = () => {
        // console.log('鼠标移入')
        setIconColor('var(--text-font-color9)');
    };
    const handleMouseLeave = () => {
        // console.log('鼠标移出')
        setIconColor('var(--text-font-color4)');
    };
    //搜索条件
    const searchList: searchItem[] = [
        {
            searchType: "text",
            searchComponentType: "selectVip",
            placeHolder: "请选择渠道",
            title: "渠道",
            key: "channelType",
            changeField: 'shopId',
            // defaultValue:'',
            options: channeldData,
            fieldNames: {
                label: 'channelName',
                value: 'channelType'
            }
        },
        {
            searchType: "text",
            searchComponentType: "select",
            placeHolder: "请选择店铺",
            title: "店铺",
            key: "shopId",
            // defaultValue:'',
            options: shoplistData,
            fieldNames: {
                label: 'shopName',
                value: 'shopId'
            }
        },
        {
            searchType: "text",
            searchComponentType: "input",
            placeHolder: "请输入订单号",
            title: "订单号",
            key: 'orderId',
        },
    ];
    // 展开查询条件
    const openfilterView = () => {
        filterViewRef.current?.showFilterView();
    };
    //搜索字段
    const [searcParam, setSearcParam] = useState({});
    //最初字段
    const searcParamInt: any = {
        oneId: oneId,
    };
    const getUserOrderList = async (
        page: { pageSize: number; pageNum: number },
        data?: any,
    ) => {
        setTableStatus(false)
        const param = {
            ...data,
            ...searcParamInt,
            ...page,
        } as any;
        let response = await getOrderStatusEnum();
        var arrStatus = [] as any
        if (response) {
            arrStatus = Object.keys(response.data).map(key => ({ name: response.data[key].split(':')[0], value: response.data[key].split(':')[1] }));
        }
        let res: any;
        res = await getOrderInfo(param);
        if (res.data) {
            setPageQuery({
                pageSize: page.pageSize,
                pageNum: page.pageNum,
                total: res.data.total,
            });
            //处理购买过的店铺
            var list: any = [];
            var arr = res.data.orderChannelList
            arr.map((item: any) => {
                if (item.shopNameList && item.shopNameList.length > 0 && item.shopNameList[0]) {
                    item.shopNameList.map((itm: any) => {
                        list.push(itm)
                    })
                }
            })
            res.data.shopNameList = list

            setOrderInfo(res.data)
            setPeopleUserList(
                [
                    {
                        name: '累计消费金额',
                        value: res.data.allBuyAmt ? res.data.allBuyAmt < 1000 ? res.data.allBuyAmt : res.data.allBuyAmt.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                    },
                    {
                        name: '累计下单次数',
                        value: res.data.allBuyCount ? res.data.allBuyCount < 1000 ? res.data.allBuyCount : res.data.allBuyCount.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                    },
                    {
                        name: '笔单价',
                        value: res.data.unitPrice ? res.data.unitPrice < 1000 ? res.data.unitPrice : res.data.unitPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
                    },
                ]
            )
            if (arrStatus && arrStatus.length > 0) {
                arrStatus.map((item: any) => {
                    res.data.orderDetailList.map((itm: any) => {
                        if (item.name == itm.orderStatus) {
                            itm.orderStatus = item.value
                        }
                    })
                })
            }
            //重新构建数据列表
            rebuildList(res.data.orderDetailList)
            setTableStatus(true)
        } else {
            setTableStatus(true)
        }


    };
    // 查询所属渠道下的店铺
    const handleChangeFilter = (data: any) => {
        if (data) {
            getShopListData(data)
        } else {
            getShopListData()
        }
    };
    //获取店铺信息
    const getShopListData = async (data?: any) => {
        const param = {
            channelType: data
        }
        let res = await getShopList(param);
        //下单数据
        if (res) {
            setShoplistData(res.data)
        }
    };
    // 获取所属渠道
    const getChannelList = async () => {
        const res = await getChannelListApi();
        if (res) {
            setChanneldData(res.data)
        }
    };
    // 查询
    const filterFinish = (data: any) => {
        setSearcParam(data);
        getUserOrderList({ pageSize: 10, pageNum: 1 }, data);
    };
    interface DataType {
        key: React.Key;
        orderSkuList?: any;
        name?: string;
        age?: number;
        shop?: string;
        address?: string;
        description?: any;
        shopName?: string;
        orderId?: any;
    }

    const columns: TableColumnsType<DataType> = [
        {
            title: '商品信息', dataIndex: 'orderMes', key: 'orderMes', width: '20%',
            onCell: (_, index) => ({
                colSpan: 5,
            }),
        },
        {
            title: '单价', width: '20%',
            onCell: (_, index) => ({
                colSpan: 0,
            }),
        },
        {
            title: '数量', width: '20%', onCell: (_, index) => ({
                colSpan: 0,
            }),
        },
        {
            title: '订单总金额', width: '20%', onCell: (_, index) => ({
                colSpan: 0,
            }),
        },
        {
            title: '订单状态', width: '20%', onCell: (_, index) => ({
                colSpan: 0,
            }),
        },
    ];
    const [data, setData] = useState(
        [] as DataType[]
    );
    // 页码信息
    const [pageQuery, setPageQuery] = useState({
        pageSize: 10,
        pageNum: 1,
        total: 0,
    });
    // 表格change事件
    const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
        let sortStore: any = {};
        if (!sorter.order) {
            sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
        } else {
            sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
        }

        getUserOrderList(
            { pageSize: pagination.pageSize, pageNum: pagination.current },
            searcParam,
            // sortStore
        );
    };
    //重新构建数据列表
    const rebuildList = (data1: any) => {
        data1.map((item: any) => {
            item.orderMes = <>
                <div className={style['Order-list-description']}>
                    <div className={style['Order-list-description-item']}>
                        <div>{item.platformName ? item.platformName : '-'}</div>
                        <div>{item.shopName ? item.shopName : '-'}</div>
                    </div>
                    <div className={style['Order-list-description-item']}>
                        <div>订单号：</div>
                        <div>{item.orderId ? item.orderId : '-'}</div>
                    </div>
                    <div className={style['Order-list-description-item']}>
                        <div>下单时间：</div>
                        <div>{item.orderStartTime ? item.orderStartTime : '-'}</div>
                    </div>
                    <div className={style['Order-list-description-item']}>
                        <div>付款时间：</div>
                        <div>{item.orderPaymentTime ? item.orderPaymentTime : '-'}</div>
                    </div>
                    <div className={style['Order-list-description-item']}>
                        <div>订单完成时间：</div>
                        <div>{item.orderEndTime ? item.orderEndTime : '-'}</div>
                    </div>
                </div>
            </>;
            item.description = description(item);
            let list = [] as any;
            item.orderSkuList.map((itm: any, indx: Number) => {
                itm.orderStatus = item.orderStatus
                itm.orderAmt = item.orderAmt
                itm.payAmt = item.payAmt
                list.push(itm)
            })
            list.map((item: any, index: any) => {
                item.age = 0
                if (index == 0) {
                    item.age = list.length
                }

            })
            // console.log("========listlistlist========",list);
        })

        setData(data1)
    }
    const description = (orderSkuList: any) => {

        return <> </>

    }
    const expandedRowRender = (orderSkuList: any) => {
        const columns = [
            {
                title: '商品信息', dataIndex: 'skuName', key: 'skuName', width: '20%',
                render: (_: any, record: any) =>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {record.skuImg && <img className={style['Order-list-goods-src']} src={record.skuImg} alt="" />}
                        <div>
                            <div>{record.skuName ? record.skuName : '-'}</div>
                            <div>商品编号</div>
                            <div>{record.skuId ? record.skuId : '-'}</div>
                        </div>
                    </div>
            },
            {
                title: '单价', dataIndex: 'skuPrice', key: 'skuPrice', width: '20%',
                render: (_: any, record: any) =>
                    <>

                        <div style={{ color: 'var(--text-font-color7)' }}>￥{record.skuPrice ? record.skuPrice : '-'}</div>
                    </>
            },
            {
                title: '数量', dataIndex: 'skuNum', key: 'skuNum', width: '20%',
                render: (_: any, record: any) =>
                    <>
                        <div>×{record.skuNum ? record.skuNum : '-'}</div>
                    </>
            },
            {
                title: '订单总金额', dataIndex: 'orderAmt', key: 'orderAmt', width: '20%',
                onCell: (item: any, index: any) => {
                    return { rowSpan: item.age };
                    return {};
                },
                render: (_: any, record: any) =>
                    <>
                        <div style={{ color: 'var(--text-font-color7)' }}>￥{record.orderAmt ? record.orderAmt : '-'}</div>
                        <div>（实际支付：￥{record.payAmt ? record.payAmt : '-'}）</div>
                    </>
            },
            {
                title: '订单状态', width: '20%',
                dataIndex: 'orderStatus',
                key: 'orderStatus',
                onCell: (item: any, index: any) => {
                    return { rowSpan: item.age };
                    return {};
                },
                render: (_: any, record: any) =>
                    <>
                        {record.orderStatus == '已完成' && <Tag color="success">{record.orderStatus}</Tag>}
                        {(record.orderStatus == '已取消' || record.orderStatus == '交易关闭') && <Tag color="default">{record.orderStatus}</Tag>}
                        {(record.orderStatus != '已完成' && record.orderStatus != '已取消' && record.orderStatus != '交易关闭')
                            && <Tag color="warning">{record.orderStatus}</Tag>
                        }
                    </>
            },
        ];
        return <Table className={style['Order_inside-label']} bordered columns={columns} dataSource={orderSkuList} pagination={false} showHeader={false} />;
    };
    //监听或进入调用
    useEffect(() => {
        openfilterView()
        getShopListData()
        getChannelList()
        getUserOrderList({ pageSize: 10, pageNum: 1 })

    }, [])
    const resetFilter = () => {
        filterViewRef.current?.resetData();
    };
    // useEffect(() => {
    //     console.log(data, 'lllllllll');

    // }, [data])

    return <>
        <div className={style['Order']}>
            <div>
                <span className={style['Order_title']}>订单信息</span>
                <div className={style["Order_peopleListBox"]}>
                    {peopleUserList.map((item: any, index: any) => (
                        <div className={style['Order_Item']} key={index}>
                            <div style={{ color: 'var(--text-font-color18)' }}>{item.name}</div>
                            <div className={style['Order_Item-value']}>{item.value}</div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <div style={{ marginTop: '20px' }}>
                <div className={style['search-list-continer']} style={{ padding: '16px' }}>
                    <div style={{ display: 'flex' }}>
                        <img src={orderBuyIcon} className={style['search-list--icon']} />
                        <div>购买过的信息：</div>
                        <div className={style['search-list-text']}>
                            <span>购买过的平台：</span>
                            {orderInfo.orderChannelList && orderInfo.orderChannelList.length > 0 && <>
                                {orderInfo.orderChannelList.map((item: any, index: any) => (
                                    <span key={index}>{item.platformName}
                                        {index != orderInfo.orderChannelList.length - 1 && <span>，</span>}
                                    </span>
                                ))}
                            </>}
                        </div>
                        <div className={style['search-list-textShop']}>
                            <div style={{whiteSpace:'nowrap'}}>购买过的店铺：</div>
                            <div>
                                {orderInfo.shopNameList && orderInfo.shopNameList.length > 0 && <>
                                    {orderInfo.shopNameList.map((item: any, index: any) => (
                                        <div key={index}>{item}</div>
                                    ))}
                                </>}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '14px' }}>
                        <img src={orderBuyNearIcon} className={style['search-list--icon']} />
                        <div>最近购买信息：</div>
                        <div className={style['search-list-text']} >
                            <span>最后一次购买平台：</span>
                            <span>{orderInfo.lastPlatformName ? orderInfo.lastPlatformName : '-'}</span>
                        </div>
                        <div className={style['search-list-text']}>
                            <div style={{display:'flex'}}>
                                <div style={{whiteSpace:'nowrap'}}>购买过的店铺：</div>
                                <div>{orderInfo.lastShopName ? orderInfo.lastShopName : '-'}</div>
                            </div>
                        </div>
                        <div className={style['search-list-text']}>
                            <span>最后一次购买时间：</span>
                            <span>{orderInfo.lastOrderTime ? orderInfo.lastOrderTime : '-'}</span>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className={style['Order_buy-info']}>
                <div className={style['Order_buy-info-item']}>
                    <img src={orderBuyIcon} className={style['search-list--icon']} />
                    <span className={style['Order_buy-info-item-title']}>
                        购买过的信息
                    </span>
                    <div className={style['Order_buy-info-item-detail']}>
                        <div className={style['Order_buy-info-item-detail-item']}>
                            <span className={style['Order_buy-info-item-detail-title']}>购买过的平台：</span>
                            <span className={style['Order_buy-info-item-detail-title']} style={{ flex: 1 }}>
                                {orderInfo.orderChannelList && orderInfo.orderChannelList.length > 0 ? _.map(orderInfo.orderChannelList, 'platformName').join(',') : ''}
                            </span>
                        </div>
                        <div className={style['Order_buy-info-item-detail-item']}>
                            <span className={style['Order_buy-info-item-detail-title']}>购买过的店铺：</span>
                            <span className={style['Order_buy-info-item-detail-title']} style={{ flex: 1 }}>
                                {orderInfo.shopNameList && orderInfo.shopNameList.length > 0 ? orderInfo.shopNameList.join(',') : ''}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={style['Order_buy-info-item']}>
                    <img src={orderBuyIcon} className={style['search-list--icon']} />
                    <span className={style['Order_buy-info-item-title']}>
                        最近购买信息
                    </span>
                    <div className={style['Order_buy-info-item-detail']}>
                        <div className={style['Order_buy-info-item-detail-item']}>
                            <span className={style['Order_buy-info-item-detail-title']}>最后一次购买平台：</span>
                            <span className={style['Order_buy-info-item-detail-title']} style={{ flex: 1 }}>
                                {orderInfo.lastPlatformName ? orderInfo.lastPlatformName : '-'}
                            </span>
                        </div>
                        <div className={style['Order_buy-info-item-detail-item']}>
                            <span className={style['Order_buy-info-item-detail-title']}>最后一次购买店铺：</span>
                            <span className={style['Order_buy-info-item-detail-title']} style={{ flex: 1 }}>
                                {orderInfo.lastShopName ? orderInfo.lastShopName : '-'}
                            </span>
                        </div>
                        <div className={style['Order_buy-info-item-detail-item']}>
                            <span className={style['Order_buy-info-item-detail-title']}>最后一次购买时间：</span>
                            <span className={style['Order_buy-info-item-detail-title']} style={{ flex: 1 }}>
                                {orderInfo.lastOrderTime ? orderInfo.lastOrderTime : '-'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '16px' }}>
                <span className={style['Order_title']}>订单明细</span>
                <div className={style['Order_serch-box']}>
                    <Tooltip placement="bottom" title={'过滤器'}>
                        <Button
                            onClick={() => filterViewRef.current?.showFilterView()}
                            icon={
                                <FilterOutlined
                                    style={{
                                        color: iconColor,
                                        fontSize: 'var(--text-font7)',
                                    }}
                                />
                            }
                            type="text"
                            className="click-element"
                            style={{ marginRight: '10px' }}
                            onMouseLeave={handleMouseLeave}
                            onMouseEnter={handleMouseEnter}></Button>
                    </Tooltip>
                </div>
                <div className={style['search-list-continer']}>
                    <FilterView
                        ref={filterViewRef}
                        searchList={searchList}
                        finish={filterFinish}
                        averageTwo={true}
                        handleChange={handleChangeFilter}
                    ></FilterView>
                </div>
            </div>
            {tableStatus &&
                <Table
                    style={{ marginTop: '24px', backgroundColor: '' }}
                    className={style['Order_outside-label']}
                    columns={columns}
                    rowKey={record => record.orderId}
                    pagination={{
                        total: pageQuery.total,
                        pageSize: pageQuery.pageSize,
                        current: pageQuery.pageNum,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showTotal: (total) => `共 ${total} 条`,
                    }}
                    onChange={handleStandardTableChange}
                    expandable={{
                        defaultExpandAllRows: true,
                        expandIcon: ({ expanded, onExpand, record }) =>
                            expanded ? (
                                <MinusCircleOutlined onClick={(e) => onExpand(record, e)} />
                            ) : (
                                <PlusCircleOutlined onClick={(e) => onExpand(record, e)} />
                            ),
                        expandedRowRender: (record) => expandedRowRender(record.orderSkuList),
                    }}
                    dataSource={data}
                />
            }
        </div>
    </>
})
export default Resume