import { AxiosPromise } from 'axios';
import request from '../network';
import { AccountListQuery, AccountListQueryVO, UserListQuery, UserListVO } from './types';

// 获取客户洞察列表
export function getUserInsightListApi(query: any): AxiosPromise<UserListVO[]> {
  return request({
    url: 'lz-cdp-data-collect/customer/list',
    method: 'POST',
    data: query,
  });
}
// 获取客户洞察列表
export function getUserQueryPageApi(params: any): AxiosPromise<UserListVO[]> {
  return request({
      url: 'cdpdata/portraitInsight/customer/queryPage',
      method: 'GET',
      params: params
  })
}
// 客户洞察列表-导出
export function exportData(query: any): AxiosPromise<UserListVO[]> {
  return request({
    url: 'cdpdata/portraitInsight/exportData',
    method: 'POST',
    data: query,
  });
}
// 获取会员卡
export function getMemberCardListApi(): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getMemberCardList',
    method: 'GET',
  });
}
// 根据oneId查询 用户详情个人信息
export function customerInfo(params: any): AxiosPromise<any> {
  return request({
    url: `lz-cdp-data-collect/customer/customerInfo`,
    method: 'GET',
    params: params,
  });
}
// 获取店铺列表
export function getShopList(params?: any): AxiosPromise<any> {
  return request({
    url: `lz-cdp-data-collect/customer/getShopList`,
    method: 'GET',
    params: params,
  });
}
// 获取渠道
export function getChannelListApi(): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getChannelList',
    method: 'GET',
  });
}
// 获取履历
export function getJourneyRecord(query: any): AxiosPromise<any> {
  return request({
    url: `lz-cdp-data-collect/customer/journeyRecord`,
    method: 'POST',
    data: query,
  });
}
// 用户身份列表
export function getIdentityApi(): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getIdentity',
    method: 'GET',
  });
}
// 查询用户标签信息
export function getUserTags(query: any): AxiosPromise<any> {
  return request({
    url: '/label/label/getUserTags',
    method: 'POST',
    data: query,
  });
}
// 查询分群信息
export function getGroupList(params: any): AxiosPromise<any> {
  return request({
    url: '/label/crowd/find/crowd/name',
    method: 'GET',
    params: params,
  });
}
// 用户订单信息
export function getOrderInfo(query: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getOrderInfo',
    method: 'POST',
    data: query,
  });
}
// 用户订单信息匹配状态
export function getOrderStatusEnum(): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/data/orderStatusEnum',
    method: 'GET',
  });
}
// 用户会员卡信息
export function memberCardInfo(params: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/memberCardInfo',
    method: 'GET',
    params: params,
  });
}
// 用户会员卡变更信息
export function getMemberCardJourney(query: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getMemberCardJourney',
    method: 'POST',
    data: query,
  });
}
// 用户会员等级列表
export function getMemberLevelList(params: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getMemberLevelList',
    method: 'GET',
    params: params,
  });
}
// 用户会员等级列表  新
export function getMemberLevelNewList(): AxiosPromise<any> {
  return request({
      url: 'lz-cdp-data-collect/customer/getMemberLevelListAll',
      method: 'GET', 
  })
}

// 手动修改积分
export function changePoints(query: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/changePoints',
    method: 'POST',
    data: query,
  });
}
// 手动修改会员等级
export function changeGrade(query: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/changeGrade',
    method: 'POST',
    data: query,
  });
}
// 手动修改会员成长值
export function changeGrowth(query: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/changeGrowth',
    method: 'POST',
    data: query,
  });
}
// 编辑字段配置  获取列表表头
export function getCustomerListTitle(): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getCustomerListTitle',
    method: 'GET',
  });
}
// 编辑字段配置  更新列表表头
export function updCustomerListTitle(query: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/updCustomerListTitle',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，计算选择人群的人数和占比
export function statisticsUserGroup(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/queryUserCrowdLabel',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，人群查看列表
export function queryReportList(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/queryReportCrowdPage',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，新增报告
export function saveUserGroup(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/saveOrUpdateReportForm',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，选择报告保存
export function updateReportForm(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/updateReportForm',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，查询制定状态的全量标签
export function queryAllCrowd(params: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/queryAllCrowd',
    method: 'GET',
    params: params,
  });
}
// 群体画像洞察，统计图刷新接口
export function refreshReportApi(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/refreshReportForm',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，详情查询接口
export function queryReportFormDetail(params: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/queryReportFormDetail',
    method: 'GET',
    params: params,
  });
}
// 群体画像洞察，查询列表
export function queryReportFormList(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/queryReportFormList',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，删除接口
export function deletedReportForm(params: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/deletedReportForm',
    method: 'GET',
    params: params,
  });
}
// 群体画像洞察，下载
export function downloadReportForm(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/downloadReportForm',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，预览接口
export function queryPreviewChart(query: any): AxiosPromise<any> {
  return request({
    url: 'label/crowd/queryPreviewChart',
    method: 'POST',
    data: query,
  });
}
// 群体画像洞察，历史归档查询接口
export function queryHistoryReportForm(query: any): AxiosPromise<any> {
  return request({
    url: '/label/crowd/queryHistoryReportForm',
    method: 'POST',
    data: query,
  });
}
export default {};
