import { useState, useEffect } from "react";
import * as echarts from "echarts";
import closeFullScreenPic from "@/pages/LuZhiBI/assets/images/closeFullScreen.png";
import fullScreenPic from "@/pages/LuZhiBI/assets/images/fullScreen.png";
import styles from "../TableStyle/index.module.scss";

let chartInstance: echarts.ECharts | null = null;
function PieStyle(props: any) {
  const { dataSource, index } = props;
  // 回答答案是否全屏
  const [fullScreen, setFullScreen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data, setData] = useState<any>([]);
  const dimensions = dataSource.dimension; // 全部维度
  const dimensionKeys = dataSource.dimensionKey; // 全部维度的key
  const metrics = dataSource.metric; // 全部指标
  const metricsKeys = dataSource.metricKey; // 全部指标的key
  const originalValue = dataSource.value;
  const originalDimensions = dataSource.origin.dimension;
  const originalMetrics = dataSource.origin.metric;
  useEffect(() => {
    let barDataArr: {
      value: string;
      name: string;
    }[] = [];
    if (originalValue && originalValue.length > 0) {
      if (dimensionKeys.length > 1) {
        let arr: { name: string; value: any }[] = [];
        originalValue.forEach((item: any) => {
          arr.push({
            name: `${item[dimensionKeys[0]]}`,
            value: Number(item[metricsKeys[0]]),
          });
        });
        setData(arr);
      } else {
        const xName = Object.values(dimensions)[0];
        const yName = Object.values(metrics)[0];
        originalValue.forEach((item: { [x: string]: any }) => {
            // @ts-ignore
          barDataArr.push({
            // @ts-ignore
            value: Number(item[yName]),
            // @ts-ignore
            name: item[xName],
          });
        });
      }
      setData(barDataArr);
    }
  }, [dataSource]);
  
  const [instanceArr, setInstanceArr] = useState<any>([]);
  useEffect(() => {
    let barDataArr: {
      value: number;
      name: string;
    }[] = [];
    if (originalValue && originalValue.length > 0) {
      if (dimensionKeys.length > 1) {
        originalValue.forEach((item: any) => {
          barDataArr.push({
            name: `${dimensionKeys[0]}:${item[dimensionKeys[0]]}- ${
              dimensionKeys[1]
            }:${item[dimensionKeys[1]]}`,
            value: Number(item[metricsKeys[0]]),
          });
        });
        setData(barDataArr);
      } else {
        const xName = Object.values(dimensions)[0];
        const yName = Object.values(metrics)[0];
        originalValue.forEach((item: { [x: string]: any }) => {
          barDataArr.push({
            // @ts-ignore
            value: Number(item[yName]),
            // @ts-ignore
            name: item[xName],
          });
        });
      }
      setData(barDataArr);
    }
    var option = {
      tooltip: {
        trigger: "item",
        formatter: "{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: data.map((item: { name: any }) => item.name),
      },
      series: [
        {
          name: "数据",
          type: "pie",
          radius: "50%",
          data: barDataArr,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    let pieDom = document.getElementById(`pieChart_${index}`);
    let pieDomFullScreen = document.getElementById(
      `pieChartFullScreen_${index}`
    );
    if (instanceArr[index]) {
      instanceArr[index].dispose();
    }
    chartInstance = echarts.init(fullScreen ? pieDomFullScreen : pieDom);
    instanceArr[index] = chartInstance;
    setInstanceArr([...instanceArr]);
    chartInstance.setOption(option);
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      chartInstance?.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        chartInstance?.resize();
      });
    };
  }, [windowWidth, fullScreen, dataSource]);
  return (
    <div>
      <div className={styles.fullScreen}>
        <img src={fullScreenPic} onClick={() => setFullScreen(true)} />
      </div>
      <div id={`pieChart_${index}`} style={{ height: "300px" }} />
      {fullScreen && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <span className={styles.close} onClick={() => setFullScreen(false)}>
              <img src={closeFullScreenPic} />
            </span>
            <div
              id={`pieChartFullScreen_${index}`}
              style={{ height: "600px", width: "900px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PieStyle;
