
//用户状态
export enum AccountStatusEnum {
    NORMAL = 0,
    DISABLE = 1,
    LOCKED = 2,
}

//用户状态
export enum AccountStatusTextEnum {
    NORMAL_TEXT = '正常',
    DISABLE_TEXT = '停用',
    LOCKED_TEXT = '锁定',
}

//账号来源
export enum AccountSourceEnum {
    MANUAL = 0,
    THIRDPART = 1,
    EXPORT = 2,
}

//用户状态
export enum AccountSourceTextEnum {
    MANUAL_TEXT = '手工创建',
    THIRDPART_TEXT = '第三方登录',
    EXPORT_TEXT = '第三方导入',
}

//授权方式
export enum AuthorizationWayEnum {
    NOCHOOSE = 0,
    CHOOSEGROUP = 1,
    DIRECTAUTHORIZATE = 2,
    ALL = 3
}

//授权方式
export enum AuthorizationWayTextEnum {
    CHOOSEGROUPTEXT = '选择用户组',
    DIRECTAUTHORIZATETEXT = "直接授权"
}