import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import styles from "./index.module.scss";
import CommercePlatform from '../CommercePlatform'


const SelectAccessMethod =  (props: { open: boolean, accessMethodList: any, getSelectedId: any }) => {
  const { open, accessMethodList, getSelectedId = () => {} } = props;

  const [accessMethodListStore, setAccessMethodListStore] = useState(accessMethodList);

  // 新建--tab页切换
  const [activeKey, setActiveKey] = useState('0');
  const tabChange = (key: string) => {
    setActiveKey(key);
    setAccessMethodListStore([]);
    if(key === '0'){
      setAccessMethodListStore([...accessMethodList]);
    } else {
      setAccessMethodListStore(accessMethodList.filter((v: any, i: number) => v.type === key));
    }
  };

  const getAccessMethodListData = (p: string) => {
    const store = [] as any;
    accessMethodList.forEach((v: any, i: number) => {
      if(v.name.indexOf(p) !== -1){
        store.push(v);
      }
    });
    setAccessMethodListStore(store);
  };

  // 接受子集传回来的id
  const getSelected = (id:number) => {
    getSelectedId(id);
  }; 

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: '全部',
      children: <CommercePlatform open={open} accessMethodList={accessMethodList} accessMethodListData={accessMethodListStore} getAccessMethodListData={getAccessMethodListData} getSelected={getSelected} />,
    },
    {
      key: '1',
      label: '陆泽电商',
      children: <CommercePlatform open={open} accessMethodList={accessMethodList} accessMethodListData={accessMethodListStore} getAccessMethodListData={getAccessMethodListData} getSelected={getSelected} />,
    },
    {
      key: '2',
      label: '其他',
      children: <CommercePlatform open={open} accessMethodList={accessMethodList} accessMethodListData={accessMethodListStore} getAccessMethodListData={getAccessMethodListData} getSelected={getSelected} />,
    },
  ];

  useEffect(() => {
    
  }, []);

  return (
    <div>
      <Tabs 
        className={styles.tabsStyle}
        activeKey={activeKey}
        items={items} 
        onChange={tabChange} 
      />
    </div>
  );
};

export default SelectAccessMethod;
