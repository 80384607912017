import { useState } from 'react';
import { List } from 'antd';
import styles from './index.module.scss'

const IntegrationTable = () => {
  const cloumns1 = [
    {
      item1: '流水号',
      item2: ['ID'],
      item3: ['ces'],
    },
    {
      item1: '用户唯一标识',
      item2: ['ID'],
      item3: ['ces'],
    },
    {
      item1: '标记订单来源',
      item2: ['ID', '', '原始父订单号', 'ID', '订单来源渠道', '原始父订单号'],
      item3: ['ID', '订单来源渠道3', '原始父订单号3', 'ID', '', '原始父订单号'],
    }
  ]
  const listHeader = () => {
    return(
      <div className={styles.listHeader}>
        <div style={{width: '20%'}}>分类/目的/作用</div>
        <div style={{width: '40%'}}>字段名/含义</div>
        <div style={{width: '40%'}}>值/说明</div>
      </div>
    )
  }
  const listBody = (item: any) => {
    return (
      <div>
        <div className={styles.listBodyBorder}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{width: '20%', padding: '8px 8px 8px 16px', }}>{item.item1? item.item1 : '--'}</div>
            <div style={{width: '40%'}}>
              {item.item2.map((it2: any, index: number) => <div style={{borderBottom: index === item.item2.length-1? 'none' : 'var(--border24)', padding: '8px 8px 8px 8px'}}>{it2? it2 : '--'}</div>)}
            </div>
            <div style={{width: '40%'}}>
              {item.item3.map((it3: any, index: number) => <div style={{borderBottom: index === item.item2.length-1? 'none' : 'var(--border24)', padding: '8px 8px 8px 0px'}}>{it3? it3 : '--'}</div>)}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <List
        size="small"
        header={listHeader()}
        bordered
        dataSource={cloumns1}
        renderItem={(item) => listBody(item)
        }
      />
    </>
  )
}
export default IntegrationTable