import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import { ChannelTag } from "../MiniStyles";
import { Popover } from "antd";
import { channelIconMap } from "@/crm/assets/public/pageMap";

let timer: any = {};

export const TagChannelIcon = ({ channelType = "JD", style = {} }) => {
  return (
    <img
      src={channelIconMap.get(`${channelType}_blue`)}
      alt=""
      style={{ width: "20px", height: "20px", ...style }}
    />
  );
};

const TagList = ({
  tagList,
  channelType,
  tagId,
}: {
  tagList: any[];
  channelType: string;
  tagId: any;
}) => {
  const outSideRef = useRef<any>({});
  const [showTagIndex, setShowTagIndex] = useState(0);
  const [letfNum, setLeftNum] = useState(0);
  const [showTagStatus, setShowTagStatus] = useState(false);

  useEffect(() => {
    // 27是省略号tag的宽度
    handScroll();
    window.addEventListener("resize", () => {
      handScroll();
    });
    return () => {
      window.removeEventListener("resize", () => {});
      clearTimeout(timer[tagId]);
    };
  }, [outSideRef?.current[tagId]?.offsetWidth]);

  const handScroll = () => {
    clearTimeout(timer[tagId]);
    timer[tagId] = setTimeout(() => {
      handJudge();
    }, 10);
  };

  const handJudge = () => {
    if (!outSideRef?.current[tagId]?.offsetWidth) return;
    const outSideWidth = outSideRef.current[tagId].offsetWidth + 5;
    const list = outSideRef.current[tagId].children;
    const listLength = list.length;

    if (listLength === 0) return;
    let allWidth = 0; //tag加一起的宽度
    let showIndex = 0; //第几个开始要隐藏
    const arr = new Array(listLength).fill("");

    arr.forEach((item, index) => {
      if (allWidth + list[index].offsetWidth + 8 <= outSideWidth) {
        showIndex = index;
        allWidth = allWidth + list[index].offsetWidth + 8;
      } else {
        arr.length = 0;
      }
    });

    if (
      (!showTagStatus && showIndex < listLength - 1) ||
      (showTagStatus && showIndex < listLength - 2)
    ) {
      // 情况：剩余部分不够显示省略号（36px），删掉最后一个，即showIndex减一
      if (outSideWidth - allWidth < 36) {
        allWidth = allWidth - list[showIndex].offsetWidth - 8;
        showIndex -= 1;
      }
      setLeftNum(allWidth);
      setShowTagStatus(true);
    } else {
      setShowTagStatus(false);
    }
    setShowTagIndex(showIndex);
  };

  // 悬浮提示框
  const PopoverSection = () => {
    const list = tagList.slice(showTagIndex + 1, tagList.length);
    return (
      <div>
        {list?.map((item, index) => (
          <div style={{ margin: "6px 0" }}>
            <ChannelTag type={channelType} key={`${item.shopListName}-${index}`}>
              {item.shopName}
            </ChannelTag>
          </div>
        ))}
      </div>
    );
  };

  // tag列表
  const getTagListSection = () => {
    return (
      <div
        className={style["tag-item"]}
        ref={(el) => (outSideRef.current[tagId] = el)}>
        {tagList?.map((item, index) => (
          <ChannelTag
            type={channelType}
            key={`${item}-${index}`}
            hidden={index > showTagIndex}>
            {item.shopName}
          </ChannelTag>
        ))}
        {showTagStatus && (
          <Popover content={<PopoverSection />} placement="rightTop">
            <div
              style={{
                position: "absolute",
                left: `${letfNum}px`,
                zIndex: "2",
                cursor: "pointer",
              }}>
              <ChannelTag type={channelType}>...</ChannelTag>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  return (
    <div className={style["tag-list-bgd"]}>
      <TagChannelIcon channelType={channelType} />
      {getTagListSection()}
    </div>
  );
};

export default TagList;
