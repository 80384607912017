import { AxiosPromise } from 'axios';
import request from '../network'
import { addCategoryQuery, categoryQuery, upDateCategoryNameQuery } from './types';
import {
    QueryLabelListVO,
    QueryLabelValueListVO,
    ProfilePropertyListVO,
    ProfileBehaviorListVO,
    labelProduceVO,
    checkVO,
    createLabelQuery,
    createCrowdQuery
} from './types';

//根据标签名查询标签列表(支持模糊查询)
export function queryLabelListApi(query: any): AxiosPromise<QueryLabelListVO[]> {
    return request({
        url: '/label/label/queryLabelList',
        method: 'post',
        data: query,
    });
}

//查询标签值列表
export function queryLabelValueListApi(query: any): AxiosPromise<QueryLabelValueListVO[]> {
    return request({
        url: '/label/label/queryLabelValueList',
        method: 'post',
        data: query,
    });
}

//获取运算符
export function profileOperatorApi(query: any): AxiosPromise<any> {
  return request({
      url: `/label/profile/${query.fieldType}/operator`,
      method: 'get',
      params: {}
  });
}

//用户属性列表查询
// export function profilePropertyListApi(query: any): AxiosPromise<ProfilePropertyListVO[]> {
export function profilePropertyListApi(): AxiosPromise<any> {
  return request({
      url: '/label/profile/property/list',
      method: 'get',
      params: {}
  });
}

//获取枚举值
export function profileEnumsListApi(query: any): AxiosPromise<any> {
  return request({
      url: '/label/profile/enums',
      method: 'get',
      params: query
  });
}

//用户行为表查询
export function profileBehaviorListApi(): AxiosPromise<ProfileBehaviorListVO[]> {
  return request({
      url: '/label/profile/behavior/list',
      method: 'get',
      params: {}
  });
}

//创建标签
export function createLabelApi(query: createLabelQuery) {
    return request({
        url: '/label/label/createLabel',
        method: 'post',
        data: query,
    });
}

//校验标签是否可编辑
export function labelCheakEditApi(query: any) {
  return request({
      url: '/label/label/check/edit',
      method: 'get',
      params: query,
  });
}

//编辑标签
export function updateLabelApi(query: createLabelQuery) {
  return request({
      url: '/label/label/updateLabel',
      method: 'post',
      data: query,
  });
}

//编辑标签
export function editCrowdApi(query: any) {
  return request({
      url: '/label/crowd/edit/crowd',
      method: 'put',
      data: query,
  });
}

//获取行为事件属性信息
export function profileBehaviorSubPageListApi(query: any): AxiosPromise<any> {
  return request({
      url: '/label/profile/behavior/sub/list',
      method: 'get',
      params: query
  });
}

// 分群下拉
export function crowdListApi(): AxiosPromise<any> {
  return request({
      url: '/label/crowd/list',
      method: 'get',
      params: {}
  });
}

// 获取标签值统计信息(分页)
export function getLabelUserStatApi(data: any) {
    return request({
        url: '/label/label/getLabelUserStat',
        method: 'post',
        data: data
    });
}
// 查询标签详情
export function getLabelInfoDetailApi(data: any) {
    return request({
        url: '/label/label/queryLabelInfoDetail',
        method: 'post',
        data: data
    });
}

// 获取标签数据概览
export function getLabelOverviewApi(data: any) {
    return request({
        url: '/label/label/labelOverview',
        method: 'post',
        data: data
    });
}

// 获取标签使用数量
export function getLabelCountApi(data?: any) {
    return request({
        url: '/label/label/queryUsedLabelCount',
        method: 'post',
        data: data
    });
}

// 获取标签使用数量
export function changeLabelStatusApi(data: any) {
    return request({
        url: '/label/label/labelStatusManage',
        method: 'post',
        data: data
    });
}


//新增类目
export function addLabelCategoryApi(data: addCategoryQuery) {
    return request({
        url: '/label/labelcategory/create',
        method: 'post',
        data: data
    });
}

//获取标签类目
export function queryListApi(data: categoryQuery) {
    return request({
        url: '/label/labelcategory/querylist',
        method: 'post',
        data: data
    });
}

//编辑类目名称
export function updateCategoryNameApi(data: upDateCategoryNameQuery) {
    return request({
        url: '/label/labelcategory/update',
        method: 'post',
        data
    })
}

//删除类目
export function deleteCategoryApi(data: any) {
    return request({
        url: '/label/labelcategory/del',
        method: 'post',
        data
    })
}

//删除标签
export function deleteLabelApi(data: any) {
    return request({
        url: '/label/label/delLabel',
        method: 'post',
        data
    })
}

//移动标签
export function moveLabelApi(data: any) {
    return request({
        url: '/label/labelcategory/updateSortIndex',
        method: 'post',
        data
    })
}

//创建分群
export function crowdAddApi(query: createCrowdQuery) {
  return request({
      url: '/label/crowd/add',
      method: 'post',
      data: query,
  });
}

//校验分群是否可编辑
export function crowdCheckEditApi(query: any) {
  return request({
      url: '/label/crowd/check/edit',
      method: 'get',
      params: query,
  });
}

//编辑分群
export function crowdUpdateApi(query: createCrowdQuery) {
  return request({
      url: '/label/crowd/update',
      method: 'put',
      data: query,
  });
}

// 标签人数预估
export function profileLabelProduceApi(query: any): AxiosPromise<labelProduceVO>  {
  return request({
      url: '/label/label/labelEstimate',
      method: 'post',
      data: query,
  });
}

// 校验标签值是否使用
export function checkLabelValueUsedApi(query: {labelValueId: string}): AxiosPromise<checkVO>  {
  return request({
      url: '/label/label/checkLabelValueUsed',
      method: 'post',
      data: query,
  });
}

// 分群人数预估
export function crowdEstimateApi(query: any): AxiosPromise<labelProduceVO>  {
  return request({
      url: 'label/crowd/estimate',
      method: 'post',
      data: query,
  });
}

//检验标签引用信息
export function checkLabelApi(query: any) {
  return request({
      url: '/label/label/check/label',
      method: 'get',
      params: query,
  });
}

// 分群详细信息获取
export function getCrowdInfoApi(query: any) {
  return request({
      url: `/label/crowd/${query.crowdId}/get`,
      method: 'get',
      params: {},
  });
}
// 分群标签下载csv模板
export function getExcel(query: any) {
  return request({
      url: `/resource/oss/getExcel`,
      method: 'get',
      params: query,
  });
}
// 标签导入创建
export function createLabelInfoExcel(query: any) {
  return request({
      url: `/label/label/createLabelInfoExcel`,
      headers: {
        'Content-Type': 'multipart/form-data',
    },
      method: 'post',
      data: query,
  });
}
// 分群导入创建
export function createGroupInfoExcel(query: any) {
  return request({
      url: `/label/crowd/createGroupInfoExcel`,
      headers: {
        'Content-Type': 'multipart/form-data',
    },
      method: 'post',
      data: query,
  });
}
// 标签下载错误信息
export function getLabelInfoExcel(query: any) {
  return request({
      url: `/label/label/getLabelInfoExcel`,
      method: 'post',
      data: query,
  });
}
// 分群下载错误信息
export function getGroupInfoExcel(query: any) {
  return request({
      url: `/label/crowd/getGroupInfoExcel`,
      method: 'post',
      data: query,
  });
}

// 上传数据查看-标签
export function exportLabelList(query: any) {
  return request({
      url: `/label/label/export/data/page/list`,
      method: 'get',
      params: query,
  });
}
// 上传数据查看-分群
export function exportCrowdList(query: any) {
  return request({
      url: `/label/crowd/export/data/page/list`,
      method: 'get',
      params: query,
  });
}
// 获取客户属性
export function customerList() {
  return request({
      url: `/label/crowd/customer/id/list`,
      method: 'get',
    //   params: query,
  });
}
// 规则标签数据更新
export function labelUpdata(query:any) {
  return request({
      url: `/label/label/update/data`,
      method: 'get',
      params: query,
  });
}
// 规则分群数据更新
export function crowdUpdata(query:any) {
  return request({
      url: `/label/crowd/update/data`,
      method: 'get',
      params: query,
  });
}

