import React from 'react';
import '../Login/index.scss'
import { Form, Button, Divider, Checkbox, InputRef, message } from 'antd';
import UnderLineInput from '../../../component/Input/UnderLineInput/underLineInput';
import {
    MobileOutlined,
    CustomerServiceOutlined,
    SafetyCertificateOutlined,
    MailOutlined
} from '@ant-design/icons';
import industrial from './../../../assets/images/industrial.png'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { getQueryParam } from '../../../utils/tools';
import { get2FaDesInfo, login2faSendCode, login2fa } from '../../../api/auth/auth'
import ButtonCountdown from '../../../component/ButtonCountdown/buttonCountdown'
import { useRef } from 'react';
import { state, setCurrentPath } from './../../../store/index';

type FieldType = {
    username?: string;
    verifycode?: string;
    remember?: string;
};

const LoginVerify = () => {
    const [loginForm] = Form.useForm()
    // const accountInfo = getQueryParam(window.location.href, 'username');
    const navigate = useNavigate();
    const deadline = Date.now() + 1000 * 60;
    const [verifyType, setVerifyType] = useState('');
    const [verifyInfo, setVerifyInfo] = useState('');
    const [codeCount, setCodeCount] = useState(null);
    const [isSendCode, setIsSendCode] = useState(false);
    const fillLoginForm = (obj: any) => {
        console.log(obj, 'obj')
        loginForm.setFieldsValue(obj)
      }
    // 获取信息
    const get2FaVerifyInfo = () => {
        get2FaDesInfo().then((res: any) => {
            setVerifyType(res.data.configType);
            setVerifyInfo(res.data.desensitization)
            // console.log(verifyInfo, 'verifyInfo');
            
        })
    }
    // 发送验证码
    const sendVerifyCode = async () => {
        if (!verifyInfo || verifyInfo ==='请输入有效的手机号或邮箱') {
            message.warning(`请先输入${verifyType ==='1' ? '邮箱号' : '手机号'}`)
            return
        }
        const res = await login2faSendCode()
        if (res) {
            setIsSendCode(true)
        } else {
            setIsSendCode(false)
        }
    }
    // 确定
    const handleLoginClick = async () => {
        const code = loginForm.getFieldValue('verifycode');
        if (!loginForm.getFieldValue('verifycode')) {
            message.warning('请输入验证码')
            return
          }
        const res = await login2fa(code)
        if (res) {
            if (res.data || res.data === 0) {
                setCodeCount(res.data)
            } else {
                navigate('/') 
            }
            
        }
    }
    //忘记密码
    const handleForgetClick = () => {
        navigate(`/validation?type=forget&username=${getQueryParam(window.location.href, 'username')}`)
    }
    // 验证码倒计时结束触发
    const finishCountDown = () => {
        setIsSendCode(false)
    }
    const myInput:any = useRef(null)
    useEffect(() => {
        get2FaVerifyInfo();
    }, [])
    const handPressEnter = (e: any) => {
        console.log(e, 'event');
        if (e.key === 'Enter') {
          e.preventDefault(); // 阻止默认提交行为
          loginForm.submit();
        } 
      }

    const prefix = verifyType ==='1' ? <MailOutlined /> : <MobileOutlined />;
    return <div className='lz_login-container'  style={{ backgroundImage: `url(${state.styleStoreData.backgroundPic})` }}>
        <div className='lz_login-wel'   style={{ backgroundImage: `url(${state.styleStoreData.loginTitle})` }}></div>
        {verifyInfo && <div className='lz_login-card'> 
            <div className='lz_login-logo' style={{ backgroundImage: `url(${state.styleStoreData.loginLogo})` }}></div>
            <div className='lz_login-title'>{verifyType ==='1' ? '邮箱验证' : '手机验证'}</div>
             <Form initialValues={{ remember: true }} onFinish={handleLoginClick} style={{ width: '336px', marginTop: '32px' }} form={loginForm}>
                <Form.Item<FieldType>
                    name="username"
                    // rules={[{ required: true, message: `请输入${verifyType ==='1' ? '手机' : '邮箱'}号` }]}
                >
                    <div style={{display: 'none'}}>{verifyInfo}</div>
                    <UnderLineInput disable={true} isPrevent={false} ref={myInput} value={verifyInfo} label="username" placeholder={verifyType ==='1' ? '邮箱' : '手机'} fillLoginFormFun={fillLoginForm} prefix={prefix} visibilityToggle={false} />
                    <span style={{color: 'var(--text-font-color27)', fontSize:'var(--text-font3)', marginLeft: '20px'}}>{`若${verifyType ==='1' ? '邮箱号' : '手机号'}有误，请联系管理员`}</span>
                </Form.Item>

                <Form.Item<FieldType>
                    name="verifycode"
                    rules={[{ required: true, message: '请输入验证码' }]}
                >
                    <UnderLineInput ref={myInput} isPrevent={true} editFinish={handPressEnter} label="verifycode" placeholder='验证码' fillLoginFormFun={fillLoginForm} prefix={<SafetyCertificateOutlined />} suffix={
                        isSendCode ? 
                        <ButtonCountdown deadline={deadline} color='var(--text-font-color10)' size='16px' finishFun={finishCountDown} /> 
                        :
                        <Button type="link" className='lz_login-get-code-text' onClick={sendVerifyCode}>
                            获取验证码
                        </Button>
                } visibilityToggle={false} />
                {codeCount ?  <span style={{color: 'var(--text-font-color27)', fontSize:'var(--text-font3)', marginLeft: '20px'}}>验证码还可输入{codeCount}次，输错此账号将锁定</span> : ''}
                </Form.Item>

                <Form.Item<FieldType>
                    name="remember"
                    valuePropName="checked">
                        <div className='lz_login-remember-container'>
                          {/* <Checkbox className='lz_login-remmeber-check-box'>记住密码</Checkbox> */}
                          <div></div>
                          <Button type="link" className='lz_login-forget-button' onClick={handleForgetClick}>
                            忘记密码?
                          </Button>
                        </div>
                </Form.Item>

                <Form.Item>
                    <div onClick={handleLoginClick}>
                      <Button type="primary" block className='lz_login-button'>
                          确 定
                      </Button>
                    </div>
                </Form.Item>
            </Form>
            </div>}
        {/* <div className='lz_login-contact-container click-element'>
            <CustomerServiceOutlined />
            <span style={{ marginLeft: '3px' }}>联系我们</span>
        </div> */}
        {state.styleStoreData.brandStatus === '1' && (
        <Divider className='lz_login-provider-divider' plain style={{ color: 'var(--text-font-color4)' }}>
          <div style={{display: 'flex'}}>
            <div style={{color:'var(--text-font-color1)'}}>
              技术支持·陆泽科技有限公司
            </div>
            {/* <div className='lz_login-contact-container click-element'>
              <CustomerServiceOutlined />
              <span style={{ marginLeft: '3px' }}>联系我们</span>
            </div> */}
          </div>
        </Divider>      
      )}
            {state.styleStoreData.recordStatus === '1' && (
        <div className='lz_login-industrial'>
            {/* <img src={industrial} alt="" className='lz_login-industrial-img' /> */}
            {/* <span>中国工商 沪ICP备08100043号-73</span> */}
            <span>{state.styleStoreData.recordCode}</span>
        </div>
              )}
    </div>
}

export default LoginVerify;