import type { MenuProps } from 'antd';
import { LzMenu } from '../Types/LzMenu';
import Iconfont from '../component/Icon';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
export const createSubMenu = (list: LzMenu[]) => {
    let m: MenuItem[] = [];
    // eslint-disable-next-line
    list.map(item => {
        if (item.children) {
            const n = createSubMenu(item.children)
            if (item.icon) {
                m.push(getItem(item.label, item.key, <Iconfont icon={item.icon}></Iconfont>, n))
            } else {
                m.push(getItem(item.label, item.key, '', n))
            }
        } else {
            if (item.icon) {
                m.push(getItem(item.label, item.key, <Iconfont icon={item.icon}></Iconfont>))
            } else {
                m.push(getItem(item.label, item.key,))
            }
        }
    })
    return m
}

