import { AxiosPromise } from 'axios';
import request from '../../../api/network'
import {
    MemberCardListQuery, MemberCardListVO, ChangeMemberCardStatusQuery
} from './types';

//获取成长值变更列表
export function getMemberCardListApi(query: MemberCardListQuery): AxiosPromise<MemberCardListVO> {
    return request({
        url: '/crm/member/card/list',
        method: 'post',
        data: query,
    });
}

// 获得店铺列表
//获取成长值变更列表
export function getShopListApi(): AxiosPromise<any> {
    return request({
        url: '/crm/member/card/shopList',
        method: 'get',
    });
}

// 创建会员卡
export function handCreateMemberCardApi(query: any): AxiosPromise<any> {
    return request({
        url: '/crm/member/card/add',
        method: 'post',
        data: query,
    });
}

// 创建会员卡
export function handUpdateMemberCardApi(query: any): AxiosPromise<any> {
    return request({
        url: '/crm/member/card/update',
        method: 'post',
        data: query,
    });
}

// 关闭会员卡
export function handChangeMemberCardStatusApi(query: ChangeMemberCardStatusQuery): AxiosPromise<any> {
    return request({
        url: '/crm/member/card/updateStatus',
        method: 'post',
        data: query,
    });
}


// 查询会员卡
export function getMemberCardDetailsApi(query: any): AxiosPromise<any> {
    return request({
        url: `/crm/member/card/details/${query.id}`,
        method: 'get',
    });
}

// 查询会员卡
export function getMemberLevelListsApi(memberCardId: string | number): AxiosPromise<any> {
    return request({
        url: `/crm/member/grade/list/${memberCardId}`,
        method: 'get',
    });
}

// 查询渠道
export function getChannelListsApi(): AxiosPromise<any> {
    return request({
        url: '/crm/system/channel/list',
        method: 'get',
    });
}

// 判断会员卡编码是否存在
export function handCheckMemberCardCodeApi(data: { memberCardCode: string }): AxiosPromise<any> {
    return request({
        url: '/crm/member/card/checkoutCode',
        method: 'get',
        params: data
    });
}


// 上传图片
export function handUploadImgApi(query: any): AxiosPromise<any> {
    return request({
        url: '/resource/oss/upload',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        data: query,
    });
}