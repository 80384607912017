import {type FC, useEffect, useState} from 'react';
import {TimeTitle} from '@/crm/component/DataBoardStyles';
import {crmTipsMap} from '@/crm/assets/public/pageMap';
import {getInsightAnalysis as getInsightAnalysisApi, getRepurchaseIntervalAnalysis} from "@/crm/api/dataBoardApi";
import {useLocation} from "react-router-dom";
import RfmLineBarChart from "./components/RfmLineBarChart";

const RepurchaseInterval: FC = () => {
  const location = useLocation();
  const id = location?.state?.id ?? '';
  const [chartData, setChartData] = useState([])
  const getPlatformList = async (subReportId: string) => {
    await getRepurchaseIntervalAnalysis(subReportId).then((res) => {
      if (res?.code === 200) {
        const result = res?.data?.repurchase.map((item: any) => {
          return {
            rfmDate: item?.timeInterval,
            percentageOfCustomers: item?.clientRatio || 0,
            numberOfCustomers: item?.clientNum || 0,
          };
        });
        setChartData(result.length > 0 ? result : []);
      }
    });
  };
  const getRFMReportDetails = async () => {
    const detailsRes: any = await getInsightAnalysisApi(id);
    if (detailsRes?.code !== 200) {
      return;
    }
    getPlatformList(detailsRes.data?.rfmReport?.id);
  };
  useEffect(() => {
    getRFMReportDetails();
  }, []);
  return (
    <div>
      <div style={{marginTop: 8}}>
        <TimeTitle
          title="复购平均间隔分析"
          tips={crmTipsMap.get("复购平均间隔分析")}
          noTime
        />
        <div style={{marginTop: 24}}>
          {/*<RfmLineBarChart chartId="rfmLine" options={chartData}/>*/}
          <RfmLineBarChart data={chartData}/>
        </div>
      </div>
    </div>
  );
};

export default RepurchaseInterval;
