/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Col, Form, Input, Row } from 'antd';
import DatasetApi from '@/pages/LuZhiBI/api/dataset';
import type { FormProps } from 'antd';

interface IProps {
  type: 'create' | 'edit';
  defaultData: {
    categoryName: string;
    id: number;
  };
  ref: any;
  onEmitCloseDrawer: () => void;
}
type FieldType = {
  categoryName: string;
};

const DatasetCategory: FC<IProps> = forwardRef((props, ref) => {
  const { type } = props;

  const [form] = Form.useForm();

  const onSubmit: FormProps<FieldType>['onFinish'] = async (callback?: any) => {
    form.validateFields().then((res) => {
      const params: { categoryName: string, id?: number } = {
        categoryName: res.categoryName,
        id: props.defaultData.id,
      };
      if (type === 'create') {
        delete params.id;
      }
      DatasetApi.putDatasetCategory(params).then((res) => {
        if (!res) {
          return;
        }
        props.onEmitCloseDrawer();
        callback();
      });
    }).catch((err) => {
      console.log(err);
    });
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));
  const resetData = () => {
    // 根据id获取数据 并填充到表单中
    form.setFieldsValue({
      categoryName: props.defaultData.categoryName,
    });
  };
  useEffect(() => {
    if (type === 'create') {
      return;
    }
    resetData();
  }, []);

  return (
    <div>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              name="categoryName"
              label="分类名称"
              rules={[{ required: true, message: '请输入分类名称' }]}
            >
              <Input maxLength={100} showCount placeholder="请输入分类名称" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});

export default DatasetCategory;