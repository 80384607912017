import React, { useState, useEffect } from 'react';
import { Drawer, Button, Space, Select, message } from "antd";
import type { DrawerProps } from "antd";
import { selectNotContainUserList, addAccount } from '../../../../../../api/group/group';
import { GroupListVO } from '../../../../../../api/group/types';
import styles from './index.module.scss';

const AddAccount = (props: {
  addOpen: boolean,
  groupData: GroupListVO,
  getAddOpen: Function,
  submitSuccess: Function,
}) => {
  const { 
    addOpen,
    groupData,
    getAddOpen = () => {},
    submitSuccess = () => {},
  } = props;

  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');
  const [open, setOpen] = useState(false);
  //添加-防连击
  const [addStatus, setAddStatus] = useState(false);
  const [account, setAccount] = useState([] as number[]);
  const [accountOptions, setAccountOptions] = useState([]);
  const getSelectNotContainUserList = async () => {
    const param = {
      groupId: groupData.id
    };
    const {data} = await selectNotContainUserList(param);
    const store: any = [];
    data.forEach((v: { userId: any; userName: any; }) => {
      const obj: any = {
        value: v.userId,
        label: v.userName
      };
      store.push(obj);
    });
    setAccountOptions(store);
  };

  // 提交
  const submit = async () => {
    if (addStatus) {
      return
    }
    setAddStatus(true)
    const param = {
      id: groupData.id,
      ids: account
    };
    const res = await addAccount(param)
    if (res) {
      message.success('添加成功');
      getAddOpen(false);
      setAccount([]);
      submitSuccess();
    }
    setAddStatus(false)
  };

  // 关闭抽屉
  const onClose = () => {
    getAddOpen(false);
    setAccount([]);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    setOpen(addOpen);
    if(addOpen){
      getSelectNotContainUserList();
    }
  }, [addOpen])

  return (
    <div>
      <Drawer
        title="添加账号"
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{marginRight: 8}}>取消</Button>
            <Button type="primary" disabled={ account.length === 0 } onClick={submit}>确定</Button>
          </Space>
        }
      >
        <div>
          <div className={styles.textStyle}>用户组名称：{groupData.name}</div>
          <div className={styles.textStyle}>用户组ID：{groupData.id}</div>
          <div className={styles.textStyle} style={{ marginBottom: 8 }}><span>*</span>添加账号</div>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={filterOption}
            placeholder="请选择"
            value={account}
            onChange={(e) => setAccount(e)}
            options={accountOptions}
            />
        </div>
      </Drawer>
    </div>
  );
};

export default AddAccount