import React, { useState, useEffect } from "react";
import { Drawer, Button,Space, Form, Input, Upload, message, Select } from "antd";
import type { DrawerProps, UploadFile, TableColumnsType } from "antd";
import {
  customerList,
  getExcel,
  createLabelInfoExcel,
  createGroupInfoExcel
} from "../../../../api/label/label";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import { download } from '../../../../api/network';
import type { UploadProps } from 'antd';
import { UploadOutlined , DownloadOutlined } from '@ant-design/icons';
import { relative } from "path";
const { Dragger } = Upload;

// 下载CSV模板 需要的识别码的参数
let onlyCodeName = '客户唯一标识' as any;
let uploadFileStatus = false;//上传文件状态
let fileUpLoad = {} as any;
const ImportMes = (props: {
  openImportMes: boolean;
  closeOpenImportMes: Function; 
  handleAddFile?: Function; 
  popType: number;  //1 标签 2分群
  fileTitle?: string;  //文件名称
  labelId?: any;  //labelId
  crowdId?: any;  //crowdId
}) => {
  const { openImportMes, closeOpenImportMes, handleAddFile = () => {} } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadFileFinish, setUploadFileFinish] = useState( false);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);
  const textArea = props.popType == 1 ? '对该标签值进行追加数据/全量数据更新' :'对该分群进行追加数据/全量数据更新';
  const [dataType, setDataType] = useState('');
  const [accountForm] = Form.useForm();
  // 关闭当前抽屉 清空数据
  const onClose = () => {
    closeOpenImportMes(false);
    setDataType('')
    accountForm.setFieldValue("dataType", null);
    fileUpLoad = {};
    setFileList([]);
    setUploadFileFinish(false);
  };

  

  // 关闭提示弹窗
  const hidden = () => {
  };
   //上传文件
   const CustomRequest = (props:any) => {
    const { onSuccess, onError, file, onProgress } = props;
 
    // 这里可以编写你的上传逻辑，例如使用FormData等
    const formData = new FormData();
    formData.append('file', file);
    
    // 模拟上传进度
    setTimeout(() => {
      onProgress({ percent: 100 });
      onSuccess("mock-upload-response");
    }, 2000);
  }
  
  const handleChange = async (info: any) => {
    // fileUpLoad = info.file.originFileObj
    let newFileList = [...info.fileList]
    newFileList = newFileList.slice(-2);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);

    fileUpLoad = info.file.originFileObj;
    if (uploadFileFinish) {
      fileUpLoad = {}
    }
    setUploadFileFinish(!uploadFileFinish);
  };
  const beforeUpload = (file:any) => {
    console.log("========",file);
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ;
    const isXls = file.name.endsWith('.xls');
    const isCsv = file.name.endsWith('.csv');
    const isSize = file.size <= 25 * 1024 *1024;
    uploadFileStatus = false;
    
    if (!isXlsx && !isXls && !isCsv) {
      message.error(`请上传.xlsx .xls .csv 的文件`);
      uploadFileStatus = true;
      return
    }
    if (!isSize) {
      message.error(`文件最大支持25mb`);
      uploadFileStatus = true;
      return
    }
    return (isXlsx || isXls || isCsv) && isSize || Upload.LIST_IGNORE;
  };
  useEffect(() => {
    propertyOptions.map((item:any)=>{
      if (item.id == dataType) {
        onlyCodeName = item.label
      }
    })
    console.log("===");
  }, [dataType])
  // 下载CSV模板
  const toDownLoad = async() => {
    // const param: any = {
    //   labelType: onlyCodeName,
    //   type: props.popType 
    // };
    // const res = await getExcel(param);
    // window.location.href = res.msg;
    download(`/resource/oss/getExcelE?labelType=${onlyCodeName}&type=${props.popType}`)
  };
  //用户唯一标识码 列表
  const [propertyOptions, setPropertyOptions] = useState([] as any);
  // 获取用户属性
  const getProfilePropertyList = async () => {
    const { data } = await customerList();
    data.forEach((v: any, i: number) => {
      v.label = v.fieldName;
      v.value = v.id;
    });
    setPropertyOptions(data);
  };
  const submit = async (isFinalConfirm: boolean) => {
    if (!dataType) {
      return message.warning("请选择客户唯一识别码");
    }
    if (Object.keys(fileUpLoad).length === 0 ) {
      return message.warning("请上传文件");
    }
    if (uploadFileStatus) {
      return message.warning("请上传.xlsx .xls .csv 的文件");
    }
    const formData = new FormData();
      formData.append('req', new Blob([JSON.stringify({
        dataType:dataType,
        flagType: 2,  // 0 新建 1 追加  2 数据更新
        taskName: props.fileTitle, //文件名称
        taskLocation: props.popType == 1 ? `导入标签_${props.fileTitle}-${dayjs().format("YYYYMMDDHHmmss")}` : `导入分群_${props.fileTitle}-${dayjs().format("YYYYMMDDHHmmss")}`, //页面位置
        createMode: props.popType == 1 ? "导入标签" : '导入分群',
        crowdId: props?.crowdId,
        labelId: props?.labelId,
      })], { type: 'application/json' }));
      formData.append('file', fileUpLoad);
      let res: any = {};
      if (props.popType  == 1) {
        res = await createLabelInfoExcel(formData);
      }
      else{
        res = await createGroupInfoExcel(formData);
      }
      if (res) {
        if (res.code == 200) {
          message.success("数据更新成功" );
          setTimeout(() => {
            onClose();
            handleAddFile()
          }, 1000);
        }
      } else {
      }
  };
  useEffect(() => {
    setOpen(openImportMes);
    getProfilePropertyList();
    // if (localStorage.getItem('setGroupDetail') && props.popType == 2) {
    //   var info: any = JSON.parse(localStorage.getItem('setGroupDetail') as any)
      // setUserInfoRecord(info)
    // }
  }, [openImportMes]);
  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={'导入文件'}
        placement={placement}
        width={700}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <div style={{ marginRight: props.popType == 1 ? 160 :170 , color:'var(--text-font-color12)',fontSize :'14px'}}>{textArea}</div>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button type="primary" onClick={() => submit(false)}>
              确定
            </Button>
          </Space>
        }
      >        <Form form={accountForm} layout="vertical">
          <Form.Item
              label="客户唯一识别码"
              name="dataType"
              rules={[{ required: true, message: "请选择客户唯一识别码" }]}
            >
              <Select
                placeholder="请选择"
                value={dataType}
                options={propertyOptions}
                onChange={(value)=>setDataType(value)}
              />
          </Form.Item>
          <Form.Item
              label="上传"
              name="crowdName"
              rules={[{ required: true , message: "请上传文件" }]}
            >
              <div className={styles.downLoadBox}>
                <Upload 
                  customRequest={CustomRequest} 
                  accept=".xlsx,.xls,.csv" 
                  onChange={handleChange}
                  beforeUpload={beforeUpload} 
                  fileList={fileList}
                >
                  {!uploadFileFinish && <Button icon={<UploadOutlined />}>再次上传</Button>}
                </Upload>
                <div className={styles.modelDownLoad} onClick={toDownLoad}>下载CSV模板</div>
              </div>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default ImportMes;
