//授权管理
import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import type { TabsProps } from 'antd';
import { Tabs, Card, Select, Checkbox, Button, Tag, Input, message } from 'antd';
import ModuleTitle from '../../../../component/ModuleTitle';
import { getSysVersionListApi, getSysVersionReviseDateApi } from '../../../../api/userIntersection/userIntersection';

// let edition: any = {};
function AuthorizationManagement() {
  const [edition, setEdition] = useState<any>();
  const [data, setData] = useState<any>();
  const [days, setDays] = useState<string>();

  const versionTipsList = [
    {
      label: '应用工程名',
      value: edition?.projectName || '-',
    },
    {
      label: '系统版本号',
      value: edition?.projectEdition || '-',
    },
    {
      label: '更新日期',
      value: edition?.updateTime || '-',
    },
  ];
  const functionTipsList = [
    {
      label: '系统首页',
    },
    {
      label: '数据看板',
    },
    {
      label: '忠诚度管理',
    },
    {
      label: '用户洞察',
    },
    {
      label: '用户标签',
    },
    {
      label: '用户分群',
    },
    {
      label: '智慧营销',
    },
    {
      label: '数据档案',
    },
    {
      label: '系统配置',
    },
    {
      label: '下载中心',
    },
  ];
  const authorizationTipsList = [
    {
      label: '公司名称',
      value: data?.companyName || '-',
    },
    {
      label: '公司编码',
      value: data?.companyCode || '-',
    },
    {
      label: '授权版本',
      value: data?.version || '-',
    },
    {
      label: '到期时间',
      value: data?.expireDate || '-',
    },
    {
      label: '授权秘钥',
      value: data?.key || '-',
    },
    {
      label: '秘钥状态',
      value: data?.status || '-',
    },
    {
      label: '激活人',
      value: data?.activator || '-',
    },
    {
      label: '激活时间',
      value: data?.activeTime || '-',
    },
  ];
  const daysOption = [
    { value: '90天', label: '90天' },
    { value: '45天', label: '45天' },
    { value: '30天', label: '30天' },
    { value: '15天', label: '15天' },
    { value: '7天', label: '7天' },
  ];
  // 版本信息
  const VersionInfo = () => {
    return (
      <div className={style.versionInfo}>
        {versionTipsList.map(it => (
          <div className={style.versionItem} key={it.label}>
            <span>{it.label}：</span>
            <span className={style.infoText}>{it.value}</span>
          </div>
        ))}
      </div>
    );
  };
  // 功能列表
  const FunctionList = () => {
    return (
      <div className={style.functionBox}>
        {functionTipsList.map(it => (
          <div className={style.functionItem} key={it.label}>
            <Tag color="default">{it.label}</Tag>
          </div>
        ))}
      </div>
    );
  };
  // 提醒设置
  const ReminderSetting = () => {
    return (
      <div className={style.reminderBox}>
        <div className={style.tipItem}>授权过期提醒：</div>
        <div>
          <div className={style.tipItem}>
            <span>提前</span>
            <Select
              style={{ width: 120, marginLeft: 10, marginRight: 10 }}
              value={days}
              options={daysOption}
              onChange={e => {
                setDays(e);
              }}
            />
            <span>提醒更新</span>
          </div>
          <Checkbox className={style.bottonBox} disabled={true} checked={true}>
            平台通知
          </Checkbox>

          <div className={style.bottonBox}>
            <Button
              type="default"
              onClick={() => {
                setDays(edition.days);
              }}>
              取消
            </Button>
            <Button type="primary" style={{ marginLeft: 20 }} onClick={getSysVersionReviseDate}>
              确定
            </Button>
          </div>
        </div>
      </div>
    );
  };
  // tabs
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '版本信息',
      children: <VersionInfo />,
    },
    {
      key: '2',
      label: '功能列表',
      children: <FunctionList />,
    },
    {
      key: '3',
      label: '提醒设置',
      children: <ReminderSetting />,
    },
  ];
  // 基本信息
  const [type, setType] = useState('1');
  const EssentialInfo = () => {
    return (
      <div>
        <span className={style.info}>基本信息</span>
        <Tabs
          onChange={e => {
            setType(e);
          }}
          activeKey={type}
          type="card"
          items={items}
          style={{ marginTop: 16 }}
        />
      </div>
    );
  };
  // 授权信息
  const AuthorizationInfo = () => {
    return (
      <div className={style['margin-24']}>
        <span className={style.info}>授权信息</span>
        <div className={style.authorizationInfo}>
          {authorizationTipsList.map((it: any, ix: number) => (
            <div className={[style.authorizationItem, style[`authorizationItem-${ix}`]].join(' ')} key={it.label}>
              <span>{it.label}：</span>
              {it.label !== '授权秘钥' ? (
                <span className={style.infoText}>{it.value}</span>
              ) : (
                // <div className={style.infoBox}>{it.value}</div>
                <Input placeholder="请输入秘钥" disabled style={{ width: '308px' }} value={it.value} />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const getSysVersionList = async () => {
    const res = await getSysVersionListApi();
    console.log(res);
    if (res.code === 200) {
      setEdition(res.data.edition);
      setData(res.data);
      setDays(res.data.edition.days);
    }
  };
  const getSysVersionReviseDate = async () => {
    const res = await getSysVersionReviseDateApi({ days });

    if (res.code === 200) {
      message.success('修改成功');
    }
  };

  useEffect(() => {
    getSysVersionList();
  }, []);
  useEffect(() => {}, [edition]);
  return (
    <div>
      <div className={style.headBox}>
            <ModuleTitle mark="large" title="授权管理" />
      </div>
      <div className={style['container']}>
        <div className={style['titleAndOperate']}>
          <div style={{ width: '100%' }}>
            <div className={style['paddingBox']}>
              <EssentialInfo />
              <AuthorizationInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorizationManagement;
