/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, type FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Button, Table } from 'antd';
import type { TableProps } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { getToolTipText } from '@/pages/LuZhiBI/utils';
import styles from './index.module.scss';

interface DataType {
  [key: string]: string | number;
  id: string;
  time: string;
}

const DatasetView: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [indicatorData, setIndicatorData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<TableProps<DataType>['columns']>([]);
  const onReturn = () => {
    navigate(-1);
  };
  const getData = () => {
    const resData = location.state?.data;
    setColumns(resData?.fields.map((item: any) => ({
      title: item.columnName,
      dataIndex: item.columnId,
      key: item.columnId,
      render: (text: string) => getToolTipText(text, { placeholder: '-' }),
    })));
    // 数据
    setIndicatorData(resData?.data.map((item: any, index: number) => ({
      ...item,
      id: index,
    })));
  };
  useEffect(() => {
    getData();
  }, [location.state?.id]);
  return (
    <div className={styles.DatasetView}>
      <div className={styles.titleBox}>
        <Flex gap="small" align="center">
          <Button
            size="small"
            style={{
              color: 'rgba(0,0,0,0.45)',
              backgroundColor: 'rgba(0,0,0,0.06)',
              border: 'none',
            }}
            icon={<LeftOutlined />}
            onClick={onReturn}
          >
            返回
          </Button>
          <span className={styles.title}>{location.state?.datasetName ?? '数据集名称'}</span>
        </Flex>
      </div>
      <div className={styles.tableBox}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={indicatorData}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default DatasetView;

