import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import { Drawer, Space, Button, Form, Input, Radio, Select, message } from "antd";
import style from './index.module.scss'

type FieldType = {
    categoryName: string;
    type: number
    id?: number
};

let AddCategoryDrawer = React.forwardRef((props: {
    open: boolean,
    cateList: any[],
    closeDraw: Function,
    submitAddCate: Function,
}, ref: any) => {
    const {
        open,
        cateList,
        closeDraw = () => { },
        submitAddCate = () => { }
    } = props
    const [form] = Form.useForm();

    const [selectType, setSelectType] = useState(1)

    const change = (e: any) => {
        setSelectType(e.target.value)
        form.setFieldValue('type', e.target.value)

    }
    useEffect(() => {
        form.setFieldValue('type', 1)
        form.setFieldValue('categoryName', '')
        form.setFieldValue('id', null)

        setSelectType(1)
        cateList.map(item => {
            item.label = item.categoryName
            item.value = item.id
        })
        console.log(cateList);

    }, [open])

    const submit = () => {
        if (!form.getFieldValue('categoryName')) {
            message.warning('请输入类目名称')
            return
        }
        if (form.getFieldValue('type') === 2 && !form.getFieldValue('id')) {
            message.warning('请选择一级类目')
            return
        }
        submitAddCate({ categoryName: form.getFieldValue('categoryName'), type: form.getFieldValue('type'), id: form.getFieldValue('type') === 1 ? null : form.getFieldValue('id') })

    }

    return <Drawer className={style['container']} open={open} title="新建类目" extra={
        <Space>
            <Button onClick={() => { closeDraw() }}>取消</Button>
            <Button type="primary" onClick={() => { submit() }}>
                确定
            </Button>
        </Space>
    } onClose={() => { closeDraw() }}>
        <Form
            layout='vertical'
            form={form}
            initialValues={{ layout: 'vertical' }}

        >
            <Form.Item<FieldType> label="类目名称" rules={[{ required: true }]} name='categoryName'>
                <Input placeholder="请输入类目名称" maxLength={20} showCount autoComplete="off" />
            </Form.Item>
            <Form.Item<FieldType> label="类目层级" rules={[{ required: true }]} name='type'>
                <Radio.Group onChange={change}>
                    <Radio value={1}>一级类目</Radio>
                    <Radio value={2}>二级类目</Radio>
                </Radio.Group>
            </Form.Item>
            {selectType === 2 && <Form.Item<FieldType> name='id' style={{ marginTop: -16 }}>
                <Select placeholder="请选择所属一级类目" options={cateList} optionLabelProp='label'></Select>
            </Form.Item>}
        </Form>
    </Drawer>

})

export default AddCategoryDrawer