import { AxiosPromise } from 'axios';
import request from '../network'
import { AccountQuery, AccountVO } from './types';


//获取用户列表
export function getUserListApi(query: AccountQuery): AxiosPromise<AccountVO[]> {
    return request({
        url: '/system/user/list',
        method: 'get',
        params: query
    });
}

//添加用户
export function addAccount(query: any): AxiosPromise<any> {
    return request({
        url: '/system/user/add',
        method: 'post',
        data: query,
    })
}

//修改用户状态
export function changeStatus(query: any): AxiosPromise<{}> {
    return request({
        url: '/system/user/changeStatus',
        method: 'get',
        params: query,
    })
}

//修改用户状态
export function modifyAccount(query: any): AxiosPromise<{}> {
    return request({
        url: '/system/user/edit',
        method: 'post',
        data: query,
    })
}

//重置密码
//修改用户状态
export function resetPwd(userId: number): AxiosPromise<{}> {
    return request({
        url: `/system/user/resetPwd/${userId}`,
        method: 'get',
        params: {},
    })
}

// 删除用户
export function deleteAccountApi(userId: number): AxiosPromise<{}> {
    return request({
        url: `/system/user/${userId}`,
        method: 'delete',
        params: {},
    })
}