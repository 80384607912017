import { useState, useEffect } from "react";
import * as echarts from "echarts";
import closeFullScreenPic from "@/pages/LuZhiBI/assets/images/closeFullScreen.png";
import fullScreenPic from "@/pages/LuZhiBI/assets/images/fullScreen.png";
import styles from "../TableStyle/index.module.scss";
import ts from "typescript";

let chartInstance: echarts.ECharts | null = null;
function BarStyle(props: any) {
  // 当维度包含日期时，日期作为横坐标，日期字段寻找集合['year', 'quarter', 'month', 'week', 'day', 'date']
  const dateArr = ["year", "quarter", "month", "week", "day", "date", "月份"];
  const { dataSource, index } = props;
  const [fullScreen, setFullScreen] = useState(false); // 回答答案是否全屏
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data, setData] = useState<any>([]);
  const [xFieldValue, setXFieldValue] = useState<any>([]);
  const [filedName, setFiledName] = useState<any>({
    xFieldName: "",
    yFieldName: "",
    thirdFieldName: "",
  }); // 横纵坐标字段名称
    const dimensions = dataSource.dimension; // 全部维度
    const dimensionKeys = dataSource.dimensionKey; // 全部维度的key
    // const dimensionValues = Object.values(); // 全部维度的value
    const metrics = dataSource.metric; // 全部指标
    const metricsKeys = dataSource.metricKey; // 全部指标的key
    const originalValue = dataSource.value;

  useEffect(() => {
    // 维度dimension大于1个时，则选择日期作为横坐标
    // 维度大于1个
    if (dimensionKeys.length > 1) {
      let findX = "";
      let findY = "";
      let third = "";
      let xFieldArr: any[];
      // 得到横坐标数组
      dimensionKeys.forEach((item: string, index: number) => {
        dateArr.forEach((dateItem) => {
          if (dateItem.includes(item)) {
            findX = dimensionKeys[index];
            third = dimensionKeys.filter(
              (itemKey: string) => itemKey !== item
            )[0];
            xFieldArr = originalValue
              .map((originalValueItem: { [x: string]: any }) => {
                return originalValueItem[item];
              })
              .reduce((accumulator: any[], currentValue: any) => {
                if (!accumulator.includes(currentValue)) {
                  accumulator.push(currentValue);
                }
                return accumulator;
              }, []).sort((a:any, b: any) => a - b);
            setXFieldValue(xFieldArr);
          }
        });
      });
      // 赋值横坐标字段名称
      setFiledName({ ...filedName, xFieldName: findX });
      // 赋值纵坐标分类名称
      setFiledName({ ...filedName, thirdFieldName: third });

      const uniqueThirdArr = originalValue
        .map((item: { [x: string]: any }) => {
          return item[third];
        })
        .filter(
          (value: any, index: any, self: string | any[]) =>
            self.indexOf(value) === index
        );

      // 数值根据月份匹配
      let yDataArr: {
        name: any;
        type: string;
        emphasis: { focus: string };
        data: null[];
      }[] = [];
      uniqueThirdArr.forEach((item: any) => {
        let valueArr: null[] = [];
        xFieldArr?.forEach((xItem: any, xIndex: number) => {
          const test = originalValue.find(
            (i: any) => i[third] === item && i[findX] === xItem
          );
          if (test !== null && test !== undefined) {
            let value = test[metricsKeys[0]];
            // @ts-ignore
            valueArr.push(Number(value));
          } else {
            valueArr.push(null);
          }
        });
        yDataArr.push({
          name: item,
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: valueArr,
        });
      });
      setData(yDataArr);
    } else {
      // 得到横坐标数组
      const xName = Object.values(dimensions)[0];
      const yName = Object.values(metrics)[0];
      // @ts-ignore
      setXFieldValue(originalValue.map((item: { [x: string]: any }) => item[xName]));
      // setXFieldValue(Object.values(dimensions)[0]);
      const barData = dataSource.value;
      let barDataArr: {
        value: number;
        name: string;
      }[] = [];
      if (barData && barData.length > 0) {
        originalValue.forEach((item: { [x: string]: any }) => {
            // @ts-ignore
          barDataArr.push({
            // @ts-ignore
            value: Number(item[yName]),
            // @ts-ignore
            name: item[dimensionKeys[0]],
          })
        })
      }
      setData(barDataArr);
    }
  }, []);

  const [instanceArr, setInstanceArr] = useState<any>([]);
  useEffect(() => {
    let option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      xAxis: {
        type: "category",
        data: xFieldValue,
      },
      yAxis: {
        type: "value",
      },
      grid: {
        left: 100,
      },
      series: dimensionKeys.length > 1 ?data:[
      {
        data: data.map((item: { value: string }) => item.value),
        type: "bar",
        barWidth: 30,
      },
    ],
    };
    let chartDom = document.getElementById(`barChart_${index}`);
    let chartDomFullScreen = document.getElementById(
      `barChartFullScreen_${index}`
    );
    if (instanceArr[index]) {
      instanceArr[index].dispose();
    }
    chartInstance = echarts.init(fullScreen ? chartDomFullScreen : chartDom);
    instanceArr[index] = chartInstance;
    setInstanceArr([...instanceArr]);
    chartInstance.setOption(option);
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      chartInstance?.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        chartInstance?.resize();
      });
    };
  }, [windowWidth, fullScreen, data]);
  return (
    <div>
      <div className={styles.fullScreen}>
        <img src={fullScreenPic} onClick={() => setFullScreen(true)} />
      </div>
      <div id={`barChart_${index}`} style={{ height: "300px" }} />
      {fullScreen && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <span
              className={styles.close}
              onClick={() => {
                setFullScreen(false);
              }}
            >
              <img src={closeFullScreenPic} />
            </span>
            <div
              id={`barChartFullScreen_${index}`}
              style={{ height: "600px", width: "900px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default BarStyle;
