/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Table, Flex, Form, Input, Select, Row, Col, Space, Button } from 'antd';
import type { TableProps } from 'antd';
import SearchIconInput from '@/pages/LuZhiBI/components/SearchIconInput/searchIconInput';
import DatasetApi from '@/pages/LuZhiBI/api/dataset';
import Filter from '@/pages/LuZhiBI/components/Filter';
import { getToolTipText } from '@/pages/LuZhiBI/utils';

interface IProps {
  ref: any;
  dict: any;
  dimensionIds: number[];
  defaultSelectedKeys: React.Key[];
}

interface DataType {
  id: string;
  IndicatorCategory: string;
  IndicatorName: string;
}

type FieldType = {
  indicatorCategory: string;
  indicatorName: string;
};

const initColumns: TableProps<DataType>['columns'] = [
  {
    title: '指标名称',
    dataIndex: 'indicatorName',
    key: 'indicatorName',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '指标分类',
    dataIndex: 'indicatorCategory',
    key: 'indicatorCategory',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
];

const IndicatorChoose: FC<IProps> = forwardRef((props, ref) => {
  const { dict, dimensionIds, defaultSelectedKeys } = props;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSlectedChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const [indicatorOptions, setIndicatorOptions] = useState<{ label: string; value: string }[]>([]);
  const [indicatorData, setIndicatorData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState(initColumns);
  const onSearch = (value: string) => {
    getIndicatorData(value);
  };
  const getIndicatorOptions = () => {
    const result = dict?.map((item: any) => ({
      label: item.dictLabel,
      value: item.dictValue,
    }));
    result.unshift({ label: '全部', value: '' });
    setIndicatorOptions(result);
  };
  const getIndicatorData = (search = '') => {
    const { indicatorName = '', indicatorCategory = '' } = form.getFieldsValue();
    const params = {
      dimensionIds,
      search,
      indicatorCategoryIds: indicatorCategory ? [indicatorCategory] : null,
      name: indicatorName,
    };
    DatasetApi.getIndicatorList(params).then(res => {
      if (!res) {
        return;
      }
      setIndicatorData(res?.data ?? []);
      if (selectedRowKeys.length > 0) {
        setSelectedRowKeys(selectedRowKeys.filter(key => res.data.map((item: any) => item.id).includes(key)));
      }
    });
  };
  useEffect(() => {
    setColumns(pre => {
      return pre.map(item => {
        if (item.key === 'indicatorCategory') {
          return {
            ...item,
            render: text => {
              const target = indicatorOptions.find(option => option.value === text);
              return getToolTipText(target?.label);
            },
          };
        }
        return item;
      });
    });
  }, [indicatorOptions]);
  useEffect(() => {
    getIndicatorOptions();
    getIndicatorData();
  }, []);
  useEffect(() => {
    setSelectedRowKeys(defaultSelectedKeys);
  }, [defaultSelectedKeys]);
  const selectedRows = useMemo(() => {
    return indicatorData.filter(item => selectedRowKeys.includes(item.id));
  }, [selectedRowKeys, indicatorData]);
  useImperativeHandle(ref, () => ({
    selectedRows,
  }));
  return (
    <div>
      <Flex justify="space-between" align="center">
        <span>已选 {selectedRowKeys.length} 个</span>
        <Flex justify="center">
          <SearchIconInput placeholder="请输入指标名称或指标分类进行搜索" width={300} onSearch={onSearch} />
          <Filter hoverTitle="过滤器" nodeID="filter-main">
            <Form
              form={form}
              onFinish={() => {
                getIndicatorData();
              }}
              initialValues={{
                indicatorName: '',
                indicatorType: '',
              }}
            >
              <Row gutter={16} wrap style={{ marginTop: 24 }}>
                <Col span={12}>
                  <Form.Item<FieldType> name="indicatorName" label="指标名称">
                    <Input placeholder="请输入指标名称" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<FieldType> style={{ flex: 1 }} name="indicatorCategory" label="指标类型">
                    <Select placeholder="请选择指标类型" options={indicatorOptions} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Flex justify="flex-end">
                    <Form.Item>
                      <Space size="small">
                        <Button htmlType="reset">重置</Button>
                        <Button type="primary" htmlType="submit">
                          查询
                        </Button>
                      </Space>
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
            </Form>
          </Filter>
        </Flex>
      </Flex>
      <div id="filter-main"></div>
      <div style={{ marginTop: 24 }}>
        <Table
          columns={columns}
          dataSource={indicatorData}
          pagination={false}
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            onChange: onSlectedChange,
          }}
        />
      </div>
    </div>
  );
});

export default IndicatorChoose;

