import { useRef } from "react";
import { handUploadImgApi } from "@/crm/api/memberCardList";

import { Upload, message } from "antd";

// 上传图片尺寸限制
const checkImageWH = (file: any, width: number, height: number) => {
  // 参数分别是上传的file，想要限制的宽，想要限制的高
  return new Promise((resolve: any) => {
    let filereader = new FileReader();
    filereader.onload = (e: any) => {
      let src = e.target.result;
      const image = new Image();
      image.onload = function () {
        if (image.naturalWidth === width && image.naturalHeight === height) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
      image.src = src;
    };
    filereader.readAsDataURL(file);
  });
};

const ImageUpload = ({
  children,
  disabled = false,
  imgIndex,
  getImageUrl,
}: {
  children: any;
  disabled?: boolean;
  imgIndex?: number | undefined;
  getImageUrl: (url: string, index: number | undefined) => void;
}) => {
  const pending = useRef(false);
  const beforeUpload = async (file: any) => {
    const isRight = ["image/png", "image/jpeg"].includes(file.type);
    if (!isRight) {
      message.error("只能上传 .png / .jpg 图片");
      pending.current = true;
      return false;
    }
    const res = await checkImageWH(file, 44, 44);
    if (!res) {
      message.error("图片宽高需为 44x44 像素");
      pending.current = true;
      return false;
    }
    pending.current = false;
    return isRight;
  };

  const handleChange = async (info: any) => {
    if (pending.current) return;
    pending.current = true;
    const params = {
      file: info.file.originFileObj,
    };
    const res = await handUploadImgApi(params);
    if (res?.code === 200 && res?.data?.url) {
      getImageUrl(res?.data?.url || "", imgIndex);
    } else {
      message.error("图片上传失败");
    }
    pending.current = false;
  };

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      disabled={disabled}>
      {children}
    </Upload>
  );
};

export default ImageUpload;
