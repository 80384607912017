import React, { ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import style from './index.module.scss'
import { Table, TableProps, message } from "antd"; //Progress
import { DatePicker, DatePickerProps, Empty, Select, SelectProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { findCdpLogsApi } from '../../../../../../api/userGroup/group';
import DownLoadLog from "../../../../../UserGrouping/components/DownLoadLog";

const { RangePicker } = DatePicker;
let cycleDays = [7, 31, 365];

let OperationLog = forwardRef((props: {
    labelId: string,
    title?: string, //标签表头名称
    // renewTime: string,
    calculateStatus?: string | number, //计算状态 0: 未计算 1: 计算中 2: 成功 3: 失败
}, ref: any) => {
    // 表格数据
    let [listData, setListData] = useState([] as any)
    // 选中周期
    const [selectedCycle, setSelectedCycle] = useState(0)
    // 选择显示的周期
    const selectCycle: SelectProps['options'] = [
        {
            label: '按周',
            value: 0,
            queryType: 'week'
        },
        {
            label: '按月',
            value: 1,
            queryType: 'month'
        },
        {
            label: '按年',
            value: 2,
            queryType: 'year'
        },
    ]

    // 下拉选择周期
    const changeSelectedCycle = (e: any) => {
        checkDate(e)
        // if (e !== 2) {
            setListQuery({
                ...listQuery,
                startDay: [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM-DD'),
                // startDay: dayjs().add(-cycleDays[selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0 ? [dayjs(props.creatTime), dayjs()][0].format('YYYY-MM-DD') : [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM-DD'),
                endDay: dayjs().format('YYYY-MM-DD')
            })
        // }
        //  else {
        //     setListQuery({
        //         ...listQuery,
        //         startDay: [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM'),
        //         // startDay: dayjs().add(-cycleDays[selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0 ? [dayjs(props.creatTime), dayjs()][0].format('YYYY-MM') : [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM'),
        //         endDay: dayjs().format('YYYY-MM')
        //     })
        // }
        setSelectedCycle(e)
    }

    const checkDate = (e?: any) => {
        // if (dayjs().add(-cycleDays[e ?? selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0) {
        //     setDates([dayjs(props.creatTime), dayjs()])
        //     setDateValue([dayjs(props.creatTime), dayjs()])
        // } else {
        setDates([dayjs().add(-cycleDays[e ?? selectedCycle], 'days'), dayjs()])
        setDateValue([dayjs().add(-cycleDays[e ?? selectedCycle], 'days'), dayjs()])
        // }
    }

    const [dates, setDates] = useState([dayjs().add(-7, 'days'), dayjs()] as any);
    const [dateValue, setDateValue] = useState([dayjs().add(-7, 'days'), dayjs()] as any);

    // 禁止选择的日期
    const disabledDate = (current: any) => {
        if (!dates) {
            return false;
        }
        let tooLate = null
        let tooEarly = null
        let cycleDays = [7, 31, 365];
        tooLate = dates[0] && ((current.diff(dates[0], 'days') > cycleDays[selectedCycle]) || (dates[0].diff(current, 'days') > cycleDays[selectedCycle]));
        tooEarly = dates[1] && ((dates[1].diff(current, 'days') > cycleDays[selectedCycle]) || (current.diff(dates[1], 'days') > cycleDays[selectedCycle]));

        return current.valueOf() >= dayjs().valueOf() || !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open: boolean) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };

    // 改变时间时重新给查询条件/日期赋值
    const onChangeDate = (val: any, string: any) => {
        setDateValue(val)
        
        // setListQuery({
        //     ...listQuery,
        //     startDay: selectedCycle > 1 ? val[0].format('YYYY-MM') : val[0].format('YYYY-MM-DD'),
        //     endDay: selectedCycle > 1 ? val[1].format('YYYY-MM') : val[1].format('YYYY-MM-DD')
        // })
        setListQuery({
            ...listQuery,
            startDay: val[0].format('YYYY-MM-DD'),
            endDay: val[1].format('YYYY-MM-DD')
        })
    }

    // 查询条件
    const [listQuery, setListQuery] = useState({
        businessId: props.labelId,
        businessType: 'LABEL',
        startDay: dayjs(dateValue[0]).format('YYYY-MM-DD'),
        endDay: dayjs(dateValue[1]).format('YYYY-MM-DD'),
        pageNum: 1,
        pageSize: 20,
    })
    const [total, setTotal] = useState(0)

    const [listStatus, setListStatus] = useState(false)
    const pushListData = async () => {
        if (listStatus) return
        setListStatus(true)
        let param = {
            ...listQuery,
            businessId: props.labelId,
        }
        const res = await findCdpLogsApi(param)
        if (res) {
            setListData(res.rows ?? [])
            setTotal(res.total ?? 0)
        } else {
            setListData([])
            setTotal(0)

        }
        setListStatus(false)
    }

    // 换页
    const handleTableChange = (pagination: any, _: any) => {
        setListQuery({
            ...listQuery,
            pageSize: pagination.pageSize,
            pageNum: pagination.current,
        });
    };
    interface DataType {
        operateTime: string,
        operator: string,
        action: string,
        operateDetail: string,
        id: number,
    }

    let list = [
        {
            value: 'ADD',
            text: '新建',
        },
        {
            value: 'UPDATE',
            text: '编辑',
        },
        {
            value: 'ENABLE',
            text: '启用',
        },
        {
            value: 'DISABLE',
            text: '禁用',
        },
        {
            value: 'DELETE',
            text: '删除',
        },
        {
            value: 'DOWNLOAD',
            text: '下载',
        },
        {
            value: 'UPLOAD',
            text: '上传',
        },
        {
            value: 'DATAUPDATE',
            text: '数据更新',
        },
    ]

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '操作时间',
            dataIndex: 'operateTime',
            key: 'operateTime',
        },
        {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
            title: '操作项',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <>
                    {
                        list.map((item, index) => {
                            if (item.value === record.action) {
                                return <div key={`${item.text}-${index}`}> {item.text}</div>
                            }
                        })
                    }
                </>
            ),
        },
        {
            title: '操作详情',
            dataIndex: 'operateDetail',
            key: 'operateDetail',
            render: (_, record) => (
                <>
                    <div dangerouslySetInnerHTML={{ __html: !record.operateDetail ? '__' : record.operateDetail }}></div>
                    {/* 只有上传的时候有按钮 需修改 */}
                    {record.action == 'UPLOAD' && <div className={style['checkUpload']} onClick={()=>openDownLoadLog(record)}>点击查看上传文件</div>}
                </>
            ),
        }
    ];
    
    //抽屉  true  开启
    const [downLoadLog, setDownLoadLog] = useState(false);
    const [downloadId, setDownloadId] = useState('');
    // 打开下载抽屉
    const openDownLoadLog = (item:any) => {
        setDownLoadLog(true);
        setDownloadId(item.downloadId);
    };
    // 关闭下载抽屉
    const closeDownLoadLog= (v: boolean) => {
        setDownLoadLog(v);
    };
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        pushListData
    }))
    //监听或进入调用
    useEffect(() => {
        pushListData()
    }, [listQuery])

    return <div className={style['container']}>
        <div className={style['container_title']}>
            <div className={style['container_title_query']}>
                <Select defaultValue={selectedCycle} options={selectCycle} style={{ width: 160 }} onChange={changeSelectedCycle}></Select>
                <RangePicker
                    placeholder={['开始时间', '结束时间']}
                    format="YYYY-MM-DD"
                    value={dates || dateValue}
                    allowClear={false}
                    disabledDate={disabledDate}
                    style={{ margin: '0 10px' }}
                    onCalendarChange={val => setDates(val)}
                    onChange={onChangeDate}
                    onOpenChange={onOpenChange}
                />
            </div>
            {/* <div>数据更新时间：{props.renewTime}</div> */}
        </div>
        <Table
            style={{ marginTop: 10 }}
            columns={columns}
            dataSource={listData}
            rowKey={(record) => record.id}
            pagination={{
                total: total,
                pageSize: listQuery.pageSize,
                current: listQuery.pageNum,
                showSizeChanger: true,
                // pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: total => `共 ${total} 条`,
                // defaultCurrent: 1
            }}
            onChange={handleTableChange}
        />
         <DownLoadLog
          // searcParam={searcParam}
          title={props.title}
          type={1}
          downLoadLog={downLoadLog}
          closeDownLoadLog={closeDownLoadLog}
          downloadId={downloadId}
          // userInfoType={userInfoType}
          labelId={props.labelId}
          // labelValueId={labelValueId}
          // renewTime={renewTime}
          // crowdId={localStorage.getItem('setGroupDetail') ? JSON.parse(localStorage.getItem('setGroupDetail') as any).crowdId : ''}
        />
    </div>
})
export default OperationLog
