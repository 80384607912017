import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Drawer, Button, Table, message, Popconfirm, Tooltip } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import SearchIconInput from "../../../../../../component/Input/SearchIconInput/searchIconInput";
import MiniLabel from "../../../../../../component/MiniLabel";
import AddAccount from "../AddAccount";
import {
  getAccountList,
  removeAccount,
} from "../../../../../../api/group/group";
import {
  AccountListQuery,
  AccountListQueryVO,
  GroupListVO,
} from "../../../../../../api/group/types";
import styles from "./index.module.scss";

const AssociatedAccount = (props: {
  associationOpen: boolean;
  editData: GroupListVO;
  getAssociationOpen: Function;
}) => {
  const { associationOpen, editData, getAssociationOpen = () => {} } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);

  const [listData, setListData] = useState([] as AccountListQueryVO[]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  //删除-防连击
  const [delStatus, setDelStatus] = useState(false);
  // 关闭当前抽屉
  const onClose = () => {
    getAssociationOpen(false);
    setListData([]);
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // 删除 & 批量删除
  const deleteItem = async (f: string, id: any) => {
    if (delStatus) {
      return
    }
    setDelStatus(true)
    let store = [];
    if (f === "single") {
      // 单独删除
      store.push(id);
    } else {
      // 批量删除
      store = [...selectedRowKeys];
    }
    const param = {
      id: editData.id,
      ids: store,
    };
    const res = await removeAccount(param);
    if (res && res.data) {
      message.success("移除成功");
      getAccountListFunction(
        { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
        idOrName,
        sortValue
      );
      setResetConfirm(false);
      setBatchResetConfirm(false);
      if (f === "multiple") {
        setSelectedRowKeys([]);
      }
      setDelStatus(false)
      // 操作成功后是否要消除批量选中的id？
    } else {
      message.error(res.msg);
      setDelStatus(false)
    }
  };

  // 获取状态文本
  const getStatusText = (v: string) => {
    let obj = {} as { text: string; labelStyle: string };
    switch (v) {
      case "0":
        obj = { text: "正常", labelStyle: "addData" };
        break;
      case "1":
        obj = { text: "停用", labelStyle: "disable" };
        break;
      case "2":
        obj = { text: "锁定", labelStyle: "lock" };
        break;
      default:
        break;
    }
    return obj;
  };

  const [tableConfig, setTableConfig] = useState({} as any);
  const [selectedTableKey, setSelectedTableKey] = useState(
    null as unknown as number
  );
  const [resetConfirm, setResetConfirm] = useState(false);
  const [batchResetConfirm, setBatchResetConfirm] = useState(false);

  //设置标特操作提示框文案
  const setTableConfirmText = (f: string, num?: number) => {
    if (f === "single") {
      setTableConfig({
        title: "确定从用户组移除此账号吗？",
        okText: "确定",
        cancelText: "取消",
        description: "",
      });
    } else {
      setTableConfig({
        title: `确定从用户组移除此${selectedRowKeys.length}个账号吗？`,
        okText: "确定",
        cancelText: "取消",
        description: () => (
          <div>
            用户组名称：
            <span style={{ color: "var(--text-font-color7)" }}>
              {editData.name}
            </span>
          </div>
        ),
      });
    }
  };

  // 单个删除确定
  const handleConfirmClick = async (item?: AccountListQueryVO) => {
    deleteItem("single", item?.userId);
  };

  // 批量移除确定
  const batchHandleConfirmClick = () => {
    deleteItem("multiple", null);
  };

  // 取消
  const handleCancelClick = () => {
    setResetConfirm(false);
    setBatchResetConfirm(false);
    setSelectedTableKey(null as unknown as number);
  };

  const columns: TableProps<AccountListQueryVO>["columns"] = [
    {
      title: "账号名称",
      dataIndex: "userName",
      key: "userName",
      // width: "130px",
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "账号ID",
      dataIndex: "userId",
      key: "userId",
      // width: "130px",
    },
    {
      title: "账号状态",
      dataIndex: "status",
      key: "status",
      // width: "130px",
      render: (_: any, record: any) => (
        // 用户状态0 正常 1 停用 2锁定
        <span>
          <MiniLabel
            text={getStatusText(_).text}
            labelStyle={getStatusText(_).labelStyle}
          />
        </span>
      ),
    },
    {
      title: "所属组织",
      dataIndex: "deptName",
      key: "deptName",
      // width: "130px",
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "所属用户组",
      dataIndex: "userGroupName",
      key: "userGroupName",
      // width: "125px",
    },
    {
      title: "添加时间",
      dataIndex: "createTime",
      key: "createTime",
      // width: "130px",
      sorter: true,
    },
    {
      title: "操作",
      key: "action",
      fixed: "right",
      align: "center",
      // width: "88px",
      render: (_, record) => (
        <div>
          <Popconfirm
            placement="right"
            title={tableConfig.title}
            okText={tableConfig.okText}
            cancelText={tableConfig.cancelText}
            description={tableConfig.description}
            onConfirm={() => handleConfirmClick(record)}
            onCancel={() => handleCancelClick()}
            open={selectedTableKey === record.userId && resetConfirm}
            onPopupClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DeleteOutlined
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                setSelectedTableKey(record.userId);
                setTableConfirmText("single");
                setResetConfirm(true);
                setBatchResetConfirm(false);
                e.nativeEvent.stopPropagation();
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  // 查询参数
  const [idOrName, setIdOrName] = useState("");

  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "createTime",
    isAsc: "desc",
  });

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if(!sorter.order){
      sortStore = {orderByColumn: 'createTime', isAsc: 'desc'};
    } else {
      sortStore = {orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc'};
    }

    getAccountListFunction(
      { pageSize: pagination.pageSize, pageNum: pagination.current },
      idOrName,
      sortStore
    );
  }

  // 添加账号抽屉
  const [addOpen, setAddOpen] = useState(false);
  const getAddOpen = (v: boolean) => {
    setAddOpen(false);
  };

  // 添加账号成功回调
  const submitSuccess = () => {
    getAccountListFunction({ pageSize: 10, pageNum: 1 }, "", {
      orderByColumn: "createTime",
      isAsc: "desc",
    });
  };

  // 获取用户组账号列表
  const getAccountListFunction = async (
    page: any,
    idOrName: string,
    sort: { orderByColumn: string; isAsc: string }
  ) => {
    const param = {
      groupId: editData.id,
      idOrName,
      ...page,
      ...sort,
    } as AccountListQuery;
    const { total, rows } = await getAccountList(param);
    rows.forEach((v) => {
      v.key = v.userId;
    });
    setListData(rows);
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
  };

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
    setBatchResetConfirm(false);
  };

  useEffect(() => {
    setOpen(associationOpen);
    if (associationOpen) {
      getAccountListFunction(
        {
          pageSize: pageQuery.pageSize,
          pageNum: pageQuery.pageNum,
        },
        idOrName,
        sortValue
      );
    } else {
      setIdOrName('');
    }
  }, [associationOpen]);

  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title="关联账号"
        placement={placement}
        width={960}
        onClose={onClose}
        open={open}
      >
        <div>
          <div className={styles.searchBox}>
            <div style={{ marginRight: 8 }}>
              <SearchIconInput
                onSearch={(e: string) => {
                  setIdOrName(e);
                  getAccountListFunction({ pageSize: 10, pageNum: 1 }, e, {
                    orderByColumn: "createTime",
                    isAsc: "desc",
                  });
                }}
                placeholder="请输入账号名称/ID"
              ></SearchIconInput>
            </div>
            <span
              style={{
                width: "0",
                height: "16px",
                border: "var(--border3)",
                marginRight: 16,
                marginTop: 8
              }}
            ></span>
            <Popconfirm
              placement="right"
              title={tableConfig.title}
              okText={tableConfig.okText}
              cancelText={tableConfig.cancelText}
              description={tableConfig.description}
              onConfirm={() => batchHandleConfirmClick()}
              onCancel={() => handleCancelClick()}
              open={batchResetConfirm}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button
                style={{ marginRight: 18 }}
                disabled={selectedRowKeys.length === 0}
                onClick={(e) => {
                  setTableConfirmText("multiple");
                  setBatchResetConfirm(true);
                  setResetConfirm(false);
                  e.nativeEvent.stopPropagation();
                }}
              >
                批量移除
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              onClick={() => {
                setAddOpen(true);
              }}
            >
              添加账号
            </Button>
          </div>
          <Table
            style={{ width: "100%", marginTop: "24px" }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={listData}
            scroll={{ y: 580 }}
            pagination={{
              pageSize: pageQuery.pageSize,
              total: pageQuery.total,
            }}
            onChange={handleStandardTableChange}
          />
        </div>
      </Drawer>
      <AddAccount
        addOpen={addOpen}
        groupData={editData}
        getAddOpen={getAddOpen}
        submitSuccess={submitSuccess}
      />
    </div>
  );
};

export default AssociatedAccount;
