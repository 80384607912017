import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { Form, Checkbox, Button, Divider, Input, message } from 'antd';
import UnderLineInput from '../../../component/Input/UnderLineInput/underLineInput';
import { UserOutlined, LockOutlined, CustomerServiceOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import loginType1 from './../../../assets/images/loginType1.png';
import loginType2 from './../../../assets/images/loginType2.png';
import loginType3 from './../../../assets/images/loginType3.png';
import industrial from './../../../assets/images/industrial.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCodeImageApi, userLoginApi, getUserCodeImageApi, jumpLoginApi } from '../../../api/auth/auth';
import { LoginData } from '../../../api/auth/types';
import { setUserToken, setMenu, changeCurrentMenuIndex, setStyleStoreData } from '../../../store';
import { LzMenu } from '../../../Types/LzMenu';
import { setCurrentPath } from './../../../store/index';
import { getSysVersionAppearanceApi } from '../../../api/userIntersection/userIntersection';

type FieldType = {
  username?: string;
  password?: string;
  code?: string;
  remember?: string;
};

let imageCodeObj: any = {};
let isReturnDate = false;

const Login = () => {
  const [loginForm] = Form.useForm();

  //图片二维码
  const [codeImage, setCodeImage] = useState('');
  // const [uuid, setUuid] = useState('')
  const [codeUuid, setCodeUuid] = useState('');
  // 是否展示验证码
  const [showCode, setShowCode] = useState(false);
  //登录-防连点
  const [loginStatus, setLoginStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loginBtnText, setLoginBtnText] = useState('登录');
  // 样式数据
  const [styleData, setStyleData] = useState<any>({});
  const handleLoginClick = async () => {
    console.log(loginForm.getFieldValue('username'));

    if (!loginForm.getFieldValue('username')) {
      message.warning('请输入账号');
      return;
    }
    if (!loginForm.getFieldValue('password')) {
      message.warning('请输入密码');
      return;
    }
    if (loginForm.getFieldValue('password').length < 8 || loginForm.getFieldValue('password').length > 32) {
      message.warning('用户密码必须在8到32个字符之间');
      return;
    }
    // 存缓存里，重置密码需要用到
    localStorage.setItem('username', loginForm.getFieldValue('username'));
    localStorage.setItem('password', loginForm.getFieldValue('password'));
    if (!isReturnDate) {
      // console.log('未获取到codeByUserName接口数据 需要重新调用');
      getUserCodeImage(loginForm.getFieldValue('username'));
    }
    if (showCode && !loginForm.getFieldValue('code')) {
      message.warning('请输入验证码');
      return;
    }
    console.log(loginStatus);

    if (loginStatus) {
      return;
    }
    setLoginStatus(true);
    setLoginBtnText('登录中...');
    const data = {
      tenantId: '000000',
      username: loginForm.getFieldValue('username'),
      password: loginForm.getFieldValue('password'),
      rememberMe: false,
      code: loginForm.getFieldValue('code'),
      uuid: codeUuid,
    } as LoginData;
    const res = await userLoginApi(data);
    if (res) {
      setLoginBtnText('登录');
      setUserToken(res.data.access_token, res.data.clientId);
      // setMenu(menuList)
      // console.log(res.data.accessType, 'res.data.accessType');
      localStorage.setItem('accessType', res.data.accessType);
      // res.data.accessType = 'INIT_PASSWORD'
      switch (res.data.accessType) {
        case 'NORMAL':
          navigate('/');
          break;
        case 'PHONE':
          navigate(`/loginVerify?type=forget&username=${encodeURIComponent(loginForm.getFieldValue('username'))}`);
          break;
        case 'EMAIL':
          navigate(`/loginVerify?type=forget&username=${encodeURIComponent(loginForm.getFieldValue('username'))}`);
          break;
        case 'EMAIL_BIND':
          navigate(`/reset?type=${'bindEmail'}`);
          break;
        case 'PHONE_BIND':
          navigate(`/reset?type=${'bindPhone'}`);
          break;
        case 'INIT_PASSWORD':
          navigate(`/reset?type=${'reset'}`);
          break;
        case 'PASSWORD_EXPIRED':
          navigate(`/reset?type=${'reset'}`);
          break;
        case 'ADMIN_RESET_PASSWORD':
          navigate(`/reset?type=${'reset'}`);
          break;
        default:
          break;
      }
      setLoginStatus(false);
    } else {
      setLoginBtnText('登录');
      getUserCodeImage(loginForm.getFieldValue('username'));
      getCodeImage();
      setLoginStatus(false);
    }
  };

  // const jumpLogin = async () => {
  //   await jumpLoginApi()
  //   navigate('/')
  // }
  const fillLoginForm = (obj: any) => {
    console.log(obj, 'obj');
    loginForm.setFieldsValue(obj);
  };

  //获取登录验证码
  const getCodeImage = async () => {
    imageCodeObj = await getCodeImageApi();
    if (imageCodeObj) {
      setCodeImage(imageCodeObj.data.img);
      setCodeUuid(imageCodeObj.data.uuid);
    }
  };
  // 样式接口
  const getSysVersionAppearance = async () => {
    const res = await getSysVersionAppearanceApi();
    if (res.code === 200) {
      setStyleData(res.data);
      setStyleStoreData(res.data);
    }
  };
  useEffect(() => {
    setMenu([]);
    changeCurrentMenuIndex(0);
    // 当用户不触发用户名和密码框获得焦点事件时
    if (loginForm.getFieldValue('username')) {
      getUserCodeImage(loginForm.getFieldValue('username'));
    }
    //获取登录验证码
    getCodeImage();
    getSysVersionAppearance();
  }, []);

  // 判断用户是否需要验证码登录
  const getUserCodeImage = async (userName: any) => {
    isReturnDate = false;
    const res = await getUserCodeImageApi(userName);
    if (res) {
      isReturnDate = true;
      setShowCode(res.data.captchaEnabled as boolean);
    } else {
      isReturnDate = true;
    }
  };

  const usernameLostBlur = () => {
    // console.log('失去焦点');
    if (loginForm.getFieldValue('username')) {
      setTimeout(() => {
        getUserCodeImage(loginForm.getFieldValue('username'));
      }, 1000);
    }
  };

  //忘记密码
  const handleForgetClick = () => {
    // 将当前path路径传到state里保存
    setCurrentPath(location.pathname);
    navigate(`/validation?type=forget&username=${encodeURIComponent(loginForm.getFieldValue('username'))}`);
  };
  // 阻止enter默认提交表单
  const handPressEnter = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // 阻止默认提交行为
      loginForm.submit();
    }
  };

  return (
    <div className="lz_login-container" style={{ backgroundImage: `url(${styleData.backgroundPic})` }}>
      <div className="lz_login-wel" style={{ backgroundImage: `url(${styleData.loginTitle})` }}></div>
      <div className="lz_login-card">
        <div className="lz_login-logo" style={{ backgroundImage: `url(${styleData.loginLogo})` }}></div>
        <div className="lz_login-title">登录</div>
        <Form initialValues={{ remember: true }} onFinish={handleLoginClick} style={{ width: '336px', marginTop: '32px' }} form={loginForm}>
          <Form.Item<FieldType>>
            <UnderLineInput
              lostBlur={usernameLostBlur}
              isPrevent={true}
              editFinish={(e: any) => e.preventDefault()}
              placeholder="请输入账号"
              prefix={<UserOutlined />}
              fillLoginFormFun={fillLoginForm}
              label="username"
              isLoginPage={true}
            />
          </Form.Item>
          <Form.Item<FieldType> style={{marginBottom: showCode? '32px' : '24px'}}>
            <UnderLineInput
              isPrevent={true}
              placeholder="请输入密码"
              editFinish={handPressEnter}
              prefix={<LockOutlined />}
              isPassWord={true}
              visibilityToggle={false}
              fillLoginFormFun={fillLoginForm}
              label="password"
              isLoginPage={true}
            />
          </Form.Item>
          {showCode && (
            <Form.Item <FieldType> style={{marginBottom: '24px'}}>
              <UnderLineInput
                fillLoginFormFun={fillLoginForm}
                isPrevent={true}
                editFinish={handPressEnter}
                label="code"
                placeholder="验证码"
                prefix={<SafetyCertificateOutlined />}
                suffix={<img onClick={getCodeImage} src={`data:image/png;base64,${codeImage}`} className="lz_login-code-image click-element" />}
                visibilityToggle={false}
              />
            </Form.Item>
          )}

          <Form.Item<FieldType> name="remember" valuePropName="checked" >
            <div className="lz_login-remember-container">
              <div></div>
              <Button type="link" className="lz_login-forget-button" onClick={handleForgetClick}>
                忘记密码?
              </Button>
            </div>
          </Form.Item>
          <Form.Item>
            <div onClick={handleLoginClick}>
              <Button type="primary" block className="lz_login-button">
                {loginBtnText}
              </Button>
            </div>
          </Form.Item>
        </Form>
        {/* <Divider className='lz_login-divider' plain>其他登录方式</Divider>
    <div className='lz_login-other-way'>
      <img className='lz_login-way-icon' src={loginType1} alt="" />
      <img className='lz_login-way-icon' src={loginType2} alt="" />
      <img className='lz_login-way-icon' src={loginType3} alt="" />
          <Checkbox onChange={(e) => setRememberMe(e.target.checked)} className='lz_login-remmeber-check-box'>记住密码</Checkbox>

    </div> */}
      </div>
      {styleData.brandStatus === '1' && (
        <Divider className="lz_login-provider-divider" plain style={{ color: 'var(--text-font-color4)' }}>
          <div style={{ display: 'flex' }}>
            <div style={{color:'var(--text-font-color1)'}}>技术支持·陆泽科技有限公司</div>

            {/*<div className='lz_login-contact-container click-element'>
        <CustomerServiceOutlined />
        <span style={{ marginLeft: '3px' }}>联系我们</span>
  </div>*/}
          </div>
        </Divider>
      )}

      {styleData.recordStatus === '1' && (
        <div className="lz_login-industrial">
          {/* <img src={industrial} alt="" className='lz_login-industrial-img' /> */}
          <span>{styleData.recordCode}</span>
        </div>
      )}
    </div>
  );
};

export default Login;
