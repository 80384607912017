import { AxiosPromise } from 'axios';
import request from '../network'
import { ImageCodeData, LoginData, LoginResult, UserInfo } from "./types";


// pc端固定客户端授权id
const clientId = process.env.REACT_APP_CLIENT_ID


//获取验证码
export function getUserCodeImageApi(userName: string): AxiosPromise<ImageCodeData> {
    return request({
        url: '/auth/codeByUserName',
        headers: {
            isToken: false,
            isEncrypt: false
        },
        method: 'get',
        params: { userName }
    });
}

//获取验证码
export function getCodeImageApi(): AxiosPromise<ImageCodeData> {
    return request({
        url: '/auth/code',
        headers: {
            isToken: false,
            isEncrypt: false
        },
        method: 'get',
        data: {}
    });
}

//登录
export function userLoginApi(data: LoginData): AxiosPromise<LoginResult> {
    const params = {
        ...data,
        clientId: data.clientId || clientId,
        grantType: data.grantType || 'password'
    };
    return request({
        url: '/auth/login',
        headers: {
            isToken: false,
            isEncrypt: true
        },
        method: 'post',
        data: params
    });
}

//登录
export function jumpLoginApi(): AxiosPromise<any> {

    return request({
        url: '/auth/setNoLimit',
        method: 'get'
    });
}

// 退出登录
export function outLoginApi(): AxiosPromise<any> {

    return request({
        url: '/auth/logout',
        method: 'post'
    });
}

//获取用户信息
export function getUserInfoApi(): AxiosPromise<UserInfo> {
    return request({
        url: '/system/user/getInfo',
        method: 'get'
    });
}

// 查看用户详情
export function getUserDetailApi(userId:any): AxiosPromise<any> {
    return request({
        url: '/system/user/detail',
        method: 'get',
        params: { userId }
    });
}
// 获取个人中心数据
export function getPersonalInfo(): AxiosPromise<any> {
    return request({
        url: '/system/personal/getPersonalInfo',
        method: 'get',
    });
}
// 个人中心-修改密码
export function resetUserPsw(data:any): AxiosPromise<any> {
    return request({
        url: '/system/system/user/resetPsw',
        method: 'get',
        params: data
    });
}
// 个人中心-修改/绑定手机号
export function updateUserPhone(data:any): AxiosPromise<any> {
    return request({
        url: '/system/personal/bindPhone',
        method: 'get',
        params: data
    });
}
// 个人中心-修改/绑定邮箱
export function updateUserEmail(data:any): AxiosPromise<any> {
    return request({
        url: '/system/personal/bindEmail',
        method: 'get',
        params: data
    });
}
// 个人中心-修改姓名
export function resetUserName(data:any): AxiosPromise<any> {
    return request({
        url: '/system/personal/resetName',
        method: 'get',
        params: data
    });
}

// 个人中心-发送验证码
export function sendUserCode(data:any): AxiosPromise<any> {
    return request({
        url: '/system/personal/sendCode',
        method: 'get',
        params: data
    });
}

// 获取双因子脱敏信息
export function get2FaDesInfo(): AxiosPromise<any> {
    return request({
        url: '/auth/get2FaDesInfo',
        method: 'get',
    });
}
// 双因子发送验证码
export function login2faSendCode(): AxiosPromise<any> {
    return request({
        url: '/auth/login2faSendCode',
        method: 'get',
    });
}
// 多因子验证码登录
export function login2fa(code: string): AxiosPromise<any> {
    return request({
        url: '/auth/login2fa',
        method: 'get',
        params: { code: code }
    });
}
// 重置密码
export function resettingPassWord(params:any): AxiosPromise<any> {
    return request({
        url: '/system/password/resettingPassWord',
        method: 'get',
        params: params
    });
}
// 首次登陆绑定邮箱手机号（发送验证码）
export function loginBindGetCaptcha(params:any): AxiosPromise<any> {
    return request({
        url: '/auth/loginBindGetCaptcha',
        method: 'get',
        params: params
    });
}
// 首次登陆绑定邮箱或手机号
export function loginBindCheckCode(params:any): AxiosPromise<any> {
    return request({
        url: '/auth/loginBindCheckCode',
        method: 'get',
        params: params
    });
}

//忘记密码-获取手机/邮箱 是否置灰
export function forgetPswApi(username: string): AxiosPromise<any> {
  return request({
    url: '/system/password/forgetPsw',
    method: 'get',
    params: { userName: username }
  });
}

// 忘记密码-获取手机/邮箱 脱敏
export function getPhoneOrEmailApi(username: string, type: number): AxiosPromise<any> {
  return request({
    url: '/system/password/getPhoneOrEmail',
    method: 'get',
    params: { userName: username, type }
  });
}

// 忘记密码-获取验证码
export function getForgetPasswordCaptchaApi(username: string, type: number): AxiosPromise<any> {
  return request({
    url: '/system/password/getForgetPasswordCaptcha',
    method: 'get',
    params: { userName: username, type }
  });
}

// 忘记密码-验证验证码
export function checkCaptchaApi(username: string, captcha: string, type: number): AxiosPromise<any> {
  return request({
    url: '/system/password/checkCaptcha',
    method: 'get',
    params: { username: username, captcha, type }
  });
}

export function resetPasswordAfterCheckApi(username: string, password: string,token:any): AxiosPromise<any> {
 
  return request({
    url: '/system/password/resetPasswordAfterCheck',
    headers: {
      isToken: false,
      isEncrypt: true,
      Authorization:'Bearer ' + token
  },
    method: 'post',
    data: {password:password}
  });
}
