import React, { type FC, useEffect, useState } from "react";
import style from "./index.module.scss";
import { Tabs, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { TextWithQuestion } from "../../../../../component/MiniStyles";
import { crmTipsMap } from "../../../../../assets/public/pageMap";
import RF from "./RF";
import RM from "./RM";
import RFM from "./RFM";
import RepurchaseInterval from "./RepurchaseInterval";
import {
  getInsightAnalysis as getInsightAnalysisApi,
  getProductLines,
} from "@/crm/api/dataBoardApi";
import { useLocation } from "react-router-dom";

const getUniqueValues = (arr: any[], key: string) => {
  const values = arr.map((item) => item[key]);
  return Array.from(new Set(values)).join("、");
};

// 路由跳转 从 state 接收 id
const RFMAnalysisResult: FC = () => {
  const navigate = useNavigate();
  const [subReportId, setSubReportId] = useState(0);
  const [pageTabKey, setPageTabKey] = useState("1");
  const [rfmInfo, setRfmInfo] = useState<any>({}); // RFM分析报告详情
  const [productNameList, setProductNameList] = useState<string[]>([]);
  const location = useLocation();

  const getRFMReportDetails = async () => {
    const detailsRes: any = await getInsightAnalysisApi(location.state.id);
    if (detailsRes?.code !== 200) {
      return;
    }
    setSubReportId(detailsRes.data?.rfmReport?.id);
    setRfmInfo({
      ...detailsRes.data?.rfmReport,
      subReportUpdateTime: detailsRes.data?.subReportUpdateTime,
    });
    await getProductLines().then((res) => {
      const productNameArr: string[] = [];
      if (res?.code === 200) {
        detailsRes.data?.rfmReport?.productList?.forEach(
          (productIdItem: string) =>
            res.data.forEach(
              (productItem: { prodctLineId: string; name: string }) => {
                if (productIdItem === productItem.name) {
                  productNameArr.push(productItem.name);
                }
              }
            )
        );
        setProductNameList(productNameArr);
      }
    });
  };

  useEffect(() => {
    getRFMReportDetails();
  }, []);

  // 上层tabs
  const tabList = [
    {
      key: "1",
      label: (
        <TextWithQuestion
          title="RF分析"
          tips={crmTipsMap.get("RF分析")}
          black
        />
      ),
      children: <RF id={subReportId} />,
    },
    {
      key: "2",
      label: (
        <TextWithQuestion
          title="RM分析"
          tips={crmTipsMap.get("RM分析")}
          black
        />
      ),
      children: <RM id={subReportId} />,
    },
    {
      key: "3",
      label: (
        <TextWithQuestion
          title="RFM分析"
          tips={crmTipsMap.get("RFM分析")}
          black
        />
      ),
      children: <RFM id={subReportId} />,
    },
    {
      key: "4",
      label: (
        <TextWithQuestion
          title="复购间隔分析"
          tips={crmTipsMap.get("复购间隔分析")}
          black
        />
      ),
      children: <RepurchaseInterval />,
    },
  ];

  return (
    <div className={style["RFMAnalysis-bgd"]}>
      {/* 标题 */}
      <div className={style["top-info-card"]}>
        <div className={style["title-box"]}>
          <div style={{ display: 'flex' }}>
          <div
            className={style.returnBtn}
            onClick={() => {
              navigate(-1);
            }}
          >
            <LeftOutlined style={{ marginRight: 8 }} />
            返回
          </div>
          <p className={style["title"]}>RFM分析报告</p>
          </div>
          <p className={style["time"]}>
            数据更新时间：{rfmInfo?.subReportUpdateTime}
          </p>
        </div>
        <div className={style["info-card"]}>
          <div className={style["channel"]}>
            <p className={style["title"]}>
              购买渠道：{getUniqueValues(rfmInfo?.shopList ?? [], "platformName")}
            </p>
          </div>
          <div className={style["shop"]}>
            <p className={style["title"]}>
              购买店铺：
              <Tooltip
                overlayInnerStyle={{ width: "400px" }}
                title={getUniqueValues(rfmInfo?.shopList ?? [], "shopName")}
              >
                {getUniqueValues(rfmInfo?.shopList ?? [], "shopName").substring(
                  0,
                  20
                )}
                {getUniqueValues(rfmInfo?.shopList ?? [], "shopName").length >
                  20 && <span>......</span>}
              </Tooltip>
            </p>
          </div>
          <div className={style["channel"]}>
            <p className={style["title"]}>
              首次入会渠道：{getUniqueValues(rfmInfo?.firstJoinShopList ?? [], "platformName") || '--'}
            </p>
          </div>
          <div className={style["joining-time"]}>
            <p className={style["title"]}>
              产品线：
              {productNameList.length === 0 ? 
                <span>全部</span>
                :
              <Tooltip
                overlayInnerStyle={{ width: "900px" }}
                title={productNameList.join(",")}
              >
                {productNameList.join(",").substring(0, 20)}
                {productNameList.join(",").length > 20 && <span>......</span>}
              </Tooltip>
            }
            </p>
          </div>
          {/* <div className={style['joining-time']}>
            <p className={style['title']}>入会时间：{rfmInfo?.startTime} 至 {rfmInfo?.endTime}</p>
          </div> */}
           <div className={style["analysis-cycle"]}>
            {rfmInfo?.startDate && rfmInfo?.endDate && (
              <p className={style["title"]}>
                分析周期：{rfmInfo?.startDate} 至 {rfmInfo?.endDate}
              </p>
            )}
          </div>
         
          
        </div>
      </div>
      {/* 上层tab */}
      <Tabs
        activeKey={pageTabKey}
        items={tabList}
        onChange={(e) => setPageTabKey(e)}
        style={{ marginTop: -12 }}
      />
    </div>
  );
};

export default RFMAnalysisResult;
