import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Drawer, Space, Radio, DatePicker, Modal, message } from "antd";
import type { TableProps, DrawerProps, RadioChangeEvent } from "antd";
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from "dayjs";
import ModuleTitle from "../../../../component/ModuleTitle";
import MiniLabel from "../../../../component/MiniLabel";
import PromitModal from "../DataAccess/components/PromitModal";
import { DataAccessDataDetailApi, AppendDataApi, EnableCheckDetialidApi } from "../../../../api/collect/collect";
import { DataAccessDetailsListVO } from "../../../../api/collect/types";
import TaskCenter from "./components/TaskCenter"
import styles from './index.module.scss';
import { format } from 'path';
import {
  LeftOutlined
} from '@ant-design/icons';

const { RangePicker } = DatePicker;

const DataAccessDetails = () => {
  const [loading, setLoading] = useState(true);

  const [notShow, setNotShow] = useState(false);

  const navigate = useNavigate();

  const promitModalRef: any = useRef(null);

  let location = useLocation();
  
  const [accesslistData, setAccessListData] = useState(location.state.record);

  const [listData, setListData] = useState([] as DataAccessDetailsListVO[]); 

  const columns: TableProps<DataAccessDetailsListVO>["columns"] = [
    {
      title: "序号",
      dataIndex: "sort",
      key: "sort",
      width: '88px',
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: "数据表名称",
      dataIndex: "datatableNames",
      key: "datatableNames",
      // width: '40%'
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
      // width: '40%'
    },
    {
      title: "操作",
      key: "action",
      align:'center',
      width: '88px',
      render: (_, record: any) => (
        <div>
          <Button type="link" onClick={() => navigate('/files/originalDataAccess/dataAccess/details/list',  { state: {record: location.state.record, pageQuery: location.state.pageQuery, recordDetail: record, page: pageQuery } })}>查看</Button>
          {
            notShow && <Button type="link" disabled={accesslistData.status === '1'} onClick={() => openAddDrawer(record)}>追加历史数据</Button>
          }
        </div>
      ),
    },
  ];

  // 配置追加信息抽屉开关
  const [additionalConfigurationOpen, setAdditionalConfigurationOpen] = useState(false);
  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

  // 追加历史数据 — 确定       
  const appendHistoricalData = async() => {
    if(loading){
      setLoading(false);
      const param = {
        detailsId: addRecord.id,
        accessId: addRecord.accessId,
        datatableNames: addRecord.datatableNames,
        shopId: accesslistData.shopId,
        useType: radioValue,
        startTime: `${dayjs(date[0]).format('YYYY-MM-DD')} 00:00:00`,
        endTime: `${dayjs(date[1]).format('YYYY-MM-DD')} 23:59:59`,
      };
  
      const res = await AppendDataApi(param);
      setLoading(true);
      if(res && res.code === 505){
        message.error(res.msg);
        // setPromitTitle(res.msg);
        // promitModalRef.current.openModal();
      } else if(res){
        setPromitTitle('追加历史数据任务进行中');
        promitModalRef.current.openModal();
      };
    }
  };

  // 任务中心抽屉开关
  const [taskCenterOpen, setTaskCenterOpen] = useState(false);
  // 获取任务中心抽屉开关状态
  const getOpenStatus = (v: boolean) => {
    setTaskCenterOpen(v);
  };


  const [radioValue, setRadioValue] = useState('0');

  const radioChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };  
  
  const [date, setDate] = useState([] as any);

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange = (e:any) => {
    getDataAccessDataDetail({pageSize: e.pageSize, pageNum: e.current}, {});
  };

  const getDataAccessDataDetail = async (page: {pageSize: number, pageNum: number}, data: any) => {
    const param = {
      accessId: accesslistData.id,
      ...page,
    }

    const { total, rows } = await DataAccessDataDetailApi(param);
    
    setListData(rows);
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
  };

  // 提示信息
  const [promitTitle, setPromitTitle] = useState('');
  // 去任务中心
  const toTaskCenter = () => {
    setTaskCenterOpen(true);
    promitModalRef.current.handleCancel();
  };

  // 打开追加历史数据弹窗
  const [addRecord, setAddRecord] = useState({} as DataAccessDetailsListVO);
  const openAddDrawer = async(record: DataAccessDetailsListVO) => {
    if(loading){
      setLoading(false);
      const param = {
        id: record.id
      };
      const res = await EnableCheckDetialidApi(param);
      setLoading(true);
      if(res && res.code === 505){
        message.error(res.msg);
        // setPromitTitle(res.msg);
        // promitModalRef.current.openModal();
      } else if(res) {
        setAddRecord(record);
        setRadioValue('0');
        setDate([]);
        setAdditionalConfigurationOpen(true);
      };
    }
    
  };

  useEffect(() => {
    if(location.state.page){
      getDataAccessDataDetail({ pageSize: location.state.page.pageSize, pageNum: location.state.page.pageNum }, {});
    } else {
      getDataAccessDataDetail({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, {});
    }
  }, []);


  return (
    <div className={styles.dataAccessDetails}>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <div className={styles.returnBtn} onClick={() => navigate("/files/originalDataAccess/dataAccess", {
            state: { pageQuery: location.state.pageQuery , searcParam: location.state.searcParam },
          })}><LeftOutlined /><span>返回</span></div>
          <div className={styles.moduleTitle}><ModuleTitle mark="large" title={accesslistData.accessName} /></div>
          <div className={styles.miniLabel}>{accesslistData.status === '0' ? <MiniLabel text="启用" labelStyle="enable" /> : <MiniLabel text="停用" labelStyle="disable" />}</div>
        </div>
        <div className={styles.baseInfomation}>
          <div style={{ marginLeft: 80 }}>创建人：<span className={styles.greyText}>{accesslistData.createBy}</span></div>
          <div>创建时间：<span className={styles.greyText}>{accesslistData.createTime}</span></div>
          <div style={{ textAlign: 'center' }}>创建方式：<span className={styles.greyText}>{accesslistData.createWayLabel}</span></div>
        </div>
      </div>
      <div className={styles.contentBox}>
        <div className={styles.middleTitle}>
          <ModuleTitle mark="middle" title="配置信息" />
        </div>
        {
          notShow && <div className={styles.details}>
          <div className={styles.items}><div>数据接入名称：</div ><span className={styles.greyText}>{accesslistData.accessName}</span></div>
          <div className={styles.items}><div>平台类型：</div><span className={styles.greyText}>{accesslistData.platformTypeLabel}</span></div>
          <div className={styles.items} style={{ marginBottom: 0 }}><div>店铺ID：</div><span className={styles.greyText}>{accesslistData.shopId}</span></div>
        </div>
        }

        <div className={styles.details}>
          <div className={styles.items}><div className={styles.itemsTitle}>店铺名称：</div><span className={styles.greyText}>{accesslistData.shopName}</span></div>
          <div className={styles.items}><div className={styles.itemsTitle}>店铺ID：</div><span className={styles.greyText}>{accesslistData.shopId}</span></div>
          <div className={styles.items} style={{ marginBottom: 0 }}><div className={styles.itemsTitle}>平台类型：</div><span className={styles.greyText}>{accesslistData.platformTypeLabel}</span></div>
          
        </div>

      </div>
      <div className={styles.tableBox}>
        <div className={styles.middleTitle}>
          <ModuleTitle mark="middle" title="数据信息" />
        </div>
        <Table
          columns={columns}
          dataSource={listData}
          rowKey={(record) => record.id}
          pagination={{
            total: pageQuery.total,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "30", "50"],
            showTotal: (total) => `共 ${total} 条`,
            // defaultCurrent: 1
          }}
          onChange={(e) => handleTableChange(e)}
        />
      </div>
      <Drawer
        className={styles.drawerStyle}
        title="配置追加信息"
        placement={placement}
        width={400}
        onClose={() => setAdditionalConfigurationOpen(false)}
        open={additionalConfigurationOpen}
        extra={
          <Space>
            <Button onClick={() => setAdditionalConfigurationOpen(false)} style={{marginRight: 8}}>取消</Button>
            <Button type="primary" disabled={radioValue !== '0' && (!date[0] || !date[1])} onClick={appendHistoricalData}>确定</Button>
          </Space>
        }
      >
        <>
          <div className={styles.radioBox}>
            <div className={styles.labelStyle}>
              <span>*</span>请选择追加方式
            </div>
            <Radio.Group onChange={radioChange} value={radioValue}>
              <Radio value="0">追加全部历史数据</Radio>
              <Radio value="1">自定义追加历史数据</Radio>
            </Radio.Group>
          </div>
          {
            radioValue === '1' && <div>
            <RangePicker
              style={{width: '100%'}}
              placeholder={['开始时间', '结束时间']}
              format="YYYY-MM-DD"
              value={date}
              onChange={(date, dateString) => {
                setDate(date);
              }}
            />
            </div>
          }
        </>
      </Drawer>
      <PromitModal ref={promitModalRef} flag="1" promitTitle={promitTitle} toTaskCenter={toTaskCenter} />
      {
        taskCenterOpen && <TaskCenter taskCenterOpen={taskCenterOpen} getOpenStatus={getOpenStatus} tableItemdata={location.state.record} />
      }
    </div>
  );
};

export default DataAccessDetails