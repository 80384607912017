import { state } from "../../../store"
const checkPermission = (permissions: string | undefined) => {
    console.log('---', permissions)
    if (!permissions) return true
    if (state.permissions.findIndex((item: string) => item === '*:*:*') >= 0 || (permissions && state.permissions.findIndex((item: string) => item === permissions) >= 0)) {
        return true
    }
    else {
        return false
    }
}

export default checkPermission