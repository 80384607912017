import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  DatePicker,
  Select,
  InputNumber,
  message,
  Tooltip,
  Cascader
} from "antd";
import type { DatePickerProps, CascaderProps, GetProp } from 'antd';
import type { Dayjs } from 'dayjs';
import {
  PlusOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import MonthPickerComponent from "../MonthPickerComponent";
import MonthRangeComponent from "../MonthRangeComponent";
import dayjs from "dayjs";
import {
  queryLabelListApi,
  profilePropertyListApi,
  profileOperatorApi,
  profileEnumsListApi,
  profileBehaviorListApi,
  profileBehaviorSubPageListApi,
  crowdListApi,
  checkLabelValueUsedApi,
} from "../../../../../../api/label/label";


const { RangePicker } = DatePicker;

type DefaultOptionType = GetProp<CascaderProps, 'options'>[number];

const BehaviorChildComponent = (props: {
  operateType: string,
  current?: number,
  listData?: any,
  itemData?: any,
  ruleData?: any,
  isGoOn?: boolean,
  checkMsg?: string,
  m: any,
  idx: number,
  childRulesLength: number,
  behaviorChildFieldOptions: any,
  getRuleDataFunction: Function,
  getCheckSizeFunction: Function,
  getRulesChangeFunction: Function
}) => {

  const {
    operateType,
    current,
    listData,
    itemData,
    ruleData,
    isGoOn,
    checkMsg,
    m,
    idx,
    childRulesLength,
    behaviorChildFieldOptions,
    getRuleDataFunction = () => { },
    getCheckSizeFunction = () => { },
    getRulesChangeFunction = () => { }
  } = props;

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [behaviorChildRelationshipOptions, setBehaviorChildRelationshipOptions] = useState([] as any);
  const [behaviorChildValueOptions, setBehaviorChildValueOptions] = useState([] as any);

  // 获取运算符
  const getProfileOperator = async (e: any, f: string) => {
    const param = {
      fieldType: f === "o" ? behaviorChildFieldOptions.find(
        (v: any, i: number) => v.id === e
      ).fieldType : e,
    };
    const { data } = await profileOperatorApi(param);

    data.forEach((v: any, i: number) => {
      v.label = v.name;
      v.value = v.code;
    });

    setBehaviorChildRelationshipOptions(data);

    if (f === 'o') {
      getRulesChangeFunction({ e: String(data[0].operator) + '-' + String(data[0].code) + '-' + data[0].name, id: m.child_id, key: "operator_id", f: 'c' });
    }
  };

  // 获取枚举值
  const getProfileEnumsList = async (val: any) => {
    let param = {
      ...val,
    };

    const { data } = await profileEnumsListApi(param);
    if (data) {
      data.forEach((v: any, i: number) => {
        v.label = v.enumName;
        // 会员等级需做单独处理
        v.value = m.field_type === "Level" || m.field_type === "Level1" || m.field_type === "ShopName" ? v.id : v.enumCode;
      });
    }
    setBehaviorChildValueOptions(data);
  };

  // 删除行为子规则
  const deleteChildItem = (id: string) => {
    if (isGoOn) {
      let store = listData.map((val: any, idx: number) => val);
      let pItem = store.find(
        (v: any, i: number) => v.operate_label_id === itemData.operate_label_id
      );
      let cItem = pItem.rules.rules?.find(
        (v: any, i: number) => v.rules_value_id === ruleData.rules_value_id
      );
      cItem.rules.forEach((m: any, n: number) => {
        if (m.rules) {
          m.rules.rules.forEach((p: any, q: number) => {
            if (p.child_id === id) {
              m.rules.rules.splice(q, 1);
            }
          });
        }
      });
      getRuleDataFunction(store);
    } else {
      message.error(checkMsg);
    }
  };

  // 数字格式处理
  const numberFormatter = (text: any) => {
    if (text) {
      const reg = /^\d+$/;
      if (reg.test(text)) {
        return text;
      } else if (m.field_type === 'Long') {
        return text.split(".")[0];
      } else if (m.field_type === 'Decimal') {
        const valueStr = text.toString();
        const parts = valueStr.split('.');
        if (parts.length === 2 && parts[1].length > 2) {
          return parseFloat(text).toFixed(2);
        } else {
          return parseFloat(text);
        }
      }
    }
    return '';
  };

  // 单月份选择
  type DateComponent = Required<NonNullable<DatePickerProps<Dayjs>['components']>>['date'];
  type GetProps<T> = T extends React.ComponentType<infer P> ? P : never;

  const [monthOpen, setMonthOpen] = useState(false);

  const MyDatePanel = (props: GetProps<DateComponent>) => {
    return (
      <MonthPickerComponent
        key={1}
        val={m}
        flag="single"
        openFlag={monthOpen}
        getSingleOpenStatus={(v: boolean) => setMonthOpen(v)}
        getData={(idVal: any) => {
          getRulesChangeFunction({ e: idVal, id: m.child_id, key: "value_m", f: "c" });
        }}
      />
    )
  }

  // 月份区间选择
  const [monthRangeOpen, setMonthRangeOpen] = useState(false);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );

  useEffect(() => {
    if (current === 1 ||
      operateType === "edit" ||
      operateType === "view" ||
      operateType === "copy" ||
      itemData.data_sources === "copy" ||
      ruleData.data_sources === "copy") {
      if (m.field_id) {
        getProfileOperator(m.field_type, 's');

        if (m.field_id !== null &&
          m.field_type !== "String" &&
          m.field_type !== "Date" &&
          m.field_type !== "Year" &&
          m.field_type !== "Month" &&
          m.field_type !== "Long" &&
          m.field_type !== "Phone" &&
          m.field_type !== "Decimal") {
          getProfileEnumsList({ fieldType: m.field_type, fieldCode: m.field_code });
        };
      }
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        marginBottom: 8,
        width: "100%"
      }}
    >
      <Cascader 
        disabled={operateType === "view"}
        showSearch={{ filter }}
        value={m.func_value} 
        placeholder="请选择事件属性" 
        style={{ width: 'calc((100% - 10px - 16px - 16px)/3)' }}
        options={behaviorChildFieldOptions} 
        onChange={(e) => {
          console.log('ww', e);
          getRulesChangeFunction({ e: e, id: m.child_id, key: "field_id", f: "c", option: behaviorChildFieldOptions });
          getProfileOperator(e[0], "o");
          const storeItem = behaviorChildFieldOptions.find((v: any, i: number) => v.id === e[0]);
          if (storeItem.fieldType !== "String" && storeItem.fieldType !== "Date" && storeItem.fieldType !== "Long" && storeItem.fieldType !== "Decimal") {
            getProfileEnumsList({ fieldType: storeItem.fieldType, fieldCode: storeItem.fieldCode });
          }
        }}
      />
      <Select
        disabled={operateType === "view"}
        placeholder="请选择运算条件"
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        style={{
          marginLeft: 8,
          width: 'calc((100% - 10px - 16px - 16px)/3)'
        }}
        value={m.operator_id}
        onChange={(e) => {
          getRulesChangeFunction({ e: e, id: m.child_id, key: "operator_id", f: "c", option: behaviorChildRelationshipOptions });
        }}
        options={behaviorChildRelationshipOptions}
      />
      {(m.field_type === "String" || m.field_type === "Phone") &&
        m.operator_id !== 5 &&
        m.operator_id !== 6 && (
          <Input
            disabled={operateType === "view"}
            value={m.value}
            style={{
              width: 'calc((100% - 10px - 16px - 16px)/3)',
              height: "32px",
              marginLeft: 8,
            }}
            placeholder="请输入关键词"
            onChange={(e) => {
              getRulesChangeFunction({ e: e.target.value, id: m.child_id, key: "value", f: "c" });
            }}
          />
        )}
      {(m.field_id !== null &&
        m.field_type !== "String" &&
        m.field_type !== "Date" &&
        m.field_type !== "Year" &&
        m.field_type !== "Month" &&
        m.field_type !== "Long" &&
        m.field_type !== "Phone" &&
        m.field_type !== "Decimal") && (
          <Select
            disabled={operateType === "view"}
            mode={(m.operator_id === 12 || m.operator_id === 13 || m.operator_id === 14) ? 'multiple' : undefined}
            placeholder="请选择事件属性值"
            maxTagCount='responsive'
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            style={{
              width: 'calc((100% - 10px - 16px - 16px)/3)',
              marginLeft: 8,
            }}
            value={m.value_select}
            onChange={(e) => {
              const id_store: any = [];
              const name_store: any = [];
              if (m.operator_id === 12 || m.operator_id === 13 || m.operator_id === 14) {
                // 多选
                behaviorChildValueOptions.forEach((p: any, q: number) => {
                  if (e.includes(p.value)) {
                    id_store.push(p.enumCode);
                    name_store.push(p.enumName);
                  }
                });
              } else {
                id_store.push(behaviorChildValueOptions.find((a: any, b: number) => a.value === e).enumCode);
                name_store.push(behaviorChildValueOptions.find((a: any, b: number) => a.value === e).enumName);
              }
              getRulesChangeFunction({ e: m.operator_id === 12 || m.operator_id === 13 || m.operator_id === 14 ? id_store : id_store[0], id: m.child_id, key: "value_select", f: "c", option: { id: e, name: name_store } });
            }}
            options={behaviorChildValueOptions}
          />
        )}
      {m.field_type === "Date" &&
        m.operator_id !== 5 &&
        m.operator_id !== 6 &&
        m.operator_id !== 7 && (
          <DatePicker
            disabled={operateType === "view"}
            value={m.value_time ? dayjs(m.value_time, 'YYYY-MM-DD') : null}
            placeholder="请选择时间"
            format="YYYY-MM-DD"
            style={{
              width: 'calc((100% - 10px - 16px - 16px)/3)',
              height: "32px",
              marginLeft: 8,
            }}
            onPanelChange={(value) => { getRulesChangeFunction({ e: value, id: m.child_id, key: "value_d", f: "c" }) }}
            onChange={(date, dateString) => {
              getRulesChangeFunction({ e: dateString, id: m.child_id, key: "value_d", f: "c" });
            }}
          />
        )}
      {m.field_type === "Year" &&
        m.operator_id !== 5 &&
        m.operator_id !== 6 &&
        m.operator_id !== 7 && (
          <DatePicker
            style={{
              width: 'calc((100% - 10px - 16px - 16px)/3)',
              height: "32px",
              marginLeft: 8,
            }}
            allowClear
            disabled={operateType === "view"}
            value={m.value_time ? dayjs(m.value_time, 'YYYY') : null}
            picker="year"
            onPanelChange={(value) => { getRulesChangeFunction({ e: value, id: m.child_id, key: "value_y", f: "c" }) }}
            onChange={(date, dateString) => {
              getRulesChangeFunction({ e: dateString, id: m.child_id, key: "value_y", f: "c" });
            }}
          />
        )}
      {(m.field_type === "Month") &&
        m.operator_id !== 5 &&
        m.operator_id !== 6 &&
        m.operator_id !== 7 && (
          <DatePicker
            style={{
              width: 'calc((100% - 10px - 16px - 16px)/3)',
              height: "32px",
              marginLeft: 8,
            }}
            allowClear
            disabled={operateType === "view"}
            value={m.value_time ? dayjs(m.value_time, "M月") : null}
            // picker="month"
            format={"M月"}
            open={monthOpen}
            onFocus={(_, info) => {
              setMonthOpen(!monthOpen);
            }}
            onBlur={(_, info) => {
              setMonthOpen(false);
            }}
            onChange={(date, dateString) => {
              console.log(dateString);

              getRulesChangeFunction({ e: dateString, id: m.child_id, key: "value_y", f: "c" });
            }}
            showNow={false}
            components={{
              date: MyDatePanel,
            }}
          />
        )}
      {(m.field_type === "Date" || m.field_type === "Year") &&
        m.operator_id === 7 && (
          <RangePicker
            disabled={operateType === "view"}
            value={m.value_time ? [dayjs(m.value_time[0], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"), dayjs(m.value_time[1], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY")] : null}
            placeholder={["开始时间", "结束时间"]}
            picker={m.field_type === "Date" ? "date" : "year"}
            format={m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"}
            style={{
              marginLeft: 8,
              height: "32px",
              width: 'calc((100% - 10px - 16px - 16px)/3)',
            }}
            allowClear
            onPanelChange={(value) => { getRulesChangeFunction({ e: value, id: m.child_id, key: "value_r", f: "c" }) }}
            onChange={(date, dateString) => {
              getRulesChangeFunction({ e: dateString, id: m.child_id, key: "value_r", f: "c" });
            }}
          />
        )}
      {
        m.field_type === "Month" &&
        m.operator_id === 7 && (
          <MonthRangeComponent
            operateType={operateType}
            width={ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)"}
            val={m}
            open={m.month_open}
            getDataChange={(mVal: any) => getRulesChangeFunction({ e: mVal.e, id: m.child_id, key: mVal.k, f: "c" })}
          />
        )
      }
      {(m.field_type === "Long" || m.field_type === "Decimal") &&
        m.operator_id !== 7 && (
          <InputNumber
            disabled={operateType === "view"}
            placeholder="请输入"
            precision={2}
            value={m.value}
            min={0}
            max={9999999}
            formatter={(text) => numberFormatter(text)}
            parser={(text: any) => text.replace(/kg$/, '')}
            style={{
              width: 'calc((100% - 10px - 16px - 16px)/3)',
              height: "32px",
              marginLeft: 8,
            }}
            onChange={(e) => {
              getRulesChangeFunction({ e: e, id: m.child_id, key: "value", f: "c" });
            }}
          />
        )}
      {(m.field_type === "Long" || m.field_type === "Decimal") &&
        m.operator_id === 7 && (
          <div
            style={{
              display: "flex",
              marginLeft: 8,
              width: 'calc((100% - 10px - 16px - 16px)/3)',
            }}
          >
            <InputNumber
              disabled={operateType === "view"}
              placeholder="请输入"
              precision={2}
              value={m.min}
              min={0}
              max={9999999}
              formatter={(text) => numberFormatter(text)}
              style={{
                width: "calc((100% - 14px - 8px - 8px)/2)",
                height: "32px",
              }}
              onChange={(e) => {
                getRulesChangeFunction({ e: e, id: m.child_id, key: "min", f: "c" });
              }}
              onBlur={(e: any) => getCheckSizeFunction({ value: e.target.value, data: m, key: "min", msg: "事件属性值区间请按照从小到大的顺序填写", f: "c" })}
              onPressEnter={(e: any) => getCheckSizeFunction({ value: e.target.value, data: m, key: "min", msg: "事件属性值区间请按照从小到大的顺序填写", f: "c" })}
            />
            <span
              style={{
                display: "inline-block",
                height: "32px",
                lineHeight: 'var(--text-font15)',
                margin: "0 8px",
              }}
            >
              到
            </span>
            <InputNumber
              disabled={operateType === "view"}
              value={m.max}
              placeholder="请输入"
              precision={2}
              min={0}
              max={9999999}
              formatter={(text) => numberFormatter(text)}
              style={{
                width: "calc((100% - 14px - 8px - 8px)/2)",
                height: "32px",
              }}
              onChange={(e) => {
                getRulesChangeFunction({ e: e, id: m.child_id, key: "max", f: "c" });
              }}
              onBlur={(e: any) => getCheckSizeFunction({ value: e.target.value, data: m, key: "max", msg: "事件属性值区间请按照从小到大的顺序填写", f: "c" })}
              onPressEnter={(e: any) => getCheckSizeFunction({ value: e.target.value, data: m, key: "max", msg: "事件属性值区间请按照从小到大的顺序填写", f: "c" })}
            />
          </div>
        )}
      {childRulesLength > 0 &&
        operateType !== "view" && (
          <div
            style={{
              marginLeft: 10,
              height: "32px",
              lineHeight: 'var(--text-font15)',
            }}
          >
            <Tooltip title="删除">
              <DeleteOutlined
                style={{
                  cursor: "pointer",
                  color: "var(--text-font-color4)",
                  fontSize: "var(--text-font7)",
                }}
                onClick={() =>
                  deleteChildItem(m.child_id)
                }
              />
            </Tooltip>
          </div>
        )}
    </div>
  );
};

export default BehaviorChildComponent;
