/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Flex, Descriptions, Table, Dropdown, Modal, Drawer, Space, message } from 'antd';
import type { DescriptionsProps, TableProps, MenuProps } from 'antd';
import { LeftOutlined, ExclamationCircleFilled, DashOutlined } from '@ant-design/icons';
import DimensionChoose from './DimensionChoose';
import IndicatorChoose from './IndicatorChoose';
import DatasetApi from '@/pages/LuZhiBI/api/dataset';
import { getToolTipText } from '@/pages/LuZhiBI/utils';
import styles from './index.module.scss';

const initItems: DescriptionsProps['items'] = [
  {
    key: 'createUser',
    label: '创建人',
    children: '曲丽丽',
  },
  {
    key: 'createTime',
    label: '创建时间',
    children: '2024-06-19 12-20:30',
  },
];

const actionItems: MenuProps['items'] = [
  {
    key: 'delete',
    label: '删除',
  },
];

interface DimensionDataType {
  id: number;
  dimensionName: string;
  dimensionCategory: string;
  dimensionType: string;
}

const initDimensionColumns: TableProps<DimensionDataType>['columns'] = [
  {
    title: '维度名称',
    dataIndex: 'dimensionName',
    key: 'dimensionName',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '维度分类',
    dataIndex: 'type',
    key: 'dimension_type',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '维度类型',
    dataIndex: 'sourceFieldType',
    key: 'dimension_source_field_type',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '操作',
    key: 'action',
    width: 100,
  },
];

interface IndicatorDataType {
  id: number;
  indicatorCode: string;
  indicatorName: string;
  indicatorCategory: string;
  rule: string;
}

const initIndicatorColumns: TableProps<IndicatorDataType>['columns'] = [
  {
    title: '指标名称',
    dataIndex: 'indicatorName',
    key: 'indicatorName',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '分类',
    dataIndex: 'indicatorCategory',
    key: 'indicator_category',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '计算规则',
    dataIndex: 'expressionName',
    key: 'expressionName',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '操作',
    key: 'action',
    width: 100,
  },
];

let datasetName = '数据集名称';
const dictList = ['dimension_type', 'dimension_source_field_type', 'indicator_category'];

const DataSourceAdd: FC = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const onReturn = () => {
    navigate(-1);
  };
  const [dictMap, setDictMap] = useState<Record<string, any[]>>({});
  const [descriptionItems, setDescriptionItems] = useState(initItems);
  const [dimensionColumns, setDimensionColumns] = useState(initDimensionColumns);
  const [dimensionData, setDimensionData] = useState<DimensionDataType[]>([]);
  const [dimensionSelectedKey, setDimensionSelectedKey] = useState<React.Key[]>([]);
  const [dimensionDrawerVisable, setDimensionDrawerVisable] = useState(false);
  const dimensionChooseRef = useRef<any>(null);
  const onDimensionSubmit = () => {
    if (!dimensionChooseRef.current) {
      return;
    }
    if (dimensionChooseRef.current.selectedRowKeys?.length <= 0) {
      messageApi.warning('请选择维度');
      return;
    }
    setDimensionData(dimensionChooseRef.current.selectedRows);
    setIndicatorDataNull();
    closeDimensionDrawer();
  };
  const openDimensionDrawer = () => {
    setDimensionDrawerVisable(true);
  };
  const closeDimensionDrawer = () => {
    setDimensionDrawerVisable(false);
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setDimensionSelectedKey(newSelectedRowKeys);
  };
  const [indicatorColumns, setIndicatorColumns] = useState(initIndicatorColumns);
  const [indicatorData, setIndicatorData] = useState<IndicatorDataType[]>([]);
  const [indicatorSelectedKey, setIndicatorSelectedKey] = useState<React.Key[]>([]);
  const [indicatorDrawerVisable, setIndicatorDrawerVisable] = useState(false);
  const indicatorChooseRef = useRef<any>(null);
  const setIndicatorDataNull = () => {
    setIndicatorData([]);
    setIndicatorSelectedKey([]);
  };
  const onIndicatorSubmit = () => {
    if (!indicatorChooseRef.current) {
      return;
    }
    if (indicatorChooseRef.current.selectedRowKeys?.length <= 0) {
      messageApi.warning('请选择指标');
      return;
    }
    setIndicatorData(indicatorChooseRef.current.selectedRows);
    closeIndicatorDrawer();
  };
  const openIndicatorDrawer = () => {
    setIndicatorDrawerVisable(true);
  };
  const closeIndicatorDrawer = () => {
    setIndicatorDrawerVisable(false);
  };
  const onIndicatorSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setIndicatorSelectedKey(newSelectedRowKeys);
  };
  const onDelete = async (menuInfo: any, id: number, type: 'dimension' | 'indicator') => {
    if (menuInfo.key === 'delete') {
      const confirmed = await modal.confirm({
        title: '删除',
        content: `确定删除该${type === 'dimension' ? '维度' : '指标'}吗？`,
      });
      if (confirmed) {
        if (type === 'dimension') {
          setDimensionData(dimensionData.filter(item => item.id !== id));
          setIndicatorDataNull();
        }
        if (type === 'indicator') {
          setIndicatorData(indicatorData.filter(item => item.id !== id));
        }
      }
    }
  };
  const onBatchDelete = async (type: 'dimension' | 'indicator') => {
    const confirmed = await modal.confirm({
      title: '删除',
      content: `确定删除这些${type === 'dimension' ? '维度' : '指标'}吗？`,
    });
    if (confirmed) {
      if (type === 'dimension') {
        setDimensionData(dimensionData.filter(item => !dimensionSelectedKey.includes(item.id)));
        setDimensionSelectedKey([]);
        setIndicatorDataNull();
      }
      if (type === 'indicator') {
        setIndicatorData(indicatorData.filter(item => !indicatorSelectedKey.includes(item.id)));
        setIndicatorSelectedKey([]);
      }
    }
  };
  const dealColumns = () => {
    const actionRender = (id: number, type: 'dimension' | 'indicator') => (
      <Dropdown
        trigger={['click']}
        menu={{
          items: actionItems,
          onClick: menuInfo => {
            onDelete(menuInfo, id, type);
          },
        }}
      >
        <DashOutlined />
      </Dropdown>
    );
    setDimensionColumns(
      initDimensionColumns.map(column => {
        if (column.key === 'action') {
          return {
            ...column,
            render: (_, record) => actionRender(record.id, 'dimension'),
          };
        }
        if (dictList.includes(column.key as string)) {
          return {
            ...column,
            render: text => {
              const target = dictMap[column.key as any].find(option => option.dictValue === text);
              return getToolTipText(target?.dictLabel);
            },
          };
        }
        return column;
      }),
    );
    setIndicatorColumns(
      initIndicatorColumns.map(column => {
        if (column.key === 'action') {
          return {
            ...column,
            render: (_, record) => actionRender(record.id, 'indicator'),
          };
        }
        if (dictList.includes(column.key as string)) {
          return {
            ...column,
            render: text => {
              const target = dictMap[column.key as any].find(option => option.dictValue === text);
              return getToolTipText(target?.dictLabel);
            },
          };
        }
        return column;
      }),
    );
  };

  const getReturnData = (id: number) => {
    DatasetApi.getDatasetConfigData({ id, }).then(res => {
      if (!res) {
        return;
      }
      setDescriptionItems(() => {
        return descriptionItems.map(item => ({
          ...item,
          children: res.data?.dataset?.[item.key as string] ?? '-',
        }));
      });
      datasetName = res.data?.dataset?.dsName ?? '数据集名称';
      setDimensionData(res.data?.dimensions.map((item: any) => ({
        ...item,
        id: item.id,
        dimensionName: item.name,
        dimensionCategory: item.type,
        dimensionType: item.type,
      })) ?? []);
      setIndicatorData(res.data?.indicators ?? []);
    });
  };

  const getDictData = () => {
    Promise.allSettled(dictList.map(dictType => DatasetApi.getDictData(dictType))).then(res => {
      const dictData = res.reduce((prev, current, index) => {
        if (current.status === 'fulfilled') {
          return {
            ...prev,
            [dictList[index]]: current.value.data,
          };
        }
        return prev;
      }, {});
      setDictMap(dictData);
    });
  };
  const onPublish = () => {
    if (dimensionData.length <= 0) {
      messageApi.warning('请选择维度');
      return;
    }
    if (indicatorData.length <= 0) {
      messageApi.warning('请选择指标');
      return;
    }
    const params = {
      dsId: location.state?.id,
      dimensionIds: dimensionData.map(item => item.id),
      indicatorIds: indicatorData.map(item => item.id),
      indicatorCodes: indicatorData.map(item => item.indicatorCode),
    };
    DatasetApi.postDatasetConfig(params).then(res => {
      if (!res) {
        return;
      }
      messageApi.success('发布成功');
      setTimeout(() => {
        navigate(-1);
      }, 1000);
    });
  };
  useEffect(() => {
    if (dictMap && Object.keys(dictMap).length > 0) {
      dealColumns();
    }
  }, [dictMap, dimensionData, indicatorData]);
  useEffect(() => {
    getDictData();
    getReturnData(location.state?.id);
  }, [location.state?.id]);
  return (
    <div className={styles.DataSourceAdd}>
      {contextHolder}
      {messageContextHolder}
      <div className={styles.titleBox}>
        <Flex gap="small" align="center">
          <Button
            size="small"
            style={{
              color: 'rgba(0,0,0,0.45)',
              backgroundColor: 'rgba(0,0,0,0.06)',
              border: 'none',
            }}
            icon={<LeftOutlined />}
            onClick={onReturn}
          >
            返回
          </Button>
          <span className={styles.title}>{datasetName}</span>
        </Flex>
        <Button type="primary" onClick={onPublish}>发布</Button>
      </div>
      <div style={{ padding: 24 }}>
        <Descriptions
          column={3}
          title="基础信息"
          items={descriptionItems}
          labelStyle={{ color: 'rgba(0,0,0,0.85)' }}
          contentStyle={{ color: 'rgba(0,0,0,0.45)' }}
        />
        <p style={{ fontSize: 16, fontWeight: 'bold' }}>指标/维度</p>
        <div className={styles.indicatorBox}>
          <ExclamationCircleFilled style={{ color: '#FAAD14' }} />
          <p className={styles.desc}>
            新增/编辑/删除 维度、指标后，重新发布模型后生效！！！（如果所关联的维度/指标自身发生变更，重新发布模型，可更新维度/指标信息）
          </p>
        </div>
        <Flex justify="space-between" align="center" style={{ marginBottom: 16 }}>
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>STEP1维度</p>
          <div>
            {dimensionSelectedKey.length > 0 && (
              <Button
                type="primary"
                onClick={() => {
                  onBatchDelete('dimension');
                }}
              >
                批量删除
              </Button>
            )}
            <Button style={{ marginLeft: 8 }} onClick={openDimensionDrawer}>
              选择维度
            </Button>
          </div>
        </Flex>
        <Table
          columns={dimensionColumns}
          dataSource={dimensionData}
          rowKey={'id'}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: dimensionSelectedKey,
            onChange: onSelectChange,
          }}
          pagination={false}
        />
        <Flex justify="space-between" align="center" style={{ margin: '24px 0 16px' }}>
          <p style={{ fontSize: 14, fontWeight: 'bold' }}>STEP2指标</p>
          <div>
            {indicatorSelectedKey.length > 0 && (
              <Button
                type="primary"
                onClick={() => {
                  onBatchDelete('indicator');
                }}
              >
                批量删除
              </Button>
            )}
            <Button style={{ marginLeft: 8 }} disabled={dimensionData.length <= 0} onClick={openIndicatorDrawer}>
              选择指标
            </Button>
          </div>
        </Flex>
        <Table
          columns={indicatorColumns}
          dataSource={indicatorData}
          rowKey={'id'}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: indicatorSelectedKey,
            onChange: onIndicatorSelectChange,
          }}
          pagination={false}
        />
      </div>
      <Drawer
        title="选择维度"
        onClose={closeDimensionDrawer}
        open={dimensionDrawerVisable}
        destroyOnClose
        width={736}
        extra={
          <Space>
            <Button onClick={closeDimensionDrawer}>取消</Button>
            <Button type="primary" htmlType="submit" onClick={onDimensionSubmit}>
              确定
            </Button>
          </Space>
        }
      >
        <DimensionChoose ref={dimensionChooseRef} dict={dictMap.dimension_type} defaultSelectedKeys={dimensionData.map((i) => i.id)} />
      </Drawer>

      <Drawer
        title="选择指标"
        onClose={closeIndicatorDrawer}
        open={indicatorDrawerVisable}
        destroyOnClose
        width={736}
        extra={
          <Space>
            <Button onClick={closeIndicatorDrawer}>取消</Button>
            <Button type="primary" htmlType="submit" onClick={onIndicatorSubmit}>
              确定
            </Button>
          </Space>
        }
      >
        <IndicatorChoose
          ref={indicatorChooseRef}
          dimensionIds={
            dimensionData.map((item) => Number(item.id))
          }
          dict={dictMap.indicator_category}
          defaultSelectedKeys={indicatorData.map((i) => i.id)}
        />
      </Drawer>
    </div>
  );
};

export default DataSourceAdd;

