import { AxiosPromise } from 'axios';
import request from '../network'
import { DataAccessListQuery, DataAccessListVO, DataAccessDetailsListVO, TaskCenterListVO } from './types';

// 创建实时数据接入
export function CreateDataAccessApi(query: any) {
    return request({
        url: '/collect/dataAccess/createDataAccess',
        method: 'POST',
        data: query
    });
}


// 测试连接
export function CheckAccessApi(query: any){
  return request({
      url: '/collect/dataAccess/checkAccess',
      method: 'POST',
      params: query,
  })
}

// 任务中心
export function AppendListApi(query: any): AxiosPromise<TaskCenterListVO[]> {
  return request({
      url: '/collect/dataAccess/appendList',
      method: 'POST',
      params: query,
  })
}

// 数据追加
export function AppendDataApi(query: any) {
  return request({
      url: '/collect/dataAccess/appendData',
      method: 'POST',
      params: query,
  })
}

// 平台类型
export function PlatformMethodListApi(): AxiosPromise<any> {
  return request({
      url: '/collect/dataAccess/platformMethodList',
      method: 'GET',
      params: {},
  })
}

// 数据接入列表
export function DataAccessListApi(query: any): AxiosPromise<DataAccessListVO[]> {
  return request({
      url: '/collect/dataAccess/list',
      method: 'GET',
      params: query,
  })
}

// 接入数据启用停用
export function DataAccessEnableApi(query: any) {
  return request({
      url: '/collect/dataAccess/enable',
      method: 'GET',
      params: query,
  })
}

// 停用检验 用于二次弹窗
export function DataAccessEnableCheckApi(query: any) {
  return request({
      url: '/collect/dataAccess/enableCheck',
      method: 'GET',
      params: query,
  })
}

// 数据详情表详情
export function DataTableDetailApi(query: any): AxiosPromise<any> {
  return request({
      url: '/collect/dataAccess/dataTableDetail',
      method: 'GET',
      params: query,
  })
}

// 数据接入列表详情
export function DataAccessDataDetailApi(query: any): AxiosPromise<DataAccessDetailsListVO[]> {
  return request({
      url: '/collect/dataAccess/dataDetail',
      method: 'GET',
      params: query,
  })
}

// 接入类型列表
export function DataAccessMethodListApi(): AxiosPromise<any> {
  return request({
      url: '/collect/dataAccess/dataAccessMethodList',
      method: 'GET',
      params: {},
  })
}

// 获取店铺id
export function ShopIdListApi(query: any): AxiosPromise<any> {
  return request({
      url: '/collect/dataAccess/shopList',
      method: 'GET',
      params: query,
  })
}

// 追加历史数据校验
export function EnableCheckDetialidApi(query: any): AxiosPromise<any> {
  return request({
      url: '/collect/dataAccess/enableCheckDetial',
      method: 'GET',
      params: query,
  })
}

export default {}