import styles from "./index.module.scss";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  type TableProps,
  Tree,
  TreeDataNode
} from "antd";
import {EllipsisOutlined, ExclamationCircleFilled, FolderOpenOutlined, SearchOutlined} from "@ant-design/icons";
import ModuleTitle from "@/pages/LuZhiBI/components/ModuleTitle";
import SearchIconInput from "@/pages/LuZhiBI/components/SearchIconInput/searchIconInput";
import FilterIcon from "@/pages/LuZhiBI/components/FilterIcon/filterIcon";
import SystemButton from "@/pages/LuZhiBI/components/Button/SystemButton";
import FilterView from "@/pages/LuZhiBI/components/FilterView";
import {IAddIndicator, IEditIndicator, IGetIndicatorManagementList} from "@/pages/LuZhiBI/api/types";
import api from "@/pages/LuZhiBI/api";
import dayjs from "dayjs";
import {JSX} from "react/jsx-runtime";
import {getToolTipText} from "@/pages/LuZhiBI/utils";

interface DataType {
  id: string
  classify: string
  indexType: string
  indexName: string
  createdAt: string
  atomicIndex: string
  dimension: string
}

interface IFormData {
  dataSourceTable: string,
  indicatorName: string,
  fieldExpression: string,
  classificationOfIndicators: string,
}

interface ISearch {
  indexName?: string
  classifyList?: Array<string>
  indexType?: string
  searchInfo?: string
  startTime?: string
  endTime?: string
  modelType?: string
}

type PageQuery = null | number

type DrawerType = "create" | 'view' | 'edit'

const AtomicIndicators = () => {
  const {TextArea} = Input;

  const {confirm} = Modal;

  const [form] = Form.useForm();

  const [options, setOptions] = useState<Record<any, Array<{ label: string, value: string, layer?: string }>>>({
    classificationOfIndicatorsOptions: [],
    dataSourceTableOptions: []
  })

  const defaultSearchInfo = {
    // 查询参数
    indicatorName: "",
    indicatorType: options.classificationOfIndicatorsOptions.map(item => item.value),
    createdAt: "",
  };

  const [listParams, setListParams] = useState(defaultSearchInfo);

  const filterViewRef = useRef<any>(null);

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [tableData, setTableData] = useState([])

  const [openDrawer, setOpenDrawer] = useState(false)

  const [drawerType, setDrawerType] = useState<DrawerType>('create')

  const drawerTitle: Record<DrawerType, string> = {
    create: '新建原子指标',
    view: '查看原子指标',
    edit: '编辑原子指标'
  }

  const defaultFormData = {
    indicatorName: '',
    classificationOfIndicators: '',
    dataSourceTable: '',
    fieldExpression: ''
  }

  const [viewId, setViewId] = useState('')

  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i: any) => {
    setCheckUserId(i.id)
    setIconColorPointBack('#f3f3f3');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('')
    setIconColorPointBack('');
  };

  const [selectedKey, setSelectedKey] = useState<React.Key>('');

  const functionTipInfo = (): { field: string, definition: string, example: string } => {
    switch (selectedKey) {
      case '0-0-0':
        return {
          field: 'SUM',
          definition: 'SUM(字段)：根据当前分析维度，返回指标字段的汇总求和值。',
          example: 'SUM(销售额)'
        }
      case '0-0-1':
        return {
          field: 'COUNT',
          definition: 'COUNT(字段)：根据当前分析维度，返回维度或指标字段的计数。',
          example: 'COUNT(订单ID)'
        }
      case '0-0-2':
        return {
          field: 'COUNT(DISTINCT )',
          definition: 'COUNT(DISTINCT 字段)：根据当前分析维度，返回维度或指标字段的去重计数。',
          example: 'COUNT(DISTINCT 订单ID)'
        }
      case '0-0-3':
        return {
          field: 'AVG',
          definition: 'AVG(字段)：根据当前分析维度，返回指标字段的平均值。',
          example: 'AVG(销售额)'
        }
      case '0-0-4':
        return {
          field: 'YoY',
          definition: 'YoY(SUM(字段))：根据当前分析维度，返回指标字段的聚合汇总的去年同期同比。',
          example: 'YoY(SUM(销售额))'
        }
      case '0-0-5':
        return {
          field: 'ChainRatio',
          definition: 'ChainRatio(SUM(字段))：根据当前分析维度，返回指标字段的聚合汇总的上期环比。',
          example: 'ChainRatio(SUM(销售额))'
        }
      case '0-1-0':
        return {
          field: 'IF',
          definition: 'IF(条件表达式1,结果1,条件表达式2,结果2,.....,其他结果)：如果满足条件表达式1，则返回结果1，如果满足条件表达式2且不满足条件表达式1，则返回结果2，如果无满足的条件表达式，则返回其他结果。参数说明 条件表达式：输出的结果需为布尔类型或数值类型；结果参数：所有结果参数的类型必须需相同；参数至少需包含1个条件表达式、1个结果和其他结果。',
          example: 'IF(true,2,8)等于2 IF(false,"first","second")等于second IF(单价<4000,"类型A",单价<8000,"类型B","类型C")，如果单价字段值如果小于4000，则返回类型A；如果大于等于4000小于8000，则返回类型B；其他情况则返回类型C。'
        }
      case '0-1-1':
        return {
          field: 'IN',
          definition: 'IN(exp,para1,para2,para3,...)：判断表达式exp的结果是否属于para1,para2,para3,...构建的集合里。参数说明 exp：任意类型的任意表达式（不能为NULL）。para1,para2,para3,...：任意类型的常量/常量表达式/组件参数。IN函数中所有参数的类型需保持一致。',
          example: 'IN(1,1,2,3)=1 IN(城市,"北京","上海","广州")：城市字段中属于北京、上海、广州的行=1，其余行=0。DEF(SUM(销售额),[],[IN(城市,$组件参数)])：计算属于组件参数值的城市的销售额总和。'
        }
      case '0-1-2':
        return {
          field: 'OR',
          definition: 'OR(logical1,logical2,……)：当所有参数的值为假时，返回0；当任意参数的值为真时，返回1。',
          example: 'OR(1+7=9,5+7=11)等于0。OR(1+7=8,5+7=11)等于1。'
        }
      case '0-1-3':
        return {
          field: 'AND',
          definition: 'AND(logical1,logical2,……)：当所有参数的值为真时，返回1；当任意参数的值为假时，返回0。',
          example: 'AND(1+7=8,5+7=12)等于1。AND(1+7=8,5+7=11)等于0。',
        }
      case '0-2-0':
        return {
          field: 'ISNULL',
          definition: 'ISNULL(object)：判断对象中所有的值是否全部都是NULL或者为空字符串。结果为空或null,返回1，否则返回0。',
          example: ''
        }
      case '0-2-1':
        return {
          field: 'NVL',
          definition: 'NVL(value1,value2,value3,……)：在所有参数中返回第一个不是null的值。参数说明value1:可以为任意数，也可以为null。value2:可以为任意数，也可以为null。当字符串长度为0时,返回也为null',
          example: 'NVL(12,20)等于12。NVL(null,12)等于12。NVL(null,null)等于null。NVL(20,null)等于20。NVL(null,null,10)等于10。'
        }
      default:
        return {
          field: '',
          definition: '',
          example: ''
        }
    }
  }

  const [treeData, setTreeData] = useState<Record<'function' | 'field', TreeDataNode[]>>({
    function: [
      {
        title: '聚合函数(6)',
        key: '0-0',
        icon: <FolderOpenOutlined/>,
        children: [
          {
            title: 'SUM(X)',
            key: '0-0-0',
          },
          {
            title: 'COUNT(X)',
            key: '0-0-1',
          },
          {
            title: 'COUNT(DISTINCT X)',
            key: '0-0-2',
          },
          {
            title: 'AVG(X)',
            key: '0-0-3',
          },
          {
            title: 'YoY(X)',
            key: '0-0-4',
          },
          {
            title: 'ChainRatio(X)',
            key: '0-0-5',
          },
        ],
      },
      {
        title: '逻辑函数(4)',
        key: '0-1',
        icon: <FolderOpenOutlined/>,
        children: [
          {
            title: 'IF(X)',
            key: '0-1-0',
          },
          {
            title: 'IN',
            key: '0-1-1',
          },
          {
            title: 'OR',
            key: '0-1-2',
          },
          {
            title: 'AND',
            key: '0-1-3',
          },

        ],
      },
      {
        title: '其他函数(2)',
        key: '0-2',
        icon: <FolderOpenOutlined/>,
        children: [
          {
            title: 'ISNULL(X)',
            key: '0-2-0',
          },
          {
            title: 'NVL(X)',
            key: '0-2-1',
          },
        ],
      },
    ],
    field: []
  })

  // 搜索
  const searchList: any[] = [
    {
      searchType: "string",
      searchComponentType: "input",
      placeHolder: "请输入指标名称",
      title: "指标名称",
      key: "indicatorName",
    },
    {
      searchType: "string",
      searchComponentType: "multipleSelect",
      placeHolder: "请选择指标分类",
      title: "指标分类",
      key: "indicatorType",
      options: options.classificationOfIndicatorsOptions,
      mode: "multiple",
      defaultValue: options.classificationOfIndicatorsOptions.map(item => item.value),
    },
    {
      searchType: "text",
      searchComponentType: "dateRangeTime",
      showTime: true,
      title: "创建时间",
      key: "createdAt",
    },
  ];

  const [expandedKeys, setExpandedKeys] = useState<Record<'function' | 'field', React.Key[]>>({
    function: [],
    field: []
  });

  const [searchValue, setSearchValue] = useState({
    function: '',
    field: ''
  });

  const [autoExpandParent, setAutoExpandParent] = useState({
    function: true,
    field: true
  });

  const [selectedKeys] = useState<React.Key[]>([]);

  const error = (errMsg: string) => {
    Modal.error({
      title: '校验失败',
      content: errMsg,
      // onOk: onClose
      okText: '确认'
    });
  };

  const getTableOperationButtonList = (item: DataType) => {
    const store = [];

    store.push({
      key: "view",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onView(item)}
        >
          查看
        </a>
      ),
    });

    store.push({
      key: "edit",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onEdit(item)}
        >
          编辑
        </a>
      ),
    });

    store.push({
      key: "delete",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onDelete(item)}
        >
          删除
        </a>
      ),
    });
    return store;
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "序号",
      key: "index",
      width: "88px",
      render: (_value, _record, index) => (
        <span>{(pageInfo.page - 1) * pageInfo.pageSize + index + 1}</span>
      ),
    },
    {
      title: "指标分类",
      key: "classify",
      dataIndex: "classify",
    },
    {
      title: "指标名称",
      key: "indexName",
      dataIndex: "indexName",
    },
    {
      title: "指标类别",
      key: "indexType",
      dataIndex: "indexType",
      render: () => '原子指标'
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{items: getTableOperationButtonList(record)}}
            trigger={["click"]}
          >
            <Popconfirm
              title=''
              placement="right"
              open={false}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                onMouseLeave={handleMouseLeavePoint}
                onMouseEnter={() => handleMouseEnterPoint(record)}
                className={styles[`line-three-point`]}
                style={{backgroundColor: checkUserId == record.id ? iconColorPointBack : ''}}
              >
                <EllipsisOutlined
                  style={{
                    color: checkUserId == record.id ? '#1e1e1e' : '#979797',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onDelete = (item: DataType) => {
    confirm({
      title: "提示",
      content: '该指标被删除后，会影响上游应用该表的所有表及后续统计数据，确认删除吗？',
      okText: "确定",
      onOk() {
        confirmDelete(item.id);
      },
    });
  }

  const confirmDelete = async (id: string) => {
    const params: { idList: Array<string>, indexType: '1' } = {
      idList: [id],
      indexType: '1'
    }
    api.deleteIndicator(params).then((res: any) => {
      if (res?.code === 200) {
        message.success('删除成功')

        let page = pageInfo.page

        if (tableData.length === 1 && page > 1) {
          page = page - 1
        }

        onSearch({page: page})
      } else {
        message.error('删除失败')
      }
    })
  }

  const onView = (_record: DataType) => {
    setViewId(_record.id ?? '')
    getViewInfo(_record.id, 'view')
  }

  const onEdit = (_record: DataType) => {
    setViewId(_record.id ?? '')
    getViewInfo(_record.id, 'edit')
  }

  const getViewInfo = async (id: string, type: DrawerType) => {

    const res = await api.viewIndicator(id)

    if (res?.code === 200) {
      const layer = options.classificationOfIndicatorsOptions.find(item => item.value === res.data.classify)?.value ?? ''

      const result = {
        indicatorName: res.data.indexName ?? '',
        classificationOfIndicators: res.data.classify ?? '',
        dataSourceTable: res.data.tableName ?? '',
        fieldExpression: res.data.fieldEnExpression ?? ''
      }

      await Promise.all([getTablesList(layer, false), getFieldsList(res.data.tableName ?? '', false)])

      form.setFieldsValue(result)

      setDrawerType(type)

      setOpenDrawer(true)
    }
  }

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  const onFinish = (data: any) => {
    const {createdAt, indicatorName, indicatorType} = data

    const params: ISearch = {
      indexName: indicatorName || '',
      classifyList: indicatorType ?? [],
      indexType: '1',
      startTime: Array.isArray(createdAt) ? dayjs(createdAt[0]).format('YYYY-MM-DD HH:mm:ss') : '',
      endTime: Array.isArray(createdAt) ? dayjs(createdAt[1]).format('YYYY-MM-DD HH:mm:ss') : ''
    }

    onSearch({page: 1}, params)
  }

  const onClose = () => {
    setViewId('')

    setOpenDrawer(false)

    form.resetFields()

    setSelectedKey('')

    setTreeData(prevState => {
      return {
        ...prevState,
        field: []
      }
    })

    setOptions(prevState => {
      return {
        ...prevState,
        dataSourceTableOptions: []
      }
    })
  }

  const onFormFinish = (values: IFormData) => {
    if (drawerType === 'edit') {
      confirm({
        title: "提示",
        content: '当前修改会影响上游应用该表的所有表及后续统计数据，确认修改吗？',
        okText: "确定",
        onOk() {
          if (!isParenthesesBalanced(form.getFieldValue('fieldExpression'))) {
            error('语义错误，请重新检查')
            return
          }

          editIndicator(values)
        },
      });
      return
    }

    if (!isParenthesesBalanced(form.getFieldValue('fieldExpression'))) {
      error('语义错误，请重新检查')
      return
    }

    addIndicator(values)
  }

  const addIndicator = (formData: IFormData) => {
    const layer = options.dataSourceTableOptions.find(item => item.value === formData.dataSourceTable)?.layer ?? ''

    const params: IAddIndicator = {
      tableName: formData.dataSourceTable,
      fieldCnExpression: formData.fieldExpression,
      indexName: formData.indicatorName,
      classify: formData.classificationOfIndicators,
      layer: layer,
      indexType: '1'
    }
    api.addIndicator(params).then((res: any) => {
      if (res?.code === 200) {
        if (res?.msg) {
          error(res?.msg)
          return
        }

        message.success('新建成功')

        onClose()

        onSearch({page: 1})
      } else {
        message.error('新建失败')

        // onClose()
      }
    })
  }

  const editIndicator = (formData: IFormData) => {
    const layer = options.dataSourceTableOptions.find(item => item.value === formData.dataSourceTable)?.layer ?? ''

    const params: IEditIndicator = {
      id: viewId,
      tableName: formData.dataSourceTable,
      fieldCnExpression: formData.fieldExpression,
      indexName: formData.indicatorName,
      classify: formData.classificationOfIndicators,
      layer: layer,
      indexType: '1'
    }
    api.editIndicator(params).then((res: any) => {
      if (res?.code === 200) {
        if (res?.msg) {
          error(res?.msg)
          return
        }

        message.success('编辑成功')

        onClose()

        onSearch({page: 1})
      } else {
        message.error('编辑失败')

        // onClose()
      }
    })
  }

  const onExpand = (newExpandedKeys: React.Key[], key: string) => {
    setExpandedKeys((prevState) => {
      return {
        ...prevState,
        [key]: newExpandedKeys
      }
    });
    setAutoExpandParent((prevState) => {
      return {
        ...prevState,
        [key]: false
      }
    });
  };

  const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: 'function' | 'field') => {
    const dataList: { key: React.Key; title: string }[] = [];

    const generateList = (data: TreeDataNode[]) => {
      for (let i = 0; i < data.length; i++) {

        const node = data[i];

        const {key, title} = node;

        dataList.push({key, title: title as string});

        if (node.children) {
          generateList(node.children);
        }
      }
    };

    const {value} = e.target;

    const tree = treeData[key]

    generateList(tree)

    const newExpandedKeys = dataList
      .map((item) => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(item.key, tree);
        }
        return null;
      })
      .filter((item, i, self): item is React.Key => !!(item && self.indexOf(item) === i));

    setExpandedKeys(prevState => {
      return {
        ...prevState,
        [key]: value.length === 0 ? [] : newExpandedKeys
      }
    });

    setSearchValue(prevState => {
      return {
        ...prevState,
        [key]: value
      }
    });

    setAutoExpandParent(prevState => {
      return {
        ...prevState,
        [key]: true
      }
    });
  };

  // 检查括号是否完整配对的函数
  const isParenthesesBalanced = (str: string): boolean => {
    if (str === undefined) return true
    const stack = [];
    for (const char of str) {
      if (char === '(') {
        stack.push(char);
      } else if (char === ')') {
        if (stack.length === 0) {
          // 发现右括号但栈为空，说明括号不匹配
          return false;
        }
        stack.pop();
      }
    }
    // 如果栈为空，说明所有左括号都有对应的右括号
    return stack.length === 0;
  }

  const onSelect: any = (selectedKeys: React.Key[], key: 'function' | 'field') => {
    if (drawerType === 'view') {
      return
    }

    if (treeData.field.length === 0) {
      message.warning('请先选择指标分类和数据源表')
      return
    }

    if (selectedKeys.toString().length > 3) {
      const dataList: { key: React.Key; title: string }[] = [];

      const generateList = (data: TreeDataNode[]) => {
        for (let i = 0; i < data.length; i++) {

          const node = data[i];

          const {key, title} = node;

          key && key.toString().length > 3 && dataList.push({key, title: title as string});

          if (node.children) {
            generateList(node.children);
          }
        }
      };

      const tree = treeData[key]

      generateList(tree)

      const titleTmp = dataList.find(item => item.key === selectedKeys.toString())?.title ?? ''

      let title = dataList.find(item => item.key === selectedKeys.toString())?.title?.replace(/\((.*?)\)/g, '()') ?? ''

      if (titleTmp === 'COUNT(DISTINCT X)') {
        title = 'COUNT(DISTINCT)'
      }

      const endsWithSpecialCharacter = (str: string) => {
        const specialCharacters = ['>', '<', '≥', '≤', '+', '-', '*', '/', '=', '(', ')', 'AND', 'OR', 'IN']

        // 将特殊字符数组转换为正则表达式的源字符串
        const regexSource = specialCharacters.map(char => char.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|');

        const regex = new RegExp(`(${regexSource})$`);

        // 测试字符串的末尾是否匹配正则表达式
        return regex.test(str);
      }

      const regex = /\(\)/;

      const currentValue = form.getFieldValue('fieldExpression')

      if (key === 'function') {
        setSelectedKey(selectedKeys.toString())

        const type = selectedKeys.toString().split('-')[1]

        if (type === '1' && title !== 'IF()') {
          form.setFieldValue('fieldExpression', currentValue + title)
        } else {
          const splitExpression = currentValue.split(',')

          let newExpression = currentValue

          const currentSplitExpression = splitExpression.filter((item: string) => !endsWithSpecialCharacter(item)) ?? []

          if (currentSplitExpression.length > 0) {

            const index = splitExpression.indexOf(currentSplitExpression[0])

            splitExpression[index] = title.replace(regex, `(${splitExpression[index]})`);

            newExpression = splitExpression.slice(0, index).join('') + (splitExpression.slice(index).join(','));

          } else {
            newExpression = newExpression + title
          }
          form.setFieldValue('fieldExpression', newExpression)
        }
      } else {
        setSelectedKey('')

        let newExpression = currentValue.replace(regex, `(${title})`);

        if (newExpression === currentValue) {
          if (endsWithSpecialCharacter(currentValue)) {
            newExpression = `${currentValue},${title}`;
          } else {
            newExpression = currentValue === '' ? title : newExpression = `${currentValue},${title}`;
          }
        }
        form.setFieldValue('fieldExpression', newExpression)
      }
    }
  };

  const onSearch = async (query?: { page?: PageQuery, size?: PageQuery }, params?: ISearch) => {
    const data: IGetIndicatorManagementList = {
      query: {
        pageNo: query?.page || pageInfo.page,
        pageSize: query?.size || pageInfo.pageSize
      },
      params: {
        indexName: params?.indexName || '',
        classifyList: params?.classifyList ?? [],
        indexType: '1',
        searchInfo: params?.searchInfo || '',
        startTime: params?.startTime || '',
        endTime: params?.endTime || '',
        modelType: 'indicator_category'
      }
    }

    const res = await api.getIndicatorManagementList(data)

    setTableData(res?.data?.records ?? [])

    setPageInfo({
      page: res?.data?.current ?? 1,
      pageSize: res?.data?.size ?? 10,
      total: res?.data?.total ?? 0,
    })
  }

  const getIndicatorCategory = async () => {

    const res = await api.getIndicatorCategory({modelType: 'indicator_category'})

    let result: any = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { dictLabel: string; dictValue: string; }) => {
        return {
          label: item.dictLabel,
          value: item.dictValue,
        }
      }) ?? []
    }

    setListParams(prevState => {
      return {
        ...prevState,
        indicatorType: result.map((item: { value: string; }) => item.value)
      }
    })

    filterViewRef.current.setFormInfo('indicatorType', result.map((item: { value: string; }) => item.value))

    setOptions(prevState => {
      return {
        ...prevState,
        classificationOfIndicatorsOptions: result
      }
    })
  }

  const getTablesList = async (layer: string, reset = true) => {
    reset && form.setFieldValue('dataSourceTable', '')
    reset && form.setFieldValue('fieldExpression', '')
    reset && setTreeData(prevState => {
      return {
        ...prevState,
        field: []
      }
    })

    const res = await api.getTablesList({layer: layer})

    let result: Array<{ label: string, value: string }> = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { tableName: string; layer: string; }) => {
        return {
          label: item.tableName,
          value: item.tableName,
          layer: item.layer
        }
      }) ?? []
    }

    setOptions(prevState => {
      return {
        ...prevState,
        dataSourceTableOptions: result
      }
    })
  }

  function transformToTree(data: any[]) {
    const fieldTreeData: {
      title: string; key: string; icon: JSX.Element;
      children: any;
    }[] = [];

    // 分组
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.filedType]) {
        acc[item.filedType] = [];
      }
      acc[item.filedType].push(item);
      return acc;
    }, {});

    // 构建树结构
    Object.keys(groupedData).forEach((type, index) => {
      const group = groupedData[type];
      const node = {
        title: `${type}(${group.length})`,
        key: `0-${index}`,
        icon: <FolderOpenOutlined/>,
        children: group.map((item: { filedCnName: any; filedEnName: any }, idx: any) => ({
          title: item.filedCnName || item.filedEnName,
          key: `0-${index}-${idx}`
        }))
      };
      fieldTreeData.push(node);
    });

    return fieldTreeData;
  }

  const getFieldsList = async (tableName: string, reset = true) => {
    reset && form.setFieldValue('fieldExpression', '')

    if (!tableName) {
      setOptions(prevState => {
        return {
          ...prevState,
          fieldOptions: []
        }
      })
      return
    }

    const res = await api.getFieldsList({
      tableName: tableName,
      layer: options.dataSourceTableOptions.find(item => item.label === tableName)?.layer ?? ''
    })

    setTreeData(prevState => {
      return {
        ...prevState,
        field: transformToTree(res?.data ?? [])
      }
    })
  }

  const functionTreeData = useMemo(() => {
    const loop = (data: TreeDataNode[]): TreeDataNode[] =>
      data.map((item) => {
        const strTitle = item.title as string;
        const strKey = item.key as string;

        const index = strTitle.indexOf(searchValue.function);

        const title =
          (index > -1 && searchValue.function.length > 0 && strKey.length > 3) ? (
            <span key={item.key}>
              <span style={{color: '#1677ff'}}>{strTitle}</span>
            </span>
          ) : (
            <span key={item.key}>{strTitle}</span>
          );

        if (item.children) {
          return {title, key: item.key, icon: item?.icon, children: loop(item.children)};
        }

        return {
          title: <div style={{wordBreak: 'break-all'}}>{title}</div>,
          key: item.key,
          icon: item?.icon
        };
      });

    return loop(treeData.function);
  }, [searchValue]);

  const fieldTreeData = useMemo(() => {
    const loop = (data: TreeDataNode[]): TreeDataNode[] =>
      data.map((item) => {
        const strTitle = item.title as string;

        const strKey = item.key as string;

        const index = strTitle.indexOf(searchValue.field);

        const title =
          (index > -1 && searchValue.field.length > 0 && strKey.length > 3) ? (
            <span key={item.key}>
              <span style={{color: '#1677ff'}}>{strTitle}</span>
            </span>
          ) : (
            <span key={item.key}>{strTitle}</span>
          );

        if (item.children) {
          return {title, key: item.key, icon: item?.icon, children: loop(item.children)};
        }

        return {
          title: <div style={{wordBreak: 'break-all'}}>{title}</div>,
          key: item.key,
          icon: item?.icon
        };
      });

    return loop(treeData.field);
  }, [searchValue, treeData]);

  useEffect(() => {
    onSearch();
    getIndicatorCategory()
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headBox}>
          <div className={styles.titleText}>
            <ModuleTitle mark="large" title="原子指标"/>
          </div>

          <div className={styles.searchBox}>
            <SearchIconInput
              placeholder="请输入指标名称或指标分类进行搜索"
              width={300}
              onSearch={(e: string) => {
                setListParams({
                  ...listParams,
                  indicatorName: e,
                });
                onSearch({page: 1}, {searchInfo: e})
              }}
            />
            <FilterIcon
              handleFilterClick={handleFilterClick}
              filterTitle="过滤器"
            />
            <span
              style={{
                width: "0",
                height: "16px",
                border: "var(--border3)",
                marginRight: 16,
              }}
            />
            <SystemButton
              title='新建原子指标'
              type="primary"
              onClick={() => {
                setOpenDrawer(true);
                setDrawerType('create')
              }
              }
            />
          </div>
        </div>

        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={onFinish}
          defaultFormValue={defaultSearchInfo}
        />

        <Table
          style={{marginTop: 38}}
          dataSource={tableData}
          columns={columns}
          pagination={{
            total: pageInfo.total,
            pageSize: pageInfo.pageSize,
            current: pageInfo.page,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showTotal: (total) => `共 ${total} 条`,
            onChange: (page, pageSize) => {
              onSearch({page, size: pageSize})
              setPageInfo(prevState => {
                return {
                  ...prevState,
                  page,
                  pageSize
                }
              })
            }
          }}
          scroll={{x: columns.reduce((pre, cur) => pre + (cur.width as number ?? 200), 0)}}
        />
      </div>

      <Drawer
        width={680}
        title={drawerTitle[drawerType]}
        onClose={onClose}
        open={openDrawer}
        extra={
          <Space>
            <Button
              onClick={onClose}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (drawerType === 'view') {
                  setDrawerType('edit')
                } else {
                  form.submit()
                }
              }
              }
            >
              {drawerType === 'view' ? '编辑' : '确定'}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          disabled={drawerType === 'view'}
          onFinish={onFormFinish}
          initialValues={defaultFormData}
        >
          <Form.Item
            name="indicatorName"
            label="指标名称"
            rules={[{required: true, message: '请输入指标名称'}]}
          >
            <Input
              showCount
              maxLength={50}
              placeholder="请输入指标名称"
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, '');
                form.setFieldValue('indicatorName', value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="classificationOfIndicators"
            label="指标分类"
            rules={[{required: true, message: '请选择指标分类'}]}
          >
            <Select
              placeholder='请选择指标分类'
              options={options.classificationOfIndicatorsOptions}
              onChange={(value) => getTablesList(value)}
            />
          </Form.Item>
          <Form.Item
            name="dataSourceTable"
            label="数据源表"
            rules={[{required: true, message: '请选择数据源表'}]}
          >
            <Select
              placeholder='请选择数据源表'
              options={options.dataSourceTableOptions}
              onChange={(value) => getFieldsList(value)}
            />
          </Form.Item>
          <Form.Item
            label="字段表达式"
            className={styles.fieldExpressionForm}
            name='fieldExpression'
          >
            <>
              {selectedKey &&
                <div className={styles.functionTipsBox}>
                  <ExclamationCircleFilled style={{color: 'rgba(60, 136, 240, 1)', paddingTop: 2}}/>
                  <div className={styles.functionTipsText}>
                    <div style={{fontWeight: 'bold'}}>
                      函数释义
                    </div>
                    <div>
                  <span
                    style={{fontWeight: 'bold'}}>{functionTipInfo().field} &nbsp;</span> {functionTipInfo().definition}
                    </div>
                    {functionTipInfo().example &&
                      <div>
                        <span style={{fontWeight: 'bold'}}>示例 &nbsp;</span> {functionTipInfo().example}
                      </div>
                    }
                  </div>
                </div>
              }

              <Row className={styles.fieldExpressionBox}>
                <Col span={8}>
                  <div className={styles.header}>
                    函数
                  </div>
                  <div className={styles.treeBox}>
                    <Input
                      style={{marginBottom: 17}}
                      suffix={<SearchOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}}/>}
                      placeholder="请输入关键字搜索"
                      onChange={(e) => onChange(e, 'function')}
                    />
                    <Tree
                      showIcon
                      onExpand={(newExpandedKeys) => onExpand(newExpandedKeys, 'function')}
                      expandedKeys={expandedKeys.function}
                      autoExpandParent={autoExpandParent.function}
                      selectedKeys={selectedKeys}
                      treeData={functionTreeData}
                      onSelect={(selectedKeys) => onSelect(selectedKeys, 'function')}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.header}>
                    字段
                  </div>

                  <div className={styles.treeBox}>
                    <Input
                      suffix={<SearchOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}}/>}
                      style={{marginBottom: 17}}
                      placeholder="请输入关键字搜索"
                      onChange={(e) => onChange(e, 'field')}
                    />
                    <Tree
                      showIcon
                      onExpand={(newExpandedKeys) => onExpand(newExpandedKeys, 'field')}
                      expandedKeys={expandedKeys.field}
                      autoExpandParent={autoExpandParent.field}
                      selectedKeys={selectedKeys}
                      treeData={fieldTreeData}
                      onSelect={(selectedKeys) => onSelect(selectedKeys, 'field')}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className={styles.header}>
                    编辑区
                  </div>
                  <div className={styles.editSign}>
                    <Flex gap='small'>
                      {['>', '<', '≥', '≤', '+', '-', '*', '/', '=', '(', ')'].map(item => (
                        <Button
                          key={item}
                          type='link'
                          style={{padding: 0}}
                          onClick={() => {
                            if (drawerType === 'view') {
                              return
                            }
                            const currentValue = form.getFieldValue('fieldExpression')
                            form.setFieldValue('fieldExpression', currentValue + item)
                          }}
                        >
                          {item}
                        </Button>
                      ))}
                    </Flex>
                  </div>
                  <Form.Item
                    name='fieldExpression'
                    style={{padding: 16, boxSizing: 'border-box'}}
                  >
                    <TextArea
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}
export default AtomicIndicators
