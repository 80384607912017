const hasUpperCase = /.*[A-Z].*/;
const hasLowerCase = /.*[a-z].*/;
const hasDigit = /.*\d.*/;
const hasSpecialChar = /.*[^a-zA-Z0-9\s].*/;

export const lowStrengthLevel = (pw: string) => {
    return (hasUpperCase.test(pw) || hasLowerCase.test(pw)) && hasDigit.test(pw);
}

export const middleStrengthLevel = (pw: string) => {
    return (hasUpperCase.test(pw) || hasLowerCase.test(pw)) && hasDigit.test(pw) && hasSpecialChar.test(pw);
}

export const highStrengthLevel = (pw: string) => {
    return hasUpperCase.test(pw) && hasLowerCase.test(pw) && hasDigit.test(pw) && hasSpecialChar.test(pw);
}

export const mobileVal = (mobile: string) => {
    return /^1[3456789]\d{9}$/.test(mobile)
}

export const emailVal = (email: string) => {
    return /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
}