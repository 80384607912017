import { useEffect, useState } from "react";
import {
  getChannelListApi,
  getShopListApi,
  getMemberCardApi,
  getMemberMemberCardApi,
  getMemberChannelListApi,
  getMemberShopListApi,
  getCustomerChannelListApi,
  getCustomerShopListApi,
} from "../../api/dataBoardApi";

// 获取体系
export const useGetMemberCard = (type: string) => {
  const [memberCardList, setMemberCardList] = useState<any>([]);

  //获得渠道列表
  const getChannelList = async () => {
    let res: any = {};
    if (type === "member") {
      res = await getMemberMemberCardApi();
    }
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ value: e.id, label: e.name }));
      setMemberCardList(arr);
    }
  };

  useEffect(() => {
    getChannelList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { memberCardList };
};

// 获取平台渠道
export const useGetChannelList = (type: string) => {
  const [channelList, setChannelList] = useState([]);
  const [channelAllList, setChannelAllList] = useState<any[]>([]);

  //获得渠道列表
  const getChannelList = async (memberCardId: string | null = null) => {
    let res: any = {};
    if (type === "member") {
      res = await getMemberChannelListApi({ memberCardId });
    } else {
      res = await getCustomerChannelListApi({ memberCardId });
    }
    // const res = await getChannelListApi({ memberCardId });
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ value: e.id, label: e.name }));
      setChannelList(arr);
      setChannelAllList([{ value: "all", label: "全部平台" }, ...arr]);
    }
  };

  useEffect(() => {
    getChannelList();
  }, []);

  return { channelList, channelAllList, getChannelList };
};

// 通过渠道获取店铺
export const useGetShoplList = (type: string) => {
  const [shopList, setShopList] = useState([]);
  const [shopAllList, setShopAllList] = useState<any[]>([]);

  //获得渠道列表
  const getShopList = async (
    memberCardId: string | null = null,
    channelType: string | null = null
  ) => {
    let res: any = {};
    if (type === "member") {
      res = await getMemberShopListApi({ memberCardId, channelType });
    } else {
      res = await getCustomerShopListApi({ memberCardId, channelType });
    }
    // const res = await getShopListApi({ memberCardId, channelType });
    if (res?.code === 200) {
      const arr = res.data?.map((e: any) => ({ value: e.id, label: e.name }));
      setShopList(arr);
      setShopAllList([{ value: "0", label: "全部店铺" }, ...arr]);
    }
  };

  useEffect(() => {
    getShopList();
  }, []);

  return { shopList, shopAllList, getShopList };
};
