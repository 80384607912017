import { Divider, Drawer, Table, Tag } from 'antd';
import React, { useEffect, ReactNode, useImperativeHandle, useState, forwardRef, useRef } from 'react';
import style from './index.module.scss';
import type { TableProps, DrawerProps, TabsProps } from 'antd';
import { getUserEnumsListApi } from '../../../../../api/userCenter/userCenter';
import SearchIconInput from '../../../../../component/Input/SearchIconInput/searchIconInput';
import { getTableScroll } from '@/store/index'

type FieldType = {
  fieldCode: string;
  fieldType: string;
  enumCodeOrName: string;
};
let defaultFormValue = {
  fieldCode: '',
  fieldType: '',
  enumCodeOrName: '',
};
const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

let checkBehavior = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
      record?: any;
    },
    ref: any,
  ) => {
    const [tableInfo, setTableInfo] = useState(defaultTableInfo);
    const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
    const [resultList, setResultList] = useState<any>([]);
    const filterViewRef = useRef<any>(null);
    const [filterStatus, setFilterStatus] = useState<number>(0);
    const [scrollY, setScrollY] = useState("")

    const record = props.record;
    // 判断是否为偶数
    const isEven = (num: any) => {
      return num % 2 === 0;
    };
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        props.drawer();
        setTableInfo({ ...defaultTableInfo });
        // 判断是否需要关闭筛选框
        if (!isEven(filterStatus)) {
          filterViewRef.current?.showFilterView();
          setFilterStatus(0);
        }
      }
    };

    interface DataType {
      id: string;
      phone: string;
      sendPoint: string;
      sendStatus: string;
      memberCardName: string;
      shopName: string;
    }

    const columns: TableProps<DataType>['columns'] = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        render: (_, record, i) => <div> {(tableInfo.pageNum - 1) * tableInfo.pageSize + (i + 1)}</div>,
      },
      {
        title: '枚举值',
        dataIndex: 'enumCode',
        key: 'enumCode',
      },
      {
        title: '展示名',
        dataIndex: 'enumName',
        key: 'enumName',
      },
    ];
    useImperativeHandle(ref, () => ({
      getResultList,
    }));
    useEffect(() => {
      searchInfo.fieldType && getResultList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(searchInfo)]);

    const getResultList = async (data?: any) => {
      if (data) {
        setSearchInfo({ ...searchInfo, fieldType: data.fieldType, fieldCode: data.fieldCode });
        return;
      }
      // const { pageNum, pageSize } = tableInfo;
      let param = {
        ...searchInfo,
      };
      const res = await getUserEnumsListApi(param);

      if (res.code === 200) {
        setResultList(res.data || []);
        // setTableInfo({ ...tableInfo, total: res.data?.total || 0 });
        setScrollY(getTableScroll(118))
      }
    };
    const handleTableChange = (pagination: any, _: any) => {
      setTableInfo({
        ...tableInfo,
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
      });
    };
    const enumTipList = [
      {
        label: '创建人',
        value: record.createBy,
      },
      {
        label: '创建时间',
        value: record.createTime,
      },
      {
        label: '更新人',
        value: record.updateBy,
      },
      {
        label: '更新时间',
        value: record.updateTime,
      },
      {
        label: '描述',
        value: record.behaviorDescribe || '——',
      },
    ];
    const drawerHeader = () => {
      return (
        <>
          <div className={style['header-title']}>
            <span style={{ marginRight: 10 }}>{record.field}</span>
            <span style={{ marginRight: 10 }}>{record.fieldName}</span>
            <Tag bordered={false} color="blue">
              {/* {record.fieldType === 'Enum' ? '枚举' : record.fieldType} */}
              {record.fieldShowType}
            </Tag>
          </div>
          <div className={style.drawerTip}>
            {enumTipList.map(it => (
              <div className={style.tipItem} key={it.label}>
                {it.label}: {it.value}
              </div>
            ))}
          </div>
        </>
      );
    };
    const TableSection = () => {
      return (
        <Table
          style={{ marginTop: '24px' }}
          columns={columns}
          dataSource={resultList}
          scroll={{y: scrollY}}
          pagination={{
            total: tableInfo.total,
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: total => `共 ${total} 条`,
            defaultCurrent: 1,
          }}
          onChange={handleTableChange}
        />
      );
    };
    return (
      <div>
        <Drawer title={drawerHeader()} className={style.drawerTableClose} onClose={handleDrawerSwitch} open={props.open} width={700}>
          <div className={style['center-bgd']}>
            <div className={style['center-left']}>枚举值</div>
            <div className={style['center-right']}>
              <SearchIconInput
                clear={true}
                placeholder="请输入枚举值/展示名"
                onSearch={(e: string) => {
                  setSearchInfo({
                    ...searchInfo,
                    enumCodeOrName: e,
                  });
                }}></SearchIconInput>
            </div>
          </div>
          <TableSection />
        </Drawer>
      </div>
    );
  },
);
export default checkBehavior;
