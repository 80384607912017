import { useEffect, useState } from "react";
import style from "./index.module.scss";
import { Col, Row, Table, Empty, message } from "antd";

import { PauseOutlined, PlusOutlined } from "@ant-design/icons";

import {
  TimeTitle,
  IconMap,
  ExportTitle,
  TableSearch,
  ChannelIconName,
  TableNumber,
} from "../../../../component/DataBoardStyles";
import { crmTipsMap } from "../../../../assets/public/pageMap";
import { TextWithQuestion } from "../../../../component/MiniStyles";

import {
  getCustomerOverview,
  getCustomerDetails,
  getCustomerDownload,
} from "@/crm/api/dataBoardApi";

import { CustomerQuery } from "@/crm/api/dataBoardApi/types";
import dayjs from "dayjs";
import handScrollTop from "@/crm/assets/public/handscrollTop";

// 进度条颜色
const progressColorMap = new Map([
  ["JD", "#3BA0FF 0%, rgba(59,160,255,0.25) 100%"],
  ["TM", "#36CBCB 0%, rgba(19,194,194,0.25) 100%"],
  ["DY", "#4DCB73 0%, rgba(77,203,115,0.25) 100%"],
  ["WX", "#FADB14 0%, rgba(250,219,20,0) 100%"],
  ["MD", "#FADB14 0%, rgba(250,219,20,0) 100%"],
  ["YZ", "#A0D911 0%, rgba(160,217,17,0) 100%"],
]);
// 进度条点颜色
const dotColorMap = new Map([
  ["JD", "#3BA0FF"],
  ["TM", "#36CBCB"],
  ["DY", "#4DCB73"],
  ["WX", "#FADB14"],
  ["MD", "#FADB14"],
  ["YZ", "#A0D911"],
]);

const cardList = [
  [
    [
      {
        title: "全渠道客户",
        tipTitle: "客户资产分析_全渠道客户",
        icon: "user",
      },
    ],
    [
      {
        title: "活跃客户",
        tipTitle: "客户资产分析_活跃客户",
        icon: "smileFilled",
      },
      {
        title: "沉默客户",
        tipTitle: "客户资产分析_沉默客户",
        icon: "mehFilled",
      },
      {
        title: "流失客户",
        tipTitle: "客户资产分析_流失客户",
        icon: "frownFilled",
      },
    ],
  ],
  [
    [
      {
        title: "已购客户",
        tipTitle: "客户资产分析_已购客户",
        icon: "hookImage",
        rightIcon: "=",
      },
      {
        title: "已购会员",
        tipTitle: "客户资产分析_已购会员",
        icon: "crownFilleds",
        rightIcon: "+",
      },
      {
        title: "已购非会员",
        tipTitle: "客户资产分析_已购非会员",
        icon: "user",
      },
    ],
  ],
  [
    [
      {
        title: "当期已购会员",
        tipTitle: "客户资产分析_当期已购会员",
        icon: "crownFilleds",
        rightIcon: "=",
      },
      {
        title: "新客会员",
        tipTitle: "客户资产分析_新客会员",
        icon: "crownFilleds",
        rightIcon: "+",
      },
      {
        title: "老客会员",
        tipTitle: "客户资产分析_老客会员",
        icon: "crownFilleds",
      },
    ],
  ],
  [
    [
      {
        title: "当期已购非会员",
        tipTitle: "客户资产分析_全部非会员",
        icon: "user",
        rightIcon: "=",
      },
      {
        title: "新客非会员",
        tipTitle: "客户资产分析_新客非会员",
        icon: "user",
        rightIcon: "+",
      },
      {
        title: "老客非会员",
        tipTitle: "客户资产分析_老客非会员",
        icon: "user",
      },
    ],
  ],
];

const CustomerAssetAnalysis = () => {
  const [overViewInfo, setOverViewInfo] = useState<any[]>([]);
  const [tableInfo, setTableInfo] = useState<any[]>([]);
  const [searchInfo, setSearchInfo] = useState<any>({});

  useEffect(() => {
    getOverView();
    handScrollTop();
  }, []);

  // 获得总览列表
  const getOverView = async () => {
    const res = await getCustomerOverview();
    if (res?.code === 200) {
      const { data } = res;
      const list: any = [
        [data?.one || [], data?.two || []],
        [data?.three || []],
        [data?.four || []],
        [data?.five || []],
      ];
      setOverViewInfo(list);
    } else {
      setOverViewInfo([]);
    }
  };

  // 获得表格数据
  const getTableInfo = async (e: CustomerQuery) => {
    setSearchInfo(e);
    const res = await getCustomerDetails({
      ...e,
      platformType: e.platformType || "all",
      shopId: e.shopId || "0",
    });
    if (res?.code === 200) {
      setTableInfo(res?.data || []);
    } else {
      setTableInfo([]);
    }
  };

  // 导出
  const handDownload = async () => {
    const params = {
      query: {
        ...searchInfo,
        shopId: searchInfo.shopId || "0",
        platformType: searchInfo.platformType || "all",
      },
      file: {
        taskName: `全渠道客户资产明细数据-客户资产分析-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`, //任务名称 + 日期时间
        taskLocation: "看板/基础看板/客户资产分析", //页面位置
        //文件上传信息
        service: "eshop", //服务编码 （eshop 电商，zt 中台）
        name: "全渠道客户资产明细数据", //文件名
        originalFilename: `全渠道客户资产明细数据-客户资产分析-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}.csv`, //文件原名
        contentType: "text/csv", //文件类型
      },
    };
    const res = await getCustomerDownload(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };

  // 进度条
  const ProgressBar = ({
    info,
    channelRate,
  }: {
    info: any;
    channelRate: string;
  }) => {
    const channel = channelRate.split("Ra")[0] || "";
    const numRate = Number(info[channelRate]);
    const rate = numRate > 100 ? 100 : numRate ? numRate : 0;

    return (
      <div style={{ marginBottom: "12px" }}>
        <div className={style["progress-text"]}>
          <ChannelIconName
            channelType={channel.toUpperCase()}
            channelName={info[`${channel}Name`]}
          />
          <div className={style["num"]}>
            {Number(info[channel]).toLocaleString()}
          </div>
        </div>
        <div className={style["progress"]}>
          <div
            className={style["rate"]}
            style={{
              width: `${rate}%`,
              background: `linear-gradient(-90deg, ${progressColorMap.get(
                channel.toUpperCase()
              )}), var(--background1)`,
            }}
          />
          <div
            className={style["dot-top"]}
            style={{
              left: `calc( ${rate}% - 1px)`,
              background: dotColorMap.get(channel.toUpperCase()),
            }}
          />
          <div
            className={style["dot-bottom"]}
            style={{
              left: `calc( ${rate}% - 1px)`,
              background: dotColorMap.get(channel.toUpperCase()),
            }}
          />
        </div>
      </div>
    );
  };

  // 小卡
  const CardSection = ({
    info,
    index1,
    index2,
    index3,
  }: {
    info: any;
    index1: number;
    index2: number;
    index3: number;
  }) => {
    const {
      rightIcon = "",
      title,
      icon,
      tipTitle,
    } = cardList[index1][index2][index3];
    const { all = 0 } = info;

    const progressList =
      Object?.keys(info)?.filter(
        (e) => e.split("Ra").length === 2 && e !== "allRate"
      ) || [];

    return (
      <div className={style["small-card-bgd"]}>
        <div className={style["flex-between"]}>
          <TextWithQuestion
            title={
              <span>
                <IconMap
                  type={icon}
                  style={{ transform: "translateY(-3px)" }}
                />
                <span className={style["card-title"]}>{title}</span>
              </span>
            }
            tips={crmTipsMap.get(tipTitle)}
            style={{ color: "var(--text-font-color4)" }}
            black
          />
          <div className={style["num"]}>{Number(all).toLocaleString()}</div>
        </div>
        {/* 白色卡片 */}
        <div className={style["card"]}>
          {progressList.map((e) => (
            <ProgressBar key={e} info={info} channelRate={e} />
          ))}
        </div>
        {rightIcon === "=" && <PauseOutlined className={style["card-icon"]} />}
        {rightIcon === "+" && <PlusOutlined className={style["card-icon"]} />}
      </div>
    );
  };

  // 上部卡片部分
  const getCardSection = () => {
    return (
      <div style={{ marginBottom: "32px" }}>
        {overViewInfo.length > 0 &&
          overViewInfo.map((item_1, index_1) => (
            <div className={style["card-list-section"]} key={index_1}>
              {item_1?.map((item_2: any, index_2: number) => {
                return (
                  <Row
                    gutter={72}
                    key={index_2}
                    style={
                      index_2 < item_1?.length - 1
                        ? { marginBottom: "24px" }
                        : {}
                    }>
                    {Array.isArray(item_2) &&
                      item_2?.map((item_3: any, index_3: number) => {
                        return (
                          <Col span={8} key={index_3}>
                            <CardSection
                              info={item_3}
                              index1={index_1}
                              index2={index_2}
                              index3={index_3}
                            />
                          </Col>
                        );
                      })}
                  </Row>
                );
              })}
            </div>
          ))}
        {overViewInfo.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: "32px auto" }}
          />
        )}
      </div>
    );
  };

  // 表格问号标题
  const TableTitle = ({ children }: { children: string }) => (
    <TextWithQuestion
      black
      title={children}
      tips={crmTipsMap.get(`客户资产分析_${children}`)}
      style={{ display: "block", marginTop: "3px" }}
    />
  );

  // 表格部分
  const TableSection = () => {
    return (
      <Table
        dataSource={tableInfo}
        pagination={false}
        style={{ marginTop: "24px" }}
        scroll={{ x: 1500, y: 500 }}
        rowClassName={style["table-row"]}
        expandable={{
          expandedRowClassName: () => {
            return style["expanded-row-expended"];
          },
        }}
        rowKey="id">
        <Table.Column width={46} fixed="left" />
        <Table.Column
          className={style["table-column-blue"]}
          title="时间"
          dataIndex="bizDate"
          width={110}
          fixed="left"
        />
        <Table.Column
          className={style["table-column-green"]}
          title="全部"
          dataIndex="name"
          width={140}
          fixed="left"
        />
        <Table.Column
          title={<TableTitle>全部客户</TableTitle>}
          dataIndex="customerAllNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>全部未购会员</TableTitle>}
          dataIndex="memberNotDealNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>全部已购客户</TableTitle>}
          dataIndex="customerDealNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>活跃客户</TableTitle>}
          dataIndex="customerActiveNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>沉默客户</TableTitle>}
          dataIndex="customerSilentNum"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
        <Table.Column
          title={<TableTitle>流失客户</TableTitle>}
          dataIndex="customerLoseNum"
          render={(value) => <TableNumber value={value} />}
          width={120}
        />
        <Table.Column
          title={<TableTitle>一次客</TableTitle>}
          dataIndex="customerOneTime"
          render={(value) => <TableNumber value={value} />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>多次客</TableTitle>}
          dataIndex="customerMultipleTimes"
          render={(value) => <TableNumber value={value} />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>全部已购会员</TableTitle>}
          dataIndex="memberDealNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>全部已购非会</TableTitle>}
          dataIndex="nonMemberDealNum"
          render={(value) => <TableNumber value={value} />}
          width={140}
        />
        <Table.Column
          title={<TableTitle>二回率</TableTitle>}
          dataIndex="newSecondCustomerRate"
          render={(value) => <TableNumber value={value} rate />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>回购率</TableTitle>}
          dataIndex="repurchaseRate"
          render={(value) => <TableNumber value={value} rate />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>历史回购率</TableTitle>}
          dataIndex="hisRepurchaseRate"
          render={(value) => <TableNumber value={value} rate />}
          width={130}
        />
        <Table.Column
          title={<TableTitle>复购率</TableTitle>}
          dataIndex="repRepurchaseRate"
          render={(value) => <TableNumber value={value} rate />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>全部会员</TableTitle>}
          dataIndex="memberNum"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
      </Table>
    );
  };

  return (
    <div className={style["CustomerAssetAnalysis-bgd"]}>
      <TimeTitle
        title="全渠道客户资产数据总览"
        tips={crmTipsMap.get("全渠道客户资产数据总览")}
      />
      {/* 上部卡片部分 */}
      {getCardSection()}
      <ExportTitle
        title="全渠道客户资产明细数据"
        tips={crmTipsMap.get("全渠道客户资产明细数据")}
        handExport={handDownload}
        permission="客户资产分析导出"
      />
      {/* 搜索部分 */}
      <TableSearch
        onFinish={getTableInfo}
        type="customer"
        background="transparent"
      />
      {/* 表格部分 */}
      <TableSection />
    </div>
  );
};

export default CustomerAssetAnalysis;
