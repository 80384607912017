import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FilterOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Drawer, Space, Steps, Modal, Popconfirm, message, Tooltip, Dropdown } from 'antd';
import type { TableProps, DrawerProps, TabsProps } from 'antd';
import ModuleTitle from '../../../../component/ModuleTitle';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterView from '../../../../component/FilterView/filterView';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import { GROUP_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import styles from './index.module.scss';
import { queryReportFormList, deletedReportForm, downloadReportForm } from '../../../../api/UserInsight/UserInsight';
import dayjs from 'dayjs';
import { Value } from 'sass';
const dateFormat = 'YYYY/MM/DD';

let operatePageNum: any;
let operatepageSize: any;
let preParams: any;
const GroupUserInsight = () => {
  const navigate = useNavigate();
  const filterViewRef: any = useRef(null);

  // 抽屉开关
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [listData, setListData] = useState([]);
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入分析报告名称',
      title: '报告名称',
      key: 'reportName',
      defaultValue: preParams?.reportName ? String(preParams.reportName) : null,
    },
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入创建人',
      title: '创建人',
      key: 'createBy',
      defaultValue: preParams?.createBy ? String(preParams.createBy) : null,
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '创建时间',
      key: 'beginTime-endTime',
      defaultValue: preParams?.beginTime ? [dayjs(preParams.beginTime, dateFormat), dayjs(preParams.endTime, dateFormat)] : null,
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '状态',
      key: 'calculateStatus',
      defaultValue: preParams?.calculateStatus ? String(preParams.calculateStatus) : null,
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '计算中',
        },
        {
          value: '1',
          label: '成功',
        },
        {
          value: '2',
          label: '失败',
        },
      ],
    },
  ];

  const searchParamInt: any = {
    reportName: '',
    createWay: '',
    status: '',
    beginTime: '',
    endTime: '',
  };
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange = (e: any) => {
    getListData({ pageSize: e.pageSize, pageNum: e.current }, searchParam);
  };

  const [deletePopConfirm, setDeletePopConfirm] = useState(false);
  const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number);

  // 操作列
  const getTableOperationButtonList = (item: any) => {
    const store = [];
    if (checkPermissions(GROUP_KEY.GROUP_DOWNLOAD)) {
      store.push({
        key: 'download',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              DownLoadReport(item);
            }}>
            下载
          </a>
        ),
      });
    }
    store.push({
      key: 'log',
      label: (
        <a
          style={{ color: item.enable ? 'var(--text-font-color9)' : 'var(--text-font-color10)' }}
          onClick={() => {
            if (item.enable) {
              navigate('/userInsight/GroupUserInsight/GroupUserInsight/history', {
                state: { record: item },
              });
            }
          }}>
          历史归档
        </a>
      ),
    });

    if (checkPermissions(GROUP_KEY.GROUP_DELETE)) {
      store.push({
        key: 'del',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              operateRecord(item, 'del');
            }}>
            删除
          </a>
        ),
      });
    }
    return store;
  };

  const DownLoadReport = async (item: any) => {
    const res = await downloadReportForm({
      reportId: item.reportId,
      reportChildId: 0,
      modelName: '洞察-群体画像洞察',
      taskLocation: '洞察/群体画像洞察/群体画像洞察',
    });
    if (res) {
      message.success('导出中，请到下载中心查看进度');
    }
  };
  // 操作列确认弹框
  const handleCancelClick = (record: any) => {
    setDeletePopConfirm(false);
  };
  // 操作列表 删除
  const operateRecord = (info: any, type: string) => {
    setSelectedTableKey(info.reportId);
    if (type === 'del') {
      setDeletePopConfirm(true);
    }
  };
  const columns: TableProps['columns'] = [
    {
      title: '序号',
      dataIndex: 'sort',
      key: 'sort',
      render: (_: any, record: any, i: number) => <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>,
    },
    {
      title: '报告名称',
      dataIndex: 'reportName',
      key: 'reportName',
      width: '300px',
      render: (_, record: any) => (
        <div>
          {record.calculateStatus == 0 ? (
            <span>{_}</span>
          ) : (
            <Space
              className={styles.oneidCopyOutlined}
              onClick={() => {
                localStorage.setItem('pageNum', String(pageQuery.pageNum));
                localStorage.setItem('pageSize', String(pageQuery.pageSize));
                localStorage.setItem('searchListData', JSON.stringify(searchParam));
                navigate('/userInsight/GroupUserInsight/GroupUserInsight/details', {
                  state: { reportId: record.reportId },
                });
              }}>
              {_}
            </Space>
          )}
        </div>
      ),
    },
    {
      title: '更新方式',
      dataIndex: 'updateModel',
      key: 'updateModel',
      render: (_, record) => (
        // 0 定时  3 手动
        <div>{_ === '0' ? <span>定时更新</span> : <span>手动更新</span>}</div>
      ),
    },
    {
      title: '状态',
      dataIndex: 'calculateStatus',
      key: 'calculateStatus',
      render: (_, record) => (
        // 0 计算中  1 成功  2失败
        <div>{_ == '0' ? <Tag color="processing">计算中</Tag> : _ == '1' ? <Tag color="success">成功</Tag> : <Tag color="error">失败</Tag>}</div>
      ),
    },
    {
      title: '计算完成时间',
      dataIndex: 'finishDate',
      key: 'finishDate',
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      align: 'center',
      render: (_, record: any) => (
        <Space>
          <Dropdown menu={{ items: getTableOperationButtonList(record) }} trigger={['click']}>
            <Popconfirm
              placement="right"
              className={styles.popconfirmBox}
              title="确定删除此分析报告吗？"
              onConfirm={() => handDeleteRecord(record)}
              onCancel={() => handleCancelClick(record)}
              open={selectedTableKey === record.reportId && deletePopConfirm}
              onPopupClick={e => {
                e.stopPropagation();
              }}
              okButtonProps={{ loading: false }}>
              <a onClick={e => {}}>
                <Space>...</Space>
              </a>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];
  // 删除
  const handDeleteRecord = async (record: any) => {
    setDeletePopConfirm(false);
    const res = await deletedReportForm({
      reportId: record.reportId,
    });
    if (res) {
      message.success('删除成功');
      getListData({ pageSize: 10, pageNum: 1 }, searchParam);
    }
  };
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });

  const filterFinish = (data: any) => {
    setSearchParam(data);
    getListData({ pageSize: 10, pageNum: 1 }, data);
  };
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const getListData = async (page: { pageSize: number; pageNum: number }, data: any) => {
    const res = await queryReportFormList({
      ...page,
      ...data,
    });
    if (res) {
      const { total, rows } = res;
      setListData(rows);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: total,
      });
    }
  };

  useEffect(() => {
    operatePageNum = Number(localStorage.getItem('pageNum'));
    operatepageSize = Number(localStorage.getItem('pageSize'));
    preParams = JSON.parse(localStorage.getItem('searchListData') as string);
    localStorage.removeItem('pageNum');
    localStorage.removeItem('pageSize');
    localStorage.removeItem('searchListData');
    if (operatePageNum || operatepageSize || preParams) {
      getListData({ pageNum: operatePageNum, pageSize: operatepageSize }, preParams);
    } else {
      getListData({ pageSize: 10, pageNum: 1 }, searchParam);
    }
  }, []);

  return (
    <div className={styles.groupUserInsight}>
      <div className={styles.titleAndOperate}>
        <div>
          <ModuleTitle mark="large" title="群体画像洞察" />
        </div>
        <div className={styles.buttonBox}>
          <SearchIconInput
            clear={true}
            width="310px"
            placeholder="请输入分析报告名称"
            onSearch={(e: string) => {
              const store = {
                ...searchParam,
                reportName: e,
              };
              setSearchParam(store);
              getListData({ pageSize: 10, pageNum: 1 }, store);
            }}></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
          <span
            style={{
              width: '0',
              height: '16px',
              border: 'var(--border3)',
              marginRight: 16,
            }}></span>
          {checkPermissions(GROUP_KEY.GROUP_ADD) && (
            <Button
              type="primary"
              onClick={() =>
                navigate('/userInsight/GroupUserInsight/GroupUserInsight/form', {
                  state: { type: '1' },
                })
              }>
              新建分析
            </Button>
          )}
        </div>
      </div>
      <FilterView ref={filterViewRef} searchList={searchList} finish={filterFinish}></FilterView>
      <div style={{ marginTop: '24px' }}>
        <Table
          columns={columns}
          dataSource={listData}
          rowKey={record => record.id}
          pagination={{
            total: pageQuery.total,
            current: pageQuery.pageNum,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={e => handleTableChange(e)}
        />
      </div>
    </div>
  );
};

export default GroupUserInsight;
