import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Select, Drawer, Space, message } from "antd";
import type { DrawerProps, SelectProps } from "antd";
import { selectAllRole, addOrEditUserGroup, EditUserGroup } from '../../../../../../api/group/group';
import { GroupListVO } from '../../../../../../api/group/types';
import styles from './index.module.scss';

const { TextArea } = Input;

const CreateGroup = (props: {
  flag: string,
  createOpen: boolean,
  editData: GroupListVO,
  getOpenStatus: Function,
  submitSuccess: Function
}) => {
  const { 
    flag, 
    createOpen,
    editData,
    getOpenStatus = () => {},
    submitSuccess = () => {},
  } = props;

  const formRef: any = useRef(null);

  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

  // 关闭抽屉
  const onClose = () => {
    getOpenStatus(false);
  };

  type FieldType = {
    name: string;
    instruction: string;
    roleIds: number[]
  };

  const formData: FieldType = {
    name: '',
    instruction: '',
    roleIds: []
  };

  const [roleIds, setRoleIds] = useState([] as number[]);

  const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const [conditions, setConditions] = useState(formData);
  const changeConditions = (newConditions:any) => {
    setConditions({
      ...conditions,
      ...newConditions,
    });
  };

  // 获取功能角色
  const [optionsValue, setOptionsValue] = useState([]);

  const getSelectAllRole = async () => {
    const {data} = await selectAllRole();
    const store: any = [];
    const handleRoleIs = editData.roleIds?.split(',');
    const storeRoleIs:number[] = [];
    data.forEach(v => {
      const obj: any = {
        value: v.roleId,
        label: v.roleName
      };
      store.push(obj);
      if(handleRoleIs && handleRoleIs.includes(v.roleName)){
        storeRoleIs.push(v.roleId);
      }
    });
    setRoleIds(storeRoleIs);
    formRef.current?.setFieldsValue({
      'roleIds': storeRoleIs,
    });
    setOptionsValue(store);
  };

  // 提交
  const submit = async () => {
    const param: any = {
      name: conditions.name,
      instruction: conditions.instruction,
      roleIds
    }
    let res: any;
    if(flag === 'edit'){
      param.id = editData.id
      res = await EditUserGroup(param)
      
    }else{
      res = await addOrEditUserGroup(param)
    }
    if (res.code === 200) {
        message.success(flag === 'edit' ? '编辑成功' : '创建成功');
        onClose()
        submitSuccess(false);
    }
  };

  useEffect(() => {
    getSelectAllRole();
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        'name': flag === 'edit' ? editData.name : '',
        'instruction': flag === 'edit' ? editData.instruction : '',
      })
    }, 100);
    setConditions({
      ...conditions,
      name: flag === 'edit' ? editData.name : '',
      instruction: flag === 'edit' ? editData.instruction : '',
    });
    if(!createOpen){
      formRef.current?.setFieldsValue({
        'roleIds': [],
      });
    }
    
  }, [createOpen])

  return (
    <div>
      <Drawer
        title={`${flag === 'create' ? '新建' : '编辑'}用户组`}
        placement={placement}
        width={400}
        onClose={onClose}
        open={createOpen}
        extra={
          <Space>
            <Button onClick={onClose} style={{marginRight: 8}}>取消</Button>
            <Button type="primary" disabled={!conditions.name || roleIds.length === 0} onClick={submit}>确定</Button>
          </Space>
        }
      >
        <Form layout="vertical" ref={formRef}>
          <Form.Item<FieldType>
            label="用户组名称"
            name={'name'}
            rules={[{ required: true, message: '请输入用户组名称' }]}
          >
            <Input 
              showCount
              maxLength={20}
              placeholder="请输入用户组名称"
              onChange={(e) => {
                if (e.target.value.trim() === '') {
                  changeConditions({ name: ''});
                } else {
                  changeConditions({ name: e.target.value });
                }
              }}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="用户组说明"
            name="instruction"
          >
            <TextArea
              placeholder="请输入用户组说明"
              showCount
              maxLength={100}
              value={conditions.instruction}
              onChange={(e) => changeConditions({instruction: e.target.value})}
              style={{ height: 120, resize: 'none' }}
            />
          </Form.Item>
          <Form.Item<FieldType>
            label="功能角色"
            name="roleIds"
            rules={[{ required: true, message: '请选择功能角色' }]}
          >
            <Select
              placeholder="请选择"
              mode="multiple"
              optionFilterProp="children"
              value={roleIds}
              filterOption={filterOption}
              onChange={(e) => setRoleIds(e)}
              options={optionsValue}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default CreateGroup