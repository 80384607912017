import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  message,
  Tooltip,
  InputNumber,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
import type { SelectProps } from "antd";
import {
  PlusOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import TimeSelect from "../TimeSelect";
import BehaviorChildComponent from "../BehaviorChildComponent";
import RelationshipDivider from "../RelationshipDivider";
import {
  profileBehaviorListApi,
  profileBehaviorSubPageListApi,
  profileOperatorApi
} from "../../../../../../api/label/label";

const Behavior = (props: {
  current?: number,
  userInsight?: boolean,   //集体画像洞察
  operateType: string,
  listData?: any,
  itemData?: any,
  ruleData?: any,
  val: any,
  idx: number,
  isGoOn?: boolean,
  checkMsg?: string,
  getDeleteItemFunction: Function,
  getRulesChangeFunction: Function,
  getRuleDataFunction: Function,
  getCheckSizeFunction: Function
}) => {

  const {
    current,
    userInsight,   //集体画像洞察
    operateType, 
    listData,
    itemData,
    ruleData, 
    val, 
    idx, 
    isGoOn,
    checkMsg,
    getDeleteItemFunction = () => {},
    getRulesChangeFunction = () => {},
    getRuleDataFunction = () => {},
    getCheckSizeFunction = () => {}
  } = props;

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // 行为子规则
  const [behaviorChildFieldOptions, setBehaviorChildFieldOptions] = useState([] as any);
  const [behaviorOptions, setBehaviorOptions] = useState([] as any);
  const [behaviorParentRelationshipOptions, setBehaviorParentRelationshipOptions] = useState([] as any);
  // 用户行为表查询
  const getProfileBehaviorList = async () => {
    const { data } = await profileBehaviorListApi();
    data.forEach((v: any, i: number) => {
      v.label = v.behaviorName;
      v.value = v.id;
    });
    setBehaviorOptions(data);
  };

  // 获取运算符
  const getProfileOperator = async (val: any, f: string) => {
    const param = {
      fieldType: "Behavior",
    };
    const { data } = await profileOperatorApi(param);

    data.forEach((v: any, i: number) => {
      v.label = v.name;
      v.value = v.code;
    });

    setBehaviorParentRelationshipOptions(data);
    if(f === 'o'){
      getRulesChangeFunction({e: {}, id: val.rules_operate_id, key: "behaviorParentRelationshipOptions", f: 'p'});
    }
  };

  // 用户行为事件属性查询
  const getProfileBehaviorSubList = async (e: string) => {
    const param = {
      behaviorId: e,
    };
    const { data } = await profileBehaviorSubPageListApi(param);

    data.forEach((v: any, i: number) => {
      v.label = v.fieldName;
      v.value = v.id;
      if(v.chirdList && v.chirdList.length > 0){
        v.chirdList.forEach((a: any, b: number) => {
          a.label = a.funcName;
          a.value = a.func;
        });
      }
      v.children = v.chirdList;
    });
    setBehaviorChildFieldOptions(data); // 标记
  };
  
  // 获取行为事件选择值
  const getTimeValue = (val: any) => {
    const store = listData.map((v: any, i: number) => v);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value_id === ruleData.rules_value_id) {
            m.rules.forEach((p: any, q: number) => {
              if (p.rules_operate_id === val.id) {
                p.behavior_time_open = val.flag;
                p.time_value = val.timeValue;
                p.time_key = val.timeKey;
                p.time = val.paramValue;
                p.is_custom = val.isCustom;
                p.custom_value = val.customValue;
                p.custom_checked = val.customChecked
              }
            });
          }
        });
      }
    });
    getRuleDataFunction(store);
  };

  // 行为规则添加子项
  const addBehaivorChild = (val: any, idx: number) => {
    if (isGoOn) {
      let store = listData.map((val: any, idx: number) => val);
      let pItem = store.find(
        (v: any, i: number) => v.operate_label_id === itemData.operate_label_id
      );
      let cItem = pItem.rules.rules?.find(
        (v: any, i: number) => v.rules_value_id === ruleData.rules_value_id
      );

      if (cItem.rules[idx].rules) {
        const obj = {
          child_id: String(new Date().valueOf()) + "05",
          field_type: "",
          field_id: null,
          field_code: "",
          table_name: "",
          field: null,
          operator: null,
          operator_id: null,
          min: "",
          max: "",
          value: "",
        };
        cItem.rules[idx].rules.rules.unshift(obj);
      } else {
        const obj = {
          combinator: "and",
          rules: [
            {
              child_id: String(new Date().valueOf()) + "05",
              field_type: "",
              field_id: null,
              field_code: "",
              table_name: "",
              field: null,
              operator: null,
              operator_id: null,
              min: "",
              max: "",
              value: "",
              value_time: "",
            },
          ],
        };
        cItem.rules[idx].rules = obj;
      }
      getRuleDataFunction(store);
    } else {
      message.error(checkMsg);
    }
  };

  // 获取且和或的change值
  const getChangeValue = (val: any) => {
    const store = listData.map((v: any, i: number) => v);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (val.flag === "p") {
            if (m.rules_value_id === val.id) {
              m.combinator = val.v;
            }
          } else {
            if (m.rules_value_id === ruleData.rules_value_id) {
              m.rules.forEach((p: any, q: number) => {
                if (p.rules_operate_id === val.id) {
                  p.rules.combinator = val.v;
                }
              });
            }
          }
        });
      }
    });
    getRuleDataFunction(store);
  };

  // 获取左边距 
  const getMarginLeft = (val: any) => {
    let width: string = '';
    if(ruleData.rules.length > 1){
      if(val.statistics_type === '不限次数'){
        if(val.rules.rules.length > 1){
          width = "calc((100% - 40px - 16px - 10px)/6 + (100% - 40px - 16px - 10px)/6 - 10px + 35px + 8px)";
          // 行为子属性长度大于1;
          return width;
        } else {
          width = "calc((100% - 40px - 16px - 10px)/6 + (100% - 40px - 16px - 10px)/6 - 10px + 7px + 8px)";
          return width;
        }
      } else {
        if(val.rules.rules.length > 1){
          width = "calc((100% - 40px - 16px - 10px)/6 + (100% - 40px - 16px - 10px)/6 - 10px + 35px)";
          // 行为子属性长度大于1;
          return width;
        } else {
          width = "calc((100% - 40px - 16px - 10px)/6 + (100% - 40px - 16px - 10px)/6 - 10px + 7px)";
          return width;
        }
      }
    } else {
      // 单个规则组
      if(val.rules.rules.length > 1){
        width = "calc((100% - 40px - 16px - 10px)/6 + (100% - 40px - 16px - 10px)/6 - 10px + 44px)";
        // 行为子属性长度大于1
        return width;
      } else {
        width = "calc((100% - 40px - 16px - 10px)/6 + (100% - 40px - 16px - 10px)/6 - 10px + 16px)";
        return width;
      }
    }
  };

  useEffect(() => {
    if(current === 1 ||
      operateType === "edit" || 
      operateType === "view" || 
      operateType === "copy" || 
      itemData.data_sources === "copy" || 
      ruleData.data_sources === "copy"){
      getProfileBehaviorList();
      getProfileOperator(val, 's');
      if(val.behavior_field_id){
        getProfileBehaviorSubList(val.field);
      }
    }
  }, []);

  useEffect(() => {
    if(userInsight){
      getProfileBehaviorList();
    }
  }, [userInsight]);

  return(
    <div style={{ width: "calc(100% - 8px - (100% - 48px - 10px - 16px)/7)" }}>
      <div style={{ display: "flex", marginLeft: 8, width: "100%" }}>
        <TimeSelect
          width="calc((100% - 40px - 16px - 10px)/6)"
          operateType={operateType}
          timeValue={val.time_value}
          timeKey={val.time_key}
          timeStore={val.timeStore}
          time={val.time}
          custom={val.is_custom}
          customVal={val.custom_value}
          customCheck={val.custom_checked}
          open={val.behavior_time_open}
          id={val.rules_operate_id}
          getTimeValue={getTimeValue}
        />
        <Select
          disabled={operateType === "view"}
          style={{ width:"calc((100% - 40px - 16px - 10px)/6 - 10px)", marginLeft: 8 }}
          value={val.behavior}
          onChange={(e) =>{
            getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "behavior", f: "p"});
            // rulesChange(e, val.rules_operate_id, "behavior", "p")
          }}
          options={[
            { value: "做过", label: "做过" },
            { value: "未做过", label: "未做过" },
          ]}
        />
        <Select
          disabled={operateType === "view"}
          placeholder="请选择客户行为"
          showSearch
          optionFilterProp="children"
          filterOption={filterOption}
          style={{ width:"calc((100% - 40px - 16px - 10px)/6 + 10px)", marginLeft: 8 }}
          value={val.behavior_field_id}
          onChange={(e) =>{
            getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "behavior_field_id", f: "p", option: behaviorOptions});
            getProfileOperator(val, 'o');
            getProfileBehaviorSubList(e);
          }}
          options={behaviorOptions}
        />
        {val.behavior === "做过" && (
          <Select
            disabled={operateType === "view"}
            style={{ width:"calc((100% - 40px - 16px - 10px)/6 - 10px)", marginLeft: 8 }}
            value={val.statistics_type}
            onChange={(e) =>{
              getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "statistics_type", f: "p"});
            }}
            options={[
              { value: "不限次数", label: "不限次数" },
              { value: "总次数", label: "总次数" },
            ]}
          />
        )}
        {val.behavior === "做过" && val.statistics_type === "总次数" && (
          <Select
            disabled={operateType === "view"}
            placeholder="请选择运算条件"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            style={{width:"calc((100% - 40px - 16px - 10px)/6 + 10px)", marginLeft: 8 }}
            value={val.operator_id}
            onChange={(e) =>{
              getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "operator_id", f: "p", option: behaviorParentRelationshipOptions});
            }}
            options={behaviorParentRelationshipOptions}
          />
        )}
        {val.behavior === "做过" &&
          val.statistics_type === "总次数" &&
          val.operator_id !== 7 && (
            <InputNumber
              disabled={operateType === "view"}
              placeholder="请输入"
              value={val.value}
              min={0}
              max={9999999}
              formatter={(text) =>
                /^\d+$/.test(text) ? text > 0 ? text : 1 : text.split(".")[0]
              }
              style={{ width:"calc((100% - 40px - 16px - 10px)/6)", marginLeft: 8, height: "32px" }}
              onChange={(e) =>{
                getRulesChangeFunction({e: e === 0 ? 1 : e, id: val.rules_operate_id, key: "value", f: "p"});
              }}
            />
          )}
        {val.behavior === "做过" &&
          val.statistics_type === "总次数" &&
          val.operator_id === 7 && (
            <div style={{ display: "flex", marginLeft: 8, width:"calc((100% - 40px - 16px - 10px)/6)" }}>
              <InputNumber
                disabled={operateType === "view"}
                placeholder="请输入"
                value={val.min}
                min={0}
                max={9999999}
                formatter={(text) =>
                  /^\d+$/.test(text) ? text > 0 ? text : 1 : text.split(".")[0]
                }
                style={{ height: "32px" }}
                onChange={(e) =>{
                  getRulesChangeFunction({e: e === 0 ? 1 : e, id: val.rules_operate_id, key: "min", f: "p"});
                }}
                onBlur={(e: any) => getCheckSizeFunction({value: e.target.value, data: val, key: "min", msg: "行为次数区间请按照从小到大的顺序填写", f: "p"})}
                onPressEnter={(e: any) => getCheckSizeFunction({value: e.target.value, data: val, key: "min", msg: "行为次数区间请按照从小到大的顺序填写", f: "p"})}
              />
              <span
                style={{
                  display: "inline-block",
                  height: "32px",
                  lineHeight: 'var(--text-font15)',
                  margin: "0 8px",
                }}
              >
                到
              </span>
              <InputNumber
                disabled={operateType === "view"}
                placeholder="请输入"
                value={val.max}
                min={0}
                max={9999999}
                formatter={(text) =>
                  /^\d+$/.test(text) ? text > 0 ? text : 1 : text.split(".")[0]
                }
                style={{ height: "32px" }}
                onChange={(e) =>{
                  getRulesChangeFunction({e: e === 0 ? 1 : e, id: val.rules_operate_id, key: "max", f: "p"});
                }}
                onBlur={(e: any) => getCheckSizeFunction({value: e.target.value, data: val, key: "max", msg: "行为次数区间请按照从小到大的顺序填写", f: "p"})}
                onPressEnter={(e: any) => getCheckSizeFunction({value: e.target.value, data: val, key: "max", msg: "行为次数区间请按照从小到大的顺序填写", f: "p"})}
              />
            </div>
          )}
          {
            (operateType !== "view" && val.behavior === '做过') && (
              <div
                style={{
                  marginLeft: 10,
                  height: "32px",
                  lineHeight: 'var(--text-font15)',
                }}
              >
                <Tooltip title="添加">
                  <PlusOutlined
                    style={{
                      cursor: "pointer",
                      color: "var(--text-font-color4)",
                      fontSize: "var(--text-font7)",
                    }}
                    onClick={() => addBehaivorChild(val, idx)}
                  />
                </Tooltip>
              </div>
            )
          }
          {(ruleData.rules?.length > 1 && operateType !== "view" || userInsight) && (
            <div
              style={{
                marginLeft: 10,
                height: "32px",
                lineHeight: 'var(--text-font15)',
              }}
            >
            <Tooltip title="删除">
              <DeleteOutlined
                style={{
                  cursor: "pointer",
                  color: "var(--text-font-color4)",
                  fontSize: "var(--text-font7)",
                }}
                onClick={() => getDeleteItemFunction(idx)}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {val.rules && (
        <div style={{width: '100%', marginTop: 8, marginLeft: 8, paddingLeft: getMarginLeft(val)}}>
          <div style={{ display: "flex", width: '100%',}}>
            {val.rules.rules.length > 1 && (
              <RelationshipDivider
                isDisables={true}
                operateType={operateType}
                size="14px"
                level="0"
                textFlag={val.rules.combinator}
                dataParams={{ id: val.rules_operate_id, flag: "c" }}
                getChangeValue={getChangeValue}
              />
            )}
            <div style={{width: '100%', marginLeft: val.rules.rules.length > 1 ? 14 : 0}}>
              {val.rules.rules.map((m: any, n: number) => {
                return (
                  <BehaviorChildComponent 
                    key={m.child_id}
                    current={current}
                    operateType={operateType}
                    listData={listData}
                    itemData={itemData}
                    ruleData={ruleData}
                    m={m}
                    idx={idx}
                    isGoOn={isGoOn}
                    checkMsg={checkMsg}
                    behaviorChildFieldOptions={behaviorChildFieldOptions}
                    childRulesLength={val.rules.rules.length}
                    getRuleDataFunction={(store: any) => getRuleDataFunction(store)}
                    getCheckSizeFunction={(val: any) => getCheckSizeFunction({value: val.value, data: val.data, key: val.key, msg: val.msg, f: val.f})}
                    getRulesChangeFunction={(val: any) => getRulesChangeFunction({e: val.e, id: val.id, key: val.key, f: val.f, option: val.option})}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  )};

export default Behavior;