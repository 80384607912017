import { AxiosPromise } from 'axios';
import request from '../network'

//用户模型-列表查询
export function getUserCenterListApi(): AxiosPromise<any> {
  return request({
    url: 'label/profile/list',
    method: 'get',
  });
}

//用户模型-用户属性分页列表查询
export function getUserPropertyListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/profile/property/page/list',
    method: 'post',
    data: params
  });
}

// 用户模型-用户行为表分页列表查询
export function getUserBehaviorListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/profile/behavior/page/list',
    method: 'post',
    data: params
  });
}

// 用户模型-获取行为事件属性信息
export function getUserBehaviorSubListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/profile/behavior/sub/page/list',
    method: 'post',
    data: params
  });
}

// 用户模型-获取行为事件属性信息
export function getUserEnumsListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/profile/enums',
    method: 'get',
    params: params
  });
}


// 用户模型-获取数据类型
export function getUserFieldTypeApi(): AxiosPromise<any> {
  return request({
    url: 'label/profile/fieldType',
    method: 'get',
  });
}

// 业务模型-列表
export function getBusinessListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/business/page/list',
    method: 'post',
    data: params
  });
}

// 业务模型-属性查询
export function getBusinessPropertyListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/business/property/page/list',
    method: 'post',
    data: params
  });
}

// 业务模型-枚举查询
export function getBusinessDetailInfoApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/business/detailInfo',
    method: 'post',
    data: params
  });
}

// oneID融合-列表
export function getOneIdMergeRecordApi(params: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getOneIdMergeRecord',
    method: 'post',
    data: params
  });
}

// oneID融合-详情
export function getOneIdMergeRecordDetailApi(params: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getOneIdMergeRecordDetail',
    method: 'get',
    params: params
  });
}

// oneID融合-关系表
export function getOneIdMergeRelationApi(params: any): AxiosPromise<any> {
  return request({
    url: 'lz-cdp-data-collect/customer/getOneIdMergeRelation',
    method: 'get',
    params: params
  });
}