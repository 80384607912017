import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import type { CollapseProps } from 'antd';
import { Form, Row, Tree, Empty, Tooltip, Button } from "antd"
import { Collapse } from 'antd';
import { motion } from 'framer-motion';
import { CaretRightOutlined , FilterOutlined ,FolderOpenOutlined} from '@ant-design/icons';
import { TagOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import { getUserTags } from "../../../../../../api/UserInsight/UserInsight";
import FilterView from "../../../../../../component/FilterView/filterView";
const { TreeNode } = Tree;

let LabelMes = forwardRef((props: {
}, ref: any) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        getLabelMes,
        resetFilter
    }))
    const filterViewRef: any = useRef(null)
    const [form] = Form.useForm();
    const [peopleUserOptions, setPeopleUserOptions] = useState([])
    const [peopleUserList, setPeopleUserList] = useState(
        [] as any
    );
    const [iconColor, setIconColor] = useState('var(--text-font-color4)');
    const handleMouseEnter = () => {
        // console.log('鼠标移入')
        setIconColor('var(--text-font-color9)');
    };
    const handleMouseLeave = () => {
        // console.log('鼠标移出')
        setIconColor('var(--text-font-color4)');
    };
    const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId'):'';
    // 获取所在人群 
    const getLabelMes = async (
        data?: any,
    ) => {
        const param = {
            ...data,
            oneId: oneId
        } as any;
        let res  = await getUserTags(param);
        var arr = res.data
        if (res) {
            if (arr.length > 0) {
                arr.map((item:any)=>{
                    item.title = item.name
                    item.key = item.id
                    item.children = item.child
                    if (item.children && item.children.length > 0) {
                        item.children.map((ite:any)=>{
                            ite.title = ite.name
                            ite.key = ite.id
                            ite.children = ite.child
                            if (ite.children && ite.children.length > 0) {
                                ite.children.map((it:any,index:any)=>{
                                    it.title = it.name
                                    it.key = index
                                    it.isRow = false
                                })
                            }
                        })
                    }
                })
            }
        }
        
        setPeopleUserList(arr)
        addIsRow(arr)

    };
    // 4.找出需要横向展示的树节点
	const addIsRow = (arr: any[]) => {
		arr.map((item: any) => {
			if (item.children && item.children.length > 0) {
				addIsRow(item.children);
			}
			const filterArr = arr.filter((val: any) => {
				if (!val.children || val.children.length === 0) {
					return val;
				}
			});
			if (filterArr.length === arr.length) item.isRow = true;
		});
        setPeopleUserList(arr)
	};
    // 2.TreeNode渲染函数
    const showTreeNode = (treeData: any[]) => {
        return (
            
        treeData && treeData.map((item: any,index:any) => {
            if (item.children && item.children.length > 0) {
                return (
                    <TreeNode 
                        className={style['deepestNode']}
                        title={item.title+'（'+ item.children.length +'）'} 
                        checkable={true} 
                        icon={<FolderOpenOutlined />}>
                        {showTreeNode(item.children)}
                    </TreeNode>
                );
            } else {
                return (
                    <TreeNode
                        icon={item.isRow ?<TagOutlined style={{color:'var(--text-font-color7)',marginRight:'8px'}}/>:<FolderOpenOutlined />}
                        title={item.isRow ? 
                            <>
                                <Tooltip title={item.title}><span>{item.title}</span></Tooltip>
                            </> : item.title+'（0）'}
                        // title={item.isRow?item.title:item.title+'（0）'}
                        checkable={true}
                        style={{marginLeft: index === 0?'60px':''}}
                        className={item.isRow ? style['deepestNodeChild'] : style['deepestNode']}
                    >
                        {showTreeNode(item.children)}
                    </TreeNode>
                    
                );
            }
        })
        );
    };
    //重置筛选框
    const resetFilter = () => {
        filterViewRef.current?.resetData();
    };
    //搜索条件
    const searchList: searchItem[] = [
        {
            searchType: "text",
            searchComponentType: "input",
            placeHolder: "请输入标签名称",
            title: "标签查询",
            key: "labelName",
        },
    ];
    // 查询
    const filterFinish = (data: any) => {
        getLabelMes( data );
    };
    //监听或进入调用
    useEffect(() => {
        getLabelMes()
        filterViewRef.current?.showFilterView();
    },[])

    return <>
        <div className={style['LabelMes']}>
            <div className={style['LabelMes_serch-box']}>
                <Tooltip placement="bottom" title={'过滤器'}>
                    <Button
                    onClick={()=>filterViewRef.current?.showFilterView()}
                    icon={
                    <FilterOutlined
                        style={{
                        color: iconColor,
                        fontSize: 'var(--text-font7)',
                        }}
                    />
                    }
                    type="text"
                    className="click-element"
                    style={{marginRight:'10px'}}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}></Button>
                </Tooltip>
            </div>
            <FilterView
                ref={filterViewRef}
                averageTwo={true}
                searchList={searchList}
                finish={filterFinish}
            ></FilterView>
            <div style={{marginTop: '16px'}}>
                <span className={style['LabelMes_title']}>全部标签</span>
                <Tree
                    showIcon
                    defaultExpandAll
                    defaultSelectedKeys={['0-0-0']}
                    // treeData={peopleUserList}
                > {showTreeNode(peopleUserList)}
                </Tree>
                { !peopleUserList || peopleUserList.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{marginTop:'150px'}}/>}
            </div>
        </div>
    </>
})
export default LabelMes
