import { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { Table, Button, Tag } from 'antd';
import type { TableProps } from 'antd';
import { TitleWithExpansion, useGetMemberCardList } from '../../../../component/MiniStyles';
import FilterView from '../../../../component/FilterView';
import CreadDistributePoints from '../../../../component/creadDistributePoints';
import CheckPointsResult from '../../../../component/checkPointsResult';
import { getPointsListApi } from '../../../../api/distReward';
import { LOYALTY_KEY } from '../../../../../router/Permissions';
import checkPermission from '../../../../assets/public/checkPermission';
type FieldType = {
  memberCardId: string;
  planName?: string;
  createTime?: string;
};
const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

function DistributePoints() {
  const { memberCardOptions, memberCardAllOptions } = useGetMemberCardList();
  const defaultFormValue = {
    memberCardId: memberCardAllOptions[0]?.value || '',
    planName: '',
    createTime: '',
  };
  const [tableList, setTableList] = useState<any[]>([]);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const filterViewRef = useRef<any>(null);
  const [formMemberCardId, setFormMemberCardId] = useState('');
  const [searchList, setSearchList] = useState<any[]>([
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '所属会员卡',
      key: 'memberCardId',
      options: [],
    },
    {
      searchType: 'string',
      searchComponentType: 'input',
      placeHolder: '请输入',
      title: '计划名称',
      key: 'planName',
    },
    {
      searchType: 'text',
      searchComponentType: 'datePicker',
      placeHolder: '',
      title: '创建时间',
      key: 'createTime',
    },
  ]);
  // 获取列表
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getPointsListApi({
      ...searchInfo,
      pageNum,
      pageSize,
    });
    if (res?.code === 200) {
      setTableList(res.data?.records || []);
      setTableInfo({ ...tableInfo, total: res.data?.total || 0 });
    }
  };
  // 查询
  const filterFinish = (data: any) => {
    setSearchInfo({ ...data });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };
  const onFormChange = (e: any) => {
    if (e[0].name[0] === 'memberCardId') {
      setFormMemberCardId(e[0].value);
      const obj = {
        ...filterViewRef.current.getFormInfo(),
      };
      filterViewRef.current.handChangeForm(obj);
    }
  };

  useEffect(() => {
    if (memberCardAllOptions.length > 0) {
      const obj = {
        ...searchInfo,
        memberCardId: memberCardAllOptions[0]?.value || '',
      };
      const arr = JSON.parse(JSON.stringify(searchList));
      arr[0].options = memberCardAllOptions;
      setSearchInfo(obj);
      setSearchList(arr);
      filterViewRef.current.handChangeForm(obj);
    }
  }, [JSON.stringify(memberCardAllOptions)]);

  // 获得表格数据
  useEffect(() => {
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInfo.pageNum, tableInfo.pageSize, JSON.stringify(searchInfo)]);
  interface DataType {
    planName: string;
    createTime: string;
    memberCardName: string;
    planStatus: string;
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '计划名称',
      dataIndex: 'planName',
      key: 'planName',
      width: '300px',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: '300px',
    },
    {
      title: '所属会员卡',
      dataIndex: 'memberCardName',
      key: 'memberCardName',
      width: '300px',
    },
    {
      title: '状态',
      dataIndex: 'planStatus',
      key: 'planStatus',
      width: '200px',
      render: (_, record) => (
        <>
          {record.planStatus === '成功' && (
            <Tag style={{ width: 52, textAlign: 'center' }} color="success">
              成功
            </Tag>
          )}
          {record.planStatus === '失败' && (
            <Tag style={{ width: 52, textAlign: 'center' }} color="error">
              失败
            </Tag>
          )}
          {record.planStatus === '进行中' && (
            <Tag style={{ width: 52, textAlign: 'center' }} color="processing">
              进行中
            </Tag>
          )}
        </>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) => (
        // 暂定0是禁用, 1是启用
        <div>
          <Button
            type="link"
            style={{ padding: 0 }}
            disabled={!checkPermission(LOYALTY_KEY.PLAN_DETAILPAGE)}
            onClick={() => {
              handleResult(record);
            }}>
            查看
          </Button>
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination: any, _: any) => {
    setTableInfo({
      ...tableInfo,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    });
  };
  // 会员卡列表

  // 新建发放计划
  const [open, setOpen] = useState(false);
  const drawerSwitch = () => {
    if (open) {
      getTableList();
    }
    setOpen(!open);
  };
  // 查看发放结果
  const [openResult, setOpenResult] = useState(false);

  const resultRef = useRef<any>(null);
  const handleResult = async (record?: any) => {
    if (!openResult) {
      setOpenResult(!openResult);
      resultRef.current.getDetailPage(record);
    } else {
      setOpenResult(!openResult);
    }
  };

  // 表格部分
  const TableSection = () => {
    return (
      <div style={{ marginTop: '24px' }}>
        <Table
          columns={columns}
          dataSource={tableList}
          pagination={{
            total: tableInfo.total,
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: total => `共 ${total} 条`,
            // defaultCurrent: 1
          }}
          onChange={handleTableChange}
        />
      </div>
    );
  };

  return (
    <div className={style['DistributePoints-bgd']}>
      {/* 标题 */}
      <TitleWithExpansion
        title="批量发放积分"
        onChange={() => filterViewRef.current.showFilterView()}
        operaTionTitle="新建发放计划"
        onBtnChange={drawerSwitch}
        operaTionPermissions={LOYALTY_KEY.PLAN_SAVE}>
        {/* 搜索部分 */}
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          defaultFormValue={defaultFormValue}
          finish={filterFinish}
          onFormChange={onFormChange}></FilterView>
      </TitleWithExpansion>
      {/* 抽屉 */}
      <CreadDistributePoints open={open} memberList={memberCardOptions} drawer={drawerSwitch}></CreadDistributePoints>
      <CheckPointsResult ref={resultRef} open={openResult} drawer={handleResult}></CheckPointsResult>

      {/* 表格部分 */}
      <TableSection />
    </div>
  );
}

export default DistributePoints;
