import { AxiosPromise } from 'axios';
import request from '../network'

// 获取下载中心列表
export function queryPageListApi(query: any): AxiosPromise<any> {
  return request({
      url: 'resource/taskCenter/queryPage',
      method: 'GET',
      params: query,
  })
}

// 获取下载中心列表
export function detailtApi(query?: any): AxiosPromise<any> {
  return request({
      url: 'resource/taskCenter/uploadTask/detail',
      method: 'GET',
      params: query,
  })
}

// 获取下载中心列表
export function uploadTaskDownloadApi(query?: any): AxiosPromise<any> {
  return request({
      url: 'resource/taskCenter/uploadTaskDownload',
      method: 'GET',
      params: query,
  })
}