import {useEffect, useRef} from "react";
import * as echarts from "echarts";
import {getNumberWithComma, handleValuePercent} from "@/utils/utils";

const legendSvg = {
  // 正方形 10*10
  rect: 'path://M-0.000,-0.000 L10.000,-0.000 L10.000,10.000 L-0.000,10.000 L-0.000,-0.000 Z',
  // 线条 10*1
  line: 'path://M-0.000,-0.000 L10.000,-0.000 L10.000,1.000 L-0.000,1.000 L-0.000,-0.000 Z'
};

function RfmLineBarChart({data = []}: { data: any }) {
  const chartRef = useRef(null);
  let chartInstance: echarts.ECharts | null = null;
  const options = {
    tooltip: {
      trigger: 'axis',
      // axisPointer: {
      //   type: 'cross',
      //   crossStyle: {
      //     color: '#999'
      //   }
      // }
    },
    grid: {
      left: 50
    },
    legend: {
      left: 0,
      data: [{
        name: '客户数',
        icon: legendSvg.rect
      },
        {
          name: '客户占比',
          icon: legendSvg.line
        },]
    },
    xAxis: [
      {
        type: 'category',
        data: data.map((item: any) => item?.rfmDate),
        // axisPointer: {
        //   type: 'shadow'
        // }
        axisLine: {
          show: true,
          lineStyle: {
            color: "#000",
            opacity: 0.25
          }
        },
        axisLabel: {//x轴文字的配置
          show: true,
          textStyle: {
            color: "#8C8C8C",
          }
        },
        axisTick: {
          show: false // 不显示坐标轴刻度线
        },
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: '',
        min: 0,
        // minInterval: 1,
        alignTicks: true,
        // max: 250,
        // interval: 50,
        // axisLabel: {
        //   formatter: '{value} ml'
        // }
      },
      {
        type: 'value',
        name: '',
        min: 0,
        max: data.length === 0 ? null : 1,
        alignTicks: true,
        // interval: 5,
        axisLabel: {
          formatter: (value: number) => (value * 100)?.toFixed(1) + '%'
        }
      }
    ],
    series: [
      {
        name: '客户数',
        type: 'bar',
        barWidth: 50,
        itemStyle: {
          normal: {
            color: '#68ABFC'
          }
        },
        tooltip: {
          valueFormatter: function (value: number) {
            return getNumberWithComma(value);
          }
        },
        data: data.map((item: any) => item?.numberOfCustomers),
      },
      {
        name: '客户占比',
        type: 'line',
        itemStyle: {
          normal: {
            color: '#5AD8A6', //改变折线点的颜色
            lineStyle: {
              color: '#5AD8A6' //改变折线颜色
            }
          }
        },
        symbol: 'circle',
        symbolSize: 6,
        yAxisIndex: 1,
        smooth: true,
        tooltip: {
          valueFormatter: function (value: number) {
            return handleValuePercent(value) + '%';
          }
        },
        data: data.map((item: any) => item?.percentageOfCustomers),
      }
    ]
  };

  // 定义渲染函数
  function renderChart() {
    try {
      // `echarts.getInstanceByDom` 可以从已经渲染成功的图表中获取实例，其目的就是在 options 发生改变的时候，不需要
      // 重新创建图表，而是复用该图表实例，提升性能
      // @ts-ignore
      const renderedInstance = echarts.getInstanceByDom(chartRef.current);
      if (renderedInstance) {
        chartInstance = renderedInstance;
      } else {
        chartInstance = echarts.init(chartRef.current);
      }
      chartInstance.setOption(options);
    } catch (error) {
      chartInstance && chartInstance.dispose();
    }
  }

  // 定义窗口大小发生改变执行的回调函数
  function resizeHandler() {
    chartInstance?.resize();
  }

  // 页面初始化时，开始渲染图表
  useEffect(() => {
    renderChart();

    return () => {
      // 销毁图表实例，释放内存
      chartInstance && chartInstance.dispose();
    };
  }, []);
  useEffect(() => {
    renderChart();
  }, [data]);
  // 监听窗口大小改变
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <div style={{height: "400px"}} ref={chartRef}/>
  );
}

export default RfmLineBarChart;
