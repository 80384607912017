import { useState, forwardRef, useImperativeHandle } from 'react';
import { InputNumber, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const ChangeF = forwardRef((_, ref) => {
  const [value, setValue] = useState<((number | undefined) | { isFinal: boolean; value: number | undefined })[]>([
    undefined,
    undefined,
    {
      isFinal: true,
      value: undefined,
    }
  ]);

  useImperativeHandle(ref, () => ({
    value,
  }));
  return (
    <div>
      {value.map((i, index) => {
        return <div key={index}>
          <p style={{ display: 'inline-block', width: 12 }}>{index + 1}:</p>
          <span style={{ margin: '0 8px' }}>F</span>
          <span style={{ margin: '0 8px' }}>{typeof i === 'object' ? '≥' : '='}</span>
          <InputNumber
            placeholder='请输入频数'
            style={{ width: 280 }}
            min={1}
            max={20}
            precision={0}
            controls={false}
            value={typeof i === 'object' ? i?.value : i}
            onChange={(v) => {
              const newValue = [...value];
              if (typeof i === 'object') {
                newValue[index] = { isFinal: true, value: v ?? undefined };
              } else {
                newValue[index] = v ?? undefined;
              }
              console.log(newValue);
              
              setValue(newValue);
            }}
          />
          {
            typeof i !== 'object' && <>
              <PlusOutlined
                style={{ margin: '0 8px' }}
                onClick={() => {
                  if (value.length >= 6) {
                    message.error('自定义频次最多6个');
                    return;
                  }
                  const newValue = [...value];
                  newValue.splice(index + 1, 0, undefined);
                  setValue(newValue);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  if (value.length <= 3) {
                    message.error('自定义频次最少3个');
                    return;
                  }
                  setValue(value.filter((_, j) => j !== index));
                }} />
            </>
          }
        </div>;
      })}
    </div>
  );
});

export default ChangeF;