import { AxiosPromise } from 'axios';
import request from '../../../api/network'
import {
    GrowthListQuery, GrowthListVO, PointsListQuery, PointsListVO, PointsStatQuery,
    PointsStatVO, GradeListQuery, GradeListVO, MemberChangeListQuery, MemberChangeListVO
} from './types';

//获取成长值变更列表
export function getGrowthValueListApi(query: GrowthListQuery): AxiosPromise<GrowthListVO> {
    return request({
        url: '/crm/growthValueChangeRecord/page',
        method: 'post',
        data: query,
    });
}

//获取积分变更列表
export function getPointsListApi(query: PointsListQuery): AxiosPromise<PointsListVO> {
    return request({
        url: '/crm/pointChangeRecord/page',
        method: 'post',
        data: query,
    });
}

//获取积分详情值
export function getPointStatApi(query: PointsStatQuery): AxiosPromise<PointsStatVO> {
    return request({
        url: '/crm/pointChangeRecord/pointStat',
        method: 'get',
        params: query,
    });
}

//获取等级变更列表
export function getGradeValueListApi(query: GradeListQuery): AxiosPromise<GradeListVO> {
    return request({
        url: '/crm/member/grade/page',
        method: 'post',
        data: query,
    });
}

//获取入退会变更列表
export function getMemberChangeValueListApi(query: MemberChangeListQuery): AxiosPromise<MemberChangeListVO> {
    return request({
        url: '/crm/member/change/page',
        method: 'post',
        data: query,
    });
}