import React, { useState, useEffect } from "react";
import { Drawer, Button,Space, Form, Input, Upload, message, Popconfirm } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import {
  
} from "../../../../../../api/UserInsight/UserInsight";
import {
  AccountListQuery,
  AccountListQueryVO,
} from "../../../../../../api/UserInsight/types";
import styles from "./index.module.scss";
import type { UploadProps } from 'antd';
import { InboxOutlined , DownloadOutlined } from '@ant-design/icons';
import { relative } from "path";
const { Dragger } = Upload;
const AddStaff = (props: {
  openAddStaff: boolean;
  closeOpenAddStaff: Function;
  popType: any;
}) => {
  const { openAddStaff, closeOpenAddStaff = () => {} } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [accountForm] = Form.useForm();
  // 关闭当前抽屉
  const onClose = () => {
    closeOpenAddStaff(false);
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const submit = async (isFinalConfirm: boolean) => {
  };

  // 关闭提示弹窗
  const hidden = () => {
  };
  const beforeUpload = (file:any) => {
    console.log("========",file);
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ;
    const isXls = file.name.endsWith('.xls');
    const isSize = file.size <= 25 * 1024 *1024;
    
    if (!isXlsx && !isXls) {
      message.error(`请上传.xlsx .xls 的文件`);
      return
    }
    if (!isSize) {
      message.error(`文件最大支持25mb`);
      
    }
    return (isXlsx || isXls) && isSize || Upload.LIST_IGNORE;
  };
  const upload: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  useEffect(() => {
    setOpen(openAddStaff);
  }, [openAddStaff]);
  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={'添加员工'}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button type="primary" onClick={() => submit(false)}>
              确定
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={accountForm}>
          <Form.Item
              label="批量更新"
              name="crowdName"
              style={{position:'relative'}}
              rules={[{ required: props.popType === 1, message: "请输入OneID" }]}
            >
              <Button className={styles.downLoad_button} type="link" icon={<DownloadOutlined />}>下载模板</Button >
              <Dragger {...upload} style={{height:'168px'}} accept=".xlsx,.xls" beforeUpload={beforeUpload}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  支持扩展名：.xlsx .xls
                  最大支持25mb
                </p>
              </Dragger>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default AddStaff;
