import { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import { getMemberChangeValueListApi } from "../../../../api/recordManagement";
import { Table } from "antd";
import {
  StickText,
  TextColorTypes,
  TitleWithExpansion,
  useGetMemberCardList,
  useGetChannelList,
} from "../../../../component/MiniStyles";
import FilterView from "../../../../component/FilterView";
import { permissionMap } from "../../../../assets/public/pageMap";

import { MemberChangeItem } from "../../../../api/recordManagement/types";
import checkPermission from "@/crm/assets/public/checkPermission";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

type FieldType = {
  memberCardId?: string;
  channelType?: string;
  changeType?: string;
  startTime?: string;
  endTime?: string;
};

const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

function MemberChangeRecord() {
  const { memberCardAllOptions } = useGetMemberCardList();
  const navigate = useNavigate();
  const defaultFormValue = {
    memberCardId: memberCardAllOptions[0]?.value || "",
    channelType: "",
    changeType: "",
    startTime: "",
    endTime: "",
    oneId: "",
  };
  const [tableList, setTableList] = useState<MemberChangeItem[]>([]);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const filterViewRef = useRef<any>(null);
  const { getChannelList } = useGetChannelList();
  const [channelNameMap, setChannelNameMap] = useState(new Map());

  const [searchList, setSearchList] = useState<any[]>([
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "会员卡",
      key: "memberCardId",
      options: memberCardAllOptions,
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "会员渠道",
      key: "channelType",
      options: [],
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "类型",
      key: "changeType",
      options: [
        {
          value: "",
          label: "全部类型",
        },
        {
          value: "BINDING",
          label: "入会",
        },
        {
          value: "UNBIND",
          label: "退会",
        },
      ],
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "变更时间",
      key: "startTime-endTime",
    },
    {
      searchType: "string",
      searchComponentType: "input",
      placeHolder: "请输入",
      key: "oneId",
      title: "OneID",
    },
  ]);

  // 获得渠道下拉列表
  const getChannelOptions = async () => {
    const { options, nameMap } = await getChannelList();
    const arr = JSON.parse(JSON.stringify(searchList));
    arr[0].options = memberCardAllOptions;
    arr[1].options = [{ value: "", label: "全部渠道" }, ...options];
    setSearchList(arr);
    setChannelNameMap(nameMap);
  };

  // 获得table列表
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getMemberChangeValueListApi({
      ...searchInfo,
      pageNum,
      pageSize,
    });
    if (res?.code === 200) {
      setTableList(res.data?.records || []);
      setTableInfo({ ...tableInfo, total: res.data?.total || 0 });
    }
  };

  // 查询
  const filterFinish = (data: any) => {
    const { startTime = "", endTime = "" } = data;
    setSearchFlag((e) => !e);
    setSearchInfo({ ...data, startTime, endTime });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };

  // 点击oneid跳转 客户详情页面
  const gotoDetail = (id: string) => {
    if (!id) return;
    navigate("/userInsight/userInsightDocument/UserDetail");
    localStorage.setItem("oneId", id);
    localStorage.setItem("loyaltySearchInfo", JSON.stringify(searchInfo));
    localStorage.setItem("loyaltyTableInfo", JSON.stringify(tableInfo));
    localStorage.setItem("loyaltySearchStatus", filterViewRef.current.arrow);
  };

  // 回显搜索部分
  const handInitSearch = () => {
    if (localStorage.getItem("oneId")) {
      if (localStorage.getItem("loyaltySearchInfo")) {
        const obj =
          JSON.parse(localStorage.getItem("loyaltySearchInfo") || "") ||
          defaultFormValue;
        filterViewRef.current.handChangeForm({
          ...obj,
          "startTime-endTime": obj.startTime
            ? [dayjs(obj.startTime), dayjs(obj.endTime)]
            : "",
        });
        localStorage.getItem("loyaltySearchStatus") === "true" &&
          filterViewRef.current.showFilterView();
        setSearchInfo(obj);
      }
      if (localStorage.getItem("loyaltyTableInfo")) {
        const obj =
          JSON.parse(localStorage.getItem("loyaltyTableInfo") || "") ||
          defaultTableInfo;
        // setTableInfo({ ...tableInfo, pageNum: obj.pageNum });
        tableInfo.pageNum = obj.pageNum;
      }
      localStorage.removeItem("oneId");
      localStorage.removeItem("loyaltySearchInfo");
      localStorage.removeItem("loyaltyTableInfo");
      localStorage.removeItem("loyaltySearchStatus");
    }
  };

  useEffect(() => {
    getChannelOptions();
    if (memberCardAllOptions.length > 0) {
      const obj = {
        ...searchInfo,
        memberCardId: memberCardAllOptions[0]?.value || "",
      };
      const arr = JSON.parse(JSON.stringify(searchList));
      arr[0].options = memberCardAllOptions;
      setSearchList(arr);
      if (localStorage.getItem("oneId")) {
        handInitSearch();
        return;
      }
      tableInfo.pageNum = 1;
      setSearchInfo(obj);
      filterViewRef.current.handChangeForm(obj);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(memberCardAllOptions)]);

  useEffect(() => {
    console.log("--------------", tableInfo.pageNum);
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableInfo.pageNum,
    tableInfo.pageSize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(searchInfo),
    searchFlag,
  ]);

  // 表格部分
  const TableSection = () => {
    return (
      <div>
        <Table
          scroll={{ x: 1000 }}
          dataSource={tableList}
          rowClassName={style["table-row"]}
          pagination={{
            current: tableInfo.pageNum,
            pageSize: tableInfo.pageSize,
            total: tableInfo.total,
            onChange: (pageNo, pageSize) => {
              setTableInfo({
                ...tableInfo,
                pageNum: pageNo,
                pageSize,
              });
            },
            showSizeChanger: true,
            showTotal: (total) => `共 ${total} 条`,
          }}>
          <Table.Column
            width={220}
            fixed="left"
            title="OneID"
            dataIndex="oneId"
            render={(value) => (
              <StickText
                text={value}
                onClick={() => gotoDetail(value)}
                show={checkPermission(permissionMap.get("入退会记录复制"))}
              />
            )}
          />
          <Table.Column
            width={200}
            title="会员渠道"
            dataIndex="channelType"
            render={(value) => channelNameMap.get(value) || "--"}
          />
          <Table.Column
            width={200}
            title="类型"
            dataIndex="changeTypeLabel"
            render={(value, record: MemberChangeItem) => (
              <TextColorTypes
                text={value}
                status={record.changeType === "BINDING"}
              />
            )}
          />
          <Table.Column width={200} title="变更时间" dataIndex="changeTime" />
        </Table>
      </div>
    );
  };

  return (
    <div className={style["MemberChangeRecord-bgd"]}>
      {/* 标题 */}
      <TitleWithExpansion
        title="入退会记录"
        searchPermissions={permissionMap.get("入退会记录查看")}
        onChange={() => filterViewRef.current.showFilterView()}>
        {/* 搜索部分 */}
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          defaultFormValue={defaultFormValue}
          finish={filterFinish}></FilterView>
      </TitleWithExpansion>

      {/* 表格部分 */}
      <TableSection />
    </div>
  );
}

export default MemberChangeRecord;
