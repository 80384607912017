import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { LeftOutlined, FilterOutlined } from '@ant-design/icons';
import type { TableProps } from 'antd';
import { Table, Button } from 'antd';
import FilterView from '../../../../../component/FilterView/filterView';
import SearchIconInput from '../../../../../component/Input/SearchIconInput/searchIconInput';
import CheckBehavior from '../checkBehavior';
import FilterIcon from '../../../../../component/FilterIcon/filterIcon';

import { getUserBehaviorListApi, getUserFieldTypeApi } from '../../../../../api/userCenter/userCenter';
import { getTableScroll } from '@/store/index'

type FieldType = {
  behaviorName?: string;
  createStart?: string;
  createEnd?: string;
  updateStart?: string;
  updateEnd?: string;
  createOrder?: string;
  updateOrder?: string;
};

let createSort = '';
let updateSort = '';

function UserBehavior({ handBackToList }: { handBackToList: () => void }) {
  const [tableList, setTableList] = useState<any[]>([]);
  const [scrollY, setScrollY] = useState("")
  let searchListData: any = {};
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '创建时间',
      key: 'createStart-createEnd',
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '更新时间',
      key: 'updateStart-updateEnd',
    },
  ];
  // 表格字段
  interface DataType {
    id: number;
    behaviorName: string;
    behaviorDescribe: string;
    createBy: string;
    createTime: string;
    updateTime: string;
    action: string;
  }
  const columns: TableProps<DataType>['columns'] = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, i) => <div> {(tableInfo.pageNum - 1) * tableInfo.pageSize + (i + 1)}</div>,
    },
    {
      title: '客户行为',
      dataIndex: 'behaviorName',
      key: 'behaviorName',
      render: (_: any, record: any) => (
        <div
          className={style['behaviorName']}
          onClick={() => {
            handleResult(record);
            setRecordList(record);
          }}>
          {record.behaviorName}
        </div>
      ),
    },
    {
      title: '描述',
      dataIndex: 'behaviorDescribe',
      key: 'behaviorDescribe',
      render: (_, record) => (record.behaviorDescribe ? <div>{record.behaviorDescribe}</div> : <div>-</div>),
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: true,
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      sorter: true,
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) => (
        // 暂定0是禁用, 1是启用
        <div>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              handleResult(record);
              setRecordList(record);
            }}>
            查看
          </Button>
        </div>
      ),
    },
  ];
  const filterViewRef = useRef<any>(null);
  const [tableInfo, setTableInfo] = useState({ pageNum: 1, pageSize: 10, total: 0 });
  // 表格的onchange方法
  const handleTableChange = (page: any, pageSize: any, sort: any) => {
    setTableInfo({
      ...tableInfo,
      pageNum: page.current,
      pageSize: page.pageSize,
    });
    if (sort.order) {
      if (sort.columnKey === 'createTime') {
        createSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        updateSort = '';
      } else {
        updateSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        createSort = '';
      }
    } else {
      createSort = '';
      updateSort = '';
    }
    getTableList({ pageNum: page.current, pageSize: page.pageSize });
  };
  // 沙漏里的查询按钮
  const filterFinish = (data: any) => {
    searchListData = data;
    getTableList({ pageNum: 1, pageSize: 10 });
  };
  // 获取表单数据
  const getTableList = async (page: any) => {
    const res = await getUserBehaviorListApi({
      ...page,
      ...searchListData,
      createOrder: createSort,
      updateOrder: updateSort,
    });
    if (res) {
      setTableList(res.data.rows);
      setTableInfo({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      setScrollY(getTableScroll())
    }
  };
  // 监听更新表格
  useEffect(() => {
    getTableList({ pageNum: 1, pageSize: 10 });
  }, []);
  useEffect(() => {
    getUserFieldTypeList();
  }, []);
  // 抽屉里需要的数据
  const [recordList, setRecordList] = useState<any>({});
  const resultRef = useRef<any>(null);
  // 抽屉开关状态
  const [openResult, setOpenResult] = useState(false);
  // 抽屉开关方法
  const handleResult = async (record: any) => {
    if (!openResult) {
      setOpenResult(!openResult);
      resultRef.current.getResultList(record);
    } else {
      setOpenResult(!openResult);
    }
  };

  const [typeList, setTypeList] = useState<any[]>([
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '数据类型',
      key: 'fieldType',
      options: [],
    },
  ]);
  const getUserFieldTypeList = async () => {
    const res = await getUserFieldTypeApi();
    if (res?.code === 200) {
      console.log(typeList);
      const options = res.data.map((item: any) => ({
        ...item,
        value: item.fieldType,
        label: item.fieldName,
      }));
      typeList[0].options = options;
      setTypeList({ ...typeList });
    }
  };

  // 表格部分

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const getHeight = (e: string) => {
    setScrollY(e)
  }
  // 页面标题和返回按钮
  const HeaderSection = () => {
    return (
      <div className={style['header-bgd']}>
        <div className={style['header-left']}>
          <div
            className={style['header-icon']}
            onClick={() => {
              searchListData = {};
              handBackToList();
            }}>
            <LeftOutlined />
            <span className={style['header_text']}>返回</span>
          </div>
          <div className={style['header-text']}>
            <div>Action 客户行为表</div>
            <div className={style['header-desc']}>描述：维护客户行为</div>
          </div>
          <div className={style['header-text']}></div>
        </div>
        <div className={style['header-right']}>
          <SearchIconInput
            clear={true}
            placeholder="请输入客户行为"
            onSearch={(e: string) => {
              {
                searchListData.behaviorName = e;
                getTableList({ pageNum: 1, pageSize: 10 });
                filterViewRef.current?.resetData();
              }
            }}></SearchIconInput>

          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
    );
  };

  return (
    <div className={style['container']}>
      {/* 页面标题 */}
      <HeaderSection />
      {/* 搜索部分 */}
      <div className='containerBox'>
        <FilterView ref={filterViewRef} getHeight={getHeight} searchList={searchList} finish={filterFinish}></FilterView>
        {/* 表格部分 */}
        <Table
          columns={columns}
          dataSource={tableList}
          scroll={{ x: 1400, y: scrollY }}
          pagination={{
            total: tableInfo.total,
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: total => `共 ${total} 条`,
          }}
          rowKey={record => record.id}
          onChange={(pagination, pageSize, sort) => handleTableChange(pagination, pageSize, sort)}
        />
      </div>
      {/* 抽屉 */}
      <CheckBehavior ref={resultRef} open={openResult} typeList={typeList} drawer={handleResult} record={recordList} />
    </div>
  );
}

export default UserBehavior;
