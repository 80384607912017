import { Input, Drawer, Space, Button, Form, Select, message, Upload, Radio, Row, Col } from 'antd';
import React, { useEffect, ReactNode, useImperativeHandle, useState, forwardRef } from 'react';
import style from './index.module.scss';
import type { UploadProps } from 'antd';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { getSavePointsListApi, getShopListByCardApi } from '../../api/distReward';

const { Dragger } = Upload;

type FieldType = {
  name: string;
  card: string;
  condition: string;
  content: string;
};

let creadDistributePoints = React.forwardRef(
  (
    props: {
      open?: boolean;
      memberList: any;
      drawer?: Function;
    },
    ref: any,
  ) => {
    const [isXlsx,setIsXlsx] =useState(false)

    // 抽屉里的复选框
    const uploadData: UploadProps = {
      name: 'file',
      multiple: true,
      beforeUpload(info: any) {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info);
        }
        if (status === 'done') {
          message.success(`${info.file.name} 上传成功`);
          console.log(info);
        } else if (status === 'error') {
          message.error(`${info.file.name} 上传失败`);
          console.log(info);
        }
      },
      onChange(info) {
        if (info.fileList.length <= 0) {
          creadForm.setFieldValue(`file`, '');
        }else{
          setIsXlsx(info.fileList[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        }
      },
    };
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        creadForm.resetFields();
        props.drawer();
      }
    };
    const memberList = props.memberList;

    const [creadForm] = Form.useForm();
    const fillLoginForm = (e: any, type?: any) => {
      creadForm.setFieldsValue(e.value);
    };
    const [loading, setLoading] = useState(false);

    const onFinish = (values: any) => {
      if (loading) {
        return;
      }
      setLoading(true);

      creadForm
        .validateFields()
        .then(res => {
          // 校验通过，执行相应操作
          console.log('校验通过，表单数据为:', res);
          // 这里可以继续执行其他操作，比如提交表单数据等
          // if(res.file.file.size > 31457280) {
          //   message.error('文件大小不能超过30MB');
          //   setLoading(false);
          //   return
          // }
          let item2 = memberList.find((item: any) => item.value === res.memberCardId);
          let param = { ...res, sendAccord: 'PHONE', memberCardName: item2.label, file: res.file.file };

          if (shopList.length > 0) {
            let shopItem: any = shopList.find((item: any) => item.shopId === res.shopId);
            param = { ...param, shopName: shopItem ? shopItem.shopName : '' };
          }

          getSavePointsList(param);
        })
        .catch(errorInfo => {
          // 校验失败，可以根据errorInfo进行相应处理
          setLoading(false);
          console.log('校验失败:', errorInfo);
        });
    };
    const getSavePointsList = async (data: any) => {
      await getSavePointsListApi(data).then(res => {
        if (res?.code === 200) {
          creadForm.resetFields(); // 走完 接口调用
          message.success('新建成功');
          if (props.drawer) {
            props.drawer();
          }
        }
        setLoading(false);
      });
    };
    const [shopList, setShopList] = useState([]);
    const getShopList = async (id: string) => {
      await getShopListByCardApi(id).then(res => {
        if (res?.code === 200) {
          setShopList(res.data);
        }
      });
    };
    const handleChange = (value: string) => {
      console.log(`selected ${value}`);
      getShopList(value);
    };
    // 下载
    const toDownLoad = () => {
      window.location.href = 'https://speedo-test.oss-cn-zhangjiakou.aliyuncs.com/test/%E6%98%8E%E6%96%87%E6%89%8B%E6%9C%BA%E5%8F%B7.xlsx';
    };
    return (
      <div>
        <Drawer
          width={400}
          title="新建发放计划"
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={onFinish} htmlType="submit">
                确定
              </Button>
            </Space>
          }>
          <Form form={creadForm} initialValues={{ remember: true }} onFinish={onFinish} style={{ maxWidth: 600 }} layout="vertical">
            <Form.Item
              label="计划名称"
              name="planName"
              rules={[
                { required: true, message: '请输入计划名称' },
                {
                  validator: (_, value) => {
                    if (/\s/.test(value)) {
                      return Promise.reject('计划名称不能包含空格');
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input autoComplete="off" placeholder="请输入计划名称" showCount maxLength={15} onChange={fillLoginForm} />
            </Form.Item>

            <Form.Item label="所属会员卡" name="memberCardId" rules={[{ required: true, message: '请选择所属会员卡' }]}>
              <Select placeholder="请选择" options={memberList} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="店铺名称" name="shopId" rules={[{ required: false, message: '请选择店铺名称' }]}>
              <Select placeholder="请选择" options={shopList.map((item: any) => ({ value: item.shopId, label: item.shopName }))} />
            </Form.Item>
            <Form.Item label="发送依据" name="basis" rules={[{ required: false }]}>
              <Radio.Group defaultValue={'phone'}>
                <Radio.Button value="phone">明文手机号</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Row>
              <Form.Item label="上传客户列表" name="file" rules={[{ required: true, message: '请上传文件' },{
                validator:()=>{
                  if(!isXlsx){
                    return Promise.reject('请上传.xls或.xlsx文件');
                  }
                  return Promise.resolve();
                }
              }]}>
                <Dragger {...uploadData} style={{ padding: 0, width: '145%' }} maxCount={1} accept=".xlsx,.xls">
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">支持扩展名：.xls .xlsx</p>
                </Dragger>
              </Form.Item>
              <Button
                style={{ padding: 0, marginLeft: '20px' }}
                type="link"
                icon={<DownloadOutlined />}
                onClick={e => {
                  toDownLoad();
                }}>
                下载模板
              </Button>
            </Row>
          </Form>
        </Drawer>
      </div>
    );
  },
);
export default creadDistributePoints;
