import { AxiosPromise } from 'axios';
import request from '../../../api/network'
import { insightAnalysisVO, RfmListQuery, crowdVO, referenceValueVO, IUpdateRFReport, IUpdateRMReport, CustomerQuery } from './types';

// 获取会员体系
export function getMemberCardApi(): AxiosPromise<any> {
    return request({
        url: '/crm/system/query/memberCard',
        method: 'post',
    });
}
// 获取渠道
export function getChannelListApi(query: { memberCardId: string | null }): AxiosPromise<any> {
    return request({
        url: '/crm/system/query/channelList',
        method: 'post',
        data: query
    });
}

// 获取店铺
export function getShopListApi(query: { memberCardId: string | null, channelType: string | null }): AxiosPromise<any> {
    return request({
        url: '/crm/system/query/shopList',
        method: 'post',
        data: query
    });
}

// 获取会员体系-会员
export function getMemberMemberCardApi(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/system/member/memberCard',
        method: 'post',
    });
}
// 获取渠道-会员
export function getMemberChannelListApi(query: { memberCardId: string | null }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/system/member/channelList',
        method: 'post',
        data: query
    });
}

// 获取店铺-会员
export function getMemberShopListApi(query: { memberCardId: string | null, channelType: string | null }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/system/member/shopList',
        method: 'post',
        data: query
    });
}

// 获取渠道-客户
export function getCustomerChannelListApi(query: { memberCardId: string | null }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/system/customer/channelList',
        method: 'post',
        data: query
    });
}

// 获取店铺-客户
export function getCustomerShopListApi(query: { memberCardId: string | null, channelType: string | null }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/system/customer/shopList',
        method: 'post',
        data: query
    });
}

// 客户资产分析
// 客户资产分析-全渠道客户资产数据总览
export function getCustomerOverview(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/assets/overview',
        method: 'post',
    });
}
// 客户资产分析-全渠道客户资产明细数据
export function getCustomerDetails(query: CustomerQuery): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/assets/details',
        method: 'post',
        data: query
    });
}
// 客户资产分析-导出
export function getCustomerDownload(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/assets/export',
        method: 'post',
        data: query
    });
}

// 客户交易分析
// 客户交易分析-全渠道客户交易数据总览
export function getTradeCustomerOverview(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/trade/overview',
        method: 'post',
    });
}
// 客户交易分析-全渠道客户购买趋势
export function getTradeCustomerTrend(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/trade/trend',
        method: 'post',
        data: query
    });
}
// 客户交易分析-全渠道客户交易明细数据
export function getTradeCustomerAnalysis(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/trade/details',
        method: 'post',
        data: query
    });
}
// 客户交易分析-导出
export function getTradeCustomerDownload(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/customer/trade/export',
        method: 'post',
        data: query
    });
}

// 会员资产分析
// 会员资产分析-全渠道会员分域一览
export function getOverviewChannel(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/overview/channel',
        method: 'post',
    });
}
// 会员资产分析-品牌全渠道等级会员占比统计
export function getOverviewLevel(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/overview/level',
        method: 'post',
        data: query
    });
}
// 会员资产分析-全渠道会员发展趋势分析
export function getOverviewTrend(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/overview/trend',
        method: 'post',
        data: query
    });
}
// 会员资产分析-全渠道会员资产明细分析
export function getAnalysisTrend(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/overview/analysis',
        method: 'post',
        data: query
    });
}
// 会员资产分析-导出
export function getOverviewDownload(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/overview/export',
        method: 'post',
        data: query
    });
}


// 会员交易分析
// 会员交易分析-全渠道会员交易数据总览
export function getTradeOverview(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/trade/overview',
        method: 'post',
    });
}
// 会员交易分析-全渠道会员购买趋势
export function getTradeTrend(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/trade/trend',
        method: 'post',
        data: query
    });
}
// 会员交易分析-全渠道会员交易明细数据
export function getTradeAnalysis(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/trade/analysis',
        method: 'post',
        data: query
    });
}
// 会员交易分析-导出
export function getTradeDownload(query: any): AxiosPromise<any> {
    return request({
        url: '/cdpdata/member/trade/export',
        method: 'post',
        data: query
    });
}


// 数据总览
// 数据总览-全渠道客户总览
export function getOverviewCustomer(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/data/overview/customer',
        method: 'post',
    });
}
// 数据总览-客户类型分布
export function getOverviewPie(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/data/overview/pie',
        method: 'post',
    });
}
// 数据总览-全渠道销售总览
export function getOverviewAll(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/data/overview/all',
        method: 'post',
    });
}
//创建RFM分析
export function createInsightAnalysis(data: any): AxiosPromise<insightAnalysisVO> {
    return request({
        url: '/cdpdata/insightAnalysis/add',
        method: 'post',
        data: data,
    });
}

//编辑RFM分析
export function updateInsightAnalysis(data: any): AxiosPromise<insightAnalysisVO> {
    return request({
        url: '/cdpdata/insightAnalysis/update',
        method: 'put',
        data: data,
    });
}

//获取分析报告详情
export function getInsightAnalysis(id: string): AxiosPromise<{}> {
    return request({
        url: `/cdpdata/insightAnalysis/${id}/get`,
        method: 'get',
    });
}

// 删除RFM分析
export function deleteInsightAnalysis(id: string): AxiosPromise<{}> {
    return request({
        url: `/cdpdata/insightAnalysis/${id}/delete`,
        method: 'delete',
    })
}

// 查询RFM分析报告
export function getInsightAnalysisList(query: RfmListQuery): AxiosPromise<any> {
    return request({
        url: '/cdpdata/insightAnalysis/list',
        method: 'post',
        data: query,
    });
}

// 获取平台
export function getPlatforms(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/common/platforms',
        method: 'get',
    });
}
// 获取店铺
export function getShops(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/common/shops',
        method: 'get',
    });
}

// 获取店铺
export function getProductLines(): AxiosPromise<any> {
    return request({
        url: '/cdpdata/common/productLines',
        method: 'get',
    });
}

// 重新计算
export function recalculateRFM(subReportId: string): AxiosPromise<{}> {
    return request({
        url: `/cdpdata/insightAnalysis/${subReportId}/recalculate`,
        method: 'get',
    });
}


//RFM分析-获取各类型基准值
export function getReferenceValueByReportId(subReportId: number): AxiosPromise<{}> {
    return request({
        url: `/cdpdata/insightAnalysis/rfmAnalysis/rfm/get/${subReportId}/item/list`,
        method: 'get',
    });
}

// RFM分析-三维添加基准值
export function addRFMReferenceValue(data: any): AxiosPromise<referenceValueVO> {
    return request({
        url: '/cdpdata/insightAnalysis/rfmAnalysis/rfm/add/item',
        method: 'post',
        data: data,
    });
}

// RFM分析-三维结果查询
export function getRFMList(query: { subReportId: number | null, frequencyNum: string | null, monetaryNum: string | null, recencyNum: string | null }): AxiosPromise<any> {
    return request({
        url: '/cdpdata/insightAnalysis/rfmAnalysis/rfm/result/list',
        method: 'post',
        data: query,
    });
}

//创建人群
export function createCrowdByRFM(data: any): AxiosPromise<crowdVO> {
    return request({
        url: '/cdpdata/insightAnalysis/rfmAnalysis/crowd',
        method: 'post',
        data: data,
    });
}
//创建人群
export function getRepurchaseIntervalAnalysis(subReportId: string): AxiosPromise<any> {
    return request({
        url: `/cdpdata/insightAnalysis/rfmAnalysis/repurchaseAnalysis/graph/${subReportId}`,
        method: 'get',
    });
}

// rfm分析-获取 rf 报告
export function getRFReport(id: number): AxiosPromise<any> {
    return request({
        url: `cdpdata/insightAnalysis/rfmAnalysis/rfAnalysis/${id}`,
        method: 'get',
    });
};

// rfm分析-更新 rf 报告
export function updateRFReport(data: IUpdateRFReport): AxiosPromise<any> {
    return request({
        url: `cdpdata/insightAnalysis/rfmAnalysis/rfAnalysis`,
        method: 'put',
        data: data,
    });
};

// rfm分析-获取 rm 报告
export function getRMReport(id: number): AxiosPromise<any> {
    return request({
        url: `cdpdata/insightAnalysis/rfmAnalysis/rmAnalysis/${id}`,
        method: 'get',
    });
};

// rfm分析-更新 rm 报告
export function updateRMReport(data: IUpdateRMReport): AxiosPromise<any> {
    return request({
        url: `cdpdata/insightAnalysis/rfmAnalysis/rmAnalysis`,
        method: 'put',
        data: data,
    });
};

// rfm分析-下载报告
export function reportExport(query: any): AxiosPromise<any> {
  return request({
      url: `cdpdata/insightAnalysis/rfmAnalysis/reportExport`,
      method: 'post',
      data: query,
  });
};