import React, { useState, useEffect, useRef } from "react";
import { Drawer, Button, Space, Form, Input, message } from "antd";
import type { DrawerProps } from "antd";
import { addRole, editRole } from "../../../../../../api/role/role";
import { RoleListVO } from "../../../../../../api/role/types";
import styles from "./index.module.scss";

const { TextArea } = Input;

const CreateRole = (props: {
  flag: string,
  creatRoleOpen: boolean;
  editData: RoleListVO;
  getOpenStatus: Function;
  submitSuccess: Function;
}) => {
  const {
    flag,
    creatRoleOpen,
    editData,
    getOpenStatus = () => {},
    submitSuccess = () => {},
  } = props;

  const [form] = Form.useForm();

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  //防连点
  const [clickStatus, setClickStatus] = useState(false)
  const formRef: any = useRef(null);

  type FieldType = {
    roleName: string;
    remark: string;
  };

  const formData: FieldType = {
    roleName: '',
    remark: ''
  };

  const [conditions, setConditions] = useState({...formData});
  const changeConditions = (newConditions:any) => {
    setConditions({
      ...conditions,
      ...newConditions,
    });
  };

  
  // 提交
  const submit = async () => {
    if (clickStatus) {
      return
    }
    setClickStatus(true)
    const param: {roleName: string, remark: string, roleId?: number | string} = {
      roleName: conditions.roleName,
      remark: conditions.remark
    };
    if(flag === 'edit' && editData.roleId){
      param.roleId = editData.roleId
    }
    if(flag === 'create'){
      const res = await addRole(param);
      if (res) {
        message.success("添加成功");
        getOpenStatus(false);
        submitSuccess();
      }
      setClickStatus(false)
    }else{
      const res = await editRole(param);
      if (res) {
        message.success("编辑成功");
        getOpenStatus(false);
        submitSuccess();
      }
      setClickStatus(false)
    }
  };

  // 关闭抽屉
  const onClose = () => {
    getOpenStatus(false);
  };

  useEffect(() => {
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        'roleName': creatRoleOpen && flag === 'edit' ? editData.roleName : '',
        'remark': creatRoleOpen && flag === 'edit' ? editData.remark : '',
      })
    }, 100);
    setConditions(creatRoleOpen && flag === 'edit' ? {roleName: editData.roleName, remark: editData.remark} : {...formData});
    if(!creatRoleOpen){
      form.resetFields();
    }
  }, [creatRoleOpen]);

  return (
    <div>
      <Drawer
        title={`${flag === 'create' ? '新建' : '编辑'}角色`}
        placement={placement}
        width={400}
        onClose={onClose}
        open={creatRoleOpen}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button
              type="primary"
              disabled={!conditions.roleName}
              onClick={submit}
            >
              确定
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" ref={formRef} form={form}>
          <Form.Item<FieldType>
            label="角色名称"
            name="roleName"
            rules={[{ required: true, message: "请输入角色名称" }]}
          >
            <Input
              count={{
                show: true,
                max: 20,
              }}
              maxLength={20}
              placeholder="请输入角色名称"
              onChange={(e) => {
                if (e.target.value.trim() === '') {
                  changeConditions({ roleName: ''});
                } else {
                  changeConditions({ roleName: e.target.value });
                }
              }}
            />
          </Form.Item>
          <Form.Item<FieldType> 
            label="角色说明" 
            name="remark"
          >
            <TextArea
              placeholder="请输入角色说明"
              showCount
              maxLength={100}
              onChange={(e) =>changeConditions({ remark: e.target.value })}
              style={{ height: 120, resize: "none" }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default CreateRole;
