import { AxiosPromise } from 'axios';
import request from '../network'

// 埋点
export function getUserClickApi(menuId: any, level: number, crowdId: any, labelId: any, parentId: any, router: any) {
  return request({
      url: '/system/home/click',
      method: 'get',
      params: { menuId, level, crowdId, labelId, parentId, router }
  });
}

// 首页
export function systemHomeApi() {
  return request({
      url: '/system/home/system',
      method: 'get',
      // params: { userName: username, type }
  });
}