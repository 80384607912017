import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Input,
  DatePicker,
  Select,
  InputNumber,
  message,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import type { DatePickerProps, SelectProps } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Label from "../Label";
import Property from "../Property";
import Crowd from "../Crowd";
import Behavior from "../Behavior";
import TimeSelect from "../TimeSelect";
import RelationshipDivider from "../RelationshipDivider";
import {
  checkLabelValueUsedApi,
} from "../../../../../../api/label/label";
import { QueryLabelListVO } from "../../../../../../api/label/types";
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

let ruleName: string[] = [];

// 请选择规则标签
const markOption1 = [
  { value: "1", label: "标签" },
  { value: "2", label: "属性" },
  { value: "3", label: "行为" },
];

// 请选择规则标签
const markOption2 = [
  { value: "1", label: "标签" },
  { value: "2", label: "属性" },
  { value: "3", label: "行为" },
  { value: "5", label: "分群" },
];

// 属性规则关系下拉值 - end
const RoleGroupsSetting = (props: {
  operateType: string;
  current: number;
  labelName?: string,
  flag: string;
  listData: any;
  itemData: any;
  ruleData: any;
  labelId: string,
  parentLabelId?: string,
  getRuleData: Function;
}) => {
  const {
    current,
    operateType,
    labelName,
    flag,
    listData,
    itemData,
    ruleData,
    labelId,
    parentLabelId,
    getRuleData = () => {},
  } = props;

  // 标签下拉值[标签\属性\行为\业务]
  const [optionsValue, setOptionsValue] = useState(
    flag === "bq" ? markOption1 : markOption2
  );

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // 校验标签是否可操作
  const [isGoOn, setIsGoOn] = useState(true);
  const [checkMsg, setCheckMsg] = useState("");
  const checkLabelValueUsed = async (id: string) => {
    const params = {
      labelValueId: id,
    };
    let f: number = 0;
    const res = await checkLabelValueUsedApi(params);
    if (res.data && res.data.isUsed === 1) {
      setCheckMsg("该标签值已被应用，不可进行修改操作");
    }

    setIsGoOn(res.data.isUsed === 0 ? true : false);
  };

  // 删除规则组
  const deleteRuleGroup = (labelId: string, ruleId: string) => {
    if (isGoOn) {
      const store = listData.map((v: any, i: number) => v);
      const currentItem = store.find(
        (v: any, i: number) => v.operate_label_id === labelId
      );
      const currentRule = currentItem.rules.rules.find(
        (v: any, i: number) => v.rules_value_id === ruleId
      );
      const arr: string[] = [];
      store.forEach((val: any, idx: number) => {
        if (val.operate_label_id === labelId) {
          if (val.rules.rules.length === 1) {
            message.error("至少保留一个规则组");
            arr.push(val.rules_value);
          } else {
            val.rules.rules.forEach((m: any, n: number) => {
              if (m.rules_value_id === ruleId) {
                val.rules.rules.splice(n, 1);
              }
            });
          }
        }
      });
      store.forEach((val: any, idx: number) => {
        val.rules.rules.forEach((m: any, n: number) => {
          arr.push(m.rules_value);
        });
      });
      ruleName = arr;
      getRuleData(store);
    } else {
      message.warning(checkMsg);
    }
  };

  // 获取规则select宽度
  const getWidth = (val: any, idx: number) => {
    if (val[idx].rules_Label_value === null && val.length === 1) {
      return "100%";
    } else if (val[idx].rules_Label_value !== null) {
      if(val[idx].rules_Label_value === "3"){
        return "calc((100% - 48px - 10px - 16px)/7)";
      } else {
        if(ruleData.rules.length > 1){
          return "calc((100% - 24px - 10px - 16px)/4)";
        } else {
          return "calc((100% - 24px)/4)";
        }
      }
    } else if (val.length > 1) {
      return "calc(100% - 10px - 16px)";
    }
  };

  // 获取各标签组件change方法
  const getRulesChangeFunction = (val: any) => {
    rulesChange(val.e, val.id, val.key, val.f, val.option);
  };

  // 请选择规则change事件
  const rulesChange = (e: any, id: string, key: string, f: string, option?: any) => {
    let store = listData.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        // 找到对应的标签组
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value_id === ruleData.rules_value_id) {
            // 找到对应的规则组
            m.rules.forEach((p: any, q: number) => {
              if (f === "p") {
                if (p.rules_operate_id === id) {
                  // 找到具体对应的某条规则
                  if (key === "min" || key === "max") {
                    if(key === "min"){
                      p.value = e + "-" + p.max;
                    } else {
                      p.value = p.min + "-" + e;
                    }
                    p[key] = e;
                  } else {
                    // 选择前面的展示标签【标签、属性、行为、业务、分群】
                    if (key === "rules_Label_value") {
                      switch (e) {
                        case "1": // 标签
                          p["field_type"] = "Label";
                          p["field_code"] = "";
                          p["table_name"] = "tag_user_detail";
                          p["field"] = "";
                          p["value"] = null;
                          // 清空后面的规则 - start
                          p["label_id"] = null;
                          p["operator_id"] = null;
                          p["operator"] = null;
                          if(p.rules){
                            delete p.rules;
                          }
                          // 清空后面的规则 - end
                          // getqueryLabelList();
                          break;
                        case "2":
                          // 清空后面的规则 - start
                          p["field_id"] = null;
                          p["field_code"] = "";
                          p["field"] = null;
                          p["field_type"] = "";
                          p["table_name"] = "";
                          p["operator_id"] = null;
                          p["operator"] = null;
                          p["value"] = null;
                          p["value_select"] = null;
                          p["value_time"] = null;
                          p["month_open"] = false;
                          p["min"] = "";
                          p["max"] = "";
                          if(p.rules){
                            delete p.rules;
                          }
                          // 清空后面的规则 - end
                          // getProfilePropertyList(); // 属性
                          break;
                        case "3":
                          // 行为
                          // 清空后面的规则 - start
                          p["time"] = null;
                          p["time_value"] = null;
                          p["behavior"] = "做过";
                          p["behavior_field_id"] = null;
                          p["field_id"] = null;
                          p["field"] = "";
                          p["field_code"] = "";
                          p["field_type"] = "";
                          p["table_name"] = "dwt_user_behavior_d";
                          p["statistics_type"] = "总次数";
                          p["operator_id"] = null;
                          p["operator"] = null;
                          p["value"] = "";
                          p["min"] = "";
                          p["max"] = "";
                          if(p.rules){
                            delete p.rules;
                          }
                          // 清空后面的规则 - end
                          // getProfileBehaviorList(); // 行为
                          break;
                        case "5":
                          // 分群
                          // getCrowdListApi(); // 获取分群
                          p.value = null; 
                          p.field_type = "Crowd";
                          p.field_code = "";
                          p.table_name = "crowd_user_detail";
                          p.operator_id = "=";
                          p.operator = "=";
                          if(p.rules){
                            delete p.rules;
                          }
                          break;
                          default:
                            console.log("");
                          break;
                        }
                      p[key] = e;
                    } else if(key === "label_id"){
                      // 请选择标签change事件
                      p["operator_id"] = null;
                      p["operator"] = null;
                      p["value"] = null;
                      p[key] = e;
                    } else if (key === "field_id") {
                      // 属性 - 清空后面的值
                      p["operator_id"] = null;
                      p["operator"] = null;
                      p["value"] = null;
                      p["value_select"] = null;
                      p["value_time"] = null;
                      p["month_open"] = false;
                      p["min"] = "";
                      p["max"] = "";
                      const storeItem = option.find((v: any, i: number) => v.id === e);
                      p["table_name"] = storeItem.tableName;
                      p["field_type"] = storeItem.fieldType;
                      p["field_code"] = storeItem.fieldCode;
                      p["field"] = storeItem.field;
                      p[key] = e;
                      if (storeItem.fieldType !== "String" && storeItem.fieldType !== "Date" && storeItem.fieldType !== "Long" && storeItem.fieldType !== "Decimal") {
                        p["value"] = null;
                        // getProfileEnumsList("property", {fieldType: storeItem.fieldType, fieldCode: storeItem.fieldCode});
                      }
                    } else if (key === "behavior_field_id") {
                      p["operator_id"] = null;
                      p["operator_value"] = "";
                      p["operator"] = null;
                      p["value"] = "";
                      p["min"] = "";
                      p["max"] = "";
                      if(p.rules){
                        delete p.rules;
                      }
                      p["field"] = e;
                      p["behavior_field_value"] = option.find((value: any, index: number) => value.value === e).label;
                      p[key] = e;
                    } else if (key === "behavior"){
                      if(e === '未做过'){
                        p["statistics_type"] = "";
                        p["operator_id"] = null;
                        p["operator"] = null;
                        p["value"] = "";
                        p["min"] = "";
                        p["max"] = "";
                      } else {
                        p["statistics_type"] = "总次数";
                        p["operator_id"] = 10;
                        p["operator"] = "gt=";
                        p["value"] = "";
                        p["min"] = "";
                        p["max"] = "";
                      }
                      if(p.rules){
                        delete p.rules;
                      };
                      p[key] = e;
                    } else if(key === 'statistics_type'){
                      p["operator_id"] = null;
                      p["operator"] = null;
                      p["value"] = "";
                      p["min"] = "";
                      p["max"] = "";
                      p[key] = e;
                    } else if (key === "behaviorParentRelationshipOptions") {
                      // 行为默认值是10
                      if(p.behavior === '做过' && p.statistics_type === '总次数'){
                        p["operator"] = "gt=";
                        p["operator_id"] = 10;
                        p["operator_value"] = "大于等于";
                      }
                    } else if (key === "operator_id") {
                      if (p.rules_Label_value === "1") {
                        // 标签
                        p["operator"] = option.find(
                          (v: any, i: number) => v.value === e
                        ).operator;
                        p["value"] = null;
                        p[key] = e;
                      }
                      if (p.rules_Label_value === "2") {
                        // 属性
                        p["operator"] = !isNaN(e) ? option.find((v:any, i:number) => v.code === e).operator : e.split('-')[0];
                        p["value"] = null;
                        p["value_select"] = null;
                        p["value_time"] = null;
                        p["min"] = "";
                        p["max"] = "";
                        p[key] = !isNaN(e) ? e : Number(e.split('-')[1]);
                      }
                      if (p.rules_Label_value === "3") {
                        // 行为            
                        p["operator"] = option.find((v: any, i: number) => v.value === e).operator;
                        p["operator_value"] = option.find((v: any, i: number) => v.value === e).name;
                        p["value"] = "";
                        p["min"] = "";
                        p["max"] = "";
                        p[key] = e;
                      }
                      if (p.rules_Label_value === "5") {
                        p["operator"] = e;
                        p[key] = e;
                      }
                    } else if(key === "value_select"){
                      p["value"] = Array.isArray(e) && e.length === 0 ? null : e;
                      p["value_select"] = option;
                    } else if (key === "value_r") {
                      if(e.length === 1 || !e[0] || !e[1]){
                        p["value_time"] = null;
                      } else {
                        p["value_time"] = [
                          dayjs(e[0], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                          dayjs(e[1], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                        ];
                      }
                      p["value"] = e;
                    
                    } else if(key === "value_m_r"){
                      p["value"] = e ? e.map((tv: any, ti: number) => tv.replace(/月/g, "")) : null;
                      p["value_time"] = e ? e.join(' - ') : null;
                    } else if (key === "value_d" || key === "value_y") {
                      // 年月日需做单独处理
                      p["value"] = e;
                      if(e){
                        p["value_time"] = key === "value_d" ? dayjs(e, "YYYY-MM-DD") : dayjs(e, "YYYY");
                      }else{
                        p["value_time"] = null;
                      }
                    } else if(key === "value_m"){
                      p["value"] = e.replace(/月/g, "");
                      p["value_time"] = e;
                    } else {
                      p[key] = e;
                    }
                  }
                }
              } else {
                if (p.rules) {
                  p.rules.rules.forEach((w: any, g: number) => {
                    if (w.child_id === id) {
                      if (key === "field_id") {
                        const storeItem = option.find((v: any, i: number) => v.id === e[0]);
                        w["operator"] = null;
                        w["operator_id"] = null;
                        w["min"] = "";
                        w["max"] = "";
                        w["value"] = null;
                        w["value_time"] = null;
                        w["month_open"] = false;
                        w["table_name"] = storeItem.tableName;
                        w["field_type"] = storeItem.fieldType;
                        w["field_code"] = storeItem.fieldCode;
                        w["field"] = storeItem.field;
                        w["field_id"] = storeItem.id;
                        w["func_value"] = e;
                        w["field_value"] = storeItem.fieldName;
                        if (storeItem.fieldType !== "String" && storeItem.fieldType !== "Date" && storeItem.fieldType !== "Long" && storeItem.fieldType !== "Decimal") {
                          w["value"] = null;
                        }
                      } else if (key === "operator_id") {
                        w["operator"] = !isNaN(e) ? option.find((v:any, i:number) => v.code === e).operator : e.split('-')[0];
                        w["min"] = "";
                        w["max"] = "";
                        w["value"] = null;
                        w["value_select"] = null;
                        w["value_time"] = null;   
                        w["month_open"] = false;
                        w["operator_value"] = !isNaN(e) ? option.find((v:any, i:number) => v.code === e).name : e.split('-')[2];
                        w[key] = !isNaN(e) ? e : Number(e.split('-')[1]);
                      } else if(key === "value_select"){
                        w["value"] = Array.isArray(e) && e.length === 0 ? null : e;
                        w["value_select"] = option.id;
                        w["value_select_value"] = option.name.join('、');
                      } else if (key === "value_r") {
                        if(e.length === 1 || !e[0] || !e[1]){
                          w["value_time"] = null;
                        } else {
                          w["value_time"] = [
                            dayjs(e[0], w.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                            dayjs(e[1], w.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                          ];
                        }
                        w["value"] = e;
                      } else if(key === "value_m_r"){
                        w["value"] = e ? e.map((tv: any, ti: number) => tv.replace(/月/g, "")) : null;
                        w["value_time"] = e ? e.join(' - ') : null;
                      } else if (key === "value_d" || key === "value_y") {
                        // 年月日需做单独处理
                        w["value"] = e;
                        if(e){
                          w["value_time"] = key === "value_d" ? dayjs(e, "YYYY-MM-DD") : dayjs(e, "YYYY");
                        } else {
                          w["value_time"] = null;
                        }
                      } else if(key === "value_m"){
                        w["value"] = e.replace(/月/g, "");
                        w["value_time"] = e;
                      } else if(key === "min" || key === "max"){
                        if(key === "min"){
                          w.value = e + "-" + w.max;
                        } else {
                          w.value = w.min + "-" + e;
                        }
                        w[key] = e;
                      } else {
                        w[key] = e;
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    getRuleData(store);
  };

  // 获取组件校验数字输入框大小方法
  const getCheckSizeFunction = (val: any) => {
    checkSize(val.value, val.data, val.key, val.msg, val.f);
  };

  // 校验数字输入框大小
  const checkSize = (e: any, val: any, f: string, msg: string, k: string) => {
    let store = listData.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value_id === ruleData.rules_value_id) {
            m.rules.forEach((p: any, q: number) => {
              if(k === 'p'){
                if (p.rules_operate_id === val.rules_operate_id) {
                  if(f === 'min' && e){
                    if(val.max && Number(val.max) < Number(e)){
                      p.min = '';
                      message.warning(msg);
                    }
                  } else if(f === 'max' && e){
                    if(val.min && Number(e) < Number(val.min)){
                      p.max = '';
                      message.warning(msg);
                    }
                  }
                }
              } else {
                if(p.rules){
                  p.rules.rules.forEach((w: any, x: number) => {
                    if(w.child_id === val.child_id){
                      if(f === 'min' && e){
                        if(val.max && Number(val.max) < Number(e)){
                          w.min = '';
                          message.warning(msg);
                        }
                      } else if(f === 'max' && e){
                        if(val.min && Number(e) < Number(val.min)){
                          w.max = null;
                          message.warning(msg);
                        }
                      }
                    }
                  })
                }
              }
            })
          }
        })
      }
    });
    
    getRuleData(store);
  };

  // 添加规则
  const addRuleItem = () => {
    if (isGoOn) {
      let store = listData.map((val: any, idx: number) => val);
      let pItem = store.find(
        (v: any, i: number) => v.operate_label_id === itemData.operate_label_id
      );
      let cItem = pItem.rules.rules?.find(
        (v: any, i: number) => v.rules_value_id === ruleData.rules_value_id
      );
      if (cItem.rules.length > 19) {
        message.error("一个规则组内最多支持添加20个规则");
      } else {
        cItem.rules?.unshift({
          rules_Label_value: null,
          rules_operate_id: String(new Date().valueOf()) + "04",
          table_name: "",
          field_type: "",
          field_code: "",
          label_id: null,
          field: null,
          field_id: null,
          func_value: [],
          min: "",
          max: "",
          time: null,
          time_value: null,
          time_key: '1',
          is_custom: false,
          custom_value: null,
          custom_checked: null,
          timeStore: "",
          operator: null,
          operator_id: null,
          operator_value: "",
          behavior: "做过",
          value: "",
          value_select: "",
          value_time: null,
          month_open: false,
          statistics_type: "总次数", // 统计方式
          behavior_field_id: null,
          behavior_field_value: "",
          behavior_time_open: false,
        });
      }
      getRuleData(store);
    } else {
      message.error(checkMsg);
    }
  };

  // 删除规则
  const deleteItem = (idx: number) => {
    if (isGoOn) {
      let store = listData.map((val: any, idx: number) => val);
      let pItem = store.find(
        (v: any, i: number) => v.operate_label_id === itemData.operate_label_id
      );
      let cItem = pItem.rules.rules?.find(
        (v: any, i: number) => v.rules_value_id === ruleData.rules_value_id
      );
      cItem.rules?.splice(idx, 1);
      getRuleData(store);
    } else {
      message.warning(checkMsg);
    }
  };

  // 获取且和或的change值
  const getChangeValue = (val: any) => {
    const store = listData.map((v: any, i: number) => v);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (val.flag === "p") {
            if (m.rules_value_id === val.id) {
              m.combinator = val.v;
            }
          } else {
            if (m.rules_value_id === ruleData.rules_value_id) {
              m.rules.forEach((p: any, q: number) => {
                if (p.rules_operate_id === val.id) {
                  p.rules.combinator = val.v;
                }
              });
            }
          }
        });
      }
    });
    getRuleData(store);
  };

  // 鼠标移入事件
  const toggleHover = (id: any, f: string) => {
    if (operateType !== "view") {
      const store = listData.map((v: any, i: number) => v);
      store.forEach((v: any, i: number) => {
        if (v.operate_label_id === itemData.operate_label_id) {
          v.rules.rules.forEach((m: any, n: number) => {
            if (m.rules_value_id === id) {
              m.action_bar_show = f === "enter" ? true : false;
            }
          });
        }
      });
      getRuleData(store);
    }
  };

  // 修改标签名字
  const changeName = (item: any) => {
    if(!isGoOn){
      return message.warning(checkMsg);
    }
    const store = listData.map((v: any, i: number) => v);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value_id === item.rules_value_id) {
            m.is_copy = true;
          }
        });
      }
    });
    getRuleData(store);
  };

  const labelNameChange = (e: string, item: any) => {
    const store = listData.map((v: any, i: number) => v);
      store.forEach((v: any, i: number) => {
        if (v.operate_label_id === itemData.operate_label_id) {
          v.rules.rules.forEach((m: any, n: number) => {
            if (m.rules_value_id === item.rules_value_id) {
              m.rules_value = e;
            }
          });
        }
      });
      getRuleData(store);
  };

  const hasDuplicates = (data: string[]) => {
    return new Set(data).size !== data.length;
  };

  // 失去焦点校验
  const handleBlur = (e: any, item: any) => {
    if(item.rules_value === ''){
      e.target.focus();
      return message.warning("规则组名称不允许为空");
    }
    const store = listData.map((val: any, idx: number) => val);
    let f = false;
    for(let i = 0; i < store.length; i++){
      if(store[i].operate_label_id === itemData.operate_label_id){
        for(let y = 0; y < store[i].rules.rules.length; y++){
          if(store[i].rules.rules[y].rules_value === item.rules_value && store[i].rules.rules[y].rules_value_id !== item.rules_value_id){
            f = true;
            break;
          }
        }
      }
    }
    if(f){
      e.target.focus();
      setTimeout(() => {
        if(ruleName.length === 0){
          message.warning("规则组名称不允许重复");
        } else {
          if(hasDuplicates(ruleName)){
            message.warning("规则组名称不允许重复");
          }
        } 
      }, 200);
    }else{
      store.forEach((v: any, i: number) => {
        if (v.operate_label_id === itemData.operate_label_id) {
          v.rules.rules.forEach((m: any, n: number) => {
            if (m.rules_value_id === item.rules_value_id) {
              if(item.rules_value.length < 21){
                m.is_copy = false; // 如果名称字数在规定的20个字符内，失焦校验成功，名称便以文本的格式展示
              }else {
                e.target.focus();
                message.warning("规则组名称最多20个字符");
              }
            }
          });
        }
      });
      getRuleData(store);
      ruleName = [];
    }
  };

  // 回车校验
  const labelNameBlur = (item: any) => {
    if(item.rules_value === ''){
      return message.warning("规则组名称不允许为空");
    }
    const store = listData.map((v: any, i: number) => v);
    let f = true;
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value === item.rules_value && m.rules_value_id !== item.rules_value_id) {
            f = false;
          }
        });
      }
    });

    if(f){
      store.forEach((v: any, i: number) => {
        if (v.operate_label_id === itemData.operate_label_id) {
          v.rules.rules.forEach((m: any, n: number) => {
            if (m.rules_value_id === item.rules_value_id) {
              if(m.rules_value.length < 21){
                m.is_copy = false; // 如果名称字数在规定的20个字符内，回车校验成功，名称便以文本的格式展示
              } else {
                message.warning("规则组名称最多20个字符");
              }
            }
          });
        }
      });
      getRuleData(store);
    } else {
      message.warning("规则组名称不允许重复");
    }
  };

  // 复制规则组
  const copyItem = (item: any) => {
    if(!isGoOn){
      return message.warning(checkMsg);
    }
    const store = listData.map((val: any, idx: number) => val);
    const itemStore = JSON.parse(JSON.stringify(item));
    let is_add = false;
    if(flag === "fq"){
      store.forEach((v: any, i: number) => {
        if (v.operate_label_id === itemData.operate_label_id) {
          if(v.rules.rules.length > 9){
            is_add = true;
          }
        }
      });
    }

    if(is_add){
      return message.warning("最多只能添加10个规则组");
    }
    
    const nameArr = [];
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if(m.rules_value.length > 20){
            m.is_copy = true;  // 如果名称字数超过二十个字符，名称便以输入框格式展示，需强制修改名称
          }
          if (m.rules_value.indexOf(itemStore.rules_value + "副本") !== -1) {
            nameArr.push(v);
          }
        });
      }
    });
    itemStore.rules_value_id = String(new Date().valueOf()) + "03";
    itemStore.rules_value =
      nameArr.length === 0
        ? itemStore.rules_value + "副本"
        : itemStore.rules_value + "副本" + "（" + String(nameArr.length) + "）";
    if(itemStore.rules_value.length > 20){
      itemStore.is_copy = true;  // 如果名称字数超过二十个字符，名称便以输入框格式展示，需强制修改名称
    }
    itemStore.data_sources = "copy";
    itemStore.rules.forEach((m: any, n: number) => {
      m.rules_operate_id = String(new Date().valueOf()) + n + "04";
      if(m.field_type === 'Date' || m.field_type === 'Year'){
        if(m.operator_id === 7){
          m["value_time"] = [
            dayjs(m.value[0], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
            dayjs(m.value[1], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
          ];
        } else {
          m.value_time = m.field_type === "Date" ? dayjs(m.value, "YYYY-MM-DD") : dayjs(m.value, "YYYY");
        }
      }
      if (m.rules) {
        m.rules.rules.forEach((p: any, q: number) => {
          p.child_id = String(new Date().valueOf()) + q + "05";
          if(p.field_type === 'Date' || p.field_type === 'Year'){
            if(p.operator_id === 7){
              p["value_time"] = [
                dayjs(p.value[0], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                dayjs(p.value[1], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
              ];
            } else {
              p.value_time = p.field_type === "Date" ? dayjs(p.value, "YYYY-MM-DD") : dayjs(p.value, "YYYY");
            }
          }
        });
      }
    });
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === itemData.operate_label_id) {
        v.rules.rules.unshift(itemStore);
      }
    });
    getRuleData(store);
  };

  useEffect(() => {
    if (operateType === "edit") {
      checkLabelValueUsed(itemData.operate_label_id);
    }
  }, [operateType]);

  return (
    <div className={styles.roleGroupsSetting}>
      <div
        onMouseEnter={() => toggleHover(ruleData.rules_value_id, "enter")}
        onMouseLeave={() => toggleHover(ruleData.rules_value_id, "leave")}
      >
        <div className={styles.headerBox}>
          {
            (!ruleData.is_copy) ? 
            <div className={styles.name}>{ruleData.rules_value}</div> :
            <div>
              <Input 
                showCount 
                maxLength={20}
                className={styles.modifyName}
                style={{color: operateType === "view" ? "var(--text-font-color10)" : "var(--text-font-color9)"}}
                value={ruleData.rules_value}
                autoFocus={ruleData.is_copy}
                onChange={(e) => labelNameChange(e.target.value, ruleData)}
                onBlur={(e) => handleBlur(e, ruleData)}
                onPressEnter={() => labelNameBlur(ruleData)}
              />
            </div>
          }
          {ruleData.action_bar_show && (
            <div style={{ lineHeight: 'var(--text-font11)' }}>
              <Tooltip title="编辑">
                <EditOutlined
                  style={{
                    marginRight: 9,
                    cursor: "pointer",
                    color: "var(--text-font-color4)",
                    fontSize: "var(--text-font7)",
                  }}
                  onClick={() => changeName(ruleData)}
                />
              </Tooltip>
              <Tooltip title="复制">
                <CopyOutlined
                  style={{
                    marginRight: 9,
                    cursor: "pointer",
                    color: "var(--text-font-color4)",
                    fontSize: "var(--text-font7)",
                  }}
                  onClick={() => copyItem(ruleData)}
                />
              </Tooltip>
              <Tooltip title="删除">
                <DeleteOutlined
                  style={{
                    cursor: "pointer",
                    color: "var(--text-font-color4)",
                    fontSize: "var(--text-font7)",
                  }}
                  onClick={() =>
                    deleteRuleGroup(
                      itemData.operate_label_id,
                      ruleData.rules_value_id
                    )
                  }
                />
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.bodyBox}>
          <div
            style={{
              display: ruleData.rules?.length > 1 ? "flex" : "block",
              justifyContent: "space-between",
            }}
          >
            {ruleData.rules?.length > 1 && (
              <RelationshipDivider
                operateType={operateType}
                size="14px"
                level="2"
                textFlag={ruleData.combinator}
                dataParams={{ id: ruleData.rules_value_id, flag: "p" }}
                getChangeValue={getChangeValue}
              />
            )}
            <div
              style={{
                width:
                  ruleData.rules?.length > 1
                    ? "calc(100% - 40px)"
                    : "100%",
              }}
            >
              {ruleData.rules?.map((val: any, idx: number) => {
                return (
                  <div key={val.rules_operate_id}>
                    <div style={{ display: "flex" }}>
                      <Select
                        disabled={operateType === "view"}
                        placeholder="请选择规则"
                        style={{
                          width: getWidth(ruleData.rules || [], idx),
                          marginBottom: 8,
                        }}
                        value={val.rules_Label_value}
                        onChange={(e) =>
                          rulesChange(
                            e,
                            val.rules_operate_id,
                            "rules_Label_value",
                            "p"
                          )
                        }
                        options={optionsValue}
                      />
                      {ruleData.rules?.length > 1 && operateType !== "view" && val.rules_Label_value === null && (
                        <div
                          style={{
                            marginLeft: 10,
                            height: "32px",
                            lineHeight: 'var(--text-font15)',
                          }}
                        >
                          <Tooltip title="删除">
                            <DeleteOutlined
                              style={{
                                cursor: "pointer",
                                color: "var(--text-font-color4)",
                                fontSize: "var(--text-font7)",
                              }}
                              onClick={() => deleteItem(idx)}
                            />
                          </Tooltip>
                        </div>
                      )}
                      {val.rules_Label_value === "1" && 
                        <Label 
                          key={val.rules_operate_id}
                          current={current}
                          labelId={labelId}
                          operateType={operateType}
                          itemData={itemData}
                          ruleData={ruleData}
                          val={val}
                          idx={idx}
                          parentLabelId={parentLabelId}
                          getDeleteItemFunction={(i: number) => deleteItem(i)}
                          getRulesChangeFunction={getRulesChangeFunction}

                        />}
                      {val.rules_Label_value === "2" && 
                        <Property
                          key={val.rules_operate_id}
                          current={current}
                          operateType={operateType}
                          itemData={itemData}
                          ruleData={ruleData}
                          val={val}
                          idx={idx}
                          getCheckSizeFunction={getCheckSizeFunction}
                          getDeleteItemFunction={(i: number) => deleteItem(i)}
                          getRulesChangeFunction={getRulesChangeFunction}
                        />
                      }
                      {val.rules_Label_value === "5" && 
                        <Crowd 
                          key={val.rules_operate_id}
                          current={current}
                          operateType={operateType}
                          itemData={itemData}
                          ruleData={ruleData}
                          val={val}
                          idx={idx}
                          getDeleteItemFunction={(i: number) => deleteItem(i)}
                          getRulesChangeFunction={getRulesChangeFunction}
                        />
                      }
                      {val.rules_Label_value === "3" && 
                        <Behavior 
                          key={val.rules_operate_id}
                          current={current}
                          operateType={operateType}
                          listData={listData}
                          itemData={itemData}
                          ruleData={ruleData}
                          val={val}
                          idx={idx}
                          isGoOn={isGoOn}
                          checkMsg={checkMsg}
                          getRuleDataFunction={(store: any) => getRuleData(store)}
                          getCheckSizeFunction={getCheckSizeFunction}
                          getDeleteItemFunction={(i: number) => deleteItem(i)}
                          getRulesChangeFunction={getRulesChangeFunction}
                        />
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {
            operateType !== "view" && <div style={{ marginBottom: "16px" }}>
            <Button
              disabled={operateType === "view"}
              type="dashed"
              icon={<PlusOutlined />}
              style={{
                width: "100%",
                marginBottom: 8
              }}
              onClick={addRuleItem}
            >
              添加规则
            </Button>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default RoleGroupsSetting;
