import { useState, forwardRef, useImperativeHandle } from 'react';
import { InputNumber, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const ChangeR = forwardRef((_, ref) => {
  const [value, setValue] = useState<((number | undefined) | { id: number, start: number | undefined; end: number | undefined })[]>([
    {
      start: undefined,
      end: undefined,
      id: Math.random(),
    },
    {
      start: undefined,
      end: undefined,
      id: Math.random(),
    },
    undefined,
  ]);
  console.log('value', value);


  useImperativeHandle(ref, () => ({
    value,
  }));
  return (
    <div>
      {value.map((i, index) => {
        return <div key={typeof i === 'object' ? i.id : 'isFinal'}>
          {
            typeof i === 'object' ? <>
              <p style={{ display: 'inline-block', width: 12 }}>{index + 1}:</p>
              <p style={{ display: 'inline-block', width: 8, margin: '0 8px' }}>[</p>
              <InputNumber
                placeholder='请输入起始时间'
                style={{ width: 130 }}
                min={0}
                max={1095}
                precision={0}
                controls={false}
                value={i.start}
                onChange={(v) => {
                  const newValue = [...value];
                  newValue[index] = { ...i, start: v ?? undefined };
                  setValue(newValue);
                }}
              />
              <span style={{ margin: '0 8px' }}>-</span>
              <InputNumber
                placeholder='请输入截止时间'
                style={{ width: 130 }}
                min={0}
                max={1095}
                precision={0}
                controls={false}
                value={i.end}
                onChange={(v) => {
                  const newValue = [...value];
                  newValue[index] = { ...i, end: v ?? undefined };
                  setValue(newValue);
                }}
              />
              <span style={{ marginLeft: 8 }}>)天</span>
            </> : <>
              <p style={{ display: 'inline-block', width: 12 }}>{index + 1}:</p>
              <span style={{ display: 'inline-block', width: 8, margin: '0 8px' }}>≥</span>
              <InputNumber
                placeholder='请输入最终截止时间'
                style={{ width: 282 }}
                min={0}
                max={1095}
                precision={0}
                controls={false}
                value={i}
                onChange={(v) => {
                  const newValue = [...value];
                  newValue[index] = v ?? undefined;
                  setValue(newValue);
                }}
              />
              <span style={{ marginLeft: 14 }}>天</span>
            </>
          }
          {
            typeof i === 'object' && <>
              <PlusOutlined
                style={{ margin: '0 8px' }}
                onClick={() => {
                  if (value.length >= 6) {
                    message.error('自定义区间最多6个');
                    return;
                  }
                  const newValue = [...value];
                  newValue.splice(index + 1, 0, { id: Math.random(), start: undefined, end: undefined });
                  setValue(newValue);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  if (value.length <= 3) {
                    message.error('自定义区间最少3个');
                    return;
                  }
                  setValue(value.filter((_, j) => j !== index));
                }} />
            </>
          }
        </div>;
      })}
    </div>
  );
});

export default ChangeR;