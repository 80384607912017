import { useEffect, useState } from "react";
import style from "./index.module.scss";

import { Row, Col, Empty } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";

import {
  TimeTitle,
  IconMap,
  PieChart2,
  DoubleLineDoubleBarChart4,
} from "../../../../component/DataBoardStyles";
import { TextWithQuestion } from "../../../../component/MiniStyles";
import { crmTipsMap } from "../../../../assets/public/pageMap";

import {
  getOverviewCustomer,
  getOverviewPie,
  getOverviewAll,
} from "@/crm/api/dataBoardApi";
import handScrollTop from "@/crm/assets/public/handscrollTop";

// 全渠道客户总览
const CustomerOverView = () => {
  const pageIconMap = new Map([
    ["全渠道客户", "user"],
    ["全渠道已购会员", "hookImage"],
    ["全渠道未购会员", "shopCar"],
    ["", "user"],
  ]);
  const [cardList, setCardList] = useState<any[]>([]);

  useEffect(() => {
    getCardList();
  }, []);

  // 获得卡片列表
  const getCardList = async () => {
    const res = await getOverviewCustomer();
    if (res?.code === 200) {
      setCardList(res?.data || []);
    } else {
      setCardList([]);
    }
  };

  // 小卡片
  const SmallCardSection = ({ title, info }: { title: string; info: any }) => {
    const { num = 0, hbRate = 0, tbRate = 0 } = info; //
    const tbStatus = Number(tbRate) > 0 ? 1 : Number(tbRate) < 0 ? 2 : 0;
    const hbStatus = Number(hbRate) > 0 ? 1 : Number(hbRate) < 0 ? 2 : 0;

    return (
      <div style={{ marginTop: "4px" }}>
        <div className={style["small-card-bgd"]}>
          <div className={style["flex-between"]}>
            <TextWithQuestion
              title={
                <span className={style["title-gray"]}>
                  {title ? title.split("_")[1] : []}
                </span>
              }
              tips={crmTipsMap.get(title)}
              style={{ color: "var(--text-font-color4)" }}
              black
            />
            <div className={style["num"]}>{Number(num).toLocaleString()}</div>
          </div>
          <div
            className={style["flex-between"]}
            style={{ margin: "7px 0 6px 0" }}>
            <div className={style["title"]}>同比</div>
            <div>
              {tbStatus === 1 && (
                <CaretUpOutlined style={{ color: "var(--label5)" }} />
              )}
              {tbStatus === 2 && (
                <CaretDownOutlined style={{ color: "var(--text-font-color19)" }} />
              )}
              <span className={style[`rate-${tbStatus}`]}>
                {Number(tbRate)
                  ? `${Number(tbRate).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}%`
                  : "--"}
              </span>
            </div>
          </div>
          <div className={style["flex-between"]}>
            <div className={style["title"]}>环比</div>
            <div>
              {hbStatus === 1 && (
                <CaretUpOutlined style={{ color: "var(--label5)" }} />
              )}
              {hbStatus === 2 && (
                <CaretDownOutlined style={{ color: "var(--text-font-color19)" }} />
              )}
              <span className={style[`rate-${hbStatus}`]}>
                {Number(hbRate)
                  ? `${Number(hbRate).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}%`
                  : "--"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // 大卡片
  const CardSection = ({ info }: { info: any }) => {
    const { name = "", num = 0, list = [] } = info;
    return (
      <div className={style["card-item-bgd"]}>
        {/* 标题 */}
        <div className={style["card-item-title"]}>
          <div>
            <IconMap type={pageIconMap.get(name) || ""} />
            <TextWithQuestion
              title={<span className={style["title"]}>{name}</span>}
              tips={crmTipsMap.get(`数据总览_${name}`)}
              style={{ color: "var(--text-font-color4)" }}
              black
            />
          </div>
          <div>{Number(num).toLocaleString()}</div>
        </div>
        {/* 小卡片 */}
        {list.length > 0 &&
          list.map((e: any) => (
            <SmallCardSection
              title={`${name}_${e.name}`}
              key={e.name}
              info={e}
            />
          ))}
        {list.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ marginTop: "130px" }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <TimeTitle
        title="全渠道客户总览"
        tips={crmTipsMap.get("全渠道客户总览")}
      />
      <div className={style["card-bgd"]}>
        {/* 大卡片 */}
        <Row gutter={[24, 0]}>
          {cardList.length > 0 &&
            cardList.map((item) => (
              <Col span={8} key={item.name}>
                <CardSection info={item} />
              </Col>
            ))}
          {cardList.length === 0 && (
            <Col span={24}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ marginTop: "64px auto" }}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

// 客户类型分布
const CusomerType = () => {
  const cusomerTypeList = [
    { title: "客户类型分布", chartId: "customerList" },
    { title: "客户活跃度分布", chartId: "activeList" },
    { title: "会员等级分布", chartId: "levelList" },
  ];
  const [optionList, setOptions] = useState<any>({});

  useEffect(() => {
    getPieInfoList();
  }, []);

  // 获得饼图数据
  const getPieInfoList = async () => {
    const res = await getOverviewPie();
    if (res?.code === 200) {
      const obj = JSON.parse(JSON.stringify(res?.data || {}));
      obj.activeList =
        obj?.activeList?.map((e: any) => ({
          ...e,
          name: `${e.name}`,
          value: e.num,
        })) || [];
      obj.customerList =
        obj?.customerList?.map((e: any) => ({
          ...e,
          name: `${e.name}`,
          value: e.num,
        })) || [];
      obj.levelList =
        obj?.levelList?.map((e: any) => ({
          ...e,
          name: `${e.name}`,
          value: e.num,
        })) || [];
      setOptions(obj);
    } else {
    }
  };

  // 饼图
  const PieSection = ({ info }: { info: any }) => {
    const { title, chartId } = info;

    return (
      <Col span={8}>
        <div className={style["CusomerType-card"]}>
          <div className={style["card-title"]}>
            <TimeTitle title={title} tips={crmTipsMap.get(title)} noTime />
          </div>
          <div style={{ marginTop: "16px", padding: "0 12px" }}>
            <PieChart2 chartId={chartId} options={optionList[chartId] || []} />
          </div>
        </div>
      </Col>
    );
  };

  return (
    <div style={{ marginTop: "32px" }}>
      <Row gutter={24}>
        {cusomerTypeList.map((item) => (
          <PieSection info={item} key={item.chartId} />
        ))}
      </Row>
    </div>
  );
};

// 全渠道销售总览
const SalesOverview = () => {
  const cardSectionList = [
    { title: "销售额", key: "gmv" },
    { title: "已购客户数", key: "customerDealNum" },
    { title: "已购会员数", key: "addMemberDealNum" },
    { title: "新增会员数", key: "addMemberNum" },
  ];
  const [pageInfo, setPageInfo] = useState<any>({});
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    getTableIfo();
  }, []);

  // 获得表格信息
  const getTableIfo = async () => {
    const res = await getOverviewAll();
    if (res?.code === 200) {
      const arr =
        res?.data?.children.map((item: any) => ({
          time: item.xbizDate,
          bar1: item.gmv || 0,
          rate1: item.gmvTb || 0,
          rateNum1: item.gmvTbRate || 0,
          bar2: item.customerDealNum || 0,
          rate2: item.customerDealNumTb || 0,
          rateNum2: item.customerDealNumTbRate || 0,
        })) || [];
      setPageInfo(res?.data || {});
      setOptions(arr);
    } else {
      setPageInfo({});
      setOptions([]);
    }
  };

  // 卡
  const CardSection = () => {
    return (
      <div className={style["card-list"]}>
        {cardSectionList.map((item) => (
          <div className={style["card-bgd"]}>
            <div className={style["card-title"]}>
              <TextWithQuestion
                title={item.title}
                tips={crmTipsMap.get(`全渠道销售总览${item.title}`)}
                black
              />
            </div>
            <div>{Number(pageInfo[item.key] || 0).toLocaleString()}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={style["SalesOverview-bgd"]}>
      <TimeTitle
        title="全渠道销售总览"
        tips={crmTipsMap.get("全渠道销售总览")}
        noTime
      />
      {/* 卡 */}
      <CardSection />
      <div style={{ marginTop: "24px" }}>
        <DoubleLineDoubleBarChart4 chartId="salesOverview" options={options} />
      </div>
    </div>
  );
};

const DataScreening = () => {
  useEffect(() => {
    handScrollTop();
  }, []);

  return (
    <div className={style["DataBoardStyles-bgd"]}>
      {/* 全渠道会员交易数据总览 */}
      <CustomerOverView />
      {/* 客户类型分布 */}
      <CusomerType />
      {/* 全渠道销售总览 */}
      <SalesOverview />
    </div>
  );
};

export default DataScreening;
