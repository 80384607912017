import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Tooltip} from "antd";
import React, {useEffect, useImperativeHandle, useState,} from "react";
import dayjs from "dayjs";

import style from "./index.module.scss";

import {motion} from "framer-motion";

const {RangePicker} = DatePicker;

export interface CrmSearchItem {
  searchType?: string;
  searchComponentType?: string;
  placeHolder?: string;
  title?: string;
  key?: string;
  options?: any[];
  selectInputKey?: string;
  mode?: 'multiple' | 'tags';
  defaultValue?: string | number | string[] | number[];
}

const FilterView = React.forwardRef(
  (
    props: {
      searchList?: CrmSearchItem[];
      finish?: any;
      defaultFormValue?: any;
      onFormChange?: any;
      background?: string;
      hasNotDefaultValue?: boolean
    },
    ref: any
  ) => {
    const {onFormChange} = props;
    const [arrow, setArrow] = useState(false);
    const [selectObj, setSelectObj] = useState<any>(() => {
      const obj: any = {};
      const arr: any[] = props.searchList || [];
      arr.forEach((item) => {
        if (item.searchComponentType === "selectInput") {
          obj[item.selectInputKey] = "";
        }
      });
      return obj;
    });
    const [form] = Form.useForm();

    const showFilterView = () => {
      // if (arrow) {
      //   form.resetFields();
      // }
      setArrow(!arrow);
    };

    // 赋值初始值
    useEffect(() => {

      handChangeForm(props.defaultFormValue);
    }, []);

    // 表单初始值
    const handChangeForm = (e: any) => {
      const obj = JSON.parse(JSON.stringify(selectObj));
      const arr = Object.keys(obj);

      if (arr.length > 0) {
        arr.map((item) => {
          return e[item] && (obj[item] = e[item]);
        });
        setSelectObj({...obj});
      }
      if (!props.hasNotDefaultValue) {
        form.setFieldsValue({...e, "startTime-endTime": ""});
      }
    };

    // 获取表单值
    const getFormInfo = () => {
      return form.getFieldsValue();
    };

    // 设置表单值
    const setFormInfo = (field: string, value: any) => {
      return form.setFieldValue(field, value);
    };

    useImperativeHandle(ref, () => ({
      showFilterView,
      handChangeForm,
      getFormInfo,
      setFormInfo
    }));

    // 获取按钮组宽度
    const getButtonBoxWidth = () => {
      if (props.searchList && props.searchList.length % 3 === 1) {
        return 16;
      } else if (props.searchList && props.searchList.length % 3 === 2) {
        return 8;
      } else if (props.searchList && props.searchList.length % 3 === 0) {
        return 24;
      }
    };

    const onFinish = (values: any) => {
      const data = {} as any;
      Object.keys(values).map((key) => {
        if (key.indexOf("-") !== -1 && values[key]) {
          const list = key.split("-");
          const a = values[key] as any[];
          a.map((item, index) => {
            if (index === 0) {
              data[list[index]] = `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : "0" + (item.$M + 1)
              }-${item.$D > 9 ? item.$D : "0" + item.$D} 00:00:00`;
            } else {
              data[list[index]] = `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : "0" + (item.$M + 1)
              }-${item.$D > 9 ? item.$D : "0" + item.$D} 23:59:59`;
            }
          });
        } else if (key === "createTime" && values[key]) {
          // 单日期
          const a = values[key];
          data[key] = `${a.$y}-${a.$M + 1 > 9 ? a.$M + 1 : "0" + (a.$M + 1)}-${a.$D > 9 ? a.$D : "0" + a.$D
          } 00:00:00`;
        } else {
          data[key] = values[key];
        }
      });
      if (props.finish) {
        const obj = JSON.parse(JSON.stringify({...data, ...selectObj}));
        if (obj["startTime-endTime"] !== undefined) {
          delete obj["startTime-endTime"];
        }
        props.finish(obj);
      }
    };

    // 日期筛选截至到当前日
    const disabledDate = (date: any) => {
      return date.valueOf() >= dayjs().valueOf();
    };

    return (
      <div style={{background: props.background || ''}} className={style["container"]}>
        <motion.div
          className={style["search-list"]}
          style={{height: 0, padding: "0 16px", overflow: "hidden"}}
          animate={{height: arrow ? "auto" : 0}}>
          <Form
            form={form}
            name="advanced_search"
            style={{width: "100%", marginTop: 16}}
            onFinish={onFinish}
            onFieldsChange={onFormChange}>
            <Row gutter={[32, 0]}>
              {props.searchList?.map((item, index) => (
                <Col span={8} key={index}>
                  <Form.Item
                    name={item.key}
                    label={item.title}
                    style={{marginBottom: "16px"}}>
                    {item.searchComponentType === "input" && (
                      <Input placeholder={item.placeHolder} autoComplete="off"></Input>
                    )}
                    {item.searchComponentType === "select" && (
                      <Select
                        placeholder={item.placeHolder}
                        mode={item.mode}
                        defaultValue={item.defaultValue}
                        options={item.options}/>
                    )}
                    {item.searchComponentType === "multipleSelect" && (
                      <Select
                        mode='multiple'
                        placeholder={item.placeHolder}
                        options={item.options}
                        defaultValue={item.defaultValue}
                        showSearch
                        optionFilterProp="label"
                        maxTagCount="responsive"
                        maxTagPlaceholder={(omittedValues) => (
                          <Tooltip title={omittedValues.map(({label}) => <p style={{margin: '6px 0'}}>{label}</p>)}>
                            <span>+ {omittedValues.length} ...</span>
                          </Tooltip>
                        )}
                      />
                    )}
                    {item.searchComponentType === "dateRangeTime" && (
                      <RangePicker
                        showTime={{format: 'HH:mm:ss'}}
                        format="YYYY-MM-DD HH:mm:ss"
                        style={{width: "100%"}}
                        disabledDate={disabledDate}
                      />
                    )}
                    {item.searchComponentType === "dateRange" && (
                      <RangePicker
                        style={{width: "100%"}}
                        disabledDate={disabledDate}
                      />
                    )}
                    {item.searchComponentType === "datePicker" && (
                      <DatePicker
                        style={{width: "100%"}}
                        disabledDate={disabledDate}
                        allowClear
                      />
                    )}
                    {item.searchComponentType === "selectInput" && (
                      <>
                        <Input
                          style={{width: "100%"}}
                          placeholder={item.placeHolder}
                          autoComplete="off"
                          addonBefore={
                            <Select
                              value={selectObj[item.selectInputKey || ""]}
                              options={item.options}
                              onChange={(e) => {
                                setSelectObj({
                                  ...selectObj,
                                  [item.selectInputKey || ""]: e,
                                });
                              }}
                              style={{width: "96px"}}
                              allowClear={false}
                            />
                          }
                        />
                      </>
                    )}
                  </Form.Item>
                </Col>
              ))}
              <Col span={getButtonBoxWidth()}>
                <div style={{textAlign: "right", marginBottom: 16}}>
                  <Space size="small">
                    <Button
                      onClick={() => {
                        handChangeForm(props.defaultFormValue);
                      }}>
                      重置
                    </Button>
                    <Button type="primary" htmlType="submit">
                      查询
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
          </Form>
        </motion.div>
      </div>
    );
  }
);

export default FilterView;
