import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import { Chart } from '@antv/g2';

// 饼图
export const LiquidChart = ({ chartId, chartData }: { chartId: string; chartData: any }) => {
  useEffect(() => {
    handSetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(chartData)]);

  const handSetChart = () => {
    console.log(222, chartData.memberNum / chartData.stockNum);
    const chart = new Chart({
      container: chartId,
      autoFit: true,
      padding: 0,
    });
    chart.liquid().style({
      contentText: ``,
      textFontSize: 24,
      textFill: '#000',
    });
    chart.data(chartData.memberNum / chartData.stockNum);

    chart.render();
  };

  return <div style={{ width: 200, height: 200 }} id={chartId} />;
};
