import { useState, useEffect, useRef } from 'react';
import ModuleTitle from '../../../component/ModuleTitle';
import styles from '../BusinessModel/index.module.scss';
import { BusinessColumnsType } from './type/data';
import { Table, Button, TableColumnsType, TableProps } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIconInput from '../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../component/FilterIcon/filterIcon';
import FilterView from '../../../component/FilterView/filterView';
import { getBusinessListApi } from '../../../api/userCenter/userCenter';
import BusinessAttribute from './components/BusinessAttribute';
import { getTableScroll } from '@/store/index'

type FieldType = {
  tableNameOrShowName?: string;
  createStartTime?: string;
  createEndTime?: string;
  updateStartTime?: string;
  updateEndTime?: string;
};

const defaultFormValue = {
  tableNameOrShowName: '',
  createStartTime: '',
  createEndTime: '',
  updateStartTime: '',
  updateEndTime: '',
};
const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};
const BusinessModel = () => {
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const filterViewRef: any = useRef(null);
  const navigate = useNavigate();
  const [businessList, setBusinessList] = useState([]);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const [scrollY, setScrollY] = useState("")
  // 查询入参
  let searchListData: any = {};
  const getBusinessList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getBusinessListApi({
      ...searchInfo,
      pageNum,
      pageSize,
      modelType: 'BUSINESS',
    });
    if (res?.code === 200) {
      setBusinessList(res.data.rows);
      setScrollY(getTableScroll())
    }
  };
  useEffect(() => {
    getBusinessList();
  }, [JSON.stringify(searchInfo)]);
  const columns: TableColumnsType<BusinessColumnsType> = [
    {
      title: '序号',
      key: 'id',
      dataIndex: 'id',
      width: '88px',
      render: (_, record, index) => <div>{index + 1}</div>,
    },
    {
      title: '表名',
      key: 'tableName',
      dataIndex: 'tableName',
      width: '200px',
      render: (text: string, record: BusinessColumnsType) => (
        <div
          className={styles.tableName}
          onClick={() => {
            toAttributePage(record);
          }}>
          {text}
        </div>
      ),
    },
    {
      title: '展示名',
      key: 'tableNameCn',
      dataIndex: 'tableNameCn',
      width: '200px',
    },
    {
      title: '描述',
      key: 'tableDescribe',
      dataIndex: 'tableDescribe',
      width: '250px',
    },
    {
      title: '创建人',
      key: 'createBy',
      dataIndex: 'createBy',
      width: '200px',
    },
    {
      title: '创建时间',
      key: 'createTime',
      dataIndex: 'createTime',
      width: '200px',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime(),
    },
    {
      title: '更新时间',
      key: 'updateTime',
      dataIndex: 'updateTime',
      width: '200px',
      sorter: (a, b) => new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime(),
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_: any, record: BusinessColumnsType) => (
        <Button
          type="link"
          size="middle"
          style={{ padding: 0 }}
          onClick={() => {
            toAttributePage(record);
          }}>
          查看
        </Button>
      ),
    },
  ];
  // 搜索条件列表
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入表明或展示名',
      title: '表名',
      key: 'tableNameOrShowName',
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '创建时间',
      key: 'createStartTime-createEndTime',
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '更新时间',
      key: 'updateStartTime-updateEndTime',
    },
  ];
  const handelTableChange = () => {};
  const [recordProp, setRecordProp] = useState<any>();
  const toAttributePage = (record: any) => {
    setRecordProp(record);
    setIsShowAttribute(true);
  };

  const [isShowAttribute, setIsShowAttribute] = useState(false);
  if (isShowAttribute) {
    return <BusinessAttribute recordProp={recordProp} handBackToList={() => setIsShowAttribute(false)} />;
  }
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  // 查询
  const filterFinish = (data: any) => {
    setSearchInfo({ ...data });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };
  const getHeight = (e: string) => {
    setScrollY(e)
  }

  return (
    <div>
      <div className={styles.headBox}>
        <ModuleTitle mark="large" title="业务模型" />
        <div className={styles.searchBox}>
          <SearchIconInput
            placeholder="请输入表名称或展示名"
            onSearch={(e: string) => {
              setSearchInfo({
                ...searchInfo,
                tableNameOrShowName: e,
              });
            }}></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
      <div className={styles.businessModel}>
        <FilterView searchList={searchList} getHeight={getHeight} ref={filterViewRef} finish={filterFinish}></FilterView>
        <div className={styles.tableBox}>
          <Table  rowKey={record => record.id} columns={columns} scroll={{ x: 1400, y: scrollY }} dataSource={businessList} onChange={handelTableChange} />
        </div>
      </div>
    </div>
  );
};
export default BusinessModel;
