import { useState, useEffect } from "react";
import { Table } from "antd";
import { getTableScroll } from "@/store/index";
import closeFullScreenPic from "@/pages/LuZhiBI/assets/images/closeFullScreen.png";
import fullScreenPic from "@/pages/LuZhiBI/assets/images/fullScreen.png";
import styles from "./index.module.scss";
function TableStyle(props: any) {
  const { dataSource } = props;
  const dimensions = dataSource.dimension; // 全部维度
  const dimensionKeys = dataSource.dimensionKey; // 全部维度的key
  const metrics = dataSource.metric; // 全部指标
  const metricsKeys = dataSource.metricKey; // 全部指标的key
  const originalDimensions = dataSource.origin.dimension;
  const originalMetrics = dataSource.origin.metric;
  let originalValue = dataSource.value;
  // 回答答案是否全屏
  const [fullScreen, setFullScreen] = useState(false);
  // 回答结果表格
  const [answerColumns, setAnswerColumns] = useState<any>([]);

  // 回答结果-列表数据源
  const [answerTableDataSource, setAnswerTableDataSource] =
    useState<any>(originalValue);

  useEffect(() => {
    setAnswerTableDataSource(originalValue);
    let columnsArr: {
      title: unknown;
      dataIndex: string;
      key: string;
      width: number;
    }[] = [];
    if (originalValue && originalValue.length > 0) {
      const tableKeys = Object.keys(originalValue[0]);
      let titleFinal: unknown  = '';
      tableKeys.forEach((key: string, index: number) => {
        // 寻找表头名称，情况有多种
        // 1、key和维度metric的key相同，title直接取metric的value，无需处理数据源
        // 2、key和维度metric的value相同，title直接取metric的value，无需处理数据源
        // 3、key和维度metric的key和value都不同，title直接取metric的value，需要处理数据源，数据源的key为metric的value
        if (key === metricsKeys[index] || key === dimensionKeys[index] || key === Object.values(dimensions)[index] || key === Object.values(metrics)[index]) {
          titleFinal = key;
        } else {
          titleFinal = Object.values(dimensions).length > 0 ? Object.values(dimensions)[index] : Object.values(metrics)[index];
          
        }
        columnsArr.push({
          title: key,
          dataIndex: key,
          key: key + index,
          width: 250,
        });
      });
      // originalValue
    }
    setAnswerColumns(columnsArr);
  }, [dataSource]);
  return (
    <div>
      <div className={styles.fullScreen}>
        <img src={fullScreenPic} onClick={() => setFullScreen(true)} />
      </div>
      <div>
        <Table
          columns={answerColumns}
          dataSource={originalValue}
          rowKey={(record) => record.key}
          scroll={{ x: 750, y: 1000 }}
          pagination={{ size: "small" }}
        />
      </div>
      {fullScreen && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <span className={styles.close} onClick={() => setFullScreen(false)}>
              <img src={closeFullScreenPic} />
            </span>
            <Table
              columns={answerColumns}
              dataSource={answerTableDataSource}
              rowKey={(record) => record.key}
              scroll={{ x: 750, y: getTableScroll() }}
              pagination={{ size: "small" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TableStyle;
