import { Button, Statistic } from 'antd';
import { useEffect } from "react";

const { Countdown } = Statistic;
const ButtonCountdown = (props: {
  deadline?: number,
  color?: string,
  size?: string,
  finishFun?: Function
}) => {
    const finishMonitor = () => {
      if (props.finishFun) {
        props.finishFun()
    }
    }
    useEffect(() => {
    }, [])

    return (
      <Button disabled type="link" className='lz_login-get-code-text'>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Countdown valueStyle={{ color: props.color, fontSize: props.size }} title="" value={props.deadline} format="ss" onFinish={finishMonitor} />
            <div style={{marginLeft: '2px',fontSize:'var(--text-font5)'}}>S后可重发</div> 
        </div>
      </Button>
  );

}

export default ButtonCountdown