import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Tooltip
} from "antd";
import type { TableProps } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import ModuleTitle from "../../../../../component/ModuleTitle";
import FilterView from "../../../../../component/FilterView/filterView";
import { getTableScroll } from '@/store/index'
import dayjs, { Dayjs } from 'dayjs';
import {
  getOfficialAccount,
} from "../../../../../api/publicAccountRecord/publicAccountRecord";
import styles from "./index.module.scss";
const GroupUserInsight = () => {
  const filterViewRef: any = useRef(null);
  //公众号记录状分类
  const [messageTypeList, setMessageTypeList] = useState([
    {
      label:'下单成功通知',
      value:'1',
    },
    // {
    //   label:'全部',
    //   value:'',
    // },
    // {
    //   label:'订单发货提醒',
    //   value:'1',
    // },
    // {
    //   label:'订单已送达通知',
    //   value:'2',
    // },
    // {
    //   label:'退款成功通知',
    //   value:'3',
    // },
  ] as any)
  //公众号记录发送状态
  const [statusList, setStatusList] = useState([
    {
      label: '发送成功',
      value: 1,
    },
    {
      label: '发送失败',
      value: 0,
    },
  ] as any)
  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入",
      title: "手机号",
      key: "phone",
    },
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入",
      title: "OneID",
      key: "oneId",
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "发送时间",
      showTime:true,
      format:'YYYY/MM/DD HH',
      key: "beginTime-endTime",
    },
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "发送状态",
      key: "sendStatus",
      options: statusList,
    },
    
    
  ];

  interface SearcParamType {
    beginTime: string;
    endTime: string;
    phone: any;
    messageType: any;
    oneId: any;
    orderNo: any;
  }

  const searcParamInt: SearcParamType = {
    beginTime: "",
    endTime: "",
    phone:null,
    messageType:null, 
    oneId: null, 
    orderNo:null, 
  };
  //过滤器
  const [iconColor, setIconColor] = useState('var(--text-font-color4)');
  const handleMouseEnter = () => {
    // console.log('鼠标移入')
    setIconColor('var(--text-font-color9)');
  };
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setIconColor('var(--text-font-color4)');
  };
  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  // 查询
  const filterFinish = (data: any) => {
    setSearcParam(data);
    getLogListData({ pageSize: 10, pageNum: 1 }, data, 

      // {
      //   orderByColumn: "createTime",
      //   isAsc: "desc",
      // }
    );
  };

  const [listData, setListData] = useState([] as any);

  const [resetConfirm, setResetConfirm] = useState(false);

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
  };




  const columns: TableProps<any>["columns"] = [
    {
      title: "发送时间",
      dataIndex: "sendTime",
      key: "sendTime",
      width: 200,
      render: (data: any, record: any) => 
        <div>{data??'--'}</div>
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
      width: 200,
      render: (data: any, record: any) => 
        <div>{data??'--'}</div>
    },
    {
      title: "OneID",
      dataIndex: "oneId",
      key: "oneId",
      width: 300,
      render: (data: any, record: any) => 
        <div>{data??'--'}</div>
    },
    {
      title: "发送内容",
      dataIndex: "sendMessage",
      key: "sendMessage",
      render: (text: any, record: any) => 
        text ? <div dangerouslySetInnerHTML={{ __html: text }}></div>: '--'
    },
    {
      title: "发送状态",
      dataIndex: "sendStatus",
      key: "sendStatus",
      width: 200,
      render: (_: any, record: any) => (
        <div>{ _ == '1' ?'发送成功':'发送失败'}</div>
      )
    },
  ];

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "updateTime",
    isAsc: "desc",
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }

    getLogListData(
      { pageSize: pagination.pageSize, pageNum: pagination.current },
      searcParam,
      // sortStore
    );
  };
  const [scrollY, setScrollY] = useState("")
  const getHeight = (e: string) => {
    console.log(e);
    setScrollY(e)
  }
  // 获取公众号查询列表
  const getLogListData = async (
    page: { pageSize: number; pageNum: number },
    data: any,
    // sort: { orderByColumn: string; isAsc: string }
  ) => {
    data.beginTime = data.beginTime ? dayjs(data.beginTime).format('YYYY/MM/DD HH:mm:ss').replace(/\//g,'-') : null
    // data.beginTime = data.beginTime ? Date.parse(data.beginTime): null
    data.endTime = data.endTime ? dayjs(data.endTime).format('YYYY/MM/DD HH:mm:ss').replace(/\//g,'-') : null
    // data.endTime = data.endTime ? Date.parse(data.endTime) : null
    const param = {
      ...data,
      ...page,
    } as any;
    const res = await getOfficialAccount(param);
    if (res) {
      setListData(res.rows);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.total,
      });
    }

    setScrollY(getTableScroll())
  };



  useEffect(() => {
    getLogListData(
      { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
      searcParam,
      // sortValue
    );
    document.addEventListener("click", hidden);

    return () => {
      document.removeEventListener("click", hidden);
    };
  }, []);

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="公众号记录查询" />
        </div>
        <div className={styles.searchBox}>
          <Tooltip placement="bottom" title={'过滤器'}>
            <Button
              onClick={() => filterViewRef.current?.showFilterView()}
              icon={
                <FilterOutlined
                  style={{
                    color: iconColor,
                    fontSize: 'var(--text-font7)',
                  }}
                />
              }
              type="text"
              className="click-element"
              style={{ marginRight: '10px' }}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
          </Tooltip>
        </div>
      </div>
      <div className={styles.GroupUserInsight}>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          getHeight={getHeight}
          dateRangeTime = {true}
        ></FilterView>
        <div>
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={listData}
            scroll={{ x: 750, y: scrollY }}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              current: pageQuery.pageNum,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupUserInsight;
