import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts';

const PieChart = ({ chartId, chartValue }: { chartId: string, chartValue: any }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const option = {
      tooltip: {
        trigger: 'item'
      },
      color: ['#3BA0FF', '#40CECE', '#56CE7A', '#F6BD16', '#6DC8EC'],
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['30%', '60%'],
          data: chartValue,
          label: {
            alignTo: 'edge',
            minMargin: 5,
            edgeDistance: 30,
            show: true,
            // formatter: '{name|{b}}\n{value|{c} 人}',
          //   normal: {
          //     formatter: function(params: any) {
          //         // 使用 \u2003 表示一个空格，\u25CF 表示圆点记号
          //         return '\u25CF ' + params.name + ' /n ' + params.value;
          //     }
          // },
          formatter(params: any) {
            return '{name|'+params.name+'}\n{value|'+params.value.toLocaleString()+'人}';
          },
             position: 'outside',
             rich: {
              name: {
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: 12,
                lineHeight: 20,
                height: 20,
              },
              value: {
                color: 'rgba(0, 0, 0, 0.45)',
                fontSize: 12,
              }
            },
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
  useEffect(() => {
    let chartDom = document.getElementById(chartId);
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
      myChart.resize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
        myChart.resize();
      });
    };

  }, [JSON.stringify(option), windowWidth])
  return <div id={chartId} style={{width: '400px', height: '300px'}} />;
}

export default PieChart