import React, { useEffect, useState } from 'react';
import { Drawer, Input, Button, Tooltip, Pagination } from 'antd';
import {
  SyncOutlined,
  StopOutlined,
  AudioOutlined
} from '@ant-design/icons'
import { DownloadCenterListQuery, DownloadCenterListVO } from "../../../../../api/resource/types";
import { queryPageListApi } from '@/api/taskUpload/task'
import type { SearchProps } from 'antd/es/input/Search';
import type { PaginationProps } from 'antd';
import styles from "./index.module.scss";
import { download } from '@/api/network';

const { Search } = Input;

const DownLoadCenter = (props: { downLoadCenterOpen: boolean, tabKey: string }) => {

  const { downLoadCenterOpen, tabKey } = props;

  const [taskLocation, setTaskLocation] = useState("");

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    setTaskLocation(value);
    getDownloadCenterList({ pageSize: 10, pageNum: 1 }, value);
  };

  const [downloadCenterList, setDownloadCenterList] = useState([] as DownloadCenterListVO[]);

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange: PaginationProps['onShowSizeChange'] = (current: any, pageSize: any) => {
    getDownloadCenterList({ pageSize: pageSize, pageNum: current }, taskLocation);
  };

  const getDownloadCenterList = async (page: { pageNum: number, pageSize: number }, taskLocation: string) => {
    const param: any = {
      type: 1,
      pageNum: page.pageNum,
      pageSize: page.pageSize,
      taskLocation
    };
    const res = await queryPageListApi(param);

    setDownloadCenterList(res.data.rows);

    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: res.data.total,
    });
  };

  const getDownloadUrl = async (val: DownloadCenterListVO) => {
    // const { data } = await downloadApi(val.id);
    // const link = document.createElement('a');
    //   link.href = data;
    //   link.download = `${val.taskName}.xlsx`;
    //   link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
    //   link.click();
    download(`/resource/downloadCenter/${val.id}/download`)
    // handleDownload(`/resource/downloadCenter/${val.id}/download`)
  }

  useEffect(() => {
    if (downLoadCenterOpen && tabKey === '1') {
      getDownloadCenterList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, taskLocation);
    } else {
      setTaskLocation('');
      setDownloadCenterList([]);
    }
  }, [downLoadCenterOpen, tabKey])
  // useEffect(() => {
  //   if (props.tabKey === '1') {
  //     getDownloadCenterList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, taskLocation);
  //   }
  // }, [tabKey])

  return (
    <div>
      <div className={styles.searchBox}>
        <Search
          placeholder="请输入查询的页面位置"
          allowClear
          onSearch={onSearch}
          style={{ width: '100%' }}
        />
      </div>
      {
        downloadCenterList.map((val: DownloadCenterListVO, idx: number) => {
          return (
            <div className={styles.listItemBox}>
              <div className={styles.headerBox}>
                <Tooltip title={val.taskName}>
                  <div className={styles.fileName}>{val.taskName}</div>
                </Tooltip>
                <div className={styles.operateBox}>
                  {val.status === 1 && <div className={styles.operate1}><SyncOutlined style={{ marginRight: 9 }} />计算中</div>}
                  {val.status === 2 && <Button type="link" disabled={!val.expireValid} onClick={() => getDownloadUrl(val)}>下载</Button>}
                  {val.status === 3 &&
                    <Tooltip title="若多次创建下载失败请联系客服。">
                      <div className={styles.operate2}><StopOutlined style={{ marginRight: 9 }} />计算失败</div>
                    </Tooltip>
                  }
                </div>
              </div>
              <div className={styles.bodyBox}>
                <div>页面位置：{val.taskLocation}</div>
                <div>创建时间：{val.createTime}</div>
                <div style={{ marginBottom: 0 }}>创建人：{val.createUser}</div>
              </div>
            </div>
          )
        })
      }
      {
        downloadCenterList.length === 0 && <div className={styles.noData}>暂无数据</div>
      }
      {
        downloadCenterList.length > 0 &&
        <Pagination
          className={styles.paginationSimple}
          simple
          current={pageQuery.pageNum}
          size="small"
          total={pageQuery.total}
          style={{ textAlign: 'end' }}
          showTotal={(total) => `共 ${total} 条`}
          showSizeChanger={false}
          onChange={handleTableChange}
        />
      }
    </div>
  )
}

export default DownLoadCenter;