import { useState, useEffect, useRef } from 'react';
import ModuleTitle from '../../../../component/ModuleTitle';
import styles from '../BusinessAttribute/index.module.scss';
import { Table, Button, TableColumnsType, TableProps, Drawer } from 'antd';
import BackList from '../../../../component/BackList/backList';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import FilterView from '../../../../component/FilterView/filterView';
import DrawerTable from '../../components/DrawerTable/drawerTable';

const BusinessAttribute = () => {
  const filterViewRef: any = useRef(null);
  const [businessList, setBusinessList] = useState([] as any[]);
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  // 查询入参
  let searchListData: any = {};
  // 搜索条件列表
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入表明或展示名',
      title: '表名',
      key: 'tableNameOrShowName',
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '创建时间',
      key: 'createStartTime-createEndTime',
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '更新时间',
      key: 'updateStartTime-updateEndTime',
    },
  ];
  const columns: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'index',
      dataIndex: 'index',
    },
    {
      title: '业务属性',
      key: 'name',
      dataIndex: 'name',
      // render: (text: string, record: BusinessColumnsType) => <div className={styles.tableName} onClick={() => {
      //   IntegrationTable()
      // }}>{text}</div>,
    },
    {
      title: '展示名',
      key: 'showName',
      dataIndex: 'showName',
    },
    {
      title: '描述',
      key: 'des',
      dataIndex: 'des',
    },
    {
      title: '数据类型',
      key: 'des',
      dataIndex: 'des',
    },
    {
      title: '创建人',
      key: 'creater',
      dataIndex: 'creater',
    },
    {
      title: '创建时间',
      key: 'createTime',
      dataIndex: 'createTime',
      sorter: true,
    },
    {
      title: '更新时间',
      key: 'updateTime',
      dataIndex: 'updateTime',
      sorter: true,
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_: any, record: any) => (
        <Button type="link" size="middle" onClick={openDrawer}>
          查看
        </Button>
      ),
    },
  ];

  const handelTableChange = () => {};

  const onCloseDrawer = () => {
    setIsShowDrawer(false);
  };
  const openDrawer = () => {
    setIsShowDrawer(true);
  };

  const getUserData = () => {};

  const filterFinish = () => {};

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  useEffect(() => {
    setBusinessList([
      {
        key: 1,
        index: 1,
        name: 'Order',
        showName: '订单表',
        des: '维护订单表',
        creater: '系统创建',
        createTime: '2013-10-09 23:19:00',
        updateTime: '2013-10-09 23:19:00',
      },
      {
        key: 2,
        index: 2,
        name: 'Order_goods',
        showName: '订单商品表',
        des: '维护订单商品表',
        creater: '系统创建',
        createTime: '2013-10-09 23:19:00',
        updateTime: '2013-10-09 23:19:00',
      },
      {
        key: 3,
        index: 3,
        name: 'Refound',
        showName: '退单表',
        des: '维护退单表',
        creater: '系统创建',
        createTime: '2013-10-09 23:19:00',
        updateTime: '2013-10-09 23:19:00',
      },
    ]);
  }, []);

  return (
    <div className={styles.businessModel}>
      <div className={styles.headBox}>
        <div style={{ display: 'flex' }}>
          <BackList />
          <div style={{ marginTop: '-3px' }}>
            <ModuleTitle mark="large" title="Order订单表" />
            <div style={{ color: 'var(--text-font-color4)' }}>描述：维护订单属性</div>
          </div>
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            placeholder="请输入表名称或展示名"
            onSearch={(e: string) => {
              searchListData.crowdNameAndId = e;
              console.log(e, 'eeee');
              getUserData();
              filterViewRef.current?.resetData();
            }}></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
      <FilterView searchList={searchList} ref={filterViewRef} finish={filterFinish}></FilterView>
      <div className={styles.tableBox}>
        <Table columns={columns} dataSource={businessList} onChange={handelTableChange} />
      </div>
      {/* <DrawerTable isShowDrawer={isShowDrawer} onCloseDrawer={onCloseDrawer} inputText={'请输入表名或展示名'} /> */}
    </div>
  );
};

export default BusinessAttribute;
