import { useContext } from "react";
import style from "./index.module.scss";
import { Radio, Table, Switch, Select, InputNumber } from "antd";
import {
  PageTitle,
  PageItem,
  CardDatePicker,
  CardSelect,
  TextWithQuestion,
} from "../../../../../../component/MiniStyles";

import { MyContext } from "../../index";

// 积分有效期下拉框
const pointValidityList = [
  { label: "永久有效", value: 1 },
  { label: "固定时间", value: 2 },
  { label: "相对时间", value: 3 },
];

// 时间选择下拉
const expireYearOptions = [
  { value: 0, label: "每" },
  { value: 1, label: "每隔1" },
  { value: 2, label: "每隔2" },
  { value: 3, label: "每隔3" },
  { value: 4, label: "每隔4" },
  { value: 5, label: "每隔5" },
  { value: 6, label: "每隔6" },
  { value: 7, label: "每隔7" },
  { value: 8, label: "每隔8" },
  { value: 9, label: "每隔9" },
  { value: 10, label: "每隔10" },
];

const PageThree = ({
  readOnly = false,
}: {
  readOnly?: boolean | undefined;
}) => {
  const {
    data: pageInfo,
    pageStatus,
    myContextChange,
    myContextChangeObj,
    myContextChangeArr,
    myContextChangeClearPointTime,
  } = useContext(MyContext);
  const { pointFlag, pointComputeConfig, gradeList, allowPointNe } = pageInfo;
  const {
    computeType,
    sendPoint,
    pointExpirateRule,
    refundPointFlag,
    tradePointExpirateType,
    tradePointRegular,
    tradePointRelativeYear,
    tradePointRelativeDate,
    nonTradePointExpirateType,
    nonTradePointRegular,
    nonTradePointRelativeYear,
    nonTradePointRelativeDate,
  } = pointComputeConfig;

  const updateStatus = pageStatus === "update";
  // 积分有效期
  const getPointValidity = (type: number) => {
    // 下拉框的值
    const selectValue =
      type === 2 ? nonTradePointExpirateType : tradePointExpirateType;
    // 固定的天
    const retular = type === 2 ? nonTradePointRegular : tradePointRegular;
    // 什么日子
    const expireDate =
      type === 2 ? nonTradePointRelativeDate : tradePointRelativeDate;
    // 多少年后
    const expireYear =
      type === 2 ? nonTradePointRelativeYear : tradePointRelativeYear;
    const month = expireDate?.split("-")[0] || "--";
    const day = expireDate?.split("-")[1] || "--";

    // 更改值
    const handChange = (name: string, value: any) => {
      const valueName = type === 2 ? `nonT${name}` : `t${name}`;
      if (type === 3) {
        myContextChangeObj(
          "pointComputeConfig",
          `t${name}`,
          value,
          `nonT${name}`,
          value
        );
      } else {
        myContextChangeObj("pointComputeConfig", valueName, value);
      }
    };

    return (
      <div>
        <div style={{ display: "flex" }}>
          <Select
            options={pointValidityList}
            className={style["input-short"]}
            style={{ width: "100px" }}
            disabled={readOnly}
            value={selectValue}
            onChange={(e) => handChange("radePointExpirateType", e)}
          />
          {/* 固定时间 */}
          {selectValue === 2 && (
            <span>
              发放后
              <InputNumber
                min={0}
                max={730}
                maxLength={5}
                precision={0}
                controls={false}
                className={style["input-short"]}
                disabled={readOnly}
                value={retular || ""}
                onChange={(e) => handChange("radePointRegular", e)}
              />
              日
            </span>
          )}
          {/* 相对时间 */}
          {selectValue === 3 && (
            <div>
              <CardSelect
                options={expireYearOptions}
                disabled={readOnly}
                value={expireYear}
                onChange={(e) => handChange("radePointRelativeYear", e)}
              />
              年{expireYear === 0 ? "" : "的"}
              <CardDatePicker
                expireYear={expireYear}
                expireDate={expireDate}
                disabled={readOnly}
                onChange={(e) => handChange("radePointRelativeDate", e)}
              />
              内有效
            </div>
          )}
        </div>

        {/* 提示 */}
        {selectValue === 3 && type === 3 && (
          <div className={style["tips"]} style={{ margin: "12px 0" }}>
            <div>
              每笔积分在
              {expireYear === null
                ? "--"
                : expireYear === 0
                ? "今"
                : expireYear}
              年{expireYear === 0 ? "" : "后"}的{month}月{day}
              日内有效。
            </div>
            <div>
              举例：2024年1月1日~
              {expireYear === 0
                ? `${expireYear + 2024}年${month}月${day}日`
                : "2024年12月31日"}
              获得的积分，在{(Math.floor(expireYear) || 0) + 2024}年{month}月
              {day}
              日有效。
            </div>
          </div>
        )}
      </div>
    );
  };

  // 积分规则设置
  const getPointRulesSetting = () => {
    return (
      <div className={style["section-bgd"]}>
        <PageTitle>积分规则设置</PageTitle>
        <div className={style["section-content-bgd"]}>
          <div style={{ width: "538px" }}>
            <PageItem title="积分是否可降为负值">
              <Radio.Group
                disabled={readOnly || updateStatus}
                value={allowPointNe}
                style={{ marginTop: "5px" }}
                onChange={(e) =>
                  myContextChange("allowPointNe", e.target.value)
                }>
                <Radio value={1}>
                  <TextWithQuestion
                    title="可降为负值"
                    tips="客户积分扣减后，可为负数"
                    black
                  />
                </Radio>
                <Radio value={2}>
                  <TextWithQuestion
                    title="不可降为负值"
                    tips="客户最低积分为0"
                    black
                  />
                </Radio>
              </Radio.Group>
            </PageItem>
            <PageItem
              title="是否开启交易积分"
              tips="客户是否可以通过购买商品获得积分">
              <Switch
                disabled={readOnly}
                checkedChildren="开"
                unCheckedChildren="关"
                value={pointFlag === 1}
                onChange={(e) => myContextChange("pointFlag", e ? 1 : 0)}
                style={{ marginTop: "5px" }}
              />
            </PageItem>
          </div>
          {pointFlag === 1 && (
            <>
              <PageItem title="京东订单是否陆泽计算">
                <Radio.Group
                  disabled={readOnly || updateStatus}
                  value={computeType}
                  style={{ marginTop: "5px" }}
                  onChange={(e) =>
                    myContextChangeObj(
                      "pointComputeConfig",
                      "computeType",
                      e.target.value
                    )
                  }>
                  <Radio value={1}>
                    <TextWithQuestion
                      title="陆泽计算"
                      tips="陆泽将按照京东价计算交易积分"
                      black
                    />
                  </Radio>
                  <Radio value={2}>
                    <TextWithQuestion
                      title="京东平台计算"
                      tips="京东积分由京东计算，陆泽不做处理"
                      black
                    />
                  </Radio>
                </Radio.Group>
              </PageItem>
              <PageItem title="交易积分发放">
                <InputNumber
                  className={style["input-short"]}
                  min={1}
                  max={999999999.99}
                  precision={2}
                  controls={false}
                  disabled={readOnly}
                  value={sendPoint || ""}
                  style={{ width: "110px" }}
                  onChange={(e) =>
                    myContextChangeObj("pointComputeConfig", "sendPoint", e)
                  }
                />
                元 = 1积分
              </PageItem>
              <PageItem title="积分有效期规则">
                <Radio.Group
                  style={{ marginTop: "5px" }}
                  disabled={readOnly}
                  value={pointExpirateRule}
                  onChange={(e) =>
                    myContextChangeClearPointTime(e.target.value)
                  }>
                  <Radio value={1}>统一设置</Radio>
                  <Radio value={2}>按交易积分/非交易积分分别设置</Radio>
                </Radio.Group>
              </PageItem>
              <PageItem title="积分有效期">
                {pointExpirateRule === 1 ? (
                  getPointValidity(3)
                ) : (
                  <>
                    <div
                      className={style["align-center"]}
                      style={{ marginBottom: "12px" }}>
                      交易积分：
                      {getPointValidity(1)}
                    </div>
                    <div className={style["align-center"]}>
                      非交易积分：
                      {getPointValidity(2)}
                    </div>
                  </>
                )}
              </PageItem>
              <PageItem title="积分发放时间点">
                <Radio checked disabled={readOnly} style={{ marginTop: "5px" }}>
                  统一设置
                </Radio>
              </PageItem>
              <PageItem title="退单积分扣除">
                <Radio.Group
                  disabled={readOnly}
                  value={refundPointFlag}
                  style={{ marginTop: "5px" }}
                  onChange={(e) =>
                    myContextChangeObj(
                      "pointComputeConfig",
                      "refundPointFlag",
                      e.target.value
                    )
                  }>
                  <Radio value={1}>扣除</Radio>
                  <Radio value={2}>不扣除</Radio>
                </Radio.Group>
              </PageItem>
            </>
          )}
        </div>
      </div>
    );
  };

  // 表格部分
  const getTableSection = () => {
    return (
      <div style={{ marginBottom: "24px" }}>
        <Table
          dataSource={gradeList}
          style={{ width: "825px", marginBottom: "16px" }}
          pagination={false}>
          <Table.Column
            title="级别"
            dataIndex="gradeCode"
            render={(value) => <span>VIP{value}</span>}
          />
          <Table.Column
            title="等级名称"
            dataIndex="gradeName"
            render={(value) => value}
          />
          <Table.Column
            title="交易积分倍数"
            dataIndex="pointRate"
            render={(value, record, index) => (
              <div>
                ×
                <InputNumber
                  className={style["input-short"]}
                  min={1}
                  max={99.9}
                  precision={1}
                  controls={false}
                  disabled={readOnly}
                  value={value || ""}
                  onChange={(e) =>
                    myContextChangeArr("gradeList", index, "pointRate", e)
                  }
                />
              </div>
            )}
          />
        </Table>
      </div>
    );
  };

  return (
    <div className={style["memberCard-create-pageThree"]}>
      {/* 积分规则设置 */}
      {getPointRulesSetting()}
      {/* 表格 */}
      {pointFlag === 1 && getTableSection()}
    </div>
  );
};

export default PageThree;
