//外观配置
import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import type { TabsProps, UploadProps, UploadFile } from 'antd';
import { Form, Tabs, Button, Input, Switch, message, Upload, Image } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import ModuleTitle from '../../../../component/ModuleTitle';
import ButtonPermissions from '../../../../component/Button/SystemButton';

import { getSysVersionAppearanceViewApi, getSysVersionAppearanceUpdateApi } from '../../../../api/userIntersection/userIntersection';
import ImageUpload from './components/ImageUpload';
import PreviewPopup from './components/PreviewPopup';
import busEvent from '../../../../utils/bus'

type FieldType = {
  loginTitle: string;
  brandStatus: string;
  recordStatus: string;
  recordCode: string;
  loginLogo: string;
  backgroundPic: string;
  groundTitle: string;
  groundLogo: string;
};

function AppearanceConfiguration() {
  useEffect(() => {}, []);
  // 图片的文件名
  const [loginTitleFile, setLoginTitleFile] = useState<any>('');
  const [loginLogoFile, setLoginLogoFile] = useState<any>('');
  const [groundLogoFile, setGroundLogoFile] = useState<any>('');
  // 图片
  const [backgroundPic, setBackgroundPic] = useState<string>('');
  // 开关状态
  const [brandStatus, setBrandStatus] = useState<string>('');
  const [recordStatus, setRecordStatus] = useState<string>('');
  // tab的key
  const [type, setType] = useState('1');
  // 防连击
  const [loading, setLoading] = useState(false);
  // 存储所有数据
  const [allData, setAllData] = useState<any>({});
  // 表单
  const [loginForm] = Form.useForm();
  // 预览弹窗
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [previewObj, setPreviewObj] = useState<any>({});

  // 子组件获取链接
  const getImageUrl = (url: string, index: number, name?: string) => {
    switch (index) {
      case 1:
        // 存进form
        loginForm.setFieldValue('loginTitle', url);
        setLoginTitleFile(name);
        break;
      case 2:
        loginForm.setFieldValue('loginLogo', url);
        setLoginLogoFile(name);
        break;
      case 3:
        loginForm.setFieldValue('backgroundPic', url);
        setBackgroundPic(url);
        break;
      case 4:
        loginForm.setFieldValue('groundLogo', url);
        setGroundLogoFile(name);
        break;

      default:
        break;
    }
  };
  // 方块上传
  const UploadImage = ({ index }: { index: number }) => (
    <div>
      {index === 3 && backgroundPic ? (
        <img
          src={backgroundPic}
          style={{
            width: 100,
            height: 60,
          }}
        />
      ) : (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button">
          <PlusOutlined style={{ color: 'var(--text-font-color28)' }} />
          <div style={{ color: 'var(--text-font-color28)' }}>上传</div>
        </button>
      )}
    </div>
  );

  // 按钮上传
  const UploadButton = ({ index }: { index: number }) => (
    <>
      <div>
        <Button icon={<UploadOutlined />}>上传图片</Button>
      </div>
      {index === 4 && groundLogoFile ? <div>{groundLogoFile}</div> : null}
      {index === 2 && loginLogoFile ? <div>{loginLogoFile}</div> : null}
      {index === 1 && loginTitleFile ? <div>{loginTitleFile}</div> : null}
    </>
  );
  // 校验
  const onFinish = (name?: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    loginForm
      .validateFields()
      .then((res: any) => {
        if (res.groundTitle === '') {
          message.warning('平台标题不能为空');
          setLoading(false);
          return;
        }
        if (name === 'preview') {
          setPreviewObj(res);
          handleShowPreview();
          setLoading(false);
        }
        if (name === 'submit') {
          handleSumbit(res);
          setLoading(false);
        }
      })
      .catch(errorInfo => {
        console.log(errorInfo);
        setLoading(false);
      });
  };

  // 确认保存
  const handleSumbit = async (res: any) => {
    console.log(res);
    const params = {
      title: res.loginTitle || '',
      brandStatus: res.brandStatus || '',
      recordStatus: res.recordStatus || '',
      record: res.recordCode || '',
      loginPic: res.loginLogo || '',
      background: res.backgroundPic || '',
      groundTitle: res.groundTitle || allData.groundTitle,
      logoPic: res.groundLogo || allData.groundLogo,
      loginTitleFile: loginTitleFile,
      loginLogoFile: loginLogoFile,
      groundLogoFile: groundLogoFile,
    };
    const result = await getSysVersionAppearanceUpdateApi(params);
    setLoading(false);
    if (result.code === 200) {
      message.success('保存成功');
      getSysVersionAppearance();
      busEvent.emit('styleStoreData')
    }
  };
  // 控制开关
  const onSwitchChange = (name: string, checked: boolean) => {
    let status = checked ? '1' : '0';
    if (name === 'brandStatus') {
      setBrandStatus(status);
    }
    if (name === 'recordStatus') {
      setRecordStatus(status);
    }
    loginForm.setFieldValue(name, status);
  };
  // 登录页tab元素
  const LoginPage = () => {
    return (
      <Form
        form={loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ maxWidth: 700 }}
        colon={false}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}>
        <Form.Item<FieldType> label="登录标题：" name="loginTitle" extra="提示：为使显示效果较佳，请选择尺寸不超过1400 x 400的图片，支持PNG、JPG等格式">
          <ImageUpload getImageUrl={getImageUrl} width={1400} height={400} isButton={true} imageIndex={1}>
            <UploadButton index={1} />
          </ImageUpload>
        </Form.Item>
        <Form.Item<FieldType>
          label="显示品牌信息："
          name="brandStatus"
          extra="提示：控制登录页底部是否显示云鹿品牌信息，品牌信息：技术支持·陆泽科技有限公司">
          <Switch
            checkedChildren="开"
            unCheckedChildren="关"
            checked={brandStatus === '1' ? true : false}
            onChange={e => {
              onSwitchChange('brandStatus', e);
            }}
          />
        </Form.Item>

        <Form.Item<FieldType> label="显示备案信息：" name="recordStatus" extra="提示：控制登录页底部是否显示备案信息">
          <Switch
            checkedChildren="开"
            unCheckedChildren="关"
            checked={recordStatus === '1' ? true : false}
            onChange={e => {
              onSwitchChange('recordStatus', e);
            }}
          />
        </Form.Item>

        {recordStatus === '1' && (
          <Form.Item<FieldType> label=" " name="recordCode">
            <Input autoComplete="off" placeholder="请输入域名信息对应的备案号" showCount  maxLength={50}/>
          </Form.Item>
        )}
        <Form.Item<FieldType> label="登录Logo：" name="loginLogo" extra="提示：为使显示效果较佳，请选择尺寸不超过309 x 96的图片，支持PNG、JPG等格式">
          <ImageUpload getImageUrl={getImageUrl} width={309} height={96} isButton={true} imageIndex={2}>
            <UploadButton index={2} />
          </ImageUpload>
        </Form.Item>
        <Form.Item<FieldType> label="背景图片：" name="backgroundPic" extra="提示：建议图片尺寸不超过 2880 x 1722支持PNG、JPG等格式">
          <ImageUpload getImageUrl={getImageUrl} width={2880} height={1722} isButton={false} imageIndex={3}>
            <UploadImage index={3} />
          </ImageUpload>
        </Form.Item>
      </Form>
    );
  };
  // 平台页tab元素
  const PlatformStyle = () => {
    return (
      <Form
        form={loginForm}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ maxWidth: 700 }}
        colon={false}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}>
        <Form.Item<FieldType> label="平台标题：" name="groundTitle" rules={[{ required: true, message: '请输入平台标题' }]}>
          <Input placeholder="请输入平台标题" autoComplete="off" maxLength={100} showCount/>
        </Form.Item>

        <Form.Item<FieldType> label="平台Logo：" name="groundLogo" extra="提示：为使显示效果较佳，请选择尺寸不超过54*42的图片，支持PNG、JPG等格式">
          <ImageUpload getImageUrl={getImageUrl} width={54} height={42} isButton={true} imageIndex={4}>
            <UploadButton index={4} />
          </ImageUpload>
        </Form.Item>
      </Form>
    );
  };
  // tab
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '登录页',
      children: <LoginPage />,
    },
    {
      key: '2',
      label: '平台样式',
      children: <PlatformStyle />,
    },
  ];
  const getSysVersionAppearance = async () => {
    const res = await getSysVersionAppearanceViewApi();
    console.log(res);
    if (res.code === 200) {
      setAllData(res.data);
      loginForm.setFieldsValue(res.data);
      publicFun(res.data);
    }
  };
  // 取消按钮
  const handleCancelButton = () => {
    loginForm.setFieldsValue(allData);
    publicFun(allData);
  };
  const publicFun = (data: any) => {
    setBackgroundPic(data.backgroundPic);
    setBrandStatus(data.brandStatus);
    setRecordStatus(data.recordStatus);
    setLoginTitleFile(data.loginTitleFile);
    setLoginLogoFile(data.loginLogoFile);
    setGroundLogoFile(data.groundLogoFile);
  };
  useEffect(() => {
    getSysVersionAppearance();
  }, []);
  const handleShowPreview = () => {
    setIsShowPreview(true);
  };
  return (
    <div>
      <div className={style.headBox}>
        <div className={style.titleBox}>
          <ModuleTitle mark="large" title="外观配置" />
        </div>
        <div className={style.searchBox}>
          <ButtonPermissions type="default" onClick={handleCancelButton} title={`取消`}></ButtonPermissions>
          <ButtonPermissions
            type="default"
            onClick={() => {
              onFinish('preview');
            }}
            title={`预览`}></ButtonPermissions>
          <ButtonPermissions
            type="primary"
            onClick={() => {
              onFinish('submit');
            }}
            htmlType="submit"
            title={`确认`}></ButtonPermissions>
        </div>
      </div>
      <div className={style['container']}>
        <Tabs
          onChange={e => {
            setType(e);
          }}
          activeKey={type}
          type="card"
          items={items}
          style={{ marginTop: 16 }}
        />
        {isShowPreview && <PreviewPopup handBackToList={() => setIsShowPreview(false)} type={type} previewObj={previewObj} />}
      </div>
    </div>
  );
}

export default AppearanceConfiguration;
