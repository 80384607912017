import React, { useEffect, useState } from "react";
import { Divider, Select, Space, Button, DatePicker, InputNumber, message } from "antd";
import type { DatePickerProps, InputNumberProps } from 'antd';
import { RightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import 'antd/dist/reset.css';

const { RangePicker } = DatePicker;

const optionValue = [
  { key: "1", label: "动态日期" },
  { key: "2", label: "静态日期" },
  { key: "3", label: "单个日期" },
];

// 日期
const dateItem2 = [
  { value: "1", label: "本日", timeValue: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')] },
  { value: "2", label: "本周", timeValue: [dayjs().add(dayjs().day() === 0 ? 0 - 1 : 0, 'week').startOf('week').add(1, 'day').format('YYYY-MM-DD'), dayjs().add(dayjs().day() === 0 ? 0 - 1 : 0, 'week').endOf('week').add(1, 'day').format('YYYY-MM-DD')] },
  { value: "3", label: "本月", timeValue: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')] }
];
const dateItem = [
  { value: "1", label: "本日", timeValue: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')] },
  { value: "2", label: "本周", timeValue: [dayjs().add(dayjs().day() === 0 ? 0 - 1 : 0, 'week').startOf('week').add(1, 'day').format('YYYY-MM-DD'), dayjs().add(dayjs().day() === 0 ? 0 - 1 : 0, 'week').endOf('week').add(1, 'day').format('YYYY-MM-DD')] },
  { value: "3", label: "本月", timeValue: [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().endOf('month').format('YYYY-MM-DD')] },
  { value: "4", label: "过去1日", timeValue: [dayjs().subtract(1, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "5", label: "过去3日", timeValue: [dayjs().subtract(3, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "6", label: "过去7日", timeValue: [dayjs().subtract(7, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "7", label: "过去14日", timeValue: [dayjs().subtract(14, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "8", label: "过去30日", timeValue: [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "9", label: "过去2个月", timeValue: [dayjs().startOf('month').subtract(2, 'month').format('YYYY-MM-DD'), dayjs().startOf('month').subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "10", label: "过去3个月", timeValue: [dayjs().startOf('month').subtract(3, 'month').format('YYYY-MM-DD'), dayjs().startOf('month').subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "11", label: "过去6个月", timeValue: [dayjs().startOf('month').subtract(6, 'month').format('YYYY-MM-DD'), dayjs().startOf('month').subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "12", label: "过去1年", timeValue: [dayjs().startOf('year').subtract(1, 'year').format('YYYY-MM-DD'), dayjs().startOf('year').subtract(1, 'day').format('YYYY-MM-DD')] },
  { value: "13", label: "过去2年", timeValue: [dayjs().startOf('year').subtract(2, 'year').format('YYYY-MM-DD'), dayjs().startOf('year').subtract(1, 'day').format('YYYY-MM-DD')] },
];

const customDateItem = [
  { value: "date", label: "日"},
  { value: "week", label: "周"},
  { value: "month", label: "月"},
  { value: "year", label: "年"},
];

const TimeSelect = (props: {
  width: string,
  time: any,
  custom: boolean,
  customVal: number,
  customCheck: string,
  operateType: string,
  timeValue: string,
  timeKey: string,
  timeStore: string,
  open: boolean;
  id: string;
  getTimeValue: Function;
}) => {

  const {width, operateType, timeValue, timeKey, timeStore, time, custom, customVal, customCheck, open, id, getTimeValue = () => {}} = props;

  const [items, setItems] = useState(optionValue);

  const [keyActive, setKeyActive] = useState("1");

  const [itemActive, setItemActive] = useState("1");

  const [isCustom, setIsCustom] = useState(false);

  const [customValue, setCustomValue] = useState(null as any);

  const [customChecked, setIsCustomChecked] = useState('date');

  const [dateValue, setDateValue] = useState(null as any);

  const [rangeValue, setRangeValue] = useState([] as any);

  const openTimeSelect = (e: any) => {
    if(operateType === 'view'){
      return;
    }
    if(open === false){
      // 如果组件是关闭的，就打开
      getTimeValue({
        timeValue, 
        paramValue: 
        time, timeKey, 
        id, 
        flag: true, 
        isCustom: custom, 
        customValue,
        customChecked
      });
    }
  };

  // 清除
  const clearAll = () => {
    getTimeValue({
      timeValue: null, 
      paramValue: null, 
      timeKey: '1', 
      id, 
      flag: false, 
      isCustom: false,
      customValue: null,
      customChecked: null
    });
  };

  // 取消
  const clearTime = () => {
    getTimeValue({
      timeValue, 
      paramValue: time, 
      timeKey: timeKey, 
      id, 
      flag: false, 
      isCustom: timeValue ? isCustom : false,
      customValue: timeValue ? customValue : null,
      customChecked: timeValue ? customChecked : null
    });
  };

  // 确定
  const chooseTime = () => {
    let timeValue : any = '';
    let paramValue : any = [];
    if(keyActive === '1'){
      if(isCustom){
        // 自定义
        if(!customValue){
          return message.warning('请输入自定义时间')
        } else {
          timeValue = `过去${customValue}${customDateItem.find((v: any, i: number) => v.value === customChecked)?.label}`;
          switch(customChecked){
            case 'date' : paramValue = [dayjs().subtract(Number(customValue), 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')];
            break;
            case 'week' : paramValue = [dayjs().startOf('week').add(1, 'day').subtract(Number(customValue), 'week').format('YYYY-MM-DD'), dayjs().add(dayjs().day() === 0 ? 0 - 1 : 0, 'week').startOf('week').format('YYYY-MM-DD')];
            break;
            case 'month' : paramValue = [dayjs().startOf('month').subtract(Number(customValue), 'month').format('YYYY-MM-DD'), dayjs().startOf('month').subtract(1, 'day').format('YYYY-MM-DD')];
            break;
            default: paramValue = [dayjs().startOf('year').subtract(Number(customValue), 'year').format('YYYY-MM-DD'), dayjs().startOf('year').subtract(1, 'day').format('YYYY-MM-DD')];
            break;
          }
        }
      } else {
        // 非自定义
        setCustomValue(null);
        setIsCustomChecked('date');
        timeValue = dateItem.find((v: any, i: number) => v.value === itemActive)?.label;
        paramValue = dateItem.find((v: any, i: number) => v.value === itemActive)?.timeValue;
      }
    } else if(keyActive === '2') {
      if (rangeValue && rangeValue.length > 0) {
        timeValue = dayjs(rangeValue[0]).format('YYYY-MM-DD') + '-' + dayjs(rangeValue[1]).format('YYYY-MM-DD');
        paramValue = [dayjs(rangeValue[0]).format('YYYY-MM-DD'), dayjs(rangeValue[1]).format('YYYY-MM-DD')];
      } else {
        return message.warning('请选择静态日期');
      }
    } else {
      if (dateValue) {
        timeValue = dayjs(dateValue).format('YYYY-MM-DD');
        paramValue = [dayjs(dateValue).format('YYYY-MM-DD'), dayjs(dateValue).format('YYYY-MM-DD')];
      } else {
        return message.warning('请选择单个日期');
      }
    }
    
    getTimeValue({
      timeValue, 
      paramValue, 
      timeKey: keyActive, 
      id, 
      flag: false, 
      isCustom,
      customValue,
      customChecked
    });
  };

  // 自定义
  const inputChange = (value: any) => {
    if(value){
      const reg = /^\d+$/;
      if(reg.test(value)){
        setCustomValue(value);
      } else {
        setCustomValue(Number(String(value).split(".")[0]));
      }
    }
  };

  // 数字格式处理
  const numberFormatter = (text: any) => {
    if(text){
      const reg = /^\d+$/;
      if(reg.test(text)){
        return text;
      } else {
        return parseFloat(text).toFixed(0);
      }
    } else {
      return '';
    }
  };

  const handleClickOutside = (e: any) => {
    if(open){
      if (e.target && !e.target.closest('.modal') && 
      !e.target.closest('.custom-dropdown-container') && 
      !e.target.closest('.ant-picker-panels') &&
      !e.target.closest('.ant-select-dropdown') &&             
      !e.target.closest('.ant-picker-body') &&
      !e.target.closest('.ant-picker-header') &&
      !e.target.closest('.ant-picker-panel-layout')) {
        getTimeValue({timeValue, paramValue: time, timeKey: timeKey, id, flag: false, isCustom});
      }
    }
  };

  useEffect(() => {
    setKeyActive(timeKey);
    if(timeKey === '1'){
      if(custom){
        // 自定义时间
        setIsCustom(true);
        setItemActive("");
        setCustomValue(customVal);
        setIsCustomChecked(customCheck);
      } else {
        const val: any = timeValue ? dateItem.find((v: any, i: number) => v.label === timeValue)?.value : '1';
        setItemActive(val);
      }
      setDateValue(null);
      setRangeValue([]);
    } else if(timeKey === '2'){
      setItemActive('1');
      setDateValue(null);
      setRangeValue([dayjs(timeValue.substring(0, 10), 'YYYY-MM-DD'), dayjs(timeValue.substring(11, 21), 'YYYY-MM-DD')]);
    } else if(timeKey === '3') {
      setItemActive('1');
      setDateValue(dayjs(timeValue, 'YYYY-MM-DD'));
      setRangeValue([]);
    }
    if(!open){
      setIsCustom(false);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open])

  useEffect(() => {
    if(timeStore.indexOf('&') !== -1){
      const timeVal = timeStore.split('&');
      const store = [dayjs(timeVal[0]), dayjs(timeVal[1])] as any;
      setRangeValue(store);
    } else {
      const str: any = timeStore ? dayjs(timeStore) : '';
      setDateValue(str);
    }
  }, [])

  return (
    <div className="custom-dropdown-container" style={{ width: width }}>
      <Select
        allowClear
        disabled={operateType === 'view'}
        style={{ width: '100%' }}
        placeholder="不限时间"
        value={timeValue}
        open={open}
        onClick={openTimeSelect}
        onClear={clearAll}
        className={styles.timeSelect}
        dropdownStyle={{ width: "605px" }}
        dropdownRender={(menu) => (
          <div style={{ display: "flex" }} className="modal">
            <div style={{ padding: "10px" }}>
              {optionValue.map((v: any, i: number) => {
                return (
                  <div
                    className={styles.dateNav}
                    key={v.key}
                    style={{
                      background:
                        keyActive === v.key
                          ? "var(--background4)"
                          : "var(--background1)",
                    }}
                    onClick={() => {
                      setKeyActive(v.key); 
                      setIsCustom(false); 
                      setCustomValue(null); 
                      setIsCustomChecked('date')
                    }}
                  >
                    <div className={styles.item}>{v.label}</div>
                    <RightOutlined />
                  </div>
                );
              })}
            </div>
            <Divider type="vertical" style={{ margin: 0, height: "272px" }} />
            <div style={{ padding: "15px 0 10px 10px", width: '100%' }}>
              {keyActive === "1" && (
                <div>
                  <div>
                    {dateItem2.map((v: any, i: number) => {
                      return (
                        <div
                          className={styles.timeItem}
                          key={i}
                          style={{
                            marginRight: (i + 1) % 3 === 0 ? 0 : "8px",
                            visibility: v.value === null ? "hidden" : "unset",
                            color: v.value === itemActive ? 'var(--text-font-color7)' : 'var(--text-font-color9)',
                            border: v.value === itemActive ? 'var(--border4)' : 'var(--border6)'
                          }}
                          onClick={() => {
                            setItemActive(v.value); 
                            setIsCustom(false); 
                            setCustomValue(null); 
                            setIsCustomChecked('date')
                          }}
                        >
                          {v.label}
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {dateItem.map((v: any, i: number) => {
                      return (
                        <div
                          className={styles.timeItem}
                          key={i}
                          style={{
                            display: i < 3 ? "none" : 'inline-block',
                            marginRight: "8px",
                            visibility: v.value === null ? "hidden" : "unset",
                            color: v.value === itemActive ? 'var(--text-font-color7)' : 'var(--text-font-color9)',
                            border: v.value === itemActive ? 'var(--border4)' : 'var(--border6)'
                          }}
                          onClick={() => {
                            setItemActive(v.value); 
                            setIsCustom(false); 
                            setCustomValue(null); 
                            setIsCustomChecked('date')
                          }}
                        >
                          {v.label}
                        </div>
                      );
                    })}
                  </div>
                  <div style={{display: "flex"}}>
                    <div 
                      className={styles.timeItem} 
                      onClick={() => {
                        setIsCustom(true); 
                        setCustomValue(''); 
                        setIsCustomChecked('date'); 
                        setItemActive("")
                      }} 
                      style={{
                        marginRight: 8, 
                        marginTop: 3,
                        color: isCustom ?  'var(--text-font-color7)' : 'var(--text-font-color9)',
                        border: isCustom ?  'var(--border4)' : 'var(--border6)'
                      
                      }}
                      >自定义</div>
                    {
                      isCustom && <div>
                        过去
                        <InputNumber 
                          placeholder="请输入"
                          min={1} 
                          max={999}
                          value={customValue}
                          style={{margin: "0 8px"}}
                          formatter={(text) => numberFormatter(text)}
                          parser={(text: any) => text.replace(/kg$/, '')}
                          onChange={inputChange} 
                        />
                        <Select
                          style={{ width: 120 }}
                          value={customChecked}
                          onChange={(e) => setIsCustomChecked(e)}
                          options={customDateItem}
                        />
                      </div>
                    }
                  </div>
                </div>
              )}
              {
                keyActive === "2" && (
                  <div style={{width: '100%'}}> 
                    <RangePicker                     
                      value={rangeValue}
                      style={{width: '100%'}} 
                      format="YYYY-MM-DD"
                      onChange={(date, dateStr: any) => {
                        if (dateStr) {
                          if(dateStr[0] && dateStr[1]){
                            setRangeValue([dayjs(dateStr[0], "YYYY-MM-DD"), dayjs(dateStr[1], "YYYY-MM-DD")])
                          } else {
                            setRangeValue(null);
                          }
                        }
                      }}
                    />
                  </div>
                )
              }
              {
                keyActive === "3" && (
                  <div style={{width: '100%'}}>
                  <DatePicker
                    value={dateValue}
                    style={{width: '100%'}}
                    placeholder="请选择时间"
                    format="YYYY-MM-DD"
                    onChange={(date, dateStr: any) =>{ 
                      if (dateStr) {
                        setDateValue(dayjs(dateStr));
                      }else{
                        setDateValue(null)
                      }
                    }}
                  />
                  </div>
                )
              }
              <div style={{ width: '100%', textAlign: "right", marginTop: keyActive !== "1" ? '183px' : '59px', paddingRight: 8 }}>
                <Button onClick={clearTime} style={{marginRight: 8}}>
                  取消
                </Button>
                <Button type="primary" onClick={chooseTime}>
                  确定
                </Button>
              </div>
            </div>
          </div>
        )}
        options={items}
      />
    </div>
  );
};

export default TimeSelect;
