import { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { Table, Button, Switch, Form, Radio, Popconfirm, message } from 'antd';
import type { TableProps } from 'antd';
import { TitleWithExpansion, useGetMemberCardList } from '../../../../component/MiniStyles';
import FilterView from '../../../../component/FilterView';
import CreadRewardEvents from '../../../../component/creadRewardEvents';
import { getEventApi, getEventStopApi, getEventDeleteApi, getDetailApi, getConfigListApi, getConfigAddOrUpdateApi } from '../../../../api/distReward';
import { LOYALTY_KEY } from '../../../../../router/Permissions';
import checkPermission from '../../../../assets/public/checkPermission';
import RewardChangeStatus from '../../../../component/rewardChangeStatus';

type FieldType = {
  memberCardId: string;
  name?: string;
};

const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

function RewardEvents() {
  const { memberCardOptions, memberCardAllOptions } = useGetMemberCardList();
  const defaultFormValue = {
    memberCardId: memberCardAllOptions[0]?.value || '',
    name: '',
  };
  const [tableList, setTableList] = useState<any[]>([]);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const filterViewRef = useRef<any>(null);
  const [formMemberCardId, setFormMemberCardId] = useState('');

  const [searchList, setSearchList] = useState<any[]>([
    {
      searchType: 'string',
      searchComponentType: 'input',
      placeHolder: '请输入',
      title: '事件名称',
      key: 'name',
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '所属会员卡',
      key: 'memberCardId',
      options: [],
    },
  ]);
  // 获得table列表
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getEventApi({
      ...searchInfo,
      pageNum,
      pageSize,
    });
    if (res?.code === 200) {
      setTableList(res.data?.records || []);
      setTableInfo({ ...tableInfo, total: res.data?.total || 0 });
    }
  };

  // 查询
  const filterFinish = (data: any) => {
    setSearchInfo({ ...data });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };
  const onFormChange = (e: any) => {
    if (e[0].name[0] === 'memberCardId') {
      setFormMemberCardId(e[0].value);
      const obj = {
        ...filterViewRef.current.getFormInfo(),
      };
      filterViewRef.current.handChangeForm(obj);
    }
  };
  // useEffect(() => {
  //   getConfigList();
  // }, []);
  useEffect(() => {
    if (memberCardAllOptions.length > 0) {
      const obj = {
        ...searchInfo,
        memberCardId: memberCardAllOptions[0]?.value || '',
      };
      const arr = JSON.parse(JSON.stringify(searchList));
      arr[1].options = memberCardAllOptions;
      setSearchInfo(obj);
      setSearchList(arr);
      filterViewRef.current.handChangeForm(obj);
    }
  }, [JSON.stringify(memberCardAllOptions)]);

  // 获得表格数据
  useEffect(() => {
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableInfo.pageNum, tableInfo.pageSize, JSON.stringify(searchInfo)]);
  interface DataType {
    name: string;
    card: string;
    rewardCondition: string;
    content: string;
    status: number;
    pointType: number;
    pointValue: number;
    pointMultiple: number;
    growthType: number;
    growthValue: number;
    growthMultiple: number;
    id: number;
    memberCardName: string;
  }

  let columns: TableProps<DataType>['columns'] = [
    {
      title: '事件名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '所属会员卡',
      dataIndex: 'card',
      key: 'card',
      render: (_, record) => <div>{record.memberCardName}</div>,
      width: '300px',
    },
    {
      title: '奖励条件',
      dataIndex: 'rewardCondition',
      key: 'rewardCondition',
    },
    {
      title: '奖励内容',
      dataIndex: 'content',
      key: 'content',
      render: (_, record) => (
        <>
          {record.growthType && (
            <>{record.growthType === 1 ? <div>成长值：{record.growthValue}成长值；</div> : <div>成长值：{record.growthMultiple}倍成长值；</div>}</>
          )}
          {record.pointType && (
            <>{record.pointType === 1 ? <div>积分：{record.pointValue}积分</div> : <div>积分：{record.pointMultiple}倍积分</div>}</>
          )}
        </>
      ),
      // width: '300px',
    },

    {
      title: '启用状态',
      dataIndex: 'status',
      key: 'status',
      width: '150px',
      render: (_, record) => (
        <div>
          <Popconfirm
            placement="top"
            title={record.status === 1 ? '是否确认关闭？' : '是否确认开启？'}
            okText="确定"
            cancelText="取消"
            onConfirm={() => onSwitchChange(record)}>
            <Switch checkedChildren="开" unCheckedChildren="关" checked={record.status === 1 ? true : false} />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) => (
        // 暂定0是禁用, 1是启用
        <div>
          {checkPermission(LOYALTY_KEY.EVENT_UPDATE) && (
            <Button
              style={{ padding: 0 }}
              onClick={() => {
                drawerSwitch(record.id);
              }}
              type="link">
              编辑
            </Button>
          )}
          {checkPermission(LOYALTY_KEY.EVENT_DELETE) && (
            <Popconfirm
              placement="left"
              title={'确定删除此奖励事件吗？'}
              okText="确定"
              cancelText="取消"
              onConfirm={() => handleOk(record)}
              onPopupClick={e => {
                e.stopPropagation();
              }}>
              <Button style={{ padding: 0 }} disabled={record.status === 1 ? true : false} type="link">
                删除
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];
  // 没有编辑权限
  if (!checkPermission(LOYALTY_KEY.EVENT_STOP)) {
    columns = columns.filter(column => column.key !== 'status');
  }
  // 没有编辑权限和删除权限
  if (!checkPermission(LOYALTY_KEY.EVENT_UPDATE) && !checkPermission(LOYALTY_KEY.EVENT_DELETE)) {
    columns = columns.filter(column => column.key !== 'action');
  }

  const handleTableChange = (pagination: any, _: any) => {
    setTableInfo({
      ...tableInfo,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    });
  };

  const handleOk = async (record: any) => {
    console.log(record);
    // return;
    getEventDelete(record, 2);
  };

  // 开关
  const onSwitchChange = (record: any) => {
    // false是关,true是开
    let status = record.status === 1 ? 0 : 1;
    getEventStop(record, status);
  };
  // 停/启用用
  const getEventStop = async (record: any, status: number) => {
    await getEventStopApi(record.id, status).then(res => {
      if (res?.code === 200) {
        const updatedA: any = tableList.map((item: any) => {
          if (item.id === record.id) {
            return { ...item, status: status };
          }
          return item;
        });
        setTableList(updatedA);
      }
    });
  };
  // 删除
  const getEventDelete = async (record: any, status: number) => {
    await getEventDeleteApi(record.id, status).then(res => {
      if (res?.code === 200) {
        message.success('删除成功');
        getTableList();
      }
    });
  };

  // 奖励条件配置
  const [configList, setConfigList] = useState([{ configValue: '0' }]);
  const getConfigList = async () => {
    await getConfigListApi().then(res => {
      if (res?.code === 200) {
        setConfigList(res.data);
      }
    });
  };
  const [formList, setFormList] = useState({});
  // 新建/编辑奖励事件
  const [open, setOpen] = useState(false);

  const drawerSwitch = (id?: any) => {
    if (typeof id === 'string') {
      getDetail(id);
    } else {
      setOpen(!open);
      if (open) {
        getTableList();
      }
    }
  };

  const getDetail = async (id: any) => {
    const res = await getDetailApi(id);
    if (res?.code === 200) {
      setFormList(res.data);
      setOpen(!open);
    }
  };

  // const handleRadioChange = async (index: any, e: any) => {
  //   let param = { ...configList[index], configValue: e };
  //   await getConfigAddOrUpdateApi(param).then(res => {
  //     if (res?.code === 200) {
  //       getConfigList();
  //     }
  //   });
  // };

  // const configInfo = [{ label: '当奖励条件重复时，奖励积分策略采用' }, { label: '奖励成长值策略采用' }];
  // 规则部分
  // const RuleSection = () => {
  //   return (
  //     <div style={{ marginTop: '24px' }}>
  //       {checkPermission(LOYALTY_KEY.EVENT_UPDATE) && (
  //         <Form labelCol={{ span: 8 }} layout="horizontal" style={{ maxWidth: 800 }}>
  //           {configList.map((config: any, index: any) => (
  //             <Form.Item key={index} label={configInfo[index].label}>
  //               <Radio.Group value={config.configValue}>
  //                 <Popconfirm
  //                   placement="top"
  //                   title={'是否确认修改？'}
  //                   disabled={configList[index].configValue === '1'}
  //                   okText="确定"
  //                   okButtonProps={{ loading: false }}
  //                   cancelText="取消"
  //                   onConfirm={() => handleRadioChange(index, '1')}>
  //                   <Radio value="1"> 叠加奖励 </Radio>
  //                 </Popconfirm>

  //                 <Popconfirm
  //                   placement="top"
  //                   title={'是否确认修改？'}
  //                   disabled={configList[index].configValue === '2'}
  //                   okText="确定"
  //                   okButtonProps={{ loading: false }}
  //                   cancelText="取消"
  //                   onConfirm={() => handleRadioChange(index, '2')}>
  //                   <Radio value="2"> 单笔交易最高获得 </Radio>
  //                 </Popconfirm>
  //               </Radio.Group>
  //             </Form.Item>
  //           ))}
  //         </Form>
  //       )}
  //     </div>
  //   );
  // };
  // 表格部分
  const TableSection = () => {
    return (
      <div style={{ marginTop: '24px' }}>
        <Table
          columns={columns}
          dataSource={tableList}
          pagination={{
            total: tableInfo.total,
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={handleTableChange}
        />
      </div>
    );
  };
  const [changeStatusOpen, setChangeStatusOpen] = useState(false);
  const changeStatusDrawer = () => {
    if (!changeStatusOpen) {
      getConfigList();
    }
    setChangeStatusOpen(!changeStatusOpen);
  };

  return (
    <div className={style['RewardEvents-bgd']}>
      {/* 标题 */}
      <TitleWithExpansion
        title="奖励事件"
        onChange={() => filterViewRef.current.showFilterView()}
        operaTionTitle="新建奖励事件"
        onBtnChange={drawerSwitch}
        operaTionPermissions={LOYALTY_KEY.EVENT_ADD}
        otherTitle="重复奖励策略设置"
        otherType="default"
        otherChange={changeStatusDrawer}>
        {/* 搜索部分 */}
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          defaultFormValue={defaultFormValue}
          finish={filterFinish}
          onFormChange={onFormChange}></FilterView>
      </TitleWithExpansion>

      {/* 规则部分 */}
      {/* <RuleSection /> */}
      {/* 表格部分 */}
      <TableSection />
      {/* 抽屉 */}
      <CreadRewardEvents open={open} formList={formList} memberList={memberCardOptions} drawer={drawerSwitch}></CreadRewardEvents>
      <RewardChangeStatus open={changeStatusOpen} configList={configList} drawer={changeStatusDrawer}></RewardChangeStatus>
    </div>
  );
}

export default RewardEvents;
