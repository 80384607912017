import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Drawer, Button, Table, message, Space, Tooltip } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import {
  exportLabelList,
  exportCrowdList,
  getLabelInfoExcel,
  getGroupInfoExcel,
  customerList
} from "../../../../api/label/label";
import styles from "./index.module.scss";

const DownLoadLog = (props: {
  downLoadLog: boolean;
  closeDownLoadLog: Function;
  type: number;//1 标签 2 分群
  downloadId?: any;
  searcParam?: any;
  crowdId?: any;
  labelId?: any;
  title?: String;
}) => {
  const { downLoadLog, closeDownLoadLog = () => { } } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);

  const [listData, setListData] = useState([] as any);

  // 关闭当前抽屉
  const onClose = () => {
    closeDownLoadLog(false);
    setListData([]);
  };


  const [resetConfirm, setResetConfirm] = useState(false);
  const [batchResetConfirm, setBatchResetConfirm] = useState(false);

  const [columnsList, setColumnsList] = useState([]);
  
  const columns: TableProps<any>["columns"] = [
    
    {
      title: "标签值",
      dataIndex: "labelValueName",
      key: "labelValueName",
    },
  ];


  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "createTime",
    isAsc: "desc",
  });

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'createTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }
    getProfilePropertyList({ pageSize: pagination.pageSize, pageNum: pagination.current })
  }
  // 获取下载用户明细列表
  const getAccountListFunction = async (page: { pageSize: number; pageNum: number }) => {
    const param = {
      ...page,
      downloadId : props.downloadId,
      crowdId: props?.crowdId,
      labelId: props?.labelId,
    }
    const res = props.type == 1 ? await exportLabelList(param) : await exportCrowdList(param);
    if (res) {
      setListData(res.data.rows);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      var title:any = '';
      propertyOptions.map ((item:any)=>{
        if (res.data.rows[0].dataType == item.id) {
          title = item.fieldName
        }
      })
      var arr1:any = [];
      if (props.type == 1) {
        arr1 = columns
      }
      arr1.unshift(
        {
          title: title,
          dataIndex: "value",
          key: "value",
        },
      )
      setColumnsList (arr1)
    }
  };
  //用户唯一标识码 列表
  let propertyOptions = useState([] as any);
  // 获取用户属性
  const getProfilePropertyList = async (page: { pageSize: number; pageNum: number }) => {
    const { data } = await customerList();
    propertyOptions = data;
    getAccountListFunction({ pageSize: page.pageSize, pageNum: page.pageNum });
  };
  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
    setBatchResetConfirm(false);
  };
  // 下载  
  const toDownLoad = async() => {
    if (listData.length < 1) {
      message.error('下载内容不可为空')
      return
    }
    if (props.type == 1) {
      const param = {
        labelId: props?.labelId,
        taskLocation: '标签/导入标签' , //页面位置
        taskName: props.title, //模块名称
        downloadId : props.downloadId
      } as any;
      const res = await getLabelInfoExcel(param)
      if (res?.data) {
        message.success("导出中，请到下载中心查看进度");
      }else {
        message.error("下载失败");
      }
    }else {
      const param = {
        crowdId: props?.crowdId,
        taskLocation: '分群/导入分群', //页面位置
        taskName: props.title, //模块名称
        downloadId : props.downloadId
      } as any;
      const res = await getGroupInfoExcel(param)
      if (res?.code === 200) {
        message.success("导出中，请到下载中心查看进度");
      }
    }
    
    
  };
  useEffect(() => {
    setOpen(downLoadLog);
    if (downLoadLog) {
      getProfilePropertyList({ pageSize: 10, pageNum: 1 });
    }
  }, [downLoadLog]);

  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
      document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={props.title}
        placement={placement}
        width={700}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button type="primary" onClick={() => toDownLoad()}>
              下载
            </Button>
          </Space>
        }
      >
        <div>
          {listData.length > 0 &&
            <Table
            style={{ width: "100%", marginTop: "24px" }}
            rowKey={(record) => record.id}
            columns={columnsList}
            dataSource={listData}
            scroll={{ y: 580 }}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
          }
        </div>
      </Drawer>
    </div>
  );
};

export default DownLoadLog;
