import React from 'react';
import styles from './index.module.scss';

const ModuleTitle = (props: {
  mark?: string,
  title?: string
}) => {
  const { mark, title } = props;

  return (
    <div>
      {
        mark === 'large' && <div className={styles.largeTitleStyle}>{title}</div>
      }
      {
        mark === 'middle' && <div className={styles.middleTitleStyle}>{title}</div>
      }
    </div>
  );
};

export default ModuleTitle