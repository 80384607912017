import { Drawer, Button, Table, Checkbox, message, Tag, Modal } from 'antd';
import React, { useEffect, useImperativeHandle, useState, useRef } from 'react';
import style from './index.module.scss';
import type { TableProps } from 'antd';
import FilterView from '../FilterView';
import SearchIconInput from '../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../component/FilterIcon/filterIcon';
import { getRevokeSendPointApi, getDetailPageApi } from '../../api/distReward';
import { LOYALTY_KEY } from '../../../router/Permissions';
import SystemButton from '../../../component/Button/SystemButton';

type FieldType = {
  planId: string;
  planName: string;
  sendPointDetailStatus: string;
  phone: string;
};
let defaultFormValue = {
  planId: '',
  planName: '',
  sendStatus: '',
  sendPointDetailStatus: '',
  phone: '',
};
const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

let checkPointsResult = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
    },
    ref: any,
  ) => {
    const [resultList, setResultList] = useState<any>([]);
    const [tableInfo, setTableInfo] = useState(defaultTableInfo);
    const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
    const [filterStatus, setFilterStatus] = useState<number>(0);

    // 判断是否为偶数
    const isEven = (num: any) => {
      return num % 2 === 0;
    };
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        props.drawer();
        if (!isEven(filterStatus)) {
          filterViewRef.current?.showFilterView();
          filterViewRef.current?.handChangeForm(defaultFormValue);
          setFilterStatus(0);
        }
      }
      setSearchInfo(defaultFormValue);
      setSelectedRowKeys([]);
    };

    interface DataType {
      id: string;
      phone: string;
      sendPoint: string;
      sendStatus: string;
      memberCardName: string;
      shopName: string;
    }

    // 复选框
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const onSelectAllChange = (e: any) => {
      const keys = e.target.checked ? resultList.filter((item: any) => item.sendStatus === '已发放').map((item: any) => item.id) : [];
      setSelectedRowKeys(keys);
    };
    const handleSelectChange = (key: string) => {
      const keys = [...selectedRowKeys];
      const index = keys.indexOf(key);
      if (index > -1) {
        keys.splice(index, 1);
      } else {
        keys.push(key);
      }
      setSelectedRowKeys(keys);
    };
    const columns: TableProps<DataType>['columns'] = [
      {
        title: (
          <Checkbox
            onChange={onSelectAllChange}
            checked={
              selectedRowKeys.length === resultList.filter((item: any) => item.sendStatus === '已发放').length && selectedRowKeys.length !== 0
            }></Checkbox>
        ),
        dataIndex: 'checkbox',
        key: 'checkbox',
        width: '48px',
        render: (text, record) => (
          // 暂定0是禁用, 1是启用
          <Checkbox
            disabled={record.sendStatus !== '已发放' ? true : false}
            checked={selectedRowKeys.includes(record.id)}
            onChange={() => handleSelectChange(record.id)}
          />
        ),
      },
      {
        title: '手机号',
        dataIndex: 'phone',
        key: 'phone',
        // render: (text) => <a>{text}</a>,
      },
      {
        title: '发放积分数量',
        dataIndex: 'sendPoint',
        key: 'sendPoint',
      },
      {
        title: '发放结果',
        dataIndex: 'sendStatus',
        key: 'sendStatus',
        render: (_, record) => (
          <>
            {record.sendStatus === '已发放' && <Tag color="success">已发放</Tag>}
            {record.sendStatus === '已撤回' && <Tag color="warning">已撤回</Tag>}
            {record.sendStatus === '待发放' && <Tag style={{ color: 'var(--text-font-color4)' }}>待发放</Tag>}
            {record.sendStatus === '发放失败' && <Tag color="error">发放失败</Tag>}
          </>
        ),
      },
      {
        title: '会员卡',
        dataIndex: 'memberCardName',
        key: 'memberCardName',
        width: 250,
      },
      {
        title: '店铺',
        dataIndex: 'shopName',
        key: 'shopName',
        width: 250,
      },
    ];

    const filterViewRef = useRef<any>(null);
    const searchList: searchItem[] = [
      {
        searchType: 'string',
        searchComponentType: 'select',
        placeHolder: '请选择',
        title: '发放结果',
        key: 'sendStatus',
        options: [
          {
            value: 'SUC_SEND',
            label: '发放成功',
          },
          {
            value: 'FAIL_SEND',
            label: '发放失败',
          },
          {
            value: 'WAIT_SEND',
            label: '待发放',
          },
          {
            value: 'BACK_SEND',
            label: '已撤回',
          },
        ],
      },
      {
        searchType: 'string',
        searchComponentType: 'input',
        placeHolder: '',
        title: '手机号',
        key: 'phone',
      },
    ];
    // 批量撤回确认框是否显示
    const [isModalOpen, setIsModalOpen] = useState(false);
    let loading = false;
    const showModal = () => {
      if (selectedRowKeys.length === 0) {
        if (loading) {
          return;
        }
        loading = true;
        setTimeout(() => {
          loading = false;
        }, 3500);
        return message.warning('您未选中用户');
      }
      setIsModalOpen(true);
    };
    // 撤销
    // 确认批量撤回
    const handleOk = async () => {
      setIsModalOpen(false);
      await getRevokeSendPointApi(searchInfo.planId, selectedRowKeys).then(res => {
        if (res?.code === 200) {
          const updatedResultList = resultList.map((item: any) => {
            if (selectedRowKeys.includes(item.id)) {
              return { ...item, sendStatus: '已撤回' };
            }
            return item;
          });
          const keys = true ? resultList.filter((item: any) => item.sendStatus === '已撤回').map((item: any) => item.id) : [];
          setSelectedRowKeys(keys);
          setResultList(updatedResultList);
          message.success('撤回成功！');
        }
      });
    };
    // 取消批量撤回
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    // 放大镜的查询.输入手机号的时候查手机号,输入id查id

    const handleTableChange = (pagination: any, _: any) => {
      setTableInfo({
        ...tableInfo,
        pageSize: pagination.pageSize,
        pageNum: pagination.current,
      });
    };

    const filterFinish = (result: any) => {
      console.log(result);
      setSearchInfo({ ...searchInfo, sendPointDetailStatus: result.sendStatus, phone: result.phone });
      setTableInfo({ ...tableInfo, pageNum: 1 });
    };
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
      getDetailPage,
    }));
    const getDetailPage = async (data?: any) => {
      if (data) {
        const { id, planName } = data;
        setSearchInfo({ ...searchInfo, planId: id, planName });
        return;
      }
      const { pageNum, pageSize } = tableInfo;
      let param = {
        ...searchInfo,
        pageNum,
        pageSize,
      };
      await getDetailPageApi(param).then(res => {
        if (res?.code === 200) {
          setResultList(res.data?.records || []);
          setTableInfo({ ...tableInfo, total: res.data?.total });
        }
      });
    };
    useEffect(() => {
      searchInfo.planId && getDetailPage();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableInfo.pageNum, JSON.stringify(searchInfo)]);
    const handleFilterClick = () => {
      setFilterStatus(prevStatus => prevStatus + 1);
      filterViewRef.current?.showFilterView();
      filterViewRef.current?.handChangeForm(defaultFormValue);
    };
    return (
      <div>
        <Drawer width={960} title="发放结果查询" onClose={handleDrawerSwitch} open={props.open}>
          {/* onBtnChange={} */}
          <div>
            <div className={style.buttonBox}>
              <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
              <span
                style={{
                  width: '0',
                  height: '16px',
                  border: 'var(--border3)',
                  marginRight: 16,
                }}></span>
              <div style={{ marginRight: 0, marginTop: 6 }}>
                <SystemButton title="批量撤回" type="default" onClick={showModal} permissions={LOYALTY_KEY.PLAN_REVOKE} />

                <Modal title="批量撤回去确认" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                  <p>{`是否确认撤回选中用户<${selectedRowKeys.length}>人积分，撤回后，本次无法再次发送积分。`}</p>
                </Modal>
              </div>
            </div>
          </div>
          <FilterView ref={filterViewRef} searchList={searchList} defaultFormValue={defaultFormValue} finish={filterFinish}></FilterView>

          <Table
            columns={columns}
            dataSource={resultList}
            pagination={{
              total: tableInfo.total,
              pageSize: tableInfo.pageSize,
              current: tableInfo.pageNum,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              showTotal: total => `共 ${total} 条`,
              // defaultCurrent: 1
            }}
            onChange={handleTableChange}
          />
        </Drawer>
      </div>
    );
  },
);
export default checkPointsResult;
