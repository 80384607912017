import React, { useState } from 'react';
import { useEffect } from "react";
import style from './backList.module.scss'
import {
  LeftOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const BackList = (props: {
  // path?: String,
}) => {
    const navigate = useNavigate();
    const checkBack = () => {
      navigate(-1)
      // var link = props.path
      // navigate(link as string)
    }
    useEffect(() => {
    }, [])

    return (
      <div className={style['BackList_title']} onClick={checkBack}>
        <LeftOutlined />
        <span className={style['BackList_text']}>返回</span>
      </div>
  );

}

export default BackList