import React, { useState, useEffect } from "react";
import { Drawer, Button,Space, Tabs , message, Checkbox } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import {
  updCustomerListTitle
} from "../../../../../../../../api/UserInsight/UserInsight";
import styles from "./index.module.scss";
const EditMes = (props: {
  openEditMes: boolean;
  closeEditMes: Function;
  // popType: any;
  // refirmTabel: Function;
  // checkUserMesList: any;
}) => {
  const { openEditMes, closeEditMes = () => {} } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);

  const [checkData, setCheckData] = useState([] as any);//选择的数据
  const [editUserMesList, setEditUserMesList] = useState(
    [
      { label: 'OneID', value: 'oneId' ,disabled:false },
      { label: '手机号', value: 'mobile',disabled:false }, 
      { label: '所属会员卡', value: 'memberCardName' , disabled:false},
      { label: '所属渠道', value: 'channelType' , disabled:false},
      { label: '客户身份', value: 'identity' , disabled:false},
      { label: '会员等级', value: 'gradeId', disabled:false },
      { label: '当前积分', value: 'totalPoint' , disabled:false },
      { label: '客户活跃度', value: 'growthValue' , disabled:false },
      { label: '累计消费金额', value: 'tradeAmount' , disabled:false },
      { label: '累计下单次数', value: 'tradeCount' , disabled:false },
      { label: '性别', value: 'axe' , disabled:false },
      { label: '年龄', value: 'age' , disabled:false },
    ] as any);
  // 关闭当前抽屉
  const onClose = () => {
    closeEditMes(false);
  };
  //确定
  const submit = async () => {
    if (checkData.length < 1) {
      message.error('至少选择一项' );
      return
    }
    // var param = {
    //   configTitle:JSON.stringify(checkData)
    // }
    // const res = await updCustomerListTitle(param)
    // props.refirmTabel(checkData)
    closeEditMes(false);
    // if (res.code === 200) {
    //     message.success('修改成功' );
    //     closeEditMes(false);
    // }
  };

  // 关闭提示弹窗
  const hidden = () => {
  };
  // 改变多选框
  const onChange = (e:any) => {
    setCheckData(e)
    //处理超过10个 其他置灰不可选择  
    let valueList = editUserMesList.map((item:any) => item.value);
    let disList = [] as any;
    valueList.forEach((item:any) => {
        if(!e.includes(item)) {
            disList.push(item);
        }
    });
    if (e.length == 10) {
      editUserMesList.map((item:any,index:any)=>{
        disList.map((itm:any,indx:any)=> {
            if(item.value == itm) {
              editUserMesList[index].disabled = true;
            }
        })
      })
    }else {
      for(let i in editUserMesList) {
        editUserMesList[i].disabled = false;
      }
    }
  };
   // 用户tab页
   const dataTagsType: any[] = [
    {
        key: '1',
        label: '基础属性',
        children: <>
                    <div style={{display:'flex'}}>
                    <Checkbox.Group
                      className={styles.editUserMes_checkBox}
                      options={editUserMesList}
                      // defaultValue={}
                      onChange={onChange}
                    />
                    </div>
                  </>,
    }, 
    {
        key: '2',
        label: '会员属性',
        children: <> 
                    <div style={{display:'flex'}}>
                      11
                    </div>
                  </>,
    }, 
    {
        key: '3',
        label: '其他属性',
        children: <> 
                      <div style={{display:'flex'}}>
                        32
                      </div>
                  </>,
    },
  ]
  const setUserType = (key:any) => {
    console.log("setUserType",key);
    // setUserTypeTabs(key)
    switch (key) {
      case '1':
        break;
      case '2':
        break;
      case '3':
        
        break;
      
      default:
        break;
    }
  }
  useEffect(() => {
    setOpen(openEditMes);
    setCheckData([])
  }, [openEditMes]);
  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={'修改展示字段'}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button type="primary" onClick={submit}>
              确定
            </Button>
          </Space>
        }
      >
        <div>
          <Tabs className={styles['content_tabs']} 
                onChange={(key) => { setUserType(key) }} 
                tabBarGutter={50} items={dataTagsType}>
          </Tabs>
        </div>
        <div className={styles.editUserMes_chooseNum}>已选择{checkData.length}/{editUserMesList.length}</div>
      </Drawer>
    </div>
  );
};

export default EditMes;
