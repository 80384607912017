import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FilterOutlined } from "@ant-design/icons";
import {
  Button,
  Table,
  Drawer,
  Space,
  Steps,
  Modal,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import type { TableProps, DrawerProps, TabsProps } from "antd";
import {
  DataAccessListApi,
  PlatformMethodListApi,
  DataAccessEnableApi,
  DataAccessMethodListApi,
  DataAccessEnableCheckApi,
} from "../../../../api/collect/collect";
import {
  DataAccessListQuery,
  DataAccessListVO,
} from "../../../../api/collect/types";
import ModuleTitle from "../../../../component/ModuleTitle";
import MiniLabel from "../../../../component/MiniLabel";
import FilterView from "../../../../component/FilterView/filterView";
import SearchIconInput from "../../../../component/Input/SearchIconInput/searchIconInput";
import styles from "./index.module.scss";
import SelectAccessMethod from "./components/SelectAccessMethod";
import ConfigureAccessInformation from "./components/ConfigureAccessInformation";
import PromitModal from "./components/PromitModal";
import FilterIcon from '../../../../component/FilterIcon/filterIcon'
import { getTableScroll, state } from '@/store/index'

// eslint-disable-next-line import/no-anonymous-default-export
const DataAccess: React.FC = () => {
  const navigate = useNavigate();
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const [notShow, setNotShow] = useState(false);

  let location = useLocation();

  const filterViewRef: any = useRef(null);

  const childRef: any = useRef(null);

  const promitModalRef: any = useRef(null);
  const [scrollY, setScrollY] = useState("")

  // 获取数据接入列表
  let accessMethodListStore = [] as any;
  const [accessMethodList, setAccessMethodList] = useState([]);
  const getDataAccessMethodList = async () => {
    const { data } = await DataAccessMethodListApi();
    data.forEach((v: any, i: number) => {
      v.value = v.wayKey;
      v.label = v.name;
    });
    setAccessMethodList(data);
    accessMethodListStore = data;
    setScrollY(getTableScroll())
    if (location.state && location.state.pageQuery) {
      getDataAccessList({
        pageSize: location.state.pageQuery.pageSize,
        pageNum: location.state.pageQuery.pageNum,
      }, '');
    } else {
      getDataAccessList({
        pageSize: pageQuery.pageSize,
        pageNum: pageQuery.pageNum,
      }, searcParam);
    }
  };

  // 获取平台类型接口
  let platformMethodListStore = [] as any;
  const [platformMethodList, setPlatformMethodList] = useState([]);
  const getPlatformMethodList = async () => {
    const res = await PlatformMethodListApi();
    if (!res) {
      return
    }
    res.data.forEach((v: any, i: number) => {
      v.label = v.value;
      v.value = v.key;
    });
    setPlatformMethodList(res.data);
    platformMethodListStore = res.data;
    getDataAccessMethodList();
  };

  const searchList: searchItem[] = [
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "平台类型",
      key: "platformType",
      options: platformMethodList,
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "创建方式",
      key: "createWay",
      options: accessMethodList,
    },
    // {
    //   searchType: "string",
    //   searchComponentType: "select",
    //   placeHolder: "请选择",
    //   title: "状态筛选",
    //   key: "status",
    //   options: [
    //     {value: '0', label: '启用'},
    //     {value: '1', label: '停用'},
    //   ],
    // },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "创建时间",
      key: "beginTime-endTime",
    },
  ];

  const searcParamInt: DataAccessListQuery = {
    searchValue: "",
    createWay: "",
    platformType: "",
    status: "",
    beginTime: "",
    endTime: "",
  };

  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  const filterFinish = (data: any) => {
    setSearcParam(data);
    getDataAccessList({ pageSize: 10, pageNum: 1 }, data);
  };

  const [listData, setListData] = useState([] as DataAccessListVO[]);

  const [selectedTableKey, setSelectedTableKey] = useState(
    null as unknown as number
  );
  const [resetConfirm, setResetConfirm] = useState(false);

  const handleConfirmClick = async (record: DataAccessListVO) => {
    dataAccessEnable(record);
  };

  const handleCancelClick = (record: DataAccessListVO) => {
    setResetConfirm(false);
    setSelectedTableKey(null as unknown as number);
  };

  // 获取平台类型
  const tableGetTlatformType = (v: string) => {
    const store = platformMethodList.length > 0 ? platformMethodList : platformMethodListStore;
    const obj: any = store.find((val: any, idx: number) => val.value === v);
    return obj.label;
  };

  // 获取创建方式
  const tableGetCreateWay = (v: string) => {
    const store = accessMethodList.length > 0 ? accessMethodList : accessMethodListStore;
    const obj: any = store.find((val: any, idx: number) => val.wayKey === v);
    return obj.label;
  };

  const columns: TableProps<DataAccessListVO>["columns"] = [
    {
      title: "序号",
      dataIndex: "sort",
      key: "sort",
      width: "88px",
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: "数据接入名称",
      dataIndex: "accessName",
      key: "accessName",
      width: '300px',
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "店铺名称",
      dataIndex: "shopName",
      key: "shopName",
      width: '300px',
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "店铺ID",
      dataIndex: "shopId",
      key: "shopId",
      width: '200px',
    },
    {
      title: "平台类型",
      dataIndex: "platformType",
      key: "platformType",
      width: '150px',
      render: (_: any, record: any, i: number) => (
        <span>{tableGetTlatformType(_)}</span>
      ),
    },
    {
      title: "创建人",
      dataIndex: "createBy",
      key: "createBy",
      width: '150px',
    },
    {
      title: "创建方式",
      dataIndex: "createWay",
      key: "createWay",
      width: '150px',
      render: (_: any, record: any, i: number) => (
        <span>{tableGetCreateWay(_)}</span>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      width: '200px',
    },
    // {
    //   title: "状态",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (_, record) => (
    //     // 0 启用  1 停用
    //     <div>
    //       {_ === "0" ? (
    //         <MiniLabel text="启用" labelStyle="enable" />
    //       ) : (
    //         <MiniLabel text="停用" labelStyle="disable" />
    //       )}
    //     </div>
    //   ),
    // },
    {
      title: "操作",
      key: "action",
      width: "88px",
      align: "center",
      render: (_, record: any) => (
        // 暂定0是禁用, 1是启用
        <div>
          <Button
            type="link"
            onClick={() =>
              navigate("/files/originalDataAccess/dataAccess/details", {
                state: { record, pageQuery, searcParam },
              })
            }
          >
            查看
          </Button>
          {
            notShow && <Popconfirm
              placement="left"
              title="确定停用吗？"
              description="停用后，将停止数据接入"
              okText="确定"
              cancelText="取消"
              onConfirm={() => handleConfirmClick(record)}
              onCancel={() => handleCancelClick(record)}
              open={selectedTableKey === record.id && resetConfirm}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Button type="link" onClick={() => operate(record)}>
                {record.status === "0" ? "停用" : "启用"}
              </Button>
            </Popconfirm>
          }
        </div>
      ),
    },
  ];

  // 提示信息
  const [promitContent, setPromitContent] = useState("");

  // 停用二次确认
  const dataAccessEnableCheck = async (data: DataAccessListVO) => {
    if (loading1) {
      setLoading1(false);
      const param = {
        id: data.id,
      };
      const res = await DataAccessEnableCheckApi(param);
      setLoading1(true);
      if (res.data) {
        setResetConfirm(true);
      } else {
        dataAccessEnable(data);
      }
    }
  };

  // 调用启用禁用列表
  const dataAccessEnable = async (data: DataAccessListVO) => {
    if (loading2) {
      setLoading2(false);
      const param = {
        id: data.id,
        status: data.status === "0" ? "1" : "0",
      };
      const res = await DataAccessEnableApi(param);
      setLoading2(true);
      if (res && res.code === 505) {
        message.error(res.msg);
        // setPromitContent(res.msg);
        // promitModalRef.current.openModal();
      } else if (res) {
        message.success(data.status === "0" ? "停用成功" : "启用成功");
        setResetConfirm(false);
        getDataAccessList({
          pageSize: pageQuery.pageSize,
          pageNum: pageQuery.pageNum,
        }, searcParam);
      }
    }
  };

  // 启用&禁用(暂定0是启用, 1是停用)
  const operate = (v: any) => {
    setSelectedTableKey(v.id);
    if (v.status === "0") {
      dataAccessEnableCheck(v);
    } else {
      dataAccessEnable(v);
    }
  };

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange = (e: any) => {
    getDataAccessList({ pageSize: e.pageSize, pageNum: e.current }, searcParam);
  };

  // 抽屉开关
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");

  // 保存按钮是否可用
  const [saveDisabled, setSaveDisabled] = useState(true);
  const getAccessStatus = (val: boolean) => {
    setSaveDisabled(val);
  };

  // 获取选中的电商id
  const [selectedPlatformId, setSelectedPlatformId] = useState("");
  const getSelectedId = (id: number) => {
    setSelectedPlatformId(String(id));
  };

  // 提交成功回调
  const submitSuccess = () => {
    setOpen(false);
    getDataAccessList({ pageSize: 10, pageNum: 1 }, { ...searcParamInt });
  };

  // 获取数据接入列表
  const getDataAccessList = async (
    page: { pageSize: number; pageNum: number },
    data: any
  ) => {
    const param = {
      ...data,
      ...page,
    } as DataAccessListQuery;

    const { total, rows } = await DataAccessListApi(param);
    rows.forEach((v: any, i: Number) => {
      const store1 = accessMethodListStore.length > 0 ? accessMethodListStore : accessMethodList;
      v.createWayLabel = store1.find(
        (s: any) => s.wayKey === v.createWay
      ).name;

      const store2 = platformMethodListStore.length > 0 ? platformMethodListStore : platformMethodList;
      // console.log(store2, 'store2');
      // console.log(platformMethodListStore.length, );


      v.platformTypeLabel = store2.find(
        (s: any) => s.value === v.platformType
      ).label;
    });
    setListData(rows);
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
  };

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  }
  const getHeight = (e: string) => {
    setScrollY(e)
  }

  useEffect(() => {
    getPlatformMethodList();
  }, []);

  return (
    <div>
      <div className={styles.titleAndOperate}>
        <div>
          <ModuleTitle mark="large" title="数据接入列表" />
        </div>
        <div className={styles.buttonBox}>
          <SearchIconInput
            clear={true}
            width="310px"
            placeholder="请输入数据接入名称或店铺名称或店铺ID"
            onSearch={(e: string) => {
              const store = {
                ...searcParam,
                searchValue: e,
              };
              setSearcParam(store);
              getDataAccessList({ pageSize: 10, pageNum: 1 }, store);
            }}
          ></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
          <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
              setCurrent(0);
              setSelectedPlatformId("");
            }}
          >
            新建
          </Button>
        </div>
      </div>
      <div className={styles.dataAccess}>

        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          getHeight={getHeight}
          finish={filterFinish}
        ></FilterView>
        <div>
          <Table
            scroll={{ x: 1400, y: scrollY }}
            columns={columns}
            dataSource={listData}
            rowKey={(record) => record.id}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              showSizeChanger: true,
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={(e) => handleTableChange(e)}
          />
        </div>
        <div>
          <Drawer
            className={styles.drawerStyle}
            title="新建数据接入"
            placement={placement}
            width={400}
            onClose={() => setOpen(false)}
            open={open}
            extra={
              <Space>
                <Button onClick={() => setOpen(false)} style={{ marginRight: 8 }}>
                  取消
                </Button>
                {current === 0 && (
                  <Button
                    type="primary"
                    disabled={!selectedPlatformId}
                    onClick={() => setCurrent(1)}
                  >
                    下一步
                  </Button>
                )}
                {current === 1 && (
                  <Button
                    type="primary"
                    disabled={saveDisabled}
                    onClick={() => {
                      if (childRef.current) {
                        childRef.current.createDataAccess();
                      }
                    }}
                  >
                    保存
                  </Button>
                )}
              </Space>
            }
          >
            <div className={styles.stepsStyle}>
              <Steps
                progressDot
                current={current}
                items={[
                  { title: "选择接入方式" },
                  { title: "配置接入信息" },
                  { title: "完成" },
                ]}
              />
            </div>
            {current === 0 && (
              <SelectAccessMethod
                open={open}
                getSelectedId={getSelectedId}
                accessMethodList={accessMethodList}
              />
            )}
            {current === 1 && (
              <ConfigureAccessInformation
                ref={childRef}
                selectedPlatformId={selectedPlatformId}
                platformMethodList={platformMethodList}
                accessTestClick={getAccessStatus}
                submitSuccess={submitSuccess}
              />
            )}
          </Drawer>
        </div>
        <PromitModal ref={promitModalRef} promitContent={promitContent} />
      </div>
    </div>
  );
};

export default DataAccess;
