export const PointIcon = () => {
  return (
    <svg
      t="1710397032882"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="9885"
      width="22"
      height="22">
      <path
        d="M11.377778 0h1001.244444v1001.244444H11.377778z"
        fill="#FFFFFF"
        fill-opacity="0"
        p-id="9886"></path>
      <path
        d="M121.514667 681.5744h60.074666c0 81.760711 145.749333 157.650489 330.410667 157.650489 181.976178 0 326.178133-73.705244 330.319644-154.100622l0.091023-3.549867h60.074666c0 125.565156-177.015467 217.725156-390.485333 217.725156-210.898489 0-386.207289-89.952711-390.417067-213.1968l-0.068266-4.528356h60.074666-60.074666z m0-173.238044h60.074666c0 81.760711 145.749333 157.650489 330.410667 157.650488 181.976178 0 326.178133-73.728 330.319644-154.100622l0.091023-3.549866h60.074666c0 125.5424-177.015467 217.725156-390.485333 217.725155-210.898489 0-386.207289-89.975467-390.417067-213.219555l-0.068266-4.5056zM512 110.136889c213.469867 0 390.485333 92.16 390.485333 217.725155S725.469867 545.5872 512 545.5872s-390.485333-92.16-390.485333-217.725156S298.530133 110.136889 512 110.136889z m0 60.074667c-184.661333 0-330.410667 75.889778-330.410667 157.650488s145.749333 157.650489 330.410667 157.650489c184.661333 0 330.410667-75.889778 330.410667-157.650489S696.661333 170.211556 512 170.211556z"
        fill="#8C8C8C"
        p-id="9887"></path>
    </svg>
  );
};
