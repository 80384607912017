import React, { useState, useEffect } from 'react';
import styles from './index.module.scss'

const RelationshipDivider = (props: {
  isDisables?: boolean,
  operateType: string,
  size: string,
  level: string,
  textFlag: string,
  dataParams?: any,
  getChangeValue: Function
}) => {

  const { isDisables, operateType, size, level, textFlag, dataParams, getChangeValue = () => {} } = props;

  const valueChange = () => {
    if(operateType === 'view'){
      return;
    }

    if(isDisables){
      return;
    }
    const v = textFlag === 'and' ? 'or' : 'and';
    getChangeValue({...dataParams, v});
  };

  useEffect(() => {
  }, [])

  return (
    <div className={styles.relationshipDivider} style={{paddingTop: size, paddingBottom: size, marginLeft: level === '2' ? '14px' : '-14px'}}>
      <div 
        className={styles.line}
        style={{borderRight: textFlag === 'and' ? '4px' : '8px'}}
      >
      </div>
      <div 
        className={styles.text} 
        style={{cursor: (isDisables || operateType === 'view') ? 'not-allowed' : 'pointer' }}
        onClick={valueChange} 
      >     
        {textFlag === 'and' ? '且' : '或'}
      </div>
    </div>
  );
}

export default RelationshipDivider;