/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { Col, Form, Input, Row, Select, Flex, Button } from 'antd';
import DatasetApi from '@/pages/LuZhiBI/api/dataset';
import { PlusOutlined } from '@ant-design/icons';
import type { FormProps } from 'antd';

interface IDefaultData {
  datasetName: string;
  id: number;
  category: string;
  description: string;
}

interface IProps {
  type: 'create' | 'edit';
  ref: any;
  defaultData: IDefaultData;
  onEmitCloseDrawer: () => void;
  onEmitOpenCreateCategory: () => void;
}
type FieldType = {
  datasetName: string;
  category: string;
  description: string;
};

const DatasetAction: FC<IProps> = forwardRef((props, ref) => {
  const { type } = props;

  const [form] = Form.useForm();

  const onSubmit: FormProps<FieldType>['onFinish'] = async () => {
    form
      .validateFields()
      .then(res => {
        const params: { dsName: string; dsTypeId: string; remark?: string; id?: number } = {
          dsName: res.datasetName,
          dsTypeId: res.category,
          remark: res.description ?? '',
          id: props.defaultData.id,
        };
        if (type === 'create') {
          delete params.id;
        }
        DatasetApi.putDataset(params).then((res) => {
          if (!res) {
            return;
          }
          props.onEmitCloseDrawer();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
    getDatasetCategorySelectList,
  }));
  const resetData = () => {
    // 根据id获取数据 并填充到表单中
    form.setFieldsValue({
      datasetName: props.defaultData.datasetName,
      category: props.defaultData.category,
      description: props.defaultData.description,
    });
  };
  const onEmitOpenCreateCategory = () => {
    props.onEmitOpenCreateCategory();
  };

  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const getDatasetCategorySelectList = () => {
    DatasetApi.getDatasetCategorySelectList().then(res => {
      if (!res) {
        return;
      }
      setCategoryOptions(res.data.map((item: any) => ({ label: item.categoryName, value: item.id })));
      if (type === 'create') {
        form.setFieldsValue({ category: res.data[0].id });
      }
    });
  };
  useEffect(() => {
    getDatasetCategorySelectList();
    if (type === 'create') {
      return;
    }
    resetData();
  }, []);

  return (
    <div>
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType>
              name="datasetName"
              label="数据集名称"
              rules={[{ required: true, message: '请输入数据集名称' }]}
            >
              <Input maxLength={100} showCount placeholder="请输入数据集名称" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Flex gap="middle" justify="space-between" align="center">
              <Form.Item<FieldType>
                style={{ flex: 1 }}
                name="category"
                label="分类"
                rules={[{ required: true, message: '请选择数据集所属的分类' }]}
              >
                <Select placeholder="请选择数据集所属的分类" options={categoryOptions} />
              </Form.Item>
              <Button
                type="link"
                style={{ flex: 'none', padding: 0 }}
                onClick={onEmitOpenCreateCategory}
                icon={<PlusOutlined />}
              >新建分类</Button>
            </Flex>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item<FieldType> name="description" label="描述">
              <Input.TextArea maxLength={100} showCount rows={4} placeholder="请输入本数据集相关的描述" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
});

export default DatasetAction;
