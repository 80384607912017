import React, { useState, useEffect, CSSProperties, useImperativeHandle, forwardRef, } from "react";
import style from './index.module.scss'
import { RightOutlined } from '@ant-design/icons';
import type { DrawerProps, TableProps } from "antd";
import ChangeScore from "../../../UserList/components/ChangeScore";
import {
    Tooltip, Tabs, Select, Table,
} from "antd";
import { checkPermissions } from '@/utils/utils';
import { USER_KEY } from '../../../../../../router/Permissions';
import { memberCardInfo, getMemberCardJourney } from "../../../../../../api/UserInsight/UserInsight";
import { getTableScroll } from '@/store/index'
import { state } from "@/store";

let VipMes = forwardRef((props: {
}, ref: any) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        getVipMes
    }))
    const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId') : '';
    const [open, setOpen] = useState(false);

    //选中的vip卡
    const [checkVipMes, setCheckVipMes] = useState(null as any);
    //选中的tab
    const [checkTabs, setCheckTabs] = useState('1');
    const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
    // 关闭当前抽屉
    const onClose = () => {
        setOpen(false);
    };
    //抽屉  true  开启
    const [openScoreChange, setOpenScoreChange] = useState(false);
    const [popType, setPopType] = useState();
    // 打开变更抽屉 1 积分  2 等级 3 成长值
    const changeScoreItem = (index: any, item: any) => {
        if (item.gradeId) {
            setChangeScoreColomn(item)
            setPopType(index + 1)
            setOpenScoreChange(true);
        }
    };
    // 关闭变更抽屉
    const closeScoreChange = (v: boolean, type?: any) => {
        setOpenScoreChange(v);
        if (type == 1) {
            setTimeout(() => {
                getVipMes()
            }, 5000);
        }
    };
    const openRecordPop = () => {
        setOpen(true);
    };
    //点击 用户的会员卡信息
    const [changeScoreColomn, setChangeScoreColomn] = useState({} as any)
    //会员卡
    const [vipCardList, setVipCardList] = useState([] as any)
    //积分变更
    const [changevipCardList, setChangevipCardList] = useState([] as any)
    //tabel 列表字段
    const levelColumns: TableProps<any>["columns"] = [
        {
            title: "变更时间",
            dataIndex: "eventTime",
            key: "eventTime",
        },
        {
            title: "变更前",
            dataIndex: "beforeValue",
            key: "beforeValue",
            // render: (text: string, record: any) =>
            // <><div>VIP{text}</div></>
        },
        {
            title: "变更后",
            dataIndex: "afterValue",
            key: "afterValue",
        },
        {
            title: "变更原因",
            dataIndex: "changeCause",
            key: "changeCause",
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
        },
    ];
    //tabel 列表字段
    const scoreColumns: TableProps<any>["columns"] = [
        {
            title: "变更时间",
            dataIndex: "eventTime",
            key: "eventTime",
        },
        {
            title: "变更类型",
            dataIndex: "changeType",
            key: "changeType",
        },
        {
            title: "变更量",
            dataIndex: "addPoint",
            key: "addPoint",
        },
        {
            title: "变更后积分总数",
            dataIndex: "afterValue",
            key: "afterValue",
        },
        {
            title: "变更原因",
            dataIndex: "changeCause",
            key: "changeCause",
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
        },
    ];
    //tabel 列表字段
    const growColumns: TableProps<any>["columns"] = [
        {
            title: "变更时间",
            dataIndex: "eventTime",
            key: "eventTime",
        },
        {
            title: "变更前",
            dataIndex: "beforeValue",
            key: "beforeValue",
        },
        {
            title: "变更后",
            dataIndex: "afterValue",
            key: "afterValue",
        },
        {
            title: "变更原因",
            dataIndex: "changeCause",
            key: "changeCause",
        },
        {
            title: "备注",
            dataIndex: "remark",
            key: "remark",
        },
    ];
    //变更 下拉框
    const [vipCardOptions, setVipCardOptions] = useState([] as any)

    //数据列表
    const [listData, setListData] = useState([] as any[]);
    // 页码信息
    const [pageQuery, setPageQuery] = useState({
        pageSize: 10,
        pageNum: 1,
        total: 0,
    });
    // 表格change事件
    const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
        let sortStore: any = {};
        if (!sorter.order) {
            sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
        } else {
            sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
        }

        getVipChangeMes(
            { pageSize: pagination.pageSize, pageNum: pagination.current },
        );
    };
    
    const [scrollY, setScrollY] = useState("")
    //变更表格数据
    const changeRecordData = [
        {
            name: '等级变更记录',
            value: '1',
            children:
                <Table
                    rowKey={(record) => record.oneId}
                    columns={levelColumns}
                    scroll={{ x: 750,}}
                    dataSource={listData}
                    pagination={{
                        total: pageQuery.total,
                        pageSize: pageQuery.pageSize, 
                        current: pageQuery.pageNum, 
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showTotal: (total) => `共 ${total} 条`,
                    }}
                    onChange={handleStandardTableChange}
                />
        },
        {
            name: '积分变更记录',
            value: '2',
            children:
                <Table
                    rowKey={(record) => record.oneId}
                    columns={scoreColumns}
                    dataSource={listData}
                    scroll={{ x: 750, }}
                    pagination={{
                        total: pageQuery.total,
                        pageSize: pageQuery.pageSize,
                        current: pageQuery.pageNum,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showTotal: (total) => `共 ${total} 条`,
                    }}
                    onChange={handleStandardTableChange}
                />
        },
        {
            name: '成长值变更记录',
            value: '3',
            children:
                <Table
                    rowKey={(record) => record.oneId}
                    columns={growColumns}
                    dataSource={listData}
                    scroll={{ x: 750, }}
                    pagination={{
                        total: pageQuery.total,
                        pageSize: pageQuery.pageSize,
                        current: pageQuery.pageNum,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        showTotal: (total) => `共 ${total} 条`,
                    }}
                    onChange={handleStandardTableChange}
                />
        },
    ]
    // 获取数据列表
    const getVipMes = async () => {
        const param = {
            oneId: oneId
        } as any;
        let res;
        res = await memberCardInfo(param);
        if (res) {
            var arr = res.data
            var arr2: any = []
            arr.map((item: any) => {
                var obj = {
                    label: item.memberCardName,
                    value: item.memberCardId
                }
                arr2.push(obj)
            })

            setCheckVipMes(arr[0] ? arr[0].memberCardId : null)//第一张会员卡
            setVipCardList(arr)//会员卡
            setVipCardOptions(arr2)//变更记录
            getVipChangeMes({ pageSize: 10, pageNum: 1 })
        }
    }
    // 获取变更列表
    const getVipChangeMes = async (
        page: { pageSize: number; pageNum: number }
    ) => {
        const param = {
            oneId: oneId,
            ...page,
            type: checkTabs,
            memberCardId: checkVipMes
        } as any;
        let res;
        res = await getMemberCardJourney(param);
        if (res) {
            setListData(res.rows)
            setPageQuery(
                {
                    pageSize: page.pageSize,
                    pageNum: page.pageNum,
                    total: res.total,
                }
            )
        }
    }
    //监听或进入调用
    useEffect(() => {
        getVipMes()
        var arr = [] as any
        if (checkPermissions(USER_KEY.USER_POINTS)) {
            arr.push(
                {
                    name: '积分变更'
                }
            )
        }
        if (checkPermissions(USER_KEY.USER_GRADE)) {
            arr.push(
                {
                    name: '等级变更'
                }
            )
        }
        if (checkPermissions(USER_KEY.USER_GROWTH)) {
            arr.push(
                {
                    name: '成长值变更'
                }
            )
        }
        setChangevipCardList(arr)
        setScrollY(getTableScroll(300))
    }, [])
    //监听或进入调用
    useEffect(() => {
        if (checkVipMes) {
            getVipChangeMes({ pageSize: 10, pageNum: 1 })
        }
    }, [checkTabs, checkVipMes])

    return <>
        <div className={style['VipMes']}>
            <div className={style['VipMes_title']}>会员卡</div>
            <div className={style['VipMes_peopleListBox']}>
                {vipCardList.map((item: any, index: any) => (
                    <div className={item.gradeId?style['VipMes_Item']:style['VipMes_Item-grey']} key={index}>
                        <div className={style['VipMes_Item-rightTop']}>{item.gradeId?'已入会':'未入会'}</div>
                        <div className={style['VipMes_Item-title']}>{item.memberCardName ? item.memberCardName: '-'}</div>
                        <div className={style['VipMes_Item-data']}>
                            <Tooltip placement="top" title={item.gradeName ? item.gradeName:''}>
                                <div style={{ flex: '1 1 33.3%' }}>
                                    <div>当前等级</div>
                                    <div className={style['VipMes_Item-num']}>{item.gradeName ? item.gradeName :'-'}</div>
                                </div>
                            </Tooltip>
                            <Tooltip placement="top" title={item.growthValue}>
                                <div style={{ flex: '1 1 33.3%' }}>
                                    <div>当前成长值</div>
                                    <div className={style['VipMes_Item-num']}>{item.growthValue ? item.growthValue:'-'}</div>
                                </div>
                            </Tooltip>
                            <Tooltip placement="top" title={item.totalPoint}>
                                <div style={{ flex: '1 1 33.3%' }}>
                                    <div>当前积分</div>
                                    <div className={style['VipMes_Item-num']}>{item.totalPoint ? item.totalPoint:'-'}</div>
                                </div>
                            </Tooltip>
                        </div>
                        <div className={style['VipMes_Item-data']}>
                            {changevipCardList.map((itm: any, indx: any) => (
                                <div key={indx} className={item.gradeId?style['VipMes_Item-changepop']:style['VipMes_Item-changepop-grey']} onClick={() => changeScoreItem(indx, item)}>
                                    <div>{itm.name}<RightOutlined style={{ marginLeft: '2px' }} /></div>
                                </div>
                            ))}
                        </div>
                        <div></div>
                    </div>
                ))}
            </div>
            <div className={style['VipMes_title']}>变更记录</div>
            <div className={style["changeRecord"]}>
                <Select
                    style={{ width: '40%', marginBottom: '24px', marginTop: '16px' }}
                    placeholder='请选择'
                    value={checkVipMes}
                    options={vipCardOptions}
                    // allowClear
                    onChange={(data) => {
                        setCheckVipMes(data)
                    }}
                ></Select>
                <Tabs
                    onChange={(key: any) => {
                        setCheckTabs(key)
                    }}
                    type="card"
                    defaultActiveKey={checkTabs}
                    items={changeRecordData.map((_, i) => {
                        const id = String(i + 1);
                        return {
                            label: _.name,
                            key: _.value,
                            children: _.children,
                        };
                    })}
                />
                {/* {true && <Button type='link'
                style={{width:'100%',textAlign: 'center'}}
                onClick={()=>{
                    
                }}>查看更多</Button>} */}
            </div>
            <ChangeScore
                openScoreChange={openScoreChange}
                closeScoreChange={closeScoreChange}
                popType={popType}
                changeScoreColomn={changeScoreColomn}
            />
        </div>
    </>
})
export default VipMes
