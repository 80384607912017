import React from 'react'
import './index.scss'
import { useState, useEffect, useRef } from 'react';
import { Layout, Steps, Form, Input, Button, message, Result, ConfigProvider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getQueryParam } from '../../../utils/tools';
import { emailVal } from "../../../utils/verifyTool";
import ButtonCountdown from '../../../component/ButtonCountdown/buttonCountdown';
import VerifyPasswordStrength from '../../../component/CheckPassword/checkPassword';
import { setUserDetail } from '../../../store'
import { state } from './../../../store/index';
import {
    MailOutlined, SafetyCertificateOutlined, LockOutlined, MobileOutlined
} from '@ant-design/icons';
import { resetUserPsw, sendUserCode, updateUserPhone, updateUserEmail, getPersonalInfo } from '../../../api/auth/auth'
const { Header, Content } = Layout;

type FieldType = {
    phoneNum?: string;
    email?: string;
    code?: string;
    password?: string;
    newPassword?: string;
    newPasswordAgain?: string;
    newEmail?: string;
    newPhoneNum?: string;
};
const bingEmailSteps = [
    {
        key: '录入邮箱',
        title: '录入邮箱',
        content: 'First-content',
    },
    {
        title: '完成',
        key: '完成',
        content: 'Second-content',
    }
];
const editEmailSteps = [
    {
        key: '录入新邮箱',
        title: '录入新邮箱',
        content: 'First-content',
    },
    {
        title: '完成',
        key: '完成',
        content: 'Second-content',
    }
];
const resetPasswordSteps = [
    {
        key: '录入密码',
        title: '录入密码',
        content: 'First-content',
    },
    {
        title: '完成',
        key: '完成',
        content: 'Second-content',
    }
]
const editMobileSteps = [
    {
        key: '录入新手机号',
        title: '录入新手机号',
        content: 'First-content',
    },
    {
        title: '完成',
        key: '完成',
        content: 'Second-content',
    }
];
const bingMobileSteps = [
    {
        key: '录入手机号',
        title: '录入手机号',
        content: 'First-content',
    },
    {
        title: '完成',
        key: '完成',
        content: 'Second-content',
    }
];
let needReLogin = 0
let needBack = 0
const ResetBind = () => {
    const [resetForm] = Form.useForm()
    // const [validationType,setValidationType] = useState('reset');
    // const [userInfo,setuserInfo] = useState('加菲猫');
    const userInfo = getQueryParam(window.location.href, 'username');
    const validationType = getQueryParam(window.location.href, 'type');
    //当前步骤
    const [current, setCurrent] = useState(0);
    const [countdownStatus, setCountdownStatus] = useState(false); //倒计时状态
    const [pageContent, setPageContent] = useState({
        title: '',
        step: [] as any[],
        prompt: '',
        finshTitle: '',
    });

    const VerifyPasswordStrengthRef = useRef<any>(null);
    const fillLoginForm = (e: any, type?: any) => {
        resetForm.setFieldsValue(e.value)
        if (type == 1) {
            let text = e.target.value.replace(/\s*/g, '').replace(/[\u4e00-\u9fa5]/g, '')
            resetForm.setFieldValue('newPassword', text)
            VerifyPasswordStrengthRef.current.checkPassWord(text)
            // VerifyPasswordStrengthRef.current.checkPassWord(e.target.value)
        }
    }
    const navigate = useNavigate();
    //到登陆页面
    const backOutPage = () => {
        navigate(`/login`)
    }
    const backPage = async () => {
        navigate(-1)
    }
    const deadline = Date.now() + 1000 * 60;  //60s倒计时
    const finishCountDown = () => {
        setCountdownStatus(false)
        //倒计时结束时的操作
    }
    // 获取验证码
    const getCode = async () => {
        let data = '';
        switch (validationType) {
            case 'bindPhone':
                data = resetForm.getFieldValue('phoneNum')
                break;
            case 'editPhone':
                data = resetForm.getFieldValue('newPhoneNum')
                break;
            case 'bindEmail':
                data = resetForm.getFieldValue('email')
                break;
            case 'editEmail':
                data = resetForm.getFieldValue('newEmail')
                break;

            default:
                break;
        }
        // const data = validationType === 'bindPhone' ? resetForm.getFieldValue('phoneNum') : resetForm.getFieldValue('email')
        const text = validationType?.indexOf('Phone') !== -1 ? '请先填写手机号码' : '请先填写邮箱'
        if (!data) {
            message.error(text)
            return
        }
        var test = /^[1][3,4,5,7,8,9][0-9]{9}$/
        if (validationType?.indexOf('Phone') !== -1 && !test.test(data)) {
            message.error('请填写正确的手机号')
            return
        }
        var status = 1
        if (validationType?.indexOf('Email') !== -1) {
            var data2 = validationType?.indexOf('edit') !== -1 ? resetForm.getFieldValue('newEmail') : resetForm.getFieldValue('email')
            if (!emailVal(data2)) {
                message.error('请输入正确的邮箱')
                status = 0
                return
            }
        }
        if (!status) {
            return
        }
        const params = {
            emailOrPhone: data
        }
        const res = await sendUserCode(params)
        if (res) {
            setCountdownStatus(true)
        }
    }

    //表单所有校验完成后
    const onFinish = () => {
        var status = 1
        if (validationType === 'reset') {
            // if (resetForm.getFieldValue('password') == resetForm.getFieldValue('newPassword')) {
            //     message.error('密码不能与曾使用密码相同！请重新输入')
            //     status = 0
            //     return
            // }
            if (resetForm.getFieldValue('newPassword').length < 8) {
                message.error('密码不得少于8个字符')
                status = 0
                return
            }
            if (resetForm.getFieldValue('newPassword') != resetForm.getFieldValue('newPasswordAgain')) {
                message.error('请保持两次新密码一致')
                status = 0
                return
            }
        }
        if (validationType?.indexOf('Phone') !== -1) {
            var data = validationType?.indexOf('edit') !== -1 ? resetForm.getFieldValue('newPhoneNum') : resetForm.getFieldValue('phoneNum')
            var test = /^[1][3,4,5,7,8,9][0-9]{9}$/
            if (!test.test(data)) {
                message.error('请填写正确的手机号')
                status = 0
                return
            }
        }
        if (validationType?.indexOf('Email') !== -1) {
            var data2 = validationType?.indexOf('edit') !== -1 ? resetForm.getFieldValue('newEmail') : resetForm.getFieldValue('email')
            if (!emailVal(data2)) {
                message.error('请输入正确的邮箱')
                status = 0
                return
            }
        }
        if (!status) {
            return
        }
        if (validationType === 'reset') {
            resetPassWord()
        } else {
            phoneRegister()
        }
    }
    // 修改密码 
    const resetPassWord = async () => {
        var params = {
            oldPassword: resetForm.getFieldValue('password'),
            newPassword: resetForm.getFieldValue('newPasswordAgain')
        }
        const res = await resetUserPsw(params)
        if (res) {
            needReLogin += 1
            setCurrent(1)
        } else {
            console.log("==========", res);
        }
    }
    // 注册手机号或邮箱
    const phoneRegister = async () => {
        const code = resetForm.getFieldValue('code')
        let emailOrPhone = ''
        let res = undefined
        switch (validationType) {
            case 'bindPhone':
                emailOrPhone = resetForm.getFieldValue('phoneNum')
                break;
            case 'bindEmail':
                emailOrPhone = resetForm.getFieldValue('email')
                break;
            case 'editPhone':
                emailOrPhone = resetForm.getFieldValue('newPhoneNum')
                break;
            case 'editEmail':
                emailOrPhone = resetForm.getFieldValue('newEmail')
                break;
            default:
                break;
        }
        res = validationType == 'editPhone' ? await updateUserPhone({ code, emailOrPhone }) : await updateUserEmail({ code, emailOrPhone })

        if (res) {
            // console.log("====成功======", res);
            const userDetail = await getPersonalInfo()
            if (userDetail) {
                needBack += 1
                setUserDetail(userDetail.data)
                setCurrent(1)
            }
        } else {
            console.log("=====失败=====", res);
        }
    }
    useEffect(() => {
        const obj = { ...pageContent }
        switch (validationType) {
            case 'bindPhone':
                obj.title = '绑定手机号'
                obj.step = bingMobileSteps
                obj.prompt = '为了账户安全，请绑定手机号'
                obj.finshTitle = '绑定手机号完成'
                break;
            case 'bindEmail':
                obj.title = '绑定邮箱'
                obj.step = bingEmailSteps
                obj.prompt = '为了账户安全，请绑定邮箱'
                obj.finshTitle = '绑定邮箱完成'
                break;
            case 'editPhone':
                obj.title = '修改手机号'
                obj.step = editMobileSteps
                obj.prompt = ''
                obj.finshTitle = '修改手机号完成'
                break;
            case 'editEmail':
                obj.title = '修改邮箱'
                obj.step = editEmailSteps
                obj.prompt = ''
                obj.finshTitle = '修改邮箱完成'
                break;
            case 'reset':
                obj.title = '修改密码'
                obj.step = resetPasswordSteps
                obj.prompt = userInfo + '，请重新设置密码'
                obj.finshTitle = '密码修改完成'
                break;
            default:
                break;
        }
        setPageContent(obj);
    }, [])
    const handlePageBack = () => {
        console.log(needReLogin, 'needReLogin', needBack);
        // 此处写你想要触发的事件
        if (needReLogin > 0) {
            localStorage.removeItem('userToken')
        }
        if(needBack > 0){
            backPage()
        }
        // 移除事件监听
        window.removeEventListener('popstate', handlePageBack)
        window.removeEventListener('beforeunload', handlePageBack)
    };
    useEffect(() => {
        // 监听浏览器的返回/刷新事件
        window.addEventListener('popstate', handlePageBack);
        window.addEventListener('beforeunload', handlePageBack);

    }, []);

    return (
        <Layout >
            <Content className={'container'}>
                <div className={'box'}>
                    <div className='lz_resetbind-logo' style={{ backgroundImage: `url(${state.styleStoreData.loginLogo})` }}></div>
                    <div className={'content'}>
                        <div className={'title'}>{pageContent.title}</div>
                        <ConfigProvider 
                            theme={{
                                token:{
                                    fontSizeLG: 14
                                }
                            }}
                        >
                            <Steps current={current} items={pageContent.step} style={{ width: '600px', fontSize: 'var(--text-font5)' }}></Steps>
                        </ConfigProvider>
                        {current < 1 && <Form
                            name="basic"
                            form={resetForm}
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            onFinish={onFinish}
                            style={{ width: '650px', marginTop: '24px', marginLeft: validationType === 'reset' ? '45px' : '80px' }}
                        >
                            {/*提示title*/}
                            <Form.Item<FieldType>
                            >
                                {/* <div className='lz_login-reset-text'>{pageContent.prompt}</div> */}
                            </Form.Item>
                            {/* 绑定手机号登录 */}
                            {validationType === 'bindPhone' && <Form.Item<FieldType>
                                name="phoneNum"
                                rules={[{ required: true, message: '请输入手机号' }]}
                            >
                                <Input placeholder='请输入手机号' onPressEnter={(e) => e.preventDefault()} prefix={<MobileOutlined />} onChange={fillLoginForm} maxLength={11} />
                            </Form.Item>}
                            {/* 绑定邮箱登录 */}
                            {validationType === 'bindEmail' && <Form.Item<FieldType>
                                name="email"
                                rules={[{ required: true, message: '请输入邮箱' }]}
                            >
                                <Input placeholder='请输入邮箱' onPressEnter={(e) => e.preventDefault()} prefix={<MailOutlined />} onChange={fillLoginForm} />
                            </Form.Item>}
                            {/* 修改手机号 */}
                            {validationType === 'editPhone' && <Form.Item<FieldType>
                                name="newPhoneNum"
                                rules={[{ required: true, message: '请输入新手机号' }]}
                            >
                                <Input placeholder='请输入新手机号' onPressEnter={(e) => e.preventDefault()} prefix={<MobileOutlined />} onChange={fillLoginForm} maxLength={11} />
                            </Form.Item>}
                            {/* 修改邮箱 */}
                            {validationType === 'editEmail' && <Form.Item<FieldType>
                                name="newEmail"
                                rules={[{ required: true, message: '请输入新邮箱' }]}
                            >
                                <Input placeholder='请输入新邮箱' onPressEnter={(e) => e.preventDefault()} prefix={<MailOutlined />} onChange={fillLoginForm} />
                            </Form.Item>}
                            {/* 填写验证码 */}
                            {(validationType?.indexOf('Email') !== -1 || validationType?.indexOf('Phone') !== -1) && <Form.Item<FieldType>
                                name="code"
                                rules={[{ required: true, message: '请输入验证码' }]}
                            >
                                <Input placeholder='请输入验证码'
                                    prefix={<SafetyCertificateOutlined />}
                                    onChange={fillLoginForm}
                                    onPressEnter={(e) => e.preventDefault()}
                                    suffix={
                                        !countdownStatus ?
                                            <Button type="link" style={{ fontSize: 'var(--text-font2)', height: 'auto', padding: '0' }} onClick={getCode}>
                                                获取验证码
                                            </Button> :
                                            <ButtonCountdown deadline={deadline} color='var(--text-font-color10)' size='14px' finishFun={finishCountDown} />
                                    }
                                />
                            </Form.Item>}
                            {/*修改密码*/}
                            {validationType === 'reset' && <Form.Item<FieldType>
                                name="password"
                                label="原密码"
                                rules={[{ required: true, message: '请输入原密码' }]}
                            >
                                <Input.Password placeholder='请输入原密码' onPressEnter={(e) => e.preventDefault()} maxLength={32} prefix={<LockOutlined />} onChange={fillLoginForm} />
                            </Form.Item>}
                            {validationType === 'reset' && <Form.Item<FieldType>
                                name="newPassword"
                                label="新密码"
                                rules={[{ required: true, message: '请输入新密码' }]}
                            >
                                <Input.Password placeholder='请输入新密码' onPressEnter={(e) => e.preventDefault()} maxLength={32} prefix={<LockOutlined />} onChange={(e) => fillLoginForm(e, 1)} />
                            </Form.Item>}
                            {validationType === 'reset' && <VerifyPasswordStrength ref={VerifyPasswordStrengthRef} hideText={true} showInfo={true} viewStyle={{ marginTop: '-24px', marginLeft: '80px' }} ></VerifyPasswordStrength>}
                            {validationType === 'reset' && <Form.Item<FieldType>
                                name="newPasswordAgain"
                                label="确认密码"
                                rules={[{ required: true, message: '请再次输入新密码' }]}
                            >
                                <Input.Password placeholder='请再次输入新密码' onPressEnter={(e) => e.preventDefault()} maxLength={32} prefix={<LockOutlined />} onChange={fillLoginForm} />
                            </Form.Item>}
                            {/* 占位 */}
                            <Form.Item></Form.Item>
                            <Form.Item wrapperCol={{ span: validationType === 'reset' ? 20 : 16 }}>
                                <div className='lz_resetbind-operation'>
                                    <Button onClick={() => { navigate(-1) }}>
                                        取消
                                    </Button>
                                    <Button type="primary" htmlType="submit">
                                        下一步
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>}
                        {
                            validationType === 'reset' && current === 1 && <Result
                                style={{ marginTop: '49px', width: '600px' }}
                                status="success"
                                title={pageContent.finshTitle}
                                extra={[
                                    <Button type="primary" key="console" onClick={backOutPage}>
                                        重新登录
                                    </Button>
                                ]}
                            />
                        }
                        {/* 绑定/修改-返回 */}
                        {
                            (validationType?.indexOf('Phone') !== -1 || validationType?.indexOf('Email') !== -1) && current === 1 && <Result
                                style={{ marginTop: '49px', width: '600px' }}
                                status="success"
                                title={pageContent.finshTitle}
                                extra={[
                                    <Button type="primary" key="console" onClick={backPage}>
                                        完成
                                    </Button>
                                ]}
                            />
                        }
                    </div>
                </div>
            </Content>
        </Layout>
    )
}

export default ResetBind
