import { Tooltip } from 'antd';

/**
 * @description: 获取数字的千分位表示
 * @param {number | string} num 数字
 * @param {object} options 选项
 * @param {number} options.digit 保留小数位数，默认为2
 * @param {boolean} options.isRatio 是否转换成百分数，默认为false
 * @param {string} options.nullSymbol 为空时的显示，默认为'-'
 * @returns {string} 返回千分位表示的字符串
 */
export const getNumberWithComma = (
  num: number | string,
  options?: { digit?: number; isRatio?: boolean; nullSymbol?: string },
): string => {
  const { digit = 2, isRatio = false, nullSymbol = '-' } = options || {};
  const newNum = Number(num);
  if (num === null || num === undefined || isNaN(newNum)) {
    return nullSymbol;
  }
  if (isRatio && newNum === 0) {
    return '0%';
  }
  if (isRatio && newNum === 1) {
    return '100%';
  }
  if (isRatio) {
    return `${(newNum * 100).toFixed(digit)}%`;
  }
  if (newNum === 0) {
    return '0';
  }
  return newNum.toFixed(2) === '-0.00'
    ? '0'
    : newNum.toLocaleString('en-US', {
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      });
};

/**
 * @description: 获取文本的 tooltip 显示
 * @param {any} text 文本
 * @param {object} options 选项
 * @param {number} options.rows 显示的行数，默认为1
 * @param {number | string} options.maxWidth 最大宽度，默认为500
 * @param {string} options.placeholder 为空时的显示，默认为''
 * @returns {React.ReactNode} 返回 tooltip 显示的文本
 */
export const getToolTipText = (
  text: any,
  options?: {
    rows?: number;
    maxWidth?: number | string;
    placeholder?: string;
  },
): React.ReactNode => {
  const { rows = 1, maxWidth = 500, placeholder = '' } = options ?? {};
  const item = (
    <div style={{ display: 'inline-flex' }}>
      <p
      style={{
        margin: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-inline-box',
        WebkitLineClamp: rows,
        WebkitBoxOrient: 'vertical',
      }}
    >
      {text ?? placeholder}
    </p>
    </div>
  );
  return (
    <Tooltip title={text} overlayStyle={{ maxWidth }}>
      {item}
    </Tooltip>
  );
};

