export const UpdateRate = ['每天', '每周', '每月']

export enum LogOperate {
  ADD = '新建',
  UPDATE = '编辑',
  ENABLE = '启用',
  DISABLE = '停用',
  DELETE = '删除',
  DOWNLOAD = '下载',
  UPLOAD = '上传',
  DATAUPDATE = '数据更新',
}

//用户状态
export enum AccountStatusEnum {
  LOCKED = 0,
  ENABLED = 1,
}

//用户状态
export enum AccountStatusTextEnum {
  LOCKED_TEXT = '停用',
  ENABLED_TEXT = '启用',
}
export enum CalculateStatus {
  NOTCALCULATED = 0, //未计算
  CALCULATING = 1, //计算中
  SUCCESS = 2, //计算成功
  FAILED = 3 //计算失败
}