import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Select,
  InputNumber,
  message,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import styles from "./index.module.scss";

const { RangePicker } = DatePicker;

const monthData = [
  {label: '1月', value: 1},
  {label: '2月', value: 2},
  {label: '3月', value: 3},
  {label: '4月', value: 4},
  {label: '5月', value: 5},
  {label: '6月', value: 6},
  {label: '7月', value: 7},
  {label: '8月', value: 8},
  {label: '9月', value: 9},
  {label: '10月', value: 10},
  {label: '11月', value: 11},
  {label: '12月', value: 12}
];

const MonthPickerComponent = (props: {
  val: any,
  flag: string,
  openFlag: boolean,
  getSingleOpenStatus?: Function,
  getRangeOpenStatus?: Function,
  getData: Function
}) => {

  const {val, flag, openFlag, getSingleOpenStatus = () => {}, getRangeOpenStatus = () => {}, getData = () => {}} = props;

  const [rangeDate, setRangeDate] = useState([] as number[]);

  const chooseItme = (id: number) => {
    if(flag === 'single'){
      getSingleOpenStatus(false);
      getData(id+'月');
    } else {
      let store: number[] = [];
      if(rangeDate.length === 0){
        store.push(id);
        setRangeDate(store);
      } else if(rangeDate.length === 1){
        store=[...rangeDate];
        store.push(id);
        setRangeDate(store.sort((a: number, b: number) => a - b));
        getRangeOpenStatus(false);
        getData(store.map((a: any, b: number) => a+'月'));
      } else if(rangeDate.length === 2){
        store=[];
        store.push(id);
        setRangeDate(store);
      }
    }
  };

  // 获取月份选择键样式
  const getItemStyle = (v: number) => {
    if(flag === 'single'){
      if(Number(val.value) === v){
        return 'itemSelect';
      } else {
        return 'itemUnSelect';
      }
    } else {
      const store: number[] = [...rangeDate];
      if(store.length === 2){
        if(v > store[0] && v < store[1]){
          return 'rangeStyle'
        } else if(v === store[0] || v === store[1]) {
          return 'itemSelect'
        } else {
          return 'itemUnSelect'
        }
      } else {
        if(store.includes(v)){
          return 'itemSelect';
        } else {
          return 'itemUnSelect';
        }
      }
    }
  };

  // 获取背景色
  const getBackgroundColor = (v: number) => {
    if(flag === 'single'){
      return "var(--background1)";
    } else {
      if(rangeDate.length === 2){
        if(v > rangeDate[0] && v < rangeDate[1]){
          return "var(--background4)";
        } else if(v === rangeDate[0]){
          return "linear-gradient(to right, var(--background1), var(--background4))"
        } else if(v === rangeDate[1]) {
          return "linear-gradient(to right, var(--background4), var(--background1))"
        } else {
          return "var(--background1)";
        }
      } else if(rangeDate.length === 1){
        return "var(--background1)";
      } else {
        return "var(--background1)";
      }
    }
  }

  const handleClickOutside = (e: any) => { 
    if(openFlag){
      if (e.target && !e.target.closest('.modal') && 
      !e.target.closest('.custom-dropdown-container') && 
      !e.target.closest('.ant-picker-panels') &&
      !e.target.closest('.ant-select-dropdown') &&             
      !e.target.closest('.ant-picker-body') &&
      !e.target.closest('.ant-picker-header') &&
      !e.target.closest('.ant-picker') &&
      !e.target.closest('.ant-select') &&
      !e.target.closest('.ant-picker-panel-layout')) {
        if(flag === 'single'){
          getSingleOpenStatus(false);
        } else {
          getRangeOpenStatus(false);
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [])

  return(
    <div className={styles.monthBox}>
      {
        monthData.map((mv: any, mi: number) => {
          return(
            <div className={styles.monthItem} key={mi}>
              <div 
                className={styles.monthItemInner} 
                style={{
                  background: getBackgroundColor(mv.value),
                }}
                >
                <div 
                  className={`${styles.item} ${styles[getItemStyle(mv.value)]}`} 
                  onClick={() => chooseItme(mv.value)}
                >{mv.label}
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default MonthPickerComponent;