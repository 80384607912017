import { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import {
  getPointsListApi,
  getPointStatApi,
} from "../../../../api/recordManagement";
import { Checkbox, Table } from "antd";
import {
  StickText,
  TextColorTypes,
  TitleWithExpansion,
  useGetMemberCardList,
  NumberStatus,
  LinesText,
} from "../../../../component/MiniStyles";
import FilterView, { CrmSearchItem } from "../../../../component/FilterView";
import { permissionMap } from "../../../../assets/public/pageMap";
import {
  PointsItem,
  PointsStatVO,
} from "../../../../api/recordManagement/types";
import checkPermission from "@/crm/assets/public/checkPermission";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

type FieldType = {
  memberCardId?: string;
  changeType?: string;
  startTime?: string;
  endTime?: string;
  searchType?: string;
  searchValue?: string;
};

const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};

function PointsChangeRecord() {
  const { memberCardAllOptions } = useGetMemberCardList();
  const navigate = useNavigate();
  const defaultFormValue = {
    memberCardId: memberCardAllOptions[0]?.value || "",
    changeType: "",
    startTime: "",
    endTime: "",
    searchType: "PHONE",
    searchValue: "",
  };
  const [handFlag, setHandFlag] = useState<boolean>(false);
  const [tableList, setTableList] = useState<PointsItem[]>([]);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const [pointDetail, setPointDetail] = useState<PointsStatVO>({
    subCount: 0,
    addCount: 0,
  });
  const [filterStatus, setFilterStatus] = useState<boolean>(false);
  const filterViewRef = useRef<any>(null);

  const searchList: CrmSearchItem[] = [
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "会员卡",
      key: "memberCardId",
      options: memberCardAllOptions,
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "类型",
      key: "changeType",
      options: [
        {
          value: "",
          label: "全部类型",
        },
        {
          value: "ADD",
          label: "增加",
        },
        {
          value: "SUB",
          label: "减少",
        },
      ],
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "变更时间",
      key: "startTime-endTime",
    },
    {
      searchType: "string",
      searchComponentType: "input",
      // options: searchIdList,
      placeHolder: "请输入",
      key: "searchValue",
      title: "OneID",
    },
  ];

  // 获得table列表
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getPointsListApi({
      ...searchInfo,
      pageNum,
      pageSize,
      handFlag,
    });
    if (res?.code === 200) {
      setTableList(res.data?.records || []);
      setTableInfo({ ...tableInfo, total: res.data?.total || 0 });
    }
  };

  // 获取积分详情
  const getPointDetails = async () => {
    const res = await getPointStatApi({
      memberCardId: searchInfo.memberCardId,
    });
    if (res.code === 200) {
      setPointDetail({ ...res.data });
    }
  };

  // 查询
  const filterFinish = (data: any) => {
    const { startTime = "", endTime = "" } = data;
    setSearchFlag((e) => !e);
    setSearchInfo({ ...data, startTime, endTime, searchType: "ONE_ID" });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };

  // 点击oneid跳转 客户详情页面
  const gotoDetail = (id: string) => {
    if (!id) return;
    navigate("/userInsight/userInsightDocument/UserDetail");
    localStorage.setItem("oneId", id);
    localStorage.setItem("loyaltySearchInfo", JSON.stringify(searchInfo));
    localStorage.setItem("loyaltyTableInfo", JSON.stringify(tableInfo));
    localStorage.setItem("loyaltySearchStatus", filterViewRef.current.arrow);
  };

  // 回显搜索部分
  const handInitSearch = () => {
    if (localStorage.getItem("oneId")) {
      if (localStorage.getItem("loyaltySearchInfo")) {
        const obj =
          JSON.parse(localStorage.getItem("loyaltySearchInfo") || "") ||
          defaultFormValue;
        filterViewRef.current.handChangeForm({
          ...obj,
          "startTime-endTime": obj.startTime
            ? [dayjs(obj.startTime), dayjs(obj.endTime)]
            : "",
        });
        if (localStorage.getItem("loyaltySearchStatus") === "true") {
          filterViewRef.current.showFilterView();
          setFilterStatus(true);
        }
        setSearchInfo(obj);
      }
      if (localStorage.getItem("loyaltyTableInfo")) {
        const obj =
          JSON.parse(localStorage.getItem("loyaltyTableInfo") || "") ||
          defaultTableInfo;
        // setTableInfo({ ...tableInfo, pageNum: obj.pageNum });
        tableInfo.pageNum = obj.pageNum;
      }
      localStorage.removeItem("oneId");
      localStorage.removeItem("loyaltySearchInfo");
      localStorage.removeItem("loyaltyTableInfo");
      localStorage.removeItem("loyaltySearchStatus");
    }
  };

  useEffect(() => {
    if (memberCardAllOptions.length > 0) {
      const obj = {
        ...searchInfo,
        memberCardId: memberCardAllOptions[0]?.value || "",
      };
      if (localStorage.getItem("oneId")) {
        handInitSearch();
        return;
      }
      tableInfo.pageNum = 1;
      setSearchInfo(obj);
      filterViewRef.current.handChangeForm(obj);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(memberCardAllOptions)]);

  useEffect(() => {
    getTableList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableInfo.pageNum,
    tableInfo.pageSize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(searchInfo),
    handFlag,
    searchFlag,
  ]);

  useEffect(() => {
    getPointDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInfo.memberCardId]);

  //数值展示部分
  const DetailsSection = () => {
    const DetailCard = ({
      title = "",
      children,
    }: {
      title: string;
      children: number;
    }) => {
      return (
        <div
          className={style[`detail-card-${title === "积分发放总数" ? 2 : 1}`]}>
          <div className={style["title"]}>{title}</div>
          <div className={style["number"]}>
            {Number(children) ? Number(children).toLocaleString() : 0}
          </div>
        </div>
      );
    };

    return (
      <div
        className={style["details-bgd"]}
        style={filterStatus ? {} : { marginBottom: "-10px" }}>
        <DetailCard title="积分发放总数">{pointDetail.addCount}</DetailCard>
        <DetailCard title="客户已消耗积分">{pointDetail.subCount}</DetailCard>
      </div>
    );
  };

  // 表格部分
  const TableSection = () => {
    return (
      <>
        <div
          style={{
            width: "100%",
            textAlign: "right",
            margin: "0 0 12px 0",
          }}>
          <Checkbox
            checked={handFlag}
            onChange={(e) => {
              setHandFlag(e.target.checked);
            }}>
            仅看手动变更
          </Checkbox>
        </div>
        <Table
          scroll={{ x: 1100 }}
          dataSource={tableList}
          rowClassName={style["table-row"]}
          pagination={{
            current: tableInfo.pageNum,
            pageSize: tableInfo.pageSize,
            total: tableInfo.total,
            onChange: (pageNo, pageSize) =>
              setTableInfo({
                ...tableInfo,
                pageNum: pageNo,
                pageSize,
              }),
            showSizeChanger: true,
            showTotal: (total) => `共 ${total} 条`,
          }}>
          <Table.Column
            width={300}
            fixed="left"
            title="OneID"
            dataIndex="oneId"
            render={(value) => (
              <StickText
                text={value}
                onClick={() => gotoDetail(value)}
                show={checkPermission(permissionMap.get("积分变更记录复制"))}
              />
            )}
          />
          <Table.Column width={200} title="变更时间" dataIndex="createTime" />
          <Table.Column
            width={100}
            title="类型"
            dataIndex="changeTypeLabel"
            render={(value, record: PointsItem) => (
              <TextColorTypes
                text={value}
                status={record.changeType === "ADD"}
              />
            )}
          />
          <Table.Column
            width={150}
            title="变更量"
            dataIndex="changeQuantity"
            render={(value, record: any) => (
              <NumberStatus
                number={value}
                status={record.changeType === "ADD"}
              />
            )}
          />
          <Table.Column
            width={150}
            title="变更后积分总数"
            dataIndex="afterPoint"
          />
          <Table.Column width={200} title="变更原因" dataIndex="changeCause" />
          <Table.Column
            width={300}
            title="备注"
            dataIndex="remark"
            render={(value) => <LinesText>{value || "--"}</LinesText>}
          />
        </Table>
      </>
    );
  };

  return (
    <div className={style["PointsChangeRecord-bgd"]}>
      {/* 标题 */}
      <TitleWithExpansion
        title="积分变更记录"
        searchPermissions={permissionMap.get("积分变更记录查看")}
        onChange={() => {
          setFilterStatus((e) => !e);
          filterViewRef.current.showFilterView();
        }}>
        <>
          {/* 数值展示部分 */}
          <DetailsSection />
          {/* 搜索部分 */}
          <FilterView
            ref={filterViewRef}
            searchList={searchList}
            defaultFormValue={defaultFormValue}
            finish={filterFinish}></FilterView>
        </>
      </TitleWithExpansion>

      {/* 表格部分 */}
      <TableSection />
    </div>
  );
}

export default PointsChangeRecord;
