import { type FC } from "react";
import { useState, useEffect, useRef } from "react";
import aiLogoPic from "@/pages/LuZhiBI/assets/images/ai-logo.png";
import aiLogoRightPic from "@/pages/LuZhiBI/assets/images/ai-logoRight.png";
import chatStylePic from "@/pages/LuZhiBI/assets/images/chatStyle.png";
import question1Pic from "@/pages/LuZhiBI/assets/images/question1.png";
import question2Pic from "@/pages/LuZhiBI/assets/images/question2.png";
import question3Pic from "@/pages/LuZhiBI/assets/images/question3.png";
import question4Pic from "@/pages/LuZhiBI/assets/images/question4.png";
import no1Pic from "@/pages/LuZhiBI/assets/images/no1.png";
import no2Pic from "@/pages/LuZhiBI/assets/images/no2.png";
import no3Pic from "@/pages/LuZhiBI/assets/images/no3.png";
import question5Pic from "@/pages/LuZhiBI/assets/images/question5.png";
import sendInfoPic from "@/pages/LuZhiBI/assets/images/sendInfo.png";
import chatQuestionsPic from "@/pages/LuZhiBI/assets/images/chatQuestions.png";
import analysisProcessPic from "@/pages/LuZhiBI/assets/images/analysisProcess.png";
import dataExplainPic from "@/pages/LuZhiBI/assets/images/dataExplain.png";
import dimensionIconPic from "@/pages/LuZhiBI/assets/images/dimensionIcon.png";
import targetIconPic from "@/pages/LuZhiBI/assets/images/targetIcon.png";
import filterIconPic from "@/pages/LuZhiBI/assets/images/filterIcon.png";
import goodActivePic from "@/pages/LuZhiBI/assets/images/goodActive.png";
import badNormalPic from "@/pages/LuZhiBI/assets/images/badNormal.png";
import goodNormalPic from "@/pages/LuZhiBI/assets/images/goodNormal.png";
import badActivePic from "@/pages/LuZhiBI/assets/images/badActive.png";
import pieResultTypePic from "@/pages/LuZhiBI/assets/images/pieResultType.png";
import tableResultTypePic from "@/pages/LuZhiBI/assets/images/tableResultType.png";
import barResultTypePic from "@/pages/LuZhiBI/assets/images/barResultType.png";
import lineResultTypePic from "@/pages/LuZhiBI/assets/images/lineResultType.png";
import TableStyle from "./AnswerDetail/TableStyle";
import BarStyle from "./AnswerDetail/BarStyle";
import LineStyle from "./AnswerDetail/LineStyle";
import PieStyle from "./AnswerDetail/PieStyle";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import type { TableProps } from "antd";
import ModuleTitle from "@/component/ModuleTitle";
import {
  QuestionCircleOutlined,
  CalendarOutlined,
  TableOutlined,
  LoadingOutlined,
  FileSearchOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { Select, Input, Table, Button, Modal, message, Spin } from "antd";
import { getToolTipText } from "@/pages/LuZhiBI/utils";
import {
  getChatConversation,
  getChatHistoryQuestionList,
  statisticAIFeedBack,
  createAIChatReport,
  getAIDataset,
  getAITop3,
  closeChatConversion,
  getAnswerByDataSet,
} from "@/pages/LuZhiBI/api";
import { IFeedback } from "@/pages/LuZhiBI/api/types";
import ReactMarkdown from "react-markdown";
const ChatBI: FC = () => {
  const navigate = useNavigate();
  const sendRef = useRef<HTMLImageElement>(null);
  const historySectionRef = useRef(null);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const defaultPageInfo = {
    pageNo: 1,
    pageSize: 10,
    total: 0,
  };
  const [pageInfo, setPageInfo] = useState(defaultPageInfo);
  // 当前是首页还是帮助中心页: true帮助中心 false首页
  const [isHelpCenter, setIsHelpCenter] = useState<boolean>(false);
  // 历史问句模块显示隐藏
  const [isShowHistoryQuestion, setIsShowHistoryQuestion] =
    useState<boolean>(false);
  // 所选答案展示类型
  const [answerTypes, setAnswerTypes] = useState<any>([]);
  // 创建报表弹窗
  const [createReportModalVisible, setCreateReportModalVisible] =
    useState(false);
  // 点赞:1 踩:-1 未点赞&&未踩: 0
  const [like, setLike] = useState(0);
  // top3问题
  const [suggest, setSuggest] = useState<any>([]);
  // 最新问题
  const [currentQuestion, setCurrentQuestion] = useState("");
  // 保存报表-报表名称
  const [reportName, setReportName] = useState("");
  // 示例问题
  const [exampleQuestion, setExampleQuestion] = useState("");
  // 主体问答
  const [conversationDataSource, setConversationDataSource] = useState<any>([]);
  // 数据集
  interface MyObject {
    value: number;
    label: string;
  }
  const [dataSet, setDataSet] = useState<MyObject[]>([]);
  // 创建报表
  const [createReportDataIndex, setCreateReportDataIndex] = useState(0);
  // 历史问句数据源
  const [historyListDataSource, setHistoryListDataSource] = useState<any>([]);
  // 前3问题
  const [top3Question, setTop3Question] = useState<any>([]);
  // 回答结果形式数据源
  const initAnswerTypesSource = [
    {
      value: "TABLE",
      label: "表格",
    },
    {
      value: "LINE",
      label: "折线图",
    },
    {
      value: "BAR",
      label: "柱状图",
    },
    {
      value: "PIE",
      label: "饼图",
    },
  ];
   const initTrendAnswerTypesSource = [
    {
      value: "LINE",
      label: "折线图",
    },
    {
      value: "TABLE",
      label: "表格",
    },
    {
      value: "BAR",
      label: "柱状图",
    },
    {
      value: "PIE",
      label: "饼图",
    },
  ];
  const [answerTypesSource, setAnswerTypesSource] = useState<any>();
  // 帮助中心问题
  const [helpQuestions, setHelpQuestions] = useState([
    {
      question: "有哪些数据/有哪些主题",
      showQuestion: "看数据：有哪些数据/有哪些主题",
      answerTitle: "当前所有数据/主题如下：",
    },
    {
      question: "有哪些维度",
      showQuestion: "看维度：有哪些维度",
      answerTitle: "当前所有维度如下：",
    },
    {
      question: "有哪些指标",
      showQuestion: "看指标：有哪些指标",
      answerTitle: "当前所有指标如下：",
    },
  ]);
  interface DataType {
    key: string;
    name: string;
  }

  // 历史问句表格
  const historyColumns: TableProps<DataType>["columns"] = [
    {
      title: "questionRecord",
      dataIndex: "questionRecord",
      key: "questionRecord",
      render: (value, record, index) => (
        <span className={styles.tableText}>
          <span style={{ marginRight: 4, color: "rgba(0, 0, 0, 0.45)" }}>
            {(pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1 === 1 && <img src={no1Pic} />}
            {(pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1 === 2 && <img src={no2Pic} />}
            {(pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1 === 3 && <img src={no3Pic} />}
            {((pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1 !== 1 && (pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1 !== 2 && (pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1 !== 3) && <span>{(pageInfo.pageNo - 1) * pageInfo.pageSize + index + 1}</span>}
          </span>
          <span onClick={() => setCurrentQuestion(value)}>
            {getToolTipText(value)}
          </span>
        </span>
      ),
    },
  ];

  // 切换历史问句模块显示隐藏
  const toggleHistory = () => {
    setIsShowHistoryQuestion(!isShowHistoryQuestion);
  };

  // 问答主体元素滚动到底部
  const scrollToBottom = () => {
    const divElement = document.getElementById("chatSectionWrap");
    if (divElement) {
      setTimeout(() => {
        divElement.scrollTop = divElement.scrollHeight + 160;
      }, 100);
    }
  };

  // 上一个问题是否回答结束
  const getIfLastQuestionFetchEnd = () => {
    if (conversationDataSource.length === 0) {
      return true;
    } else {
      return conversationDataSource[conversationDataSource.length - 1]?.answer
        ?.fetchEnd;
    }
  };
  // 发送问题
  const sendQuestion = (question?: any, questionType?: string) => {
    if (!getIfLastQuestionFetchEnd()) {
      return false;
    }
    if (question === "" && currentQuestion === "" && exampleQuestion === "") {
      message.warning("请输入问题");
      return false;
    }
    setIsHelpCenter(false);
    setCurrentQuestion("");
    let sendInfo = question
      ? question
      : currentQuestion
      ? currentQuestion
        : exampleQuestion;
    sendInfo = sendInfo.replace(/\s+/g, '');
    let newQA = {
      question: sendInfo,
      questionType,
      answer: { hasAnswer: false, explain: "" },
      answerTitle: helpQuestions.filter((item) => item.question === question)[0]
        ?.answerTitle,
      fetchEnd: false,
      explain: "",
      explainShow: false,
      top3: [],
    };
    let dataSource = [...conversationDataSource, newQA];
    setConversationDataSource(dataSource);
    scrollToBottom();
    getChatConversation({ question: sendInfo }).then((res) => {
      // @ts-ignore
      if (res?.code === 200) {
        let finalValue = res?.data.value;
        // if (
        //   Object.keys(res?.data?.dimension ? res?.data?.dimension : {}).length >
        //   2
        // ) {
        //   setAnswerTypes([...answerTypes, "LINE"]);
        //   setAnswerTypesSource([initAnswerTypesSource[1]]);
        // } else {
          if (question?.includes('趋势图')) {
            setAnswerTypesSource(initTrendAnswerTypesSource);
            setAnswerTypes([...answerTypes, "LINE"]);
          } else {
            setAnswerTypesSource(initAnswerTypesSource);
            setAnswerTypes([...answerTypes, "TABLE"]);
          }
        // }
        if (questionType === "help") {
          let helpResultArr: unknown[] = [];
          res?.data.value.forEach((item: any) => {
            helpResultArr.push(Object.values(item)[0]);
          });
          finalValue = helpResultArr;
          dataSource[dataSource.length - 1].answer = {
            data: {
              value: finalValue,
            },
            hasAnswer: true,
            fetchEnd: true,
          };
        } else {
          dataSource[dataSource.length - 1].answer = {
            data: {
              filter: res?.data.filter,
              dimension: Object.values(
                res?.data?.dimension ? res?.data?.dimension : {}
              ),
              dimensionKey: Object.keys(
                res?.data?.dimension ? res?.data?.dimension : {}
              ),
              metric: Object.values(res?.data?.metric ? res?.data?.metric : {}),
              metricKey: Object.keys(
                res?.data?.metric ? res?.data?.metric : {}
              ),
              value: finalValue,
              origin: res?.data,
            },
            explain:
              finalValue && finalValue?.length > 0
                ? ""
                : "抱歉，没有找到相关数据",
            hasAnswer: true,
            fetchEnd: true,
          };
        }
        scrollToBottom();
        // 赋值top3问题
        getAITop3().then((resTop3) => {
          if (resTop3?.code === 200) {
            if (resTop3.data.length > 0) {
              dataSource[dataSource.length - 1].top3 = resTop3.data;
            } else {
              if (res.data?.similarMetric?.length > 0) {
                dataSource[dataSource.length - 1].top3 =
                  res.data.similarMetric.slice(0, 3);
              }
            }
          }
        });
        setConversationDataSource([...dataSource]);
      } else {
        // 跳转到兜底页面
        navigate("/DataBoard/LuZhiBI/ChatBI/noResult");
      }
    });
  };

  // 点击帮助中心
  const clickHelpCenter = () => {
    let newQA = {
      questionType: "helpCenter",
      answer: { fetchEnd: true, hasAnswer: true },
    };
    let dataSource = [...conversationDataSource, newQA];
    setConversationDataSource(dataSource);
    scrollToBottom();
  };

  // 观测接口请求返回数据的时间 15秒第一个状态,剩下都是第二个状态有结果就输出
  useEffect(() => {
    let interval: any;
    const lastDataSourceExplain =
      conversationDataSource[conversationDataSource.length - 1]?.answer
        ?.explain;
    const lastDataSourceType =
      conversationDataSource[conversationDataSource.length - 1]?.questionType;
    const dataPendingDiv = document.getElementById(
      `dataPending_${conversationDataSource.length - 1}`
    );
    const status1IconDiv = document.getElementById(
      `status1Icon_${conversationDataSource.length - 1}`
    );
    const status2IconDiv = document.getElementById(
      `status2Icon_${conversationDataSource.length - 1}`
    );
    if (lastDataSourceExplain === "") {
      const searchMsg1 = "正在为您查询中";
      const searchMsg2 = "正在输出结果中";
      const explainMsg1 = "正在分析数据中";
      const explainMsg2 = "正在输出数据解释";
      let currentMsg = "";
      if (dataPendingDiv && status1IconDiv && status2IconDiv) {
        setTimeout(() => {
          status1IconDiv.style.display = "block";
          status2IconDiv.style.display = "none";
          currentMsg =
            lastDataSourceType === "dataExplain" ? explainMsg1 : searchMsg1;
        }, 0);
        setTimeout(() => {
          status1IconDiv.style.display = "none";
          status2IconDiv.style.display = "block";
          currentMsg =
            lastDataSourceType === "dataExplain" ? explainMsg2 : searchMsg2;
        }, 15000);

        let dots = "...";
        let counter = 0;
        interval = setInterval(function () {
          let dotsToShow =
            counter % 4 === 3
              ? dots.substring(0, 1)
              : dots.substring(0, (counter % 4) + 1);
          dataPendingDiv.innerHTML = currentMsg + dotsToShow;
          counter++;
        }, 500);
      }
    }
    return () => {
      clearInterval(interval);
    };
  }, [conversationDataSource[conversationDataSource.length - 1]?.answer]);

  // 获取相应的数据解释
  const getDataExplain = async (index: number) => {
    let newQA = {
      question: "数据解释" + conversationDataSource[index].question,
      questionType: "dataExplain",
      answer: { hasAnswer: false, explain: "" },
      fetchEnd: false,
    };
    let dataSource = [...conversationDataSource, newQA];
    setConversationDataSource(dataSource);
    scrollToBottom();
    fetch("http://192.168.1.129:14431/api/chat/chat/interpretation", {
      // fetch("http://localhost:3000/api/chat/chat/interpretation", {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        clientid: process.env.REACT_APP_CLIENT_ID || "",
        "Content-Type": "application/json;charset=utf-8",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: JSON.stringify({
        question: dataSource[index].question,
        data: JSON.stringify(dataSource[index].answer.data.value),
      }),
    })
      .then((response: Response) => {
        const reader = response.body?.getReader();
        dataSource[dataSource.length - 1].answer.explain = "";
        if (!reader) {
          throw new Error("ReadableStream not supported");
        }
        return new ReadableStream({
          start(controller) {
            function pump(): Promise<void> {
              // @ts-ignore
              return reader.read().then(({ done, value }) => {
                if (done) {
                  dataSource[dataSource.length - 1].answer = {
                    ...dataSource[dataSource.length - 1].answer,
                    fetchEnd: true,
                  };
                  setConversationDataSource([...dataSource]);
                  controller.close();
                  return;
                }
                const chunk = new TextDecoder().decode(value);
                dataSource[dataSource.length - 1].answer = {
                  fetchEnd: true,
                  explain:
                    dataSource[dataSource.length - 1].answer.explain + chunk,
                  hasAnswer: true,
                };
                scrollToBottom();
                setConversationDataSource([...dataSource]);

                controller.enqueue(chunk);
                return pump();
              });
            }
            return pump();
          },
        });
      })
      .then((stream: ReadableStream<string>) => {
        const reader = stream.getReader();
        function processText({
          done,
          value,
        }: {
          done: boolean;
          value: string;
        }): Promise<void> {
          if (done) {
            return Promise.resolve();
          }
          // @ts-ignore
          return reader.read().then(processText);
        }
        // @ts-ignore
        return reader.read().then(processText);
      })
      .catch((error: Error) => {
        console.error(error);
      });
  };

  // 选择回答形式 表格/柱状图/折线图/饼图
  const handleChangeAnswerTypes = (value: string, index: number) => {
    const newAnswerTypes = [...answerTypes];
    newAnswerTypes[index] = value;
    setAnswerTypes([...newAnswerTypes]);
    if (index === conversationDataSource.length - 1) {
      scrollToBottom();
    }
  };

  // 打开创建报表弹窗
  const toCreateReport = (index: number) => {
    setCreateReportDataIndex(index);
    setCreateReportModalVisible(true);
  };

  // 创建报表
  const createReport = () => {
    const params = {
      reportName: reportName,
      reportData: JSON.stringify(
        conversationDataSource[createReportDataIndex].answer.data.value
      ),
    };
    createAIChatReport(params).then((res) => {
      if (res?.code === 200) {
        message.success("报表创建成功");
      } else {
        message.error(res.msg);
      }
    });
    handleCancelCreateReport();
  };
  // 关闭创建报表弹窗
  const handleCancelCreateReport = () => {
    setCreateReportModalVisible(false);
    setReportName("");
  };

  // 点赞/踩 参数value:取消点赞和踩都false，点赞和取消踩都true
  const changeLikeStatus = async (type: string, index: number) => {
    let likeValue = 0;
    let statusValue = false;
    let msgType = 0;
    if (type === "good") {
      if (like === 1) {
        likeValue = 0;
        statusValue = false;
        msgType = 0; // 取消点赞
      }
      if (like === 0 || like === -1) {
        likeValue = 1;
        statusValue = true;
        msgType = 1; // 点赞
      }
    }
    if (type === "bad") {
      if (like === -1) {
        likeValue = 0;
        statusValue = true;
        msgType = 2; // 取消踩
      }
      if (like === 0 || like === 1) {
        likeValue = -1;
        statusValue = false;
        msgType = 3; // 踩
      }
    }
    const currentData = conversationDataSource[index];
    const params: IFeedback = {
      dimension: currentData.answer.data.dimension,
      metric: currentData.answer.data.metric,
      filter: currentData.answer.data.filter,
      value: statusValue,
    };
    await statisticAIFeedBack(params).then((res) => {
      if (res?.code === 200) {
        setLike(likeValue);
        switch (msgType) {
          case 0:
            message.success("点赞取消");
            break;
          case 1:
            message.success("点赞成功");
            break;
          case 2:
            message.success("取消踩");
            break;
          case 3:
            message.success("踩一下");
            break;
        }
      }
    });
  };
  // 获取Top3指标
  const getTop3 = () => {
    getAITop3().then((res) => {
      if (res?.code === 200) {
        setSuggest(res.data);
      }
    });
  };
  const questionExampleSuffix = (
    <img
      src={sendInfoPic}
      onClick={() => {
        sendQuestion(exampleQuestion, "common");
      }}
      style={{
        width: 16,
        height: 16,
        cursor: "pointer",
      }}
    />
  );

  const questionSuffix = (
    <img
      ref={sendRef}
      onClick={() => {
        sendQuestion(currentQuestion, "common");
      }}
      src={sendInfoPic}
      style={{
        width: 24,
        height: 24,
        cursor: "pointer",
      }}
    />
  );

  // 获取历史问句列表
  const getHistoryQuestionList = () => {
    const params = {
      pageNo: pageInfo.pageNo.toString(),
      pageSize: pageInfo.pageSize.toString(),
    };
    getChatHistoryQuestionList(params).then((res) => {
      if (res?.code === 200) {
        const result = res.data.records.map((item: any) => ({
          questionRecord: item,
        }));
        setHistoryListDataSource(result);
        setPageInfo({
          ...pageInfo,
          total: res.data.total,
        });
      }
    });
  };

  // 获取下拉数据集
  const getDataset = () => {
    getAIDataset().then((res) => {
      if (res?.code === 200) {
        let dataSetArr: object[] = [];
        res.data.forEach((item: { dsName: string; id: number }) => {
          dataSetArr.push({
            label: item.dsName,
            value: item.id,
          });
        });
        // @ts-ignore
        setDataSet(dataSetArr);
      }
    });
  };

  // 结束对话
  const toCloseConversion = () => {
    closeChatConversion().then((res) => {
      if (res?.code === 200) {
        message.success("对话已经结束，重新提问进行新的对话吧~");
      }
    });
  };

  // 设置默认数据展示格式
  // useEffect(() => {
  //   setAnswerTypes([...answerTypes, conversationDataSource[conversationDataSource.length - 1]?.question.includes('趋势图') ? "LINE" : "TABLE"]);
  // }, [conversationDataSource.length]);

  // 回车触发发送问题按钮
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        sendRef.current?.click();
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // 问答区域滚动处理
  useEffect(() => {
    scrollToBottom();
  }, [conversationDataSource.length]);

  // 浏览器尺寸变化，历史问句宽度变化，处理是否结束对话位置
  // useEffect(() => {
  //   let terminalChat = document.getElementById("terminalChat");
  //   if (isShowHistoryQuestion) {
  //     if (terminalChat) {
  //       terminalChat.style.right = "444px";
  //     }
  //   }
  //   if (!isShowHistoryQuestion) {
  //     if (terminalChat) {
  //       terminalChat.style.right = "24px";
  //     }
  //   }
  //   setTimeout(() => {
  //     let terminalChat = document.getElementById("terminalChat");
  //     const resizeObserver = new ResizeObserver((entries) => {
  //       for (let entry of entries) {
  //         if (entry.target === historySectionRef.current) {
  //           if (terminalChat && document.getElementById("historySection")) {
  //             const rightSize = entry.contentRect.width + 24;
  //             terminalChat.style.right = `${rightSize}px`;
  //           }
  //         }
  //       }
  //     });
  //     if (historySectionRef.current) {
  //       resizeObserver.observe(historySectionRef.current);
  //     }
  //     return () => {
  //       if (historySectionRef.current) {
  //         resizeObserver.unobserve(historySectionRef.current);
  //       }
  //     };
  //   }, 10);
  // }, [isShowHistoryQuestion, conversationDataSource.length]);

  useEffect(() => {
    getHistoryQuestionList();
  }, [pageInfo.pageNo, pageInfo.pageSize]);

  useEffect(() => {
    getTop3();
    getDataset();
  }, []);

  return (
    <div>
      {/* 顶部 */}
      <div className={styles.headerSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ModuleTitle mark="large" title={"AI查询"} />
        </div>
        <div style={{ display: "flex" }}>
          <div
            className={styles.chatBtn}
            onClick={() => {
              window.open("/bi/DataBoard/LuZhiBI/ChatBI", "_blank");
            }}
            style={{ marginRight: 16 }}
          >
            <TableOutlined style={{ marginRight: 5 }} />
            对话分析列表
          </div>
          {/* <div className={styles.historyBtn} onClick={toggleHistory}>
            <CalendarOutlined style={{ marginRight: 5 }} />
            历史问句
          </div> */}
        </div>
      </div>


        {/* 历史 */}
          <div
            className={styles.historySection}
            id="historySection"
            ref={historySectionRef}
          >
            <div className={styles.title}>历史问句</div>
            <div className={styles.table}>
              <Table
                columns={historyColumns}
                dataSource={historyListDataSource}
                pagination={{
                  size: "small",
                  current: Number(pageInfo.pageNo),
                  pageSize: Number(pageInfo.pageSize),
                  total: pageInfo.total,
                  onChange: (pageNo, pageSize) =>
                    setPageInfo({
                      ...pageInfo,
                      pageNo: pageNo,
                      pageSize: pageSize,
                    }),
                  showSizeChanger: true,
                  showTotal: (total) => `共 ${total} 条`,
                }}
              />
            </div>
      </div>
      
      <div className={styles.mainSection}>
        {/* 对话 */}
        {!isHelpCenter && (
          <div
            id="chatSectionWrap"
            className={styles.chatSectionWrap}
            style={{
              width: isShowHistoryQuestion ? "calc(100% - 256px)" : "100%",
            }}
          >
            <div className={styles.chatSection} >
              <img src={aiLogoPic} className={styles.aiLogo} />

              <div className={styles.chatStyles}>
                <div className={styles.header}>
                  <img src={chatStylePic} className={styles.chatStyle} />
                  <img src={aiLogoRightPic} className={styles.aiLogoRight} />
                </div>
                <div className={styles.main}>
                  {/* <div className={styles.exampleQuestionsTitle}>
                    你可能想选择常用图表提问？
                  </div>
                  <div
                    className={styles.exampleQuestionsItems}
                    onClick={() => sendQuestion("数据总览", "common")}
                  >
                    <img src={question1Pic} className={styles.questionLogo} />
                    <span>数据总览</span>
                  </div>
                  <div
                    className={styles.exampleQuestionsItems}
                    onClick={() => sendQuestion("客户资产分析", "common")}
                  >
                    <img src={question2Pic} className={styles.questionLogo} />
                    <span>客户资产分析</span>
                  </div>
                  <div
                    className={styles.exampleQuestionsItems}
                    onClick={() => sendQuestion("客户交易分析", "common")}
                  >
                    <img src={question3Pic} className={styles.questionLogo} />
                    <span>客户交易分析</span>
                  </div> */}
                  {/* <div className={styles.exampleQuestionsTitle}>
                    你可能想选择常用数据集提问？
                  </div>
                  <div className={styles.exampleQuestionsItems}>
                    <img src={question4Pic} className={styles.questionLogo} />
                    <Select
                      placeholder="请选择数据集"
                      style={{ width: 140 }}
                      onChange={(value) => {
                        setCurrentQuestion(
                          dataSet.filter((item) => item.value === value)[0]
                            .label
                        );
                      }}
                      options={dataSet}
                    />
                  </div> */}
                  <div className={styles.exampleQuestionsTitle}>
                    你可能想查找图表进行提问？
                  </div>
                  <div className={styles.exampleQuestionsItems}>
                    <img src={question5Pic} className={styles.questionLogo} />
                    <Input
                      suffix={questionExampleSuffix}
                      placeholder="输入自然语言查找相关图表，比如今年支付GMV趋势图"
                      value={exampleQuestion}
                      onChange={(e) => {
                        setExampleQuestion(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {conversationDataSource.map((item: any, index: number) => (
              <div className={styles.chatSection} key={`chat_${index}`}>
                {item.questionType !== "helpCenter" ? (
                  item.questionType === "dataExplain" ? (
                    <div className={styles.questionElement}>
                      <span className={styles.content}>
                        <span style={{ fontWeight: "bold" }}>
                          {item.question.slice(0, 4)}
                        </span>
                        <span>
                          {item.question.slice(4, item.question.length)}
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className={styles.questionElement}>
                      <span className={styles.content}>{item.question}</span>
                    </div>
                  )
                ) : (
                  <div
                    className={styles.questionElement}
                    style={{ background: "#fff", height: 1 }}
                  ></div>
                )}
                {item.answer?.fetchEnd && (
                  <div>
                    <img src={aiLogoPic} className={styles.aiLogo} />
                    {/* 常规问题回答元素 */}
                    {item.answer.hasAnswer &&
                      item.questionType === "common" && (
                        <div className={styles.answerSection}>
                          <div className={styles.answerBorder}>
                            <div className={styles.header}>
                              <img
                                src={analysisProcessPic}
                                className={styles.chatStyle}
                              />
                              <img
                                src={aiLogoRightPic}
                                className={styles.aiLogoRight}
                              />
                              {/* <div className={styles.info}>
                          用户想要查看2024年至今每个月的销售额情况，我们可以通过SQL查询从'订单表'中提取2024年每个月的销售额，然后对结果进行汇总。我们需要注意的是，'订单日期'字段是日期类型，我们需要使用SQL的年份和月份函数来提取年份和月份信息。
                        </div> */}
                            </div>
                            {item.answer.hasAnswer &&
                              item.answer.explain === "" && (
                                <div>
                                  <div className={styles.main}>
                                    <div
                                      className={styles.searchTypes}
                                      style={{ marginBottom: 0 }}
                                    >
                                      <div
                                        className={`${styles.dimension} ${styles.item}`}
                                      >
                                        <img
                                          src={dimensionIconPic}
                                          alt="维度"
                                        />
                                        <div className={styles.typeName}>
                                          维度
                                        </div>
                                        <div>
                                          {item.answer.data &&
                                          item.answer.data?.dimension.length > 0
                                            ? item.answer.data?.dimension?.map(
                                                (
                                                  dimensionItem: string,
                                                  dimensionIndex: number
                                                ) => (
                                                  <span
                                                    className={styles.content}
                                                    key={`dimension_${dimensionIndex}`}
                                                  >
                                                    {dimensionItem}
                                                  </span>
                                                )
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`${styles.target} ${styles.item}`}
                                      >
                                        <img src={targetIconPic} alt="指标" />
                                        <div className={styles.typeName}>
                                          指标
                                        </div>
                                        <div>
                                          {item.answer.data &&
                                          item.answer.data?.metric.length > 0
                                            ? item.answer.data?.metric?.map(
                                                (
                                                  metricItem: string,
                                                  metricIndex: number
                                                ) => (
                                                  <span
                                                    className={styles.content}
                                                    key={`metric_${metricIndex}`}
                                                  >
                                                    {metricItem}
                                                  </span>
                                                )
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                      <div
                                        className={`${styles.filter} ${styles.item}`}
                                      >
                                        <img src={filterIconPic} alt="筛选" />
                                        <div className={styles.typeName}>
                                          筛选
                                        </div>
                                        <div>
                                          {item.answer.data &&
                                          item.answer.data?.filter &&
                                          item.answer.data?.filter.length > 0
                                            ? item.answer.data?.filter?.map(
                                                (
                                                  screenItem: string,
                                                  screenIndex: number
                                                ) => (
                                                  <span
                                                    className={styles.content}
                                                    key={`screen_${screenIndex}`}
                                                  >
                                                    {screenItem}
                                                  </span>
                                                )
                                              )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                    {conversationDataSource[index].answer.data
                                      .value && (
                                      <div className={styles.answerMain}>
                                        <div className={styles.answerHeader}>
                                          <div className={styles.answerTypes}>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={
                                                  answerTypes[index] === "TABLE"
                                                    ? tableResultTypePic
                                                    : answerTypes[index] ===
                                                      "BAR"
                                                    ? barResultTypePic
                                                    : answerTypes[index] ===
                                                      "LINE"
                                                    ? lineResultTypePic
                                                    : pieResultTypePic
                                                }
                                                className={
                                                  styles.answerIconTypes
                                                }
                                              />
                                              <Select
                                                defaultValue={answerTypesSource[0].value}
                                                style={{
                                                  width: 140,
                                                  height: 30,
                                                }}
                                                onChange={(v) => {
                                                  handleChangeAnswerTypes(
                                                    v,
                                                    index
                                                  );
                                                }}
                                                options={answerTypesSource}
                                              />
                                              <div></div>
                                            </div>
                                          </div>
                                          <Button
                                            type="link"
                                            onClick={() =>
                                              toCreateReport(index)
                                            }
                                            style={{ marginRight: -15 }}
                                          >
                                            +创建报表
                                          </Button>
                                        </div>
                                        <div className={styles.answerContent}>
                                          {answerTypes[index] === "TABLE" && (
                                            <TableStyle
                                              dataSource={
                                                conversationDataSource[index]
                                                  .answer.data
                                              }
                                            />
                                          )}
                                          {answerTypes[index] === "BAR" && (
                                            <BarStyle
                                              index={index}
                                              dataSource={
                                                conversationDataSource[index]
                                                  .answer.data
                                              }
                                            />
                                          )}
                                          {answerTypes[index] === "LINE" && (
                                            <LineStyle
                                              index={index}
                                              dataSource={
                                                conversationDataSource[index]
                                                  .answer.data
                                              }
                                            />
                                          )}
                                          {answerTypes[index] === "PIE" && (
                                            <PieStyle
                                              index={index}
                                              dataSource={
                                                conversationDataSource[index]
                                                  .answer.data
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div className={styles.dataExplainBtn}>
                                      <Button
                                        type="default"
                                        className={styles.submitBtn}
                                        onClick={() => getDataExplain(index)}
                                      >
                                        数据解释
                                      </Button>
                                      <div className={styles.agree}>
                                        {like === 1 && (
                                          <img
                                            src={goodActivePic}
                                            alt="已点赞"
                                            style={{
                                              marginRight: 16,
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              changeLikeStatus("good", index)
                                            }
                                          />
                                        )}
                                        {(like === -1 || like === 0) && (
                                          <img
                                            src={goodNormalPic}
                                            alt="未点赞"
                                            style={{
                                              marginRight: 16,
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              changeLikeStatus("good", index)
                                            }
                                          />
                                        )}
                                        {(like === 1 || like === 0) && (
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={badNormalPic}
                                            alt="未踩"
                                            onClick={() =>
                                              changeLikeStatus("bad", index)
                                            }
                                          />
                                        )}
                                        {like === -1 && (
                                          <img
                                            style={{ cursor: "pointer" }}
                                            src={badActivePic}
                                            alt="已踩"
                                            onClick={() =>
                                              changeLikeStatus("bad", index)
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {item.explainShow && (
                                      <div
                                        className={styles.dataExplainContent}
                                      >
                                        {item.explain ? (
                                          item.explain
                                        ) : (
                                          <Spin indicator={antIcon} />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            {item.answer.hasAnswer &&
                              item.answer.explain !== "" && (
                            <div style={{ margin: 24 }} className={styles.noData}>
                                抱歉，没有找到相关数据<br />
                                <Button type='link' onClick={() => {navigate("/files/LuZhiBI/IndicatorPlatform/AtomicIndicators");}}>去创建原子指标，再继续提问～</Button>
                                </div>
                              )}
                          </div>
                          <div className={styles.suggest}>
                            <span
                              style={{
                                fontSize: 14,
                                color: "rgba(0,0,0,0.88)",
                                minWidth: 160,
                              }}
                            >
                              你是不是要找如下指标？
                            </span>
                            <div>
                              {/* {conversationDataSource[index]?.top3.map( */}
                              {suggest.map(
                                (metricItem: string, metricIndex: number) => (
                                  <span
                                    key={`metric_${metricIndex}`}
                                    className={styles.content}
                                    onClick={() =>
                                      setCurrentQuestion(metricItem)
                                    }
                                  >
                                    {metricItem}
                                  </span>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* 数据集-维度、指标回答元素 */}
                    {item.answer.hasAnswer &&
                      item.questionType === "dataSet" && (
                        <div className={styles.dataSetAnswerSection}>
                          <div className={styles.question}>
                            当前数据集：
                            <span style={{ fontWeight: "bold" }}>
                              [{item.question}]
                            </span>
                            ，包括以下维度和指标：
                          </div>
                          <div className={styles.main}>
                            <div className={styles.dimensions}>
                              <div className={styles.title}>维度：</div>
                              {item.answer.data.dimensions.map(
                                (
                                  dimensionsItem: string,
                                  dimensionsIndex: string
                                ) => {
                                  return (
                                    <div key={`dimensions_${dimensionsIndex}`}>
                                      {dimensionsItem}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                            <div className={styles.indicators}>
                              <div className={styles.title}>指标：</div>
                              {item.answer.data.indicators.map(
                                (
                                  indicatorsItem: string,
                                  indicatorsIndex: string
                                ) => {
                                  return (
                                    <div key={`dimensions_${indicatorsIndex}`}>
                                      {indicatorsItem}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    {/* 帮助问题回答元素 */}
                    {item.answer.hasAnswer &&
                      item.questionType === "help" &&
                      item.answer.data.value && (
                        <div className={styles.helpAnswerSection}>
                          <div className={styles.dataSetAnswerSection}>
                            <div className={styles.question}>
                              <span style={{ fontWeight: "bold" }}>
                                {item.answerTitle}
                              </span>
                            </div>
                            <div className={styles.main}>
                              <div className={styles.dimensions}>
                                {item.answer.data?.value?.map(
                                  (answerItem: string) => {
                                    return (
                                      <div key={`answer_${answerItem}`}>
                                        {answerItem}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {/* 数据解释 15秒第一个状态,剩下都是第二个状态有结果输出*/}
                    {item.questionType === "dataExplain" && (
                      <div className={styles.answerTemplate}>
                        <div className={styles.answerSection}>
                          <div className={styles.answerBorder}>
                            <div className={styles.header}>
                              <img
                                src={dataExplainPic}
                                className={styles.chatStyle}
                              />
                              <div className={styles.blueLine}></div>
                              <span className={styles.explainQuestionsName}>
                                {item.question.slice(4, item.question.length)}
                              </span>
                              <img
                                src={aiLogoRightPic}
                                className={styles.aiLogoRight}
                              />
                            </div>
                            <div className={styles.dataShow}>
                              <div style={{ fontWeight: "bold" }}>
                                数据解释：
                              </div>
                              {/* <div dangerouslySetInnerHTML={{ __html: item.answer.explain }} id={`explainHtml${index}`}></div> */}
                              <ReactMarkdown children={item.answer.explain} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* 帮助中心问题集 */}
                    {item.questionType === "helpCenter" && (
                      <div className={styles.chatSection}>
                        <div className={styles.chatStyles}>
                          <div className={styles.header}>
                            <img
                              src={
                                isHelpCenter ? chatQuestionsPic : chatStylePic
                              }
                              className={styles.chatStyle}
                            />
                            <img
                              src={aiLogoRightPic}
                              className={styles.aiLogoRight}
                            />
                          </div>
                          <div className={styles.main}>
                            {helpQuestions.map((helpItem) => {
                              return (
                                <div
                                  className={styles.exampleQuestionsTitle}
                                  style={{ marginBottom: 0 }}
                                  onClick={() =>
                                    sendQuestion(helpItem.question, "help")
                                  }
                                >
                                  {helpItem.showQuestion}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* 数据未返回 */}
                {!item.answer?.fetchEnd &&
                  (item.questionType === "common" ||
                    item.questionType === "dataExplain") && (
                    <div className={styles.answerTemplate}>
                      <img src={aiLogoPic} className={styles.aiLogo} />
                      <div className={styles.answerSection}>
                        <div className={styles.answerBorder}>
                          <div className={styles.header}>
                            <img
                              src={
                                item.questionType === "common"
                                  ? analysisProcessPic
                                  : dataExplainPic
                              }
                              className={styles.chatStyle}
                            />
                            <div className={styles.blueLine}></div>
                            {item.questionType === "dataExplain" && (
                              <span className={styles.explainQuestionsName}>
                                {item.question.slice(4, item.question.length)}
                              </span>
                            )}
                            <img
                              src={aiLogoRightPic}
                              className={styles.aiLogoRight}
                            />
                          </div>
                          <div
                            className={styles.dataShow}
                            style={{ display: "flex" }}
                          >
                            <div
                              id={`status1Icon_${index}`}
                              style={{ display: "none", marginRight: 12 }}
                            >
                              <FileSearchOutlined
                                style={{ color: "#1677FF" }}
                              />
                            </div>
                            <div
                              id={`status2Icon_${index}`}
                              style={{ display: "none", marginRight: 12 }}
                            >
                              <ExportOutlined style={{ color: "#1677FF" }} />
                            </div>
                            <div id={`dataPending_${index}`}>
                              {item.answer.explain}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ))}

            <div  className={styles.terminalAndHelp}>

            <div
          className={styles.helpCenterBtn}
          onClick={() => {
            clickHelpCenter();
          }}
        >
          <QuestionCircleOutlined style={{ marginRight: 5, color: '1677FF' }} />
          帮助中心
            </div>
            
            {conversationDataSource.length > 0 && (
              <div
                id="terminalChat"
                className={`${styles.terminalChat} ${
                  top3Question.length > 0 ? styles.left24 : ""
                }`}
              >
                <div style={{ minWidth: 233 }}>
                  <span>是否结束对话</span>
                  <div className={styles.no}>否</div>
                  <div
                    className={styles.yes}
                    onClick={() => {
                      setConversationDataSource([]);
                      setIsHelpCenter(false);
                      toCloseConversion();
                    }}
                  >
                    是
                  </div>
                </div>
              </div>
              )}
              </div>
          </div>
        )}
      </div>
      {/* 底部 */}
      <div style={{ height: 120 }}></div>
      <div className={styles.inputSection}>
        {/* <div
          className={styles.helpCenterBtn}
          onClick={() => {
            clickHelpCenter();
          }}
        >
          <QuestionCircleOutlined style={{ marginRight: 5 }} />
          帮助中心
        </div> */}
        <Input
          style={{ width: "100%",  borderRadius: 6, height: 56, background: '#FAFBFF'}}
          suffix={questionSuffix}
          value={currentQuestion}
          onChange={(e) => {
            setCurrentQuestion(e.target.value);
          }}
          placeholder='请输入问句直接查询，或者输入"帮助"查看使用说明'
        />
      </div>
      {createReportModalVisible && (
        <Modal
          title="创建报表"
          open={createReportModalVisible}
          onOk={createReport}
          onCancel={handleCancelCreateReport}
          okText="确定"
          cancelText="取消"
        >
          <p style={{ marginTop: 24 }}>报表名称</p>
          <Input
            maxLength={100}
            showCount
            style={{ marginBottom: 12 }}
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
            placeholder="请输入想要创建的报表名称"
          />
        </Modal>
      )}
    </div>
  );
};

export default ChatBI;
