import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { LeftOutlined, FilterOutlined } from '@ant-design/icons';
import type { TableProps } from 'antd';
import { Table, Button } from 'antd';
import FilterView from '../../../../../component/FilterView/filterView';
import SearchIconInput from '../../../../../component/Input/SearchIconInput/searchIconInput';
import CheckProperty from '../checkProperty';
import FilterIcon from '../../../../../component/FilterIcon/filterIcon';
import { getTableScroll } from '@/store/index'
import { getUserPropertyListApi, getUserFieldTypeApi } from '../../../../../api/userCenter/userCenter';
type FieldType = {
  orderByColumn?: string;
  isAsc?: string;
  propertyName?: string;
  createStart?: string;
  createEnd?: string;
  updateStart?: string;
  updateEnd?: string;
  fieldType?: string;
  createOrder?: string;
  updateOrder?: string;
};

let createSort = '';
let updateSort = '';
let searchListData: any = {};
const searchList: searchItem[] = [
  {
    searchType: 'string',
    searchComponentType: 'select',
    placeHolder: '请选择',
    title: '数据类型',
    key: 'fieldType',
    options: [],
  },
  {
    searchType: 'text',
    searchComponentType: 'dateRange',
    placeHolder: '',
    title: '创建时间',
    key: 'createStart-createEnd',
  },

  {
    searchType: 'text',
    searchComponentType: 'dateRange',
    placeHolder: '',
    title: '更新时间',
    key: 'updateStart-updateEnd',
  },
];
function UserProperty({ handBackToList }: { handBackToList: () => void }) {
  const [tableList, setTableList] = useState<any[]>([]);
  const [scrollY, setScrollY] = useState("")

  // 表格内容
  interface DataType {
    id: number;
    field: string;
    fieldName: string;
    columnDescribe: string;
    fieldType: string;
    createBy: string;
    createTime: string;
    updateTime: string;
    action: string;
    fieldShowType: string;
  }
  const columns: TableProps<DataType>['columns'] = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      render: (_, record, i) => <div> {(tableInfo.pageNum - 1) * tableInfo.pageSize + (i + 1)}</div>,
    },
    {
      title: '客户属性',
      dataIndex: 'field',
      key: 'field',
      render: (_, record) => (
        <>
          {record.fieldShowType === '枚举' ? (
            <div
              className={style['field']}
              onClick={() => {
                handleResult(record);
                setRecordList(record);
              }}>
              {record.field}
            </div>
          ) : (
            <div>{record.field}</div>
          )}
        </>
      ),
    },
    {
      title: '展示名',
      dataIndex: 'fieldName',
      key: 'fieldName',
    },
    {
      title: '描述',
      dataIndex: 'columnDescribe',
      key: 'columnDescribe',
      render: (_, record) => (record.columnDescribe ? <div>{record.columnDescribe}</div> : <div>-</div>),
    },
    {
      title: '数据类型',
      dataIndex: 'fieldShowType',
      key: 'fieldShowType',
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime(),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      sorter: (a, b) => new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime(),
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) =>
        record.fieldShowType === '枚举' ? (
          <div>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                handleResult(record);
                setRecordList(record);
              }}>
              查看
            </Button>
          </div>
        ) : null,
    },
  ];
  const filterViewRef = useRef<any>(null);
  const [tableInfo, setTableInfo] = useState({ pageNum: 1, pageSize: 10, total: 0 });
  // 表格的onchange方法
  const handleTableChange = (page: any, pageSize: any, sort: any) => {
    setTableInfo({
      ...tableInfo,
      pageNum: page.current,
      pageSize: page.pageSize,
    });
    if (sort.order) {
      if (sort.columnKey === 'createTime') {
        createSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        updateSort = '';
      } else {
        updateSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        createSort = '';
      }
    } else {
      createSort = '';
      updateSort = '';
    }
    getTableList({ pageNum: page.current, pageSize: page.pageSize });
  };
  // 沙漏里的查询按钮
  const filterFinish = (data: any) => {
    searchListData = data;
    getTableList({ pageNum: 1, pageSize: 10 });
  };
  // 表单数据
  const getTableList = async (page: any) => {
    const res = await getUserPropertyListApi({
      ...page,
      ...searchListData,
      createOrder: createSort,
      updateOrder: updateSort,
    });
    if (res) {
      setTableList(res.data.rows);
      setTableInfo({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      setScrollY(getTableScroll())
    }
  };
  // 监听更新表单
  useEffect(() => {
    getTableList({ pageNum: 1, pageSize: 10 });
  }, []);
  useEffect(() => {
    getUserFieldTypeList();
  }, []);

  // 抽屉里需要的数据
  const [recordList, setRecordList] = useState<any>({});
  const resultRef = useRef<any>(null);
  // 抽屉开关状态
  const [openResult, setOpenResult] = useState(false);
  // 抽屉开关方法
  const handleResult = async (record: any) => {
    if (!openResult) {
      setOpenResult(!openResult);
      resultRef.current.getResultList(record);
    } else {
      setOpenResult(!openResult);
    }
  };

  const getUserFieldTypeList = async () => {
    const res = await getUserFieldTypeApi();
    if (res?.code === 200) {
      const options = res.data.map((item: any) => ({
        ...item,
        value: item.fieldType,
        label: item.fieldName,
      }));
      searchList[0].options = options;
    }
  };
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const getHeight = (e: string) => {
    setScrollY(e)
  }
  // 页面标题和返回按钮
  const HeaderSection = () => {
    return (
      <div className={style['header-bgd']}>
        <div className={style['header-left']}>
          <div
            className={style['header-icon']}
            onClick={() => {
              searchListData = {};
              handBackToList();
            }}>
            <LeftOutlined />
            <span className={style['header_text']}>返回</span>
          </div>
          <div className={style['header-text']}>
            <div>Customer 客户属性表</div>
            <div className={style['header-desc']}>描述：维护客户属性</div>
          </div>
        </div>
        <div className={style['header-right']}>
          {/* 搜索 */}
          <SearchIconInput
            clear={true}
            placeholder="请输入客户属性/展示名"
            onSearch={(e: string) => {
              {
                searchListData.propertyName = e;
                getTableList({ pageNum: 1, pageSize: 10 });
                filterViewRef.current?.resetData();
              }
            }}></SearchIconInput>
          {/* 沙漏 */}
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
    );
  };

  return (
    <div className={style['container']}>
      {/* 页面标题 */}
      <HeaderSection />
      {/* 沙漏下拉框 */}
      <div className='containerBox'>
        <FilterView ref={filterViewRef} getHeight={getHeight} searchList={searchList} finish={filterFinish}></FilterView>
        {/* 表格部分 */}
        <Table
          rowKey="id"
          columns={columns}
          dataSource={tableList}
          scroll={{ x: 1400, y: scrollY }}
          pagination={{
            total: tableInfo.total,
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            showTotal: total => `共 ${total} 条`,
            showSizeChanger: true,
          }}
          onChange={(pagination, pageSize, sort) => handleTableChange(pagination, pageSize, sort)}
        />
      </div>
      
      {/* 抽屉 */}
      <CheckProperty ref={resultRef} open={openResult} drawer={handleResult} record={recordList} />
    </div>
  );
}

export default UserProperty;
