import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Select,
  message,
  Tooltip,
} from "antd";
import type { SelectProps } from "antd";
import {
  DeleteOutlined
} from "@ant-design/icons";
import {
  crowdListApi,
} from "../../../../../../api/label/label";

const Crowd = (props: {
  operateType: string,
  current: number,
  itemData: any,
  ruleData: any,
  val: any,
  idx: number,
  getDeleteItemFunction: Function,
  getRulesChangeFunction: Function
}) => {

  const {
    operateType, 
    current,
    itemData,
    ruleData, 
    val, 
    idx, 
    getDeleteItemFunction = () => {},
    getRulesChangeFunction = () => {}
  } = props;

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [crowdListOptions, setCrowdListOptions] = useState([] as any);

  // 获取分群下拉列表
  const getCrowdListApi = async () => {
    const { data } = await crowdListApi();
    data.forEach((v: any, i: number) => {
      v.label = v.crowdName;
      v.value = v.crowdId;
    });
    setCrowdListOptions(data);
  };

  useEffect(() => {
    if(current === 1 || 
      operateType === "edit" || 
      operateType === "view" || 
      operateType === "copy" || 
      itemData.data_sources === "copy" || 
      ruleData.data_sources === "copy"){
      getCrowdListApi();
    }
  }, []);

  return(
    <div style={{ display: "flex", marginLeft: 8, width: ruleData.rules.length > 1 ? "calc(100% - 8px - (100% - 24px - 10px - 16px)/4)" : "calc(100% - 8px - (100% - 24px)/4)" }}>
      <Select
        disabled={operateType === "view"}
        placeholder="请选择运算条件"
        style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)" }}
        value={val.operator_id}
        onChange={(e) => {
          getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "operator_id", f: 'p'});
        }}
        options={[
          { value: "=", label: "等于" },
          { value: "!=", label: "不等于" },
        ]}
      />
      <Select
        disabled={operateType === "view"}
        placeholder="请选择分群"
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", marginLeft: 8 }}
        value={val.value}
        onChange={(e) => {
          getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "value", f: "p"});
          // rulesChange(e, val.rules_operate_id, "value", "p");
        }}
        options={crowdListOptions}
      />
      {ruleData.rules?.length > 1 && operateType !== "view" && (
        <div
          style={{
            marginLeft: 10,
            height: "32px",
            lineHeight: 'var(--text-font15)',
          }}
        >
        <Tooltip title="删除">
          <DeleteOutlined
            style={{
              cursor: "pointer",
              color: "var(--text-font-color4)",
              fontSize: "var(--text-font7)",
            }}
            onClick={() => getDeleteItemFunction(idx)}
          />
        </Tooltip>
      </div>
    )}
    </div>
  )};

export default Crowd;