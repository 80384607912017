import React, { useState, useEffect } from 'react';
import './style/common.css';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import FrontendAuth from './router/FrontendAuth';

import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
// import './style/skin/default.css';

function App() {
  const [custom, setCustom] = useState<string>('default');

  useEffect(() => {
    import(`./style/skin/${custom}.css`)
      .then(module => console.log('Loaded theme:', custom))
      .catch(error => console.error('Error loading theme:', error));
  }, [custom]);
  return (
    <div className={custom}>
      <ConfigProvider locale={zhCN}>
        <Router>
          <FrontendAuth />
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
