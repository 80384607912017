import { useState, forwardRef, useImperativeHandle } from 'react';
import { InputNumber, message } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const ChangeM = forwardRef((_, ref) => {
  const [value, setValue] = useState<({ id: number; isStart: boolean; value: number | undefined } | { id: number; start: number | undefined; end: number | undefined } | { id: number; isFinal: boolean; value: number | undefined })[]>([
    {
      id: Math.random(),
      isStart: true,
      value: undefined,
    },
    {
      id: Math.random(),
      start: undefined,
      end: undefined,
    },
    {
      id: Math.random(),
      isFinal: true,
      value: undefined,
    },
  ]);

  useImperativeHandle(ref, () => ({
    value,
  }));
  return (
    <div>
      {value.map((i, index) => {
        return <div key={i.id}>
          {
            'isStart' in i || 'isFinal' in i ? <>
              <p style={{ display: 'inline-block', width: 12 }}>{index + 1}:</p>
              <p style={{ display: 'inline-block', width: 8, margin: '0 8px' }}>
                {'isStart' in i ? '<' : '≥'}
              </p>
              <InputNumber
                placeholder={'isStart' in i ? '请输入起始金额' : '请输入最终金额'}
                style={{ width: 282 }}
                min={0}
                max={999999999.99}
                precision={2}
                controls={false}
                value={i.value}
                onChange={(v) => {
                  const newValue = [...value];
                  newValue[index] = { ...i, value: v ?? undefined };
                  setValue(newValue);
                }}
              />
              <span style={{ marginLeft: 14 }}>元</span>
            </> : <>
              <p style={{ display: 'inline-block', width: 12 }}>{index + 1}:</p>
              <p style={{ display: 'inline-block', width: 8, margin: '0 8px' }}>[</p>
              <InputNumber
                placeholder='请输入起始金额'
                style={{ width: 130 }}
                min={0}
                max={999999999.99}
                precision={2}
                controls={false}
                value={i.start}
                onChange={(v) => {
                  const newValue = [...value];
                  newValue[index] = { ...i, start: v ?? undefined };
                  setValue(newValue);
                }}
              />
              <span style={{ margin: '0 8px' }}>-</span>
              <InputNumber
                placeholder='请输入截止金额'
                style={{ width: 130 }}
                min={0}
                max={999999999.99}
                precision={2}
                controls={false}
                value={i.end}
                onChange={(v) => {
                  const newValue = [...value];
                  newValue[index] = { ...i, end: v ?? undefined };
                  setValue(newValue);
                }}
              />
              <span style={{ marginLeft: 8 }}>)元</span>
            </>
          }
          {
            !('isStart' in i || 'isFinal' in i) && <>
              <PlusOutlined
                style={{ margin: '0 8px' }}
                onClick={() => {
                  if (value.length >= 6) {
                    message.error('自定义区间最多6个');
                    return;
                  }
                  const newValue = [...value];
                  newValue.splice(index + 1, 0, { id: Math.random(), start: undefined, end: undefined });
                  setValue(newValue);
                }}
              />
              <DeleteOutlined
                onClick={() => {
                  if (value.length <= 3) {
                    message.error('自定义区间最少3个');
                    return;
                  }
                  setValue(value.filter((_, j) => j !== index));
                }} />
            </>
          }
        </div>;
      })}
    </div>
  );
});

export default ChangeM;