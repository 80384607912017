import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import G6 from '@antv/g6-pc';

// 实际开发中把 window.AntVUtil 换成从 @antv/util 引入的相关模块
// replace window.AntVUtil.isObject with
import { isObject } from '@antv/util';
// const isObject = window.AntVUtil.isObject;
export const FlowChart = ({ chartId, data, colorMap }: { chartId: string; data: any; colorMap: any }) => {
  useEffect(() => {
    handSetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 设置流程图
  const handSetChart = () => {
    G6.registerNode(
      'round-rect',
      {
        drawShape: function drawShape(cfg: any, group: any) {
          const { height = 30, label } = cfg; // 默认宽高
          const stroke = cfg.style.stroke;
          const fontSize = 14;
          const padding = 30;
          const textWidth = (G6.Util.getTextSize(label,  fontSize )[0]) + padding;
          const rect = group.addShape('rect', {
            attrs: {
              x: -textWidth / 2,
              y: -height / 2,
              width: textWidth,
              height,
              radius: height / 2,
              stroke,
              lineWidth: 1.2,
              fillOpacity: 1,
            },
            name: 'rect-shape',
          });
          
          if(label !== 'OneId'){
            group.addShape('circle', {
              attrs: {
                x: -textWidth / 2,
                y: 0,
                r: 3,
                fill: stroke,
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'circle-shape',
            });
          }
          if(label !== 'OneId1' && label !== 'OneId2' ){
            group.addShape('circle', {
              attrs: {
                x: textWidth / 2,
                y: 0,
                r: 3,
                fill: stroke,
              },
              // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
              name: 'circle-shape2',
            });
          }
          return rect;
        },
        getAnchorPoints: function getAnchorPoints() {
          return [
            [0, 0.5],
            [1, 0.5],
          ];
        },
        update: function update(cfg: any, item: any) {
          const group = item.getContainer();
          const children = group.get('children');
          const node = children[0];
          const circleLeft = children[1];
          const circleRight = children[2];

          const stroke = cfg.style.stroke;

          if (stroke) {
            node.attr('stroke', stroke);
            circleLeft.attr('fill', stroke);
            circleRight.attr('fill', stroke);
          }
        },
      },
      'single-node',
    );

    G6.registerEdge('fund-polyline', {
      itemType: 'edge',
      draw: function draw(cfg: any, group: any) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;

        const Ydiff = endPoint.y - startPoint.y;

        const slope = Ydiff !== 0 ? Math.min(500 / Math.abs(Ydiff), 20) : 0;

        const cpOffset = slope > 15 ? 0 : 16;
        const offset = Ydiff < 0 ? cpOffset : -cpOffset;

        const line1EndPoint = {
          x: startPoint.x + slope,
          y: endPoint.y + offset,
        };
        const line2StartPoint = {
          x: line1EndPoint.x + cpOffset,
          y: endPoint.y,
        };

        // 控制点坐标
        const controlPoint = {
          x: ((line1EndPoint.x - startPoint.x) * (endPoint.y - startPoint.y)) / (line1EndPoint.y - startPoint.y) + startPoint.x,
          y: endPoint.y,
        };

        let path = [
          ['M', startPoint.x, startPoint.y],
          ['L', line1EndPoint.x, line1EndPoint.y],
          ['Q', controlPoint.x, controlPoint.y, line2StartPoint.x, line2StartPoint.y],
          ['L', endPoint.x, endPoint.y],
        ];

        if (Math.abs(Ydiff) <= 5) {
          path = [
            ['M', startPoint.x, startPoint.y],
            ['L', endPoint.x, endPoint.y],
          ];
        }

        const endArrow = cfg?.style && cfg.style.endArrow ? cfg.style.endArrow : false;
        // if (isObject(endArrow)) endArrow.fill = stroke;
        const line = group.addShape('path', {
          attrs: {
            path,
            stroke: colorMap[cfg.data && cfg.data.type],
            lineWidth: 1.2,
            endArrow,
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'path-shape',
        });

        const labelLeftOffset = 0;
        const labelTopOffset = 8;
        // amount
        const amount = group.addShape('text', {
          attrs: {
            text: cfg.data && cfg.data.amount,
            x: line2StartPoint.x + labelLeftOffset,
            y: endPoint.y - labelTopOffset - 2,
            fontSize: 14,
            textAlign: 'left',
            textBaseline: 'middle',
            fill: '#000000D9',
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'text-shape-amount',
        });
        // type
        group.addShape('text', {
          attrs: {
            // text: cfg.data && cfg.data.type,
            x: line2StartPoint.x + labelLeftOffset,
            y: endPoint.y - labelTopOffset - amount.getBBox().height - 2,
            fontSize: 14,
            textAlign: 'left',
            textBaseline: 'middle',
            fill: '#000000D9',
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'text-shape-type',
        });
        // date
        group.addShape('text', {
          attrs: {
            text: cfg.data && cfg.data.date,
            x: line2StartPoint.x + labelLeftOffset,
            y: endPoint.y + labelTopOffset + 4,
            fontSize: 14,
            fontWeight: 300,
            textAlign: 'left',
            textBaseline: 'middle',
            fill: '#000000D9',
          },
          // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
          name: 'text-shape-date',
        });
        return line;
      },
    });

    const width = document.getElementById(chartId)?.scrollWidth;
    const height = document.getElementById(chartId)?.scrollHeight || 500;
    const graph = new G6.Graph({
      container: chartId,
      width,
      height,
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesep: 30,
        ranksep: 100,
      },
      modes: {
        default: ['drag-canvas'],
      },
      defaultNode: {
        type: 'round-rect',
        labelCfg: {
          style: {
            fill: '#000000A6',
            fontSize: 14,
          },
        },
        style: {
          stroke: colorMap.A,
          width: 150,
        },
      },
      defaultEdge: {
        type: 'fund-polyline',
      },
    });

    graph.data(data);
    graph.render();

    const edges = graph.getEdges();
    edges.forEach(function (edge) {
      const line = edge.getKeyShape();
      const stroke = line.attr('stroke');
      const targetNode = edge.getTarget();
      targetNode.update({
        style: {
          stroke,
        },
      });
    });
    graph.paint();
  };

  return <div id={chartId} />;
};
