import React from 'react'
import './index.scss'
import { useState, useEffect ,useRef } from 'react';
import { Layout , Steps, Form, Input, Button, message ,Result ,ConfigProvider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getQueryParam } from '../../../utils/tools';
import { emailVal } from "../../../utils/verifyTool";
import ButtonCountdown  from '../../../component/ButtonCountdown/buttonCountdown';
import VerifyPasswordStrength  from '../../../component/CheckPassword/checkPassword';
import {
   MailOutlined, SafetyCertificateOutlined, LockOutlined, MobileOutlined
} from '@ant-design/icons';
import { state } from './../../../store/index';
import { resettingPassWord , loginBindGetCaptcha , loginBindCheckCode } from '../../../api/auth/auth'
const { Header, Content } = Layout;

type FieldType = {
   phoneNum?: string;
   email?: string;
   code?: string;
   password?: string;
   newPassword?: string;
   newPasswordAgain?: string;
 };
const bingEmailSteps = [
   {
       key: '录入邮箱',
       title: '录入邮箱',
       content: 'First-content',
   },
   {
       title: '完成',
       key: '完成',
       content: 'Second-content',
   }
];
const resetPasswordSteps = [
   {
       key: '录入密码',
       title: '录入密码',
       content: 'First-content',
   },
   {
       title: '完成',
       key: '完成',
       content: 'Second-content',
   }
]
const bingMobileSteps = [
   {
       key: '录入手机号',
       title: '录入手机号',
       content: 'First-content',
   },
   {
       title: '完成',
       key: '完成',
       content: 'Second-content',
   }
];
const ResetBind = () => {
   const [resetForm] = Form.useForm()
   // const [validationType,setValidationType] = useState('reset');
   // const userInfo = getQueryParam(window.location.href, 'username');
   const userInfo = localStorage.getItem('username');
   const validationType = getQueryParam(window.location.href, 'type');
   //当前步骤
   const [current, setCurrent] = useState(0);
   const [countdownStatus, setCountdownStatus] = useState(false); //倒计时状态
   const [pageContent,setPageContent] = useState({
      title: '',
      step: [] as any[],
      prompt: '',
      finshTitle: '',
   });
   
   const VerifyPasswordStrengthRef = useRef<any>(null);
   const fillLoginForm = (e: any,type?:any) => {
      resetForm.setFieldsValue(e.value)
      if (type == 1) {
         let text = e.target.value.replace(/\s*/g, '').replace(/[\u4e00-\u9fa5]/g,'')
         resetForm.setFieldValue('newPassword', text)
         VerifyPasswordStrengthRef.current.checkPassWord(text)
      }
   }
   const navigate = useNavigate();
   //到登陆页面
   const backOutPage = () =>{
      navigate(`/login`)
   }
   const deadline = Date.now() + 1000 * 60;  //60s倒计时
   const finishCountDown = () => {
      setCountdownStatus(false)
         //倒计时结束时的操作
   }
   // 获取验证码
   const getCode = async() => {
      const data = validationType === 'bindPhone'? resetForm.getFieldValue('phoneNum') : resetForm.getFieldValue('email')
      const text = validationType === 'bindPhone'? '请先填写手机号码': '请先填写邮箱'
      if (!data) {
         message.error(text)
         return
      }
      var test = /^[1][3,4,5,7,8,9][0-9]{9}$/
      if (validationType === 'bindPhone' && !test.test(resetForm.getFieldValue('phoneNum'))){
         message.error('请填写正确的手机号')
         return
      }
      const params = {
         emailOrPhone: data
      }
      const res = await loginBindGetCaptcha(params)
      if (res) {
         setCountdownStatus(true)
      }
   }

   //表单所有校验完成为 
   const onFinish = () => {
      var status = 1
      if (validationType === 'reset'){
         // if (resetForm.getFieldValue('password') == resetForm.getFieldValue('newPassword')) {
         //   message.error('密码不能与曾使用密码相同！请重新输入')
         //   status = 0
         //   return
         // }
         if (resetForm.getFieldValue('newPassword').length < 8) {
           message.error('密码不得少于8个字符')
           status = 0
           return
         }
         if (resetForm.getFieldValue('newPassword') != resetForm.getFieldValue('newPasswordAgain')) {
           message.error('请保持两次新密码一致')
           status = 0
           return
         }
      }
      if (validationType === 'bindPhone'){
         var test = /^[1][3,4,5,7,8,9][0-9]{9}$/
         if (!test.test(resetForm.getFieldValue('phoneNum'))) {
           message.error('请填写正确的手机号')
           status = 0
           return
         }
      }
      if (validationType === 'bindEmail') {
         if (!emailVal(resetForm.getFieldValue('email'))) {
            message.error('输入正确邮箱')
            status = 0
            return
         }
      }
      if (!status) {
         return
      }
      if (validationType === 'reset') {
         resetPassWord()
      }else{
         phoneRegister()
      }
   }
   const handleKeyDown = (event:any) => {
      if (event.keyCode === 32) {
         event.preventDefault();
      }
   }
   // 重置密码 
   const resetPassWord = async() => {
      var params = {
         oldPassword:resetForm.getFieldValue('password'),
         newPassword:resetForm.getFieldValue('newPasswordAgain')
      }
      const res = await resettingPassWord( params )
      if (res) {
         setCurrent(1)
      } else{
         console.log("==========",res);
      }
   }
   // 注册手机号或邮箱
   const phoneRegister = async() => {
      var params = {
         emailOrPhone:validationType === 'bindPhone'? resetForm.getFieldValue('phoneNum') : resetForm.getFieldValue('email'),
         code:resetForm.getFieldValue('code')
      }
      const res = await loginBindCheckCode( params )
      if (res) {
         console.log("====成功======",res);
         setCurrent(1)
      } else{
         console.log("==========",res);
      }
   }
   useEffect(() => {
      const obj = {...pageContent}
      switch (validationType) {
         case 'bindPhone':
            obj.title = '绑定手机号'
            obj.step = bingMobileSteps
            obj.prompt = '为了账户安全，请绑定手机号'
            obj.finshTitle = '绑定手机号完成'
            break;
         case 'bindEmail':
            obj.title = '绑定邮箱'
            obj.step = bingEmailSteps
            obj.prompt = '为了账户安全，请绑定邮箱'
            obj.finshTitle = '绑定邮箱完成'
            break;
         case 'reset':
            obj.title = '重置密码'
            obj.step = resetPasswordSteps
            obj.prompt = userInfo + '，请重新设置密码'
            obj.finshTitle = '密码重置完成'
            break;
         default:
            break;
      }
      setPageContent(obj);
      resetForm.setFieldValue('password',localStorage.getItem('password'));
   }, [])

   return (
      <Layout >
         <Content className={'container'}>
            <div className={'box'}>
                <div className='lz_resetbind-logo' style={{ backgroundImage: `url(${state.styleStoreData.loginLogo})` }}></div>
                <div className={'content'}>
                    <div className={'title'}>{pageContent.title}</div>
                    <ConfigProvider 
                           theme={{
                              token:{
                                 fontSizeLG: 14
                              }
                           }}
                    >
                     <Steps current={current}  items={pageContent.step} style={{ width: '600px', fontSize: 'var(--text-font5)' }}></Steps>
                    </ConfigProvider>
                    { current < 1 &&<Form
                        name="basic"
                        form={resetForm}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onFinish={onFinish}
                        style={{ width: '650px', marginTop: '24px', marginLeft: '80px' }}
                     >
                        {/*提示title*/}
                        <Form.Item<FieldType>
                        >
                           <div className='lz_login-reset-text'>{pageContent.prompt}</div>
                        </Form.Item>
                        {/* 绑定手机号登录 */}
                        {validationType === 'bindPhone' && <Form.Item<FieldType>
                           name="phoneNum"
                           rules={[{ required: true, message: '请输入手机号' }]}
                        >
                           <Input placeholder='请输入手机号' onPressEnter={(e)=> e.preventDefault()} prefix={<MobileOutlined />} onChange={fillLoginForm} maxLength={11}/>
                        </Form.Item>}
                        {/* 绑定邮箱登录 */}
                        {validationType === 'bindEmail' && <Form.Item<FieldType>
                           name="email"
                           rules={[{ required: true, message: '请输入邮箱' }]}
                        >
                           <Input placeholder='请输入邮箱' onPressEnter={(e)=> e.preventDefault()} prefix={<MailOutlined />} onChange={fillLoginForm}/>
                        </Form.Item>}
                        {(validationType === 'bindEmail' || validationType === 'bindPhone' ) && <Form.Item<FieldType>
                           name="code"
                           rules={[{ required: true, message: '请输入验证码' }]}
                        >
                           <Input placeholder='请输入验证码' 
                              prefix={<SafetyCertificateOutlined />} 
                              onChange={fillLoginForm}
                              onPressEnter={(e)=> e.preventDefault()}
                              suffix={
                                 !countdownStatus?
                                 <Button type="link" style={{ fontSize: 'var(--text-font2)', height: 'auto', padding: '0' }} onClick={getCode}>
                                       获取验证码
                                 </Button>:
                                 <ButtonCountdown deadline={deadline} color='var(--text-font-color10)' size='14px' finishFun={finishCountDown} />
                              } 
                           />
                        </Form.Item>}
                        {/*重置密码*/}
                        {validationType === 'reset' && <Form.Item<FieldType>
                           name="password"
                           rules={[{ required: true, message: '请输入原密码' }]}
                        >
                           <Input.Password placeholder='请输入原密码' onKeyDown={handleKeyDown} onPressEnter={(e)=> e.preventDefault()} maxLength={32} prefix={<LockOutlined />} onChange={fillLoginForm}/>
                        </Form.Item>}
                        {validationType === 'reset' && <Form.Item<FieldType>
                           name="newPassword"
                           rules={[{ required: true, message: '请输入新密码' }]}
                        >
                           <Input.Password placeholder='请输入新密码' value={resetForm.getFieldValue('newPassword')} onKeyDown={handleKeyDown} onPressEnter={(e)=> e.preventDefault()} maxLength={32} prefix={<LockOutlined />} onChange={(e)=>fillLoginForm(e,1)}/>
                        </Form.Item>}
                        {validationType === 'reset' && <VerifyPasswordStrength ref={VerifyPasswordStrengthRef} hideText={true} showInfo={true} viewStyle={{marginTop:'-24px'}} ></VerifyPasswordStrength>}
                        {validationType === 'reset' && <Form.Item<FieldType>
                           name="newPasswordAgain"
                           rules={[{ required: true, message: '请再次输入新密码' }]}
                        >
                           <Input.Password placeholder='请再次输入新密码' onKeyDown={handleKeyDown} onPressEnter={(e)=> e.preventDefault()} maxLength={32} prefix={<LockOutlined />} onChange={fillLoginForm}/>
                        </Form.Item>}
                        <Form.Item>
                           <div className='lz_resetbind-button'>
                                 <Button style={{ marginRight: '8px' }} onClick={backOutPage}>
                                    返回
                                 </Button>
                                 <Button type="primary" htmlType="submit">
                                    下一步
                                 </Button>
                           </div>
                        </Form.Item>
                     </Form>}
                     {
                        validationType === 'reset' && current === 1 && <Result
                            style={{ marginTop: '49px', width: '600px'}}
                            status="success"
                            title={pageContent.finshTitle}
                            extra={[
                                <Button type="primary" key="console" onClick={backOutPage}>
                                    重新登录
                                </Button>
                            ]}
                        />
                    }
                    {
                        (validationType === 'bindPhone' || validationType === 'bindEmail') &&  current === 1 && <Result
                            style={{ marginTop: '49px', width: '600px'}}
                            status="success"
                            title={pageContent.finshTitle}
                            extra={[
                                <Button type="primary" key="console" onClick={backOutPage}>
                                    完成
                                </Button>
                            ]}
                        />
                    }
                </div>
            </div>
         </Content>
      </Layout>
   )
}

export default ResetBind
