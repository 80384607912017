import React, { ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import style from './checkPassword.module.scss'
import { message, Progress } from "antd";
import { red, green, orange } from '@ant-design/colors';
import { highStrengthLevel, middleStrengthLevel } from "../../utils/verifyTool";

let VerifyPasswordStrength = React.forwardRef((props: {
    hideText?: boolean,
    showInfo?:boolean,
    viewStyle?: any
}, ref: any) => {

    // 定义字段
    const [passwordPercent, setPasswordPercent] = useState(0)
    const [passwordColor, setPasswordColor] = useState([] as any[])
    const [passwordTextColor, setPasswordTextColor] = useState('')
    const [passwordText, setPasswordText] = useState('')
    // 默认属性
    const [defaultProperties, setDefaultProperties] = useState({fontSize: 'var(--text-font2)', marginTop: '5px'})

    const checkPassWord = (password: any) => {
        if (highStrengthLevel(password)) {
            setPasswordPercent(100)
            setPasswordColor([red[0], orange[0], green[3]])
            setPasswordTextColor(green[3])
            setPasswordText('高')
        } else if (middleStrengthLevel(password)) {
            setPasswordPercent(66)
            setPasswordColor([red[0], orange[3], green[0]])
            setPasswordTextColor(orange[3])
            setPasswordText('中')
        } else if (!password) {
            setPasswordPercent(0)
            setPasswordColor([red[0], orange[0], green[0]])
            setPasswordText('')
        } else {
            setPasswordPercent(33)
            setPasswordColor([red[3], orange[0], green[0]])
            setPasswordTextColor(red[3])
            setPasswordText('低')
        }
    }
    
    const progressText =() =>{
        return <span style={{ color: passwordTextColor }}>{passwordText}</span>
    }

    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        checkPassWord
    }))

    useEffect(() => {
        
    })

    return <div>
        <div style={props.viewStyle || defaultProperties }>
            {!props.hideText && <span className={style['text_tip']}>密码强度 </span>}
            <Progress percent={passwordPercent} steps={3} strokeColor={passwordColor} showInfo={!(!props.showInfo)} format={progressText} />
        </div>
    </div>
})

export default VerifyPasswordStrength