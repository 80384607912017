import React, { useState, useEffect, useRef } from 'react'
import ModuleTitle from "../../../../../component/ModuleTitle";
import styles from '../../../DimensionalModel/index.module.scss'
import { DimensionPropertyType } from '../../../DimensionalModel/type/data'
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { Table, Button, TableColumnsType, Modal, Form, Upload, message, Drawer, Space  } from "antd";
import type { UploadProps } from 'antd';
import SearchIconInput from '../../../../../component/Input/SearchIconInput/searchIconInput'
import FilterIcon from '../../../../../component/FilterIcon/filterIcon'
import FilterView from '../../../../../component/FilterView/filterView'
import DrawerTable from '../../../components/DrawerTable/drawerTable'
import { getDimensionPropertyListApi, getDimensionDetailInfotApi, skuGoodsExportApi, updateGoodsByExcelApi } from '@/api/dimensionModel/dimensionModel'
import TableCheck from '../PageTableCheck/tableCheck'
import { useLocation, useNavigate } from 'react-router-dom';
import { DIMENSION_KEY } from '@/router/Permissions'
import ButtonPermissions from '@/component/Button/SystemButton'
import {
  LeftOutlined
} from '@ant-design/icons';
import { getTableScroll, state } from '@/store/index'
import { title } from 'process';

const { Dragger } = Upload;
// 列表排序
let createSort = '';
let updateSort = '';
let hiddenIndex = -1;

const TableItem = React.forwardRef((props: {
  info?: any,
  checkBack?: Function,
}, ref: any) => {
  const [accountForm] = Form.useForm();
  const { info } = props
  const [propertyList, setPropertyList] = useState([] as any[])
  const [enumList, setEnumList] = useState([] as any)
  const [isShowDrawer, setIsShowDrawer] = useState(false)
  const filterViewRef: any = useRef(null);
  const [scrollY, setScrollY] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  // 属性分页
  const [pageQuery, setPageQuery] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0
  })
  // 枚举值分页
  const [pageEnum, setPageEnum] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0
  })
  // 查询入参
  let searchListData: any = {}
  // 需要隐藏操作列的表[平台表, 产品线表, 品牌表, 店铺表]
  const hiddenList = [8, 9, 10, 11]
  // 搜索条件列表
  const searchList: searchItem[] = [
      {
        searchType: "text",
        searchComponentType: "input",
        placeHolder: "请输入字段名或展示名",
        title: "字段",
        key: "fieldOrFieldName",
      },
      {
        searchType: "text",
        searchComponentType: "dateRange",
        placeHolder: "",
        title: "创建时间",
        key: "createStartTime-createEndTime",
      },
      {
        searchType: "text",
        searchComponentType: "dateRange",
        placeHolder: "",
        title: "更新时间",
        key: "updateStartTime-updateEndTime",
      },
  ]
  const columns: TableColumnsType<DimensionPropertyType> = [
    {
      title: '序号',
      key: 'index',
      dataIndex: 'index',
      width: '88px',
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: '字段',
      key: 'field',
      dataIndex: 'field',
      width: '150px',
      render: (text: string, record: DimensionPropertyType) => <div className={record.fieldShowType === 'STRING(枚举)'? styles.tableName : ''} onClick={() => {
        openDrawer(record)}}>{text}</div>,
    },
    {
      title: '展示名',
      key: 'fieldName',
      width: '200px',
      dataIndex: 'fieldName',
    },
    {
      title: '描述',
      key: 'columnDescribe',
      width: '300px',
      dataIndex: 'columnDescribe',
    },
    {
      title: '数据类型',
      key: 'fieldShowType',
      width: '150px',
      dataIndex: 'fieldShowType',
    },
    {
      title: '创建人',
      key: 'createBy',
      width: '200px',
      dataIndex: 'createBy',
    },
    {
      title: '创建时间',
      key: 'createTime',
      width: '200px',
      dataIndex: 'createTime',
      sorter: true,
    },
    {
      title: '更新时间',
      key: 'updateTime',
      width: '200px',
      dataIndex: 'updateTime',
      sorter: true,
    },
    {
      title: '操作',
      key: "action",
      dataIndex: 'age',
      hidden: hiddenIndex >= 0? true : false,
      width: '88px',
      render: (_: any, record: DimensionPropertyType) => (
        <>
        {
          record.fieldShowType === 'STRING(枚举)' && <Button type="link" size='middle' onClick={() => openDrawer(record)}>
          查看</Button>
        }
        </>
        
      )
    },
  ];
  const [propertyInfo, setPropertyInfo] = useState({} as DimensionPropertyType)
  const [isShowTableDetail, setIsShowTableDetail] = useState(false)
  const openDrawer = (property: DimensionPropertyType) => {
    // sku, spu表展示抽屉
    // if (info.tableNameEn === 'sku' || info.tableNameEn === 'spu') {
      if(property.fieldShowType !== 'STRING(枚举)') {
        return
      }
      setPropertyInfo(property)
      setIsShowDrawer(true)
      getEnumData({pageNum: 1, pageSize: 10}, property.id, '')
    // } else {
    //   setIsShowTableDetail(true)

    // }
    
  }
  const handelTableChange = (page: any, filters: any, sort: any) => {
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize
    })
    if (sort.order) {
      if (sort.columnKey === 'createTime') {
        createSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        updateSort = ''
      } else {
        updateSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        createSort = '';
      }
    } else {
      createSort = '';
      updateSort = ''
    }
    getPropertyData({pageNum: page.current, pageSize: page.pageSize})

  }
  const filterFinish = (data: any) => {
    searchListData = data;
    getPropertyData({pageNum: 1, pageSize: 10})

  }
  // 操作列是否显示查看按钮
  const isShowBtn = (current: any) => {
    if (info.id === 5 && (current.id === 111 || current.id === 112 || current.id === 113)) {
      return true
    }
    return false
  }
  const getPropertyData = async (page: any) => {
    setPropertyList([])
    const res = await getDimensionPropertyListApi({
      ...page,
      profileId: info.id,
      ...searchListData,
      createOrder: createSort,
      updateOrder: updateSort,
      // modelType: 'DIMENSION',
    })
    if (res) {
      setPropertyList(res.data.rows);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      setScrollY(getTableScroll())
    }
  }

  const getEnumData = async (page: any, id: number, enumCodeOrName: string) => {
    const res = await getDimensionDetailInfotApi({
      pageNum: page.pageNum,
      pageSize: page.pageSize,
      id,
      enumCodeOrName
    })
    if (res) {
      if (res.data) {
        setEnumList(res.data.rows)
        setPageEnum({
          pageSize: page.pageSize,
          pageNum: page.pageNum,
          total: res.data.total
        })
      }
    }
  }
  
  const checkBack = () => {
    if (props.checkBack) {
      props.checkBack();
    }
  }
  const detailTableBack = () => {
    setIsShowTableDetail(false)
  }
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  }
  const onCloseDrawer = () => {
    setIsShowDrawer(false)
    setEnumList([])
  }

  const checkUpload = async () => {
    // console.log(files, 'files');
    if (!files) {
      message.error('请上传.xlsx .xls文件')
      return;
    }
    let fd = new FormData()
    fd.append('file', files)
    const res = await updateGoodsByExcelApi(fd)
    if (res) {
      message.success('上传成功')
      setIsModalOpen(false)
    }
  }
  const cancleUpload = () => {
    accountForm.resetFields();
    setIsModalOpen(false)
    // console.log(uploadForm.getFieldValue('fileName'), '=======================================fileName');
    
    
  }
  // 上传
  let files: any
  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    // customRequest: () => {return},
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      // console.log(info, '============================onChange');
    },
    beforeUpload(info: any) {
      // console.log(info, '============================beforeUpload');
      if (info.name.indexOf('.xlsx') < 0 && info.name.indexOf('.xls') < 0 ) {
        message.error('只能上传.xlsx .xls文件!');
        return;
      }
      const isLt20M = info.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        message.error('文件大小不能超过20MB!');
        return
      }
      files = info
      
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file: any) {
      files = null
      // console.log(file, '====================================remove');
      
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const location = useLocation()
  // 下载数据
  const downloadData = async () => {
    const params = {
      // taskLocation: location.pathname,
      taskLocation: '档案/数据整合/维度模型',
      moduleName: 'sku商品表'
    }
    const res = await skuGoodsExportApi(params)
    if (res.code == 200) {
      message.success('下载成功,请到下载中心页面查看');
    }
    
  }
  const getHeight = (e: string) => {
    setScrollY(e)
  }
  useEffect(() => {
    getPropertyData({pageNum: 1, pageSize: 10})
    hiddenIndex = hiddenList.findIndex((it: any) => it === info.id)
    // console.log(hiddenIndex, 'hiddenIndex');
    
  }, [])

  const BackBtn = () => {
    return (
      <div className={styles['BackList_title']} onClick={checkBack}>
        <LeftOutlined />
        <span className={styles['BackList_text']}>返回</span>
      </div>
    )
  }

  return (
    <>
    {
      isShowTableDetail? <TableCheck checkInfo={info} detailTableBack={detailTableBack} /> :
      <div>
        <div className='headBox'>
          <div style={{display: 'flex'}}>
            <BackBtn />
            <div>
              <ModuleTitle mark="large" title={`${info.tableNameEn}表`} />
              <div style={{color: 'var(--text-font-color4)'}}>描述：{info.tableDescribe}</div> 
            </div>
          </div>
          <div className={styles.searchBox}>
            <SearchIconInput
              placeholder="请输入字段名称或展示名"
              onSearch={(e: string) => { searchListData.fieldOrFieldName = e; getPropertyData({pageNum: 1, pageSize: 10}); filterViewRef.current?.resetData(); }}
            ></SearchIconInput>
            <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
            {(info.id !== 5 && info.id !== 6) && <Button type="primary" onClick={() => setIsShowTableDetail(true)}>查看</Button>}
            {info.id === 6 && <ButtonPermissions permissions={DIMENSION_KEY.DATA_IMPORT} type="primary" onClick={() => {setIsModalOpen(true); accountForm.resetFields()}} title={'导入商品'}></ButtonPermissions>}
          </div>
        </div>
        <div className='containerBox'>
          <FilterView searchList={searchList} getHeight={getHeight} ref={filterViewRef} finish={filterFinish}></FilterView>
          <Table 
            rowKey='id'
            columns={columns}
            scroll={{ x: 1400, y: scrollY }}
            dataSource={propertyList}  
            pagination={{
              current: pageQuery.pageNum,
              pageSize: pageQuery.pageSize,
              total: pageQuery.total,
              showTotal: (total) => `共${total}条`,
              showSizeChanger: true,
            }}
            onChange={(page, filters, sort) => handelTableChange(page, filters, sort)}
            />
        </div>
        <Drawer className={styles.uploadModal} onClose={cancleUpload} open={isModalOpen} width={400} extra={
          <Space>
            <Button onClick={cancleUpload}>取消</Button>
            <Button type="primary" onClick={checkUpload}>
              确定
            </Button>
          </Space>
        }>
        <Form layout="vertical" form={accountForm}>
          <Form.Item
              label="批量更新"
              name="fileName"
              style={{position:'relative'}}
              rules={[{ required: true, message: "" }]}
            >
              <Button className={styles.downLoad_button} type="link" onClick={downloadData} icon={<DownloadOutlined />}>下载模板</Button >
              <Dragger {...uploadProps} style={{height:'168px'}} accept=".xlsx,.xls" maxCount={1}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">
                  支持扩展名：.xlsx .xls
                </p>
                <p className="ant-upload-hint">
                  最大支持20MB
                </p>
              </Dragger>
              <div style={{marginTop: '10px', textAlign: 'center'}}>上传的商品为覆盖上传，请上传完整商品列表</div>
          </Form.Item>
        </Form>
          {
          // <Form
          // className={styles.uploadForm}
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          // initialValues={{ remember: true }}
          // autoComplete="off">  
          //   <Form.Item<any>
          //     label="批量更新"
          //     name="username"
          //     rules={[{ required: true, message: 'Please input your username!' }]}
          //   >
          //   <div style={{display: 'flex', justifyContent: 'space-between'}}>
          //       <Dragger {...uploadProps} accept=".xlsx,.xls">
          //         <p className="ant-upload-drag-icon">
          //           <InboxOutlined />
          //         </p>
          //         <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
          //         <p className="ant-upload-text"> 
          //           支持扩展域名：.xlsx .xls
          //         </p>
          //         <p className="ant-upload-text">
          //           最大支持50MB
          //         </p>
          //       </Dragger>
          //       <Button type="link" size='large' onClick={downloadData}>下载数据</Button>
          //   </div>
          //   <div>上传的商品为覆盖上传，请上传完整商品列表</div>
          //   </Form.Item>
          // </Form>
        }
        </Drawer>
        <DrawerTable 
          isShowDrawer={isShowDrawer} 
          onCloseDrawer={onCloseDrawer} 
          inputText={'请输入字段名或展示名'} 
          titleInfo={propertyInfo} enumList={enumList}
          pageEnum={pageEnum}
          getEnumData={getEnumData}
           />
      </div>
    }
    </>
  )
})
export default TableItem