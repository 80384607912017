import { AxiosPromise } from 'axios';
import request from '../../../api/network'
import {
  PointsListQuery,DetailQuery,EventListQuery,SavePointsQuery
} from './types';
// 会员卡列表
export function getMemberCardListApi(): AxiosPromise<any> {
  return request({
      url: 'crm/member/card/memberCardList',
      method: 'post',
      data: {},
  });
}

// 店铺列表
export function getShopListApi(): AxiosPromise<any> {
  return request({
      url: 'crm/shop/shopList',
      method: 'get',
      params: {},
  });
}

// 根据选中的会员卡查询出的店铺列表
export function getShopListByCardApi(memberCardId:string): AxiosPromise<any> {
  return request({
      url: 'crm/memberCardShop/shopListByCard',
      method: 'get',
      params: {memberCardId},
  });
}

// 积分发放列表
export function getPointsListApi(query: PointsListQuery): AxiosPromise<any> {
  return request({
      url: 'crm/sendPointPlan/page',
      method: 'post',
      data: query,
  });
}
// 新建发放计划
export function getSavePointsListApi(query:SavePointsQuery): AxiosPromise<any> {
  return request({
    
    url: 'crm/sendPointPlan/save',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
      method: 'post',
      data: query,
  });
}

// 查看计划列表
export function getDetailPageApi(query:DetailQuery): AxiosPromise<any> {
  return request({
      url: 'crm/sendPointPlan/detailPage',
      method: 'post',
      data: query,
  });
}

// 撤回积分发放(只有已发放的才可以勾选)
export function getRevokeSendPointApi(planId:any,detailIdList:any): AxiosPromise<any> {
  return request({
      url: 'crm/sendPointPlan/revokeSendPoint',
      method: 'post',
      data: {planId,detailIdList},
  });
}
// 事件查询
export function getEventApi(param:any): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/page',
      method: 'post',
      data: param,
  });
}

// 更换状态
export function getEventStatusApi(id:number,status:number): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/status',
      method: 'post',
      data: {id,status},
  });
}
// 停用
export function getEventStopApi(id:number,status:number): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/stop',
      method: 'post',
      data: {id,status},
  });
}
// 删除
export function getEventDeleteApi(id:number,status:number): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/delete',
      method: 'post',
      data: {id,status},
  });
}
// 新增
export function getAddApi(param:any): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/add',
      method: 'post',
      data: param,
  });
}
// 修改
export function getUpdateApi(param:any): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/update',
      method: 'post',
      data: param,
  });
}
// 新增修改
export function getAddOrUpdateApi(param:any): AxiosPromise<any> {
  return request({
      url: 'crm/reward/event/addOrUpdate',
      method: 'post',
      data: param,
  });
}
// 编辑 
export function getDetailApi(eventId:number): AxiosPromise<any> {
  return request({
      url: `crm/reward/event/detail/${eventId}`,
      method: 'get',
  });
}
// 查询奖励条件配置
export function getConfigListApi(): AxiosPromise<any> {
  return request({
      url: 'crm/reward/config/list',
      method: 'get',
      params: {},
  });
}
// 新增修改奖励策略配置
export function getConfigAddOrUpdateApi(data:any): AxiosPromise<any> {
  return request({
      url: 'crm/reward/config/addOrUpdate',
      method: 'post',
      data: data,
  });
}