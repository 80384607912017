import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Button, Modal } from 'antd';
import styles from './index.module.scss';


const PromitModal = React.forwardRef ((props: {
  promitTitle?: string,
  promitContent?: string,
  hasReason?: boolean,
  flag?: string,
  toTaskCenter?: Function
}, ref: any) => {
  const { 
    promitTitle,
    promitContent, 
    hasReason,
    flag,
    toTaskCenter = () => {}
  } = props;

  // 提示弹窗
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 展开 
  const openModal = () => {
    setIsModalOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openModal, handleCancel
  }))

  // 取消
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal 
        title="提示"
        open={isModalOpen} 
        onCancel={handleCancel}
        footer={false}
      >
        <div className={styles.modalBox}>
          <div>
            {promitTitle && <p style={{ marginBottom: 15, fontWeight: 'var(--text-font-weight3)' }}>{promitTitle}</p>}
            <p>
              {
                hasReason && <span>失败原因：</span>
              }
              {
                !flag ? <span>{promitContent}</span> : <span>（任务进度，可在<span style={{ color: 'var(--text-font-color7)', cursor: 'pointer' }} onClick={() => toTaskCenter()}>【任务中心】</span>查看）</span>
              }
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default PromitModal