import React, { useState, useEffect } from 'react';
import { Table, Button, Tooltip } from "antd";
import type { TableProps } from "antd";
import { useNavigate, useLocation } from 'react-router-dom'
import { DataTableDetailApi } from "../../../../api/collect/collect";
import { DataAccessDetailsListVO } from "../../../../api/collect/types";
import ModuleTitle from "../../../../component/ModuleTitle";
import MiniLabel from "../../../../component/MiniLabel";
import SearchIconInput from "../../../../component/Input/SearchIconInput/searchIconInput";
import styles from './index.module.scss';
import {
  LeftOutlined
} from '@ant-design/icons';
import { getTableScroll, state } from '@/store/index'

const DataAccessDetailsList = () => {

  const navigate = useNavigate();

  let location = useLocation();

  const [columnsData, setColumnsData] = useState([] as any);
  const [scrollY, setScrollY] = useState("")

  interface SearcParamType {
    orderId: string;
    tableName: string;
    shopId: number;
  }

  console.log('11111111111111111111111', location)

  const searcParamInt: SearcParamType = {
    orderId: '',
    shopId: location.state.record.shopId,
    tableName: location.state.recordDetail.datatableNames
  };

  const [searcParam, setSearcParam] = useState({...searcParamInt});

  const [listData, setListData] = useState([] as any);

  const getDataAccessDataDetail = async (page: {pageSize: number, pageNum: number}, paramData: SearcParamType) => {
    const param = {
      ...paramData,
      ...page
    }

    const { data } = await DataTableDetailApi(param);

    let store = [] as any;
 interface listType {
      title:string,
      dataIndex:string|number,
      key:any,
      ellipsis?:boolean,
      width?:number,
 }
    let arr = Object.keys(data.tableTittle).map(key => ({name:  data.tableTittle[key],value:key}));
    arr.forEach((val: any, idx: number) => {
      let obj = {
        title: val.name, 
        dataIndex: val.value,
        key: val.value, 
        // ellipsis:true,
        width: 250,
        render: (_: any, record: any) => (
          <Tooltip title={_} placement="topLeft">
            <span className='line-text-two'>{_}</span>
          </Tooltip>
        )
      } as listType ;
      // if (val === 'titleTitle' || val === 'picPath' || val === 'skuName'){
      //   obj.ellipsis = true
      // }
      store.push(obj);
    });
    setColumnsData(store);

    setListData(data.result);

    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: data.total,
    });
    setScrollY(getTableScroll())
  };

  const columns: TableProps<any>["columns"] = columnsData;

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange = (e:any) => {
    getDataAccessDataDetail({ pageSize: e.pageSize, pageNum: e.current }, searcParam);
  };

  useEffect(() => {
    getDataAccessDataDetail({pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum}, searcParam);
  },[])

  return (
    <div className={styles.dataAccessDetailsList}>
      <div className='headBox'>
        <div className={styles.titleBox}>
          <div className={styles.returnBtn} onClick={() => navigate('/files/originalDataAccess/dataAccess/details',  { state: {record:location.state.record,pageQuery: location.state.pageQuery, page: location.state.page } })}><LeftOutlined /><span>返回</span></div>
          <div className={styles.moduleTitle}><ModuleTitle mark="large" title="数据表详情" /></div>
        </div>
        <div>
          <SearchIconInput
            placeholder={location.state.record.createWay === 'shop_member' ? '请输入手机号查询' : '请输入订单编号查询'}
            onSearch={(e: string) => {
              const idOrNum = location.state.record.createWay === 'shop_member' ? 'phoneNumber':'orderId'
              const store = {
                ...searcParam,
                [idOrNum]: e,
              };
              setSearcParam(store);
              getDataAccessDataDetail({ pageSize: 10, pageNum: 1 }, store);
            }}
          ></SearchIconInput>
        </div>
      </div>
      <div className='containerBox'>
        <div className={styles.baseInfoBox}>
          <div>店铺名称：<span className={styles.greyText}>{location.state.record.shopName}</span></div>
          <div>店铺ID：<span className={styles.greyText}>{location.state.record.shopId}</span></div>
          <div>平台类型：<span className={styles.greyText}>{location.state.record.platformTypeLabel}</span></div>
          <div>数据表名称：<span className={styles.greyText}>{location.state.recordDetail.datatableNames}</span></div>
        </div>
        <div className={styles.tableBox}>
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={listData}
            scroll={{ x: 1800, y: scrollY}}
            pagination={{
              pageSize: pageQuery.pageSize,
              total: pageQuery.total,
              showTotal: () => `共${pageQuery.total}条`,
              showSizeChanger: true,
            }}
            onChange={(e) => handleTableChange(e)}
          />
        </div>
      </div>
      
    </div>
  );
};

export default DataAccessDetailsList