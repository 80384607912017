import React, { useState, useEffect } from "react";
import { Drawer, Button,Space, Form, Input, Upload, message, Popconfirm } from "antd";
import type { DrawerProps } from "antd";
import {
  
} from "../../../../../../api/UserInsight/UserInsight";
import {
  getEncryptTypeListApi
}from "@/api/userList/userList";
const { Dragger } = Upload;
const ExportRefirm = (props: {
  openExportRefirm: boolean;
  closeExportRefirm: Function;
  checkExport: Function;
}) => {
  const { openExportRefirm, closeExportRefirm ,checkExport = () => {} } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);
  const [accountForm] = Form.useForm();
  // 关闭当前抽屉
  const onClose = () => {
    closeExportRefirm(false);
  };

  const getEncryptTypeList = async(status:boolean) => {
    const res = await getEncryptTypeListApi();
    if (res?.code === 200) {
      const encryptType = res.data.find((item:any)=>item.encryptName==='MD5').encryptType
      submit(status,encryptType)
    }
  };

  const submit = async (isFinalConfirm: boolean,encryptType?:number) => {
    checkExport(encryptType)
    closeExportRefirm(false);
  };

  // 关闭提示弹窗
  const hidden = () => {
  };
  useEffect(() => {
    setOpen(openExportRefirm);
  }, [openExportRefirm]);
  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={'导出确认'}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Popconfirm  title="是否需要MD5加密？"  onConfirm={() =>getEncryptTypeList(false) } onCancel={() => submit(false)} okText="是" cancelText="否">
              <Button type="primary" >
                确认导出
              </Button>
            </Popconfirm>
          </Space>
        }
      >
        <Form layout="vertical" form={accountForm}>
          <Form.Item>
              <div>
                1.支持导出全量数据
              </div>
              <div>
                2.导出格式为csv
              </div>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default ExportRefirm;
