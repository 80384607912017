import { useContext, useMemo } from "react";
import style from "./index.module.scss";
import { PageItem, PageTitle } from "../../../../../../component/MiniStyles";
import { Input, Transfer, Tag } from "antd";
import { crmTipsMap } from "../../../../../../assets/public/pageMap";

import { MyContext } from "../../index";

const PageOne = ({ readOnly = false }: { readOnly?: boolean | undefined }) => {
  const {
    data: pageInfo,
    myContextChange,
    pageStatus,
    channelNameMap,
    selectShopList,
    shopList: shopNameList,
  } = useContext(MyContext);
  const { memberCardName, memberCardCode, remark, shopList } = pageInfo;
  const updateStatus = pageStatus === "update";
  const transferList = useMemo(() => {
    // 可选地列表
    const arr = shopNameList.map((item: any) => ({
      ...item,
      key: Number(item.shopId),
      shopId: Number(item.shopId),
    }));
    // 已选择的列表
    const selectArr = selectShopList.map((item: any) => ({
      ...item,
      key: Number(item.shopId),
      shopId: Number(item.shopId),
      disabled: updateStatus,
    }));
    // 穿梭框的数据源需要包括可选和已选的列表
    return [...arr, ...selectArr];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(shopNameList), JSON.stringify(selectShopList)]);

  // 穿梭框onchange事件
  const onTransferChange = (e: any) => {
    const arr = transferList.filter((item: any) => e.includes(item.shopId));
    myContextChange("shopList", arr);
  };

  // 穿梭框
  const TransferSection = () => {
    const targetKeys = shopList.map((item: any) => Number(item.shopId));

    return (
      <Transfer
        dataSource={transferList}
        titles={["所有店铺", "目标店铺"]}
        targetKeys={targetKeys}
        onChange={onTransferChange}
        render={(item) =>
          `${channelNameMap.get(item.channelType)}-${item.shopName}`
        }
        disabled={readOnly}
        listStyle={{
          width: 242,
          height: 250,
        }}
        showSearch
      />
    );
  };

  // 店铺标签显示部分
  const ShopTagList = () => {
    const TagListContetn = () => {
      return (
        <>
          <div className={style["tips"]}>
            共选择 <span className={style["tips-blue"]}>{shopList.length}</span>{" "}
            个店铺
          </div>
          <div className={style["tag-bgd"]}>
            {shopList.map((item: any) => {
              return (
                <Tag color="blue">
                  {`${channelNameMap.get(item.channelType)}-${item.shopName}`}
                </Tag>
              );
            })}
          </div>
        </>
      );
    };
    return (
      <>
        {readOnly ? (
          <div style={{ marginTop: "5px" }}>
            <TagListContetn />
          </div>
        ) : (
          <PageItem title="">
            <TagListContetn />
          </PageItem>
        )}
      </>
    );
  };

  return (
    <div className={style["memberCard-create-pageOne"]}>
      <PageTitle>基础信息</PageTitle>
      <PageItem title="会员卡名称">
        <Input
          value={memberCardName}
          className={style["input-long"]}
          maxLength={30}
          showCount
          disabled={readOnly}
          onChange={(e) => myContextChange("memberCardName", e.target.value)}
        />
      </PageItem>
      <PageItem title="体系编码" tips={crmTipsMap.get("体系编码")}>
        <Input
          value={memberCardCode}
          className={style["input-long"]}
          maxLength={30}
          showCount
          disabled={readOnly || updateStatus}
          // onBlur={myContextHandCheckCode}
          onChange={(e) => {
            if (e.target.value.length > 30) return;
            const value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
            myContextChange("memberCardCode", value);
          }}
        />
      </PageItem>
      <PageItem title="选择店铺">
        {readOnly ? <ShopTagList /> : <TransferSection />}
      </PageItem>
      {!readOnly && <ShopTagList />}
      <PageItem title="备注" require={false}>
        <Input.TextArea
          value={remark}
          className={style["input-long"]}
          maxLength={100}
          showCount
          disabled={readOnly || updateStatus}
          onChange={(e) => myContextChange("remark", e.target.value)}
        />
      </PageItem>
    </div>
  );
};

export default PageOne;
