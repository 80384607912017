import styles from "./index.module.scss";
import {useEffect, useRef, useState} from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  type TableProps
} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import SystemButton from "@/pages/LuZhiBI/components/Button/SystemButton";
import ModuleTitle from "@/pages/LuZhiBI/components/ModuleTitle";
import SearchIconInput from "@/pages/LuZhiBI/components/SearchIconInput/searchIconInput";
import FilterIcon from "@/pages/LuZhiBI/components/FilterIcon/filterIcon";
import FilterView from "@/pages/LuZhiBI/components/FilterView";
import api from "@/pages/LuZhiBI/api";
import {IAddDimension, IEditDimension, IGetDimensionManagementList, ModelType} from "@/pages/LuZhiBI/api/types";
import dayjs from "dayjs";

interface DataType {
  id: number
  type: string
  name: string
  sourceFieldType: string
  bizExpression: string
  createTime: string
}

interface ISearch {
  info?: string,
  name?: string,
  type?: string[],
  startTime?: string,
  endTime?: string
}

interface IFormData {
  dimensionName: string,
  dimensionClassification: string,
  tableName: string,
  field: string,
  dimensionType: string,
  dimensionValue: string,
  sourceFieldType: string,
  businessMeaning: string,
  dateType: string,
}

type PageQuery = null | number

type DrawerType = "create" | 'view' | 'edit'

const DimensionManagement = () => {

  const {confirm} = Modal;

  const {TextArea} = Input;

  const [form] = Form.useForm();

  const [options, setOptions] = useState<Record<string, Array<{ label: string, value: string, layer?: string }>>>({
    tableNameOptions: [],
    fieldOptions: [],
    dateTypeOptions: [],
    dimensionTypeOptions: [],
    dimensionClassificationOptions: [],
    sourceFieldTypeOptions: []
  })

  const defaultSearchInfo = {
    // 查询参数
    dimensionName: "",
    dimensionType: options.dimensionClassificationOptions.map(item => item.value),
    createdAt: "",
  };

  const [listParams, setListParams] = useState(defaultSearchInfo);

  const filterViewRef = useRef<any>(null);

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [tableData, setTableData] = useState([])

  const [openDrawer, setOpenDrawer] = useState(false)

  const [drawerType, setDrawerType] = useState<DrawerType>('create')

  const drawerTitle: Record<DrawerType, string> = {
    create: '新建维度',
    view: '查看维度',
    edit: '编辑维度'
  }

  const defaultFormData = {
    dimensionName: '',
    dimensionClassification: '',
    tableName: '',
    field: '',
    dimensionType: '',
    dimensionValue: '',
    businessMeaning: '',
    sourceFieldType: '',
    dateType: '',
  }

  const [dimensionClassification, setDimensionClassification] = useState('')

  const [dimensionType, setDimensionType] = useState('')

  const [viewId, setViewId] = useState('')

  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i: any) => {
    setCheckUserId(i.id)
    setIconColorPointBack('#f3f3f3');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('')
    setIconColorPointBack('');
  };

  // 搜索
  const searchList: any[] = [
    {
      searchType: "string",
      searchComponentType: "input",
      placeHolder: "请输入维度名称",
      title: "维度名称",
      key: "dimensionName",
    },
    {
      searchType: "string",
      searchComponentType: "multipleSelect",
      placeHolder: "请选择维度分类",
      title: "维度分类",
      key: "dimensionType",
      options: options.dimensionClassificationOptions,
      mode: "multiple",
      defaultValue: options.dimensionClassificationOptions.map(item => item.value),
    },
    {
      searchType: "text",
      searchComponentType: "dateRangeTime",
      showTime: true,
      title: "创建时间",
      key: "createdAt",
    },
  ];

  const getTableOperationButtonList = (item: DataType) => {
    const store = [];

    store.push({
      key: "view",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onView(item)}
        >
          查看
        </a>
      ),
    });

    store.push({
      key: "edit",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onEdit(item)}
        >
          编辑
        </a>
      ),
    });

    store.push({
      key: "delete",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onDelete(item)}
        >
          删除
        </a>
      ),
    });
    return store;
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "序号",
      key: "index",
      width: "88px",
      render: (_value, _record, index) => (
        <span>{(pageInfo.page - 1) * pageInfo.pageSize + index + 1}</span>
      ),
    },
    {
      title: "维度分类",
      key: "type",
      dataIndex: "type",
      render: (value) => {
        return options.dimensionClassificationOptions.find(
          (item) => item.value == value
        )?.label ?? '';
      },
    },
    {
      title: "维度名称",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "维度类型",
      key: "sourceFieldType",
      dataIndex: "sourceFieldType",
      render: (value) => {
        return options.sourceFieldTypeOptions.find(
          (item) => item.value == value
        )?.label ?? '';
      },
    },
    {
      title: "业务含义",
      key: "bizExpression",
      dataIndex: "bizExpression",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{items: getTableOperationButtonList(record)}}
            trigger={["click"]}
          >
            <Popconfirm
              title=''
              placement="right"
              open={false}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                onMouseLeave={handleMouseLeavePoint}
                onMouseEnter={() => handleMouseEnterPoint(record)}
                className={styles[`line-three-point`]}
                style={{backgroundColor: checkUserId == record.id ? iconColorPointBack : ''}}
              >
                <EllipsisOutlined
                  style={{
                    color: checkUserId == record.id ? '#1e1e1e' : '#979797',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onDelete = (item: DataType) => {
    confirm({
      title: "提示",
      content: '该维度被删除后，会影响上游应用该表的所有表及后续统计数据，确认删除吗？',
      okText: "确定",
      onOk() {
        confirmDelete(item.id);
      },
    });
  }

  const confirmDelete = async (id: number) => {
    api.deleteDimensionManagementList(id).then((res: any) => {
      if (res?.code === 200) {
        message.success('删除成功')

        let page = pageInfo.page

        if (tableData.length === 1 && page > 1) {
          page = page - 1
        }

        onSearch({page: page})
      } else {
        message.error('删除失败')
      }
    })
  }

  const getViewInfo = async (id: string, type: DrawerType) => {
    const res = await api.viewDimension({id})

    if (res?.code === 200) {
      const result = {
        dimensionName: res.data.name ?? '',
        dimensionClassification: res.data.type ?? '',
        tableName: res.data.sourceTable ?? '',
        field: res.data.sourceField ?? '',
        dimensionType: res.data.dimensionOperator ?? '',
        dimensionValue: res.data.dimensionValue ?? '',
        businessMeaning: res.data.bizExpression ?? '',
        sourceFieldType: res.data.sourceFieldType ?? '',
        dateType: res.data.dateType ?? '',
      }

      const layer = res.data?.type ?? ''

      layer !== '1' && await getTablesList(layer, false, {tableName: res.data.sourceTable})

      setDimensionClassification(res.data.type ?? '')

      setDimensionType(options.dimensionTypeOptions.find(item => item.value === res.data.dimensionOperator)?.label ?? '')

      form.setFieldsValue(result)

      setDrawerType(type)

      setOpenDrawer(true)
    }
  }

  const onView = (_record: DataType) => {
    setViewId(_record.id.toString() ?? '')
    getViewInfo(_record.id.toString() ?? '', 'view')
  }

  const onEdit = (_record: DataType) => {
    setViewId(_record.id.toString() ?? '')
    getViewInfo(_record.id.toString() ?? '', 'edit')
  }

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  const onFinish = (data: any) => {
    const {createdAt, dimensionType, dimensionName} = data

    const params: ISearch = {
      name: dimensionName,
      type: dimensionType,
      startTime: Array.isArray(createdAt) ? dayjs(createdAt[0]).format('YYYY-MM-DD HH:mm:ss') : '',
      endTime: Array.isArray(createdAt) ? dayjs(createdAt[1]).format('YYYY-MM-DD HH:mm:ss') : ''
    }

    onSearch({page: 1}, params)
  }

  const onClose = () => {
    setOpenDrawer(false)

    setViewId('')

    setDimensionClassification('')

    setOptions(prevState => {
      return {
        ...prevState,
        fieldOptions: [],
        tableNameOptions: [],
      }
    })

    setDimensionType('')

    form.resetFields()

    form.setFieldValue('dimensionType', options.dimensionTypeOptions[0].value)
  }

  const onFormFinish = (value: {
    dimensionName: any;
    dimensionClassification: any;
    tableName: any;
    field: any;
    dimensionType: any;
    dimensionValue: any;
    sourceFieldType: any;
    businessMeaning: any;
    dateType: any;
  }) => {
    if (drawerType === 'edit') {
      confirm({
        title: "提示",
        content: '当前修改会影响上游应用该表的所有表及后续统计数据，确认修改吗？',
        okText: "确定",
        onOk() {
          editDimension(value)
        },
      });
    } else {
      addDimension(value)
    }

  }

  const addDimension = (formData: IFormData) => {
    const params: IAddDimension = {
      name: formData.dimensionName,
      type: formData.dimensionClassification,
      sourceTable: formData.tableName,
      sourceField: formData.field,
      dimensionOperator: formData.dimensionType,
      dimensionValue: formData.dimensionValue,
      sourceFieldType: formData.sourceFieldType,
      bizExpression: formData.businessMeaning,
      dateType: formData.dateType,
    }

    api.addDimension(params).then((res: any) => {
      if (res?.code === 200) {
        message.success('新建成功')
        onClose()
        onSearch({page: 1})
      } else {
        message.error('新建失败')
      }
    })
  }

  const editDimension = (formData: IFormData) => {
    const params: IEditDimension = {
      id: viewId,
      name: formData.dimensionName,
      type: formData.dimensionClassification,
      sourceTable: formData.tableName,
      sourceField: formData.field,
      dimensionOperator: formData.dimensionType,
      dimensionValue: formData.dimensionValue,
      sourceFieldType: formData.sourceFieldType,
      bizExpression: formData.businessMeaning,
      dateType: formData.dateType,
    }

    api.editDimension(params).then((res: any) => {
      if (res?.code === 200) {
        message.success('编辑成功')
        onClose()
        onSearch({page: 1})
      } else {
        message.error('编辑失败')
      }
    })
  }

  const onSearch = async (query?: { page?: PageQuery, size?: PageQuery }, params?: ISearch) => {
    const data: IGetDimensionManagementList = {
      query: {
        pageNo: query?.page || pageInfo.page,
        pageSize: query?.size || pageInfo.pageSize
      },
      params: {
        info: params?.info || '',
        name: params?.name || '',
        type: params?.type || [],
        startTime: params?.startTime || '',
        endTime: params?.endTime || ''
      }
    }

    const res = await api.getDimensionManagementList(data)

    setTableData(res?.data?.records ?? [])

    setPageInfo({
      page: res?.data?.current ?? 1,
      pageSize: res?.data?.size ?? 10,
      total: res?.data?.total ?? 0,
    })
  }

  const getIndicatorCategory = async (modelType: ModelType) => {
    const modelTypeMap = {
      dimension_type: 'dimensionClassificationOptions',
      dimension_operator: 'dimensionTypeOptions',
      dimension_date_type: 'dateTypeOptions',
      dimension_source_field_type: 'sourceFieldTypeOptions',
    }

    const res = await api.getIndicatorCategory({modelType})

    let result: any = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { dictLabel: string; dictValue: string; }) => {
        return {
          label: item.dictLabel,
          value: item.dictValue,
        }
      }) ?? []
    }

    if (modelType === 'dimension_type') {
      setListParams(prevState => {
        return {
          ...prevState,
          // @ts-ignore
          dimensionType: result.map((item: { value: string; }) => item.value)
        }
      })

      filterViewRef.current.setFormInfo('dimensionType', result.map((item: { value: string; }) => item.value))
    }

    if (modelType === 'dimension_operator') {
      form.setFieldValue('dimensionType', result[0]?.value ?? '')
    }

    setOptions(prevState => {
      return {
        ...prevState,
        // @ts-ignore
        [modelTypeMap[modelType]]: result
      }
    })
  }

  const getTablesList = async (layer: string, reset = true, field?: null | { tableName: string; }) => {
    reset && form.setFieldValue('tableName', '')
    reset && form.setFieldValue('field', '')

    setOptions(prevState => {
      return {
        ...prevState,
        fieldOptions: []
      }
    })

    const res = await api.getTablesList({layer})

    let result: Array<{ label: string, value: string, layer: string }> = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { tableName: string; layer: string }) => {
        return {
          label: item.tableName,
          value: item.tableName,
          layer: item.layer
        }
      }) ?? []
    }

    setOptions(prevState => {
      return {
        ...prevState,
        tableNameOptions: result
      }
    })

    if (field) {
      await getFieldsList(field.tableName, true, result.find(item => item.label === field.tableName)?.layer ?? '')
    }
  }

  const getFieldsList = async (tableName?: string, reset = true, layer = '') => {
    reset && form.setFieldValue('field', '')

    if (!tableName) {
      setOptions(prevState => {
        return {
          ...prevState,
          fieldOptions: []
        }
      })
      return
    }

    const res = await api.getFieldsList({
      tableName: tableName,
      layer: layer || (options.tableNameOptions.find(item => item.label === tableName)?.layer ?? '')
    })

    let result: Array<{ label: string, value: string }> = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { filedCnName: string; filedEnName: string; }) => {
        return {
          label: item.filedCnName,
          value: item.filedEnName,
        }
      }) ?? []
    }

    setOptions(prevState => {
      return {
        ...prevState,
        fieldOptions: result
      }
    })
  }

  useEffect(() => {
    const modelTypeList: ModelType[] = ['dimension_type', 'dimension_operator', 'dimension_date_type', 'dimension_source_field_type']
    modelTypeList.map((modelType: ModelType) => {
      getIndicatorCategory(modelType)
    })

    onSearch(undefined, {type: ['0']});

  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headBox}>
          <div className={styles.titleText}>
            <ModuleTitle mark="large" title="维度列表"/>
          </div>

          <div className={styles.searchBox}>
            <SearchIconInput
              placeholder="请输入维度名称或维度分类进行搜索"
              width={300}
              onSearch={(e: string) => {
                setListParams({
                  ...listParams,
                  dimensionName: e,
                });
                onSearch({page: 1}, {info: e})
              }}
            />
            <FilterIcon
              handleFilterClick={handleFilterClick}
              filterTitle="过滤器"
            />
            <span
              style={{
                width: "0",
                height: "16px",
                border: "var(--border3)",
                marginRight: 16,
              }}
            />
            <SystemButton
              title='新建维度'
              type="primary"
              onClick={() => {
                setOpenDrawer(true);
                setDrawerType('create')
              }
              }
            />
          </div>
        </div>

        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={onFinish}
          defaultFormValue={defaultSearchInfo}
        />

        <Table
          style={{marginTop: 38}}
          dataSource={tableData}
          columns={columns}
          pagination={{
            total: pageInfo.total,
            pageSize: pageInfo.pageSize,
            current: pageInfo.page,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showTotal: (total) => `共 ${total} 条`,
            onChange: (page, pageSize) => {
              onSearch({page, size: pageSize})
              setPageInfo(prevState => {
                return {
                  ...prevState,
                  page,
                  pageSize
                }
              })
            }
          }}
          scroll={{x: columns.reduce((pre, cur) => pre + (cur.width as number ?? 200), 0)}}
        />
      </div>
      <Drawer
        title={drawerTitle[drawerType]}
        onClose={onClose}
        open={openDrawer}
        extra={
          <Space>
            <Button
              onClick={onClose}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (drawerType === 'view') {
                  setDrawerType('edit')
                } else {
                  form.submit()
                }
              }
              }
            >
              {drawerType === 'view' ? '编辑' : '确定'}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          disabled={drawerType === 'view'}
          onFinish={onFormFinish}
          initialValues={defaultFormData}
        >
          <Form.Item
            name="dimensionName"
            label="维度名称"
            rules={[{required: true, message: '请输入维度名称'}]}
          >
            <Input
              showCount
              maxLength={50}
              placeholder="请输入维度名称"
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, '');
                form.setFieldValue('dimensionName', value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="dimensionClassification"
            label="维度分类"
            rules={[{required: true, message: '请选择维度'}]}
          >
            <Select
              placeholder='请选择维度'
              onChange={(val) => {
                if (dimensionClassification === '1' || val === '1') {
                  form.setFieldsValue({
                    ...defaultFormData,
                    dimensionClassification: val,
                    dimensionName: form.getFieldValue('dimensionName'),
                    dimensionType: form.getFieldValue('dimensionType')
                  })
                }

                val !== '1' && getTablesList(val)

                form.setFieldValue('dimensionClassification', val)

                setDimensionClassification(val)
              }}
              options={options.dimensionClassificationOptions}
            />
          </Form.Item>
          {dimensionClassification !== '1' &&
            <>
              <Form.Item
                name="tableName"
                label="表名选择"
                rules={[{required: true, message: '请选择表'}]}
              >
                <Select
                  placeholder='请选择表'
                  onChange={(val) => getFieldsList(val)}
                  options={options.tableNameOptions}
                />
              </Form.Item>
              <Form.Item
                name="field"
                label="字段选择"
                rules={[{required: true, message: '请选择字段'}]}
              >
                <Select
                  placeholder='请选择字段'
                  options={options.fieldOptions}
                />
              </Form.Item>
              <Form.Item
                name="sourceFieldType"
                label="维度类型"
                rules={[{required: true, message: '请选择维度类型'}]}
              >
                <Select
                  placeholder='请选择维度类型'
                  options={options.sourceFieldTypeOptions}
                />
              </Form.Item>
              <div style={{display: 'flex', alignItems: 'flex-end', gap: 8}}>
                <Form.Item
                  className={styles.required}
                  name="dimensionType"
                  label="维度值设置"
                  style={{width: '100%', flex: 1}}
                >
                  <Select
                    style={{width: '100%'}}
                    options={options.dimensionTypeOptions}
                    onChange={value => {
                      const label = options.dimensionTypeOptions.find(item => item.value === value)?.label ?? ''

                      setDimensionType(label)

                      if (['为空', '不为空'].includes(dimensionType)) {
                        form.setFieldValue('dimensionValue', '')
                      }
                    }
                    }
                  />
                </Form.Item>
                {!['为空', '不为空'].includes(dimensionType) &&
                  <Form.Item
                    name="dimensionValue"
                    style={{width: '100%', flex: 1}}
                    rules={[{required: true, message: '请输入维度值'}]}
                    className={styles.required}
                  >
                    <Input
                      name="dimensionValue"
                      style={{width: '100%'}}
                      placeholder='请输入维度值'
                      onChange={(e) => {
                        const value = e.target.value.replace(/\s/g, '');
                        form.setFieldValue('dimensionValue', value);
                      }}
                    />
                  </Form.Item>
                }
              </div>
            </>
          }
          {
            dimensionClassification === '1' &&
            <Form.Item
              name="dateType"
              label="时间维度类型"
              rules={[{required: true, message: '请选择时间维度类型'}]}
            >
              <Select
                placeholder='请选择时间维度类型'
                options={options.dateTypeOptions}
              />
            </Form.Item>
          }
          <Form.Item
            name="businessMeaning"
            label="业务含义"
            rules={[
              {
                required: true,
                message: '请输入业务含义',
              },
            ]}
          >
            <TextArea
              showCount={drawerType !== 'view'}
              maxLength={100}
              placeholder="请输入业务含义"
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, '');
                form.setFieldValue('businessMeaning', value);
              }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}
export default DimensionManagement
