import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Space, Card, Select, Tag, message, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import busEvent from '@/utils/bus';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

let direction = 0;

const commonOption = {
  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'shadow',
    },
  },
  legend: {
    type: 'scroll',
  },
  grid: {
    left: '0',
    right: '2%',
    bottom: '16%',
    containLabel: true,
  },
  color: ['#3BA0FF', '#40CECE', '#56CE7A', '#F6BD16', '#6DC8EC', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#40CECE'],
};
//基础属性行列不一致时，横坐标的取值
const ChartData = ({
  chartValue,
  operation,
  peopleType,
  changeSort,
  changeShape,
}: {
  chartValue: any;
  operation: string;
  peopleType: string;
  changeSort: (title: string, sortType: string) => void;
  changeShape: (title: string, type: string) => void;
}) => {
  const handleChange1 = (val: any) => {
    console.log('handleChange1', val);
    changeSort(chartValue.title, val);
  };
  const handleChange2 = (val: any) => {
    console.log('handleChange2', val);
    changeShape(chartValue.title, val);
  };
  const chartTitle = (item: any) => {
    return (
      <div className={styles.cardTitle}>
        <div>
          {peopleType && (
            <div>
              <Tag color={peopleType == 'compare' ? 'purple' : 'processing'}>{peopleType == 'compare' ? '对比人群' : '目标人群'}</Tag>
            </div>
          )}
          <Space wrap style={{ marginTop: 5 }}>
            <Tooltip title={item.title}>
              <div className={styles.titleBox} style={{ maxWidth: operation == 'edit' ? 100 : 410 }}>
                {item.title}
              </div>
            </Tooltip>
            <Tooltip title="当前仅显示前10，下载数据查看所有">
              <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)' }} />
            </Tooltip>
          </Space>
        </div>
        {operation == 'edit' && (
          <div>
            <Space wrap>
              <Select
                defaultValue={chartValue.sortType}
                style={{ width: 126 }}
                onChange={handleChange1}
                options={
                  chartValue.portraitType === '2'
                    ? [
                        { value: '0', label: '按人数降序' },
                        { value: '1', label: '按人数升序' },
                      ]
                    : [
                        { value: '0', label: '按人数降序' },
                        { value: '1', label: '按人数升序' },
                        { value: '2', label: '按维度值升序' },
                        { value: '3', label: '按维度值降序' },
                      ]
                }
              />
              <Select
                defaultValue={chartValue.type}
                style={{ width: 86 }}
                onChange={handleChange2}
                options={
                  chartValue.statsValue == '1'
                    ? [
                        { value: 'column', label: '柱状图' },
                        { value: 'bar', label: '条形图' },
                      ]
                    : [
                        { value: 'column', label: '柱状图' },
                        { value: 'bar', label: '条形图' },
                        { value: 'line', label: '折线图' },
                        { value: 'pie', label: '饼图' },
                      ]
                }
              />
              {/* <DeleteOutlined style={{ color: 'var(--text-font-color4)' }} /> */}
            </Space>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    // let chartDom = document.getElementById(chartValue.chartId);
    // let myChart = echarts.init(chartDom);
    // myChart.setOption(option);
  }, [JSON.stringify(chartValue)]);

  // 根据排序类型对数据进行排序

  const sortedData = chartValue.data.sort((a: any, b: any) => {
    if (chartValue.sortType === '0' && chartValue.statsValue == '0') {
      return b.value - a.value; // 人数降序排序
    } else if (chartValue.sortType === '1' && chartValue.statsValue == '0') {
      return a.value - b.value; // 人数升序排序
    } else if (chartValue.sortType === '2' && chartValue.statsValue == '0') {
      return a.name.localeCompare(b.name); // 维度升序排序
    } else if (chartValue.sortType === '3' && chartValue.statsValue == '0') {
      return b.name.localeCompare(a.name); // 维度降序排序
    }
  });

  const sortedData2 = chartValue.data.sort((a: any, b: any) => {
    if (chartValue.sortType === '2' && chartValue.statsValue == '1') {
      return a.row.localeCompare(b.row); // 升序排序
    } else if (chartValue.sortType === '3' && chartValue.statsValue == '1') {
      return b.row.localeCompare(a.row); // 降序排序
    } else {
      return 0;
    }
  });
  const getOption = () => {
    let barOption = {};
    let lineOption = {};
    let pieOption = {};
    let series: any = [];
    let rows = [];
    let perCharNumber = 21;
    if (chartValue.data.length > 6) {
      direction = 90;
      perCharNumber = 5;
    }
    switch (chartValue.data.length) {
      case 6:
        perCharNumber = 10;
        break;
      case 5:
        perCharNumber = 11;
        break;
      case 4:
        perCharNumber = 12;
        break;
      case 3:
        perCharNumber = 13;
        break;
      default:
        break;
    }
    //基础属性行列不一致的情况
    if (chartValue.statsValue == '1') {
      let newData = sortedData2.map((item: any, index: any) => {
        return {
          ...item,
          list: item.list.sort((a: any, b: any) => {
            if (chartValue.sortType == '1') {
              return a.value - b.value;
            } else if (chartValue.sortType == '0') {
              return b.value - a.value;
            } else {
              return 0;
            }
          }),
        };
      });
      rows = newData.map((i: any) => {
        let count = 0;
        let index = 0;
        i.valueNameText = i.row;
        let indexArr = [];
        for (let char of i.row) {
          if (!isNaN(char) && char !== `\n`) {
            count += 0.5;
          } else {
            count++;
          }
          index++;
          if (count % perCharNumber === 0 && index != i.valueNameText.length) {
            indexArr.push(index);
          }
        }
        if (indexArr.length > 0) {
          for (let t = indexArr.length - 1; t >= 0; t--) {
            i.valueNameText = `${i.valueNameText.slice(0, indexArr[t])}\n${i.valueNameText.slice(indexArr[t])}`;
          }
        }
        return i.row.substr(0, 8);
      });
      newData.forEach((item: any, index: any) => {
        item.list.forEach((item2: any, index2: any) => {
          let findIndex = series.findIndex((e: any) => e.name == item2.name);
          if (findIndex !== -1) {
            series[findIndex].data[index] = item2.value;
          } else {
            let defaultData = [];
            for (let i = 0; i < newData.length; i++) {
              defaultData.push(0);
            }
            defaultData[index] = item2.value;
            series.push({
              name: item2.name,
              type: chartValue.type == 'column' ? 'bar' : chartValue.type,
              data: defaultData,
              barGap: 0,
              emphasis: {
                focus: 'series',
              },
              tooltip: {
                formatter(params: any, ticket: any, callback: any) {
                  if (newData[params.dataIndex].list.filter((i: any) => i.name == params.seriesName)[0].tgi) {
                    return ` 
                      <div>${newData[params.dataIndex].row
                        .replace(/&/g, '&amp;')
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;')
                        .replace(/"/g, '&quot;')
                        .replace(/'/g, '&#039;')}</div>
                      <div>${params.seriesName}：${Number(params.value).toLocaleString()}</div>
                      <div>TGI：${Number(
                        newData[params.dataIndex].list.filter((i: any) => i.name == params.seriesName)[0].tgi
                      ).toLocaleString()}</div>
                      `;
                  } else {
                    return ` 
                      <div>${newData[params.dataIndex].row
                        .replace(/&/g, '&amp;')
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;')
                        .replace(/"/g, '&quot;')
                        .replace(/'/g, '&#039;')}</div>
                      <div>${params.seriesName}：${Number(params.value).toLocaleString()}</div>
                      `;
                  }
                },
              },
            });
          }
        });
      });
    } else {
      sortedData.map((item: any) => {
        let count = 0;
        let index = 0;
        item.valueNameText = item.valueName;
        let indexArr = [];
        for (let char of item.valueName) {
          if (!isNaN(char) && char !== `\n`) {
            count += 0.5;
          } else {
            count++;
          }
          index++;
          if (count % perCharNumber === 0 && index != item.valueNameText.length) {
            indexArr.push(index);
          }
        }
        if (indexArr.length > 0) {
          for (let i = indexArr.length - 1; i >= 0; i--) {
            item.valueNameText = `${item.valueNameText.slice(0, indexArr[i])}\n${item.valueNameText.slice(indexArr[i])}`;
          }
        }
      });
      let a = {
        name: '人数',
        barGap: 0,
        type: chartValue.type == 'column' ? 'bar' : chartValue.type,
        data: sortedData.map((item: any) => item.value),
      } as any;
      a[chartValue.type === 'bar' ? 'xAxisIndex' : 'yAxisIndex'] = 0;
      series.push(a);
      if (chartValue.portraitType != '2') {
        let b = {
          name: 'TGI',
          barGap: 0,
          type: chartValue.type == 'column' ? 'bar' : chartValue.type,
          data: sortedData.map((item: any) => item.tgi),
        } as any;
        b[chartValue.type === 'bar' ? 'xAxisIndex' : 'yAxisIndex'] = 1;
        series.push(b);
      }
    }

    barOption = {
      tooltip: {
        trigger: 'item',
        formatter: function (params: any) {
          // params 是当前鼠标所在位置的数据项信息
          return `
          <div>${params.seriesName}</div>
          <div>${sortedData[params.dataIndex].valueName}: ${Number(params.value).toLocaleString()}</div>
          `;
        },
      },
      color: commonOption.color,
      legend: commonOption.legend,
      grid: commonOption.grid,
      xAxis: [
        {
          type: 'value',
          name: '',
          position: 'left',
        },
        {
          type: 'value',
          name: '',
          position: 'right',
          // 双 Y 轴时，可以通过这个字段来设置不同的刻度刻度范围
          splitLine: {
            show: false,
          },
        },
      ],
      yAxis: {
        type: 'category',
        axisLabel: {
          show: true,
          fontSize: 12,
          interval: 0,
        },
        data: chartValue.statsValue == '1' ? rows : sortedData.map((item: any) => item.valueName),
      },
      series,
    };
    lineOption = {
      tooltip: {
        trigger: 'item',
        formatter: function (params: any) {
          // params 是当前鼠标所在位置的数据项信息
          return `
          <div>${params.seriesName}</div>
          <div>${sortedData[params.dataIndex].valueName}: ${Number(params.value).toLocaleString()}</div>
          `;
        },
      },
      color: commonOption.color,
      grid: commonOption.grid,
      legend: commonOption.legend,
      xAxis: {
        type: 'category',
        axisLabel: {
          show: true,
          fontSize: 12,
          interval: 0,
          rotate: sortedData.length > 6 ? 90 : 0,
          // align: 'right',
          // verticalAlign: 'top'
        },
        data: chartValue.statsValue == '1' ? rows : sortedData.map((item: any) => item.valueNameText),
      },
      yAxis: [
        {
          type: 'value',
          name: '',
          position: 'left',
        },
        {
          type: 'value',
          name: '',
          position: 'right',
          // 双 Y 轴时，可以通过这个字段来设置不同的刻度刻度范围
          splitLine: {
            show: false,
          },
        },
      ],
      series,
    };
    pieOption = {
      tooltip: {
        trigger: 'item',
        formatter: function (params: any) {
          // params 是当前鼠标所在位置的数据项信息
          if (params.data.tgi) {
            return `${params.name}: ${params.value}<br/>TGI: ${Number(params.data.tgi).toLocaleString()}`;
          } else {
            return `${params.name}: ${Number(params.value).toLocaleString()}`;
          }
        },
      },
      color: commonOption.color,
      xAxis: null,
      yAxis: null,
      legend: commonOption.legend,
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: '50%',
          data: sortedData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
    if (chartValue.type == 'column' || chartValue.type == 'line') {
      //柱状图，折线图
      return lineOption;
    } else if (chartValue.type == 'bar') {
      //条形图
      return barOption;
    } else if (chartValue.type == 'pie') {
      //饼图
      return pieOption;
    }
  };
  return (
    <Card title={chartTitle(chartValue)} style={{ width: '100%', height: 368 }}>
      {/* <div id={chartValue.chartId} style={{ width: '100%', height: '300px' }} /> */}
      <ReactEcharts option={getOption()} notMerge={true} />
    </Card>
  );
};

export default ChartData;
