import React, { useEffect, useState } from 'react';

import { Chart } from '@antv/g2';
import { color } from 'framer-motion';

// 饼图
export const PieChart = ({ chartId, chartData }: { chartId: string; chartData: any }) => {
  useEffect(() => {
    handSetChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const colorArray: any = [];
  chartData.forEach((item: any) => {
    colorArray.push(item.color);
  });

  // 设置饼图
  const handSetChart = () => {
    const chart = new Chart({
      container: chartId,
      autoFit: true,
      height: 300,
    });

    chart.coordinate({ type: 'theta', outerRadius: 0.7 });
    chart
      .interval()
      .data(chartData)
      .transform({ type: 'stackY' })
      .encode('y', 'percent')
      .encode('color', 'label')
      .legend('color', {
        position: 'right',
        layout: { justifyContent: 'center' },
      })
      .scale('color', {
        range: colorArray,
      })
      .tooltip(data => ({
        name: data.label,
        value:data.percent * 100 === 100? `${(data.percent * 100)}%`:`${Math.floor(data.percent * 100 * 100) / 100}%`,
      }));

    chart.render();
    // 解决第一次渲染，图标宽度过款问题
    const e = document.createEvent('Event');
    e.initEvent('resize', true, true);
    window.dispatchEvent(e);
  };

  return <div id={chartId} />;
};

