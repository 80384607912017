import { AxiosPromise } from 'axios';
import request from '../network'
import { RoleVO, RoleListQuery, RoleListVO } from './types';

//获取部门列表
export function checkHasRoleApi(): AxiosPromise<boolean> {
    return request({
        url: `/system/user/add/checkRole`,
        method: 'POST',
        data: {}
    });
}

export function getRoleListApi(): AxiosPromise<RoleVO[]> {
    return request({
        url: `/system/role/optionselect`,
        method: 'GET',
    })
}

export function getRoleList(query: RoleListQuery): AxiosPromise<RoleListVO[]> {
  return request({
      url: `/system/role/list`,
      method: 'GET',
      params: query,
  })
}

// 添加或编辑用户组
export function addRole(query:any) {
  return request({
      url: '/system/role',
      method: 'post',
      data: query,
  })
}

// 添加或编辑用户组
export function editRole(query:any) {
  return request({
      url: '/system/role/edit',
      method: 'post',
      data: query,
  })
}

// 角色状态修改
export function changeStatus(query:any) {
  return request({
      url: '/system/role/changeStatus',
      method: 'put',
      data: query,
  })
}

// 删除角色
export function removeRole(roleIds: string) {
  return request({
      url: `/system/role/remove/${roleIds}`,
      method: 'get',
      params: {},
  })
}

// 根据角色编号获取详细信息
export function getRouters(query:number): AxiosPromise<{checkedKeys: [], menus:[]}> {
  return request({
      url: `/system/menu/roleMenuTreeselect/${query}`,
      method: 'get',
      params: {},
  })
}

// 角色管理-授权
export function authorizations(query:any) {
  return request({
      url: '/system/role/authorizations',
      method: 'post',
      data: query,
  })
}

// 角色关联的用户数量和用户组数量
export function roleCountUser(query:any) {
  return request({
      url: `/system/role/roleCountUser/${query}`,
      method: 'get',
      data: {},
  })
}




export default {}