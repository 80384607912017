import { Input, Drawer, Space, Button, Form, Select, Checkbox, Radio, TreeSelect } from "antd"
import React, { useEffect, ReactNode, useImperativeHandle, useState, forwardRef } from "react";
// import './index.css'
import style from './index.module.scss'
import { getDeptTreeApi } from "../../../../../../api/dept/dept";
import { getBrandListApi } from "../../../../../../api/brand/brand";
import { getShopListApi } from "../../../../../../api/shop/shop";
import { getProductLineListApi } from "../../../../../../api/productLines/productLines";
import type { RadioChangeEvent } from 'antd';
import { DeptVO } from "../../../../../../api/dept/types";
import { BrandVO } from "../../../../../../api/brand";
import { ShopVO } from "../../../../../../api/shop/types";
import { ProductLinesVO } from "../../../../../../api/productLines/types";
import { AccountSourceEnum, AuthorizationWayEnum, AuthorizationWayTextEnum } from "../../../../../../enums/AccountStatusEnum";
import { getUserGroupListApi } from "../../../../../../api/group/group";
import { GroupVO } from "../../../../../../api/group/types";
import { RoleVO } from "../../../../../../api/role/types";
import { getRoleListApi } from "../../../../../../api/role/role";
import { message, Progress } from "antd";
import { red, green, orange } from '@ant-design/colors';
import FormItem from "antd/es/form/FormItem";
import { emailVal, highStrengthLevel, middleStrengthLevel, mobileVal } from "../../../../../../utils/verifyTool";
import { addAccount, modifyAccount } from "../../../../../../api/account/account";
import { AccountVO } from "../../../../../../api/account/types";
import busEvent from '../../../../../../utils/bus';

type FieldType = {
    accountName: string;
    password: string;
    nickName: string;
    deptId: number;
    brandIds: number[];
    shopIds: number[];
    productLineIds: number[];
    email: string;
    phoneNumber: string;
    authType: number;
    userGroupIds: number[];
    roleId: number
};

let userId: number | string;

let accountItem: AccountVO;

const authorizationWayOptions = [
    { label: AuthorizationWayTextEnum.CHOOSEGROUPTEXT, value: AuthorizationWayEnum.CHOOSEGROUP },
    { label: AuthorizationWayTextEnum.DIRECTAUTHORIZATETEXT, value: AuthorizationWayEnum.DIRECTAUTHORIZATE }
];
let CreateAccountDrawer = React.forwardRef((props: {
    createSuccess?: Function,
    editDrawerOpen?: Function,
    type: string
}, ref: any) => {
    // const [messageApi, contextHolder] = message.useMessage();
    //防连点
    const [clickStatus, setClickStatus] = useState(false)
    const [accountForm] = Form.useForm()
    const [open, setOpen] = useState(false)
    //部门列表
    const [deptList, setDeptList] = useState([] as DeptVO[])
    //品牌列表
    const [brandList, setBrandList] = useState([] as BrandVO[])
    //店铺列表
    const [shopList, setShopList] = useState([] as ShopVO[])
    //产品线列表
    const [productLineList, setProductLineList] = useState([] as ProductLinesVO[])
    //用户组列表
    const [userGroupList, setUserGroupList] = useState([] as GroupVO[])
    //角色列表
    const [roleList, setRoleList] = useState([] as RoleVO[])
    //授权方式
    const [authType, setAuthType] = useState(AuthorizationWayEnum.CHOOSEGROUP)
    //密码强度
    const [passwordPercent, setPasswordPercent] = useState(0)
    const [passwordColor, setPasswordColor] = useState([] as any[])

    const [defaultDeptId, setDefaultDeptId] = useState(null as unknown as number | string)
    //品牌
    const [brandIds, setBrandIds] = useState([] as number[])
    const [brandSelectAll, setBrandSelectAll] = useState(false)
    //店铺
    const [shopIds, setShopIds] = useState([] as any)
    const [shopSelectAll, setShopSelectAll] = useState(false)
    //生产线
    const [productLineIds, setProductLineIds] = useState([] as number[])
    const [productLineIdsSelectAll, setProductLineIdsSelectAll] = useState(false)
    //编辑的时候 初始化的密码
    const [oldPaddword, setOldPaddword] = useState(null as any)
    //编辑的时候 初始化的邮箱
    const [oldEmail, setOldEmail] = useState(null as any)
    //登录的时候 是否邮箱必填
    const [bindEmail, setBindEmail] = useState(localStorage.getItem('accessType') == 'EMAIL')
    //登录的时候 是否邮箱必填
    const [bindPhone, setBindPhone] = useState(localStorage.getItem('accessType') == 'PHONE')
    const createDrawerClose = () => {
        setOpen(false)
    }
    const createDrawerOpen = (arr: string[]) => {
        userId = ''
        initData('CREATE')
        console.log(arr);

        if (arr && arr.length > 0) {
            setDefaultDeptId(parseInt(arr[0]))
            accountForm.setFieldValue('deptId', parseInt(arr[0]))
        } else {
            setDefaultDeptId('')
            accountForm.setFieldValue('deptId', null)
        }
        accountForm.setFieldValue('authType', [AuthorizationWayEnum.CHOOSEGROUP])
        accountForm.setFieldValue('accountName', "")
        accountForm.setFieldValue('nickName', "")
        accountForm.setFieldValue('password', "")
        // accountForm.setFieldValue('deptId', null)
        /**
         * speedo隐藏
         
        setBrandIds([])
        setBrandSelectAll(false)
        setShopIds([])
        setShopSelectAll(false)
        setProductLineIds([])
        setProductLineIdsSelectAll(false)
        */
        setPasswordPercent(0);
        accountForm.setFieldValue('phoneNumber', "")
        accountForm.setFieldValue('email', "")
        setAuthType(1)
        accountForm.setFieldValue('userGroupIds', [])
        accountForm.setFieldValue('roleId', [])

    }

    const editDrawerOpen = async (item: AccountVO, type: string) => {
        userId = item.userId
        accountItem = item;
        await initData('EDIT')
        accountForm.setFieldValue('accountName', item.userName)
        setDefaultDeptId(item.deptId || 0)
        accountForm.setFieldValue('deptId', item.deptId)

        accountForm.setFieldValue('phoneNumber', item.phoneNumber)
        setOldPaddword(item.phoneNumber)
        accountForm.setFieldValue('nickName', item.nickName)
        accountForm.setFieldValue('email', item.email)
        setOldEmail(item.email)
        setAuthType(item.authType || 1)
        accountForm.setFieldValue('authType', item.authType == 3 ? [1, 2] : item.authType == 2 ? [2] : item.authType == 1 ? [1] : [])

        let t: number[] = []
        if (accountItem.userGroupIds) {
            accountItem.userGroupIds?.split(',').map(item => {
                t.push(parseInt(item))
            })
            accountForm.setFieldValue('userGroupIds', t)
        }

        let a: number[] = []
        if (accountItem.roleIds) {
            accountItem.roleIds?.split(',').map(item => {
                a.push(parseInt(item))
            })
        }
        accountForm.setFieldValue('roleId', a)
    }


    const initData = async (type: string) => {
        setOpen(true)
        await getDeptList()
        /**
         * speedo隐藏
         * 
        await getBrandList(type)
        await getShopList(type)
        await getProductLineList(type)
         */
        await getUserGroupList()
        await getRoleList()
    }

    useEffect(() => {

    }, [])

    //获取所有部门
    const getDeptList = async () => {
        const res = await getDeptTreeApi()
        if (res) {
            const list = res.data[0].children as any[]
            prepareData(list)
            setDeptList(list)
        }
    }

    const prepareData = (arr: any[]) => {
        arr.map(item => {
            item.title = item.label
            item.value = item.id
            if (item.children && item.children.length > 0) {
                prepareData(item.children)
            }
        })
    }

    //获取所有品牌
    const getBrandList = async (type: string) => {
        const res = await getBrandListApi()
        if (res) {
            const list = res.data
            list.map(item => {
                item.label = item.brand;
                item.value = item.brandId;
            })
            setBrandList(list)
            if (type !== 'CREATE') {
                let brandIds: number[] = []
                accountItem.brandIds?.split(',').map(item => {
                    brandIds.push(parseInt(item))
                })
                setBrandIds(brandIds)
                if (brandIds.length === list.length) {
                    setBrandSelectAll(true)
                } else {
                    setBrandSelectAll(false)
                }
            }
        }
    }

    //获取所有店铺
    const getShopList = async (type: string) => {
        const res = await getShopListApi()
        if (res) {
            const list = res.data
            list.map((item: any) => {
                item.title = item.sysChannelName;
                item.value = item.sysChannelId;
                item.children = item.shop;
                item.key = item.id
                item.children?.map((itm: any) => {
                    itm.title = itm.shopName;
                    itm.value = item.value + '-' + itm.shopId;
                })
            })
            setShopList(list)
            if (type !== 'CREATE') {
                let shopIdsArr = [] as any
                // accountItem.shopIds?.split(',').map(item => {
                //     console.log("==========s-",item);

                //     shopIds.push(parseInt(item))
                // })
                //判断选中的店铺和全部渠道下面的店铺相加总和是不是一样的 一样的话 全选店铺
                var num = 0
                list.map((item: any) => {
                    if (item.shop) {
                        num = item.shop.length + num
                        item.shop.map((itm: any) => {
                            accountItem.shopIds?.split(',').map(im => {
                                if (itm.shopId == im) {
                                    shopIdsArr.push(itm.value)
                                }
                            })
                        })
                    }
                })
                setShopIds(shopIdsArr)
                if ((shopIdsArr.length === num) && num != 0) {
                    setShopSelectAll(true)
                } else {
                    setShopSelectAll(false)
                }
            }
        }
    }

    //获取所有产品线
    const getProductLineList = async (type: string) => {
        const res = await getProductLineListApi()
        if (res) {
            const list = res.data
            list.map(item => {
                item.label = item.productLineName;
                item.value = item.productLineId;
            })
            setProductLineList(list)
            if (type !== 'CREATE') {
                let productLineIds: number[] = []
                accountItem.productLineIds?.split(',').map(item => {
                    productLineIds.push(parseInt(item))
                })
                setProductLineIds(productLineIds)
                if (productLineIds.length === list.length) {
                    setProductLineIdsSelectAll(true)
                } else {
                    setProductLineIdsSelectAll(false)
                }
            }
        }
    }

    //获取所有用户组
    const getUserGroupList = async () => {
        const res = await getUserGroupListApi()
        if (res) {
            const list = res.data
            list.map(item => {
                item.label = item.name;
                item.value = item.id;
            })
            setUserGroupList(list)
        }
    }

    //获取所有角色
    const getRoleList = async () => {
        const res = await getRoleListApi()
        if (res) {
            const list = res.data
            list.map(item => {
                item.label = item.roleName;
                item.value = item.roleId;
            })
            setRoleList(list)
        }
    }
    useImperativeHandle(ref, () => ({
        createDrawerOpen,
        editDrawerOpen
    }));

    const handleCreateClick = async () => {

        console.log(accountForm.getFieldValue('deptId'));

        if (!accountForm.getFieldValue('accountName')) {
            showErrorMessage('账号名称不能为空')
            return
        } else {
            // const reg: any = '^[\u4E00-\u9FA5A-Za-z0-9][\u4E00-\u9FA5A-Za-z0-9-、 _]*$';
            var pattern1 = /^[a-zA-Z0-9\u4e00-\u9fa5\-_、\\]+$/;
            // 使用正则表达式匹配中文、英文或数字开头的字符串
            var pattern = /^[a-zA-Z0-9\u4e00-\u9fa5]/;
            console.log(pattern.test(accountForm.getFieldValue('accountName')), 'pattern.test(accountForm.getFieldValue(accountName))');
            // debugger

            // if((accountForm.getFieldValue('accountName').match(reg, '') === null && !/\\/.test(accountForm.getFieldValue('accountName'))) || !pattern.test(accountForm.getFieldValue('accountName'))){
            if (!pattern1.test(accountForm.getFieldValue('accountName')) || !pattern.test(accountForm.getFieldValue('accountName'))) {
                showErrorMessage('账号名称以中文、英文、数字开头，支持中文、英文、数字和部分符号（-、\\ _）');
                return
            }
        }

        if (accountForm.getFieldValue('accountName').length < 5) {
            showErrorMessage('账号名称至少需要5个字符')
            return
        }
        if (props.type !== 'EDIT') {
            if (!accountForm.getFieldValue('password')) {
                showErrorMessage('初始密码不能为空')
                return
            }
            if (accountForm.getFieldValue('password').length < 8) {
                showErrorMessage('初始密码至少需要8个字符')
                return
            }
        }
        if (!accountForm.getFieldValue('nickName')) {
            showErrorMessage('请输入姓名')
            return
        }
        if (!accountForm.getFieldValue('deptId')) {
            showErrorMessage('请选择所属组织')
            return
        }
        /**
         * speedo隐藏
         * 
        if (brandIds.length === 0) {
            showErrorMessage('至少选择一个品牌')
            return
        }
        if (shopIds.length === 0) {
            showErrorMessage('至少选择一个店铺')
            return
        }
        if (productLineIds.length === 0) {
            showErrorMessage('至少选择一个产品线')
            return
        }
         */
        var status = 0
        if (accountForm.getFieldValue('phoneNumber') && accountForm.getFieldValue('phoneNumber') != oldPaddword) {
            if (!mobileVal(accountForm.getFieldValue('phoneNumber'))) {
                showErrorMessage('输入正确手机号')
                status = 1
                return
            }
        }
        if (accountForm.getFieldValue('email') && accountForm.getFieldValue('email') != oldEmail) {
            if (!emailVal(accountForm.getFieldValue('email'))) {
                showErrorMessage('输入正确邮箱')
                status = 1
                return
            }
        }
        if (status) {
            return
        }
        if (authType === AuthorizationWayEnum.NOCHOOSE) {
            showErrorMessage('请选择授权方式')
            return
        }
        if ((authType === AuthorizationWayEnum.CHOOSEGROUP || authType === AuthorizationWayEnum.ALL) && !accountForm.getFieldValue('userGroupIds').length) {
            showErrorMessage('请选择所属用户组')
            return
        }
        if ((authType === AuthorizationWayEnum.DIRECTAUTHORIZATE || authType === AuthorizationWayEnum.ALL) && !accountForm.getFieldValue('roleId').length) {
            showErrorMessage('请选择功能角色')
            return
        }
        if (clickStatus) {
            return
        }
        setClickStatus(true)
        var arr = [] as any;
        shopIds.map((item: any) => {
            arr.push(Number(item.split('-')[1]))
        })
        const param = {
            userName: accountForm.getFieldValue('accountName'),
            password: props.type === 'CREATE' ? accountForm.getFieldValue('password') : '',
            nickName: accountForm.getFieldValue('nickName'),
            deptId: accountForm.getFieldValue('deptId'),
            /**
             * speedo隐藏
            brandIds,
            shopIds: arr,
            productLineIds,
             */
            email: (oldEmail == accountForm.getFieldValue('email')) && accountForm.getFieldValue('email') ? '0' : accountForm.getFieldValue('email'),
            phoneNumber: (oldPaddword == accountForm.getFieldValue('phoneNumber')) && accountForm.getFieldValue('phoneNumber') ? '0' : accountForm.getFieldValue('phoneNumber'),
            authType,
            userGroupIds: accountForm.getFieldValue('userGroupIds'),
            userRoleIds: accountForm.getFieldValue('roleId'),
            userId
        }

        if (props.type === 'CREATE') {
            const res = await addAccount(param)
            if (res) {
                message.success('账号创建成功');
                if (props.createSuccess) {
                    props.createSuccess();
                }
                createDrawerClose();
            }
            setClickStatus(false)
        } else if (props.type === 'EDIT') {
            const res = await modifyAccount(param)
            if (res) {
                busEvent.emit('updateUserDetail')
                message.success('账号修改成功');
                if (props.createSuccess) {
                    props.createSuccess();
                }
                createDrawerClose();
            }
            setClickStatus(false)
        }
    }

    const handlePasswordInputChange = (e: any) => {
        let password = e.target.value
        password = password.replace(/\s*/g, '').replace(/[\u4e00-\u9fa5]/g, '')// 匹配非中文字符的正则表达式
        accountForm.setFieldValue('password', password)
        if (highStrengthLevel(password)) {
            setPasswordPercent(100)
            setPasswordColor([red[0], orange[0], green[3]])
        } else if (middleStrengthLevel(password)) {
            setPasswordPercent(66)
            setPasswordColor([red[0], orange[3], green[0]])
        } else if (!password) {
            setPasswordPercent(0)
            setPasswordColor([red[0], orange[0], green[0]])
        } else {
            setPasswordPercent(33)
            setPasswordColor([red[3], orange[0], green[0]])
        }
    }

    const showErrorMessage = (str: string) => {
        message.error(str)
    }


    //选择品牌
    const handleBrandSelect = (value: number[]) => {
        setBrandIds(value)
        if (value.length === brandList.length) {
            setBrandSelectAll(true)
        } else {
            setBrandSelectAll(false)
        }
    }

    //品牌全选
    const handleBrandSelectAllClick = (e: any) => {
        if (brandSelectAll) {
            setBrandSelectAll(false)
            setBrandIds([])
        } else {
            setBrandSelectAll(true)
            setBrandIds(brandList.map(item => item.brandId))
        }
    }
    //选择店铺
    const handleShopSelect = (value: any) => {
        setShopIds(value)
        //判断选中的店铺和全部渠道下面的店铺相加总和是不是一样的 一样的话 全选店铺
        var num = 0
        shopList.map((item: any) => {
            if (item.shop) {
                num = item.shop.length + num
            }
        })
        if ((value.length === num) && num != 0) {
            setShopSelectAll(true)
        } else {
            setShopSelectAll(false)
        }
    }

    //店铺全选
    const handleShopSelectAllClick = (e: any) => {
        var arr = [] as any
        var arr1 = [] as any
        if (shopSelectAll) {
            setShopSelectAll(false)
            setShopIds([])
        } else {
            setShopSelectAll(true)
            shopList.map((item: any) => {
                if (item.shop) {
                    item.shop.map((itm: any) => {
                        // parseInt(itm.shopId as string)
                        arr1.push(itm.value);
                        arr.push(Number(itm.shopId))
                    })
                }
            })
            setShopIds(arr1)
        }

    }

    //选择产品线
    const handleProductLineSelect = (value: number[]) => {
        setProductLineIds(value)
        if (value.length === productLineList.length) {
            setProductLineIdsSelectAll(true)
        } else {
            setProductLineIdsSelectAll(false)
        }
    }

    //产品线全选
    const handleProductLineSelectAllClick = (e: any) => {
        if (productLineIdsSelectAll) {
            setProductLineIdsSelectAll(false)
            setProductLineIds([])
        } else {
            setProductLineIdsSelectAll(true)
            setProductLineIds(productLineList.map(item => parseInt(item.productLineId as string)))
        }
    }
    const handleKeyDown = (event: any) => {
        if (event.keyCode === 32) {
            event.preventDefault();
        }
    }
    const setAuthType1 = (e: any) => {
        let type = e.length < 1 ? 0 : e.length > 1 ? 3 : e.toString()
        console.log("====", e, type);
        setAuthType(Number(type))
        console.log(accountForm.getFieldValue('userGroupIds'));
        accountForm.setFieldValue('authType', e)

    }

    return (
        <div>
            {open && <Drawer width={420} styles={{ body: { overflowX: 'hidden' } }} title={`${props.type === 'CREATE' ? '新建' : props.type === 'EDIT' ? '编辑' : '查看'}账号`} onClose={createDrawerClose} open={open}
                extra={
                    props.type !== 'CHECK' && <Space>
                        <Button onClick={createDrawerClose}>取消</Button>
                        <Button type="primary" onClick={handleCreateClick}>
                            确定
                        </Button>
                    </Space>
                }>
                <Form layout="vertical" form={accountForm} disabled={props.type === 'CHECK'}>
                    <Form.Item<FieldType>
                        label="账号名称"
                        name="accountName"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="请输入账号名称" onKeyDown={handleKeyDown} showCount maxLength={20} />
                    </Form.Item>
                    {props.type === 'CREATE' && <Form.Item<FieldType>
                        label="初始密码"
                        name="password"
                        rules={[{ required: true }]}
                    >
                        <Input.Password onChange={handlePasswordInputChange} value={accountForm.getFieldValue('password')} placeholder="请输入初始密码" onKeyDown={handleKeyDown} showCount maxLength={32} minLength={8} />
                        <div style={{ fontSize: 'var(--text-font2)', marginTop: '5px' }}>密码强度
                            <Progress showInfo={false} percent={passwordPercent} steps={3} strokeColor={passwordColor} />
                        </div>
                    </Form.Item>}
                    <Form.Item<FieldType>
                        label="姓名"
                        name="nickName"
                        rules={[{ required: true }]}
                    >
                        <Input autoComplete="off" placeholder="请输入姓名" maxLength={20} count={{ max: 20, show: true }} />
                    </Form.Item>
                    <Form.Item<FieldType>
                        rules={[{ required: true }]}
                        label="所属组织"
                        name="deptId"
                    >
                        <TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="请选择"
                            allowClear
                            treeData={deptList}
                            treeNodeFilterProp="title"
                        />
                    </Form.Item>
                    {/* 
                        speedo隐藏

                    <Form.Item<FieldType>
                        rules={[{ required: true }]}
                        label="所属品牌"
                        name="brandIds"
                    >
                        <div className={style["CreateAccount_form-item-horizontal"]}>
                            <Select
                                style={{ width: 260 }}
                                mode="multiple"
                                allowClear
                                placeholder="请选择"
                                options={brandList}
                                optionFilterProp={'label'}
                                onChange={handleBrandSelect}
                                value={brandIds}
                            />
                            <Checkbox style={{ marginLeft: 12 }} checked={brandSelectAll} onChange={handleBrandSelectAllClick}>全部品牌</Checkbox>
                        </div>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="所属店铺"
                        name="shopIds"
                        rules={[{ required: true }]}
                    >
                        <div className={style["CreateAccount_form-item-horizontal"]}>
                            <TreeSelect
                                showSearch
                                style={{ width: 260 }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="请选择"
                                allowClear
                                treeCheckable
                                value={shopIds}
                                treeData={shopList}
                                onChange={handleShopSelect}
                                treeNodeFilterProp="title"
                            />
                            <Checkbox style={{ marginLeft: 12 }} checked={shopSelectAll} onChange={handleShopSelectAllClick}>全部店铺</Checkbox>
                        </div>
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="所属产品线"
                        name="productLineIds"
                        rules={[{ required: true }]}
                    >
                        <div className={style["CreateAccount_form-item-horizontal"]}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: 246 }}
                                options={productLineList}
                                placeholder="请选择"
                                optionFilterProp={'label'}
                                onChange={handleProductLineSelect}
                                value={productLineIds}
                            />
                            <Checkbox style={{ marginLeft: 12 }} checked={productLineIdsSelectAll} onChange={handleProductLineSelectAllClick}>全部产品线</Checkbox>
                        </div>
                    </Form.Item>
                    */}
                    <Form.Item<FieldType>
                        label="绑定手机号"
                        name="phoneNumber"
                        rules={[{ required: bindPhone }]}
                    >
                        <Input placeholder="请输入手机号" autoComplete="off" maxLength={11} count={{ max: 11, show: true }} />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="绑定邮箱"
                        name="email"
                        rules={[{ required: bindEmail }]}
                    >
                        <Input autoComplete="off" placeholder="请输入邮箱" />
                    </Form.Item>
                    <Form.Item<FieldType>
                        label="授权方式"
                        name="authType"
                        rules={[{ required: true, message: '请选择授权方式' }]}
                    >
                        <Checkbox.Group
                            options={authorizationWayOptions}
                            onChange={e => {
                                setAuthType1(e);
                            }}
                        />
                        {/* <Radio.Group options={authorizationWayOptions} onChange={(e) => { setAuthType(e.target.value) }} /> */}
                    </Form.Item>
                    {(authType === AuthorizationWayEnum.CHOOSEGROUP || authType == AuthorizationWayEnum.ALL) && <Form.Item<FieldType>
                        label="所属用户组"
                        name="userGroupIds"
                        rules={[{ required: true, message: '请选择所属用户组' }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="请选择"
                            options={userGroupList}
                            optionFilterProp={'label'}
                        />
                    </Form.Item>}
                    {(authType === AuthorizationWayEnum.DIRECTAUTHORIZATE || authType == AuthorizationWayEnum.ALL) && <Form.Item<FieldType>
                        label="功能角色"
                        name="roleId"
                        rules={[{ required: true, message: '请选择功能角色' }]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            placeholder="请选择"
                            options={roleList}
                            optionFilterProp={'label'}
                        />
                    </Form.Item>}
                </Form>

            </Drawer>}
        </div>
    );
})
export default CreateAccountDrawer