import React, { useState, useEffect } from "react";
import { Drawer, Button,Space, Form, Input, Select, message, Popconfirm } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import {
  getMemberCardListApi,
  getShopList,
  changePoints,
  changeGrade,
  changeGrowth,
  getMemberLevelList,
} from "../../../../../../api/UserInsight/UserInsight";
import styles from "./index.module.scss";
const { TextArea } = Input;
const ChangeScore = (props: {
  openScoreChange: boolean;
  closeScoreChange: Function;
  popType: any;
  changeScoreColomn?: any;
}) => {
  const { openScoreChange, closeScoreChange = () => {} } = props;
  const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId'):'';
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);
  //会员卡数据
  const [memberCardData, setMemberCardData] = useState([] as any);
  //店铺数据
  const [shoplistData, setShoplistData] = useState([] as any);
  //会员等级列表
  const [memberLevel, setMemberLevel] = useState([] as any);
  //变更类型 
  const changeTypeList = 
  [
    {
      label:'增加',
      value:'ADD'
    },
    {
      label:'减少',
      value:'SUB'
    },
  ]

  // 关闭当前抽屉
  const onClose = (type?:any) => {
    resetData()
    setErrnoText('')
    closeScoreChange(false,type);
  };
  
  // 关闭提示弹窗
  const hidden = () => {
  };
  const [accountForm] = Form.useForm();
  // 查看状态
  type FieldType = {
    OneID: string;
    vipPhone: string;
    memberCardId: string;
    shopId: string;
    beforeGradeCode: string;
    beforeGradeName: string;
    score: string;
    growUp: string;
    afterGradeCode: string;
    changeType: string;
    remark: string;
  };

  const formData: FieldType = {
    OneID: "",
    vipPhone: "",
    memberCardId: "",
    shopId: "",
    beforeGradeCode: "",
    beforeGradeName: "",
    score: "",
    growUp: "",
    afterGradeCode: "",
    changeType: "",
    remark: "",
  };
  const changeScoreColomn = props.changeScoreColomn;
  const [forceUpdate, setForceUpdate] = useState(false); // 更新状态以触发重新渲染
  const [errnoText, setErrnoText] = useState(''); // 成长值低于扣减值，提示文案
  const [nowScore, setNowScore] = useState(0);
  const [changeLevText, setChangeLevText] = useState(
    {
      viplev:'',
      addOrDel:'',
      growScore: 0,
      addOrDellev: '',
    }
  );//变更后等级 文案显示
  const [conditions, setConditions] = useState(
    {} as any
  );
  //重置搜索数据
  const resetData = () => {
    accountForm.resetFields();
    changeConditions({ 
      memberCardId : null,
      shopId : null,
      afterGradeCode : null,
      changeType : null,
      changeQuantity : null,
      remark : null,
     })
  }
  const changeConditions = (newConditions:any) => {
    
    setConditions({
      ...conditions,
      ...newConditions,
    });
    setForceUpdate(!forceUpdate); 
  };
  
  const onFinish = (value:any) =>{
    // console.log("======",conditions);
    
  }
  
  const [loading, setLoading] = useState(false);//防连点
  // 手动修改积分
  const getChangePoints = async(param:any) =>{
    const res  = await changePoints(param);
    if (res) {
      message.success('修改成功')
      onClose(1)
    }
    setLoading(false)
  }
  // 手动修改会员等级
  const getChangeGrade = async(param:any) =>{
    const res  = await changeGrade(param);
    if (res) {
      message.success('修改成功')
      onClose(1)
    }
    setLoading(false)
  }
  // 手动修改会员成长值
  const getChangeGrowth = async(param:any) =>{
    const res  = await changeGrowth(param);
    if (res) {
      message.success('修改成功')
      onClose(1)
    }
    setLoading(false)
  }
  const submit = async () => {
    if (!conditions.memberCardId) {
      message.error('请选择会员卡')
      return
    }
    if (props.popType != 2 && !conditions.changeType) {
      message.error('请选择变更类型')
      return
    }
    if (props.popType != 2 && !conditions.changeQuantity) {
      var text = props.popType == 1? '请输入积分':'请输入成长值'
      message.error(text)
      return
    }
    if (props.popType != 2 && conditions.changeQuantity == 0) {
      var text = props.popType == 1? '请输入大于0的积分':'请输入大于0的成长值'
      message.error(text)
      return
    }
    if (props.popType == 2 && !conditions.afterGradeCode) {
      message.error('请选择变更后的等级')
      return
    }
    if (!conditions.remark) {
      message.error('请输入备注')
      return
    }
    if (loading) {
      return
    }
    //将当前等级的vip 删除掉
    let param = {
      ...conditions,
      // beforeGradeCode:conditions.beforeGradeCode.slice(3)
    }
    if (props.popType === 1) {
      getChangePoints(param)
    }else if(props.popType === 2){
      getChangeGrade(param)
    }else {
      getChangeGrowth(param)
    }
    
  };
  //变更类型改变 提示文字 积分
  const countScore = (value?:any) =>{
    value = value ?? conditions.changeQuantity
    var num:any =  Number(value) + Number(conditions.totalPoint)
    var num2:any = Number(conditions.totalPoint) - Number(value) 
    setNowScore(conditions.changeType == 'ADD'?num:num2)
    if (conditions.changeType == 'SUB' && props.popType === 1) {
      if (num2 < 0) {
        setErrnoText('客户积分不足' + conditions.totalPoint +'积分，无法扣减')
      }else {
        setErrnoText('')
      }
    }
    if (props.popType === 3) {
      var addOrDellev = ''
      var text = conditions.changeType == 'ADD'? '升至':'降至' 
      //增加或减少后的成长值
      var newGrowNum = conditions.changeType =='ADD' ? Number(conditions.growthValue) + Number(value):Number(conditions.growthValue) - Number(value)
      memberLevel.map((item:any,index:any)=>{
        
        if (newGrowNum < 0) {
          setErrnoText('客户成长值不足' + conditions.growthValue +'成长值，无法扣减')
        } else if (item.growthValue <= newGrowNum ) {
          addOrDellev = text + item.gradeName
          setErrnoText('')
        } else if (Number(conditions.growthValue) + Number(value) == item.growthValue) {
          setErrnoText('')
          if (item.gradeCode == conditions.beforeGradeCode) {
            addOrDellev = '无提升'
          }
        }
      })
      setChangeLevText(
        {
          viplev: '',
          addOrDel: '',
          growScore: 0,
          addOrDellev:addOrDellev,
        }
      )
    }
  }
  //变更等级改变 提示文字 积分
  const changeLevel = (value?:any) =>{
    value = value ?? conditions.afterGradeCode
    var old = conditions.beforeGradeCode//以前等级
    var newlev = Number(value)//变更后等级
    var levName = '' //变更后等级名称
    var growthValue:any = null
    
    memberLevel.map((item:any)=>{
      if (item.gradeCode == newlev) {
        growthValue = item.growthValue
        levName = item.gradeName
      }
    })
    var viplev = ''
    var addOrDel = ''
    var growScore = 0
    
    if (growthValue < conditions.growthValue) {
      addOrDel = '减少';
      growScore = Number(conditions.growthValue) - Number(growthValue)
    }else if (growthValue > conditions.growthValue){
      addOrDel = '增加';
      growScore = Number(growthValue) - Number(conditions.growthValue)
    }
    if (old > newlev) {
      viplev = '降至' + levName;
    }else if (old < newlev) {
      viplev = '升至' + levName;
    }else{
      viplev = '保持' + levName;
    }
    setChangeLevText(
      {
        viplev: viplev,
        addOrDel: addOrDel,
        growScore: growScore,
        addOrDellev:''
      }
    )
  }
  //更新状态以触发重新渲染
  const getFrom = () =>{
    console.log("=======changeScoreColomn",changeScoreColomn);
    
    changeConditions({ 
      oneId: changeScoreColomn.oneId,
      aesPhone: changeScoreColomn.aesPhone,
      beforeGradeName: changeScoreColomn.gradeName,
      beforeGradeCode: changeScoreColomn.gradeId,
      growthValue: changeScoreColomn.growthValue,
      totalPoint: changeScoreColomn.totalPoint,
      memberCardId:changeScoreColomn.memberCardId,
     })
    var arr = [
      {
        memberCardName:changeScoreColomn.memberCardName,
        memberCardId:changeScoreColomn.memberCardId,
      }
    ]
    setMemberCardData(arr)
    getMemberLevel(changeScoreColomn.memberCardId)
    setForceUpdate(!forceUpdate); 
  }
  //获取店铺信息
  const getShopListData = async() =>{
    let res  = await getShopList();
    if (res) {
        setShoplistData(res.data)
    }
  };
  //获取会员等级列表
  const getMemberLevel = async(e?:any) =>{
    var params = {
      memberCardId : e 
    }
    let res  = await getMemberLevelList(params);
    if (res) {
      res.data.map((item:any)=>{
        item.label =  item.gradeName
        item.value =  item.gradeCode
        if (item.gradeCode == changeScoreColomn.gradeId) {
          item.disabled = true
        } else {
          item.disabled = false
        }
      })
      setMemberLevel(res.data)
      // if (props.popType == 3) {
      //   countScore()
      // }else if(props.popType == 2) {
      //   changeLevel()
      // }
    }
  };
  useEffect(() => {
    countScore()
  },[conditions])

  useEffect(() => {
    setOpen(openScoreChange);
    if (openScoreChange) {
      if (changeScoreColomn) {
        getFrom()
      }
    }
    setErrnoText('')
  }, [openScoreChange]);
  useEffect(() => {
    // getMemberCardList()
    getShopListData()
    document.addEventListener('click', hidden)
    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={props.popType === 1 ?'积分变更':props.popType === 2?'等级变更':"成长值变更"}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button type="primary" disabled={errnoText?true:false} onClick={() => submit()}>
              确定
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={accountForm} onFinish={onFinish}>
          <Form.Item<FieldType>
            label="OneID"
            name="OneID"
            rules={[{ required: props.popType === 1, message: "请输入OneID" }]}
          >
            <Input
              defaultValue={conditions.oneId}
              disabled={true}
            />
          </Form.Item>
          {props.popType !== 1 &&<Form.Item<FieldType>
            label="会员手机号"
            name="vipPhone"
          >
            <Input
              defaultValue={conditions.aesPhone}
              disabled={true}
            />
          </Form.Item>}
          
          <Form.Item<FieldType>
            label="会员卡"
            name="memberCardId"
            rules={[{ required: true, message: "请选择会员卡" }]}
          >
            <Select
              placeholder="请选择会员卡"
              defaultValue={conditions.memberCardId}
              key={conditions.memberCardId}
              disabled
              // onChange={(e) => { 
              //   changeConditions({ memberCardId: e });
              //   getMemberLevel(e)
              // }}
              fieldNames= {{
                label: 'memberCardName',
                value: 'memberCardId'
              }}
              options={memberCardData}
            />
          </Form.Item>
          {props.popType === 2 && <Form.Item<FieldType>
            label="选择店铺"
            name="shopId"
          >
            <Select
              placeholder="请选择店铺"
              value={conditions.shopId}
              onChange={(e) => changeConditions({ shopId: e })}
              options={shoplistData}
              fieldNames = {{
                label: 'shopName',
                value: 'shopId'
              }}
            />
          </Form.Item>}
          {props.popType === 2 &&<Form.Item<FieldType>
            label="当前等级"
            name="beforeGradeName"
          >
            <Input
              key={conditions.beforeGradeName}
              disabled={true}
              defaultValue={conditions.beforeGradeName}
            />
          </Form.Item>}
          {props.popType === 1 && <Form.Item<FieldType>
            label="当前积分"
            name="score"
          >
            <Input
              defaultValue={conditions.totalPoint}
              disabled={true}
            />
          </Form.Item>}
          {props.popType === 3 && <Form.Item<FieldType>
            label="当前成长值"
            name="growUp"
          >
            <Input
              disabled={true}
              defaultValue={conditions.growthValue}
            />
          </Form.Item>}
          {props.popType === 2 &&<Form.Item<FieldType>
            label="变更后等级"
            name="afterGradeCode"
            rules={[{ required:true, message: "请选择变更后等级" }]}
          >
            <Select
              placeholder="请选择变更后等级"
              value={conditions.afterGradeCode}
              onChange={(e) => {
                changeConditions({ afterGradeCode: e })
                changeLevel(e)
              }}
              disabled = {!conditions.memberCardId}
              // fieldNames= {{
              //   label: 'gradeCode',
              //   value: 'gradeCode'
              // }}
              options={memberLevel}
            />
            {conditions.afterGradeCode && 
              <div>客户{changeLevText.viplev}，本次操作将
                <span style={{color:'var(--text-font-color7)'}}>{changeLevText.addOrDel}</span >客户
                <span style={{color:'var(--text-font-color7)'}}>{changeLevText.growScore}</span>成长值
              </div>
            }
          </Form.Item>}
          {props.popType !== 2 &&<Form.Item<FieldType>
            label="变更类型"
            name="changeType"
            rules={[{ required: true, message: "请选择变更类型" }]}
          >
            <div style={{display: 'flex',height:'32px'}}>
              <Form.Item>
                <Select
                  style={{width:'160px',marginRight:'8px'}}
                  placeholder="请选择"
                  value={conditions.changeType}
                  onChange={(e) => {
                    changeConditions({ changeType: e })
                    countScore()
                  }}
                  options={changeTypeList}
                />
              </Form.Item>
              <Form.Item>
                <Input
                  disabled={conditions.changeType ? false : true}
                  placeholder="请输入"
                  maxLength={7}
                  value={conditions.changeQuantity}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g,'');
                    changeConditions({ changeQuantity: value })
                    countScore(value )
                  }}
                  addonAfter={props.popType === 1 ?'积分':''}
                />
              </Form.Item>
            </div>
            {conditions.changeQuantity && props.popType === 1 &&  !errnoText && 
              <div style={{color:'var(--text-font-color18)'}}>本次操作后，客户当前积分为<span style={{color:'var(--text-font-color7)'}}>{nowScore}</span></div>}
            {conditions.changeQuantity && props.popType === 3 && conditions.memberCardId && !errnoText &&
              <div style={{color:'var(--text-font-color18)'}}>本次操作将{conditions.changeType == 'ADD'?'增加':'减少'}客户
                <span style={{color:'var(--text-font-color7)'}}>
                  {conditions.changeQuantity}
                </span>成长值，会员等级{changeLevText.addOrDellev}
              </div>}
            {errnoText && 
              <div>
                {errnoText}
              </div>
              }
          </Form.Item>}
          <Form.Item<FieldType> label="备注" name="remark" rules={[{ required: true, message: "请输入备注" }]}>
            <TextArea
              placeholder="请输入备注"
              showCount
              maxLength={100}
              style={{ height: 100, resize: "none" }}
              value={ conditions.remark }
              onChange={(e) =>
               {
                  changeConditions({ remark: e.target.value })
                  // accountForm.setFieldsValue({remark :e.target.value })
               }
              }
            />
            {props.popType == 1 && <div className={styles.remarkBottom}>变更记录可在 积分变更记录 中查看</div>}
            {props.popType == 2 && <div className={styles.remarkBottom}>会员升降级记录可在 等级变更记录 中查看</div>}
            {props.popType == 3 && <div className={styles.remarkBottom}>变更记录可在 成长值变更记录 中查看</div>}
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default ChangeScore;
