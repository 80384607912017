import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import FilterView, { CrmSearchItem } from '@/crm/component/FilterView';
import { TextColorTypes } from '../../../crm/component/MiniStyles';
import { Table, Progress, ConfigProvider, Button, message, Popover, Tooltip } from 'antd';
import type { ProgressProps } from 'antd';
import { CaretUpOutlined, CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  getStockMemberApi,
  getStockStatusStatisticsApi,
  downloadStockCustomerApi,
  getTransStatisticsApi,
} from '../../../api/stockMember/stockMember';
import JD from '../../../assets/images/JD.png';
import TM from '../../../assets/images/TM.png';
import DY from '../../../assets/images/DY.png';
import MD from '../../../assets/images/offlineShop.png';
import YZ from '../../../assets/images/YZ.png';
import jdBule from '../../../assets/images/jdBule.png';
import tmBlue from '../../../assets/images/tmall.png';
import dyBlue from '../../../assets/images/douyin.png';
import mdBlue from '../../../assets/images/offlineShopBlue.png';
import yzBlue from '../../../assets/images/youzan.png';
import { LiquidChart } from './components/LiquidChart';
import { UserItem } from '@/api/stockMember/types';
import arrowPng from '../../../assets/images/arrow.png';
import { STOCKMEMBER_KEY, USER_KEY } from '../../../router/Permissions';
import checkPermission from '@/crm/assets/public/checkPermission';
import SearchIconInput from '../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../component/FilterIcon/filterIcon';
type FieldType = {
  transferred?: string;
  searchType?: string;
  searchValue?: string;
};

// 渠道图标map
export const channelIconMapDefault = ['JD', 'TM', 'DY', 'MD', 'YZ'];
export const channelIconMapText = new Map([
  ['JD', '京东'],
  ['TM', '天猫'],
  ['DY', '抖音'],
  ['MD', '线下门店'],
  ['YZ', '有赞'],
]);
export const channelIconMap = new Map([
  ['JD', JD],
  ['TM', TM],
  ['DY', DY],
  ['MD', MD],
  ['YZ', YZ],
  ['JDBule', jdBule],
  ['TMBule', tmBlue],
  ['DYBule', dyBlue],
  ['MDBule', mdBlue],
  ['YZBule', yzBlue],
]);
const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};
const conicColors: ProgressProps['strokeColor'] = {
  '0%': '#B0D0FF',
  '100%': '#5B8FF9',
};
const StockMemberManagement = () => {
  const navigate = useNavigate();
  const filterViewRef = useRef<any>(null);
  const [stockNum, setStockNum] = useState(0);
  const [conversionNumber, setConversionNumber] = useState(0);
  const [memberNum, setMemberNum] = useState(0);
  const [yesterdayTransNum, setYesterdayTransNum] = useState(0);
  const [monthTransNum, setMonthTransNum] = useState(0);
  const [lastMonthTransNum, setLastMonthTransNum] = useState(0);
  const [lastYearMonthTransNum, setLastYearMonthTransNum] = useState(0);
  const searchList: CrmSearchItem[] = [
    {
      searchType: 'string',
      searchComponentType: 'selectInput',
      options: [
        {
          value: 'oneId',
          label: 'OneID',
        },
        {
          value: 'tmOuid',
          label: 'Ouid',
        },
      ],
      placeHolder: '请输入',
      key: 'searchValue',
      selectInputKey: 'searchType',
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '是否转化',
      key: 'transferred',
      options: [
        {
          value: '1',
          label: '已转化',
        },
        {
          value: '0',
          label: '未转化',
        },
      ],
    },
  ];
  const defaultFormValue = {
    searchType: 'oneId',
    searchValue: '',
    transferred: '',
  };
  const searchParamInt: any = {};
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const [tableList, setTableList] = useState<UserItem[]>([]);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const filterFinish = (result: any) => {
    console.log(result);
    setSearchInfo({ ...result });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };
  // 获得table列表
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getStockMemberApi({
      oneId: searchInfo.searchType == 'oneId' ? searchInfo.searchValue : '',
      tmOuid: searchInfo.searchType == 'tmOuid' ? searchInfo.searchValue : '',
      transferred: searchInfo.transferred,
      pageNum,
      pageSize,
    });
    console.log(res);

    if (res) {
      setTableList(res.rows);
      setTableInfo({ ...tableInfo, total: res.total });
    }
  };

  useEffect(() => {
    // filterViewRef.current?.showFilterView();
    getStockStatusStatistics();
    getTransStatistics();
  }, []);
  const downloadStockCustomer = async () => {
    const params = {
      taskName: '忠诚度管理-存量会员管理',
      taskLocation: '忠诚度管理/存量会员管理/存量会员管理',
    };
    const res = await downloadStockCustomerApi(params);
    if (res.code == 200) {
      message.success('导出成功,请到下载中心页面查看');
    }
  };
  const jumpPage = (value: any) => {
    console.log(111, value);
    if (checkPermission(USER_KEY.USER_DETAIL)) {
      navigate('/userInsight/userInsightDocument/UserDetail');
      localStorage.setItem('oneId', value);
    }
  };
  const renderChannelType = (value: any) => {
    let iconList: any = [],
      newArray: any = [],
      textArray: any = [];
    value.forEach((item: any) => {
      if (item.channelType) {
        iconList.push(item.channelType);
      }
    });
    console.log(111, iconList);
    channelIconMapDefault.forEach((item: any) => {
      if (iconList.length > 0 && iconList.filter((i: any) => i == item).length > 0) {
        newArray.push(channelIconMap.get(item + 'Bule'));
      } else {
        newArray.push(channelIconMap.get(item));
      }
      textArray.push(channelIconMapText.get(item));
    });
    return newArray.map((item: any, index: any) => {
      return (
        <Popover content={textArray[index]}>
          <img src={item} alt="" style={{ width: '20px', height: '20px' }} />
        </Popover>
      );
    });
  };
  const getStockStatusStatistics = async () => {
    const res = await getStockStatusStatisticsApi();
    let total = 0,
      rest = 0,
      member = 0;
    if (res) {
      res.data.forEach((item: any) => {
        if (item.transferred === 1) {
          member = item.num;
        } else if (item.transferred === 0) {
          rest = item.num;
        }
      });
    }
    total = rest + member;
    setMemberNum(member);
    setConversionNumber(rest);
    setStockNum(total);
  };
  const getTransStatistics = async () => {
    const res = await getTransStatisticsApi();
    if (res) {
      setYesterdayTransNum(res.data.yesterdayTransNum); //昨日转化数
      setMonthTransNum(res.data.monthTransNum); //本月转化数
      setLastMonthTransNum(res.data.lastMonthTransNum); //上月转化数
      setLastYearMonthTransNum(res.data.lastYearMonthTransNum); //去年同月转化数
    }
  };
  useEffect(() => {
    getTableList();
  }, [
    tableInfo.pageNum,
    tableInfo.pageSize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(searchInfo),
  ]);
  return (
    <div className={style['StockMemberManagement-bgd']}>
      <div className={style['title']}>存量会员管理</div>
      <div className={style['StockMemberManagement-content']}>
        <div className={style['dashboard-box']}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ConfigProvider
              theme={{
                components: {
                  Progress: {
                    /* 这里是你的组件 token */
                    circleTextFontSize: '24px',
                  },
                },
              }}>
              <Progress
                type="dashboard"
                percent={(conversionNumber / stockNum) * 100}
                size={180}
                strokeColor={conicColors}
                gapDegree={165}
                format={() => `${Number(conversionNumber).toLocaleString()}`}
              />
            </ConfigProvider>
            <div className={style['progress-text']}>
              <span>0</span>
              <span>当前存量会员数</span>
              <span>{Number(stockNum).toLocaleString()}</span>
            </div>
          </div>
          <div className={style['conversion']}>
            <div className={style['conversion-percent']}>
              <span style={{ color: 'var(--text-font-color9)' }}>已转化：</span>
              <span style={{ color: 'var(--text-font-color7)' }}>{memberNum == 0 || stockNum == 0 ? 0 : Math.floor((memberNum / stockNum) * 100 * 100) / 100}%</span>
            </div>
            <img className={style['arrow-img']} src={arrowPng} alt="" />
          </div>
          <div className={style['liquid-text']}>
            已转化会员数
            <div>{Number(memberNum).toLocaleString()}</div>
          </div>
          <LiquidChart chartId={'liquid'} chartData={{ memberNum, stockNum: stockNum ? stockNum : 1 }} />
          <div className={style['trans-box']}>
            <div className={style['trans-item']} style={{ color: 'var(--text-font-color4)' }}>
              昨日转化 <div style={{ fontSize: 'var(--text-font13)', color: 'var(--text-font-color9)' }}>{yesterdayTransNum}</div>{' '}
            </div>
            <div className={style['trans-item']}>
              <div>
                同比
                <Tooltip title="(本月转化-去年同月转化) / 去年同月转化 ×100%">
                  <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)', marginLeft: 5 }} />
                </Tooltip>
              </div>
              {monthTransNum - lastYearMonthTransNum == 0 || lastYearMonthTransNum == 0 ? (
                <div>0</div>
              ) : (
                <div style={{ color: monthTransNum - lastYearMonthTransNum > 0 ? 'var(--text-font-color19)' : 'var(--label5)' }}>
                  {monthTransNum - lastYearMonthTransNum > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  {lastYearMonthTransNum ? (Math.round(((monthTransNum - lastYearMonthTransNum) / lastYearMonthTransNum) * 100) / 100) * 100 : 0}%
                </div>
              )}
            </div>
            <div className={style['trans-item']}>
              <div>
                环比
                <Tooltip title="(本月转化-上月转化) / 上月转化×100%">
                  <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)', marginLeft: 5 }} />
                </Tooltip>
              </div>
              {monthTransNum - lastMonthTransNum == 0 || lastMonthTransNum == 0 ? (
                <div>0</div>
              ) : (
                <div style={{ color: monthTransNum - lastMonthTransNum > 0 ? 'var(--text-font-color19)' : 'var(--label5)' }}>
                  {monthTransNum - lastMonthTransNum > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
                  {lastMonthTransNum ? (Math.round(((monthTransNum - lastMonthTransNum) / lastMonthTransNum) * 100) / 100) * 100 : 0}%
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={style.buttonBox}>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
          <span
            style={{
              width: '0',
              height: '16px',
              border: 'var(--border3)',
              marginRight: 16,
            }}></span>
          {checkPermission(STOCKMEMBER_KEY.EVENT_DOWNLOAD) && (
            <Button onClick={downloadStockCustomer} disabled={Number(conversionNumber) == 0} type="primary">
              下载人群
            </Button>
          )}
        </div>
        <div style={{ width: 'calc(100% + 32px)', marginLeft: '-16px', marginTop: '8px', marginBottom: '8px' }}>
          <FilterView
            background="var(--background1)"
            ref={filterViewRef}
            searchList={searchList}
            defaultFormValue={defaultFormValue}
            finish={filterFinish}></FilterView>
        </div>
        <Table
          scroll={{ x: 1000 }}
          dataSource={tableList}
          pagination={{
            current: tableInfo.pageNum,
            pageSize: tableInfo.pageSize,
            total: tableInfo.total,
            onChange: (pageNo, pageSize) =>
              setTableInfo({
                ...tableInfo,
                pageNum: pageNo,
                pageSize,
              }),
            showSizeChanger: true,
            showTotal: total => `共 ${total} 条`,
          }}>
          <Table.Column
            width={350}
            title="OneID"
            dataIndex="oneId"
            render={(value, record: any) => (
              <>
                {/* <Link
                  onClick={() => {
                    jumpPage(value);
                  }}
                  className={style['text-btn']}
                  to="/userInsight/userInsightDocument/UserDetail"
                  target="_blank">
                  {value}
                </Link> */}
                <Button
                  className={style['text-btn']}
                  style={{ color: checkPermission(USER_KEY.USER_DETAIL) ? '' : 'var(--text-font-color3)' }}
                  onClick={() => {
                    jumpPage(value);
                  }}>
                  {value}
                </Button>
              </>
            )}
          />
          <Table.Column width={350} title="Ouid" dataIndex="tmOuid" />
          {/* <Table.Column
            width={100}
            title="客户身份"
            dataIndex="identityCode"
            render={(value, record: any) => (
              <div>
                <Popover content={'存量会员'}>
                  <CrownOutlined style={{ color: value == 'STOCK' ? 'var(--text-font-color7)' : '', marginRight: 5 }} />
                </Popover>
                <Popover content={'渠道会员'}>
                  <DeploymentUnitOutlined style={{ color: value == 'CHANNEL' ? 'var(--text-font-color7)' : '', marginRight: 5 }} />
                </Popover>
                <Popover content={'普通会员'}>
                  <TeamOutlined style={{ color: value == 'COMMON' ? 'var(--text-font-color7)' : '', marginRight: 5 }} />
                </Popover>
                <Popover content={'员工'}>
                  <IdcardOutlined style={{ color: value == 'STAFF' ? 'var(--text-font-color7)' : '', marginRight: 5 }} />
                </Popover>
              </div>
            )}
          />
          <Table.Column
            width={100}
            title="所属渠道"
            dataIndex="customerMemberInfo"
            render={(value, record: any, index) => renderChannelType(value)}
          /> */}
          <Table.Column
            width={200}
            title="是否转化"
            dataIndex="transferred"
            render={(value, record: any) => <TextColorTypes text={value == 1 ? '已转化' : '未转化'} status={value} />}
          />
        </Table>
      </div>
    </div>
  );
};

export default StockMemberManagement;
