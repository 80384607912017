import React, { useEffect } from 'react';
import { Layout } from 'antd';
// import './index.css'
import style from './index.module.scss'
import logo from '../../../assets/images/logo.png'
import MenuItem from './MenuItem';
import { useSnapshot } from 'valtio';
import { state, changeCurrentMenuIndex, setCurrentPath } from './../../../store/index'
import { useLocation, useNavigate } from 'react-router-dom';
import routerMap from '../../../router/routerMap';


const { Sider } = Layout;



const Menu: React.FC = () => {
    const snap = useSnapshot(state);
    const menuList = state.menu;
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        // 在路由变化时执行的操作
        const menu = state.menu
        // 全局储存当前页面的路由
        setCurrentPath(location.pathname)
        // 修改所有层级选中菜单项
        // routerMap.find(item => item.path === pathname);
        menu.forEach((item, index) => {
            const config = routerMap.find(item1 => item1.path === location.pathname);
            if (item.key && config?.menu && location.pathname.indexOf(item.key.toString()) !== -1) {
                changeCurrentMenuIndex(index, location.pathname)
            }
        })
        //跳转到根目录时，跳转到菜单首个菜单项
        if (location.pathname === '/') {
            if (menu[0] && menu[0].children && menu[0].children[0] && menu[0].children[0].children) {
                navigate(menu[0].children[0].children[0].key.toString())
            }
            if (menu[0] && (!menu[0].children || menu[0].children.length < 1)) {
                navigate(menu[0].key.toString())
            }
        }
    }, [location]);// eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Sider width={'64px'} className={style['lz_menu']}>
            <div className={style['lz_main-menu']}>
                <img className={style['lz_menu-logo']} src={state.styleStoreData.groundLogo}  alt="" />
                {menuList.map((item, index) => (
                    <MenuItem selected={snap.currentMenuIndex === index} key={index} menuItemObj={item} index={index}></MenuItem>
                ))}
            </div>
        </Sider>
    );
}

export default Menu;