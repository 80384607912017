import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Divider, Steps } from 'antd';
import type { CollapseProps } from 'antd';
import { Form, Tooltip, Button, Select, Space, Empty ,DatePicker ,Pagination } from "antd"
import style from './index.module.scss'
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import type { CheckboxProps } from 'antd';
import {
    getJourneyRecord,
    getShopList,
    getChannelListApi
} from "../../../../../../api/UserInsight/UserInsight";
import FilterView from "../../../../../../component/FilterView/filterView";

const { RangePicker } = DatePicker;
let Resume = forwardRef((props: {
}, ref: any) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        getUserResumeList,
        resetFilter
    }))
    const oneId = localStorage.getItem('oneId') ? localStorage.getItem('oneId'):'';
    const filterViewRef: any = useRef(null)
    const [form] = Form.useForm();
    const [iconColor, setIconColor] = useState('var(--text-font-color4)');
    const handleMouseEnter = () => {
        // console.log('鼠标移入')
        setIconColor('var(--text-font-color9)');
    };
    const handleMouseLeave = () => {
        // console.log('鼠标移出')
        setIconColor('var(--text-font-color4)');
    };
    
    //渠道数据
    const [channeldData, setChanneldData] = useState([] as any);
    //店铺下拉框数据
    const [shoplistData , setShoplistData] = useState([] as any);
    //搜索条件
    const searchList: searchItem[] = [
        {
            searchType: "text",
            searchComponentType: "dateRangeDoubel",
            placeHolder: "",
            title: "变更时间",
            key: "startTime-endTime",
        },
        {
            searchType: "text",
            searchComponentType: "checkBox",
            placeHolder: "",
            title: "",
            key: "nolitTime",
        },
        {
            searchType: "text",
            searchComponentType: "selectVip",
            placeHolder: "请选择渠道",
            title: "渠道",
            key: "channelType",
            // defaultValue:'',
            changeField:'shopId',
            options: channeldData,
            fieldNames: {
                label: 'channelName',
                value: 'channelType'
            }
        },
        {
            searchType: "text",
            searchComponentType: "select",
            placeHolder: "请选择店铺",
            title: "店铺",
            key: "shopId",
            // defaultValue:'',
            options: shoplistData,
            fieldNames: {
                label: 'shopName',
                value: 'shopId'
            }
        },
    ];
    //搜索字段
    const [searcParam, setSearcParam] = useState({ });
    // 查询
    const filterFinish = (data: any) => {
        setSearcParam(data);
        getUserResumeList({ pageSize: 10, pageNum: 1 }, data );
    };
    const [resumeList, setResumeList] = useState([] as any)
    const searcParamInt: any = {
        oneId: oneId,
    };
    // 页码信息
    const [pageQuery, setPageQuery] = useState({
        pageSize: 10,
        pageNum: 1,
        total: 0,
    });
    const channelTypeName = (type: string) => {
        switch (type) {
          case 'JD': 
            return '京东'
            break;
          case 'DY': 
            return '抖音'
            break;
          case 'TM': 
            return '天猫'
            break;
          case 'YZ': 
            return '有赞'
            break;
          case 'offlineShop': 
            return '线下门店'
            break;
          default:
            break
        }
    };
    // 换页
    const handleTableChange = (newPage: number, newPageSize: number) => {
        getUserResumeList({ pageSize: newPageSize, pageNum: newPage }, searcParam);
    };
    //获取客户履历
    const getUserResumeList = async(
        page: { pageSize: number; pageNum: number },
        data?: any,
    ) =>{
        const param = {
            ...page,
            ...data,
            ...searcParamInt,
        } as any;
        let res = await getJourneyRecord(param);
        if (res) {
            setPageQuery({
                pageSize: page.pageSize,
                pageNum: page.pageNum,
                total: res.total,
            });
            let list =[] as any
            //下单数据
            const listData = res.rows
            if (listData && listData.length > 0) {
                listData.map((item:any,index:any)=>{
                    list.push({
                        title:<>
                            <div className={style['Resume_left-title']} key={index}>{item.eventTime}</div>
                            <div className={style['Resume_progressDot-box']} style={{
                                border: item.eventType == '下单' ? 'var(--border11)':
                                        item.eventType == '入会' ? 'var(--border14)':
                                        item.eventType == '积分变更' ? 'var(--border15)':
                                        item.eventType == '成长值变更' ? 'var(--border16)': 'var(--border13)'
                            }}></div>
                            <div className={style['Resume_step-text']}>{item.eventType}</div>
                        </>,
                        description: <>
                                        {(item.shopName && item.channelName) && 
                                        <div className={style['Resume_step-text']}>
                                            <span style={{marginRight:'10px'}}>{channelTypeName(item.channelName)}</span>
                                            {item.shopName}
                                        </div>}
                                        {item.memberTime && <div>入会时间：
                                            <span className={style['Resume_step-text']}>{item.memberTime}</span>
                                        </div>}
                                        {item.orderId && <div>订单号：
                                            <span className={style['Resume_step-text']}>{item.orderId}</span>
                                        </div>}
                                        {item.orderPrice && <div>订单价格：
                                            <span className={style['Resume_step-text']}>{item.orderPrice}</span>
                                        </div>}
                                        {item.orderId && <div>订单状态：
                                            <span className={style['Resume_step-text']}>已完成</span>
                                        </div>}
                                        {item.memberCardName && <div>会员卡名称：
                                            <span className={style['Resume_step-text']}>{item.memberCardName}</span>
                                        </div>}
                                        {item.addPoint && <div>{item.changeType}：
                                            <span className={style['Resume_step-text']}>{item.addPoint}</span>
                                        </div>}
                                        {item.afterValue && item.eventType.indexOf('等级变更') == -1 && <div>
                                            {item.eventType.indexOf('积分变更') != -1? '当前积分':'当前成长值'}：<span className={style['Resume_step-text']}>{item.afterValue}</span>
                                        </div>}
                                        {item.afterValue && item.eventType.indexOf('等级变更') != -1 && <div>等级变更为：
                                            <span className={style['Resume_step-text']}>VIP{item.afterValue}</span>
                                        </div>}
                                        {item.changeCause && <div>变更原因：
                                            <span className={style['Resume_step-text']}>{item.changeCause}</span>
                                        </div>}
                                    </>,
                    })
                    setResumeList(list)
                })
            }else{
                setResumeList([])
            }
        }

    };
    // 查询所属渠道下的店铺
    const handleChangeFilter = (data: any) => {
        if (data) {
            getShopListData(data)
        } else {
            getShopListData()
        }
    };
    //获取店铺信息
    const getShopListData = async(data?:any) =>{
        const param = {
            channelType:data
        }
        let res  = await getShopList(param);
        //下单数据
        if (res) {
            setShoplistData(res.data)
        }
    };
    // 获取所属渠道
    const getChannelList = async () => {
        const res  = await getChannelListApi();
        if (res) {
            setChanneldData(res.data)
        }
    };
    // 改变客户履历 样式
    const progressDot =  () => {
       return <><div></div></>
    };
    //监听或进入调用
    useEffect(() => {
        getUserResumeList({ pageSize: 10, pageNum: 1 })
        getShopListData()
        getChannelList()
        filterViewRef.current?.showFilterView();
    }, [])
    const resetFilter = () => {
        filterViewRef.current?.resetData();
    };

    return <>
        <div className={style['Resume']}> 
            <div className={style['Resume_serch-box']}>
                <Tooltip placement="bottom" title={'过滤器'}>
                    <Button
                    onClick={()=>filterViewRef.current?.showFilterView()}
                    icon={
                    <FilterOutlined
                        style={{
                        color: iconColor,
                        fontSize: 'var(--text-font7)',
                        }}
                    />
                    }
                    type="text"
                    className="click-element"
                    style={{marginRight:'10px'}}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}></Button>
                </Tooltip>
            </div>
            <div className={style['search-list-continer']}>
                <FilterView
                    ref={filterViewRef}
                    searchList={searchList}
                    finish={filterFinish}
                    averageTwo={true}
                    doubelDate={true}
                    handleChange={handleChangeFilter}
                ></FilterView>
            </div>
            <Steps
                style={{paddingLeft: '230px'}}
                progressDot={()=>progressDot()}
                current={-1}
                direction="vertical"
                items={resumeList}
            />
            { resumeList && resumeList.length > 0 && <div style={{textAlign: 'right'}}>
                <Pagination
                    pageSize={pageQuery.pageSize}
                    current={pageQuery.pageNum}
                    total={pageQuery.total}
                    showSizeChanger
                    showTotal={(total) => `共 ${total} 条`}
                    onChange={handleTableChange}
                />
            </div>}
            { !resumeList || resumeList.length == 0 &&<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{marginTop:'100px'}}/>}
        </div>
    </>
})
export default Resume
