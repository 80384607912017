import React, { useState, useEffect } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Drawer, Button, Table, message, Popconfirm, Tooltip } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import {
  getExportNameList,
  getUploadTask,
  getLabelUploadTask,
  getEncryptTypeListApi
} from "@/api/userList/userList";
import styles from "./index.module.scss";

const DownloadUserDeatil = (props: {
  openDownloadPop: boolean;
  closeDownLoadItem: Function;
  searcParam: any;
  crowdId: any;
}) => {
  const { openDownloadPop, closeDownLoadItem = () => { } } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);

  const [listData, setListData] = useState([] as any);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(['OneID']);

  // 关闭当前抽屉
  const onClose = () => {
    closeDownLoadItem(false);
    setListData([]);
    // selectedRowKeys = []
    setSelectedRowKeys(['OneID']);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // selectedRowKeys = newSelectedRowKeys

  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    defaultSelectedRowKeys: selectedRowKeys
  };

  const [resetConfirm, setResetConfirm] = useState(false);
  const [batchResetConfirm, setBatchResetConfirm] = useState(false);

  const columns: TableProps<any>["columns"] = [
    {
      title: "序号",
      dataIndex: "",
      key: "",
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: "字段名称",
      dataIndex: "name",
      key: "name",
    },
  ];

  // 查询参数
  const [idOrName, setIdOrName] = useState("");

  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "createTime",
    isAsc: "desc",
  });

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'createTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }

    getAccountListFunction();
  }
  // 获取下载用户明细列表
  const getAccountListFunction = async () => {
    const res = await getExportNameList();
    if (res) {
      var arr = [] as any
      res.data.map((item: any, index: any) => {
        arr.push(
          {
            key: index,
            ids: index + 1,
            name: item,
          }
        )
      })
      setListData(arr);
    }
  };

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
    setBatchResetConfirm(false);
  };
  const getEncryptTypeList = async(type:number) => {
    const res = await getEncryptTypeListApi();
    if (res?.code === 200) {
      const encryptType = res.data.find((item:any)=>item.encryptName==='MD5').encryptType
      toDownLoad(type,encryptType)
    }
  };
  // 下载  0明文下载，脱敏1
  const toDownLoad = async(type:number,encryptType?:number) => {
    if (selectedRowKeys.length < 1) {
      message.error('至少选择一个字段进行下载')
      return
    }
    var taskLocation = '分群/自定义分群/客户列表'
    var moduleName = '分群-自定义分群'
    let param = {
      crowdId: props.crowdId,
      ...props.searcParam,
      type: type,
      taskLocation: taskLocation, //页面位置
      moduleName: moduleName, //模块名称
      fieldNameList:selectedRowKeys
    } as any;
    if(encryptType){
      param = {...param,encryptType}
    }
    const res = await getUploadTask(param);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    }
  };
  useEffect(() => {
    setOpen(openDownloadPop);
    if (openDownloadPop) {
      getAccountListFunction();
    } else {
      setIdOrName('');
    }
  }, [openDownloadPop]);

  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
      document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title="下载客户明细"
        placement={placement}
        width={960}
        onClose={onClose}
        open={open}
      >
        <div>
          <div className={styles.searchBox}>
            <Button
              onClick={() => {
                toDownLoad(0)
              }}
            >
              明文下载
            </Button>
            <Popconfirm  title="是否需要MD5加密？"  onConfirm={() =>getEncryptTypeList(1) } onCancel={() => toDownLoad(1)} okText="是" cancelText="否">
              <Button
                style={{
                  marginLeft: '10px'
                }}
                type="primary"
              >
                脱敏下载
              </Button>
            </Popconfirm>
          </div>
          {listData.length > 0 &&
            <Table
            style={{ width: "100%", marginTop: "24px" }}
            rowSelection={
              {
                ...rowSelection,
                defaultSelectedRowKeys: ['OneID']
              }
            }
            rowKey={(record) => record.name}
            columns={columns}
            dataSource={listData}
            scroll={{ y: 580 }}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              current: pageQuery.pageNum,
              showSizeChanger: false,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
          }
        </div>
      </Drawer>
    </div>
  );
};

export default DownloadUserDeatil;
