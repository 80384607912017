import React from 'react'
import {AppRoute, AppRouter} from "@ice/stark";

const marketRoot = process.env.REACT_APP_MARKET_ROOT;

console.log('marketRoot', marketRoot)

const marketUrl = [
    `${marketRoot}/js/index.js`,
    `${marketRoot}/css/index.css`,
]
const Index = () => {
    return (
        <AppRouter>
            <AppRoute
                activePath="/market"
                url={marketUrl}
            />
        </AppRouter>
    );
}

export default Index;
