import React from 'react';
import styles from './index.module.scss';

const MiniLabel = (props: {
  text: string,
  labelStyle: string
}) => {
  // labelStyle:
  // disable 禁用样式
  // enable 启用样式
  // addData 追加数据样式
  // completionData 补齐数据样式
  // lock 锁定样式

  const { text, labelStyle } = props;

  return (
    <div>
      <div className={`${styles.commonStyle} ${styles[labelStyle]}`}>{text}</div>
    </div>
  );
};

export default MiniLabel