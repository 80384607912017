import React, { useEffect, useState } from "react";
import { Button, Divider, message, Tooltip, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  EditOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import RuleGroupsSetting from "../RuleGroupsSetting";
import RelationshipDivider from "../RelationshipDivider";
import {
  checkLabelValueUsedApi,
  profileLabelProduceApi,
} from "../../../../../../api/label/label";
import styles from "./index.module.scss";

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SortableList = ({
  current,
  labelId,
  operateType,
  paramsList,
  parentLabelId,
  onParam,
  onSort,
}: any) => {
  const [listStore, setListStore] = useState(paramsList);

  const [loading, setLoading] = useState(true);

  // 拖拽事件
  const handleDragEnd = (result: any) => {
    // 如果没有拖动任何item，则不做处理
    if (!result.destination) {
      return;
    }

    const items = reorder(
      listStore,
      result.source.index,
      result.destination.index
    );

    setListStore(listStore);

    onSort(items);
  };

  // 鼠标移入事件
  const toggleHover = (id: any, f: string) => {
    if (operateType !== "view") {
      const store = paramsList.map((val: any, idx: number) => val);
      store.forEach((v: any, i: number) => {
        if (v.operate_label_id === id) {
          v.action_bar_show = f === "enter" ? true : false;
        }
      });
      onParam(store);
    }
  };

  // 标签展开收起
  const isLabelOpen = (id: string) => {
    const store = paramsList.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === id) {
        v.label_open = !v.label_open;
      }
    });
    onParam(store);
  };

  // 校验标签是否可操作
  const checkLabelValueUsed = async (id: string, item: any, k: string) => {
    if(loading){
      setLoading(false);
      const params = {
        labelValueId: id,
      };
      let f: number = 0;
      const res = await checkLabelValueUsedApi(params);
      if (res.data && res.data.isUsed === 1) {
        message.warning("该标签值已被应用，不可进行修改操作");
      } else {
        switch (k) {
          case "add":
            addCommon(id);
            break;
          case "delete":
            const store = JSON.parse(JSON.stringify(paramsList));
            if (store.length === 1) {
              message.warning("最少保留一个标签值");
            } else {
              store.forEach((val: any, idx: number) => {
                if (val.operate_label_id === id) {
                  store.splice(idx, 1);
                }
              });
              onParam(store);
            }
            break;
          default:
            const store2 = listStore.map((val: any, idx: number) => val);
            store2.forEach((v: any, i: number) => {
              if( v.operate_label_id === id){
                v.is_copy = true;
              }
            });
            onParam(store2);
            break;
        }
      };
      setLoading(true);
    } else {
      message.warning('标签删除中，请勿频繁操作');
    };
  };

  const hasDuplicates = (data: string[]) => {
    return new Set(data).size !== data.length;
  };

  const addCommon = (id: string) => {
    const store = JSON.parse(JSON.stringify(paramsList));
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === id) {
        const rules_value_store: string[] = [];
        v.rules.rules.forEach((m: any, n: number) => {
          rules_value_store.push(m.rules_value);
        });

        if(!hasDuplicates(rules_value_store)){
          const obj = {
            rules_value: "规则组",
            action_bar_show: false,
            rules_value_id: String(new Date().valueOf()) + "03",
            combinator: "and",
            data_sources: "create",
            is_copy: true,
            rules: [
              {
                rules_Label_value: null,
                rules_operate_id: String(new Date().valueOf()) + "04",
                table_name: "",
                field_type: "",
                label_id: null,
                field: null,
                field_id: null,
                field_code: "",
                min: "",
                max: "",
                time: null,
                time_value: null,
                time_key: '1',
                is_custom: false,
                custom_value: null,
                custom_checked: null,
                timeStore: "",
                operator: null,
                operator_id: null,
                behavior: "做过",
                value: "",
                value_select: null,
                value_time: null,
                month_open: false,
                statistics_type: "总次数", // 统计方式
                behavior_field_id: null,
                behavior_field_value: "",
                behavior_time_open: false
              },
            ],
          };
          v.rules.rules.unshift(obj);
        }
      }
    });
    onParam(store);
  };

  // 添加规则组
  const addruleGroup = (id: string) => {
    if (operateType === "edit") {
      checkLabelValueUsed(id, {}, "add");
    } else if (operateType === "create") {
      addCommon(id);
    }
  };

  // 删除标签组
  const deleteLabelItem = (id: any) => {
    // if (operateType === "edit") {
    //   checkLabelValueUsed(id, {}, "delete");
    // } else if (operateType === "create") {
    //   const store = JSON.parse(JSON.stringify(paramsList));
    //   if (store.length === 1) {
    //     message.warning("最少保留一个标签值");
    //   } else {
    //     store.forEach((val: any, idx: number) => {
    //       if (val.operate_label_id === id) {
    //         store.splice(idx, 1);
    //       }
    //     });
    //     onParam(store);
    //   }
    // }
    const store = paramsList.map((val: any, idx: number) => val);
    if (store.length === 1) {
      message.warning("最少保留一个标签值");
    } else {
      store.forEach((val: any, idx: number) => {
        if (val.operate_label_id === id) {
          store.splice(idx, 1);
        }
      });
      onParam(store);
    }
  };

  // 获取规则参数
  const getRuleData = (val: any) => {
    onParam(val);
  };

  // 获取且和或的change值
  const getChangeValue = (val: any) => {
    const store = listStore.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === val.id) {
        v.rules.combinator = val.v;
      }
    });
    onParam(store);
  };

  // 修改标签名字
  const changeName = (item: any) => {
    if (operateType === "edit") {
      checkLabelValueUsed(item.id, item, "changeName");
    } else if (operateType === "create") {
      const store = listStore.map((val: any, idx: number) => val);
      store.forEach((v: any, i: number) => {
        if(item.operate_label_id === v.operate_label_id){
          v.is_copy = true;
        }
      });
      onParam(store);
    }
  };

  const labelNameChange = (e: string, item: any) => {
    const store = listStore.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if(item.operate_label_id === v.operate_label_id){
        v.label_value = e;
      }
    });
    onParam(store);
  };

  const handleBlur = (e: any, item: any) => {
    if(item.label_value === ''){
      e.target.focus();
      return message.warning("标签值名称不允许为空");
    }
    const store = listStore.map((val: any, idx: number) => val);
    let f = false;
    for(let i = 0; i < store.length; i++){
      if(store[i].label_value === item.label_value && store[i].operate_label_id !== item.operate_label_id){
        message.warning('标签值名称不允许重复');
        f = true;
        break;
      }
    }
    if(f){
      e.target.focus();
    }else{
      store.forEach((v: any, i: number) => {
        if(item.operate_label_id === v.operate_label_id){
          if(item.label_value.length < 21){
            v.is_copy = false;
          } else {
            e.target.focus();
            message.warning("标签值名称最多20个字符");
          }
        }
      });
      onParam(store);
    }
  };

  const labelNameBlur = (item: any) => {
    if(item.label_value === ''){
      return message.warning("标签值名称不允许为空");
    }
    const store = listStore.map((val: any, idx: number) => val);
    let f = true;
    for(let i = 0; i < store.length; i++){
      if(store[i].label_value === item.label_value && store[i].operate_label_id !== item.operate_label_id){
        f = false;
        message.warning('标签值名称不允许重复');
        break;
      }
    }
    if(f){
      store.forEach((v: any, i: number) => {
        if(item.operate_label_id === v.operate_label_id){
          if(item.label_value.length < 21){
            v.is_copy = false;
          } else {
            message.warning("标签值名称最多20个字符");
          }
        }
      });
    }
    onParam(store);
  };

  // 复制标签组
  const copyItem = (item: any) => {
    const store = listStore.map((val: any, idx: number) => val);
    const nameArr = [];
    const itemStore = JSON.parse(JSON.stringify(item));
    itemStore.label_value_id = String(new Date().valueOf()) + "02";  // 标签id
    itemStore.id = String(new Date().valueOf()) + "02";  // 标签id
    itemStore.operate_label_id = String(new Date().valueOf()) + "02";  // 标签id
    // 处理名字展示情况
    store.forEach((v: any, i: number) => {
      if(v.label_value.length > 20){
        v.is_copy = true;
      }
      if (v.label_value.indexOf(itemStore.label_value + "副本") !== -1) {
        nameArr.push(v);
      }
    });
    itemStore.label_value =
      nameArr.length === 0
        ? itemStore.label_value + "副本"
        : itemStore.label_value + "副本" + "（" + String(nameArr.length) + "）";
    if(itemStore.label_value.length > 20){
      itemStore.is_copy = true;
    } 
    itemStore.data_sources = "copy";
    itemStore.rules.rules.forEach((v: any, i: number) => {
      v.rules_value_id = String(new Date().valueOf()) + i + "03";
      v.rules.forEach((m: any, n: number) => {
        m.rules_operate_id =
        String(new Date().valueOf()) + n + "04";
        if(m.field_type === 'Date' || m.field_type === 'Year'){
          if(m.operator_id === 7){
            m["value_time"] = [
              dayjs(m.value[0], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
              dayjs(m.value[1], m.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
            ];
          } else {
            m.value_time = m.field_type === "Date" ? dayjs(m.value, "YYYY-MM-DD") : dayjs(m.value, "YYYY");
          }
        }
        if (m.rules) {
          m.rules.rules.forEach((p: any, q: number) => {
            p.child_id = String(new Date().valueOf()) + q + "05";
            if(p.field_type === 'Date' || p.field_type === 'Year'){
              if(p.operator_id === 7){
                p["value_time"] = [
                  dayjs(p.value[0], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                  dayjs(p.value[1], p.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"),
                ];
              } else {
                p.value_time = p.field_type === "Date" ? dayjs(p.value, "YYYY-MM-DD") : dayjs(p.value, "YYYY");
              }
            }
          });
        }
      });
    });
    store.unshift(itemStore);
    onParam(store);
  };

  // 估算
  const estimate = async (item: any) => {
    const store: any = [];
    store.push(item);

    let f: boolean = true;

    checkFillIn: for (var i = 0; i < store.length; i++) {
      for (var y = 0; y < store[i].rules.rules.length; y++) {
        for (var z = 0; z < store[i].rules.rules[y].rules.length; z++) {
          if (!store[i].rules.rules[y].rules[z].rules_Label_value) {
            message.warning("请设置标签规则");
            f = false;
            break checkFillIn;
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "1") {
            if (store[i].rules.rules[y].rules[z].label_id === null) {
              message.warning("请设置标签规则");
              f = false;
              break checkFillIn;
            }
            if (store[i].rules.rules[y].rules[z].operator_id === null) {
              f = false;
              message.warning("请设置标签规则");
              break checkFillIn;
            }
            if (
              store[i].rules.rules[y].rules[z].value === null ||
              store[i].rules.rules[y].rules[z].value === ""
            ) {
              f = false;
              message.warning("请设置标签规则");
              break checkFillIn;
            }
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "2") {
            if (store[i].rules.rules[y].rules[z].field_id === null) {
              f = false;
              message.warning("请设置标签规则");
              break checkFillIn;
            }
            if(store[i].rules.rules[y].rules[z].operator_id !== 5 && 
              store[i].rules.rules[y].rules[z].operator_id !== 6 && 
              store[i].rules.rules[y].rules[z].operator_id !== 7
            ){
              if (
                store[i].rules.rules[y].rules[z].value === null ||
                store[i].rules.rules[y].rules[z].value === ""
              ) {
                f = false;
                message.warning("请设置标签规则");
                break checkFillIn;
              }
            }
            if(store[i].rules.rules[y].rules[z].operator_id === 7){
              if(store[i].rules.rules[y].rules[z].field_type === 'Date' || 
                store[i].rules.rules[y].rules[z].field_type === 'Year' || 
                store[i].rules.rules[y].rules[z].field_type === 'Month'
              ){
                if(store[i].rules.rules[y].rules[z].value === '' ||
                store[i].rules.rules[y].rules[z].value === null || 
                store[i].rules.rules[y].rules[z].value.length === 0
                ){
                  f = false;
                  message.warning("请设置标签规则");
                  break checkFillIn;
                }
              } else {
                if(
                  store[i].rules.rules[y].rules[z].max === '' || 
                  store[i].rules.rules[y].rules[z].max === null ||
                  store[i].rules.rules[y].rules[z].min === '' ||
                  store[i].rules.rules[y].rules[z].min === null
                ){
                  f = false;
                  message.warning("请设置标签规则");
                  break checkFillIn;
                } else {
                  if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                    f = false;
                    message.warning("属性值区间请按照从小到大的顺序填写");
                    break checkFillIn;
                  }
                }
              }
            }
          }
          if (store[i].rules.rules[y].rules[z].rules_Label_value === "3") {
            if (store[i].rules.rules[y].rules[z].behavior_field_id === null) {
              f = false;
              message.warning("请设置标签规则");
              break checkFillIn;
            }
            if (
              store[i].rules.rules[y].rules[z].behavior === "做过" &&
              store[i].rules.rules[y].rules[z].statistics_type === "总次数"
            ) {
              if (store[i].rules.rules[y].rules[z].operator_id === null) {
                f = false;
                message.warning("请设置标签规则");
                break checkFillIn;
              }
              if (
                store[i].rules.rules[y].rules[z].value === null ||
                store[i].rules.rules[y].rules[z].value === ""
              ) {
                f = false;
                message.warning("请设置标签规则");
                break checkFillIn;
              }
              if (store[i].rules.rules[y].rules[z].operator_id === 7) {
                // 区间
                if (
                  store[i].rules.rules[y].rules[z].max === "" ||
                  store[i].rules.rules[y].rules[z].min === ""
                ) {
                  f = false;
                  message.warning("请设置标签规则");
                  break checkFillIn;
                } else {
                  if(Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)){
                    f = false;
                    message.warning("行为次数区间请按照从小到大的顺序填写");
                    break checkFillIn;
                  }
                }
              }
            }
            if (store[i].rules.rules[y].rules[z].rules) {
              for (
                var s = 0;
                s < store[i].rules.rules[y].rules[z].rules.rules.length;
                s++
              ) {
                if (
                  store[i].rules.rules[y].rules[z].rules.rules[s].field_id ===
                  null
                ) {
                  f = false;
                  message.warning("请设置标签规则");
                  break checkFillIn;
                }
                if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 5 && 
                  store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 6 &&
                  store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 7 
                ){
                  if (
                    store[i].rules.rules[y].rules[z].rules.rules[s].value ===
                      null ||
                    store[i].rules.rules[y].rules[z].rules.rules[s].value === ""
                  ) {
                    f = false;
                    message.warning("请设置标签规则");
                    break checkFillIn;
                  }
                }
                if(store[i].rules.rules[y].rules[z].rules.rules[s].operator_id === 7){
                  if(store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Date' || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Year' || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].field_type === 'Month'
                  ){
                    if(store[i].rules.rules[y].rules[z].rules.rules[s].value === '' ||
                      store[i].rules.rules[y].rules[z].rules.rules[s].value === null || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].value.length === 0
                    ){
                      f = false;
                      message.warning("请设置标签规则");
                      break checkFillIn;
                    }
                  } else {
                    if(
                      store[i].rules.rules[y].rules[z].rules.rules[s].max === '' || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].min === '' ||
                      store[i].rules.rules[y].rules[z].rules.rules[s].max === null || 
                      store[i].rules.rules[y].rules[z].rules.rules[s].min === null
                    ){
                      f = false;
                      message.warning("请设置标签规则");
                      break checkFillIn;
                    } else {
                      if(Number(store[i].rules.rules[y].rules[z].rules.rules[s].max) < Number(store[i].rules.rules[y].rules[z].rules.rules[s].min)){
                        f = false;
                        message.warning("事件属性值区间请按照从小到大的顺序填写");
                        break checkFillIn;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const label_group_params: any = [];
    store.forEach((v: any, i: number) => {
      // 最外层
      const obj = {} as any;
      obj.label_value_id = v.label_value_id;
      obj.label_value = v.label_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E');
      obj.rules = {};
      obj.rules.combinator = v.rules.combinator;
      obj.rules.rules = [];
      v.rules.rules.forEach((p: any, q: number) => {
        // 规则组
        const obj_child: any = {
          rules_value: p.rules_value.replace(/</g, '\\u003C').replace(/>/g, '\\u003E'),
          combinator: p.combinator,
          rule: []
        };
        p.rules.forEach((m: any, n: number) => {
          // 规则item
          const obj_child_child: any = {
            rules_Label_value: m.rules_Label_value,
            table_name: m.table_name,
            field: m.field,
            field_type: m.field_type === 'Date' || m.field_type === 'Long' || m.field_type === 'Decimal' || m.field_type === 'Label' || m.field_type === 'Crowd' ? m.field_type : 'String',
            label_id: m.label_id,
            time: m.time,
            operator: m.operator,
            behavior: m.behavior,
            value: m.value,
            statistics_type: m.statistics_type,
          }
          if(m.rules){
            if(m.rules.rules.length > 0){
              obj_child_child.rules = {};
              obj_child_child.rules.combinator = m.rules.combinator;
              obj_child_child.rules.rules = [];
              m.rules.rules.forEach((x: any, y: number) => {
                const obj_child_child_child: any = {
                  table_name: x.table_name,
                  field_type: x.field_type === 'Date' || x.field_type === 'Long' || x.field_type === 'Decimal' || x.field_type === 'Label' || x.field_type === 'Crowd' ? x.field_type : 'String',
                  field: x.field,
                  func: x.func_value.length > 1 ? x.func_value[1] : '',
                  operator: x.operator,
                  value: x.value
                }
                obj_child_child.rules.rules.push(obj_child_child_child);
              })
            } else {
              delete m.rules;
            }
          }
          obj_child.rule.push(obj_child_child);
        })
        obj.rules.rules.push(obj_child);
      })
      label_group_params.push(obj);
    });

    console.log('参数🐟', label_group_params)

    if (f) {
      const obj = {
        label_id: labelId,
        label_group: label_group_params,
      };

      const params = {
        labelValueRule: JSON.stringify(obj),
      };
      const res = await profileLabelProduceApi(params);

      const listStoreStore = listStore.map((val: any, idx: number) => val);

      if (res) {
        listStoreStore.forEach((m: any, n: number) => {
          if (m.operate_label_id === item.operate_label_id) {
            m.people_num = res.data;
          }
        });
      } else {
        listStoreStore.forEach((m: any, n: number) => {
          if (m.operate_label_id === item.operate_label_id) {
            m.people_num = "--";
          }
        });
      }
      onParam(listStoreStore);
    }
  };

  // 数字千分位
  const formatNumber = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    setListStore(paramsList);
  }, [paramsList]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" isDropDisabled={operateType === "view"}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {listStore.map((item: any, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={operateType === "view"}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    key={item.operate_label_id}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <div className={styles.cardBox} key={item.operate_label_id}>
                      <div
                        className={styles.headerBox}
                        style={{
                          borderBottom: item.label_open
                            ? "var(--border3)"
                            : "unset",
                        }}
                        onMouseEnter={() =>
                          toggleHover(item.operate_label_id, "enter")
                        }
                        onMouseLeave={() =>
                          toggleHover(item.operate_label_id, "leave")
                        }
                      >
                        <div>
                        {
                          (!item.is_copy) ? <span
                            style={{
                              color:
                                operateType === "view"
                                  ? "var(--text-font-color10)"
                                  : "var(--text-font-color9)",
                            }}
                          >
                            {item.label_value}
                          </span> : <Input 
                            showCount 
                            maxLength={20}
                            className={styles.modifyName}
                            style={{color: operateType === "view" ? "var(--text-font-color10)" : "var(--text-font-color9)"}}
                            value={item.label_value}
                            autoFocus={item.is_copy}
                            onChange={(e) => labelNameChange(e.target.value, item)}
                            onBlur={(e) => handleBlur(e, item)}
                            onPressEnter={() => labelNameBlur(item)}
                          />
                        }
                       
                          {item.label_open ? (
                            <CaretDownOutlined
                              className={styles.openIcon}
                              onClick={() => {
                                isLabelOpen(item.operate_label_id);
                              }}
                            />
                          ) : (
                            <CaretUpOutlined
                              className={styles.openIcon}
                              onClick={() => {
                                isLabelOpen(item.operate_label_id);
                              }}
                            />
                          )}
                        </div>
                        {item.action_bar_show && (
                          <div>
                            <Tooltip title="编辑">
                              <EditOutlined
                                style={{
                                  marginRight: 9,
                                  cursor: "pointer",
                                  color: "var(--text-font-color4)",
                                  fontSize: "var(--text-font7)",
                                }}
                                onClick={() => changeName(item)}
                              />
                            </Tooltip>
                            <Tooltip title="复制">
                              <CopyOutlined
                                style={{
                                  marginRight: 9,
                                  cursor: "pointer",
                                  color: "var(--text-font-color4)",
                                  fontSize: "var(--text-font7)",
                                }}
                                onClick={() => copyItem(item)}
                              />
                            </Tooltip>
                            <Tooltip title="删除">
                              <DeleteOutlined
                                style={{
                                  cursor: "pointer",
                                  color: "var(--text-font-color4)",
                                  fontSize: "var(--text-font7)",
                                }}
                                onClick={() =>
                                  deleteLabelItem(item.operate_label_id)
                                }
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      {item.label_open && (
                        <div className={styles.bodyBox}>
                          {
                            operateType !== "view" && <div className={styles.addBox}>
                            <Button
                              icon={<PlusOutlined />}
                              disabled={operateType === "view"}
                              onClick={() =>
                                addruleGroup(item.operate_label_id)
                              }
                            >
                              添加规则组
                            </Button>
                            <div
                              style={{
                                lineHeight: 'var(--text-font15)',
                                fontSize: "var(--text-font5)",
                                color: "var(--text-font-color4)",
                                fontWeight: "var(--text-font-weight2)",
                              }}
                            >
                              预估人数：
                              <span
                                style={{
                                  color: "var(--text-font-color7)",
                                }}
                              >
                                {item.people_num === "--"
                                  ? "--"
                                  : formatNumber(Number(item.people_num))}
                              </span>
                              <span
                                style={{
                                  color: "var(--text-font-color3)",
                                  display: "inline-block",
                                  marginLeft: "5px",
                                }}
                              >
                                人
                              </span>
                              <span
                                  style={{
                                    color: "var(--text-font-color7)",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => estimate(item)}
                                >
                                  估算
                                </span>
                            </div>
                          </div>
                          }
                          <div
                            style={{
                              display:
                                item.rules?.rules?.length > 1
                                  ? "flex"
                                  : "block",
                              justifyContent: "space-between",
                            }}
                          >
                            {item.rules?.rules?.length > 1 && (
                              <RelationshipDivider
                                operateType={operateType}
                                size="32px"
                                level="2"
                                textFlag={item.rules.combinator}
                                dataParams={{
                                  id: item["operate_label_id"],
                                  flag: "p",
                                }}
                                getChangeValue={getChangeValue}
                              />
                            )}
                            <div
                              style={{
                                width:
                                  item.rules?.rules?.length > 1
                                    ? "calc(100% - 42px)"
                                    : "100%",
                              }}
                            >
                              {item.rules?.rules?.map(
                                (val: any, idx: number) => {
                                  return (
                                    <RuleGroupsSetting
                                      key={val.rules_value_id}
                                      current={current}
                                      labelId={labelId}
                                      operateType={operateType}
                                      flag="bq"
                                      listData={paramsList}
                                      parentLabelId={parentLabelId}
                                      itemData={item}
                                      ruleData={val}
                                      getRuleData={getRuleData}
                                    />
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

// // 这个样式函数用于在拖动项时提供反馈
const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // 其他样式保持不变
  ...draggableStyle,
  ...(isDragging && {
    color: "white",
  }),
});

export default SortableList;
