import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import style from './index.module.scss';
import React from 'react';
import { DatePicker, DatePickerProps, Empty, Select, SelectProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Histogram from "./components/Histogram"
import { getLabelOverviewApi } from '../../../../../../api/label/label'

const { RangePicker } = DatePicker;
let cycleDays = [7, 31, 0];

let ChartData = React.forwardRef((props: {
    labelId: string | number,
    // renewTime: string,
    calculateStatus?: string | number, //计算状态 0: 未计算 1: 计算中 2: 成功 3: 失败
}, ref: any) => {

    // 图表数据+图表日期范围
    let [listData, setListData] = useState([] as any)

    // 选择显示的周期
    const selectCycle: SelectProps['options'] = [
        {
            label: '按周',
            value: 0,
            queryType: 'week'
        },
        {
            label: '按月',
            value: 1,
            queryType: 'month'
        },
        {
            label: '按年',
            value: 2,
            queryType: 'year'
        },
    ]
    // 选择显示的图表
    const selectChart: SelectProps['options'] = [
        {
            label: '柱状图',
            value: 0
        },
        {
            label: '折线图',
            value: 1
        },
    ]

    // 选中周期
    const [selectedCycle, setSelectedCycle] = useState(0)
    // 选中的图表类型
    const [selectedChart, setSelectedChart] = useState(0)
    // 下拉选择周期
    const changeSelectedCycle = (e: any) => {
        checkDate(e)
        if (e !== 2) {
            setListQuery({
                ...listQuery,
                queryType: selectCycle[e].queryType,
                startTime: [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM-DD'),
                // startTime: dayjs().add(-cycleDays[selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0 ? [dayjs(props.creatTime), dayjs()][0].format('YYYY-MM-DD') : [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM-DD'),
                endTime: dayjs().format('YYYY-MM-DD')
            })
        } else {
            setListQuery({
                ...listQuery,
                queryType: selectCycle[e].queryType,
                startTime: `${dayjs().format('YYYY')}-01-01`,
                // startTime: [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM'),
                // startTime: dayjs().add(-cycleDays[selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0 ? [dayjs(props.creatTime), dayjs()][0].format('YYYY-MM') : [dayjs().add(-cycleDays[e], 'days'), dayjs()][0].format('YYYY-MM'),
                // endTime: dayjs().format('YYYY-MM')
                endTime: `${dayjs().format('YYYY')}-12-31`
            })
        }
        setSelectedCycle(e)
    }
    // 下拉选择图表类型
    const changeSelectedChart = (e: any) => {
        setSelectedChart(e)
    }

    const [dates, setDates] = useState([dayjs().add(-7, 'days'), dayjs()] as any);
    const [dateValue, setDateValue] = useState([dayjs().add(-7, 'days'), dayjs()] as any);
    // 禁止选择的日期
    const disabledDate = (current: any) => {
        if (!dates) {
            return false;
        }
        let tooLate = null
        let tooEarly = null
        tooLate = dates[0] && ((current.diff(dates[0], 'days') > cycleDays[selectedCycle]) || (dates[0].diff(current, 'days') > cycleDays[selectedCycle]));
        tooEarly = dates[1] && ((dates[1].diff(current, 'days') > cycleDays[selectedCycle]) || (current.diff(dates[1], 'days') > cycleDays[selectedCycle]));
// || dayjs(current.format('YYYY-MM-DD'), 'YYYY-MM-DD').valueOf() < dayjs(props.creatTime, 'YYYY-MM-DD').valueOf()
        return current.valueOf() >= dayjs().valueOf() || !!tooEarly || !!tooLate ;
    };
    const onOpenChange = (open: boolean) => {
        if (open) {
            setDates([null, null]);
        } else {
            setDates(null);
        }
    };
    // 改变时间时重新给查询条件/日期赋值
    const onChangeDate = (val: any, string: any) => {
        if(val){
            setDateValue(val)
            setListQuery({
                ...listQuery,
                startTime: selectedCycle > 1 ? `${val.$y}-01-01` : val[0].format('YYYY-MM-DD'),
                endTime: selectedCycle > 1 ? `${val.$y}-12-31` : val[1].format('YYYY-MM-DD')
                // startTime: selectedCycle > 1 ? val[0].format('YYYY-MM') : val[0].format('YYYY-MM-DD'),
                // endTime: selectedCycle > 1 ? val[1].format('YYYY-MM') : val[1].format('YYYY-MM-DD')
            })
        }
    }
    // 查询条件
    const [listQuery, setListQuery] = useState({
        labelId: props.labelId,
        queryType: selectCycle[selectedCycle].queryType,
        startTime: selectedCycle > 1 ? `${dateValue.$y}-01-01` : dateValue[0].format('YYYY-MM-DD'),
        endTime:  selectedCycle > 1 ? `${dateValue.$y}-12-31` : dateValue[1].format('YYYY-MM-DD'),
        // startTime: dayjs().add(-cycleDays[selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0 ? [dayjs(props.creatTime), dayjs()][0].format('YYYY-MM-DD') : dateValue[0].format('YYYY-MM-DD'),
        // endTime: dayjs().add(-cycleDays[selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0 ? [dayjs(props.creatTime), dayjs()][1].format('YYYY-MM-DD') : dateValue[1].format('YYYY-MM-DD'),
    })

    const [listStatus, setListStatus] = useState(false)
    const pushListData = async () => {
        if (listStatus) return
        setListStatus(true)
        let param = {
            ...listQuery,
            labelId: props.labelId
        }
        const res = await getLabelOverviewApi(param)
        if (res) {
            res.data.map((item: any) => {
                item.opTime = item.opTime.replace(/\-/g, '.')
            })
            setListData(res.data ?? [])
        } else {
            setListData([])
        }
        setListStatus(false)
    }

    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({
        pushListData
    }));

    const checkDate = (e?: any) => {
        // if (dayjs().add(-cycleDays[e ?? selectedCycle], 'days').diff(dayjs(props.creatTime), 'days') < 0) {
        //     setDates([dayjs(props.creatTime), dayjs()])
        //     setDateValue([dayjs(props.creatTime), dayjs()])
        // } else {
            setDates([dayjs().add(-cycleDays[e ?? selectedCycle], 'days'), dayjs()])
            setDateValue([dayjs().add(-cycleDays[e ?? selectedCycle], 'days'), dayjs()])
        // }
    }

    // 监听或进入调用
    useEffect(() => {
        pushListData()
    }, [listQuery]);

    return (
        <div className={style['container']}>
            <div className={style['container_title']}>
                <div className={style['container_title_query']}>
                    <Select defaultValue={selectedCycle} options={selectCycle} style={{ width: 160 }} onChange={changeSelectedCycle}></Select>
                    {selectedCycle != 2 &&<RangePicker
                        placeholder={['开始时间', '结束时间']}
                        format="YYYY-MM-DD"
                        value={dates || dateValue}
                        allowClear={false}
                        disabledDate={disabledDate}
                        style={{ margin: '0 10px' }}
                        onCalendarChange={val => setDates(val)}
                        onChange={onChangeDate}
                        onOpenChange={onOpenChange}
                    />}
                    {selectedCycle == 2 &&
                        <DatePicker 
                            format="YYYY"
                            value={dates || dateValue}
                            allowClear={false}
                            disabledDate={disabledDate}
                            style={{ margin: '0 10px' }}
                            onCalendarChange={val => setDates(val)}
                            onChange={onChangeDate}
                            onOpenChange={onOpenChange}
                            picker="year" />}
                    <Select defaultValue={selectedChart} options={selectChart} style={{ width: 160 }} onChange={changeSelectedChart}></Select>
                </div>
                {/* <div>数据更新时间：{props.renewTime}</div> */}
            </div>
            {/* 图表 */}
            {(!listData || listData.length < 1 || Number(props.calculateStatus) !== 2) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{marginTop:'80px'}}/>}
            {(listData.length > 0 && Number(props.calculateStatus) === 2) && <Histogram selectedChart={selectedChart} listData={listData} />}
        </div>
    );
});

export default ChartData;