import {AxiosPromise} from 'axios';
import request from '@/api/network'
// import * as T from './types';
// 用于处理 query 入参
const formatUrl = (url: string, params: { [propsName: string]: any }) => {
  if (!params) {
    return url;
  }
  if (Object.keys(params).length === 0) {
    return url;
  }
  return url + '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
}

// 数据集分类列表（包含分类和数据集）
export function getDatasetCategoryList(params?: { search?: string }): AxiosPromise<any> {
  return request({
    url: formatUrl('/bi/dataset/list', params ?? {}),
    method: 'get',
  });
}

// 数据集新增编辑
export function putDataset(params: { id?: number, dsName: string, dsTypeId: string, remark?: string }): AxiosPromise<any> {
  return request({
    url: '/bi/dataset',
    method: 'put',
    data: params,
  });
}

// 删除数据集
export function deleteDataset(params: { id: number }): AxiosPromise<any> {
  return request({
    url: `/bi/dataset/${params.id}`,
    method: 'delete',
  });
}

// 数据集启用禁用
export function updateDatasetStatus(params: { id: number, disableFlag: string }): AxiosPromise<any> {
  return request({
    url: '/bi/dataset/status',
    method: 'post',
    data: params,
  });
}

// 配置数据源
export function postDatasetConfig(params: { dsId: number, dimensionIds: number[], indicatorIds: number[], indicatorCodes: string[] }): AxiosPromise<any> {
  return request({
    url: '/bi/dataset/dsSource',
    method: 'post',
    data: params,
  });
}

// 查看数据集数据
export function getDatasetConfigData(params: { id: number }): AxiosPromise<any> {
  return request({
    url: `/bi/dataset/${params.id}`,
    method: 'get',
  });
}

// 查看数据集视图数据
export function getDatasetViewData(params: { id: number }): AxiosPromise<any> {
  return request({
    url: `/bi/dataset/view/${params.id}`,
    method: 'get',
  });
}

// 获取字典数据
export function getDictData(dictType: string): AxiosPromise<any> {
  return request({
    url: `/system/dict/data/type/${dictType}`,
    method: 'get',
  });
}

// 分类下拉列表
export function getDatasetCategorySelectList(): AxiosPromise<any> {
  return request({
    url: '/bi/dataset/categories',
    method: 'get',
  });
}

// 查看维度列表
export function getDimensionList(params: { search: string, typeIds: string[] | null, dimensionName: string }): AxiosPromise<any> {
  return request({
    url: '/bi/dataset/dimensions',
    method: 'post',
    data: params,
  });
}

// 根据维度ID集合查询指标列表
export function getIndicatorList(params: { dimensionIds: number[], search: string, indicatorCategoryIds: string[] | null, name: string }): AxiosPromise<any> {
  return request({
    url: '/bi/dataset/indicators',
    method: 'post',
    data: params,
  });
}

// 数据集分类新增编辑
export function putDatasetCategory(params: { id?: number, categoryName: string }): AxiosPromise<any> {
  return request({
    url: '/bi/dataset/category',
    method: 'put',
    data: params,
  });
}

// 删除数据集分类
export function deleteDatasetCategory(params: { id: number }): AxiosPromise<any> {
  return request({
    url: `/bi/dataset/category/${params.id}`,
    method: 'delete',
  });
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getDatasetCategoryList,
  putDataset,
  deleteDataset,
  updateDatasetStatus,
  postDatasetConfig,
  getDatasetConfigData,
  getDatasetViewData,
  getDictData,
  getDatasetCategorySelectList,
  getDimensionList,
  getIndicatorList,
  putDatasetCategory,
  deleteDatasetCategory,
}