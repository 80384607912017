import { AxiosPromise } from 'axios';
import request from '../network'

// //获取部门列表
// export function checkHasRoleApi(): AxiosPromise<boolean> {
//     return request({
//         url: `/system/user/add/checkRole`,
//         method: 'POST',
//         data: {}
//     });
// }

//获取系统日志
export function getSystemLogList(query: any): AxiosPromise<any[]> {
  return request({
      url: `/system/operlog/list`,
      method: 'GET',
      params: query,
  })
}
//查询功能模块树的接口 
export function getReactRouters(): AxiosPromise<any[]> {
  return request({
      url: `/system/menu/getReactRouters`,
      method: 'GET',
  })
}
//查询功能模块树的接口 新
export function getLogReactRouters(): AxiosPromise<any[]> {
  return request({
      url: `/system/menu/getLogReactRouters`,
      method: 'GET',
  })
}
//操作类型下拉选列表
export function getOperType(): AxiosPromise<any[]> {
  return request({
      url: `/system/dict/data/type/sys_oper_type`,
      method: 'GET',
  })
}





export default {}