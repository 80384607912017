import {AxiosPromise} from 'axios';
import request from '@/api/network'
import * as T from './types';
// 用于处理 query 入参
const formatUrl = (url: string, params: { [propsName: string]: any }) => {
  if (!params) {
    return url;
  }
  if (Object.keys(params).length === 0) {
    return url;
  }
  return url + '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
}

// 维度管理-查询列表
export function getDimensionManagementList(data: T.IGetDimensionManagementList): AxiosPromise<any> {
  return request({
    url: formatUrl('/bi/dimension/list', data.query),
    method: 'post',
    data: data.params
  });
}

// 维度管理-删除
export function deleteDimensionManagementList(id: number): AxiosPromise<any> {
  return request({
    url: `/bi/dimension/${id}`,
    method: 'delete',
  });
}

// 维度管理-新建
export function addDimension(params: T.IAddDimension): AxiosPromise<any> {
  return request({
    url: '/bi/dimension',
    method: 'post',
    data: params
  });
}

// 维度管理-编辑
export function editDimension(params: T.IEditDimension): AxiosPromise<any> {
  return request({
    url: '/bi/dimension',
    method: 'put',
    data: params
  });
}

// 维度管理-查看
export function viewDimension(params: { id: string }): AxiosPromise<any> {
  return request({
    url: `/bi/dimension/${params.id}`,
    method: 'get',
  });
}

// 指标分类接口
/**
 *
 * @param params
 * 维度分类【dimension_type】
 * 指标分类【indicator_category】
 * 维度运算条件【dimension_operator】
 * 时间维度类型【dimension_date_type】
 * 维度类型  【dimension_source_field_type】
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getIndicatorCategory(params: {
  modelType: T.ModelType
}): AxiosPromise<any> {
  return request({
    url: formatUrl('/bi/indicator/category', params),
    method: 'get',
  });
}

// 数据源表下拉框
export function getTablesList(params: {
  layer: string,
}): AxiosPromise<any> {
  return request({
    url: '/bi/indicator/tables',
    method: 'get',
    params: params
  });
}

// 字段汇总下拉框
export function getFieldsList(params: { layer: string; tableName: string }): AxiosPromise<any> {
  return request({
    url: formatUrl('/bi/indicator/fields', params),
    method: 'get',
  });
}

// 指标管理-列表页
export function getIndicatorManagementList(data: T.IGetIndicatorManagementList): AxiosPromise<any> {
  return request({
    url: formatUrl('/bi/indicator/list', data.query),
    method: 'post',
    data: data.params
  });
}

// 指标管理-删除
export function deleteIndicator(params: { idList: Array<string>, indexType: '1' | '2' }): AxiosPromise<any> {
  return request({
    url: '/bi/indicator',
    method: 'delete',
    data: params
  });
}

// 指标管理-查看
export function viewIndicator(id: string): AxiosPromise<any> {
  return request({
    url: `/bi/indicator/${id}`,
    method: 'get',
  });
}

// 指标管理-新建
export function addIndicator(params: T.IAddIndicator): AxiosPromise<any> {
  return request({
    url: '/bi/indicator',
    method: 'post',
    data: params
  });
}

// 指标管理-编辑
export function editIndicator(params: T.IEditIndicator): AxiosPromise<any> {
  return request({
    url: '/bi/indicator',
    method: 'put',
    data: params
  });
}

// 衍生指标-原子指标下拉框
export function getAtomicIndexList(params: { classify: string }): AxiosPromise<any> {
  return request({
    url: formatUrl('/bi/indicator/derived-Indicator/category', params),
    method: 'get',
  });
}

// 衍生指标-覆盖维度下拉框
export function getDimensionList(id: string): AxiosPromise<any> {
  return request({
    url: `/bi/indicator/derived-Indicator/dimension/${id}`,
    method: 'get',
  });
}

// AI
// AI查询
export function getChatConversation(params: {
  question: string;
}): AxiosPromise<any> {
  return request({
    url: formatUrl("/chat/chat/conversation", params),
    method: "get",
  });
}

// 历史问题
export function getChatHistoryQuestionList(params: {
  pageNo: string;
  pageSize: string;
}): AxiosPromise<any> {
  return request({
    url: formatUrl("/chat/chat/question/list", params),
    method: "get",
  });
}

// 数据解释
export function getChatInterpretation(query: T.IInterpretation): AxiosPromise<any> {
  return request({
    url: "/chat/chat/interpretation",
    method: "post",
    data: query,
  });
}

// 报表列表
export function getAIReportList(params: {
  pageNo: string;
  pageSize: string;
}): AxiosPromise<any> {
  return request({
    url: formatUrl("/chat/chat/report/list", params),
    method: "get",
  });
}

// 创建报表
export function createAIChatReport(query: T.ICreateAIReport): AxiosPromise<any> {
  return request({
    url: "/chat/chat/report",
    method: "post",
    data: query,
  });
}


// 查询报表
export function getAIReportDetail(id: string): AxiosPromise<any> {
  return request({
    url: `/chat/chat/report/${id}`,
    method: "get",
  });
}

// 下载报表
export function downloadAIReport(id: any, query: { taskLocation: string, moduleName: string }): AxiosPromise<any> {
  return request({
    url: `/chat/chat/report/${id}/download`,
    method: "get",
    params: query,
  });
}

// 删除报表
export function deleteAiReport(id: string): AxiosPromise<{}> {
  return request({
    url: `/chat/chat/report/${id}`,
    method: 'delete',
    params: {},
  })
}

// 统计赞/踩
export function statisticAIFeedBack(query: T.IFeedback): AxiosPromise<any> {
  return request({
    url: "/chat/chat/conversation/feedback/statistic",
    method: "post",
    data: query,
  });
}


//统计的Top3
export function getAITop3(): AxiosPromise<any> {
  return request({
    url: "/chat/chat/conversation/feedback/statistic/top",
    method: "get",
  });
}


// 数据集下拉
export function getAIDataset(): AxiosPromise<any> {
  return request({
    url: "/chat/chat/dataset",
    method: "get",
  });
}

// 关闭对话
export function closeChatConversion(): AxiosPromise<any> {
  return request({
    url: "/chat/chat/conversation/close",
    method: "get",
  });
}

// 根据数据集查询结果
export function getAnswerByDataSet(id: any, query: { name: string }): AxiosPromise<any> {
  return request({
    url: `/chat/chat/dataset/${id}`,
    method: "get",
    params: query,
  });
}

const all = {
  getDimensionManagementList,
  deleteDimensionManagementList,
  addDimension,
  editDimension,
  viewDimension,
  getIndicatorCategory,
  getTablesList,
  getFieldsList,
  getIndicatorManagementList,
  deleteIndicator,
  viewIndicator,
  addIndicator,
  editIndicator,
  getAtomicIndexList,
  getDimensionList,
  getChatConversation,
  getChatHistoryQuestionList,
  getChatInterpretation,
  getAIReportList,
  createAIChatReport,
  getAIReportDetail,
  downloadAIReport,
  deleteAiReport,
  statisticAIFeedBack,
  getAIDataset,
  closeChatConversion,
  getAnswerByDataSet,
  getAITop3,
};
export default all;
