import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Row, Col, Tag, Drawer, Space, Card, Table, Popconfirm, message, Tooltip, Empty } from 'antd';
import type { TableColumnsType } from 'antd';
import { ProfileOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import ModuleTitle from '../../../../component/ModuleTitle';
import styles from './index.module.scss';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import FilterView from '../../../../component/FilterView/filterView';
import ChartData from '../component/ChatData/ChartData';
import { queryReportFormDetail, queryReportList, refreshReportApi } from '../../../../api/UserInsight/UserInsight';
import BackList from '../../../../component/BackList/backList';
import { GROUP_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import { spawn } from 'child_process';
const GroupUserInsightDetail = () => {
  const navigate = useNavigate();
  let location = useLocation();
  //人群包是否被删除
  const [reportData, setReportData] = useState<any>({});
  //目标人群对应图表数据
  const [reportChartData, setReportChartData] = useState([]);
  //对比人群对应图表数据
  const [reportChartCompareData, setReportChartCompareData] = useState([]);
  const searchParamInt: any = {
    // crowdTypes: 0,
    // crowdName: '',
    // createMode: '',
    // minCrowdNum: '',
    // maxCrowdNum: '',
  };
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const filterViewRef: any = useRef(null);
  const searchList: searchItem[] = [
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '类型',
      key: 'crowdTypes',
      options: [
        {
          value: 0,
          label: '目标人群',
        },
        {
          value: 1,
          label: '对比人群',
        },
      ],
    },
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入人群名称',
      title: '人群名称',
      key: 'crowdName',
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '创建方式',
      key: 'createMode',
      options: [
        {
          value: '1',
          label: '规则创建',
        },
        {
          value: '2',
          label: '营销创建',
        },
        {
          value: '3',
          label: 'RFM创建',
        },
        {
          value: '4',
          label: '导入创建',
        },
      ],
    },
    {
      searchType: 'text',
      searchComponentType: 'inputDouble',
      addonAfter: '',
      placeHolder: '请输入',
      title: '人群数量',
      key: 'minCrowdNum,maxCrowdNum',
    },
  ];
  const columns: TableColumnsType<any> = [
    {
      title: '人群名称',
      dataIndex: 'crowdName',
    },
    {
      title: '人群描述',
      dataIndex: 'crowdDescribe',
    },
    {
      title: '人群类型',
      dataIndex: 'crowdType',
      render: (_: any) => <div>{_ === 0 ? <span>目标人群</span> : _ === 1 ? <span>对比人群</span> : <span>目标人群/对比人群</span>}</div>,
    },
    {
      title: '创建方式',
      dataIndex: 'createMode',
      render: (_: any) => (
        // 1 规则创建、2营销创建、3RFM创建
        <div>{_ === '1' ? <span>规则创建</span> : _ === '2' ? <span>营销创建</span> : _ === '3' ? <span>RFM创建</span> : <span>导入创建</span>} </div>
      ),
    },
    {
      title: '人群数量',
      dataIndex: 'groupNum',
      render: (_: any) => (
        // 1 规则创建、2营销创建、3RFM创建
        <div>{Number(_).toLocaleString()}</div>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
    },
  ];

  //详情抽屉人群列表
  const [peopleList, setPeopleList] = useState([]);
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  // 抽屉开关
  const [open, setOpen] = useState(false);
  const [calculateStatus, setCalculateStatus] = useState(2);
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const filterFinish = (data: any) => {
    setSearchParam(data);
    getPeopleList({ pageSize: 10, pageNum: 1 }, data);
  };
  // 换页
  const handleTableChange = (page: any) => {
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize,
    });
    getPeopleList({ pageSize: page.pageSize, pageNum: page.current }, searchParam);
  };
  //获取人群信息
  const getPeopleList = async (page: { pageSize: number; pageNum: number }, data: any) => {
    console.log(333, data.crowdTypes);
    const res = await queryReportList({
      ...page,
      ...data,
      reportId: location.state.reportId,
      crowdTypes: data.crowdTypes == undefined ? [0, 1] : [data.crowdTypes],
    });
    if (res) {
      setPeopleList(res.rows);
      setPageQuery({
        ...pageQuery,
        total: res.total,
      });
    }
  };
  //刷新
  const refreshReport = async () => {
    const res = await refreshReportApi({
      ...reportData,
      crowdIds: reportData.userCrowds
        ? reportData.userCrowds.map((item: any) => {
            return item.crowdId;
          })
        : [],
      compareCrowdIds: reportData.compareUserCrowds?.map((item: any) => {
        return item.crowdId;
      }),
      labelIds: reportData.userLabels
        ? reportData.userLabels.map((item: any) => {
            return item.labelId;
          })
        : [],
    });
    if (res) {
      if (res.data.calculateStatus == 0) {
        //计算中，返回列表
        navigate('/userInsight/GroupUserInsight/GroupUserInsight');
        return;
      } else if (res.data.calculateStatus == 2) {
        message.warning('数据返回为空，请点击手动刷新重新计算');
      } else if (res.data.deletedCrowd) {
        message.warning('目标人群已删除，请重新选择人群包');
      } else {
        message.success('刷新成功');
        getReportData();
      }
    }
  };
  const reorganizeData = (data: any) => {
    // 使用reduce方法来创建一个新的对象，其中键是row的值，值是包含其他字段的数组
    const reorganized = data.reduce((accumulator: any, item: any) => {
      const { row, ...rest } = item; // 解构出row字段和其他字段

      if (!accumulator[row]) {
        // 如果当前row还没有对应的数组，则初始化一个空数组
        accumulator[row] = [];
      }

      // 将剩余字段组成的对象添加到对应row的数组中
      accumulator[row].push(rest);

      return accumulator;
    }, {});

    // 将重组后的对象转换为所需的数组格式
    const result = Object.entries(reorganized).map(([row, list]) => ({
      row,
      list,
    }));

    return result;
  };
  //获取报告数据
  const getReportData = async () => {
    const res = await queryReportFormDetail({
      reportId: location.state.reportId,
    });
    if (res) {
      setReportData(res.data);

      setCalculateStatus(res.data.calculateStatus);
      if (res.data.calculateStatus == 0) {
        //计算中，返回列表
        navigate('/userInsight/GroupUserInsight/GroupUserInsight');
        return;
      } else if (res.data.calculateStatus == 2) {
        message.warning('数据返回为空，请点击手动刷新重新计算');
      } else if (res.data.deletedCrowd) {
        message.warning('目标人群已删除，请重新选择人群包');
      }
      if (res.data.reportChats) {
        res.data.reportChats.forEach((item: any, index: any) => {
          item.data.forEach((subItem: any, subIndex: any) => {
            item.data[subIndex].value = subItem.customers;
            item.data[subIndex].name = subItem.valueName;
          });
          if (item.statsValue == '1') {
            res.data.reportChats[index].data = reorganizeData(item.data);
          }
        });
        //目标人群
        setReportChartData(res.data.reportChats.filter((item: any) => item.targetType === 0));
        //对比人群
        setReportChartCompareData(res.data.reportChats.filter((item: any) => item.targetType === 1));
      }
    }
  };
  useEffect(() => {
    getReportData();
  }, []);

  return (
    <div className={styles.groupUserInsight}>
      <div className={styles.titleAndOperate}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackList></BackList>
          <ModuleTitle mark="large" title="群体画像" />
        </div>
        {checkPermissions(GROUP_KEY.GROUP_UPDATE) && (
          <div className={styles.buttonBox}>
            <div style={{ fontSize: 'var(--text-font5)', marginRight: 16 }}>数据更新时间：{reportData.createTime}</div>
            <Button onClick={refreshReport} disabled={calculateStatus == 0}>
              刷新
            </Button>
            <span
              style={{
                width: '0',
                height: '16px',
                border: 'var(--border3)',
                margin: '0 16px',
              }}></span>
            <Button
              onClick={() =>
                navigate('/userInsight/GroupUserInsight/GroupUserInsight/form', {
                  state: { type: '2', reportId: location.state.reportId },
                })
              }>
              编辑
            </Button>
          </div>
        )}
      </div>
      {calculateStatus == 1 ? (
        <>
          <div className={styles.flexSB}>
            <ModuleTitle mark="middle" title="画像人群" />
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setOpen(true);
                filterViewRef.current?.resetData();
                getPeopleList({ pageSize: 10, pageNum: 1 }, searchParam);
              }}>
              <ProfileOutlined style={{ marginRight: '9px' }} />
              <span>详情</span>
            </div>
          </div>
          <div className={styles.groupBox}>
            {/* 目标人群 */}
            <div className={styles.flexBox}>
              {' '}
              <Tag style={{ marginRight: '16px' }} color="processing">
                目标人群
              </Tag>
              <Tooltip
                title={reportData.userCrowds
                  ?.map((item: any) => {
                    return item.crowdName;
                  })
                  .join('、')}>
                <div className={styles.peopleNamebox}>
                  {reportData.userCrowds
                    ?.map((item: any) => {
                      return item.crowdName;
                    })
                    .join('、')}
                </div>
              </Tooltip>
              <span>
                ，去重后：{Number(reportData.userNum).toLocaleString()}人，去重后占全部客户：{reportData.userRate}
              </span>
            </div>
            {/* 对比人群 */}
            {reportData.compareUserCrowds?.length > 0 && (
              <div className={styles.flexBox}>
                {' '}
                <Tag style={{ marginRight: '16px' }} color="purple">
                  对比人群
                </Tag>
                <Tooltip title={reportData.compareUserCrowds[0].crowdName + reportData.compareUserCrowds.length > 1 ? '等' : ''}>
                  <div className={styles.peopleNamebox}>
                    {reportData.compareUserCrowds
                      ?.map((item: any) => {
                        return item.crowdName;
                      })
                      .join('、')}
                  </div>
                </Tooltip>
                <span>
                  ，去重后：{Number(reportData.compareUserNum).toLocaleString()}人，去重后占全部客户：{reportData.compareUserRate}
                </span>
              </div>
            )}
          </div>
          <div className={styles.flexSB}>
            <ModuleTitle mark="middle" title="画像信息" />
            <Tooltip
              placement="left"
              title={
                <span>
                  定义：分群包中某标签值在该项目下的显著性特征结果
                  <br />
                  计算公式：（分群包中可识别标签值用户量/分群包用户总量）/（项目下可识别标签值用户/项目下用户总量）*100
                  <br />
                  判断标准：
                  <br />
                  1、{'>'}100：分群包具有相对倾向或者偏好，数值越大倾向和偏好越强；
                  <br />
                  2、{'<'}100：相关倾向较弱（和平均相比）
                  <br />
                  3、=100：平均水平
                </span>
              }>
              TGI
              <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)', marginLeft: 5 }} />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', justifyContent: reportChartData.length > 0 ? 'space-between' : 'center', alignItems: 'flex-start' }}>
            {reportChartData.length == 0 ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              <Row style={{ width: reportChartCompareData.length > 0 ? '50%' : '100%' }} gutter={24}>
                {reportChartData.map((item: any, index) => {
                  return (
                    <Col key={item.chartId} className={styles.chartBox} span={reportChartCompareData.length > 0 ? 24 : 12}>
                      <ChartData chartValue={item} operation={'view'} peopleType={'aim'} changeSort={() => {}} changeShape={() => {}} />
                    </Col>
                  );
                })}
              </Row>
            )}

            {reportChartCompareData.length > 0 && (
              <Row style={{ width: '50%' }}>
                {reportChartCompareData.map((item: any, index) => {
                  return (
                    <Col key={item.chartId} className={styles.chartBox} span={24}>
                      <ChartData chartValue={item} operation={'view'} peopleType={'compare'} changeSort={() => {}} changeShape={() => {}} />
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}

      {/* 人群抽屉 */}
      <Drawer
        className={styles.drawerStyle}
        title="查看人群"
        placement="right"
        width={960}
        onClose={() => {
          setOpen(false);
        }}
        open={open}>
        <div className={styles.buttonBox}>
          <SearchIconInput
            clear={true}
            width="310px"
            placeholder="请输入人群包名称"
            onSearch={(e: string) => {
              const store = {
                ...searchParam,
                crowdName: e,
              };
              setSearchParam(store);
              getPeopleList({ pageSize: 10, pageNum: 1 }, store);
            }}></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
        <FilterView ref={filterViewRef} searchList={searchList} finish={filterFinish}></FilterView>
        <Table
          columns={columns}
          dataSource={peopleList}
          pagination={{
            total: pageQuery.total,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={(page: any) => handleTableChange(page)}
        />
      </Drawer>
    </div>
  );
};

export default GroupUserInsightDetail;
