import React, { useState, useEffect } from "react";
import {
  Input,
  Select,
  DatePicker,
  InputNumber,
  message,
  Tooltip,
} from "antd";
import type { DatePickerProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from "dayjs";
import type { SelectProps } from "antd";
import {
  DeleteOutlined
} from "@ant-design/icons";
import MonthPickerComponent from "../MonthPickerComponent";
import MonthRangeComponent from "../MonthRangeComponent";
import {
  profilePropertyListApi,
  profileOperatorApi,
  profileEnumsListApi,
} from "../../../../../../api/label/label";
import { QueryLabelListVO } from "../../../../../../api/label/types";

const { RangePicker } = DatePicker;


const monthData = [
  {label: '1月', value: 1},
  {label: '2月', value: 2},
  {label: '3月', value: 3},
  {label: '4月', value: 4},
  {label: '5月', value: 5},
  {label: '6月', value: 6},
  {label: '7月', value: 7},
  {label: '8月', value: 8},
  {label: '9月', value: 9},
  {label: '10月', value: 10},
  {label: '11月', value: 11},
  {label: '12月', value: 12}
]

const Property = (props: {
  current: number,
  operateType: string,
  itemData: any,
  ruleData: any,
  val: any,
  idx: number,
  getCheckSizeFunction: Function,
  getDeleteItemFunction: Function,
  getRulesChangeFunction: Function
}) => {

  const {
    current,
    operateType, 
    itemData,
    ruleData, 
    val, 
    idx, 
    getCheckSizeFunction = () => {},
    getDeleteItemFunction = () => {},
    getRulesChangeFunction = () => {}
  } = props;

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [propertyOptions, setPropertyOptions] = useState([] as any);
  const [propertyRelationshipOptions, setPropertyRelationshipOptions] = useState([] as any);
  const [propertyValueOptions, setPropertyValueOptions] = useState([] as any);

  // 获取用户属性
  const getProfilePropertyList = async () => {
    const { data } = await profilePropertyListApi();
    data.forEach((v: any, i: number) => {
      v.label = v.fieldName;
      v.value = v.id;
    });
    setPropertyOptions(data);
  };

  // 获取运算符
  const getProfileOperator = async (e: any, f: string) => {
    const param = {
      fieldType: f === "o" ? propertyOptions.find(
        (v: any, i: number) => v.id === e
      ).fieldType : e,
    };
    const { data } = await profileOperatorApi(param);

    data.forEach((v: any, i: number) => {
      v.label = v.name;
      v.value = v.code;
    });

    setPropertyRelationshipOptions(data);

    if(f === "o"){
      // 默认值第一项
      getRulesChangeFunction({e: String(data[0].operator) + '-' + String(data[0].code), id: val.rules_operate_id, key: "operator_id", f: 'p'});
    }
  };

  // 获取枚举值
  const getProfileEnumsList = async (value: any) => {
    let param = {
      fieldType: value.fieldType,
      fieldCode: value.fieldCode,
    };

    const { data } = await profileEnumsListApi(param);
    if(data){
      data.forEach((v: any, i: number) => {
        v.label = v.enumName;
        // 会员等级会有enumCode重复的情况需要做单独处理 
        v.value = val.field_type === "Level" || val.field_type === "Level1" || val.field_type === "ShopName" ? v.id : v.enumCode;
      });
    }
    setPropertyValueOptions(data);
  };

  // 数字格式处理
  const numberFormatter = (text: any) => {
    if(text){
      const reg = /^\d+$/;
      if(reg.test(text)){
        return text;
      } else if(val.field_type === 'Long'){
        return text.split(".")[0];
      } else if(val.field_type === 'Decimal'){ 
        const valueStr = text.toString();
        const parts = valueStr.split('.');
        if (parts.length === 2 && parts[1].length > 2) {
          return parseFloat(text).toFixed(2);
        } else {
          return parseFloat(text);
        }
      }
    }
    return '';
  };

  // 单月份选择
  type DateComponent = Required<NonNullable<DatePickerProps<Dayjs>['components']>>['date'];
  type GetProps<T> = T extends React.ComponentType<infer P> ? P : never;

  const [monthOpen, setMonthOpen] = useState(false);

  const MyDatePanel = (props: GetProps<DateComponent>) => {
    return (
      <MonthPickerComponent  
        key={1}
        val={val}
        flag="single"
        openFlag={monthOpen}
        getSingleOpenStatus={(v: boolean) => setMonthOpen(v)}
        getData={(eVal: any) => {
          getRulesChangeFunction({e: eVal, id: val.rules_operate_id, key: "value_m", f: "p"});
        }}
      />
    )
  };
  
  useEffect(() => {
    if(current === 1 ||
      operateType === "edit" || 
      operateType === "view" || 
      operateType === "copy" || 
      itemData.data_sources === "copy" || 
      ruleData.data_sources === "copy"){
      getProfilePropertyList();
      if(val.field_id){
        getProfileOperator(val.field_type, 's');
        getProfileEnumsList({fieldType: val.field_type, fieldCode: val.field_code});
      }
    }
  }, []);

  return(
    <div style={{ display: "flex", marginLeft: 8, width: ruleData.rules.length > 1 ? "calc(100% - 8px - (100% - 24px - 10px - 16px)/4)" : "calc(100% - 8px - (100% - 24px)/4)" }}>
      <Select
        disabled={operateType === "view"}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        placeholder="请选择客户属性"
        style={{width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)"}}
        value={val.field_id}
        onChange={(e) => {
          getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "field_id", f: "p", option: propertyOptions});
          setPropertyRelationshipOptions([]);
          getProfileOperator(e, 'o');
          const storeItem = propertyOptions.find((v: any, i: number) => v.id === e);
          if (storeItem.fieldType !== "String" && storeItem.fieldType !== "Date" && storeItem.fieldType !== "Long" && storeItem.fieldType !== "Decimal") {
            getProfileEnumsList({fieldType: storeItem.fieldType, fieldCode: storeItem.fieldCode});
          }
        }}
        options={propertyOptions}
      />
      <Select
        disabled={operateType === "view"}
        placeholder="请选择运算条件"
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", marginLeft: 8 }}
        value={val.operator_id}
        onChange={(e) =>{
          getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "operator_id", f: "p", option: propertyRelationshipOptions});
        }}
        options={propertyRelationshipOptions}
      />
      {(val.field_type === "String" || val.field_type === "Phone") &&
        val.operator_id !== 5 &&
        val.operator_id !== 6 && (
          <Input
            disabled={operateType === "view"}
            value={val.value}
            style={{
              width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", 
              height: "32px",
              marginLeft: 8,
            }}
            placeholder="请输入关键词"
            onChange={(e) =>{
              getRulesChangeFunction({e: e.target.value, id: val.rules_operate_id, key: "value", f: "p"});
            }}
          />
        )}
        {val.field_type === "Date" &&
          val.operator_id !== 5 &&
          val.operator_id !== 6 &&
          val.operator_id !== 7 && (
            <DatePicker
              style={{
                width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", 
                height: "32px",
                marginLeft: 8,
              }}
              allowClear
              disabled={operateType === "view"}
              value={val.value_time ? dayjs(val.value_time, 'YYYY-MM-DD') : null}
              placeholder="请选择时间"
              format="YYYY-MM-DD"
              onPanelChange={(value) => {getRulesChangeFunction({e: value, id: val.rules_operate_id, key: "value_d", f: "p"})}}
              onChange={(date, dateString) => {
                getRulesChangeFunction({e: dateString, id: val.rules_operate_id, key: "value_d", f: "p"});
              }}
            />
        )}
        {val.field_type === "Year" &&
          val.operator_id !== 5 &&
          val.operator_id !== 6 &&
          val.operator_id !== 7 && (
            <DatePicker
              style={{
                width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", 
                height: "32px",
                marginLeft: 8,
              }}
              allowClear
              disabled={operateType === "view"}
              value={val.value_time ? dayjs(val.value_time, 'YYYY') : null}
              picker="year"
              onPanelChange={(value) => {getRulesChangeFunction({e: value, id: val.rules_operate_id, key: "value_y", f: "p"})}}
              onChange={(date, dateString) => {
                getRulesChangeFunction({e: dateString, id: val.rules_operate_id, key: "value_y", f: "p"});
              }}
            />
        )}
        {(val.field_type === "Month") &&
          val.operator_id !== 5 &&
          val.operator_id !== 6 &&
          val.operator_id !== 7 && (
            <DatePicker
              style={{
                width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", 
                height: "32px",
                marginLeft: 8,
              }}
              allowClear
              disabled={operateType === "view"}
              value={val.value_time ? dayjs(val.value_time, 'M月') : null}
              // picker="month"
              format={"M月"}
              open={monthOpen}
              onFocus={(_, info) => {
                setMonthOpen(!monthOpen);
              }}
              onBlur={(_, info) => {
                setMonthOpen(false);
              }}
              showNow={false}
              components={{
                date: MyDatePanel,
              }}
              onChange={(date, dateString) => {
                getRulesChangeFunction({e: dateString, id: val.rules_operate_id, key: "value_m", f: "p"});
              }}
            />
        )}
        {(val.field_type === "Date" || val.field_type === "Year") &&
          val.operator_id === 7 && (
            <RangePicker
              disabled={operateType === "view"}
              value={val.value_time ? [dayjs(val.value_time[0], val.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"), dayjs(val.value_time[1], val.field_type === "Date" ? "YYYY-MM-DD" : "YYYY")] : null}
              placeholder={["开始时间", "结束时间"]}
              style={{
                width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", 
                height: "32px",
                marginLeft: 8,
              }}
              allowClear
              picker={val.field_type === "Date" ? "date" : "year"}
              format={val.field_type === "Date" ? "YYYY-MM-DD" : "YYYY"}
              onPanelChange={(value) => {getRulesChangeFunction({e: value, id: val.rules_operate_id, key: "value_r", f: "p"})}}
              onChange={(date, dateString) => {
                getRulesChangeFunction({e: dateString, id: val.rules_operate_id, key: "value_r", f: "p"});
              }}
            />
          )}
          {val.field_type === "Month" && 
          val.operator_id === 7 && (
            <MonthRangeComponent 
              operateType={operateType}
              width = {ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)"}
              val={val}
              open={val.month_open}
              getDataChange={(mVal: any) => getRulesChangeFunction({e: mVal.e, id: val.rules_operate_id, key: mVal.k, f: "p"})}
            />
            )
          }
        {(val.field_id !== null && 
          val.field_type !== "String" &&  
          val.field_type !== "Date" && 
          val.field_type !== "Year" &&
          val.field_type !== "Month" &&
          val.field_type !== "Long" &&
          val.field_type !== "Phone" &&
           val.field_type !== "Decimal") && (
          <Select
            disabled={operateType === "view"}
            mode={(val.operator_id === 12 || val.operator_id === 13 || val.operator_id === 14) ? 'multiple' : undefined}
            maxTagCount= 'responsive'
            placeholder="请选择属性值"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            style={{ width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", height: '32px', marginLeft: 8 }}
            value={val.value_select}
            onChange={(e) => {
              const id_store: any = [];
              if(val.operator_id === 12 || val.operator_id === 13 || val.operator_id === 14){
                // 多选
                propertyValueOptions.forEach((p: any, q: number) => {
                  if(e.includes(p.value)){
                    id_store.push(p.enumCode);
                  }
                });
              } else {
                // 单选
                id_store.push(propertyValueOptions.find((a: any, b: number) => a.value === e).enumCode);
              }
              getRulesChangeFunction({e: val.operator_id === 12 || val.operator_id === 13 || val.operator_id === 14 ? id_store : id_store[0], id: val.rules_operate_id, key: "value_select", f: "p", option: e});
            }}
            options={propertyValueOptions}
          />
        )}
        {(val.field_type === "Long" || val.field_type === "Decimal") && val.operator_id !== 7 && (
          <InputNumber
            disabled={operateType === "view"}
            placeholder="请输入"
            value={val.value}
            min={0}
            max={9999999}
            precision={2}
            formatter={(text) => numberFormatter(text)}
            parser={(text: any) => text.replace(/kg$/, '')}
            style={{
              width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)", 
              height: "32px",
              marginLeft: 8,
            }}
            onChange={(e) => {
              getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "value", f: "p"});
            }}
          />
        )}
        {(val.field_type === "Long" || val.field_type === "Decimal") && val.operator_id === 7 && (
          <div style={{ display: "flex", marginLeft: 8, width: ruleData.rules.length > 1 ? "calc((100% - 16px - 16px - 10px)/3)" : "calc((100% - 16px)/3)" }}>
            <InputNumber
              disabled={operateType === "view"}
              placeholder="请输入"
              precision={2}
              value={val.min}
              min={0}
              max={9999999}
              formatter={(text: any) => numberFormatter(text)}
              parser={(text: any) => text.replace(/kg$/, '')}
              style={{ width: "calc((100% - 14px - 8px - 8px)/2)", height: "32px" }}
              onChange={(e) => {
                getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "min", f: "p"});
              }}
              onBlur={(e: any) => {
                getCheckSizeFunction({value: e.target.value, data: val, key: "min", msg: "属性值区间请按照从小到大的顺序填写", f: "p"});
              }}
              onPressEnter={(e: any) => {
                getCheckSizeFunction({value: e.target.value, data: val, key: "min", msg: "属性值区间请按照从小到大的顺序填写", f: "p"});
              }}
            />
            <span
              style={{
                display: "inline-block",
                height: "32px",
                lineHeight: 'var(--text-font15)',
                margin: "0 8px",
              }}
            >
            到
            </span>
            <InputNumber
              disabled={operateType === "view"}
              value={val.max}
              placeholder="请输入"
              precision={2}
              min={0}
              max={9999999}
              formatter={(text: any) => numberFormatter(text)}
              parser={(text: any) => text.replace(/kg$/, '')}
              style={{ width: "calc((100% - 14px - 8px - 8px)/2)", height: "32px" }}
              onChange={(e) => {
                getRulesChangeFunction({e: e, id: val.rules_operate_id, key: "max", f: "p"});
              }}
              onBlur={(e: any) => {
                getCheckSizeFunction({value: e.target.value, data: val, key: "max", msg: "属性值区间请按照从小到大的顺序填写", f: "p"});
              }}
              onPressEnter={(e: any) => {
                getCheckSizeFunction({value: e.target.value, data: val, key: "max", msg: "属性值区间请按照从小到大的顺序填写", f: "p"});
              }}
              />
        </div>
      )}
      {ruleData.rules?.length > 1 && operateType !== "view" && (
        <div
          style={{
            marginLeft: 10,
            height: "32px",
            lineHeight: 'var(--text-font15)',
          }}
        >
          <Tooltip title="删除">
            <DeleteOutlined
              style={{
                cursor: "pointer",
                color: "var(--text-font-color4)",
                fontSize: "var(--text-font7)",
              }}
              onClick={() => getDeleteItemFunction(idx)}
            />
          </Tooltip>
        </div>
      )}
      </div>
    )};
export default Property;