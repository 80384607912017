import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  Modal,
  Dropdown,
  Space,
  Select,
  Popconfirm,
  message,
  Switch,
  Tooltip
} from "antd";
import type { TableProps } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import ModuleTitle from "../../../../component/ModuleTitle";
import SearchIconInput from "../../../../component/Input/SearchIconInput/searchIconInput";
import FilterView from "../../../../component/FilterView/filterView";
import { getDeptTreeApi } from "../../../../api/dept/dept";
import { getTableScroll } from '@/store/index'
import ButtonPermissions from '../../../../component/Button/SystemButton';
import { SYS_KEY } from '../../../../router/Permissions';
import {
  getSystemLogList,
  getReactRouters,
  getOperType,
  getLogReactRouters
} from "../../../../api/systemLog/systemLog";
import styles from "./index.module.scss";
import { checkPermissions } from "@/utils/utils";
const SystemLog = () => {
  const filterViewRef: any = useRef(null);
  const searchIconInputRef: any = useRef(null);
  //部门列表
  const [deptList, setDeptList] = useState([] as any)
  //操作项
  const [operateList, setOperateList] = useState([] as any)
  const [functionalModule, setFunctionalModule] = useState([] as any);
  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "操作时间",
      key: "beginTime-endTime",
    },
    
    {
      searchType: "text",
      searchComponentType: "cascader",
      placeHolder: "请选择",
      title: "功能模块",
      key: "functionalModule",
      options: functionalModule,
      fieldNames: {
        label: 'label',
        value: 'menuId',
        children:'children',
      }
    },
    {
      searchType: "text",
      searchComponentType: "treeSelect",
      placeHolder: "请选择",
      title: "所属组织",
      key: "deptIds",
      mode:'multiple',
      options: deptList,
      fieldNames: {
        label: 'label',
        value: 'id',
        children:'children',
      }
    },
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "操作项",
      key: "businessTypes",
      options: operateList,
      mode:'multiple',
      fieldNames: {
        label: 'dictLabel',
        value: 'dictValue',
      }
    },
    
  ];

  interface SearcParamType {
    status: string;
    beginTime: string;
    endTime: string;
    businessTypes: any;
    deptIds: any;
    functionalModule: any;
    operName: string;
  }

  const searcParamInt: SearcParamType = {
    status: "",
    beginTime: "",
    endTime: "",
    businessTypes:null,
    deptIds:null, 
    functionalModule:null, 
    operName: "",
  };

  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  // 查询
  const filterFinish = (data: any) => {
    // console.log(data.functionalModule, 'data.functionalModule');
    let first = [] as any
    let second = [] as any
    let end = [] as any
    if (data.functionalModule) {
      data.functionalModule.forEach((it: any) => {
        if (it.length === 1) {
          first.push(it[it.length-1])
        } else if (it.length === 2) {
          second.push(it[it.length-1])
        } else if (it.length === 3) {
          end.push(it[it.length-1])
        }
      })
    }
    const params = {
      firstMenuIds: first? first.join(','):null,
      secondMenuIds: second ? second.join(',') :null,
      endMenuIds: end? end.join(','):null,
      params:{
        beginTime: data.beginTime,
        endTime: data.endTime,
      },
      deptIds: data.deptIds ? data.deptIds.join(','):null,
      businessTypes: data.businessTypes? data.businessTypes.join(','):null,
    } as any
    
    setSearcParam(params);
    getLogListData({ pageSize: 10, pageNum: 1 }, params, 

      // {
      //   orderByColumn: "createTime",
      //   isAsc: "desc",
      // }
    );
  };

  const [listData, setListData] = useState([] as any);

  const [resetConfirm, setResetConfirm] = useState(false);

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
  };




  const columns: TableProps<any>["columns"] = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      key: "sort",
      width: '88px',
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: "操作人",
      dataIndex: "operName",
      key: "operName",
      width: 150
    },
    {
      title: "IP地址",
      dataIndex: "operIp",
      key: "operIp",
      width: 200
    },
    {
      title: "所属组织",
      dataIndex: "deptName",
      key: "deptName",
      width: 200,
    },
    {
      title: "功能模块",
      dataIndex: "firstMenuName",
      key: "firstMenuName",
      width: 200,
    },
    {
      title: "菜单名称",
      dataIndex: "endMenuName",
      key: "endMenuName",
      width: 250
    },
    {
      title: "操作项",
      dataIndex: "businessTypeName",
      key: "businessTypeName",
      width: 250
    },
    {
      title: "操作详情",
      dataIndex: "operateInfo",
      key: "operateInfo",
      width: 200,
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <div className={styles.line_two}>{_}</div>
        </Tooltip>
      )
    },
    {
      title: "操作时间",
      dataIndex: "operTime",
      key: "operTime",
      width: 250
    },
  ];

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "updateTime",
    isAsc: "desc",
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }

    getLogListData(
      { pageSize: pagination.pageSize, pageNum: pagination.current },
      searcParam,
      // sortStore
    );
  };

  // 获取系统日志列表
  const getLogListData = async (
    page: { pageSize: number; pageNum: number },
    data: any,
    // sort: { orderByColumn: string; isAsc: string }
  ) => {
    const param = {
      ...data,
      ...page,
    } as any;
    const { total, rows } = await getSystemLogList(param);
    getHeight(getTableScroll())
    setListData(rows);
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
    // setSortValue({
    //   orderByColumn: sort.orderByColumn,
    //   isAsc: sort.isAsc,
    // });
  };
  // 查询功能模块树
  const getfunctionalModule = async () => {
    const res= await getLogReactRouters();
    if (res) {
      
      setFunctionalModule(res.data)
    }
  };
  //获取所有部门
  const getDeptList = async () => {
    const res = await getDeptTreeApi()
    if (res) {
        const list = res.data[0].children as any[]
        setDeptList(list)
    }
  };
  const getOperTypeData = async () => {
    const res = await getOperType()
    if (res) {
      setOperateList(res.data)
    }
  };
   //过滤器
   const [iconColor, setIconColor] = useState('var(--text-font-color4)');
   const handleMouseEnter = () => {
     // console.log('鼠标移入')
     setIconColor('var(--text-font-color9)');
   };
   const handleMouseLeave = () => {
     // console.log('鼠标移出')
     setIconColor('var(--text-font-color4)');
   };


  const [scrollY, setScrollY] = useState("")
  const getHeight = (e: string) => {
    console.log(e);
    setScrollY(e)
  }
  useEffect(() => {
    getLogListData(
      { pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum },
      searcParam,
      // sortValue
    );
    getfunctionalModule()
    getDeptList()
    getOperTypeData()
    document.addEventListener("click", hidden);

    return () => {
      document.removeEventListener("click", hidden);
    };
  }, []);

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="系统日志" />
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            ref={searchIconInputRef}
            onSearch={(e: string) => {
              const store: SearcParamType = {
                ...searcParamInt,
                operName: e,
              };
              filterViewRef.current?.resetData();
              setSearcParam(store);
              getLogListData({ pageSize: 10, pageNum: 1 }, store, 
                // {
                //   orderByColumn: "createTime",
                //   isAsc: "desc",
                // }
              );
            }}
            placeholder="请输入操作人"
          ></SearchIconInput>
          <Tooltip placement="bottom" title={'过滤器'}>
            <Button
              onClick={() => filterViewRef.current?.showFilterView()}
              icon={
                <FilterOutlined
                  style={{
                    color: iconColor,
                    fontSize: 'var(--text-font7)',
                  }}
                />
              }
              type="text"
              className="click-element"
              style={{ marginRight: '10px' }}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
          </Tooltip>
          {/* <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span> */}
        </div>
      </div>
      <div className={styles.SystemLog}>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          getHeight={getHeight}
        ></FilterView>
        <div>
          <Table
            rowKey={(record) => record.operId}
            columns={columns}
            scroll={{ x: 750, y: scrollY }}
            dataSource={listData}
            pagination={{
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              current: pageQuery.pageNum,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SystemLog;
