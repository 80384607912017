import { AxiosPromise } from 'axios';
import request from '../network';
import { StatisticsVO, UserListVO } from './types';

// 获取列表
export function getStockMemberApi(query: any): AxiosPromise<any> {
  return request({
    url: 'cdpdata/hisMember/list',
    method: 'POST',
    data: query,
  });
}

// 获取统计
export function getStockStatusStatisticsApi(): AxiosPromise<any> {
  return request({
    url: 'cdpdata/hisMember/stockStatusStatistics',
    method: 'get',
    data: {},
  });
}

// 下载
export function downloadStockCustomerApi(query: any): AxiosPromise<any> {
  return request({
    url: 'cdpdata/hisMember/downloadStockCustomer',
    method: 'POST',
    data: query,
  });
}
// 获取同比环比数据
export function getTransStatisticsApi(): AxiosPromise<any> {
  return request({
    url: 'cdpdata/hisMember/transStatistics',
    method: 'get',
    data: {},
  });
}