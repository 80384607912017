import React from 'react';
import styles from "./index.module.scss";
import noResult from "@/pages/LuZhiBI/assets/images/noResult.png";
import { useNavigate } from "react-router-dom";

function ChatBiNoResult() {
  const navigate = useNavigate();
  return (
    <div className={styles.main}>
      <img src={noResult} alt="noResult" />
      <div className={styles.btn} onClick={() => navigate("/DataBoard/LuZhiBI/ChatBI")}>返回对话分析列表</div>
    </div>
  );
}

export default ChatBiNoResult;
