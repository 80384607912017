import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import { LeftOutlined, FilterOutlined } from '@ant-design/icons';
import type { TableProps } from 'antd';
import { Table, Button } from 'antd';
import FilterView from '../../../../../component/FilterView/filterView';
import SearchIconInput from '../../../../../component/Input/SearchIconInput/searchIconInput';
import CheckBusiness from '../checkBusiness';
import FilterIcon from '../../../../../component/FilterIcon/filterIcon';

import { getBusinessPropertyListApi, getUserFieldTypeApi } from '../../../../../api/userCenter/userCenter';
import { getTableScroll } from '@/store/index'
type FieldType = {
  fieldOrFieldName?: string;
  field?: string;
  fieldName?: string;
  fieldShowType?: string;
  createStartTime?: string;
  createEndTime?: string;
  updateStartTime?: string;
  updateEndTime?: string;
};

let createSort = '';
let updateSort = '';
let searchListData: any = {};
const searchList: searchItem[] = [
  {
    searchType: 'text',
    searchComponentType: 'input',
    placeHolder: '请输入业务属性',
    title: '业务属性',
    key: 'field',
  },
  {
    searchType: 'text',
    searchComponentType: 'input',
    placeHolder: '请输入展示名',
    title: '展示名',
    key: 'fieldName',
  },
  {
    searchType: 'text',
    searchComponentType: 'dateRange',
    placeHolder: '',
    title: '创建时间',
    key: 'createStartTime-createEndTime',
  },

  {
    searchType: 'text',
    searchComponentType: 'dateRange',
    placeHolder: '',
    title: '更新时间',
    key: 'updateStartTime-updateEndTime',
  },
  {
    searchType: 'string',
    searchComponentType: 'select',
    placeHolder: '请选择',
    title: '数据类型',
    key: 'fieldShowType',
    options: [],
  },
];
function BusinessAttribute({ recordProp, handBackToList }: { recordProp?: any; handBackToList: () => void }) {
  const [tableList, setTableList] = useState<any[]>([]);
  const [scrollY, setScrollY] = useState("")
  // 表格内容
  interface DataType {
    id: number;
    field: string;
    fieldName: string;
    columnDescribe: string;
    fieldType: string;
    createBy: string;
    createTime: string;
    updateTime: string;
    action: string;
  }
  const columns: TableProps<DataType>['columns'] = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: '88px',
      render: (_, record, i) => <div> {(tableInfo.pageNum - 1) * tableInfo.pageSize + (i + 1)}</div>,
    },
    {
      title: '业务属性',
      dataIndex: 'field',
      key: 'field',
      width: '200px',
      render: (_, record: any) => (
        <>
          {record.fieldType === 'Sql' ? (
            <div
              className={style['field']}
              onClick={() => {
                handleResult(record);
                setRecordList(record);
              }}>
              {record.field}
            </div>
          ) : (
            <div>{record.field}</div>
          )}
        </>
      ),
    },
    {
      title: '展示名',
      dataIndex: 'fieldName',
      key: 'fieldName',
      width: '300px',
    },
    {
      title: '描述',
      dataIndex: 'columnDescribe',
      key: 'columnDescribe',
      width: '300px',
      render: (_, record) => (record.columnDescribe ? <div>{record.columnDescribe}</div> : <div>-</div>),
    },
    {
      title: '数据类型',
      dataIndex: 'fieldShowType',
      key: 'fieldShowType',
      width: '200px',
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
      width: '200px',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: '200px',
      sorter: true,
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '200px',
      sorter: true,
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) =>
        record.fieldType === 'Sql' ? (
          <div>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                handleResult(record);
                setRecordList(record);
              }}>
              查看
            </Button>
          </div>
        ) : null,
    },
  ];
  const filterViewRef = useRef<any>(null);
  const [tableInfo, setTableInfo] = useState({ pageNum: 1, pageSize: 10, total: 0 });
  const handleTableChange = (page: any, pageSize: any, sort: any) => {
    setTableInfo({
      ...tableInfo,
      pageNum: page.current,
      pageSize: page.pageSize,
    });
    if (sort.order) {
      if (sort.columnKey === 'createTime') {
        createSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        updateSort = '';
      } else {
        updateSort = sort.order === 'ascend' ? 'ASC' : 'DESC';
        createSort = '';
      }
    } else {
      createSort = '';
      updateSort = '';
    }
    getBusinessData({ pageNum: page.current, pageSize: page.pageSize });
  };
  // 沙漏里的查询按钮
  const filterFinish = (data: any) => {
    searchListData = data;
    getBusinessData({ pageNum: 1, pageSize: 10 });
  };
  // 表单数据
  const getBusinessData = async (page: any) => {
    const res = await getBusinessPropertyListApi({
      ...page,
      ...searchListData,
      createOrder: createSort,
      updateOrder: updateSort,
      profileId: recordProp.id,
    });
    if (res) {
      setTableList(res.data.rows);
      setTableInfo({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      setScrollY(getTableScroll())
    }
  };
  // 监听更新表单
  useEffect(() => {
    getBusinessData({ pageNum: 1, pageSize: 10 });
  }, []);
  useEffect(() => {
    getUserFieldTypeList();
  }, []);

  // 抽屉里需要的数据
  const [recordList, setRecordList] = useState<any>({});
  const resultRef = useRef<any>(null);
  // 抽屉开关状态
  const [openResult, setOpenResult] = useState(false);
  // 抽屉开关方法
  const handleResult = async (record: any) => {
    if (!openResult) {
      setOpenResult(!openResult);
      resultRef.current.getResultList(record);
    } else {
      setOpenResult(!openResult);
    }
  };

  const getUserFieldTypeList = async () => {
    const res = await getUserFieldTypeApi();
    if (res?.code === 200) {
      const options = res.data.map((item: any) => ({
        ...item,
        value: item.fieldType,
        label: item.fieldName,
      }));
      searchList[4].options = options;
    }
  };
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const getHeight = (e: string) => {
    setScrollY(e)
  }
  // 页面标题和返回按钮
  const HeaderSection = () => {
    return (
      <div className={style['header-bgd']}>
        <div className={style['header-left']}>
          <div
            className={style['header-icon']}
            onClick={() => {
              searchListData = {};
              handBackToList();
            }}>
            <LeftOutlined />
            <span className={style['header_text']}>返回</span>
          </div>
          <div className={style['header-text']}>
            <div>{recordProp.tableName}表</div>
            <div className={style['header-desc']}>描述：{recordProp.tableDescribe}</div>
          </div>
        </div>
        <div className={style['header-right']}>
          {/* 搜索 */}
          <SearchIconInput
            clear={true}
            placeholder="请输入业务属性名或展示名"
            onSearch={(e: string) => {
              {
                searchListData.fieldOrFieldName = e;
                getBusinessData({ pageNum: 1, pageSize: 10 });
                filterViewRef.current?.resetData();
              }
            }}></SearchIconInput>
          {/* 沙漏 */}
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
    );
  };

  return (
    <div className={style['container']}>
      {/* 页面标题 */}
      <HeaderSection />
      {/* 沙漏下拉框 */}
      <div className='containerBox'>
        <FilterView ref={filterViewRef} getHeight={getHeight} searchList={searchList} finish={filterFinish}></FilterView>
        {/* 表格部分 */}
        <Table
          columns={columns}
          dataSource={tableList}
          scroll={{ x: 1400, y: scrollY }}
          pagination={{
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            total: tableInfo.total,
            showTotal: total => `共${total}条`,
            showSizeChanger: true,
          }}
          onChange={(pagination, pageSize, sort) => handleTableChange(pagination, pageSize, sort)}
        />
      </div>
      
      {/* 抽屉 */}
      <CheckBusiness ref={resultRef} open={openResult} drawer={handleResult} record={recordList} />
    </div>
  );
}

export default BusinessAttribute;
