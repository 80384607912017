import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import ModuleTitle from "@/component/ModuleTitle";
import toChatGif from "@/pages/LuZhiBI/assets/images/toChat.gif";
import { checkPermissions } from "@/utils/utils";
import { Table, Space, Dropdown, Popconfirm, Modal, message } from "antd";
import type { TableProps } from "antd";
import { SYS_KEY } from "@/router/Permissions";
import { getTableScroll } from "@/store/index";
import { EllipsisOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getAIReportList, deleteAiReport } from "@/pages/LuZhiBI/api";
function ReportFormList() {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const [tableConfig, setTableConfig] = useState({} as any);
  // const [tableDataSource, setListData] = useState([] as DataType[]);
  // 历史问句数据源
  const [tableDataSource, setTableDataSource] = useState<any>([]);
  const [selectedTableKey, setSelectedTableKey] = useState(
    null as unknown as number
  );
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  interface DataType {
    id: string;
    key: string;
    status: string;
    reportName: string;
    createUser: string;
    createTime: string;
  }
  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    setPageQuery({
      ...pageQuery,
      pageNo: pagination.current,
      pageSize: pagination.pageSize
    })
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: "updateTime", isAsc: "desc" };
    } else {
      sortStore = {
        orderByColumn: sorter.columnKey,
        isAsc: sorter.order === "ascend" ? "asc" : "desc",
      };
    }

    // getList();
  };

  useEffect(() => {
    getList();
  }, []);
  // 获取报表列表
  const getList = async () => {
    const params = {
      pageNo: pageQuery?.pageNo?.toString(),
      pageSize: pageQuery?.pageSize?.toString(),
    };
    await getAIReportList(params).then((res) => {
      if (res?.code === 200) {
        setTableDataSource(res.data.records);
        setPageQuery({
          pageSize: res.data.size,
          pageNo: res.data?.current ? res.data.current : pageQuery.pageNo,
          total: res.data.total,
        });
      }
    });
  };
  // 删除一条记录
  const deleteRecord = (item: DataType, type: string) => {
    console.log(item, type);
    confirm({
      title: "确定要删除该分析报告吗？",
      okText: "确认删除",
      onOk() {
        confirmDeleteRecord(item.id);
      },
    });
  };

  // 确认删除
  const confirmDeleteRecord = (id: string) => {
    deleteAiReport(id.toString()).then((res) => {
      if (res?.code === 200) {
        message.success(res.msg);
      }
      getList();
    });
  };
  useEffect(() => {
    getList();
  }, [pageQuery.pageNo, pageQuery.pageSize]);
  // 查看一条记录详情
  const toRecordDetail = (item: DataType) => {
    navigate("/DataBoard/LuZhiBI/ChatBI/reportFormDetail", { state: {reportId: item.id, reportName: item.reportName } });
  };

  const handleConfirmClick = (item: DataType, type: string) => {};

  const handleCancelClick = (item: DataType, type: string) => {};

  const handleMouseLeavePoint = () => {};
  const handleMouseEnterPoint = (i: any) => {};
  // TODO 权限未处理，暂用其他
  const getTableOperationButtonList = (item: DataType) => {
    const store = [];
    if (checkPermissions(SYS_KEY.ROLE_SAVE)) {
      store.push({
        key: "empower",
        label: (
          <a
            type="link"
            style={{ color: "var( --text-font-color9)" }}
            onClick={() => {
              toRecordDetail(item);
            }}
          >
            查看
          </a>
        ),
      });
    }
    // if (checkPermissions(SYS_KEY.ROLE_REMOVE)) {
      store.push({
        key: "delete",
        label: (
          <a
            style={{ color: "var( --text-font-color9)" }}
            onClick={(e) => {
              deleteRecord(item, "delete");
              e.nativeEvent.stopPropagation();
            }}
          >
            删除
          </a>
        ),
      });
    // }
    return store;
  };
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "报表名称",
      dataIndex: "reportName",
      key: "reportName",
      width: 250,
    },
    {
      title: "创建人",
      dataIndex: "createUser",
      key: "createUser",
      width: 250,
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      width: 250,
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{ items: getTableOperationButtonList(record) }}
            trigger={["click"]}
          >
            <Popconfirm
              placement="right"
              title={tableConfig.title}
              okText={tableConfig.okText}
              cancelText={tableConfig.cancelText}
              description={tableConfig.description}
              onConfirm={() => handleConfirmClick(record, tableConfig.type)}
              onCancel={() => handleCancelClick(record, tableConfig.type)}
              open={selectedTableKey === 1}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                onMouseLeave={handleMouseLeavePoint}
                onMouseEnter={() => handleMouseEnterPoint(record)}
                className={styles[`line-three-point`]}
                // style={{backgroundColor:checkUserId == record.id ? iconColorPointBack:''}}
              >
                <EllipsisOutlined
                  style={{
                    // color: checkUserId == record.id ?'#1e1e1e':'#979797',
                    cursor: "pointer",
                  }}
                />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="对话分析列表" />
        </div>
        <div>
          <img
            onClick={() => window.open('/bi/DataBoard/LuZhiBI/ChatBI/toChat', '_blank')}
            src={toChatGif}
            className={styles.toChatGif}
            alt="开始对话吧"
          />
        </div>
      </div>
      <div className={styles.tableBox}>
        <Table
          columns={columns}
          dataSource={tableDataSource}
          rowKey={(record) => record.key}
          scroll={{ x: 750, y: getTableScroll() }}
          pagination={{
            total: pageQuery.total,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showTotal: (total) => `共 ${total} 条`,
          }}
          onChange={handleStandardTableChange}
        />
      </div>
    </div>
  );
}

export default ReportFormList;
