import React, { useEffect, useState } from "react";
import { 
  Select,
} from "antd";
import dayjs from "dayjs";
import MonthPickerComponent from "../MonthPickerComponent";
import styles from "./index.module.scss";

const monthData = [
  {label: '1月', value: 1},
  {label: '2月', value: 2},
  {label: '3月', value: 3},
  {label: '4月', value: 4},
  {label: '5月', value: 5},
  {label: '6月', value: 6},
  {label: '7月', value: 7},
  {label: '8月', value: 8},
  {label: '9月', value: 9},
  {label: '10月', value: 10},
  {label: '11月', value: 11},
  {label: '12月', value: 12}
];


const MonthRangeComponent = (props: {
  operateType: string,
  width: string
  val: any,
  open: boolean,
  getDataChange: Function
  
}) => {

  const {operateType, width, val, open, getDataChange = () => {}} = props;

  const [rangeDate, setRangeDate] = useState([] as number[]);

  const chooseItme = (id: number) => {
    let store: number[] = [];
    if(rangeDate.length === 0){
      store.push(id);
      setRangeDate(store);
    } else if(rangeDate.length === 1){
      store=[...rangeDate];
      store.push(id);
      setRangeDate(store.sort((a: number, b: number) => a - b));
      getDataChange({e: store.map((a: any, b: number) => a+'月'), k: "value_m_r"});
      setTimeout(() => {
        getDataChange({e: false, k: "month_open"});
      }, 100);
    } else if(rangeDate.length === 2){
      store=[];
      store.push(id);
      setRangeDate(store);
    }
  };

  // 获取月份选择键样式
  const getItemStyle = (v: number) => {
    const store: number[] = [...rangeDate];
    if(store.length === 2){
      if(v > store[0] && v < store[1]){
        return 'rangeStyle'
      } else if(v === store[0] || v === store[1]) {
        return 'itemSelect'
      } else {
        return 'itemUnSelect'
      }
    } else {
      if(store.includes(v)){
        return 'itemSelect';
      } else {
        return 'itemUnSelect';
      }
    }
  };

  // 获取背景色
  const getBackgroundColor = (v: number) => {
    if(rangeDate.length === 2){
      if(v > rangeDate[0] && v < rangeDate[1]){
        return "var(--background4)";
      } else if(v === rangeDate[0]){
        return "linear-gradient(to right, var(--background1), var(--background4))"
      } else if(v === rangeDate[1]) {
        return "linear-gradient(to right, var(--background4), var(--background1))"
      } else {
        return "var(--background1)";
      }
    } else if(rangeDate.length === 1){
      return "var(--background1)";
    } else {
      return "var(--background1)";
    }
  }

  const handleClickOutside = (e: any) => { 
    if(open){
      if (e.target && !e.target.closest('.modal') && 
      !e.target.closest('.custom-dropdown-container') && 
      !e.target.closest('.ant-picker-panels') &&
      !e.target.closest('.ant-select-dropdown') &&             
      !e.target.closest('.ant-picker-body') &&
      !e.target.closest('.ant-picker-header') &&
      !e.target.closest('.ant-picker') &&
      !e.target.closest('.ant-select') &&
      !e.target.closest('.ant-picker-panel-layout')) {
        getDataChange({e: false, k: "month_open"});
      }
    } 
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    if(open){
      if(val.value){
        setRangeDate(val.value.map((a: any, b: number) => Number(a)));
      }
    } else {
      setRangeDate([]);
    }
  }, [open]);

  return(
    <div style={{width: width, marginLeft: 8, height: "32px"}}>
      <Select 
        allowClear
        style={{width: '100%'}}
        disabled={operateType === "view"}
        value={val.value_time}
        placeholder="请选择月份区间"
        open={open}
        dropdownStyle={{ width: "294px" }}
        onClick={(e) => {
          if(operateType === "view"){
            return;
          } 
          if(val.month_open === false){
            getDataChange({e: true, k: 'month_open'});
          }
        }}
        onClear={() => {
          getDataChange({e: null, k: 'value_m_r'});
        }}
        dropdownRender={(menu) => (
          <div className={styles.monthBox}>
          {
            monthData.map((mv: any, mi: number) => {
              return(
                <div className={styles.monthItem} key={mi}>
                  <div 
                    className={styles.monthItemInner} 
                    style={{
                      background: getBackgroundColor(mv.value),
                    }}
                    >
                    <div 
                      className={`${styles.item} ${styles[getItemStyle(mv.value)]}`} 
                      onClick={() => chooseItme(mv.value)}
                    >{mv.label}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        )} 
      />
    </div>
  )
}

export default MonthRangeComponent;