import {Button} from "antd"
import React, {ReactNode, useState} from "react";

let SystemButton = React.forwardRef((props: {
  block?: boolean,
  danger?: boolean,
  disabled?: boolean,
  ghost?: boolean,
  href?: string,
  htmlType?: "button" | "submit" | "reset" | undefined,
  icon?: ReactNode,
  loading?: boolean | { delay: number },
  shape?: "circle" | "round" | "default" | undefined,
  size?: "large" | "middle" | "small" | undefined,
  styles?: any,
  target?: string,
  type?: "link" | "text" | "default" | "dashed" | "primary" | undefined,
  onClick?: any,
  permissions?: string,
  style?: any,
  title?: string
}) => {

  const [style, setStyle] = useState({} as any)
  const handleButtonClick = (e: any) => {
    if (props.onClick) {
      props.onClick(e)
    }
  }

  return <Button
    style={style}
    block={props.block}
    danger={props.danger}
    disabled={props.disabled}
    ghost={props.ghost}
    href={props.href}
    htmlType={props.htmlType}
    icon={props.icon}
    loading={props.loading}
    shape={props.shape}
    size={props.size}
    styles={props.styles}
    target={props.target}
    type={props.type}
    onClick={(e) => handleButtonClick(e)}>{props.title}</Button>
})

export default SystemButton
