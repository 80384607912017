import React, { useState, useEffect, useRef } from 'react'
import styles from '../../../DimensionalModel/index.module.scss'
import ModuleTitle from "@/component/ModuleTitle"
import SearchIconInput from '@/component/Input/SearchIconInput/searchIconInput'
import { LeftOutlined } from '@ant-design/icons'
import { Table, Button, TableColumnsType, TableProps, } from "antd";
import { getDimensionDetailApi } from '@/api/dimensionModel/dimensionModel'
import { getTableScroll, state } from '@/store/index'

const TableCheck = (props:{
  checkInfo?: any,
  detailTableBack?: Function
}) => {
  const { checkInfo, detailTableBack } = props
  const [columns, setColumns] = useState([] as any);
  const [scrollY, setScrollY] = useState("")
  let searchParam: any
  
  // 省市区街道表
  const columnsProvince: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
      // render: (_: any, record: any, i: number) => (
      //   <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      // ),
    },
    {
      title: 'area_id',
      key: 'id',
      dataIndex: 'id',
      // width: '150px',
    },
    {
      title: '位置类型', 
      key: 'level',
      dataIndex: 'level',
      width: '350px',
    },
    {
      title: '位置名称',
      key: 'name',
      dataIndex: 'name',
      width: '350px',
    },
    {
      title: '父级ID',
      key: 'pid',
      dataIndex: 'pid',
      width: '350px',
    } 
  ]
  // 商品类目表
  const columnsCategories: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: 'categories_id',
      key: 'category_id',
      dataIndex: 'category_id',
      width: '150px',
    },
    {
      title: '类目层级', 
      key: 'stratum',
      width: '200px',
      dataIndex: 'stratum',
    },
    {
      title: '类目名称',
      key: 'name',
      width: '300px',
      dataIndex: 'name',
    },
    {
      title: '父级ID',
      key: 'parent_id',
      width: '150px',
      dataIndex: 'parent_id',
    } 
  ]
  // product_line
  const columnsProductLine: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '产品线名称',
      key: 'name',
      dataIndex: 'name',
      width: '150px',
    },
  ]
  // brand
  const columnsBrand: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '品牌名称',
      key: 'brand_name',
      dataIndex: 'brand_name',
      width: '150px',
    },
  ]
  // shop
  const columnsShop: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '店铺名称',
      key: 'shop_name',
      dataIndex: 'shop_name',
      width: '150px',
    },
  ]
  // Platform
  const columnsPlatform: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '平台名称',
      key: 'channel_name',
      dataIndex: 'channel_name',
      width: '150px',
    },
  ]
 
  const [searchContent, setSearchContent] = useState('')
  useEffect(() => {
    switch (checkInfo.id) {
      case 4:
        // area表
        setColumns(columnsProvince as any)
        setSearchContent('请输入位置名称')
        break;
      case 7:
        // dim_category表
        setColumns(columnsCategories as any)
        setSearchContent('请输入类目名称')
        break;
      case 9:
        setColumns(columnsProductLine as any)
        setSearchContent('请输入产品线名称')
        break;
      case 8:
        setColumns(columnsPlatform as any)
        setSearchContent('请输入平台名称')
        break;
      case 10:
        setColumns(columnsBrand as any)
        setSearchContent('请输入品牌名称 ')
        break;
      case 11:
        setColumns(columnsShop as any)
        setSearchContent('请输入店铺名称')
        break;
      default: 
        break
    }
    getDetailData({pageNum: 1, pageSize: 10})
  }, [])
  const [tableList, setTableList] = useState([] as any)
  // 属性分页
  const [pageQuery, setPageQuery] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0
  })
  // 列表查询
  const getDetailData = async (page: any) => {
    const params = {
      tableName: checkInfo.tableNameEn,
      ...page,
      param: searchParam,
      orderByColumn: '',
      isAsc: ''
    }
    const res = await getDimensionDetailApi({...params})
    if (res) {
      if (res.data.result.length > 0) {
        res.data.result.map((item: any, index: any) => {
          item.sort = (page.pageNum - 1) * page.pageSize + (index + 1)
        })
      }
      
      setTableList(res.data.result)
      setPageQuery(
        {
        pageNum: page.pageNum,
        pageSize: page.pageSize,
        total: res.data.total
        }
      )
      setScrollY(getTableScroll())
    }
  }

  // 分页
  const handelTableChange = (page: any) => {
    console.log(page.current, 'page.current');
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize
    })
    getDetailData({pageNum: page.current, pageSize: page.pageSize })
  }

  // 返回按钮
  const comeBack = () => {
    if (detailTableBack) {
      detailTableBack();
    }
  }
  const BackBtn = () => {
    return (
      <div className={styles['BackList_title']} onClick={comeBack}>
        <LeftOutlined />
        <span className={styles['BackList_text']}>返回</span>
      </div>
    )
  }

  return(
    <>
      {
        columns.length > 0 &&
        <div>
          <div className='headBox'>
            <div style={{display: 'flex'}}>
              <BackBtn />
              <div>
                <ModuleTitle mark="large" title={`${checkInfo.tableNameEn}表`} />
                <div style={{color: 'var(--text-font-color4)'}}>描述：{checkInfo.tableDescribe}</div> 
              </div>
            </div>
            <div className={styles.searchBox}>
              <SearchIconInput
                placeholder={searchContent}
                onSearch={(e: string) => { searchParam = e; getDetailData({pageNum: 1, pageSize: 10});}}
              ></SearchIconInput>
            </div>
          </div>
          <div className={styles.detailTableBox}>
              <Table 
                columns={columns}
                scroll={{ x: 1400, y: scrollY }}
                dataSource={tableList} 
                pagination={{
                  current: pageQuery.pageNum,
                  pageSize: pageQuery.pageSize,
                  total: pageQuery.total,
                  showTotal: (total) => `共${total}条`,
                  showSizeChanger: true,
                }}
                onChange={(page, filters, sort) => handelTableChange(page)}
                />
          </div>
        </div>
      }
    </>
  )
}
export default TableCheck