import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import { Input, Drawer, Space, Button, Form, Select, Checkbox, Card, DatePicker, InputNumber, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getUpdateApi, getAddApi } from '../../api/distReward';
const dateFormat = 'YYYY/MM/DD';

const { RangePicker } = DatePicker;
const { Option } = Select;
type FieldType = {
  name: string;
  memberCardId: string;
  condition: string;
  content: string;
  pointType: string;
  pointValue: number;
  pointMultiple: number;
  growthType: string;
  growthValue: number;
  growthMultiple: number;
};
let creadRewardEvents = React.forwardRef(
  (
    props: {
      open?: boolean;
      formList?: any;
      memberList?: any;
      drawer?: Function;
    },
    ref: any,
  ) => {
    const [pointType, setPointType] = useState<number>();
    const [growthType, setGrowthType] = useState<number>();
    const [content, setContent] = useState(false);
    const [growthStatus, setGrowthStatus] = useState(false);
    const [pointStatus, setPointStatus] = useState(false);
    const [updateId, setUpdateId] = useState<any>('');
    const [updateStatus, setUpdateStatus] = useState<any>('');
    const [growthValueFlag, setGrowthValueFlag] = useState<number>();
    const [point, setPoint] = useState<string>();
    const [growth, setGrowth] = useState<string>();
    const [pointFlag, setPointFlag] = useState<number>();
    const [sendPoint, setSendPoint] = useState<number>();
    const [dealGrowthValue, setDealGrowthValue] = useState<number>();
    const [time, setTime] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [rewardForm] = Form.useForm();
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [selectList, setSelectList] = useState<any[]>([]);
    const memberList = props.memberList;

    // 关闭抽屉的时候清空所有状态
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        rewardForm.resetFields();
        setGrowth('');
        setGrowthValueFlag(0);
        setGrowthType(undefined);
        setGrowthStatus(false);
        setPoint('');
        setPointFlag(0);
        setPointType(undefined);
        setPointStatus(false);
        setContent(false);
        setSelectList([]);
        setSelectedValues([]);
        setTime([]);
        setUpdateId(undefined);
        setUpdateStatus(undefined);

        props.drawer();
      }
    };
    const regex = /^[0-9,]*$/; // 判断数字和英文的正则
    const onFinish = () => {
      if (loading) {
        return;
      }
      setLoading(true);
      rewardForm
        .validateFields()
        .then((res: any) => {
          handleSumbit(res);
        })
        .catch(errorInfo => {
          if (errorInfo.errorFields.length === 1 && errorInfo.errorFields[0].name[0] === 'condition' && selectList.length > 0) {
            handleSumbit(errorInfo.values);
            // 在这里处理自定义验证通过的情况
          } else {
            console.log(errorInfo);
            setLoading(false);
            // 校验失败，可以根据errorInfo进行相应处理
          }
          // 校验失败，可以根据errorInfo进行相应处理
        });
    };
    const handleSumbit = (res: any) => {
      console.log('保存结果1', res);
      let selectObj: any = {};
      let valueObj: any = {};
      for (const key in res) {
        if (key.startsWith('select_')) {
          selectObj[key] = res[key];
        }
        if (key.startsWith('value_')) {
          valueObj[key] = res[key];
        }
      }
      let startTime = '';
      let endTime = '';
      Object.keys(valueObj).map(key => {
        const a = valueObj[key] as any[];
        a.map((item, index) => {
          if (index === 0) {
            startTime = `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 00:00:00`;
          } else {
            endTime = `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 23:59:59`;
          }
        });
      });
      let rewardCondition = Object.keys(selectObj)
        .filter(key => selectObj[key] !== undefined)
        .map(key => {
          switch (selectObj[key]) {
            case 'date':
              return '指定支付时间';
            case 'money':
              return '指定订单金额';
            case 'sku':
              return '指定商品';
            case '指定支付时间':
              return '指定支付时间';
            case '指定订单金额':
              return '指定订单金额';
            case '指定商品':
              return '指定商品';
            default:
              return;
          }
        })
        .join(', ');

      let conditionList = Array(selectList.length)
        .fill(0)
        .map((_, index) => {
          const conditionTypeMap: any = {
            指定支付时间: 'date',
            指定订单金额: 'money',
            指定商品: 'sku',
          };

          const selectedValue = res[`select_${index}`];
          const conditionType = conditionTypeMap[selectedValue] || selectedValue;

          return {
            startTime: startTime,
            endTime: endTime,
            skuIds: res.skuIds,
            minAmount: res.minAmount,
            maxAmount: res.maxAmount,
            conditionType: conditionType,
          };
        });

      const param = {
        name: res.name,
        memberCardId: res.memberCardId,
        pointType: res.pointType || null,
        pointValue: res.pointValue || null,
        pointMultiple: res.pointMultiple || null,
        growthType: res.growthType || null,
        growthValue: res.growthValue || null,
        growthMultiple: res.growthMultiple || null,
        rewardCondition: rewardCondition,
        status: updateStatus || '1',
        conditionList: conditionList,
        id: updateId || undefined,
      };
      console.log('传输结果', param);
      // setLoading(false);
      // return;
      getAddOrUpdate(param);
    };
    const getAddOrUpdate = async (param: any) => {
      if (updateId) {
        const res = await getUpdateApi(param);
        if (res?.code === 200) {
          handleDrawerSwitch();
          message.success('更新成功');
        }
      } else {
        const res = await getAddApi(param);
        if (res?.code === 200) {
          handleDrawerSwitch();
          message.success('新建成功');
        }
      }
      setLoading(false);
    };

    const getRewardForm = (e: any, type?: number) => {
      rewardForm.setFieldsValue(e.value);
      if (type === 1) {
        const arr = rewardForm.getFieldValue('content');
        const hasGrowth = arr.includes('growthValue');
        const hasPoint = arr.includes('pointValue');
        if (!hasGrowth) {
          setGrowth('');
          setGrowthType(undefined);
          rewardForm.setFieldsValue({
            growthType: '',
            growthValue: '',
            growthMultiple: '',
          });
        }
        if (!hasPoint) {
          setPoint('');
          setPointType(undefined);
          rewardForm.setFieldsValue({
            pointType: '',
            pointValue: '',
            pointMultiple: '',
          });
        }
        setGrowthStatus(hasGrowth);
        setPointStatus(hasPoint);
        setContent(arr.length > 0);
      }
      if (type === 2) {
        setPoint('');
        setPointType(rewardForm.getFieldValue('pointType'));
        rewardForm.setFieldValue(`pointValue`, '');
        rewardForm.setFieldValue(`pointMultiple`, '');
      }
      if (type === 3) {
        setGrowth('');
        setGrowthType(rewardForm.getFieldValue('growthType'));
        rewardForm.setFieldValue(`growthValue`, '');
        rewardForm.setFieldValue(`growthMultiple`, '');
      }
    };

    const handleSelectChange = (value: string, index: number) => {
      const newSelectedValues = [...selectedValues];
      newSelectedValues[index] = value;
      setSelectedValues(newSelectedValues);
      rewardForm.setFieldValue(`select_${index}`, value);
      switch (value) {
        case 'date':
          rewardForm.setFieldValue(`value_${index}`, '');
          break;
        case 'money':
          rewardForm.setFieldValue(`minAmount`, '');
          rewardForm.setFieldValue(`maxAmount`, '');

          break;
        case 'sku':
          rewardForm.setFieldValue(`skuIds`, '');
          break;
        default:
          break;
      }

      // 检查被删除的项对应的Option，解除禁用
      const deletedItem = selectList[index];
      if (!selectList.includes(deletedItem)) {
        setSelectedValues(selectedValues.filter(value => Number(value) !== deletedItem));
      }
    };
    const deleteArr = (indexToDelete: any) => {
      let list = selectedValues.filter((item: any, index: any) => index !== indexToDelete);
      setSelectedValues(list);
      rewardForm.setFieldValue(`select_${indexToDelete}`, '');
      setSelectList(selectList.slice(0, selectList.length - 1));
      list.forEach((item, index) => {
        rewardForm.setFieldValue(`select_${index}`, item);
      });
    };
    const options = [
      { value: 'date', label: '指定支付时间' },
      { value: 'money', label: '指定订单金额' },
      { value: 'sku', label: '指定商品' },
    ];

    useEffect(() => {}, [pointStatus, growthStatus]);
    useEffect(() => {
      if (props.formList) {
        console.log('编辑', props.formList);
        let data: any = props.formList;
        let newSelectedValues: any = [];

        data.conditionList?.forEach((item: any, index: number) => {
          setSelectList(data.conditionList.map((item: any, index: any) => index));
          switch (item.conditionType) {
            case 'date':
              data[`select_${index}`] = '指定支付时间';
              setSelectedValues(['date']);
              break;
            case 'money':
              data[`select_${index}`] = '指定订单金额';
              setSelectedValues(['money']);
              break;
            case 'sku':
              data[`select_${index}`] = '指定商品';
              setSelectedValues(['sku']);
              break;
            default:
              return;
          }
          if (item.startTime) {
            let a = [dayjs(item.startTime, dateFormat), dayjs(item.endTime, dateFormat)];
            setTime(a);
            data[`value_${index}`] = [dayjs(item.startTime, dateFormat), dayjs(item.endTime, dateFormat)];
          }

          newSelectedValues.push(item.conditionType);
          // 检查被删除的项对应的Option，解除禁用
          setSelectedValues(newSelectedValues.filter((value: any) => Number(value) !== index));
        });
        data.content = [];
        let option = memberList.find((item: any) => item.value === data.memberCardId);
        if (option) {
          setPointFlag(option.pointFlag);
          setSendPoint(option.sendPoint);
          setGrowthValueFlag(option.growthValueFlag);
          setDealGrowthValue(option.dealGrowthValue);
        }

        if (data.pointType) {
          data.content.push('pointValue');
          setContent(true);
          setPointStatus(true);
          setPointType(data.pointType);
          if (data.pointType === 1) {
            setPoint(data.pointValue);
          }
          if (data.pointType === 2) {
            setPoint(data.pointMultiple);
          }
        }
        if (data.growthType) {
          data.content.push('growthValue');
          setContent(true);
          setGrowthStatus(true);
          setGrowthType(data.growthType);
          if (data.growthType === 1) {
            setGrowth(data.growthValue);
          }
          if (data.growthType === 2) {
            setGrowth(data.growthMultiple);
          }
        }

        setUpdateId(data.id);
        setUpdateStatus(data.status);
        if (data.conditionList) {
          data.skuIds = data.conditionList[0].skuIds;
          data.maxAmount = data.conditionList[0].maxAmount;
          data.minAmount = data.conditionList[0].minAmount;
        }
        console.log('编辑2', data);

        rewardForm.setFieldsValue(data);
        // setSelectList(formList.conditionList);
      }
    }, [props.formList, rewardForm]);

    const fillLoginForm = (e: any, type: number) => {
      switch (type) {
        case 1:
          setPoint(e || '');
          break;
        case 2:
          setGrowth(e || '');
          break;
        default:
          break;
      }

      rewardForm.setFieldsValue(e?.value || '');
    };

    const rewardsOptions = [
      { label: '积分奖励', value: 'pointValue', checked: pointStatus, disabled: pointFlag === 1 ? false : true },
      { label: '成长值奖励', value: 'growthValue', checked: growthStatus, disabled: growthValueFlag === 1 ? false : true },
    ];
    const memberChange = (e: any) => {
      let option = memberList.find((item: any) => item.value === e);
      setPointFlag(option.pointFlag);
      setGrowthValueFlag(option.growthValueFlag);
      setSendPoint(option.sendPoint);
      setDealGrowthValue(option.dealGrowthValue);
      setGrowthStatus(false);
      setPointStatus(false);
      setContent(false);
      rewardForm.setFieldsValue({
        pointType: '',
        growthType: '',
        content: [],
        pointValue: '',
        growthValue: '',
        pointMultiple: '',
        growthMultiple: '',
      });
    };
    const minAmountChange=(e:any)=>{
      rewardForm
      .validateFields(['minAmount','maxAmount'])
    }


    return (
      <div>
        <Drawer
          width={600}
          title="奖励事件规则设置"
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={onFinish} htmlType="submit">
                确定
              </Button>
            </Space>
          }>
          <Form form={rewardForm} initialValues={{ remember: true }} onFinish={onFinish} style={{ maxWidth: 600 }} layout="vertical">
            <Form.Item<FieldType>
              label="事件名称"
              name="name"
              rules={[
                { required: true, message: '请输入事件名称' },
                {
                  validator: (_, value) => {
                    if (/\s/.test(value)) {
                      return Promise.reject('事件名称不能包含空格');
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input placeholder="请输入事件名称" autoComplete="off" showCount maxLength={15} />
            </Form.Item>

            <Form.Item<FieldType> label="所属会员卡" name="memberCardId" rules={[{ required: true, message: '请选择' }]}>
              <Select placeholder="请选择" options={memberList} onChange={value => memberChange(value)} />
            </Form.Item>
            {/*} */}
            <Form.Item<FieldType> label="触发条件" name="condition" rules={[{ required: true, message: selectList.length > 0 ? '' : '请添加条件' }]}>
              <div
                className={style['creadRewardEvents-triggerBox']}
                style={{ borderLeftColor: selectList.length > 1 ? 'var(--background3)' : 'var(--background10)' }}>
                {selectList.length > 1 && <div className={style['creadRewardEvents-triggerItem']}>且</div>}
                <div>
                  {selectList.map((item: any, index): any => (
                    <div style={{ display: 'flex' }}>
                      <Form.Item
                        key={item}
                        name={`select_${index}`}
                        rules={[{ required: true, message: '请选择' }]}
                        style={{ display: 'inline-block' }}>
                        <Select
                          style={{ width: 146, marginRight: 10 }}
                          value={selectedValues[index]}
                          onChange={value => handleSelectChange(value, index)}>
                          {options.map(option => (
                            <Option key={option.value} value={option.value} disabled={selectedValues.includes(option.value)}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                        {selectedValues[index] === 'date' && (
                          <Form.Item
                            name={`value_${index}`}
                            rules={[{ required: true, message: '请选择日期范围' }]}
                            style={{ display: 'inline-block' }}>
                            <RangePicker style={{ width: 320 }} defaultValue={time.length > 0 ? time : []} format={dateFormat} allowClear />
                          </Form.Item>
                        )}
                        {selectedValues[index] === 'money' && (
                          <Form.Item name="minAmount" rules={[{ required: true, message: '请输入金额范围' }]} style={{ display: 'inline-block' }}>
                            <InputNumber prefix="￥" precision={2} style={{ width: 152 }} min={1} max={999999999.99} onChange={minAmountChange}/>
                          </Form.Item>
                        )}
                        {selectedValues[index] === 'money' && <> - </>}
                        {selectedValues[index] === 'money' && (
                          <Form.Item
                            name="maxAmount"
                            rules={[
                              { required: true, message: '请输入金额范围' },
                              {
                                validator: (_, value) => {
                                  if (value && rewardForm.getFieldValue('minAmount') >= value) {
                                    return Promise.reject('后置金额应比前置金额大');
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            style={{ display: 'inline-block' }}>
                            <InputNumber prefix="￥" precision={2} style={{ width: 152 }} min={1} max={999999999.99} onChange={minAmountChange}/>
                          </Form.Item>
                        )}
                        {selectedValues[index] === 'sku' && (
                          <Form.Item
                            name="skuIds"
                            rules={[
                              { required: true, message: '请输入商品SKU' },
                              {
                                validator: (_, value) => {
                                  if (/\s/.test(value)) {
                                    return Promise.reject('请输入正确的SKUid');
                                  }
                                  if (!/^[0-9,]*$/.test(value)) {
                                    return Promise.reject("请输入正确的SKUid");
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            style={{ display: 'inline-block' }}>
                            <Input autoComplete="off" showCount maxLength={10000} placeholder="输入商品SKUID用英文','号隔开" style={{ width: 320 }} />
                          </Form.Item>
                        )}
                      </Form.Item>
                      {selectList.length >= 2 && (
                        <Form.Item name="delete" rules={[{ required: false }]} style={{ display: 'inline-block' }}>
                          <DeleteOutlined
                            style={{ marginTop: '-50px', marginLeft: '10px' }}
                            onClick={() => {
                              deleteArr(index);
                            }}
                          />
                        </Form.Item>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                type="dashed"
                disabled={selectList.length >= 3 ? true : false}
                block
                onClick={() => {
                  setSelectList([...selectList, selectList.length]);
                  rewardForm.setFieldValue(`select_${selectList.length}`, '');
                }}>
                添加条件({selectList.length}/3)
              </Button>
            </Form.Item>
            <Form.Item<FieldType> label="奖励内容" name="content" rules={[{ required: true, message: '选择奖励内容' }]}>
              <Checkbox.Group
                options={rewardsOptions}
                onChange={e => {
                  getRewardForm(e, 1);
                }}
              />
            </Form.Item>
            {content && (
              <Card bodyStyle={{ backgroundColor: 'var(--background2)', padding: '8px' }}>
                {pointStatus && (
                  <>
                    <p>积分奖励</p>
                    <div className={style['creadRewardEvents-inputBox']}>
                      <Form.Item<FieldType> name="pointType" rules={[{ required: true, message: '请选择' }]}>
                        <Select
                          placeholder="请选择"
                          style={{ width: 160 }}
                          options={[
                            { value: 1, label: '固定积分' },
                            { value: 2, label: '倍数积分' },
                          ]}
                          onChange={e => {
                            getRewardForm(e, 2);
                          }}
                        />
                      </Form.Item>
                      {pointType && (
                        <>
                          <Form.Item<FieldType>
                            className={style['creadRewardEvents-inputTwo']}
                            name={pointType === 1 ? 'pointValue' : 'pointMultiple'}
                            rules={[{ required: true, message: pointType === 1 ? '请输入积分' : '请输入积分倍数' }]}>
                            <InputNumber
                              min={1}
                              max={pointType === 1 ? 99999999.99 : 50}
                              precision={pointType === 1 ? 0 : 2}
                              controls={false}
                              style={{ width: 185, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
                              addonAfter={pointType === 1 ? '积分' : '倍积分'}
                              onChange={e => fillLoginForm(e, 1)}
                            />
                          </Form.Item>
                        </>
                      )}
                    </div>
                    <span style={{ color: 'var(--text-font-color4)' }}>
                      当前{sendPoint}元=1积分{point ? `，客户额外获得积分 =  ${point && pointType === 1 ? point : `基础积分  × ${point}倍`}` : ''}
                    </span>
                  </>
                )}
                {growthStatus && (
                  <>
                    <p>成长值奖励</p>
                    <div className={style['creadRewardEvents-inputBox']}>
                      <Form.Item<FieldType> name="growthType" rules={[{ required: true, message: '请选择' }]}>
                        <Select
                          onChange={e => {
                            getRewardForm(e, 3);
                          }}
                          placeholder="请选择"
                          style={{ width: 160 }}
                          options={[
                            { value: 1, label: '固定成长值' },
                            { value: 2, label: '倍数成长值' },
                          ]}
                        />
                      </Form.Item>
                      {growthType && (
                        <>
                          <Form.Item<FieldType>
                            className={style['creadRewardEvents-inputTwo']}
                            name={growthType === 1 ? 'growthValue' : 'growthMultiple'}
                            rules={[{ required: true, message: growthType === 1 ? '请输入成长值' : '请输入成长值倍数' }]}>
                            <InputNumber
                              min={1}
                              max={growthType === 1 ? 99999999.99 : 50}
                              precision={growthType === 1 ? 0 : 2}
                              controls={false}
                              style={{ width: 185, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}
                              addonAfter={growthType === 1 ? '成长值' : '倍成长值'}
                              onChange={e => fillLoginForm(e, 2)}
                            />
                          </Form.Item>
                        </>
                      )}
                    </div>
                    <span style={{ color: 'var(--text-font-color4)' }}>
                      当前{dealGrowthValue}元=1成长值
                      {growth ? `，客户额外获得成长值 = ${growth && growthType === 1 ? growth : `基础成长值  × ${growth}倍`}` : ''}
                    </span>
                  </>
                )}
              </Card>
            )}
          </Form>
        </Drawer>
      </div>
    );
  },
);
export default creadRewardEvents;
