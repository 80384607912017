import React, { useState, useEffect, useRef } from 'react'
import styles from '../DrawerTable/index.module.scss'
import { Table, Drawer, TableColumnsType, TableProps, } from "antd";
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput'
import { getTableScroll } from '@/store/index'

const DrawerTable = React.forwardRef((props: {
  isShowDrawer?: boolean,
  onCloseDrawer?: Function,
  inputText?: string,
  titleInfo?: any,
  enumList?: any,
  pageEnum?: any,
  getEnumData?: Function
}, ref: any) => {
  const {isShowDrawer, onCloseDrawer, inputText, titleInfo, enumList, pageEnum, getEnumData} = props
  // const [enumValueList, setEnumValueList] = useState([] as any[])
  const filterViewRef: any = useRef(null);
  const [scrollY, setScrollY] = useState("")
  // 查询入参
  let searchListData: any = {}
  const drawerColumns = [
    {
      title: '序号',
      key: "id",
      dataIndex: 'id',
      width: '88px',
      render: (_: any, record: any, i: number) => (
        <span>{(pageEnum.pageNum - 1) * pageEnum.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: '枚举值',
      key: "enumCode",
      dataIndex: 'enumCode',
    },
    {
      title: '展示名',
      key: "enumName",
      dataIndex: 'enumName',
    },
  ]
  const enumTipList = [
    {
      label: '创建人',
      value: titleInfo.createBy
    },
    {
      label: '创建时间',
      value: titleInfo.createTime
    },
    {
      label: '更新人',
      value: titleInfo.createBy
    },
    {
      label: '更新时间',
      value: titleInfo.updateTime
    },
    {
      label: '描述',
      value: titleInfo.columnDescribe
    },
  ]
  const handelTableChange = (page: any, filters: any, sort: any) => {
    if (getEnumData) {
      getEnumData({pageNum: page.current, pageSize: page.pageSize}, titleInfo.id, searchListData.enumCodeOrName)
    }
  }
  const getUserData = () => {
    if (getEnumData) {
      getEnumData({pageNum: 1, pageSize: 10}, titleInfo.id, searchListData.enumCodeOrName)
    }
  }
  const onClose = () => {
    if (onCloseDrawer) {
      onCloseDrawer();
    }
  }
  useEffect(() => {
    setScrollY(getTableScroll(118))

  }, [isShowDrawer])

  const drawerHeader = () => {
    return (
      <>
        <div style={{display: 'flex'}}>
          <div>{titleInfo.field} {titleInfo.fieldName}</div>
          <div className={styles.headerIcon}>{titleInfo.fieldShowType}</div>
        </div>
        <div className={styles.drawerTip}> 
          {enumTipList.map((it) => 
            <div className={styles.tipItem} key={it.label}>
              {it.label}: {it.value}
            </div>
          )}
        </div>
      </>
    )
  }
  useEffect(() => {
  }, [])
  return (
    <Drawer title={drawerHeader()} className={styles.drawerTableClose} onClose={onClose} open={isShowDrawer} width={600}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div className={styles.drawerTableTitle}>枚举值</div>
        <SearchIconInput
            placeholder={inputText}
            onSearch={(e: string) => { searchListData.enumCodeOrName = e; getUserData(); filterViewRef.current?.resetData(); }}
        ></SearchIconInput>
      </div>
      <Table 
        columns={drawerColumns} 
        dataSource={enumList} 
        scroll={{y: scrollY}}
        pagination={{
          current: pageEnum.pageNum,
          pageSize: pageEnum.pageSize,
          total: pageEnum.total,
          showTotal: (total) => `共${total}条`,
          showSizeChanger: true,
        }}
        onChange={(page, filters, sort) => handelTableChange(page, filters, sort)}
         />
    </Drawer>
  )
})
export default DrawerTable