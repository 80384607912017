import React, { useState, useEffect } from "react";
import { Drawer, Table,Space, Form, Input, Upload, message, Select } from "antd";
import type { DrawerProps, TableProps, TableColumnsType } from "antd";
import styles from "./index.module.scss";
import { UploadOutlined , DownloadOutlined } from '@ant-design/icons';
const ExportExplain = (props: {
  openExportExplain: boolean;
  closeExportExplain: Function; 
}) => {
  const { openExportExplain, closeExportExplain = () => {} } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);
  //客户唯一识别码
  const [optionsValue, setOptionsValue] = useState([
    { label: "手机号", value: "1" },
  ]);
  // 关闭当前抽屉
  const onClose = () => {
    closeExportExplain(false);
  };
  const [listData, setListData] = useState([{},{},{}] as any);
  const columns: TableProps<any>["columns"] = [
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "标签值",
      dataIndex: "labelValueName",
      key: "labelValueName",
    },
  ];
  const columnsLabel: TableProps<any>["columns"] = [
    {
      title: "客户OneID",
      dataIndex: "oneID",
      key: "oneID",
      width: 130
    },
    {
      title: "手机号",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "标签值",
      dataIndex: "labelValueName",
      key: "labelValueName",
    },
  ];

  // 关闭提示弹窗
  const hidden = () => {
  };
  useEffect(() => {
    setOpen(openExportExplain);
  }, [openExportExplain]);
  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={'导入说明'}
        placement={placement}
        width={700}
        onClose={onClose}
        open={open}
      >
        <div>
          <div className={styles.exportExplainMes}>1 上传的客户属性需与选中的客户属性一致，标签值数据类型需与标签数据类型保持一致；</div>
          <div className={styles.exportExplainMes}>2 客户属性或标签值为空，会导致上传失败</div>
          <div className={styles.exportExplainMes}>3 若文件中客户属性存在多行重复数据，则随机保留其中一个属性的标签值</div>
        </div>
        <div className={styles.exportExplainText}>示例</div>
        <div className={styles.exportExplainTable}>
          <div className={styles.exportExplainItem} style={{marginRight:'20px'}}>上传文件信息
            <Table
              style={{ width: "100%", marginTop:'24px' }}
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={listData}
              scroll={{ y: 580 }}
              pagination={false}
            />
          </div>
          <div className={styles.exportExplainItem}>标签计算结果
            <Table
              style={{ width: "100%", marginTop:'24px'}}
              rowKey={(record) => record.id}
              columns={columnsLabel}
              dataSource={listData}
              scroll={{ y: 580 }}
              pagination={false}
            />
          </div>
          
        </div>
      </Drawer>
    </div>
  );
};

export default ExportExplain;
