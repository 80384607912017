import {Button, Tooltip} from 'antd';
import {useState} from "react";
import {FilterOutlined} from "@ant-design/icons";

const FilterIcon = (props: {
  filterTitle?: string,
  handleFilterClick?: any
}) => {
  const [iconColor, setIconColor] = useState('var(--text-font-color4)')
  const handleMouseEnter = () => {
    // console.log('鼠标移入')
    setIconColor('rgba(0, 0, 0, 0.88)')
  }
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setIconColor('var(--text-font-color4)')
  }
  const filterClick = () => {
    if (props.handleFilterClick) {
      props.handleFilterClick();
    }
  }
  return (
    <Tooltip placement="bottom" title={props.filterTitle}>
      <Button onClick={filterClick} icon={<FilterOutlined style={{
        color: iconColor,
        fontSize: "16px",
      }}/>} type="text" className="click-element" style={{marginRight: "10px",}} onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
    </Tooltip>
  );
}
export default FilterIcon
