/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Table, Flex, Form, Input, Select, Row, Col, Space, Button } from 'antd';
import type { TableProps } from 'antd';
import SearchIconInput from '@/pages/LuZhiBI/components/SearchIconInput/searchIconInput';
import DatasetApi from '@/pages/LuZhiBI/api/dataset';
import Filter from '@/pages/LuZhiBI/components/Filter';
import { getToolTipText } from '@/pages/LuZhiBI/utils';

interface IProps {
  ref: any;
  dict: any;
  defaultSelectedKeys: React.Key[];
}

interface DataType {
  id: string;
  dimensionName: string;
  dimensionType: string;
  meaning: string;
}

type FieldType = {
  dimensionName: string;
  dimensionType: string;
};

const initColumns: TableProps<DataType>['columns'] = [
  {
    title: '维度名称',
    dataIndex: 'dimensionName',
    key: 'dimensionName',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '维度分类',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '业务含义',
    dataIndex: 'meaning',
    key: 'meaning',
    width: 200,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
];

const DimensionChoose: FC<IProps> = forwardRef((props, ref) => {
  const { dict, defaultSelectedKeys } = props;
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSlectedChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const [dimensionOptions, setDimensionOptions] = useState<{ label: string; value: string }[]>([]);
  const [dimensionData, setDimensionData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState(initColumns);
  const onSearch = (value: string) => {
    getDimensionData(value);
  };
  const getDimensionOptions = () => {
    const result = dict?.map((item: any) => ({
      label: item.dictLabel,
      value: item.dictValue,
    }));
    result.unshift({ label: '全部', value: '' });
    setDimensionOptions(result);
  };
  const getDimensionData = (search = '') => {
    const { dimensionName = '', dimensionType = '' } = form.getFieldsValue();
    const params = {
      search,
      typeIds: dimensionType ? [dimensionType] : null,
      dimensionName,
    };
    DatasetApi.getDimensionList(params).then(res => {
      if (!res) {
        return;
      }
      setDimensionData(
        res.data?.map((item: any) => ({
          ...item,
          dimensionName: item.name,
          dimensionType: item.sourceFieldType,
          meaning: item.bizExpression,
        })),
      );
      if (selectedRowKeys.length > 0) {
        setSelectedRowKeys(selectedRowKeys.filter(key => res.data.map((item: any) => item.id).includes(key)));
      }
    });
  };
  useEffect(() => {
    setColumns(pre => {
      return pre.map(item => {
        if (item.key === 'type') {
          return {
            ...item,
            render: text => {
              const target = dimensionOptions.find(option => option.value === text);
              return getToolTipText(target?.label);
            },
          };
        }
        return item;
      });
    });
  }, [dimensionOptions]);
  useEffect(() => {
    getDimensionOptions();
    getDimensionData();
  }, []);
  useEffect(() => {
    setSelectedRowKeys(defaultSelectedKeys);
  }, [defaultSelectedKeys]);
  const selectedRows = useMemo(() => {
    return dimensionData.filter(item => selectedRowKeys.includes(item.id));
  }, [selectedRowKeys, dimensionData]);
  useImperativeHandle(ref, () => ({
    selectedRows,
  }));
  return (
    <div>
      <Flex justify="space-between" align="center">
        <span>已选 {selectedRowKeys.length} 个</span>
        <Flex justify="center">
          <SearchIconInput placeholder="请输入维度名称或维度分类进行搜索" width={300} onSearch={onSearch} />
          <Filter hoverTitle="过滤器" nodeID="filter-main">
            <Form
              form={form}
              onFinish={() => {
                getDimensionData();
              }}
              initialValues={{
                dimensionName: '',
                dimensionType: '',
              }}
            >
              <Row gutter={16} wrap style={{ marginTop: 24 }}>
                <Col span={12}>
                  <Form.Item<FieldType> name="dimensionName" label="维度名称">
                    <Input placeholder="请输入维度名称" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item<FieldType> style={{ flex: 1 }} name="dimensionType" label="维度分类">
                    <Select placeholder="请选择维度分类" options={dimensionOptions} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Flex justify="flex-end">
                    <Form.Item>
                      <Space size="small">
                        <Button htmlType="reset">重置</Button>
                        <Button type="primary" htmlType="submit">
                          查询
                        </Button>
                      </Space>
                    </Form.Item>
                  </Flex>
                </Col>
              </Row>
            </Form>
          </Filter>
        </Flex>
      </Flex>
      <div id="filter-main"></div>
      <div style={{ marginTop: 24 }}>
        <Table
          columns={columns}
          dataSource={dimensionData}
          pagination={false}
          rowKey="id"
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            onChange: onSlectedChange,
          }}
        />
      </div>
    </div>
  );
});

export default DimensionChoose;

