//用户状态
export enum RoleStatusEnum {
  ENABLE = 0, // 启用
  DISABLED = 1,  // 停用
}

//用户状态
export enum RoleStatusTextEnum {
  ENABLE_TEXT = '启用',
  DISABLED_TEXT = '停用',
}