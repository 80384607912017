import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import type { TableProps } from 'antd';
import { Table, Button } from 'antd';
import FilterView from '../../../component/FilterView/filterView';
import FilterIcon from '../../../component/FilterIcon/filterIcon';
import OneIdDetails from './components/OneIdDetails';
import ModuleTitle from '../../../component/ModuleTitle';
import SearchIconInput from '../../../component/Input/SearchIconInput/searchIconInput';
import { getOneIdMergeRecordApi } from '../../../api/userCenter/userCenter';
import { getTableScroll } from '@/store/index';

type FieldType = {
  mergeStartDate?: string;
  aesPhone?: string;
  mergeEndDate?: string;
  oneId?: string;
};
const defaultFormValue = {
  mergeStartDate: '', //合并开始时间
  aesPhone: '', //手机号
  mergeEndDate: '', //合并结束时间
  oneId: '', //oneId
};
const defaultTableInfo = {
  pageNum: 1,
  pageSize: 10,
  total: 0,
};
const searchList: searchItem[] = [
  {
    searchType: 'string',
    searchComponentType: 'input',
    placeHolder: '请输入',
    title: 'OneID',
    key: 'oneId',
  },
  {
    searchType: 'text',
    searchComponentType: 'dateRange',
    placeHolder: '',
    title: '合并时间',
    key: 'mergeStartDate-mergeEndDate',
  },
  {
    searchType: 'string',
    searchComponentType: 'input',
    placeHolder: '请输入',
    title: '手机号',
    key: 'aesPhone',
  },
];
function OneIDCoalesce() {
  const [tableList, setTableList] = useState<any[]>([]);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const filterViewRef = useRef<any>(null);
  const [tableInfo, setTableInfo] = useState(defaultTableInfo);
  const [isShowOneIdDetails, setIsShowOneIdDetails] = useState(false);
  const [scrollY, setScrollY] = useState('');

  // 沙漏里的查询按钮
  const filterFinish = (data: any) => {
    setSearchInfo({ ...data });
    setTableInfo({ ...tableInfo, pageNum: 1 });
  };
  // 表单数据
  const getTableList = async () => {
    const { pageNum, pageSize } = tableInfo;
    const res = await getOneIdMergeRecordApi({
      ...searchInfo,
      pageNum,
      pageSize,
    });
    console.log({ ...searchInfo });

    if (res.rows) {
      setTableList(res.rows || []);
      setTableInfo({ ...tableInfo, total: res.total || 0 });
      setScrollY(getTableScroll());
    }
  };
  const getHeight = (e: string) => {
    setScrollY(e);
  };
  // 监听更新表单
  useEffect(() => {
    getTableList();
  }, [tableInfo.pageNum, tableInfo.pageSize, JSON.stringify(searchInfo)]);
  // 表格内容
  interface DataType {
    oneId: string;
    oneIdUsed: string;
    mergeDate: string;
  }

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      render: (_, record, i) => <div> {(tableInfo.pageNum - 1) * tableInfo.pageSize + (i + 1)}</div>,
      width: 88,
    },
    {
      title: '当前OneID',
      dataIndex: 'oneId',
      key: 'oneId',
    },
    {
      title: '历史OneID',
      dataIndex: 'oneIdUsed',
      key: 'oneIdUsed',
    },
    {
      title: '合并时间',
      dataIndex: 'mergeDate',
      key: 'mergeDate',
    },
    {
      title: '合并字段',
      dataIndex: 'mergeRule',
      key: 'mergeRule',
      // render: (_, record, index) => <div>手机号相同</div>,
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) => (
        <div>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              handleDetailList(record);
            }}>
            详情
          </Button>
        </div>
      ),
    },
  ];
  // 表格的onchange方法
  const handleTableChange = (pagination: any, _: any) => {
    setTableInfo({
      ...tableInfo,
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
    });
  };
  // 表格部分
  const TableSection = () => {
    return (
      <div>
        <Table
          columns={columns}
          dataSource={tableList}
          scroll={{ x: 1400, y: scrollY }}
          pagination={{
            total: tableInfo.total,
            pageSize: tableInfo.pageSize,
            current: tableInfo.pageNum,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={handleTableChange}
        />
      </div>
    );
  };
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  // 页面标题和返回按钮
  const HeaderSection = () => {
    return (
      <div className={style['header-bgd']}>
        <div className={style['header-left']}>
          <ModuleTitle mark="large" title="OneID融合" />
        </div>
        <div className={style['header-right']}>
          <SearchIconInput
            clear={true}
            placeholder="请输入OneID"
            onSearch={(e: string) => {
              setSearchInfo({
                ...searchInfo,
                oneId: e,
              });
            }}></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
    );
  };
  const detailsRef = useRef<any>(null);
  const [oneId, setOneId] = useState<string>();
  const [oneIdUsed, setOneIdUsed] = useState<string>();
  const [updateTime, setUpdateTime] = useState<string>();
  const handleDetailList = async (record: any) => {
    setOneId(record.oneId);
    setOneIdUsed(record.oneIdUsed);
    setUpdateTime(record.mergeDate);
    setIsShowOneIdDetails(true);
    detailsRef.current?.getDetailList();
  };
  if (isShowOneIdDetails) {
    return (
      <OneIdDetails
        ref={detailsRef}
        oneId={oneId}
        oneIdUsed={oneIdUsed}
        updateTime={updateTime}
        handBackToList={() => setIsShowOneIdDetails(false)}
      />
    );
  }
  return (
    <div>
      {/* 页面标题 */}
      <HeaderSection />
      <div className={style['container']}>
        {/* 沙漏下拉框 */}
        <FilterView ref={filterViewRef} getHeight={getHeight} searchList={searchList} finish={filterFinish}></FilterView>
        {/* 表格部分 */}
        <TableSection />
      </div>
    </div>
  );
}

export default OneIDCoalesce;
