import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { systemHomeApi } from '../../../api/home/home'
import { SysHomeVo, RecentlyVisitedVo } from '../../../api/home/types'
import dayjs from "dayjs";
import { state } from '../../../store/index'
import styles from '../HomePage/index.module.scss'
import { Tooltip, Card, Tabs, Col, Row, ConfigProvider } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import PieChart from './components/PieChart/pieChart'
import RingChart from './components/RingChart/ringChart'
import img1 from './assets/img1.png'
import img2 from './assets/img2.png'
import img3 from './assets/img3.png'
import img4 from './assets/img4.png'
import emptyIcon from './assets/empty.png'
import Iconfont from '@/component/Icon';


// 首页标题
const HeaderText = ({ sysCustomerInfo }: { sysCustomerInfo: SysHomeVo }) => {
  return (
    <div className={styles.HeaderText}>
      <div style={{ lineHeight: 'var(--text-font15)' }}> Hi，{state.detail.nickName}，Welcome to {state.styleStoreData.groundTitle}</div>
      <div className={styles.deadline}>数据统计截止：
        <span style={{ color: 'var(--text-font-color4)' }}>{sysCustomerInfo.date ? dayjs(sysCustomerInfo.date).format('YYYY-MM-DD') : '--'}</span>
      </div>
    </div>
  )
}
// 客户数据 
const CustomerData = ({ sysCustomerInfo }: { sysCustomerInfo: SysHomeVo }) => {
  const cardList = [
    {
      label: '全渠道客户',
      value: sysCustomerInfo.customerNum ? sysCustomerInfo.customerNum : '--',
      img: img1
    },
    {
      label: '全渠道已购会员',
      value: sysCustomerInfo.orderVip ? sysCustomerInfo.orderVip : '--',
      img: img2
    },
    {
      label: '全渠道未购会员',
      value: sysCustomerInfo.noOrderVip ? sysCustomerInfo.noOrderVip : '--',
      img: img3
    },
    {
      label: '全渠道已购非会员',
      value: sysCustomerInfo.orderCustomer ? sysCustomerInfo.orderCustomer : '--',
      img: img4
    }
  ]
  return (
    <div className={styles.CustomerData}>
      <div className={styles.smallCardList}>
        {
          cardList.map((it: any, index: number) =>
            <div className={styles.smallCardItem} key={it.label} style={{ marginRight: index === cardList.length - 1 ? '0px' : '24px' }}>
              <div className={styles.cardTitle}>{it.label}</div>
              <div className={styles.customerData}>{it.value.toLocaleString('en-US')}</div>
              <img className={styles.customerImg} src={it.img} alt="" />
            </div>
          )
        }
      </div>
    </div>
  )
}

// 统计图
const ChartSection = ({ sysCustomerInfo }: { sysCustomerInfo: SysHomeVo }) => {

  const customerType = {
    label: '客户类型分布',
    tip: '■ 查看会员和非会员的结构分布状况\n● 会员已购：产生有效订单的会员\n● 会员未购：未产生有效订单的会员\n● 非会员已购：产生有效订单的非会员',
    chartId: 'cusomer_type',
    chartValue: [
      { name: '会员已购', value: sysCustomerInfo.orderVip ? sysCustomerInfo.orderVip : 0 },
      { name: '会员未购', value: sysCustomerInfo.noOrderVip ? sysCustomerInfo.noOrderVip : 0 },
      { name: '非会员已购', value: sysCustomerInfo.orderCustomer ? sysCustomerInfo.orderCustomer : 0 },
    ],
  };
  const customerActivity = {
    label: '客户活跃度分布',
    tip: ' ■ 查看客户活跃和非活跃程度\n● 活跃客户：截止到数据更新日期，近180天内产生过有效订单的客户\n● 沉默客户：截止到数据更新日期，近180天内未购买，前180天到365天内产生过有效订单的客户\n● 流失客户：截止到数据更新日期，近365天内未购买，前365天前产生过有效订单的客户',
    chartId: 'cusomer_activity_degree',
    chartValue: [
      { name: '活跃客户数', value: sysCustomerInfo.activityCustomer ? sysCustomerInfo.activityCustomer : 0 },
      { name: '沉默客户数', value: sysCustomerInfo.silentCustomer ? sysCustomerInfo.silentCustomer : 0 },
      { name: '流失客户数', value: sysCustomerInfo.lostCustomer ? sysCustomerInfo.lostCustomer : 0 },
    ],
  };
  const cusomerLevel = {
    label: '会员等级分布',
    tip: '查看会员等级分布状况',
    chartId: 'member_level',
    chartValue: [
      { name: '水上玩家', value: sysCustomerInfo.levelI ? sysCustomerInfo.levelI : 0 },
      { name: '水上达人', value: sysCustomerInfo.levelII ? sysCustomerInfo.levelII : 0 },
      { name: '水上精英', value: sysCustomerInfo.levelIII ? sysCustomerInfo.levelIII : 0 },
      { name: '水上大神', value: sysCustomerInfo.levelIV ? sysCustomerInfo.levelIV : 0 },
      { name: '星海', value: sysCustomerInfo.levelV ? sysCustomerInfo.levelV : 0 },
    ],
  };
  return (
    <Row justify="space-between" className={styles.chartList} gutter={24}>
      <Col className={styles.smallCardItem} span={8}>
        <RingChartItem data={customerType} />
      </Col>
      <Col className={styles.smallCardItem} span={8}>
        <PieChartItem data={customerActivity} />
      </Col>
      <Col className={styles.smallCardItem} span={8}>
        <PieChartItem data={cusomerLevel} />
      </Col>
    </Row>
  )
}

// 卡片头部
const CardTitle = (title: string, tip: string) => {
  return (
    <div className={styles.pieChartName}>
      <span>{title}</span>
      <Tooltip placement="top" title={<div style={{whiteSpace: 'pre-wrap'}}>{tip}</div>}>
        <QuestionCircleOutlined className={styles.questionIcon} />
      </Tooltip>
    </div>
  )
}

// 环形图
const RingChartItem = ({ data }: { data: any }) => {
  return (
    <Card title={CardTitle(data.label, data.tip)} style={{ height: 335,}}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '-24PX' }}>
        <RingChart chartId={data.chartId} chartValue={data.chartValue} />
      </div>
    </Card>
  )
}

// 饼图
const PieChartItem = ({ data }: { data: any }) => {
  return (
    <Card title={CardTitle(data.label, data.tip)} style={{ height: 335 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '-24PX' }}>
        <PieChart chartId={data.chartId} chartValue={data.chartValue} />
      </div>
    </Card>
  )
}

// 最近访问
const RecentVisitSection = ({ menuListOne }: { menuListOne: any }) => {
  const navigate = useNavigate();
  let currentTabValue = menuListOne.length > 0 ? menuListOne[0].sysMenu.menuId : '';
  const onChange = (e: any) => {
    currentTabValue = e
  }
  const toCurrentPage = (path: any) => {
    navigate(path)
  }
  const TabChildren = ({ currentMenu }: { currentMenu: any }) => {
    // 跳转分群/标签操作详情页
    const toOperatePage = (info: any, index: number) => {
      if (currentMenu.crowdUrl) {
        localStorage.setItem('setGroupDetail', JSON.stringify(info))
        navigate(currentMenu.crowdUrl[index])
      }
      if (currentMenu.labelUrl) {
        // navigate('/userGrouping/groupingManagement/createUserGrouping',  { state: { flag: 'view', editData: val }})
        navigate(currentMenu.labelUrl[index], { state: { labelId: info } })
        // localStorage.removeItem('labelData')
      }
    }
    // console.log(currentMenu.sysMenu.icon, 'currentMenu.sysMenu.icon');

    return (
      <Row justify="start" className={styles.tabChildren}>
        {
          currentTabValue === 1682 ? // 人群包
            <>
              {
                currentMenu.userCrowdInfos ?
                  currentMenu.userCrowdInfos.map((it: any, index: number) =>
                    <Col className={styles.groupingItem} key={it.id} span={4} onClick={() => toOperatePage(it, index)}>
                      <div className={styles.groupName}>{it.crowdName}</div>
                      <div className={styles.peopleNum}>{it.groupNum ? it.groupNum : '0'}<span className={styles.groupName}> 人</span></div>
                      <div className={styles.updateTime}>
                        最近一次更新时间：<br />
                        {it.calculateDate}
                      </div>
                    </Col>) :
                  <div className={styles.emptyStatus}>
                    <img className={styles.img} src={emptyIcon} alt="" />
                    <div className={styles.text}>暂无记录</div>
                  </div>
              }
            </>
            :
            currentTabValue === 1647 ? // 标签
              <>
                {
                  (currentMenu.labelListQueryRespList && currentMenu.labelListQueryRespList.length > 0) ?
                    currentMenu.labelListQueryRespList.map((it: any, index: number) =>
                      <Col className={styles.groupingItem} key={it.labelId} span={4} onClick={() => toOperatePage(it.labelId, index)}>
                        <div className={styles.labelName}>{it.labelName? it.labelName : '--'}</div>
                        <div className={styles.updateTime}>
                          最近一次更新时间：<br />
                          {it.lastRenewTime}
                        </div>
                      </Col>) :
                    <div className={styles.emptyStatus}>
                      <img className={styles.img} src={emptyIcon} alt="" />
                      <div className={styles.text}>暂无记录</div>
                    </div>
                }
              </>
              :
              // 看板
              <>
                {
                  currentMenu.sysMenuList ?
                    currentMenu.sysMenuList.map((it: any, index: number) =>
                      <Col className={styles.kanbanItem} key={index} onClick={() => toCurrentPage(it.component)} span={4}>
                        <Iconfont className={styles.itemIcon} icon={currentMenu.sysMenu.icon} />
                        <div>{it.menuName ? it.menuName : '--'}</div>
                      </Col>) :
                    <div className={styles.emptyStatus}>
                      <img className={styles.img} src={emptyIcon} alt="" />
                      <div className={styles.text}>暂无记录</div>
                    </div>
                }
              </>
        }
      </Row>
    )
  }
  return (
    <Card title={CardTitle('最近访问', '近30天经常访问')} className={styles.recentVisit}>
      {
        menuListOne.length > 0 ?
          <Tabs
            onChange={onChange}
            type="card"
            items={menuListOne.map((_: any, i: any) => {
              return {
                label: _.sysMenu.ezName,
                key: _.sysMenu.menuId,
                children: <TabChildren currentMenu={_} />,
              };
            })} />
          :
          <div className={styles.emptyStatus}>
            <img className={styles.img} src={emptyIcon} alt="" />
            <div className={styles.text}>暂无记录</div>
          </div>
      }
    </Card>
  )
}

const HomePage = () => {
  // 客户数据
  const [sysCustomerInfo, setSysCustomerInfo] = useState({} as SysHomeVo)
  // 最近访问菜单列表
  const [menuListOne, setMenuListOne] = useState([] as any)
  const [isComplete, setIsComplete] = useState(false)
  const getSystemInfo = async () => {
    const res = await systemHomeApi()
    if (res) {
      setSysCustomerInfo(res.data.sysHomeVo)
      setMenuListOne(res.data.menuListOne)
      setIsComplete(true)
    }
  }

  useEffect(() => {
    getSystemInfo();

  }, [])

  return (
    <>
      {
        isComplete &&
        <div className={styles.homePageContent}>
          <HeaderText sysCustomerInfo={sysCustomerInfo} />
          <CustomerData sysCustomerInfo={sysCustomerInfo} />
          <ChartSection sysCustomerInfo={sysCustomerInfo} />
          <RecentVisitSection menuListOne={menuListOne} />
        </div>
      }
    </>
  )
}
export default HomePage 