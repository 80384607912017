import React, { useState, useEffect, useRef, useContext } from "react";
import dayjs from "dayjs";
import { Table, Button, Dropdown, Space, Modal, message, Popconfirm, Form, Input, Badge, Tooltip, Select, Switch } from "antd";
import type { TableProps, TableColumnsType, GetRef, InputRef } from "antd";
import { EditOutlined, QuestionCircleOutlined, EllipsisOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import ModuleTitle from "../../../../component/ModuleTitle";
import SearchIconInput from "../../../../component/Input/SearchIconInput/searchIconInput";
import FilterView from "../../../../component/FilterView/filterView";
import OperateLog from '../../components/OperateLog'
import ImportMes from '../../components/ImportMes'
import DownloadUserDeatil from '../../components/DownLoad'
import { GroupListVO, EditableCellProps, DataType, SearcParamType, DataCountVO } from '../../../../api/userGroup/type'
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { getUserDataApi, enableDisableApi, deleteGroupApi, editGroupApi, dataCountApi, describeEditApi } from '../../../../api/userGroup/group'
import { crowdUpdata } from '../../../../api/label/label'
import { getUserClickApi } from '../../../../api/home/home'
import { AccountStatusEnum, AccountStatusTextEnum, CalculateStatus, UpdateRate } from '../../../../api/userGroup/status'
import FilterIcon from '../../../../component/FilterIcon/filterIcon'
import ButtonPermissions from '../../../../component/Button/SystemButton'
import { CROWD_KEY } from '../../../../router/Permissions'
import { checkPermissions } from "@/utils/utils";
import { getTableScroll, state } from '@/store/index';
import CreateChooseGroup from '../../../UserGrouping/GroupingManagement/CreateChooseGroup';
import { log } from "console";

// type InputRef = GetRef<typeof Input>;
type FormInstance<T> = GetRef<typeof Form<T>>;
const EditableContext = React.createContext<FormInstance<any> | null>(null);
// const EditableContext = React.createContext(null);
interface EditableRowProps {
  index: number;
}
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form as any}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
// 编辑描述列
const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    console.log('edit');
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const editDescribe = async (values: any) => {
    console.log(values, 'editValues');
    const res = await describeEditApi({
      id: record.id,
      ...values
    })
    if (res) {
      // 操作成功后调用埋点接口
      getUserClickApi(state.childMenuId, 3, record.crowdId, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
      message.success('修改成功')
    }
  }

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
      editDescribe(values)
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: false,
            message: '',
          },
        ]}
      >
        <Input ref={inputRef} maxLength={100} showCount={true} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;


// 查询入参
let searchListData: any = {}
// 列表操作 启用、停用、删除
let operateType = ''
// 列表排序
let sortType = ''
// 父组件
const UserGroupingManagement = () => {
  const filterViewRef: any = useRef(null);
  // 查询参数
  const searcParamInt: SearcParamType = {
    createTimeStart: "",
    createTimeEnd: "",
    idOrName: "",
  };
  const navigate = useNavigate();
  
  //传到创建分群还是标签的值
  const [chooseGroupData, setChooseGroupData] = useState({ flag: 'create', type : 2} as any);
  // const [searchListData, setSearchListData] = useState({} as any)
  const [searcParam, setSearcParam] = useState({ ...searcParamInt });
  const [tableConfig, setTableConfig] = useState({} as any);
  const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number);
  const [resetConfirm, setResetConfirm] = useState(false);
  const [tablePopStatus, setTablePopStatus] = useState(0)
  const [tablePopConfirm, setTablePopConfirm] = useState(false)
  const [operatePopConfirm, setOperatePopConfirm] = useState(false)
  const [deletePopConfirm, setDeletePopConfirm] = useState(false)
  const [openLog, setOpenLog] = useState(false)
  const [confirmTitle, setConfirmTitle] = useState('')
  const [scrollY, setScrollY] = useState("")
  // 表格数据计算
  const [tableDataCount, setTableDataCount] = useState({} as DataCountVO)
  // 搜索条件列表
  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入分群名称",
      title: "分群名称",
      key: "crowdName",
    },
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入分群ID",
      title: "分群ID",
      key: "crowdId",
    },
    {
      searchType: "text",
      searchComponentType: "select",
      mode: 'multiple',
      placeHolder: "请选择",
      title: "计算状态",
      key: "statusList",
      options: [
        { value: 1, label: '计算中' },
        { value: 2, label: '计算成功' },
        { value: 3, label: '计算失败' },
      ]
    },
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "分群状态",
      mode: 'multiple',
      key: "enableList",
      options: [
        { value: 0, label: '停用' },
        { value: 1, label: '启用' },
      ]
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "创建时间",
      key: "startTime-endTime",
    },
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "创建方式",
      mode: 'multiple',
      key: "createModeList",
      options: [
        { value: 1, label: '营销创建' },
        { value: 2, label: '规则创建' },
        { value: 3, label: 'RFM创建' },
        { value: 4, label: '导入创建' },
      ]
    },
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择",
      title: "更新方式",
      mode: 'multiple',
      key: "updateTypeList",
      options: [
        { value: 0, label: '定时更新' },
        { value: 3, label: '手动更新' },  //手动更新 需修改
        // { value: 1, label: '自动更新' },
        { value: 2, label: '不更新' },
      ]
    },
  ];
  // 表格
  const defaultColumns = [
    {
      title: "序号",
      dataIndex: "",
      key: "",
      width: "88px",
      fixed: "left",
      render: (_: any, record: any, i: number) => (
        <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>
      ),
    },
    {
      title: "分群名称",
      dataIndex: "crowdName",
      key: "crowdName",
      width: "200px",
      fixed: "left",
      render: (text: string, record: GroupListVO) =>
        <div className={styles.tableName} onClick={() => {
          editCrowd(record);
        }}>{text}</div>,
    },
    {
      title: "分群ID",
      dataIndex: "crowdId",
      key: "crowdId",
      width: "200px",
    },
    {
      title: "描述",
      dataIndex: "crowdDescribe",
      key: "crowdDescribe",
      // ellipsis: true,
      editable: true,
      width: '300px',
      render: (text: string, record: GroupListVO, index: number) =>
        <Tooltip placement="bottom" title={record.crowdDescribe}>
          <div style={{ display: 'flex', height: '45px', lineHeight: 'var(--text-font27)' }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeaver(index)}>
            <div style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{text}</div>
            {record.isShowEditIcon && <EditOutlined style={{ marginLeft: '10px' }} />}
          </div>
        </Tooltip>
    },
    {
      title: "分群人数",
      dataIndex: "groupNum",
      key: "groupNum",
      width: '200px',
      filters: [
        {
          text: '计算中',
          value: '1',
        },
        {
          text: '计算成功',
          value: '2',
        },
        {
          text: '计算失败',
          value: '3',
        },
      ],
      render: (text: string, record: GroupListVO) =>
        <div>
          {
            record.calculateStatus === 2 ?
              <div>
                <ButtonPermissions
                  type="link"
                  onClick={() => toUserDetail(record)}
                  title={text}
                // permissions={CROWD_KEY}
                ></ButtonPermissions>
                <br />
                <span style={{ whiteSpace: 'nowrap' }}>{record.calculateDate}</span>
              </div> : record.calculateStatus === 1 ?
                <div><Badge status="processing" text="计算中" /></div> : record.calculateStatus === 3 ?
                  <div>
                    <Badge status="error" text="计算失败" />
                    {/* <Tooltip placement="bottom" title={record.failMessage ? record.failMessage : '暂无原因'}>
                      <QuestionCircleOutlined style={{ marginLeft: '5px', cursor: 'pointer' }} />
                    </Tooltip> */}
                  </div> : <div>--</div>
          }
        </div>
    },
    {
      title: "分群状态",
      dataIndex: "enable",
      key: "enable",
      width: "150px",
      filters: [
        {
          text: '启用',
          value: '1',
        },
        {
          text: '停用',
          value: '0',
        },
      ],
      onFilter: (value: any, record: GroupListVO) => String(record.enable).indexOf(value) === 0,
      render: (_: any, record: any) => (
        <div className={styles[`accountStatus`]}>
          <Switch disabled={!checkPermissions(CROWD_KEY.UPDATA)} checkedChildren="启用" unCheckedChildren="停用" defaultChecked value={!!parseInt(_)} onChange={(checked, event) => {
            handleAccountStatusOnSelect(checked ? 1 : 0, record);
          }} />
          {/* <Select
            value={getAccountStatus(parseInt(_))}
            style={{ width: '60px', height: '22px' }}
            className={styles[`accountStatusType${_}`]}
            onSelect={(value, options) => { handleAccountStatusOnSelect(value, record); }}
            options={[
              { value: AccountStatusEnum.LOCKED, label: '停用', disabled: _ === 0 },
              { value: AccountStatusEnum.ENABLED, label: '启用', disabled: _ === 1 },
            ]}
          /> */}

          <Popconfirm
            placement="top"
            title={`确认${record.enable === 1 ? '停用' : '启用'}该分群吗？`}
            okText={tableConfig.okText}
            cancelText={tableConfig.cancelText}
            description={(record.enable === 1 && record.calculateStatus === 1) ? '正在计算中的任务将置为计算失败' : ''}
            onConfirm={() => handleAccountStatusChange(tablePopStatus, record)}
            onCancel={() => handleCancelClickPop()}
            open={selectedTableKey === record.id && tablePopConfirm}
            onPopupClick={(e) => {
              e.stopPropagation();
            }}
            okButtonProps={{ loading: false }}
          ></Popconfirm>
        </div>

      )
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      width: "200px",
      sorter: true,
    },
    {
      title: "创建方式",
      dataIndex: "type",
      key: "type",
      width: "200px",
      filters: [
        {
          text: '营销创建',
          value: '1',
        },
        {
          text: '规则创建',
          value: '2',
        },
        {
          text: 'RFM创建',
          value: '3',
        },
        {
          text: '导入创建',
          value: '4',
        },
      ],
      render: (text: number, record: GroupListVO) =>
        <div>
          <span>{text === 1 ? '营销创建' : text === 2 ? '规则创建' : text === 3 ? 'RFM创建' : '导入创建'}</span>
        </div>
    },
    {
      title: "更新方式",
      dataIndex: "updateType",
      key: "updateType",
      width: "200px",
      filters: [
        {
          text: '定时更新',
          value: '0',
        },
        {
          text: '手动更新',
          value: '3',
        },
        //手动更新 需修改
        // {
        //   text: '自动更新',
        //   value: '1',
        // },
        {
          text: '不更新',
          value: '2',
        },
      ],
      render: (text: string, record: GroupListVO) =>
        <div>
          <span>{text === '0' ? '定时更新' :text === '2' ? '不更新' :'手动更新'}</span>
          <br />
          <span style={{ fontSize: 'var(--text-font2)', color: 'var(--text-font-color4)' }}>{UpdateRate[record.updateRate]}</span>
        </div>
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_: any, record: GroupListVO) => (
        <Space>
          <Dropdown
            menu={{ items: getTableOperationButtonList(record) }}
            trigger={["click"]}
          >
            <Popconfirm
              placement="right"
              className={styles.popconfirmBox}
              title={confirmTitle}
              okText={tableConfig.okText}
              cancelText={tableConfig.cancelText}
              onConfirm={() => confirmType(record)}
              onCancel={() => handleCancelClick(record, tableConfig.type)}
              open={selectedTableKey === record.id && (operatePopConfirm || deletePopConfirm)}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
              okButtonProps={{ loading: false }}
            >
              <div
                onMouseLeave={handleMouseLeavePoint}
                onMouseEnter={() => handleMouseEnterPoint(record)}
                className={styles[`line-three-point`]}
                style={{ backgroundColor: checkUserId == record.id ? iconColorPointBack : '' }}
              >
                <EllipsisOutlined
                  style={{
                    color: checkUserId == record.id ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const handleSave = (row: DataType) => {
    const newData = [...listData];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setListData(newData);
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  // 操作列
  const getTableOperationButtonList = (item: GroupListVO) => {
    const store = []
     //手动更新 需修改
     if (item.updateType == '3') {
      store.push({
        key: 'updata',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              handelUpdata(item)
            }}
          >
            数据更新
          </a>
        ),
      })
    }
    if (checkPermissions(CROWD_KEY.DOWNLOAD) && item.calculateStatus === 2 && item.groupNum) {
      store.push({
        key: 'detail',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              downloadItem(item)
              // toUserDetail(item)
            }}
          >
            下载
          </a>
        ),
      })
    }
   
    if (checkPermissions(CROWD_KEY.GET)) {  //创建方式为规则创建
      store.push({
        key: 'log',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => { openLogs(item) }}
          >
            日志
          </a>
        ),
      })
    }

    if (item.type === 2 && checkPermissions(CROWD_KEY.ADD)) {  //创建方式为规则创建
      store.push(
        {
          key: 'copy',
          label: (
            <a
              style={{ color: 'var( --text-font-color9)' }}
              onClick={() => {
                copyRecord(item);
              }}
            >
              复制
            </a>
          ),
        },
      );
    };
    if (checkPermissions(CROWD_KEY.DEL)) {
      store.push(
        {
          key: 'del',
          label: (
            <a
              style={{ color: 'var( --text-font-color9)' }}
              onClick={() => {
                operateRecord(item, 'del')
              }}
            >
              删除
            </a>
          ),
        },
      );
    };
    return store;
  };
  //列表中二次确认框 取消之后
  const handleCancelClickPop = () => {
    setTablePopConfirm(false)
    setSelectedTableKey(null as unknown as number)
  }
  // 操作列确认弹框
  const handleCancelClick = (record: any, type: any) => {
    setOperatePopConfirm(false)
    setDeletePopConfirm(false)
  }
  // 描述单元格鼠标hover事件
  const handleMouseEnter = (index: number) => {
    const newData = [...listData];
    newData[index].isShowEditIcon = true
    setListData(newData)
  }

  const handleMouseLeaver = (index: number) => {
    const newData = [...listData];
    newData[index].isShowEditIcon = false
    setListData(newData)
  }
  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i: any) => {
    setCheckUserId(i.id)
    setIconColorPointBack('var(--text-font-color35)');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('')
    setIconColorPointBack('');
  };
  let operatePageNum: any
  let operatepageSize: any
  let preParams: any
  let a: any
  useEffect(() => {
    operatePageNum = Number(localStorage.getItem('pageNum'))
    operatepageSize = Number(localStorage.getItem('pageSize'))
    preParams = JSON.parse(localStorage.getItem('searchListData') as string)
    // console.log(preParams, 'preParams');
    localStorage.removeItem('pageNum')
    localStorage.removeItem('pageSize')
    localStorage.removeItem('searchListData')

    searchListData = {};
    if (operatePageNum || operatepageSize || preParams) {
      searchListData = preParams
      getUserData({ pageNum: operatePageNum, pageSize: operatepageSize }, preParams, '')
    } else {
      getUserData({ pageNum: pageQuery.pageNum, pageSize: pageQuery.pageSize }, searchListData, '')
    }
  }, [])

  // 数量查询
  const searchDataCount = async () => {
    const res = await dataCountApi({
      pageSize: pageQuery.pageSize,
      pageNum: pageQuery.pageNum,
    })
    setTableDataCount(res.data)
  }

  //获取账号状态
  const getAccountStatus = (status: number) => {
    switch (status) {
      case AccountStatusEnum.ENABLED:
        return AccountStatusTextEnum.ENABLED_TEXT
      case AccountStatusEnum.LOCKED:
        return AccountStatusTextEnum.LOCKED_TEXT
      default:
        break;
    }
  }

  // 获取数据列表
  const getUserData = async (pages: any, data: any, sort: any) => {

    const res = await getUserDataApi({
      pageSize: pages.pageSize,
      pageNum: pages.pageNum,
      ...data,
      orderType: sort
    })
    if (res) {
      setListData(res.data.rows)
      setPageQuery({
        pageNum: pages.pageNum,
        pageSize: pages.pageSize,
        total: res.data.total
      })
      // getTableScroll()
      setScrollY(getTableScroll())
      searchDataCount()
    }
  }

  // 日志
  const [crowIdRecord, setCrowIdRecord] = useState({} as GroupListVO)
  const openLogs = (info: any) => {
    setCrowIdRecord(info)
    setOpenLog(true)
  }

  // 在编辑页查看操作日志
  const viewOperateLog = (item: any) => {
    setCrowIdRecord(item)
    setOpenLog(true)
  };

  // 用户明细
  const toUserDetail = (info: any) => {
    localStorage.setItem('pageNum', String(pageQuery.pageNum))
    localStorage.setItem('pageSize', String(pageQuery.pageSize))
    localStorage.setItem('searchListData', JSON.stringify(searchListData))
    info = JSON.stringify(info)
    localStorage.setItem('setGroupDetail', info)
    navigate(`/userGrouping/groupingManagement/userGroupingList/userList?type=1`)
  }

  const handleAccountStatusOnSelect = (value: any, record: GroupListVO) => {
    if (record.type == 4 && record.calculateStatus === 1) {
      message.warning('当前分群任务计算中，分群状态暂不能修改')
      return
    }
    const index = listData.findIndex((it) => it.id === record.id)
    const id = listData[index].id
    setTablePopStatus(value)
    setTablePopConfirm(true)
    setSelectedTableKey(id)
  }

  // 操作列表 删除、启用、禁用
  const operateRecord = (info: any, type: string) => {
    setSelectedTableKey(info.id)
    operateType = type;
    if (type === 'del') {
      setDeletePopConfirm(true)
      setConfirmTitle('确定删除此分群吗？')
    } else if (type === 'enable') {
      setOperatePopConfirm(true)
      setConfirmTitle('确定启用此分群吗？')
    } else if (type === 'lock') {
      setOperatePopConfirm(true)
      setConfirmTitle('确定停用此分群吗？')
    }

  }

  // 删除
  const [showErrModal, setShowErrModal] = useState(false)
  const handDeleteRecord = async (record: GroupListVO) => {
    setDeletePopConfirm(false)
    const res = await deleteGroupApi({
      crowdId: record.crowdId
    })
    if (res) {
      if (res.data.delStatus) {
        message.success('删除成功')
      } else {
        // setShowErrModal(true)
        message.warning('该分群正在被应用，不允许删除')
      }

      getUserClickApi(state.childMenuId, 3, record.crowdId, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
      getUserData({ pageNum: pageQuery.pageNum, pageSize: pageQuery.pageSize }, searchListData, '')
    }
  }

  const confirmType = (record: GroupListVO) => {
    if (operateType === 'enable' || operateType === 'lock') {
      handleAccountStatusChange(record.enable === 1 ? 0 : 1, record)
    } else {
      handDeleteRecord(record)
    }
  }

  // 启用、停用二次确认
  const handleAccountStatusChange = async (tablePopStatus: any, record: GroupListVO) => {
    setTablePopConfirm(false)
    setOperatePopConfirm(false)
    const res = await enableDisableApi({
      crowdId: record.crowdId,
      crowdStatus: tablePopStatus,
      isFinalConfirm: true,
    })
    if (res) {
      message.success(res.msg)
      getUserClickApi(state.childMenuId, 3, record.crowdId, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')
      getUserData({ pageNum: pageQuery.pageNum, pageSize: pageQuery.pageSize }, searchListData, '')
    }

  }
  const [listData, setListData] = useState([] as GroupListVO[]);
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 复制
  const copyRecord = (info: GroupListVO) => {
    // 仅创建方式为“规则创建”的，可以复制。点击复制后，打开新建分群页面，各项默认加载原值
    localStorage.setItem('pageNum', String(pageQuery.pageNum))
    localStorage.setItem('pageSize', String(pageQuery.pageSize))
    localStorage.setItem('searchListData', JSON.stringify(searchListData))
    setEditData(info);
    if (info.type === 2) {
      navigate('/userGrouping/groupingManagement/createUserGrouping', { state: { flag: 'copy', editData: info } })
    } else {
      navigate('/userGrouping/groupingManagement/createUserGroupingChild', { state: { flag: 'copy', editData: info } })
    }
  }
  const [chooseGroup, setChooseGroup] = useState(false); // 选择新建分群弹窗
  // 新建用户组
  const addGroups = () => {
    const count: any = tableDataCount.totalNum;
    if (count === 300) {
      message.error('分群总额度达到上限，请删除不常用或未被应用分群');
    } else {
      setChooseGroupData({ flag: 'create', type : 2} )
      setChooseGroup(true)
      // navigate('/userGrouping/groupingManagement/CreateChooseGroup', { state: { flag: 'create' ,type : 2} })
      // navigate('/userGrouping/groupingManagement/createUserGrouping', { state: { flag: 'create' } })
    }
  }
  // 关闭下载抽屉
  const colseChooseGroup= (v: boolean) => {
    setChooseGroup(v);
  };
  // 关闭日志
  const getOpenlog = (log: boolean) => {
    setOpenLog(log)
  }
  // 查询按钮
  const filterFinish = (data: any) => {
    searchListData = data;
    // setSearchListData(data)
    setPageQuery({
      pageNum: 1,
      pageSize: 10,
      total: pageQuery.total
    })
    getUserData({ pageNum: 1, pageSize: 10 }, data, '')
  }
  // 分页 筛选 排序
  const handleStandardTableChange = (page: any, filterValue: any, sorter: any, extra: any) => {
    // console.log(extra, 'extra');
    if (extra.action === 'paginate') {
      setPageQuery({
        ...pageQuery,
        pageSize: page.pageSize,
        pageNum: page.current
      })
    }
    if (extra.action === 'sort') {
      if (!sorter.order) {
        sortType = '';
      } else {
        sortType = sorter.order === 'ascend' ? 'ASC' : 'DESC';
      }
    }
    if (extra.action === 'filter') {
      if (filterValue.groupNum) {
        searchListData.statusList = filterValue.groupNum
      } else {
        searchListData.statusList = null
      }
      if (filterValue.enable) {
        searchListData.enableList = filterValue.enable
      } else {
        searchListData.enableList = null
      }
      if (filterValue.type) {
        searchListData.createModeList = filterValue.type
      } else {
        searchListData.createModeList = null
      }
      if (filterValue.updateType) {
        searchListData.updateTypeList = filterValue.updateType
      } else {
        searchListData.updateTypeList = null
      }
    }
    getUserData({ pageNum: page.current, pageSize: page.pageSize }, searchListData, sortType ? sortType :'' )

  }

  // 编辑分群
  const [editData, setEditData] = useState({} as GroupListVO);
  const editCrowd = (val: GroupListVO) => {
    // 缓存跳页信息，从编辑页回来之后自动从该页查询
    localStorage.setItem('pageNum', String(pageQuery.pageNum))
    localStorage.setItem('pageSize', String(pageQuery.pageSize))
    localStorage.setItem('searchListData', JSON.stringify(searchListData))

    setEditData(val);
    if (val.type === 2) {
      navigate('/userGrouping/groupingManagement/createUserGrouping', { state: { flag: 'view', editData: val } })
    } else if(val.type === 4){
      //是否是手动更新  需修改
      navigate('/userGrouping/groupingManagement/CreateExportMain', { state: { flag: 'view' , editData: val ,type : 2 } })
    } else {
      navigate('/userGrouping/groupingManagement/createUserGroupingChild', { state: { flag: 'view', editData: val } })
    }
    getUserClickApi(state.childMenuId, 3, val.crowdId, undefined, state.parentMenuId, '/userGrouping/groupingManagement/userGroupingList/userList?type=1')

  };

  //抽屉  true  开启
  const [openDownloadPop, setOpenDownloadPop] = useState(false);
  // 打开下载抽屉
  const downloadItem = (info: GroupListVO) => {
    setCrowIdRecord(info)
    setOpenDownloadPop(true);
  };
  // 关闭下载抽屉
  const closeDownLoadItem = (v: boolean) => {
    setOpenDownloadPop(v);
  };

  // 创建&编辑成功之后刷新列表
  // const operateSuccess = () => {
  //   getUserData();
  // }
  const getHeight = (e: string) => {
    // console.log(e);
    setScrollY(e)

  }
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  }
  //数据更新
  const dataUpdata = async (id:any) => {
    let param = {
      crowdId: id
    };
    const res = await crowdUpdata(param);
    if (res) {
      message.success('数据更新成功')
      getUserData({ pageNum: 1, pageSize: 10 }, searchListData, '')
    }
  };
  //抽屉  true  开启导入文件
  const [openImportMes, setOpenImportMes] = useState(false);
  //手动更新
  const handelUpdata = (item:any) => {
    if (item.calculateStatus === 1) {
      message.error('分群计算中，请稍后重试');
    } else if (item.type === 4){
      setCrowIdRecord(item)
      setOpenImportMes(true)
    } else{
      dataUpdata(item.crowdId)
    }
  }
  // 关闭手动更新抽屉
  const closeOpenImportMes = (v: boolean) => {
    setOpenImportMes(v);
  };
  // 数据更新追加之后重新调取接口
  const handleAddFile = () => {
    getUserData({ pageNum: 1, pageSize: 10 }, searchListData, '')
  };

  
  return (
    <div className='management'>
      <div className='headBox'>
        <div className={styles.titleText}>
          <ModuleTitle mark="large" title="客户分群" />
          <div className={styles.dataTip}>当前分群总计<span style={{ color: 'var(--text-font-color7)' }}>{tableDataCount.totalNum ? tableDataCount.totalNum : '0'}</span>个，其中计算成功<span style={{ color: 'var(--text-font-color19)' }}>{tableDataCount.successNum ? tableDataCount.successNum : '0'}</span>个，计算失败<span style={{ color: 'var(--required)' }}>{tableDataCount.failNum ? tableDataCount.failNum : '0'}</span>个，计算中<span style={{ color: 'var(--label6)' }}>{tableDataCount.calculatingNum ? tableDataCount.calculatingNum : '0'}</span>个</div>
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            placeholder="请输入分群名称或分群ID"
            onSearch={(e: string) => { searchListData.crowdNameAndId = e; getUserData({ pageNum: 1, pageSize: 10 }, searchListData, ''); filterViewRef.current?.resetData(); }}
          ></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
          <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span>
          <ButtonPermissions
            type="primary"
            onClick={addGroups}
            title={`新建分群 ${tableDataCount.totalNum ? tableDataCount.totalNum : '0'}/${300}`}
            permissions={CROWD_KEY.ADD}
          ></ButtonPermissions>
        </div>
      </div>
      <div className='containerBox'>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          getHeight={getHeight}
        ></FilterView>
        <div className={styles.tableBox}>
          <Table
            style={{ width: "100%" }}
            columns={columns as any}
            components={components}
            dataSource={listData}
            scroll={{ x: 1750, y: scrollY }}
            pagination={{
              current: pageQuery.pageNum,
              pageSize: pageQuery.pageSize,
              total: pageQuery.total,
              showTotal: (total) => `共${total}条`,
              showSizeChanger: true,
            }}
            // onChange={handleStandardTableChange}
            onChange={(page, filters, sort, extra) => handleStandardTableChange(page, filters, sort, extra)}
            rowClassName={() => 'editable-row'}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
      <OperateLog
        openLog={openLog}
        getOpenlog={getOpenlog}
        crowInfo={crowIdRecord}
      />
      <DownloadUserDeatil
        searcParam={searchListData}
        openDownloadPop={openDownloadPop}
        closeDownLoadItem={closeDownLoadItem}
        crowdId={crowIdRecord.crowdId}
      />
      <Modal title="提示" open={showErrModal} footer={null} onCancel={() => setShowErrModal(false)}>
        <p>该分群正在被应用，不允许删除</p>
      </Modal>
      <ImportMes
          openImportMes={openImportMes}
          closeOpenImportMes={closeOpenImportMes}
          popType={2}
          fileTitle={crowIdRecord.crowdName}
          crowdId={crowIdRecord.crowdId}
          handleAddFile={handleAddFile}
      />
      <CreateChooseGroup
          chooseGroupData={chooseGroupData}
          chooseGroup={chooseGroup}
          colseChooseGroup={colseChooseGroup}
        />
    </div>
  )
}
export default UserGroupingManagement;