import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Form, Input, Select, Button, message } from 'antd';
import { CreateDataAccessApi, CheckAccessApi, ShopIdListApi } from "../../../../../../api/collect/collect";
import PromitModal from '../PromitModal'

const { Search } = Input;

const ConfigureAccessInformation = React.forwardRef((props: { selectedPlatformId: string, platformMethodList: any, submitSuccess: Function, accessTestClick?: Function }, ref: any) => {

  const { selectedPlatformId, platformMethodList, submitSuccess = () => { }, accessTestClick = () => { } } = props;

  const promitModalRef: any = useRef(null);

  const [loading, setLoading] = useState(true);

  const [sourceData, setSourceData] = useState();

  const [accountForm] = Form.useForm()

  type FieldType = {
    accessName?: string;
    platformType?: string;
    shopId?: string;
  };

  const formData: FieldType = {
    accessName: '',
    platformType: '',
    shopId: ''
  };

  // 表单数据
  const [conditions, setConditions] = useState(formData);
  const changeConditions = (newConditions: any) => {
    if (newConditions[Object.keys(newConditions)[0]] === '') {
      accessTestClick(true);
    }
    setConditions({
      ...conditions,
      ...newConditions
    })
  };

  // 店铺ID
  const [shopIdList, setShopIdList] = useState([]);
  const getShopIdList = async (p: string) => {
    const param = {
      platformType: p,
    };
    const { data } = await ShopIdListApi(param);

    data.forEach((v: any, i: number) => {
      v.value = v.shopId;
      v.label = v.shopName;
    });

    setShopIdList(data);
  };

  // 提示信息
  const [promitTitle, setPromitTitle] = useState('');
  const [promitContent, setPromitContent] = useState('');
  const [hasReason, setHasReason] = useState(false);

  // 连接测试
  const toText = async () => {
    if (!conditions.accessName ||
      !conditions.platformType ||
      !conditions.shopId) {
      message.error('必填项未填写完整');
      return
      // setPromitTitle('必填项未填写完整');
      // setHasReason(true);
    }

    if (!conditions.accessName) {
      setPromitContent('数据接入名称未填写');
      promitModalRef.current.openModal();
      return;
    } else {
      const p = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
      if (p.test(conditions.accessName)) {
        message.error('数据接入名称不支持特殊字符，请重新填写')
        // setPromitContent('数据接入名称不支持特殊字符，请重新填写');
        // promitModalRef.current.openModal();
        return;
      }
    }

    if (!conditions.platformType) {
      setPromitContent('未选择平台类型');
      promitModalRef.current.openModal();
      return;
    }

    if (!conditions.shopId) {
      setPromitContent('未选择店铺ID');
      promitModalRef.current.openModal();
      return;
    }

    if (loading) {
      setLoading(false);
      const param = {
        platformType: conditions.platformType,
        shopId: conditions.shopId
      };

      const res = await CheckAccessApi(param);
      setLoading(true);
      if (res && res.code === 505) {
        accessTestClick(true);
        message.error(res.msg);
        // setPromitTitle('连接测试失败');
        // setPromitContent(res.msg);
        // promitModalRef.current.openModal();
        // setHasReason(true);
      } else if (res) {
        message.success('连接测试成功');
        accessTestClick(false);
        // setPromitTitle('连接测试成功');
        // setPromitContent('点击【保存】按钮，开始进行数据接入');
        // promitModalRef.current.openModal();
        // setHasReason(false);
      }
    }
  };

  const getShopName = (f: string) => {
    let obj: any = {};
    obj = shopIdList.find((v: any, i: number) => v.value === f);
    return obj.label
  };

  // 创建接入数据
  const createDataAccess = async () => {
    if (loading) {
      setLoading(false);
      const param = {
        createWay: selectedPlatformId,
        accessName: accountForm.getFieldValue('accessName'),
        platformType: accountForm.getFieldValue('platformType'),
        shopId: Number(accountForm.getFieldValue('shopId')),
        shopName: getShopName(accountForm.getFieldValue('shopId'))
      };
      const res = await CreateDataAccessApi(param);
      setLoading(true);
      if (res.code === 505) {
        message.error(res.msg);
        // setPromitTitle('保存失败');
        // setPromitContent(res.msg);
        // setHasReason(true);
        // promitModalRef.current.openModal();
      } else {
        message.success('保存成功');
        // setPromitTitle('');
        // setPromitContent('保存成功，数据接入中');
        // setHasReason(false);
        // promitModalRef.current.openModal();
        setTimeout(() => {
          // promitModalRef.current.handleCancel();
          submitSuccess();
        }, 3000)
      }
    }
  };

  useImperativeHandle(ref, () => ({
    createDataAccess
  }))

  useEffect(() => {
  }, []);
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <div style={{ marginTop: 24 }}>
      <Form layout="vertical" form={accountForm}>
        <Form.Item<FieldType>
          label="数据接入名称"
          name="accessName"
          rules={[{ required: true, message: '请输入数据接入名称' }]}
        >
          <Input
            placeholder="请输入数据接入名称"
            showCount
            maxLength={20}
            onChange={(e) => changeConditions({ accessName: e.target.value })}
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="平台类型"
          name="platformType"
          rules={[{ required: true, message: '请输入平台类型' }]}
        >
          <Select
            placeholder="请选择平台类型"
            onChange={(e) => {
              changeConditions({ platformType: e, shopId: '' });
              accountForm.setFieldValue('shopId', '')
              getShopIdList(e)
            }

            }
            options={platformMethodList}
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="店铺ID"
          name="shopId"
          rules={[{ required: true, message: '请输入店铺ID' }]}
        >
          <Select
            showSearch
            placeholder="请选择店铺ID"
            onChange={(e) => changeConditions({ shopId: e })}
            filterOption={filterOption}
            options={shopIdList}
            optionRender={(item) => {
              return <>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <span>
                    {item.label}
                  </span>
                  <span>
                    {item.value}
                  </span>
                </div>
              </>
            }}
            labelRender={(item) => {
              return <>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <span>
                    {item.label}
                  </span>
                  <span>
                    {item.value}
                  </span>
                </div>
              </>
            }}
          />
        </Form.Item>
      </Form>
      <div>
        <Button type="primary" onClick={toText} >
          连接测试
        </Button>
      </div>
      <PromitModal ref={promitModalRef} promitTitle={promitTitle} promitContent={promitContent} hasReason={hasReason} />
    </div>
  );
});

export default ConfigureAccessInformation;
