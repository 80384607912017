import React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Select,
  Input,
  Tag,
  Drawer,
  Space,
  Form,
  Table,
  Radio,
  DatePicker,
  Tabs,
  TreeSelect,
  Tree,
  Popconfirm,
  message,
  Tooltip,
  Row,
  Col,
} from 'antd';
import type { TableColumnsType, RadioChangeEvent, GetProps, TabsProps, TreeProps, TreeDataNode } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dayjs from 'dayjs';
import { CloseOutlined, PlusOutlined, QuestionCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import ModuleTitle from '../../../../component/ModuleTitle';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import FilterView from '../../../../component/FilterView/filterView';
import BackList from '../../../../component/BackList/backList';
import Behavior from '../../../Tag/RuleTags/CreateLabel/component/Behavior';
import RuleGroupsSetting from '../../../Tag/RuleTags/CreateLabel/component/RuleGroupsSetting';

import { queryListApi } from '../../../../api/label/label';
import { getUserPropertyListApi } from '../../../../api/userCenter/userCenter';
import { getUserDataApi } from '../../../../api/userGroup/group';
import {
  statisticsUserGroup,
  saveUserGroup,
  updateReportForm,
  queryPreviewChart,
  queryReportList,
  queryAllCrowd,
  queryReportFormDetail,
} from '../../../../api/UserInsight/UserInsight';

import styles from './index.module.scss';
import ChartData from '../component/ChatData/ChartData';
import busEvent from '@/utils/bus';
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const { Search } = Input;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
const options = [
  { label: '定时', value: '0' },
  { label: '手动', value: '1' },
];
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
//编辑回显的数据
let editData: any = [];
//记录人群id
let crowdIds: any = [];
//记录对比人群id
let compareCrowdIds: any = [];
//记录标签改变的值
let checkedNodes: any = [];
//预览的报告数组
let reportChats: any = [];
const GroupUserInsight = () => {
  const navigate = useNavigate();
  let location = useLocation();
  // 人群抽屉开关
  const [open, setOpen] = useState(false);
  const [portraitType, setPortraitType] = useState('1');
  // 画像抽屉开关
  const [portraitOpen, setPortraitOpen] = useState(false);
  const [reportForm] = Form.useForm();
  const formData = {
    reportName: '',
    updateModel: '0',
    rangeDate: [],
  };
  const [conditions, setConditions] = useState(JSON.parse(JSON.stringify(formData)));
  const searchParamInt: any = {
    // crowdName: '',
    // createMode: '',
    // minCrowdNum: 0,
    // maxCrowdNum: 0,
  };
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });
  const filterViewRef: any = useRef(null);
  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入人群名称',
      title: '人群名称',
      key: 'crowdName',
    },
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '创建方式',
      key: 'createMode',
      options: [
        {
          value: '1',
          label: '营销创建',
        },
        {
          value: '2',
          label: '规则创建',
        },
        {
          value: '3',
          label: 'RFM创建',
        },
        {
          value: '4',
          label: '导入创建',
        },
      ],
    },
    {
      searchType: 'text',
      searchComponentType: 'inputDouble',
      addonAfter: '',
      placeHolder: '请输入',
      title: '人群数量',
      key: 'minCrowdNum,maxCrowdNum',
    },
  ];
  const [dataList, setDataList] = useState([]);
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  //选择人群
  const [selectType, setSelectType] = useState<any>(1);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [peopleRowKeys, setPeopleRowKeys] = useState<any>([]);
  const [peopleNames, setPeopleNames] = useState('');
  const [statisticsObj, setStatisticsObj] = useState<any>({
    userNum: 0,
    userRate: 0,
    compareUserNum: 0,
    compareUserRate: 0,
  });
  //选择对比人群
  const [peopleCompareRowKeys, setPeopleCompareRowKeys] = useState<any>([]);
  const [peopleCompareNames, setPeopleCompareNames] = useState('');
  const columns: TableColumnsType<any> = [
    {
      title: '人群名称',
      dataIndex: 'crowdName',
    },
    {
      title: '人群描述',
      dataIndex: 'crowdDescribe',
    },
    {
      title: '创建方式',
      dataIndex: 'createMode',
      render: (_: any) => (
        // 1 营销创建、2规则创建、3RFM创建  4 导入创建
        <div>{_ === '1' ? <span>营销创建</span> : _ === '2' ? <span>规则创建</span> : _ === '3' ? <span>RFM创建</span> : <span>导入创建</span>}</div>
      ),
    },
    {
      title: '人群数量',
      dataIndex: 'groupNum',
      render: (_: any) => <div>{Number(_).toLocaleString()}</div>,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
    },
  ];
  //客户标签列表
  const [tagList, setTagList] = useState<any>([]);
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const [optionsValue, setOptionsValue] = useState<any>([]);
  const searchRef: any = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const searchChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  //基础属性
  const [userPropertyRules, setUserPropertyRules] = useState<any>([]);
  const [baseOption, setBaseOption] = useState<any>([]);
  //预览开关
  const [chartOpen, setChartOpen] = useState(false);

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  //校验数据
  const checkFormData = () => {
    console.log('checkFormData', tagList, userPropertyRules, paramsList, conditions);
    let f: boolean = true;

    userPropertyRules.forEach((item: any, index: any) => {
      if (!item.row && !item.col) {
        message.error('行列属性至少选择一项');
        return false;
      }
      if (item.row.field == item.col.field) {
        //行列相同，只展示一个维度
        item.statsValue = '0';
      } else {
        item.statsValue = '1';
      }
    });
    if (hasDuplicates(userPropertyRules)) {
      message.error('基础属性不能重复');
      return false;
    }

    const store: any = paramsList.map((v: any, i: number) => v);
    checkFillIn: for (var i = 0; i < store.length; i++) {
      for (var y = 0; y < store[i].rules.rules.length; y++) {
        if (store[i].rules.rules[y].rules_value.length > 20) {
          message.warning('规则组名称最多20个字符');
          f = false;
          break checkFillIn;
        } else {
          for (var z = 0; z < store[i].rules.rules[y].rules?.length; z++) {
            if (!store[i].rules.rules[y].rules[z].rules_Label_value) {
              message.warning('请填写完整规则');
              f = false;
              break checkFillIn;
            }

            if (store[i].rules.rules[y].rules[z].rules_Label_value === '3') {
              if (store[i].rules.rules[y].rules[z].behavior_field_id === null) {
                f = false;
                message.warning('请选择用户行为');
                break checkFillIn;
              }

              if (store[i].rules.rules[y].rules[z].behavior === '做过' && store[i].rules.rules[y].rules[z].statistics_type === '总次数') {
                if (store[i].rules.rules[y].rules[z].operator_id === null) {
                  f = false;
                  message.warning('请选择运算条件');
                  break checkFillIn;
                }
                if (store[i].rules.rules[y].rules[z].value === null || store[i].rules.rules[y].rules[z].value === '') {
                  f = false;
                  message.warning('请填写用户行为次数值');
                  break checkFillIn;
                }
                if (store[i].rules.rules[y].rules[z].operator_id === 7) {
                  // 区间
                  if (store[i].rules.rules[y].rules[z].max === '' || store[i].rules.rules[y].rules[z].min === '') {
                    f = false;
                    message.warning('请填写用户行为次数值');
                    break checkFillIn;
                  } else {
                    if (Number(store[i].rules.rules[y].rules[z].max) < Number(store[i].rules.rules[y].rules[z].min)) {
                      f = false;
                      message.warning('行为次数区间请按照从小到大的顺序填写');
                      break checkFillIn;
                    }
                  }
                }
              }
              if (store[i].rules.rules[y].rules[z].rules) {
                for (var s = 0; s < store[i].rules.rules[y].rules[z].rules.rules.length; s++) {
                  if (store[i].rules.rules[y].rules[z].rules.rules[s].field_id === null) {
                    f = false;
                    message.warning('请选择事件属性');
                    break checkFillIn;
                  }
                  if (
                    store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 5 &&
                    store[i].rules.rules[y].rules[z].rules.rules[s].operator_id !== 6
                  ) {
                    if (
                      store[i].rules.rules[y].rules[z].rules.rules[s].value === null ||
                      store[i].rules.rules[y].rules[z].rules.rules[s].value === ''
                    ) {
                      f = false;
                      message.warning('请选择/填写用户行为值');
                      break checkFillIn;
                    }
                  }
                  if (store[i].rules.rules[y].rules[z].rules.rules[s].operator_id === 7) {
                    if (store[i].rules.rules[y].rules[z].rules.rules[s].max === '' || store[i].rules.rules[y].rules[z].rules.rules[s].min === '') {
                      f = false;
                      message.warning('请填写事件属性值区间');
                      break checkFillIn;
                    } else {
                      if (Number(store[i].rules.rules[y].rules[z].rules.rules[s].max) < Number(store[i].rules.rules[y].rules[z].rules.rules[s].min)) {
                        f = false;
                        message.warning('事件属性值区间请按照从小到大的顺序填写');
                        break checkFillIn;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return f;
  };
  const onFinish = async (values: any) => {
    console.log('onFinish', values, tagList, userPropertyRules);
    const res = checkFormData();
    if (res) {
      userPropertyRules.forEach((item: any, index: any) => {
        userPropertyRules[index].title = item.row.fieldName + ' × ' + item.col.fieldName + '的交叉分析';
      });
      paramsList[0].rules.rules[0].rules.forEach((item: any, index: any) => {
        let title = '在';
        if (item.time_value) {
          title += item.time_value;
        } else {
          title += '不限时间';
        }
        console.log('item', item);
        title += item.behavior + item.behavior_field_value + item.statistics_type;
        title += item.operator_id == 7 ? item.min + '到' + item.max : item.operator_value + item.value;
        if (item.rules && item.rules.rules.length > 0) {
          title += '且';
          item.rules.rules.forEach((subItem: any, index: any) => {
            title += subItem.field_value;
            if (subItem.operator_id == 7) {
              title += subItem.min + '到' + subItem.max;
            } else if (
              subItem.field_type !== 'String' &&
              subItem.field_type !== 'Date' &&
              subItem.field_type !== 'Year' &&
              subItem.field_type !== 'Month' &&
              subItem.field_type !== 'Long' &&
              subItem.field_type !== 'Phone' &&
              subItem.field_type !== 'Decimal'
            ) {
              title += subItem.operator_value + subItem.value_select_value;
            } else {
              title += subItem.operator_value + subItem.value;
            }
          });
        }
        title += '的行为分析';
        paramsList[0].rules.rules[0].rules[index].title = title;
      });
      let userBehaviorRulesJsonString: any = [];
      let userPropertyRulesJsonString: any = [];
      paramsList[0].rules.rules[0].rules.forEach((item: any, index: any) => {
        userBehaviorRulesJsonString.push(JSON.stringify(item));
      });
      userPropertyRules.forEach((item: any) => {
        userPropertyRulesJsonString.push(JSON.stringify(item));
      });
      const params = {
        ...conditions,
        ...values,
        crowdIds,
        compareCrowdIds,
        labelIds: tagList.map((i: any) => i.labelId),
        userBehaviorRules: userBehaviorRulesJsonString,
        userPropertyRules: userPropertyRulesJsonString,
        ...statisticsObj,
        userLabelRules: [],
      };

      delete params.tagList;
      delete params.rangeDate;
      // setConditions(params);
      delete params.group;
      if (location.state.type === '1') {
        console.log('新增 接口传参', params);
        params.labelIds.forEach((item: any) => {
          params.userLabelRules.push(
            JSON.stringify({
              sortType: '0',
              type: 'column',
              title: tagList.filter((i: any) => i.labelId == item)[0].labelName,
              bean: '0',
              portraitType: '0',
              labelId: item,
            })
          );
        });
        //新增
        const res = await saveUserGroup(params);
        if (res) {
          message.success('新增成功');
          navigate('/userInsight/GroupUserInsight/GroupUserInsight', {});
        }
      } else {
        params.labelIds.forEach((item: any) => {
          params.userLabelRules.push(
            JSON.stringify({
              sortType:
                reportChartData.filter(
                  (ele: any) => ele.portraitType == '0' && ele.title == tagList.filter((i: any) => i.labelId == item)[0].labelName
                )[0]?.sortType || '0',
              type:
                reportChartData.filter(
                  (ele: any) => ele.portraitType == '0' && ele.title == tagList.filter((i: any) => i.labelId == item)[0].labelName
                )[0]?.type || 'column',
              title: tagList.filter((i: any) => i.labelId == item)[0].labelName,
              bean: '0',
              portraitType: '0',
              labelId: item,
            })
          );
        });
        //编辑
        editData = {
          ...editData,
          ...params,
          crowdIds:
            crowdIds.length > 0
              ? crowdIds
              : editData.userCrowds.map((item: any) => {
                  return item.crowdId;
                }),
          compareCrowdIds:
            compareCrowdIds.length > 0
              ? compareCrowdIds
              : editData.compareUserCrowds?.map((item: any) => {
                  return item.crowdId;
                }),
        };
        console.log('编辑 接口传参', params);
        const res = await updateReportForm(editData);
        if (res) {
          message.success('更新成功');
          navigate(-1);
        }
      }
    }
  };
  const hasDuplicates = (arr: any) => {
    const seen = new Set();
    for (const item of arr) {
      if (seen.has(JSON.stringify(item))) {
        return true;
      }
      seen.add(JSON.stringify(item));
    }
    return false;
  };

  // 换页
  const handleTableChange = (page: any) => {
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize,
    });
    getUserData({ pageSize: page.pageSize, pageNum: page.current }, searchParam);
  };
  // 打开当前抽屉
  const onOpen = (type: any) => {
    setSelectType(type);
    setOpen(true);
    filterViewRef.current?.resetData();

    getUserData({ pageSize: 10, pageNum: 1 }, searchParamInt);
    if (type == 1) {
      setSelectedRowKeys(peopleRowKeys);
    } else {
      setSelectedRowKeys(peopleCompareRowKeys);
    }
  };
  // 关闭当前抽屉
  const onClose = () => {
    if (portraitOpen) {
      setPortraitOpen(false);
    } else {
      setDataList([]);
      setOpen(false);
      setSelectedRowKeys([]);
    }
  };

  const filterFinish = (data: any) => {
    setSearchParam(data);
    console.log('filterFinish', data);
    getUserData({ pageSize: 10, pageNum: 1 }, data);
  };
  // 获取人群数据列表
  const getUserData = async (page: { pageSize: number; pageNum: number }, data: any) => {
    //queryReportList
    const res = await queryReportList({
      ...page,
      ...data,
      crowdTypes: [],
    });
    if (res) {
      setDataList(
        res.rows.map((item: any) => {
          return {
            ...item,
            key: item.crowdId,
          };
        })
      );
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.total,
      });
    }
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    // getCheckboxProps: (record: DataType) => ({
    //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  //计算人群
  const calcPeople = async () => {
    if (selectType == 1) {
      compareCrowdIds = [];
      crowdIds = selectedRows.map((item: any) => {
        return item.crowdId;
      });
      if (crowdIds.length == 0) {
        reportForm.setFieldValue('group', '');
        setPeopleRowKeys([]);
        setPeopleNames('');
        return;
      }
    } else {
      compareCrowdIds = selectedRows.map((item: any) => {
        return item.crowdId;
      });
      if (compareCrowdIds.length == 0) {
        setPeopleCompareNames('');
        setPeopleCompareRowKeys([]);
        return;
      }
    }
    const params = {
      crowdIds: selectType == 1 ? crowdIds : compareCrowdIds,
    };
    const res = await statisticsUserGroup(params);
    if (res) {
      if (selectType == 1) {
        setStatisticsObj({
          ...statisticsObj,
          userNum: res.data.userNum,
          userRate: res.data.userRate,
        });
      } else {
        setStatisticsObj({
          ...statisticsObj,
          compareUserNum: res.data.userNum,
          compareUserRate: res.data.userRate,
        });
      }
      const names = selectedRows
        .map((item: any) => {
          return item.crowdName;
        })
        .join('、');
      if (selectType == 1) {
        setPeopleRowKeys(selectedRowKeys);
        setPeopleNames(names);
        reportForm.setFieldValue('group', selectedRows);
        reportForm.validateFields(['group']);
      } else {
        setPeopleCompareRowKeys(selectedRowKeys);
        setPeopleCompareNames(names);
      }
    }
  };

  const onWayChange = ({ target: { value } }: RadioChangeEvent) => {
    setConditions({
      ...conditions,
      updateModel: value,
    });
    reportForm.setFieldValue('updateModel', value);
  };
  const onDateChange = (date: any, dateString: any) => {
    console.log(date, dateString);
    if (date) {
      reportForm.setFieldValue('rangeDate', [dayjs(dateString[0], dateFormat), dayjs(dateString[1], dateFormat)]);
    } else {
      reportForm.setFieldValue('rangeDate', []);
    }
    setConditions({
      ...conditions,
      beginTime: dateString[0],
      endTime: dateString[1],
      rangeDate: date ? [dayjs(dateString[0], dateFormat), dayjs(dateString[1], dateFormat)] : [],
    });
  };
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };
  const searchTag = (labelName: any) => {
    console.log('searchTag', labelName);
    // searchText = labelName
    if (!labelName) {
      return;
    }
    const arr = [] as any[];
    const dataModel = JSON.parse(JSON.stringify(optionsValue));
    console.log(dataModel);
    dataModel.map((t: any) => {
      let category = JSON.parse(JSON.stringify(t));
      if (t.children && t.children.length > 0) {
        let b = [] as any[];
        t.children.map((s: any) => {
          let label = JSON.parse(JSON.stringify(s));
          if (s.children && s.children.length > 0) {
            let a = [] as any[];
            s.children.map((m: any) => {
              if (m.labelName.indexOf(labelName) > -1) {
                a.push(m);
              }
            });

            s.children = a.length > 0 ? a : [];
          }
          if (s.children.length > 0) {
            b.push(s);
          }
        });
        t.children = b.length > 0 ? b : [];
      }
      if (t.children.length > 0) {
        arr.push(t);
      }
    });
    arr.map((item: any, index: number) => {
      item.title = item.categoryName;
      item.key = item.id;
      item.floor = 0;
      item.position = `${index}`;
      // item.children = item.children || []
      if (item.children.length > 0) {
        item.children.map((t: any, i: number) => {
          t.title = t.categoryName;
          t.key = t.id;
          t.floor = 1;
          t.position = `${index}-${i}`;
          // t.children = t.children || []
          t.parentId = item.id;
          if (t.children.length > 0) {
            t.children.map((m: any, n: number) => {
              m.title = m.labelName;
              m.key = m.labelId;
              m.floor = 2;
              m.position = `${index}-${i}-${n}`;
              m.parentId = t.id;
            });
          }
        });
      }
    });
    console.log(arr);
    if (arr.length === 0) {
      message.warning('该标签不存在，请重新搜索');
    }
    setOptionsValue(arr);
  };

  // 获取标签类目
  const getCategoryList = async (creater?: string) => {
    setOptionsValue([]);
    const param: any = {
      calculateStatus: '2',
    };
    const res = await queryAllCrowd(param);
    let categoryList = JSON.parse(JSON.stringify(res.data));
    categoryList.map((item: any, index: number) => {
      item.title = item.categoryName;
      item.key = item.id;
      item.children = item.userCrowdViewVos || [];
      if (item.children?.length > 0) {
        let disableNum = 0;
        item.children.map((t: any, i: number) => {
          t.title = t.categoryName;
          t.key = t.id;
          t.father = item.id;
          t.children = t.userLabelVos || [];
          if (t.children?.length > 0) {
            t.children.map((m: any, n: number) => {
              m.title = m.labelName;
              m.key = m.labelId;
            });
          } else {
            disableNum++;
            t.disabled = true;
          }
        });
        if (disableNum == item.children.length) {
          //二级类目下没有标签
          item.disabled = true;
        }
      } else {
        item.disabled = true;
      }
    });
    categoryList = categoryList.filter((i: any) => !i.disabled);
    categoryList = categoryList.map((i: any) => {
      return {
        ...i,
        children: i.children.filter((ii: any) => !ii.disabled),
      };
    });
    setOptionsValue(categoryList);

    if (checkedNodes.length === 0) {
      setCheckedKeys([]);
    }

    return categoryList;
  };
  // 获取基础属性类目
  const getBaseOptions = async () => {
    // 表单数据
    const res = await getUserPropertyListApi({
      createOrder: '',
      updateOrder: '',
    });
    if (res) {
      const list = ['手机号', '抖音unionId', '京东pin', '天猫omid', '抖音openId', '京东xid', '天猫ouid', 'OneID', '会员ID'];
      const options = res.data.rows
        .filter((ele: any) => list.indexOf(ele.fieldName) == -1)
        .map((item: any) => {
          return {
            value: item.id,
            label: item.fieldName,
            field: item.field,
          };
        });
      setBaseOption(options);
    }
  };
  const getDrawList = (type: any) => {
    if (type == 'tag') {
      // 选择标签
      setPortraitOpen(true);
      getCategoryList();
    } else if (type == 'base') {
      // 基础属性
    } else {
      //客户行为
    }
  };
  const onExpand: TreeProps['onExpand'] = newExpandedKeys => {
    // setExpandedKeys(newExpandedKeys);
    console.log('onExpand', newExpandedKeys);
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeysValue: any, e: any) => {
    console.log('onCheck', checkedKeysValue);
    setCheckedKeys(checkedKeysValue as React.Key[]);
    checkedNodes = e.checkedNodes;
    console.log('checkedNodes', checkedNodes);
  };

  const onSelect: TreeProps['onSelect'] = (selectedKeysValue, info) => {
    console.log('onSelect', info);
  };
  const calcPortrait = () => {
    // const titles = findTitlesByIds(optionsValue, checkedKeys);
    const temp = checkedNodes.filter((item: any) => item.categoryId);
    if (temp.length <= 10) {
      setTagList(temp);
      setConditions({
        ...conditions,
        tagList: temp,
      });
      setPortraitOpen(false);
    } else {
      message.warning('最多添加10个标签');
    }
  };
  const deleteTag = (item: any) => {
    const temp = checkedNodes.filter((i: any) => i.categoryId && i.labelId !== item.labelId);
    checkedNodes = temp;
    const keys = temp.map((i: any) => {
      return i.labelId;
    });
    console.log('deleteTag', keys);
    setCheckedKeys(keys);
    calcPortrait();
  };
  //基础属性选择
  const handleRowChange = (val: any, index: any) => {
    const list = [...userPropertyRules];
    list[index].row = {
      id: val,
      field: baseOption.filter((i: any) => i.value === val)[0].field,
      fieldName: baseOption.filter((i: any) => i.value === val)[0].label,
    };
    setUserPropertyRules(list);
  };
  const handleColChange = (val: any, index: any) => {
    const list = [...userPropertyRules];
    list[index].col = {
      id: val,
      field: baseOption.filter((i: any) => i.value === val)[0].field,
      fieldName: baseOption.filter((i: any) => i.value === val)[0].label,
    };
    setUserPropertyRules(list);
  };
  const addBase = () => {
    const list = [...userPropertyRules];
    const obj = {
      row: {
        id: baseOption[0].value,
        field: baseOption[0].field,
        fieldName: baseOption[0].label,
      },
      col: {
        id: baseOption[0].value,
        field: baseOption[0].field,
        fieldName: baseOption[0].label,
      },
      bean: '1',
      portraitType: '1',
      sortType: '0',
      type: 'column',
    };
    list.push(obj);
    setUserPropertyRules(list);
  };
  const deleteBase = (index: any) => {
    const list = [...userPropertyRules];
    list.splice(index, 1);
    setUserPropertyRules(list);
  };

  const items = [
    {
      crowd_value_id: String(new Date().valueOf()) + '02', //生成
      id: String(new Date().valueOf()) + '02',
      operate_label_id: String(new Date().valueOf()) + '02',
      is_copy: false, // 此值用于标签值名称是以文本方式还是输入框方式展示
      label_open: true,
      label_value: '标签值',
      action_bar_show: false,
      people_num: '--',
      data_sources: 'create',
      rules: {
        combinator: 'and',
        rules: [
          {
            rules_value: '规则组',
            action_bar_show: false,
            rules_value_id: String(new Date().valueOf()) + '03',
            combinator: 'and',
            data_sources: 'create',
            is_copy: false, // 此值用于规则组名称是以文本方式还是输入框方式展示
            rules: [],
          },
        ],
      },
    },
  ];
  // 规则操作参数
  const [paramsList, setParamsList] = useState(JSON.parse(JSON.stringify(items)));
  const onTabChange = (val: any) => {
    setPortraitType(val);
    if (val === '2') {
      getBaseOptions();
    }
  };

  // 获取规则参数
  const getRuleData = (val: any) => {
    setParamsList(val);
  };
  // 获取组件校验数字输入框大小方法
  const getCheckSizeFunction = (val: any) => {
    checkSize(val.value, val.data, val.key, val.msg, val.f);
  };

  // 校验数字输入框大小
  const checkSize = (e: any, val: any, f: string, msg: string, k: string) => {
    let store = paramsList.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === paramsList[0].operate_label_id) {
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value_id === paramsList[0].rules.rules[0].rules_value_id) {
            m.rules.forEach((p: any, q: number) => {
              if (k === 'p') {
                if (p.rules_operate_id === val.rules_operate_id) {
                  if (f === 'min' && e) {
                    if (val.max && Number(val.max) < Number(e)) {
                      p.min = '';
                      message.warning(msg);
                    }
                  } else if (f === 'max' && e) {
                    if (val.min && Number(e) < Number(val.min)) {
                      p.max = '';
                      message.warning(msg);
                    }
                  }
                }
              } else {
                if (p.rules) {
                  p.rules.rules.forEach((w: any, x: number) => {
                    if (w.child_id === val.child_id) {
                      if (f === 'min' && e) {
                        if (val.max && Number(val.max) < Number(e)) {
                          w.min = '';
                          console.log('111');
                          message.warning(msg);
                        }
                      } else if (f === 'max' && e) {
                        if (val.min && Number(e) < Number(val.min)) {
                          w.max = null;
                          message.warning(msg);
                        }
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    });

    getRuleData(store);
  };

  // 获取各标签组件change方法
  const getRulesChangeFunction = (val: any) => {
    rulesChange(val.e, val.id, val.key, val.f, val.option);
  };
  // 请选择规则change事件
  const rulesChange = (e: any, id: string, key: string, f: string, option?: any) => {
    let store = paramsList.map((val: any, idx: number) => val);
    store.forEach((v: any, i: number) => {
      if (v.operate_label_id === paramsList[0].operate_label_id) {
        // 找到对应的标签组
        v.rules.rules.forEach((m: any, n: number) => {
          if (m.rules_value_id === paramsList[0].rules.rules[0].rules_value_id) {
            // 找到对应的规则组
            m.rules.forEach((p: any, q: number) => {
              if (f === 'p') {
                if (p.rules_operate_id === id) {
                  // 找到具体对应的某条规则
                  if (key === 'min' || key === 'max') {
                    if (key === 'min') {
                      p.value = e + '-' + p.max;
                    } else {
                      p.value = p.min + '-' + e;
                    }
                    p[key] = e;
                  } else {
                    // 选择前面的展示标签【标签、属性、行为、业务、分群】
                    if (key === 'rules_Label_value') {
                      switch (e) {
                        case '1': // 标签
                          p['field_type'] = 'Label';
                          p['field_code'] = '';
                          p['table_name'] = 'tag_user_detail';
                          p['field'] = '';
                          p['value'] = null;
                          // 清空后面的规则 - start
                          p['label_id'] = null;
                          p['operator_id'] = null;
                          p['operator'] = null;
                          if (p.rules) {
                            delete p.rules;
                          }
                          // 清空后面的规则 - end
                          // getqueryLabelList();
                          break;
                        case '2':
                          // 清空后面的规则 - start
                          p['field_id'] = null;
                          p['field_code'] = '';
                          p['field'] = null;
                          p['field_type'] = '';
                          p['table_name'] = '';
                          p['operator_id'] = null;
                          p['operator'] = null;
                          p['value'] = null;
                          p['value_select'] = null;
                          p['value_time'] = null;
                          p['month_open'] = false;
                          p['min'] = '';
                          p['max'] = '';
                          if (p.rules) {
                            delete p.rules;
                          }
                          // 清空后面的规则 - end
                          // getProfilePropertyList(); // 属性
                          break;
                        case '3':
                          // 行为
                          // 清空后面的规则 - start
                          p['time'] = null;
                          p['time_value'] = null;
                          p['behavior'] = '做过';
                          p['behavior_field_id'] = null;
                          p['field_id'] = null;
                          p['field'] = '';
                          p['field_code'] = '';
                          p['field_type'] = '';
                          p['table_name'] = 'dwt_user_behavior_d';
                          p['statistics_type'] = '总次数';
                          p['operator_id'] = null;
                          p['operator'] = null;
                          p['value'] = '';
                          p['min'] = '';
                          p['max'] = '';
                          if (p.rules) {
                            delete p.rules;
                          }
                          // 清空后面的规则 - end
                          // getProfileBehaviorList(); // 行为
                          break;
                        case '5':
                          // 分群
                          // getCrowdListApi(); // 获取分群
                          p.value = null;
                          p.field_type = 'Crowd';
                          p.field_code = '';
                          p.table_name = 'crowd_user_detail';
                          p.operator_id = '=';
                          p.operator = '=';
                          if (p.rules) {
                            delete p.rules;
                          }
                          break;
                        default:
                          console.log('');
                          break;
                      }
                      p[key] = e;
                    } else if (key === 'label_id') {
                      // 请选择标签change事件
                      p['operator_id'] = null;
                      p['operator'] = null;
                      p['value'] = null;
                      p[key] = e;
                    } else if (key === 'field_id') {
                      // 属性 - 清空后面的值
                      p['operator_id'] = null;
                      p['operator'] = null;
                      p['value'] = null;
                      p['value_select'] = null;
                      p['value_time'] = null;
                      p['month_open'] = false;
                      p['min'] = '';
                      p['max'] = '';
                      const storeItem = option.find((v: any, i: number) => v.id === e);
                      p['table_name'] = storeItem.tableName;
                      p['field_type'] = storeItem.fieldType;
                      p['field_code'] = storeItem.fieldCode;
                      p['field'] = storeItem.field;
                      p[key] = e;
                      if (
                        storeItem.fieldType !== 'String' &&
                        storeItem.fieldType !== 'Date' &&
                        storeItem.fieldType !== 'Long' &&
                        storeItem.fieldType !== 'Decimal'
                      ) {
                        p['value'] = null;
                        // getProfileEnumsList("property", {fieldType: storeItem.fieldType, fieldCode: storeItem.fieldCode});
                      }
                    } else if (key === 'behavior_field_id') {
                      p['operator_id'] = null;
                      p['operator_value'] = '';
                      p['operator'] = null;
                      p['value'] = '';
                      p['min'] = '';
                      p['max'] = '';
                      if (p.rules) {
                        delete p.rules;
                      }
                      p['field'] = e;
                      p['behavior_field_value'] = option.find((value: any, index: number) => value.value === e).label;
                      p[key] = e;
                    } else if (key === 'behavior') {
                      if (e === '未做过') {
                        p['statistics_type'] = '';
                        p['operator_id'] = null;
                        p['operator'] = null;
                        p['value'] = '';
                        p['min'] = '';
                        p['max'] = '';
                      } else {
                        p['statistics_type'] = '总次数';
                        p['operator_id'] = 10;
                        p['operator'] = 'gt=';
                        p['value'] = '';
                        p['min'] = '';
                        p['max'] = '';
                      }
                      if (p.rules) {
                        delete p.rules;
                      }
                      p[key] = e;
                    } else if (key === 'statistics_type') {
                      p['operator_id'] = null;
                      p['operator'] = null;
                      p['value'] = '';
                      p['min'] = '';
                      p['max'] = '';
                      p[key] = e;
                    } else if (key === 'behaviorParentRelationshipOptions') {
                      // 行为默认值是10
                      if (p.behavior === '做过' && p.statistics_type === '总次数') {
                        p['operator'] = 'gt=';
                        p['operator_id'] = 10;
                        p['operator_value'] = '大于等于';
                      }
                    } else if (key === 'operator_id') {
                      if (p.rules_Label_value === '1') {
                        // 标签
                        p['operator'] = option.find((v: any, i: number) => v.value === e).operator;
                        p['value'] = null;
                        p[key] = e;
                      }
                      if (p.rules_Label_value === '2') {
                        // 属性
                        p['operator'] = !isNaN(e) ? option.find((v: any, i: number) => v.code === e).operator : e.split('-')[0];
                        p['value'] = null;
                        p['value_select'] = null;
                        p['value_time'] = null;
                        p['min'] = '';
                        p['max'] = '';
                        p[key] = !isNaN(e) ? e : Number(e.split('-')[1]);
                      }
                      if (p.rules_Label_value === '3') {
                        // 行为
                        p['operator'] = option.find((v: any, i: number) => v.value === e).operator;
                        p['operator_value'] = option.find((v: any, i: number) => v.value === e).name;
                        p['value'] = '';
                        p['min'] = '';
                        p['max'] = '';
                        p[key] = e;
                      }
                      if (p.rules_Label_value === '5') {
                        p['operator'] = e;
                        p[key] = e;
                      }
                    } else if (key === 'value_select') {
                      p['value'] = Array.isArray(e) && e.length === 0 ? null : e;
                      p['value_select'] = option;
                    } else if (key === 'value_r') {
                      if (e.length === 1 || !e[0] || !e[1]) {
                        p['value_time'] = null;
                      } else {
                        p['value_time'] = [
                          dayjs(e[0], p.field_type === 'Date' ? 'YYYY-MM-DD' : 'YYYY'),
                          dayjs(e[1], p.field_type === 'Date' ? 'YYYY-MM-DD' : 'YYYY'),
                        ];
                      }
                      p['value'] = e;
                    } else if (key === 'value_m_r') {
                      p['value'] = e ? e.map((tv: any, ti: number) => tv.replace(/月/g, '')) : null;
                      p['value_time'] = e ? e.join(' - ') : null;
                    } else if (key === 'value_d' || key === 'value_y') {
                      // 年月日需做单独处理
                      p['value'] = e;
                      if (e) {
                        p['value_time'] = key === 'value_d' ? dayjs(e, 'YYYY-MM-DD') : dayjs(e, 'YYYY');
                      } else {
                        p['value_time'] = null;
                      }
                    } else if (key === 'value_m') {
                      p['value'] = e.replace(/月/g, '');
                      p['value_time'] = e;
                    } else {
                      p[key] = e;
                    }
                  }
                }
              } else {
                if (p.rules) {
                  p.rules.rules.forEach((w: any, g: number) => {
                    if (w.child_id === id) {
                      if (key === 'field_id') {
                        const storeItem = option.find((v: any, i: number) => v.id === e[0]);
                        w['operator'] = null;
                        w['operator_id'] = null;
                        w['min'] = '';
                        w['max'] = '';
                        w['value'] = null;
                        w['value_time'] = null;
                        w['month_open'] = false;
                        w['table_name'] = storeItem.tableName;
                        w['field_type'] = storeItem.fieldType;
                        w['field_code'] = storeItem.fieldCode;
                        w['field'] = storeItem.field;
                        w['field_id'] = storeItem.id;
                        w['func_value'] = e;
                        w['field_value'] = storeItem.fieldName;
                        if (
                          storeItem.fieldType !== 'String' &&
                          storeItem.fieldType !== 'Date' &&
                          storeItem.fieldType !== 'Long' &&
                          storeItem.fieldType !== 'Decimal'
                        ) {
                          w['value'] = null;
                        }
                      } else if (key === 'operator_id') {
                        w['operator'] = !isNaN(e) ? option.find((v: any, i: number) => v.code === e).operator : e.split('-')[0];
                        w['min'] = '';
                        w['max'] = '';
                        w['value'] = null;
                        w['value_select'] = null;
                        w['value_time'] = null;
                        w['month_open'] = false;
                        w['operator_value'] = !isNaN(e) ? option.find((v: any, i: number) => v.code === e).name : e.split('-')[2];
                        w[key] = !isNaN(e) ? e : Number(e.split('-')[1]);
                      } else if (key === 'value_select') {
                        w['value'] = Array.isArray(e) && e.length === 0 ? null : e;
                        w['value_select'] = option.id;
                        w['value_select_value'] = option.name.join('、');
                      } else if (key === 'value_r') {
                        if (e.length === 1 || !e[0] || !e[1]) {
                          w['value_time'] = null;
                        } else {
                          w['value_time'] = [
                            dayjs(e[0], w.field_type === 'Date' ? 'YYYY-MM-DD' : 'YYYY'),
                            dayjs(e[1], w.field_type === 'Date' ? 'YYYY-MM-DD' : 'YYYY'),
                          ];
                        }
                        w['value'] = e;
                      } else if (key === 'value_m_r') {
                        w['value'] = e ? e.map((tv: any, ti: number) => tv.replace(/月/g, '')) : null;
                        w['value_time'] = e ? e.join(' - ') : null;
                      } else if (key === 'value_d' || key === 'value_y') {
                        // 年月日需做单独处理
                        w['value'] = e;
                        if (e) {
                          w['value_time'] = key === 'value_d' ? dayjs(e, 'YYYY-MM-DD') : dayjs(e, 'YYYY');
                        } else {
                          w['value_time'] = null;
                        }
                      } else if (key === 'value_m') {
                        w['value'] = e.replace(/月/g, '');
                        w['value_time'] = e;
                      } else if (key === 'min' || key === 'max') {
                        if (key === 'min') {
                          w.value = e + '-' + w.max;
                        } else {
                          w.value = w.min + '-' + e;
                        }
                        w[key] = e;
                      } else {
                        w[key] = e;
                      }
                    }
                  });
                }
              }
            });
          }
        });
      }
    });
    getRuleData(store);
  };

  // 删除规则
  const deleteItem = (idx: number) => {
    let store = paramsList.map((val: any, idx: number) => val);
    let pItem = store.find((v: any, i: number) => v.operate_label_id === paramsList[0].operate_label_id);
    let cItem = pItem.rules.rules?.find((v: any, i: number) => v.rules_value_id === paramsList[0].rules.rules[0].rules_value_id);
    cItem.rules?.splice(idx, 1);
    getRuleData(store);
  };
  // 添加规则
  const addBehavior = () => {
    let store = paramsList.map((val: any, idx: number) => val);
    let pItem = store.find((v: any, i: number) => v.operate_label_id === paramsList[0].operate_label_id);
    let cItem = pItem.rules.rules?.find((v: any, i: number) => v.rules_value_id === paramsList[0].rules.rules[0].rules_value_id);
    if (cItem.rules.length > 10) {
      message.error('最多支持添加10个行为');
    } else {
      cItem.rules?.unshift({
        rules_Label_value: '3',
        rules_operate_id: String(new Date().valueOf()) + '04',
        table_name: 'dwt_user_behavior_d',
        field_type: '',
        field_code: '',
        label_id: null,
        field: null,
        field_id: null,
        min: '',
        max: '',
        time: null,
        time_value: null,
        time_key: '1',
        is_custom: false,
        custom_value: null,
        custom_checked: null,
        timeStore: '',
        operator: null,
        operator_id: null,
        operator_value: '',
        behavior: '做过',
        value: '',
        value_select: '',
        value_time: '',
        statistics_type: '总次数', // 统计方式
        behavior_field_id: null,
        behavior_field_value: '',
        behavior_time_open: false,
        bean: '2',
        portraitType: '2',
        type: 'column',
        sortType: '0',
      });
    }
    getRuleData(store);
  };
  const portraitItems: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <div>
          客户标签
          <Tooltip title="查询分群在标签值的分布情况">
            <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)', marginLeft: 9 }} />
          </Tooltip>
        </div>
      ),
      children: (
        <>
          <Tag
            className={styles.tagStyle}
            icon={<PlusOutlined />}
            onClick={() => {
              getDrawList('tag');
            }}>
            {' '}
            添加标签
          </Tag>
          <div>
            {tagList.map((item: any, index: any) => (
              <Tag
                style={{ marginRight: 16, marginBottom: 16 }}
                key={index}
                closable
                onClose={e => {
                  e.preventDefault();
                  deleteTag(item);
                }}>
                {' '}
                {item.labelName}
              </Tag>
            ))}
          </div>
        </>
      ),
    },
    {
      key: '2',
      label: (
        <div>
          客户基础属性
          <Tooltip title="查询分群在基础属性值的分布情况">
            <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)', marginLeft: 9 }} />
          </Tooltip>
        </div>
      ),
      children: (
        <div className={styles.baseBox}>
          {userPropertyRules.map((item: any, index: any) => (
            <div key={index} className={styles.baseItem}>
              行属性：
              <Select defaultValue={item.row.id} style={{ width: 280 }} onChange={val => handleRowChange(val, index)} options={baseOption} />
              <span
                style={{
                  width: '0',
                  height: '16px',
                  border: 'var(--border3)',
                  margin: '0 16px',
                }}></span>
              列属性：
              <Select
                defaultValue={item.col.id}
                style={{ width: 280, marginRight: 16 }}
                onChange={val => handleColChange(val, index)}
                options={baseOption}
              />
              <DeleteOutlined onClick={() => deleteBase(index)} style={{ color: 'var(--text-font-color4)' }} />
            </div>
          ))}

          <Button className={styles.addBtn} disabled={userPropertyRules.length > 9} onClick={addBase}>
            添加基础属性
          </Button>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div>
          客户行为
          <Tooltip title="查询分群在行为属性值的分布情况">
            <QuestionCircleOutlined style={{ color: 'var(--text-font-color4)', marginLeft: 9 }} />
          </Tooltip>
        </div>
      ),
      children: (
        <div>
          {paramsList[0].rules.rules[0].rules?.map((val: any, idx: number) => {
            return (
              <div key={val.rules_operate_id} style={{ marginTop: 8 }}>
                <Behavior
                  userInsight={true}
                  key={val.rules_operate_id}
                  operateType={location.state.type == '1' ? 'create' : 'edit'}
                  listData={paramsList}
                  itemData={paramsList[0]}
                  ruleData={paramsList[0].rules.rules[0]}
                  val={val}
                  idx={idx}
                  isGoOn={true}
                  getRuleDataFunction={(store: any) => getRuleData(store)}
                  getCheckSizeFunction={getCheckSizeFunction}
                  getDeleteItemFunction={(i: number) => deleteItem(i)}
                  getRulesChangeFunction={getRulesChangeFunction}
                />
              </div>
            );
          })}

          <Button
            style={{ marginTop: paramsList[0].rules.rules[0].rules.length > 0 ? 24 : 8, width: 'calc(85.7143% + 2.57143px)' }}
            className={styles.addBtn}
            disabled={paramsList[0].rules.rules[0].rules?.length > 9}
            onClick={addBehavior}>
            添加行为偏好
          </Button>
        </div>
      ),
    },
  ];
  // 拖拽事件
  const handleDragEnd = (result: any) => {
    // 如果没有拖动任何item，则不做处理
    if (!result.destination) {
      return;
    }
    const items = Array.from(reportChartData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setReportChartData(items);
    console.log('更新后的data', items);
  };
  const handleChangeSort = (title: string, sortType: string) => {
    const newData = [...reportChartData];
    newData.forEach((item, index) => {
      if (item.title === title) {
        console.log('sortType', sortType);
        newData[index].sortType = sortType;
        if (item.portraitType == '1') {
          //属性
          userPropertyRules.forEach((ele: any, subIndex: any) => {
            if (ele.title == title) {
              userPropertyRules[subIndex].sortType = sortType;
            }
          });
          setUserPropertyRules(userPropertyRules);
          console.log('更改属性rules', userPropertyRules);
        } else if (item.portraitType == '2') {
          //行为
          paramsList[0].rules.rules[0].rules.forEach((ele: any, subIndex: any) => {
            if (ele.title == title) {
              paramsList[0].rules.rules[0].rules[subIndex].sortType = sortType;
            }
          });
          console.log('更改行为rules', paramsList[0].rules.rules[0].rules);
        }
      }
    });
    setReportChartData(newData);
  };
  const handleChangeShape = (title: string, type: string) => {
    const newData = [...reportChartData];
    newData.forEach((item, index) => {
      if (item.title === title) {
        newData[index].type = type;
        if (item.portraitType == '1') {
          //属性
          userPropertyRules.forEach((ele: any, subIndex: any) => {
            if (ele.title == title) {
              userPropertyRules[subIndex].type = type;
            }
          });
          setUserPropertyRules(userPropertyRules);
          console.log('更改属性rules', userPropertyRules);
        } else if (item.portraitType == '2') {
          //行为
          paramsList[0].rules.rules[0].rules.forEach((ele: any, subIndex: any) => {
            if (ele.title == title) {
              paramsList[0].rules.rules[0].rules[subIndex].type = type;
            }
          });
          console.log('更改行为rules', paramsList[0].rules.rules[0].rules);
        }
      }
    });
    setReportChartData(newData);
  };
  const reorganizeData = (data: any) => {
    // 使用reduce方法来创建一个新的对象，其中键是row的值，值是包含其他字段的数组
    const reorganized = data.reduce((accumulator: any, item: any) => {
      const { row, ...rest } = item; // 解构出row字段和其他字段
      if (!accumulator[row]) {
        // 如果当前row还没有对应的数组，则初始化一个空数组
        accumulator[row] = [];
      }
      // 将剩余字段组成的对象添加到对应row的数组中
      accumulator[row].push(rest);
      return accumulator;
    }, {});
    // 将重组后的对象转换为所需的数组格式
    const result = Object.entries(reorganized).map(([row, list]) => ({
      row,
      list,
    }));
    return result;
  };
  const formatChartData = (reportChats: any) => {
    reportChats.forEach((item: any, index: any) => {
      item.data.forEach((subItem: any, subIndex: any) => {
        item.data[subIndex].value = subItem.customers;
        item.data[subIndex].name = subItem.valueName;
      });
      if (item.statsValue == '1') {
        reportChats[index].data = reorganizeData(item.data);
      }
    });
    //目标人群
    setReportChartData(reportChats.filter((item: any) => item.targetType === 0));
    //对比人群
    setReportChartCompareData(reportChats.filter((item: any) => item.targetType === 1));
  };
  const previewChart = async () => {
    const res = checkFormData();
    if (res) {
      //获取预览图表数据
      if (tagList.length > 0 || userPropertyRules.length > 0 || paramsList[0].rules.rules[0].rules.length > 0) {
        let userBehaviorRulesJsonString: any = [];
        let userPropertyRulesJsonString: any = [];
        console.log(333, reportChartData);

        paramsList[0].rules.rules[0].rules.forEach((item: any, index: any) => {
          userBehaviorRulesJsonString.push(JSON.stringify(item));
        });
        userPropertyRules.forEach((item: any) => {
          userPropertyRulesJsonString.push(JSON.stringify(item));
        });
        const params = {
          ...editData,
          labelIds: tagList.map((i: any) => i.labelId),
          crowdIds:
            crowdIds.length > 0
              ? crowdIds
              : editData.userCrowds.map((item: any) => {
                  return item.crowdId;
                }),
          compareCrowdIds:
            compareCrowdIds.length > 0
              ? compareCrowdIds
              : editData.compareUserCrowds?.map((item: any) => {
                  return item.crowdId;
                }),
          userBehaviorRules: userBehaviorRulesJsonString,
          userPropertyRules: userPropertyRulesJsonString,
          ...statisticsObj,
        };
        params.userLabelRules = [];
        params.labelIds.forEach((item: any) => {
          params.userLabelRules.push(
            JSON.stringify({
              sortType:
                reportChartData.filter(
                  (ele: any) => ele.portraitType == '0' && ele.title == tagList.filter((i: any) => i.labelId == item)[0].labelName
                )[0]?.sortType || '0',
              type:
                reportChartData.filter(
                  (ele: any) => ele.portraitType == '0' && ele.title == tagList.filter((i: any) => i.labelId == item)[0].labelName
                )[0]?.type || 'column',
              title: tagList.filter((i: any) => i.labelId == item)[0].labelName,
              bean: '0',
              portraitType: '0',
              labelId: item,
            })
          );
          console.log('params.userLabelRules', params.userLabelRules);
        });
        const res = await queryPreviewChart(params);
        if (res) {
          formatChartData(res.data);
        }
      } else {
        setReportChartData([]);
      }

      setChartOpen(true);
    }
  };
  //目标人群对应图表数据
  const [reportChartData, setReportChartData] = useState<any>([]);
  //对比人群对应图表数据
  const [reportChartCompareData, setReportChartCompareData] = useState<any>([]);
  // 编辑数据处理
  const handleData = async () => {
    if (location.state.type == '1') {
      //新增
      reportForm.setFieldValue('name', '');
      reportForm.setFieldValue('group', '');
      reportForm.setFieldValue('updateModel', '0');
    } else {
      checkedNodes = [];
      //编辑
      const res = await queryReportFormDetail({
        reportId: location.state.reportId,
      });
      if (res && res.data) {
        editData = res.data;
        reportForm.setFieldValue('reportName', editData.reportName);
        reportForm.setFieldValue('updateModel', editData.updateModel);
        const obj = {
          ...conditions,
          updateModel: editData.updateModel,
          beginTime: editData.updateModel == '0' ? editData.beginTime : '',
          endTime: editData.updateModel == '0' ? editData.endTime : '',
          rangeDate: editData.updateModel == '0' ? [dayjs(editData.beginTime, dateFormat), dayjs(editData.endTime, dateFormat)] : [],
        };
        reportForm.setFieldValue('rangeDate', obj.rangeDate);
        setConditions(obj);
        if (res.data.deletedCrowd) {
          message.warning('目标人群已删除，请重新选择人群包');
        } else {
          setPeopleRowKeys(editData.crowdIds);
          const names = editData.userCrowds
            .map((item: any) => {
              return item.crowdName;
            })
            .join('、');
          setPeopleNames(names);
          reportForm.setFieldValue('group', editData.userCrowds);
        }

        if (editData.compareUserCrowds?.length > 0) {
          setPeopleCompareRowKeys(editData.compareUserCrowds);
          const names = editData.compareUserCrowds
            .map((item: any) => {
              return item.crowdName;
            })
            .join('、');
          setPeopleCompareNames(names);
        }
        if (editData.userBehaviorRules) {
          const tempParamsList = [...paramsList];
          tempParamsList[0].rules.rules[0].rules = [];
          editData.userBehaviorRules.forEach((item: any, index: any) => {
            tempParamsList[0].rules.rules[0].rules.push(JSON.parse(item));
          });
          setParamsList(tempParamsList);
          setPortraitType('3');
        }

        if (editData.userPropertyRules) {
          let temp: any = [];
          editData.userPropertyRules.forEach((item: any, index: any) => {
            temp.push(JSON.parse(item));
          });
          setUserPropertyRules(temp);
          setPortraitType('2');
          getBaseOptions();
        }
        if (editData.userLabels) {
          setTagList(editData.userLabels);
          setPortraitType('1');
          checkedNodes = editData.userLabels;
          //编辑第一次打开标签抽屉要勾选
          setCheckedKeys(
            editData.userLabels.map((i: any) => {
              return i.labelId;
            })
          );
        }
        setStatisticsObj({
          userNum: res.data.userNum,
          userRate: res.data.userRate,
          compareUserNum: res.data.compareUserNum,
          compareUserRate: res.data.compareUserRate,
        });
        if (res.data.reportChats) {
          formatChartData(res.data.reportChats);
        }
      }
    }
  };
  useEffect(() => {
    console.log(111, compareCrowdIds);
    handleData();
  }, []);

  return (
    <div className={styles.groupUserInsight}>
      <div className={styles.titleAndOperate}>
        <BackList></BackList>
        <ModuleTitle mark="large" title={location.state.type == '1' ? '新建分析' : '编辑分析'} />
      </div>
      <div className={styles.formbox}>
        <Form {...layout} form={reportForm} name="control-hooks" onFinish={onFinish}>
          <ModuleTitle mark="middle" title="报告基本信息" />
          <Form.Item style={{ marginTop: '24px' }} name="reportName" label="分析名称" rules={[{ required: true }]}>
            <Input
              disabled={location.state.type == '2'}
              style={{ width: 360 }}
              autoComplete="off"
              placeholder="请输入分析名称"
              maxLength={100}
              showCount
            />
          </Form.Item>
          <Form.Item label="报告人群" name="group" rules={[{ required: true }]}>
            <div>
              <Button
                onClick={() => {
                  onOpen(1);
                }}>
                选择人群
              </Button>
              {peopleNames && (
                <div className={styles.peopleinfo}>
                  <Tooltip title={peopleNames}>
                    <div className={styles.peopleNamebox}>{peopleNames}</div>
                  </Tooltip>
                  <span>，去重后：</span>
                  <span style={{ color: 'var(--text-font-color7)' }}>{Number(statisticsObj.userNum).toLocaleString()}</span>人，去重后占全部用户：
                  <span style={{ color: 'var(--text-font-color7)' }}>{statisticsObj.userRate}</span>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item label="对比人群">
            <div>
              <Button
                onClick={() => {
                  onOpen(2);
                }}>
                选择对比人群
              </Button>
              {peopleCompareNames && (
                <div className={styles.peopleinfo}>
                  <Tooltip title={peopleCompareNames}>
                    <div className={styles.peopleNamebox}>{peopleCompareNames}</div>
                  </Tooltip>
                  <span>，去重后：</span>
                  <span style={{ color: 'var(--text-font-color7)' }}>{Number(statisticsObj.compareUserNum).toLocaleString()}</span>
                  人，去重后占全部用户：
                  <span style={{ color: 'var(--text-font-color7)' }}>{statisticsObj.compareUserRate}</span>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item name="updateModel" label="报告更新方式" rules={[{ required: true }]}>
            <div>
              <Radio.Group options={options} onChange={onWayChange} value={conditions.updateModel} optionType="button" />
              {conditions.updateModel == '0' && (
                <Form.Item style={{ marginTop: '16px' }} name="rangeDate" rules={[{ required: true, message: '请选择日期' }]}>
                  在
                  <RangePicker
                    value={conditions.rangeDate.length > 0 ? conditions.rangeDate : []}
                    disabledDate={disabledDate}
                    onChange={onDateChange}
                    style={{ margin: '0 8px' }}
                  />
                  内 每天
                </Form.Item>
              )}
            </div>
          </Form.Item>

          <ModuleTitle mark="middle" title="选择画像信息" />
          <Tabs style={{ marginTop: '24px' }} activeKey={portraitType} type="card" onChange={onTabChange} items={portraitItems} />
          {/* <DragDropContext onDragEnd={handleDragEnd}>
               <Droppable droppableId="droppable">
                {provided => (ref={provided.innerRef} {...provided.droppableProps}
                     )}
                </Droppable>
              </DragDropContext>
                */}
          {chartOpen &&
            (reportChartData.length > 0 ? (
              <div style={{ display: 'flex', justifyContent: reportChartData.length > 0 ? 'space-between' : 'center', alignItems: 'flex-start' }}>
                <Row style={{ width: reportChartCompareData?.length > 0 ? '50%' : '100%' }} gutter={16}>
                  {reportChartData.map((item: any, index: any) => (
                    // <Draggable key={item.title} draggableId={item.title} index={index}>
                    // {provided => (ref={provided.innerRef}
                    // {...provided.draggableProps}
                    // {...provided.dragHandleProps}
                    <Col key={item.chartId} className={styles.chartBox} span={reportChartCompareData?.length > 0 ? 24 : 12}>
                      <ChartData
                        chartValue={item}
                        operation={'edit'}
                        peopleType={'aim'}
                        changeSort={handleChangeSort}
                        changeShape={handleChangeShape}
                      />
                    </Col>
                    // )}
                    // </Draggable>
                  ))}
                  {/* {provided.placeholder} */}
                </Row>
                {reportChartCompareData.length > 0 && (
                  <Row style={{ width: '50%' }}>
                    {reportChartCompareData.map((item: any) => {
                      return (
                        <Col key={item.chartId} className={styles.chartBox} span={24}>
                          <ChartData chartValue={item} operation={'view'} peopleType={'compare'} changeSort={() => {}} changeShape={() => {}} />
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </div>
            ) : (
              <div style={{ textAlign: 'center', marginTop: 24 }}>暂无数据</div>
            ))}
          <Form.Item {...tailLayout} style={{ marginTop: '24px' }}>
            <Space>
              <Button
                htmlType="button"
                onClick={() => {
                  navigate(-1);
                }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
              {location.state.type == '2' && <Button onClick={previewChart}>预览</Button>}
            </Space>
          </Form.Item>
        </Form>
      </div>
      {/* 人群抽屉 */}
      <Drawer
        className={styles.drawerStyle}
        title="选择人群"
        placement="right"
        width={960}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                calcPeople();
                setOpen(false);
              }}>
              确定
            </Button>
          </Space>
        }>
        <div className={styles.buttonBox}>
          <SearchIconInput
            clear={true}
            width="310px"
            placeholder="请输入人群包名称"
            onSearch={(e: string) => {
              const store = {
                ...searchParam,
                crowdName: e,
              };
              setSearchParam(store);
              getUserData({ pageSize: 10, pageNum: 1 }, store);
            }}></SearchIconInput>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
        <FilterView ref={filterViewRef} averageTwo={true} searchList={searchList} finish={filterFinish}></FilterView>
        <Table
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={dataList}
          pagination={{
            total: pageQuery.total,
            current: pageQuery.pageNum,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={(page: any) => handleTableChange(page)}
        />
      </Drawer>
      {/* 添加标签抽屉 */}
      <Drawer
        className={styles.drawerStyle}
        title="添加标签"
        placement="right"
        width={400}
        onClose={onClose}
        open={portraitOpen}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                calcPortrait();
              }}>
              确定
            </Button>
          </Space>
        }>
        <div>
          <div
            style={{ fontSize: 'var(--text-font7)', fontWeight: 'var(--text-font-weight3)', color: 'var(--text-font-color9)', marginBottom: '16px' }}>
            客户标签
          </div>
          <Search style={{ marginBottom: 8 }} value={searchValue} ref={searchRef} placeholder="请输入" onChange={searchChange} onSearch={searchTag} />
          {optionsValue.length > 0 && (
            <Tree
              checkable
              defaultExpandAll={true}
              onExpand={onExpand}
              onCheck={onCheck}
              onSelect={onSelect}
              checkedKeys={checkedKeys}
              treeData={optionsValue}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default GroupUserInsight;
