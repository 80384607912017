import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import style from './index.module.scss';
import { LeftOutlined, FilterOutlined } from '@ant-design/icons';
import { Tabs, Row, Col, Card, Divider, Collapse } from 'antd';
import type { TabsProps, CollapseProps } from 'antd';
import { UngroupOutlined, GroupOutlined, CaretUpOutlined } from '@ant-design/icons';
import { getOneIdMergeRecordDetailApi, getOneIdMergeRelationApi } from '../../../../../api/userCenter/userCenter';
import { FlowChart } from '../FlowChart';

// function OneIdDetails({ handBackToList }: { handBackToList: () => void }) {
let OneIdDetails = React.forwardRef(
  (
    props: {
      handBackToList: () => void;
      oneId?: any;
      oneIdUsed?: any;
      updateTime?: string;
    },
    ref: any,
  ) => {
    // 详情接口
    const getDetailList = async () => {
      const res = await getOneIdMergeRecordDetailApi({ oneId: props.oneId, oneIdUsed: props.oneIdUsed });
      res.data && handleIntegration(res.data);
    };
    // 原始数据
    // 整合后的详情数据
    const [enumTipList, setEnumTipList] = useState<any>([]);
    // 默认展开小卡
    const [defaultActiveKey, setDefaultActiveKey] = useState<any>(['0']);

    // 整合方法,方便后期枚举
    const handleIntegration = (data: any) => {
      const list = Object.keys(data).map(key => {
        const item = data[key];
        return [
          {
            label: 'OneID',
            value: item.oneId,
          },
          {
            label: '手机号',
            value: item.aesPhone || '-',
          },
          {
            label: '会员卡ID',
            value: item.memberCardInfoList[0]?.memberCardId || '-',
          },
          {
            label: '会员等级',
            value: item.memberCardInfoList[0]?.gradeId || '-',
          },
          {
            label: '京东pin',
            value: item.jdPin,
          },
          {
            label: '京东xid',
            value: item.xid,
          },
          {
            label: '天猫ouid',
            value: item.tmOuid,
          },
          {
            label: '抖店id',
            value: item.doudianOpenId,
          },
        ];
      });

      setEnumTipList(list);
      setDefaultActiveKey(['1']);
    };
    // 关系图数据
    const [chartData, setChartData] = useState<any>({});
    // 关系图接口
    const getOneIdMergeRelation = async () => {
      const res = await getOneIdMergeRelationApi({ oneId: props.oneId, oneIdUsed: props.oneIdUsed });
      if (res.data) {
        setChartData(res.data.chartData);
      }
    };
    useEffect(() => {
      getDetailList();
      getOneIdMergeRelation();
    }, []);

    // 页面标题和返回按钮
    const HeaderSection = () => {
      return (
        <div className={style['header-bgd']}>
          <div className={style['header-left']}>
            <div className={style['header-icon']} onClick={props.handBackToList}>
              <LeftOutlined />
              <span className={style['header_text']}>返回</span>
            </div>
            <div className={style['header-text']}>OneID融合详情</div>
          </div>
          <div className={style['header-right']}>
            {/* 搜索 */}
            <span>数据更新时间：{props.updateTime}</span>
          </div>
        </div>
      );
    };
    // 蓝色的大框数据
    const pageList = [
      {
        title: '合并前OneID',
        iconName: <UngroupOutlined style={{ color: 'var(--text-font-color16)', fontSize: 'var(--text-font7)' }} />,
        list: [enumTipList[0], enumTipList[1]],
        key: 'before',
      },
      {
        title: '合并后OneID',
        iconName: <GroupOutlined style={{ color: 'var(--text-font-color16)', fontSize: 'var(--text-font7)' }} />,
        list: [enumTipList[2]],
        key: 'after',
      },
    ];
    // 蓝色的大框
    const MergerDetails = () => {
      return (
        <div className={style['pre_merger']}>
          {/* 大卡片 */}
          <Row gutter={[24, 0]}>
            {pageList.map(item => (
              <Col span={12} key={item.title}>
                <CardSection info={item} />
              </Col>
            ))}
          </Row>
        </div>
      );
    };
    // 蓝大卡
    const CardSection = ({ info }: { info: any }) => {
      const { title, iconName } = info;
      return (
        <div className={style['card-item-bgd']}>
          {/* 标题 */}
          <div className={style['card-item-title']}>
            <span className={style['icon']}>{iconName}</span>
            <span className={style['title']}>{title}</span>
          </div>
          {/* 小卡片 */}
          {info.list.map((item: any, index: number) => (
            <SmallCardSection
              title={
                <div>
                  <span style={{ marginRight: 8, fontWeight: 'var(--text-font-weight2)' }}>OneID{info.list.length > 1 ? index + 1 : ''}</span>
                  <span
                    style={{
                      width: '0',
                      height: '16px',
                      border: 'var(--border3)',
                      marginRight: 8,
                    }}></span>
                  {item && <span style={{ color: 'var(--text-font-color4)' }}>{item[0].value}</span>}
                </div>
              }
              item={item}
            />
          ))}
        </div>
      );
    };
    // 自定义折叠图标
    const CustomExpandIcon = ({ isActive }: { isActive: any }) => <CaretUpOutlined rotate={isActive ? 180 : 0} />;
    // 小卡片-折叠面板
    const SmallCardSection = ({ title, item }: { title: any; item: any }) => {
      return (
        <>
          <Collapse
            className={style['small-card']}
            expandIconPosition="end"
            expandIcon={panelProps => <CustomExpandIcon isActive={panelProps.isActive} />}
            defaultActiveKey={defaultActiveKey}
            items={[
              {
                key: '1',
                label: title,
                children: (
                  <>
                    {item?.map((it: any) => (
                      <div className={style.tipItem} key={it.label}>
                        {it.label !== 'OneID' && it.value && (
                          <>
                            <div style={{ width: '75px' }}> {it.label}： </div>
                            <div className={style.tipValue}> {it.value}</div>
                          </>
                        )}
                      </div>
                    ))}
                  </>
                ),
              },
            ]}
          />
        </>
      );
    };
    // 关系图表的颜色
    const colorMap: any = {
      A: '#1677FF',
      B: '#7CB305',
    };

    // 融合关系图
    const MergerChart = () => {
      return <FlowChart chartId="chart" colorMap={colorMap} data={chartData} />;
    };
    // tabs数据
    const tabList: TabsProps['items'] = [
      {
        key: '1',
        label: '融合详情',
        children: <MergerDetails />,
      },
      {
        key: '2',
        label: '融合关系图',
        children: <MergerChart />,
      },
    ];
    return (
      <div className={style['container']}>
        {/* 页面标题 */}
        <HeaderSection />
        {/* Tabs栏 */}
        <Tabs defaultActiveKey="1" items={tabList} className={style['tabs']} />
      </div>
    );
  },
);

export default OneIdDetails;
