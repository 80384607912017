import { AxiosPromise } from 'axios';
import request from '../network'
import { UserTableData, ManageUserGroupType } from "./type";

// 获取用户信息
export function getUserDataApi(data: any) {
  return request({
      url: '/label/crowd/page/list',
      method: 'post',
      data: data
  });
}

// 分群启用禁用
export function enableDisableApi(data: ManageUserGroupType) {
  return request({
      url: '/label/crowd/status/manage',
      method: 'post',
      data: data
  });
}

// 分群删除
export function deleteGroupApi(data: any) {
  return request({
      url: '/label/crowd/del',
      method: 'post',
      data: data
  });
}
// 分群编辑
export function editGroupApi(data: any) {
  return request({
      url: '/label/crowd/update',
      method: 'put',
      data: data
  });
}

// 表格数据数量查询
export function dataCountApi(data: any) {
  return request({
      url: '/label/crowd/num',
      method: 'post',
      data: data
  });
}

// 分群描述修改
export function describeEditApi(data: any) {
  return request({
    url: '/label/crowd/update/describe',
    method: 'put',
    data: data
});
}
// 分群操作日志
export function findCdpLogsApi(data: any) {
  return request({
    url: '/label/label/findCdpLogs',
    method: 'post',
    data: data
});
}