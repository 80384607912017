/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, useState, useEffect, useRef } from 'react';
import { Tabs, Button, Table, type TableColumnsType, Drawer, Space, message } from 'antd';
import { TextWithQuestion } from '../../../../../../component/MiniStyles';
import ChangeM from '../ChangeM';
import ChangeR from '../ChangeR';
import { getRMReport as getRMReportApi, updateRMReport as updateRMReportApi } from '@/crm/api/dataBoardApi';
import styles from './index.module.scss';

const formatNum = (num: number | string, options?: { digit?: number; isRatio?: boolean; nullSymbol?: string; }) => {
  const { digit = 2, isRatio = false, nullSymbol = '-' } = options || {};
  const newNum = Number(num);
  if (num === null || num === undefined || isNaN(newNum)) {
    return nullSymbol;
  }
  if (isRatio && newNum === 0) {
    return '0%';
  }
  if (isRatio && newNum === 1) {
    return '100%';
  }
  if (isRatio) {
    return `${(newNum * 100).toFixed(digit)}%`;
  }
  if (newNum === 0) {
    return 0;
  }
  return newNum.toFixed(2) === '-0.00' ? '0' : newNum.toLocaleString('en-US', {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  });
};

// 后端真实数据
const mockData = {
  "rmAnalysisData": [
    {
      "recencyType": null,
      "monetaryType": null,
      "crowdNum": 3,
      "crowdRatio": "1",
      "memberAtv": "28.33",
      "memberAov": "21.25",
      "memberAur": "21.25"
    },
    {
      "recencyType": null,
      "monetaryType": "28.33",
      "crowdNum": 2,
      "crowdRatio": "0.6667",
      "memberAtv": "3",
      "memberAov": "2",
      "memberAur": "2"
    },
    {
      "recencyType": null,
      "monetaryType": "56.66,84.99",
      "crowdNum": 1,
      "crowdRatio": "0.3333",
      "memberAtv": "79",
      "memberAov": "79",
      "memberAur": "79"
    },
    {
      "recencyType": "0.00,22.00",
      "monetaryType": null,
      "crowdNum": 2,
      "crowdRatio": "0.6667",
      "memberAtv": "3",
      "memberAov": "2",
      "memberAur": "2"
    },
    {
      "recencyType": "0.00,22.00",
      "monetaryType": "28.33",
      "crowdNum": 2,
      "crowdRatio": "0.6667",
      "memberAtv": "3",
      "memberAov": "2",
      "memberAur": "2"
    },
    {
      "recencyType": "44.00,66.00",
      "monetaryType": null,
      "crowdNum": 1,
      "crowdRatio": "0.3333",
      "memberAtv": "79",
      "memberAov": "79",
      "memberAur": "79"
    },
    {
      "recencyType": "44.00,66.00",
      "monetaryType": "56.66,84.99",
      "crowdNum": 1,
      "crowdRatio": "0.3333",
      "memberAtv": "79",
      "memberAov": "79",
      "memberAur": "79"
    }
  ],
  "rmAnalysisField": {
    "recencyInterval": {
      "intervals": [
        {
          "start": "0",
          "end": "22"
        },
        {
          "start": "22",
          "end": "44"
        },
        {
          "start": "44",
          "end": "66"
        },
        {
          "start": "66",
          "end": "88"
        },
        {
          "start": "88",
          "end": "110"
        }
      ],
      "deadline": "110"
    },
    "monetaryInterval": {
      "initialAmount": "28.33",
      "intervals": [
        {
          "start": "28.33",
          "end": "56.66"
        },
        {
          "start": "56.66",
          "end": "84.99"
        },
        {
          "start": "84.99",
          "end": "113.32"
        },
        {
          "start": "113.32",
          "end": "141.65"
        }
      ],
      "finalAmount": "141.65"
    }
  }
};
type FetchData = typeof mockData;

const tableTabList = [
  {
    key: '1',
    label: '客户数（占比）',
  },
  {
    key: '2',
    label: '客单价',
  },
  {
    key: '3',
    label: '笔单价',
  },
  {
    key: '4',
    label: '件单价',
  },
];

const getDataIndexM = (obj: { isStart: boolean; value: number } | { start: number; end: number } | { isFinal: boolean; value: number }) => {
  // 如果 obj 有 isStart 属性或者 isFinal 属性，返回 value
  if ('isTotal' in obj) {
    return null;
  }
  if ('isStart' in obj || 'isFinal' in obj) {
    return `${obj.value}`;
  }
  return `${Number(obj.start).toFixed(2)},${Number(obj.end).toFixed(2)}`;
};

const getResIndex = (v: null | string): any => {
  if (v === null) {
    return null;
  }
  if (v.includes(',')) {
    return `${Number(v.split(',')[0]).toFixed(2)},${Number(v.split(',')[1]).toFixed(2)}`;
  }
  return Number(v).toFixed(2);
}

const defaultColumns: TableColumnsType<{
  [propsName: string]: string | number;
}> = [
    {
      title: '购买金额（元）',
      key: 'monetaryType',
      width: 250,
      fixed: 'left',
      render: (_, record) => {
        if (record.isStart) {
          return <TextWithQuestion
            black
            title={`M<${record.value}`}
            tips={`购买金额小于${record.value}元`}
          />;
        }
        if (record.isFinal) {
          return <TextWithQuestion
            black
            title={`M≥${record.value}`}
            tips={`购买金额大于等于${record.value}元`}
          />;
        }
        if (record.isTotal) {
          return <TextWithQuestion
            black
            title="合计"
          />;
        }
        return <TextWithQuestion
          black
          title={`${record.start}≤M<${record.end}`}
          tips={`购买金额大于等于${record.start}小于${record.end}元`}
        />;
      },
    },
    {
      title: '购买客户数',
      dataIndex: 'recentThreeYearsPurchaseCustomerNum',
      key: 'recentThreeYearsPurchaseCustomerNum',
      width: 180,
      fixed: 'left',
    },
    {
      title: '购买间隔',
    },
  ];

const isNullORundefined = (v: any) => {
  return v === null || v === undefined;
};

const RM: FC<{ id: number }> = (props) => {
  const [tableTabKey, seTableTabKey] = useState('1');
  const [columns, setColums] = useState(defaultColumns);
  const [fetchData, setFetchData] = useState<FetchData>();
  const [loading, setLoaidng] = useState(false);
  const [drawerMVisible, setDrawerMVisible] = useState(false);
  const [drawerRVisible, setDrawerRVisible] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>();
  const [mData, setMData] = useState<({ isStart: boolean; value: number | string } | { start: number | string; end: number | string } | { isFinal: boolean; value: number | string })[]>(); // 仅用于修改M
  const [rData, setRData] = useState<(number | { start: number; end: number })[]>(); // 仅用于修改R
  const changeMRef = useRef<{ value: any }>(null);
  const changeRRef = useRef<{ value: any }>(null);

  const getColumns = () => {
    const { rmAnalysisField, rmAnalysisData } = fetchData!;
    const { recencyInterval } = rmAnalysisField;
    let { intervals } = recencyInterval;
    if (!intervals) {
      intervals = [];
    }
    const newColumnsChildren = [...intervals, recencyInterval.deadline].map((i) => {
      if (typeof i === 'object') {
        return {
          title: <TextWithQuestion
            black
            title={`${formatNum(i.start)}天≤R＜${formatNum(i.end)}天`}
            tips={`最近一次消费间隔大于等于${formatNum(i.start)}天小于${formatNum(i.end)}天`}
          />,
          key: `${formatNum(i.start)}天<R≤${formatNum(i.end)}天`,
          render: (_: string, record: any) => {
            const dataIndexR = `${Number(i.start).toFixed(2)},${Number(i.end).toFixed(2)}`;
            const dataIndexM = getDataIndexM(record);
            const cur = rmAnalysisData.find((j) => getResIndex(j.recencyType) === dataIndexR && getResIndex(j.monetaryType) === dataIndexM);
            if (!cur) {
              return <div>
                {tableTabKey === '1' && <p>0人</p>}
                {tableTabKey === '1' && <p>占比0%</p>}
                {tableTabKey === '2' && <p>0元</p>}
                {tableTabKey === '3' && <p>0元</p>}
                {tableTabKey === '4' && <p>0元</p>}
              </div>
            }
            return <div>
              {tableTabKey === '1' && <p>{formatNum(cur.crowdNum, { digit: 0 })}人</p>}
              {tableTabKey === '1' && <p>占比{formatNum(cur.crowdRatio, { isRatio: true })}</p>}
              {tableTabKey === '2' && <p>{formatNum(cur.memberAtv)}元</p>}
              {tableTabKey === '3' && <p>{formatNum(cur.memberAov)}元</p>}
              {tableTabKey === '4' && <p>{formatNum(cur.memberAur)}元</p>}
            </div>;
          },
        };
      }
      return {
        title: <TextWithQuestion
          black
          title={`R≥${formatNum(i)}天`}
          tips={`最近一次消费间隔大于等于${formatNum(i)}天`}
        />,
        key: `${i}`,
        render: (_: string, record: any) => {
          const dataIndexR = `${Number(i).toFixed(2)}`;
          const dataIndexM = getDataIndexM(record);
          const cur = rmAnalysisData.find((j) => getResIndex(j.recencyType) === dataIndexR && getResIndex(j.monetaryType) === dataIndexM);
          if (!cur) {
            return <div>
              {tableTabKey === '1' && <p>0人</p>}
              {tableTabKey === '1' && <p>占比0%</p>}
              {tableTabKey === '2' && <p>0元</p>}
              {tableTabKey === '3' && <p>0元</p>}
              {tableTabKey === '4' && <p>0元</p>}
            </div>
          }
          return <div>
            {tableTabKey === '1' && <p>{formatNum(cur.crowdNum, { digit: 0 })}人</p>}
            {tableTabKey === '1' && <p>占比{formatNum(cur.crowdRatio, { isRatio: true })}</p>}
            {tableTabKey === '2' && <p>{formatNum(cur.memberAtv)}元</p>}
            {tableTabKey === '3' && <p>{formatNum(cur.memberAov)}元</p>}
            {tableTabKey === '4' && <p>{formatNum(cur.memberAur)}元</p>}
          </div>;
        },
      };
    });
    const newColumns = columns.map((i) => {
      if (i.title === '购买间隔') {
        return {
          ...i,
          children: newColumnsChildren,
        };
      }
      return i;
    });
    // 修改 最近 3 年购买客户数
    newColumns[1].render = (_: any, record: any) => {
      const cur = rmAnalysisData.find((j) => {
        return j.recencyType === null && getResIndex(j.monetaryType) === getDataIndexM(record);
      });
      if (!cur) {
        return <div>
          {<p>0人</p>}
          {<p>占比0%</p>}
          {/* {tableTabKey === '2' && <p>0元</p>}
          {tableTabKey === '3' && <p>0元</p>}
          {tableTabKey === '4' && <p>0元</p>} */}
        </div>
      }
      return <div>
        {<p>{formatNum(cur.crowdNum, { digit: 0 })}人</p>}
        {<p>占比{formatNum(cur.crowdRatio, { isRatio: true })}</p>}
        {/* {tableTabKey === '2' && <p>{formatNum(cur.memberAtv)}元</p>}
        {tableTabKey === '3' && <p>{formatNum(cur.memberAov)}元</p>}
        {tableTabKey === '4' && <p>{formatNum(cur.memberAur)}元</p>} */}
      </div>;
    };
    setRData([...intervals.map((i) => ({
      start: Number(i.start),
      end: Number(i.end),
    })), Number(recencyInterval.deadline)]);
    setColums(newColumns);
  };
  const getDataSource = () => {
    const { rmAnalysisField } = fetchData!;
    let { monetaryInterval } = rmAnalysisField;
    const newDataSource = [{ isStart: true, value: Number(monetaryInterval.initialAmount).toFixed(2) }, ...(monetaryInterval?.intervals ?? []).map((i) => ({ start: Number(i.start).toFixed(2), end: Number(i.end).toFixed(2) })), { isFinal: true, value: Number(monetaryInterval.finalAmount).toFixed(2) }].map((i) => i);
    setMData(newDataSource);
    setDataSource(newDataSource.length >= 1 ? [...newDataSource, { isTotal: true, value: null }] : newDataSource);
  };
  const getData = () => {
    setLoaidng(true);
    getRMReportApi(props.id).then((res) => {
      setFetchData(mockData);
      if (res?.code !== 200) {
        setFetchData([] as any);
        return;
      }
      setFetchData(res.data);
    }).finally(() => {
      setLoaidng(false);
    });
  };

  const updateRMReport = (r: typeof rData, m: typeof mData) => {
    if (!r || !m) {
      return;
    }
    updateRMReportApi({
      monetaryInterval: {
        initialAmount: (m?.find((i) => 'isStart' in i) as any)!.value,
        finalAmount: (m?.find((i) => 'isFinal' in i) as any)!.value,
        intervals: m?.filter((i) => 'start' in i).map((i) => ({
          start: (i as { start: number; end: number }).start,
          end: (i as { start: number; end: number }).end,
        })) as any,
      },
      recencyInterval: {
        deadline: r?.find((i) => typeof i === 'number')! as number,
        intervals: r?.filter((i) => typeof i === 'object').map((i) => ({
          start: (i as { start: number; end: number }).start,
          end: (i as { start: number; end: number }).end,
        })) as any,
      },
      subReportId: String(props.id),
    }).then((res) => {
      if (res?.code !== 200) {
        return;
      }
      message.success('修改成功');
      setDrawerMVisible(false);
      setDrawerRVisible(false);
      getData();
    });
  };

  const onCloseDrawerM = () => {
    setDrawerMVisible(false);
  };
  const onSubmitM = () => {
    const value: ({ isStart: boolean; value: number } | { start: number; end: number } | { isFinal: boolean; value: number })[] = changeMRef.current?.value;
    if (value.some((i: any) => ('isStart' in i || 'isFinal' in i) ? isNullORundefined(i.value) : (isNullORundefined(i.start) || isNullORundefined(i.end)))) {
      message.error('起始金额和截止金额不能为空');
      return;
    }
    updateRMReport(rData, value);
  };

  const onCloseDrawerR = () => {
    setDrawerRVisible(false);
  };
  const onSubmitR = () => {
    const value: (number | { start: number; end: number })[] = changeRRef.current?.value;
    if (value.some((i: any) => typeof i === 'object' ? (isNullORundefined(i.start) || isNullORundefined(i.end)) : isNullORundefined(i))) {
      message.error('起始时间和截止时间不能为空');
      return;
    }
    updateRMReport(value, mData);
  };

  useEffect(() => {
    if (!fetchData) {
      return;
    }
    getColumns();
    getDataSource();
  }, [fetchData, tableTabKey]);

  useEffect(() => {
    if (!props?.id) {
      return;
    }
    getData();
  }, [props?.id]);

  return (
    <div className={styles['RM']}>
      {/* 表格底层tab */}
      <Tabs
        activeKey={tableTabKey}
        items={tableTabList}
        onChange={(e) => seTableTabKey(e)}
        type="card"
        style={{ marginTop: 12 }}
      />
      <div className={styles['btn-box']}>
        <Button onClick={() => { setDrawerMVisible(true); }} style={{ marginRight: 8 }}>调M</Button>
        <Button onClick={() => { setDrawerRVisible(true); }}>调R</Button>
      </div>
      <Table
        rowKey={(record) => ('isStart' in record || 'isFinal' in record) ? record.value : `${record.start}-${record.end}`}
        pagination={false}
        style={{ marginTop: 8 }}
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        scroll={{
          x: columns.reduce((pre, cur) => {
            if ((cur as any).children) {
              return pre + (cur as any).children.length * 220;
            }
            return pre + (cur?.width as number ?? 220);
          }, 0)
        }}
      />
      <Drawer
        width={450}
        title={<TextWithQuestion black title="编辑自定义分布" tips={<div>
          <p style={{ margin: 0 }}>提示：</p>
          <p style={{ margin: 0 }}>1：输入数值区间范围（元）：0.00-999,999,999.99</p>
          <p style={{ margin: 0 }}>2：数值区间输入浮点型。如400.36、500.66....</p>
          <p style={{ margin: 0 }}>3：建议您输入的金额区间为连续区间，所分析出来的数据 更有研究价值。</p>
        </div>} />}
        onClose={onCloseDrawerM}
        open={drawerMVisible}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={onCloseDrawerM}>取消</Button>
            <Button type="primary" onClick={onSubmitM}>确定</Button>
          </Space>
        }
      >
        <ChangeM ref={changeMRef} />
      </Drawer>
      <Drawer
        width={450}
        title={<TextWithQuestion black title="编辑自定义分布" tips={<div>
          <p style={{ margin: 0 }}>提示：</p>
          <p style={{ margin: 0 }}>1：输入数值区间范围（天）：0.0-1095.0</p>
          <p style={{ margin: 0 }}>2：数值区间输入int型。如4、5、6....</p>
          <p style={{ margin: 0 }}>3：建议您输入的时间区间为连续区间，所分析出来的数据 更有研究价值。</p>
          <p style={{ margin: 0 }}>如：[0.0，21.0）天、[21.0，42.0)天、[42.0，63.0）天、≥63天。</p>
        </div>} />}
        onClose={onCloseDrawerR}
        open={drawerRVisible}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={onCloseDrawerR}>取消</Button>
            <Button type="primary" onClick={onSubmitR}>确定</Button>
          </Space>
        }
      >
        <ChangeR ref={changeRRef} />
      </Drawer>
    </div>
  );
};

export default RM;