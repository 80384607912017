import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Table, Button, Dropdown, Space, Modal, message, Popconfirm, Tooltip } from "antd";
import type { TableProps } from "antd";
import { FilterOutlined , EllipsisOutlined } from "@ant-design/icons";
import ModuleTitle from "../../../../component/ModuleTitle";
import CreateGroup from "./components/CreateGroup";
import AssociatedAccount from "./components/AssociatedAccount";
import FilterView from "../../../../component/FilterView/filterView";
import SearchIconInput from "../../../../component/Input/SearchIconInput/searchIconInput";
import { getUserGroupList, deleteUserGroup } from "../../../../api/group/group";
import { checkHasRoleApi } from "../../../../api/role/role";
import { UserGroupQuery, GroupListVO } from "../../../../api/group/types";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import ButtonPermissions from '../../../../component/Button/SystemButton';
import { SYS_KEY } from '../../../../router/Permissions';
import { checkPermissions } from "@/utils/utils";
import { getTableScroll } from '@/store/index';

const UserGroupManagement = () => {
  const filterViewRef: any = useRef(null);

  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入用户组名称或者用户组ID",
      title: "用户组名称/ID",
      key: "idOrName",
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      placeHolder: "",
      title: "创建时间",
      key: "createTimeStart-createTimeEnd",
    },
  ];

  // 查询参数
  interface SearcParamType {
    createTimeStart: string;
    createTimeEnd: string;
    idOrName: string;
  }

  const searcParamInt: SearcParamType = {
    createTimeStart: "",
    createTimeEnd: "",
    idOrName: "",
  };

  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  const filterFinish = (data: any) => {
    setSearcParam(data);
    getUserGroupListData({ pageSize: 10, pageNum: 1 }, data, {
      orderByColumn: "updateTime",
      isAsc: "desc",
    });
  };

  const navigate = useNavigate();

  // 新建 & 编辑
  const [flag, setFlag] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [editData, setEditData] = useState({} as GroupListVO);

  // 新建&编辑关闭
  const getOpenStatus = (v: boolean) => {
    setCreateOpen(v);
    setEditData({} as GroupListVO);
  };

  // 新建&编辑成功
  const submitSuccess = (v: boolean) => {
    setCreateOpen(v);
    if (flag === "create") {
      getUserGroupListData(
        { pageSize: 10, pageNum: 1 },
        { ...searcParamInt },
        { orderByColumn: "updateTime", isAsc: "desc" }
      );
    } else {
      getUserGroupListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam, sortValue);
    }
  };

  // 新建用户组
  const addGroups = async () => {
    const res = await checkHasRoleApi();
    console.log(res, 'res用户组');

    if (res && res.code == 200) {
      setFlag("create");
      setCreateOpen(true);
    } else if (res.code == 500) { //401-登陆超时 需要重新登陆 不弹创建弹窗
      setCreatRoleOpen(true);
    }
  };

  // 去创建角色弹窗提示
  const [creatRoleOpen, setCreatRoleOpen] = useState(false);

  // 关联账号
  const [associationOpen, setAssociationOpen] = useState(false);

  // 编辑
  const editItem = (item: GroupListVO) => {
    setFlag("edit");
    setEditData(item);
    setCreateOpen(true);
  };

  // 关联账号
  const associationItem = (item: any) => {
    setEditData(item);
    setAssociationOpen(true);
  };

  // 关联账号——关闭
  const getAssociationOpen = (v: boolean) => {
    setAssociationOpen(v);
    getUserGroupListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam, sortValue);
  };

  const getTableOperationButtonList = (item: GroupListVO) => {

    const store = []
    if (checkPermissions(SYS_KEY.GROUP_EDIT)) {
      store.push({
        key: "edit",
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              editItem(item);
            }}
          >
            编辑
          </a>
        ),
      })
    }
    if (checkPermissions(SYS_KEY.GROUP_ADDUSER)) {
      store.push({
        key: "association",
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              associationItem(item);
            }}
          >
            关联账号
          </a>
        ),
      })
    }
    if (item.userCount === 0 && checkPermissions(SYS_KEY.GROUP_DEL)) {
      store.push(
        {
          key: "delete",
          label: (
            <a
              style={{ color: 'var( --text-font-color9)' }}
              onClick={(e) => {
                setSelectedTableKey(item.id);
                setTableConfirmText();
                setResetConfirm(true);
                e.nativeEvent.stopPropagation();
              }}
            >
              删除
            </a>
          ),
        }
      );
    };

    return store;
  };

  const [tableConfig, setTableConfig] = useState({} as any);
  const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number);
  const [resetConfirm, setResetConfirm] = useState(false);

  //设置标特操作提示框文案
  const setTableConfirmText = () => {
    setTableConfig({
      title: "确定删除此用户组吗？",
      okText: "确定",
      cancelText: "取消",
      description: "",
    });
  };

  const handleConfirmClick = async (item: GroupListVO) => {
    const param = {
      id: item.id,
    };
    const res = await deleteUserGroup(param);
    if (res && res.data) {
      message.success("删除成功");
      getUserGroupListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam, sortValue);
    } else {
      message.error(res.msg);
    }
  };

  const handleCancelClick = (item: GroupListVO, type: string) => {
    setResetConfirm(false);
  };


  const columns: TableProps<GroupListVO>["columns"] = [
    {
      title: "用户组ID",
      dataIndex: "id",
      key: "id",
      width: 100,
      fixed: "left",
    },
    {
      title: "用户组名称",
      dataIndex: "name",
      key: "name",
      width: "170px",
      fixed: "left",
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "用户组说明",
      dataIndex: "instruction",
      key: "instruction",
      width: 250,
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      key: "createTime",
      // width: "190px",
      sorter: true,
    },
    {
      title: "功能角色",
      dataIndex: "roleIds",
      key: "roleIds",
      width: 250,
      render: (_: any, record: any) => (
        <Tooltip title={_} placement="topLeft">
          <span className='line-text-two'>{_}</span>
        </Tooltip>
      )
    },
    {
      title: "关联账号数（个）",
      dataIndex: "userCount",
      key: "userCount",
      // width: "180px",
      sorter: true,
    },
    {
      title: "更新时间",
      dataIndex: "updateTime",
      key: "updateTime",
      // width: "190px",
      sorter: true,
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{ items: getTableOperationButtonList(record) }}
            trigger={["click"]}
          >
            <Popconfirm
              placement="right"
              className={styles.popconfirmBox}
              title={tableConfig.title}
              okText={tableConfig.okText}
              cancelText={tableConfig.cancelText}
              description={tableConfig.description}
              onConfirm={() => handleConfirmClick(record)}
              onCancel={() => handleCancelClick(record, tableConfig.type)}
              open={selectedTableKey === record.id && resetConfirm}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
              okButtonProps={{ loading: false }}
            >
              <div 
                  onMouseLeave={handleMouseLeavePoint}
                  onMouseEnter={()=>handleMouseEnterPoint(record)}
                  className={styles[`line-three-point`]}
                  style={{backgroundColor:checkUserId == record.id ? iconColorPointBack:''}}
              >
                  <EllipsisOutlined 
                      style={{
                          color: checkUserId == record.id ?'var(--text-font-color33)':'var(--text-font-color34)',
                          cursor:'pointer'
                      }}
                  />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  //获取用户组列表
  const [listData, setListData] = useState([] as GroupListVO[]);
  const getUserGroupListData = async (
    page: { pageSize: number; pageNum: number },
    data: any,
    sort: { orderByColumn: string; isAsc: string }
  ) => {
    const param = {
      ...data,
      ...page,
      ...sort,
    } as UserGroupQuery;
    const { total, rows } = await getUserGroupList(param);
    setListData(rows);
    setScrollY(getTableScroll())
    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
  };

  // 排序
  const [sortValue, setSortValue] = useState({
    orderByColumn: "updateTime", // 默认按照更新时间倒序排列
    isAsc: "desc",
  });

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }

    getUserGroupListData(
      { pageSize: pagination.pageSize, pageNum: pagination.current },
      searcParam,
      sortStore
    );
  };

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
  };
  //过滤器
  const [iconColor, setIconColor] = useState('var(--text-font-color4)');
  const handleMouseEnter = () => {
    // console.log('鼠标移入')
    setIconColor('var(--text-font-color9)');
  };
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setIconColor('var(--text-font-color4)');
  };
  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i:any) => {
      setCheckUserId(i.id)
      setIconColorPointBack('var(--text-font-color35)');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('')
      setIconColorPointBack('');
  };
  const [scrollY, setScrollY] = useState("")
  const getHeight = (e: string) => {
    console.log(e);
    setScrollY(e)
  }
  useEffect(() => {
    getUserGroupListData({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam, sortValue);

    document.addEventListener('click', hidden)

    return () => {
      document.removeEventListener('click', hidden)
    }

  }, []);

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="用户组管理" />
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            placeholder="请输入用户组名称/ID"
            onSearch={(e: string) => {
              const store: SearcParamType = {
                ...searcParamInt,
                idOrName: e,
              };
              filterViewRef.current?.resetData();
              setSearcParam(store);
              getUserGroupListData({ pageSize: 10, pageNum: 1 }, store, {
                orderByColumn: "updateTime",
                isAsc: "desc",
              });
            }}
          ></SearchIconInput>
          <Tooltip placement="bottom" title={'过滤器'}>
            <Button
              onClick={() => filterViewRef.current?.showFilterView()}
              icon={
                <FilterOutlined
                  style={{
                    color: iconColor,
                    fontSize: 'var(--text-font7)',
                  }}
                />
              }
              type="text"
              className="click-element"
              style={{ marginRight: '10px' }}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
          </Tooltip>
          <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span>
          <ButtonPermissions
            type="primary"
            onClick={addGroups}
            title={`新建用户组`}
            permissions={SYS_KEY.GROUP_ADD}
          ></ButtonPermissions>
        </div>
      </div>
      <div className={styles.userGroupManagement}>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          getHeight={getHeight}
        ></FilterView>
        <div>
          <Table
            style={{ width: "100%" }}
            columns={columns}
            dataSource={listData}
            rowKey={record => record.id}
            scroll={pageQuery.pageSize > 10 ? { x: 1750, y: scrollY } : { x: 1750, y: scrollY }}
            pagination={{
              pageSize: pageQuery.pageSize,
              total: pageQuery.total,
              showTotal: (total) => `共${total}条`,
              showSizeChanger: true,
            }}
            onChange={handleStandardTableChange}
          />
        </div>
        <Modal
          title="提示"
          open={creatRoleOpen}
          onCancel={() => setCreatRoleOpen(false)}
          footer={null}
        >
          <p className={styles.createRolePromit}>
            还未创建角色，请先创建角色
            <span
              onClick={() =>
                navigate("/files/originalDataAccess/dataAccess/details")
              }
            >
              前往角色管理
            </span>
          </p>
        </Modal>
        <CreateGroup
          createOpen={createOpen}
          flag={flag}
          editData={editData}
          getOpenStatus={getOpenStatus}
          submitSuccess={submitSuccess}
        />
        <AssociatedAccount
          associationOpen={associationOpen}
          editData={editData}
          getAssociationOpen={getAssociationOpen}
        />
      </div>
    </div>
  );
};

export default UserGroupManagement;
