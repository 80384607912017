import React, { useEffect, useState } from 'react';
import { Input, Drawer, Space, Button, Form, Radio, Popconfirm, message } from 'antd';
import checkPermission from '../../assets/public/checkPermission';
import { getConfigAddOrUpdateApi, getConfigListApi } from '../../api/distReward';
import { LOYALTY_KEY } from '../../../router/Permissions';

type FieldType = {};
let rewardChangeStatus = React.forwardRef(
  (
    props: {
      open?: boolean;
      configList: any;
      drawer?: Function;
    },
    ref: any,
  ) => {
    // 关闭抽屉的时候清空所有状态
    useEffect(() => {
      // getConfigList();
      setConfigList(props.configList);
    }, [props.configList]);
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        setConfigList(props.configList);
        props.drawer();
      }
    };
    const [configList, setConfigList] = useState<any>([]);
    const configInfo = [{ label: '当奖励条件重复时，奖励积分策略采用' }, { label: '奖励成长值策略采用' }];

    const handleRadioChange = async (index: any, e: any) => {
      const updatedConfigList = [...configList];
      updatedConfigList[index].configValue = e;
      // 更新state中的configList
      setConfigList(updatedConfigList);
      console.log(updatedConfigList);
    };
    const handleOkButton = async () => {
      await getConfigAddOrUpdateApi(configList).then(res => {
        if (res?.code === 200) {
          message.success('修改成功');
          handleDrawerSwitch();
        }
      });
    };
    return (
      <div>
        <Drawer
          width={600}
          title="重复奖励策略设置"
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button disabled={!checkPermission(LOYALTY_KEY.EVENT_CONFIG_UPDATE)} type="primary" onClick={handleOkButton}>
                确定
              </Button>
            </Space>
          }>
          <div style={{ marginTop: '24px' }}>
            <Form labelCol={{ span: 11 }} layout="horizontal" style={{ maxWidth: 600 }}>
              {configList.map((config: any, index: any) => (
                <Form.Item key={index} label={configInfo[index].label}>
                  <Radio.Group onChange={e => handleRadioChange(index, e.target.value)} value={config.configValue}>
                    <Radio value="1"> 叠加奖励 </Radio>
                    <Radio value="2"> 单笔交易最高获得 </Radio>
                  </Radio.Group>
                </Form.Item>
              ))}
            </Form>
          </div>
        </Drawer>
      </div>
    );
  },
);
export default rewardChangeStatus;
