//@ts-nocheck
import { type FC, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Drawer,
  Space,
  Input,
  Table,
  Tooltip,
  message,
  InputNumber,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { getNumberWithComma, handleValuePercent } from "@/utils/utils";
import { TimeTitle } from "@/crm/component/DataBoardStyles";
import styles from "./index.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import {getInsightAnalysis as getInsightAnalysisApi, getReferenceValueByReportId} from "@/crm/api/dataBoardApi";
import { QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import good from "@/assets/images/good.png";
import bad from "@/assets/images/bad.png";
import { useLocation } from "react-router-dom";
import {
  addRFMReferenceValue,
  getRFMList,
  createCrowdByRFM,
} from "@/crm/api/dataBoardApi";

const RFM: FC<{ id: number }> = (props) => {
  let location = useLocation();
  const { TextArea } = Input;
  const id = location?.state?.id;
  const [subReportId, setSubReportId] = useState(0)
  const [open, setOpen] = useState(false);
  const [createCrowdDrawer, setCreateCrowdDrawer] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [createCrowdRecord, setCreateCrowdRecord] = useState(tableData[0]);
  const [benchmarkType, setBenchmarkType] = useState("R");
  const [benchmarkNum, setBenchmarkNum] = useState();
  const [crowdName, setCrowdName] = useState(""); // 人群名称
  const [crowdDesc, setCrowdDesc] = useState(""); // 备注
  // rfm搜索默认值
  const defaultRFMSearchValue = {
    recencyNum: null,
    frequencyNum: null,
    monetaryNum: null,
  };
  const [recentBuyAllOptions, setRecentBuyAllOptions] = useState([]); // 最近购买时长基准值全部下拉项
  const [buyDurationAllOptions, setBuyDurationAllOptions] = useState([]); // 购买频次基准值全部下拉项
  const [buyAmountAllOptions, setBuyAmountAllOptions] = useState([]); // 消费金额基准值全部下拉项
  const [listParams, setListParams] = useState(defaultRFMSearchValue);
  interface DataType {
    userLayeredName: string;
    recency: string;
    frequency: string;
    monetary: string;
    peopleNum: number;
    peopleRatio: number;
    userLayeredRemarks: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "用户分层",
      dataIndex: "userLayeredName",
      key: "userLayeredName",
      render: (value, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            height: "14px",
            lineHeight: 'var(--text-font5)',
          }}
        >
          <span style={{ marginRight: "10px" }}>{value}</span>
          <Tooltip title={record.userLayeredRemarks}>
            <QuestionCircleOutlined
              style={{
                margin: "0 4px",
                color: "var(--text-font-color4)",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "最近购买时长",
      dataIndex: "recency",
      key: "recency",
      render: (value, record, index) => heightLowRender(value, record, index),
    },
    {
      title: "购买频次",
      dataIndex: "frequency",
      key: "frequency",
      render: (value, record, index) => heightLowRender(value, record, index),
    },
    {
      title: "消费金额",
      dataIndex: "monetary",
      key: "monetary",
      render: (value, record, index) => heightLowRender(value, record, index),
    },
    {
      title: "人数",
      dataIndex: "peopleNum",
      key: "peopleNum",
      render: (value) => (
        <span>{getNumberWithComma(value, 0)}</span>
      ),
    },
    {
      title: "占比",
      dataIndex: "peopleRatio",
      key: "peopleRatio",
      render: (value) => (
        <span>{`${handleValuePercent(value)}%`}</span>
      ),
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => {
              setCreateCrowdDrawer(true);
              setCreateCrowdRecord(record);
            }}
          >
            新建分群
          </a>
        </Space>
      ),
    },
  ];
  // 高/低 列渲染
  const heightLowRender = (value: string) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          height: "14px",
          lineHeight: 'var(--text-font5)',
        }}
      >
        <span style={{ marginRight: 10 }}>{value}</span>
        {value === "低" && <img src={bad}  alt=''/>}
        {value === "高" && <img src={good}  alt=''/>}
      </div>
    );
  };
  const getRFMReportDetails = async () => {
    const detailsRes: any = await getInsightAnalysisApi(id);
    if (detailsRes?.code !== 200) {
      return;
    }
    setSubReportId(detailsRes.data?.rfmReport?.id);
    await getReferenceValue(detailsRes.data?.rfmReport?.id)
  };
  useEffect(() => {
    getRFMReportDetails()
  }, []);

  // 列表
  const getRFM = async (frequencyNum, monetaryNum, recencyNum,_subReportId='') => {
    const params = {
      subReportId:_subReportId || subReportId,
      frequencyNum: listParams.frequencyNum ?? frequencyNum,
      monetaryNum: listParams.monetaryNum ?? monetaryNum,
      recencyNum: listParams.recencyNum ?? recencyNum,
    };
    if (!(params.frequencyNum && params.monetaryNum && params.recencyNum)) {
      message.warning("查询条件不能为空");
      return false;
    }
    await getRFMList(params).then((res) => {
      if (res?.code === 200) {
        setTableData(res.data);
      }
    });
  };

  // 获取各类型基准值
  const getReferenceValue = async (_subReportId= '') => {
    await getReferenceValueByReportId(props.id).then((res) => {
      if (res?.code === 200) {
        let RArr = [];
        res.data?.recencyItemList?.forEach((item) => {
          if (item.numType === "MEAN") {
            RArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}天（均值）`,
              value: item.benchmarkNum,
            });
          } else if (item.numType === "MEDIAN") {
            RArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}天（中位数）`,
              value: item.benchmarkNum,
            });
          } else {
            RArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}天`,
              value: item.benchmarkNum,
            });
          }
        });
        let FArr = [];
        res.data?.frequencyItemList?.forEach((item) => {
          if (item.numType === "MEAN") {
            FArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}次（均值）`,
              value: item.benchmarkNum,
            });
          } else if (item.numType === "MEDIAN") {
            FArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}次（中位数）`,
              value: item.benchmarkNum,
            });
          } else {
            FArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}次`,
              value: item.benchmarkNum,
            });
          }
        });
        let MArr = [];
        res.data?.monetaryItemList?.forEach((item) => {
          if (item.numType === "MEAN") {
            MArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}元（均值）`,
              value: item.benchmarkNum,
            });
          } else if (item.numType === "MEDIAN") {
            MArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}元（中位数）`,
              value: item.benchmarkNum,
            });
          } else {
            MArr.push({
              label: `${getNumberWithComma(item?.benchmarkNum, 2)}元`,
              value: item.benchmarkNum,
            });
          }
        });
        setRecentBuyAllOptions(RArr);
        setBuyDurationAllOptions(FArr);
        setBuyAmountAllOptions(MArr);
        setListParams({
          recencyNum: RArr[0]?.value,
          frequencyNum: FArr[0]?.value,
          monetaryNum: MArr[0]?.value,
        });
        if (RArr[0]?.value && FArr[0]?.value && MArr[0]?.value) {
          getRFM(FArr[0]?.value, MArr[0]?.value, RArr[0]?.value, _subReportId);
        }
      }
    });
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCloseCrowdDrawer = () => {
    setOpen(false);
    setCreateCrowdDrawer(false);
    setCrowdName("");
    setCrowdDesc("");
    setBenchmarkNum();
  };
  const addBenchmarkValue = async () => {
    const params = {
      benchmarkType,
      benchmarkNum,
      subReportId,
    };
    await addRFMReferenceValue(params)
      .then((res) => {
        if (res?.code === 200) {
          message.success("添加成功");
          setBenchmarkNum("");
          getReferenceValue();
        }
      })
      .catch(() => {
        message.error("该基准值已存在");
        return false;
      });
    setOpen(false);
  };

  const setBenchmark = (value: any) => {
    setBenchmarkNum(value);
  };
  const handleCreateCrowd = async () => {
    if (crowdName === "") {
      message.warning("分群名称不能为空");
      return false;
    }
    if (createCrowdRecord.peopleNum === 0) {
      message.warning("分群大小为0不能生成分群");
      return false;
    }
    const params = {
      subReportId,
      requestType: "RFM",
      requestValue: JSON.stringify({
        rfmInterval: {
          avgMonetary: listParams.monetaryNum,
          avgRecency: listParams.recencyNum,
          avgFrequency: listParams.frequencyNum,
          userLayer: createCrowdRecord.userLayered?.split(","),
        },
      }),
      userLayer: createCrowdRecord.userLayeredName,
      crowdName: crowdName,
      crowdDesc: crowdDesc,
    };
    await createCrowdByRFM(params).then((res) => {
      if (res?.code === 200) {
        message.success("分群创建成功");
      }
    });
    onCloseCrowdDrawer();
  };
  return (
    <div className={styles.RFM}>
      <div style={{ marginTop: "8px" }}>
        <TimeTitle title="RFM客户分层分析" noTime />
        <div className={styles["rfm-search-bgd"]}>
          <Form style={{ width: "100%" }}>
            <Row gutter={[32, 0]}>
              <Col span={8}>
                <Form.Item
                  label="最后一次购买时长"
                  style={{ marginBottom: "16px" }}
                >
                  <Select
                    value={listParams.recencyNum}
                    onChange={(val) => {
                      setListParams({
                        ...listParams,
                        recencyNum: val,
                      });
                    }}
                    style={{ width: "90%" }}
                    options={recentBuyAllOptions}
                  />
                  <Button
                    className={styles.addBtn}
                    onClick={() => {
                      showDrawer();
                      setBenchmarkType("R");
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="购买频次" style={{ marginBottom: "16px" }}>
                  <Select
                    value={listParams.frequencyNum}
                    onChange={(val) => {
                      setListParams({
                        ...listParams,
                        frequencyNum: val,
                      });
                    }}
                    style={{ width: "90%" }}
                    options={buyDurationAllOptions}
                  />
                  <Button
                    className={styles.addBtn}
                    onClick={() => {
                      showDrawer();
                      setBenchmarkType("F");
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="消费金额" style={{ marginBottom: "16px" }}>
                  <Select
                    style={{ width: "90%" }}
                    options={buyAmountAllOptions}
                    value={listParams.monetaryNum}
                    onChange={(val) => {
                      setListParams({
                        ...listParams,
                        monetaryNum: val,
                      });
                    }}
                  />
                  <Button
                    className={styles.addBtn}
                    onClick={() => {
                      showDrawer();
                      setBenchmarkType("M");
                    }}
                  >
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </Col>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  onClick={() => {
                    setListParams({
                      recencyNum: recentBuyAllOptions[0].value,
                      frequencyNum: buyDurationAllOptions[0].value,
                      monetaryNum: buyAmountAllOptions[0].value,
                    });
                    getRFM({
                      recencyNum: recentBuyAllOptions[0].value,
                      frequencyNum: buyDurationAllOptions[0].value,
                      monetaryNum: buyAmountAllOptions[0].value,
                    });
                  }}
                >
                  重置
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "8px" }}
                  onClick={() => getRFM({ ...listParams })}
                >
                  查询
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <Table columns={columns} dataSource={tableData} />
      </div>
      <Drawer
        title="生成人群"
        placement="right"
        onClose={onCloseCrowdDrawer}
        open={open}
        extra={
          <Space>
            <Button onClick={onCloseCrowdDrawer}>取消</Button>
            <Button type="primary" onClick={addBenchmarkValue}>
              确定
            </Button>
          </Space>
        }
      >
        {benchmarkType === "R" && (
          <div>
            <p>最后一次购买时长</p>
            <InputNumber
              controls={false}
              style={{ width: "100%" }}
              min={0}
              max={1095}
              placeholder="请输入最后一次购买时长"
              value={benchmarkNum}
              onChange={setBenchmark}
            />
            <p className={styles.tipBg}>
              <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
              输入数值区间范围（天）：0.0-1095.0
            </p>
          </div>
        )}
        {benchmarkType === "F" && (
          <div>
            <p>购买频次</p>
            <InputNumber
              controls={false}
              style={{ width: "100%" }}
              min={1}
              max={20}
              placeholder="请输入购买频次"
              value={benchmarkNum}
              onChange={setBenchmark}
            />
            <p className={styles.tipBg}>
              <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
              输入数值区间范围（次）：1.0-20.0
            </p>
          </div>
        )}
        {benchmarkType === "M" && (
          <div>
            <p>消费金额</p>
            <InputNumber
              controls={false}
              style={{ width: "100%" }}
              min={0}
              max={999999999.99}
              placeholder="请输入消费金额"
              value={benchmarkNum}
              onChange={setBenchmark}
            />
            <p className={styles.tipBg}>
              <ExclamationCircleOutlined style={{ marginRight: '5px' }} />
              输入数值区间范围（元）：0.00-999,999,999.99
            </p>
          </div>
        )}
      </Drawer>
      <Drawer
        title="新建分群"
        placement="right"
        onClose={onCloseCrowdDrawer}
        open={createCrowdDrawer}
        extra={
          <Space>
            <Button onClick={onCloseCrowdDrawer}>取消</Button>
            <Button type="primary" onClick={handleCreateCrowd}>
              确定
            </Button>
          </Space>
        }
      >
        <span className={styles.Required}>*</span>分群名称:
        <Input
          maxLength={50}
          placeholder="请输入分群名称"
          value={crowdName}
          style={{ margin: "8px 0 24px 0" }}
          onChange={(e) => setCrowdName(e.target.value)}
        />
        描述:
        <TextArea
          rows={2}
          maxLength={50}
          style={{ margin: "8px 0 24px 0" }}
          value={crowdDesc}
          onChange={(e) => setCrowdDesc(e.target.value)}
        />
        <div style={{ marginBottom: 24 }}>创建方式：RFM创建</div>
        <div>
          分群人数：
          <span className={styles.crowdNum}>
            {createCrowdRecord?.peopleNum}
          </span>
          人
        </div>
      </Drawer>
    </div>
  );
};

export default RFM;
