import { AxiosPromise } from 'axios';
import request from '../network'

//客户洞察-客户交集一览-单
export function getPlatFormInfoApi(params: any): AxiosPromise<any> {
  return request({
    url: 'collect/customerIntersect/getPlatFormInfo',
    method: 'post',
    data: params
  });
}

//客户洞察-客户交集一览-全
export function getUserPropertyListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'collect/customerIntersect/getPlatFormIntersectList',
    method: 'post',
    data: params
  });
}

//系统设置 - 授权管理
export function getSysVersionListApi(): AxiosPromise<any> {
  return request({
    url: 'system/sysVersion/list',
    method: 'get',
  });
}

//系统设置 - 修改提醒日期
export function getSysVersionReviseDateApi(query:any): AxiosPromise<any> {
  return request({
    url: 'system/sysVersion/reviseDate',
    method: 'get',
    params: query

  });
}

//系统设置 - 外观设置
export function getSysVersionAppearanceApi(): AxiosPromise<any> {
  return request({
    url: 'system/sysVersion/appearance',
    method: 'get',
  });
}
//系统设置 - 外观设置2
export function getSysVersionAppearanceViewApi(): AxiosPromise<any> {
  return request({
    url: 'system/sysVersion/appearanceView',
    method: 'get',
  });
}
//系统设置 - 外观设置保存
export function getSysVersionAppearanceUpdateApi(query:any): AxiosPromise<any> {
  return request({
    url: 'system/sysVersion/appearanceUpdate',
    method: 'get',
    params: query

  });
}

// 上传图片
export function handUploadImgApi(query: any): AxiosPromise<any> {
  return request({
      url: '/resource/oss/upload',
      headers: {
          'Content-Type': 'multipart/form-data',
      },
      method: 'post',
      data: query,
  });
}

//系统设置 - 查询当前用户所有消息
export function getUserMessageApi(): AxiosPromise<any> {
  return request({
    url: 'system/sysMessage/getUserMessage',
    method: 'get',
  });
}

//系统设置 - 修改状态
export function getUpdateMessageApi(query:any): AxiosPromise<any> {
  return request({
    url: 'system/sysMessage/updateMessage',
    method: 'get',
    params: query
  });
}