import React, { useState, useEffect } from "react";
import { Drawer, Button,Space, Checkbox , ConfigProvider, Popconfirm } from "antd";
import type { DrawerProps, TableColumnsType } from "antd";
import {
  updCustomerListTitle
} from "../../../../../../api/UserInsight/UserInsight";
import {
  AccountListQuery,
  AccountListQueryVO,
} from "../../../../../../api/UserInsight/types";
import styles from "./index.module.scss";
const EditField = (props: {
  openEditField: boolean;
  closeOpenEditField: Function;
  popType: any;
  refirmTabel: Function;
  checkFieldList: any;
}) => {
  const { openEditField, closeOpenEditField = () => {} } = props;

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  const [open, setOpen] = useState(false);

  const [checkData, setCheckData] = useState([] as any);
  const [editFieldList, setEditFieldList] = useState([
    { label: 'OneID', value: 'oneId' , disabled:true},
    { label: '手机号', value: 'mobile', disabled:true }, 
    { label: '所属会员卡', value: 'memberCardName' , disabled:true},
    { label: '所属渠道', value: 'channelType' , disabled:true},
    { label: '客户身份', value: 'identity' , disabled:false},
    { label: '会员等级', value: 'gradeId', disabled:false },
    { label: '当前积分', value: 'totalPoint' , disabled:false },
    { label: '当前成长值', value: 'growthValue' , disabled:false },
    { label: '累计消费金额', value: 'tradeAmount' , disabled:false },
    { label: '累计下单次数', value: 'tradeCount' , disabled:false },
  ] as any);
  // 关闭当前抽屉
  const onClose = () => {
    closeOpenEditField(false);
  };
  //确定
  const submit = async () => {
    var param = {
      configTitle:JSON.stringify(checkData)
    }
    const res = await updCustomerListTitle(param)
    props.refirmTabel(checkData)
    closeOpenEditField(false);
    // if (res.code === 200) {
    //     message.success(flag === 'edit' ? '编辑成功' : '创建成功');
    //     onClose()
    //     submitSuccess(false);
    // }
  };

  // 关闭提示弹窗
  const hidden = () => {
  };
  // 改变多选框
  const onChange = (e:any) => {
    setCheckData(e)
  };
  useEffect(() => {
    setOpen(openEditField);
  }, [openEditField]);
  useEffect(() => {
    document.addEventListener('click', hidden)

    return () => {
        document.removeEventListener('click', hidden)
    }
  }, []);

  return (
    <div>
      <Drawer
        title={'编辑字段配置'}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
            <Button type="primary" onClick={submit}>
              确定
            </Button>
          </Space>
        }
      >
        <div>
          <div className={styles.editField_text}>字段显示只会更改当前账号下的显示情况，并在下次登录时保持所选状态。不会影响其他账号的显示</div>
          {/* <Checkbox.Group
            className={styles.editField_checkBox}
            options={editFieldList}
            defaultValue={props.checkFieldList}
            onChange={onChange}
          /> */}
          <ConfigProvider 
            theme={{
              components: {
                Checkbox: {
                  lineHeight: 2.7,
                }
              },
            }}
          >
            <Checkbox.Group
              options={editFieldList}
              defaultValue={props.checkFieldList}
              onChange={onChange}
            />
          </ConfigProvider>
        </div>
      </Drawer>
    </div>
  );
};

export default EditField;
