import { AxiosPromise } from 'axios';
import request from '../network'
import { GroupVO, GroupListVO, roleVO, AccountListQuery,  AccountListQueryVO} from './types';

// 获取所有用户
export function getUserGroupListApi(): AxiosPromise<GroupVO[]> {
    return request({
        url: `/system/sysUserGroup/selectAllUserGroup`,
        method: 'GET',
        data: {}
    });
}


//获取用户组列表
export function getUserGroupList(query: any): AxiosPromise<GroupListVO[]> {
  return request({
      url: '/system/sysUserGroup/getUserGroupList',
      method: 'get',
      params: query,
  })
}


// 获取所有角色
export function selectAllRole(): AxiosPromise<roleVO[]> {
  return request({
      url: '/system/sysUserGroup/selectAllRole',
      method: 'get',
      params: {},
  })
}


// 添加用户组
export function addOrEditUserGroup(query:any): AxiosPromise<roleVO[]> {
  return request({
      url: '/system/sysUserGroup/addOrEditUserGroup',
      method: 'post',
      data: query,
  })
}
// 编辑用户组
export function EditUserGroup(query:any): AxiosPromise<roleVO[]> {
  return request({
      url: '/system/sysUserGroup/EditUserGroup',
      method: 'post',
      data: query,
  })
}

// 删除用户组
export function deleteUserGroup(query: any) {
  return request({
      url: '/system/sysUserGroup/deleteUserGroup',
      method: 'get',
      params: query,
  })
}

// 获取用户组账号列表
export function getAccountList(query: AccountListQuery): AxiosPromise<AccountListQueryVO[]> {
  return request({
      url: '/system/sysUserGroup/selectUserGroupUser',
      method: 'get',
      params: query,
  })
}

// 移除账号
export function removeAccount(query: any){
  return request({
      url: '/system/sysUserGroup/removeAccount',
      method: 'get',
      params: query,
  })
}

// 获取用户组外的用户列表
export function selectNotContainUserList(query: any){
  return request({
      url: '/system/sysUserGroup/selectNotContainUserList',
      method: 'get',
      params: query,
  })
}

// 添加账号
export function addAccount(query: any){
  return request({
      url: '/system/sysUserGroup/addAccount',
      method: 'get',
      params: query,
  })
}

export default {}