import { AxiosPromise } from 'axios';
import request from '../network'

//维度模型列表查询
export function getDimensionListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/dimension/page/list',
    method: 'post',
    data: params
  });
}

//维度模型属性查询
export function getDimensionPropertyListApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/dimension/property/page/list',
    method: 'post',
    data: params
  });
}

//维度模型枚举查询
export function getDimensionDetailInfotApi(params: any): AxiosPromise<any> {
  return request({
    url: 'label/business/detailInfo',
    method: 'post',
    data: params
  });
}
//维度模型详情查询
export function getDimensionDetailApi(data: any): AxiosPromise<any> {
  return request({
    url: 'label/dimension/property/page/detail',
    method: 'get',
    params: data
  });
}
//下载数据
export function skuGoodsExportApi(params?: any): AxiosPromise<any> {
  return request({
    url: 'collect/goodsCollect/originalProductTemplateExport',
    method: 'post',
    data: params
  });
}
// 上传
export function updateGoodsByExcelApi(params?: any): AxiosPromise<any> {
  return request({
    url: 'collect/goodsCollect/importOriginalProductData',
    method: 'post',
    data: params
  });
}