//客户模型
import React, { useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import type { TableProps } from 'antd';
import { Table, Button } from 'antd';
import ModuleTitle from '../../../component/ModuleTitle';
import { getUserCenterListApi } from '../../../api/userCenter/userCenter';
import UserProperty from './components/UserProperty';
import UserBehavior from './components/UserBehavior';
import { getTableScroll } from '@/store/index'
type FieldType = {
  createOrder?: string;
  updateOrder?: string;
};
const defaultFormValue = {
  createOrder: '',
  updateOrder: '',
};
function UserCenter() {
  const [tableList, setTableList] = useState<any[]>([]);
  const [isShowBehavior, setIsShowBehavior] = useState(false);
  const [isShowProperty, setIsShowProperty] = useState(false);
  const [searchInfo, setSearchInfo] = useState<FieldType>(defaultFormValue);
  const [scrollY, setScrollY] = useState("")
  interface DataType {
    id: number;
    tableNameEn: string;
    tableNameCn: string;
    tableDescribe: string;
    createBy: string;
    createTime: string;
    updateTime: string;
    action: string;
  }
  const getTableList = async () => {
    const res = await getUserCenterListApi();
    if (res?.code === 200) {
      setTableList(res.data || []);
      setScrollY(getTableScroll())
    }
  };

  useEffect(() => {
    getTableList();
  }, [JSON.stringify(searchInfo)]);

  const columns: TableProps<DataType>['columns'] = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id',
      width: '88px',
      render: (_, record, index) => <div>{index + 1}</div>,
    },
    {
      title: '表名',
      dataIndex: 'tableNameEn',
      key: 'tableNameEn',
      width: '250px',
      render: (_: any, record: any) => (
        <div className={style['tableNameEn']} onClick={() => jumpCorrespondingPage(record)}>
          {record.tableNameEn}
        </div>
      ),
    },
    {
      title: '展示名',
      dataIndex: 'tableNameCn',
      key: 'tableNameCn',
      width: '200px',
    },
    {
      title: '描述',
      dataIndex: 'tableDescribe',
      key: 'tableDescribe',
      width: '300px',
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
      width: '200px',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: '200px',
      sorter: (a, b) => new Date(a.createTime).getTime() - new Date(b.createTime).getTime(),
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '200px',
      sorter: (a, b) => new Date(a.updateTime).getTime() - new Date(b.updateTime).getTime(),
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      render: (_, record) => (
        // 暂定0是禁用, 1是启用
        <div>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() => {
              jumpCorrespondingPage(record);
            }}>
            查看
          </Button>
        </div>
      ),
    },
  ];
  const jumpCorrespondingPage = (record: any) => {
    switch (record.tableNameCn) {
      case '客户行为表':
        setIsShowBehavior(true);
        break;
      case '客户属性表':
        setIsShowProperty(true);
        break;
      default:
        break;
    }
  };
  const pageChange = (page: any, pageSize: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }
  };

  // 表格部分
  const TableSection = () => {
    return (
      <div style={{ marginTop: '24px' }}>
        <Table rowKey = 'id' columns={columns} scroll={{ x: 1400, y: scrollY }} dataSource={tableList} onChange={(page, pageSize, sort) => pageChange(page, pageSize, sort)} />
      </div>
    );
  };

  if (isShowBehavior) {
    return <UserBehavior handBackToList={() => setIsShowBehavior(false)} />;
  }
  if (isShowProperty) {
    return <UserProperty handBackToList={() => setIsShowProperty(false)} />;
  }
  return (
    <>
      <div className={style['titleAndOperate']}>
        <ModuleTitle mark="large" title="客户模型" />
      </div>
      <div className={style['container']}>
        <TableSection />
      </div>
    </>
  );
}

export default UserCenter;
