
import React, { ReactNode, useEffect, useRef, useState, useImperativeHandle, forwardRef } from "react";
import style from './index.module.scss'
import { message } from "antd"; //Progress
import { Chart } from '@antv/g2';
import _ from "lodash";

let Histogram = forwardRef((props: {
    selectedChart?: number,
    listData?: any,
}, ref: any) => {
    // 暴露方法给父组件
    useImperativeHandle(ref, () => ({

    }))
    //监听或进入调用
    useEffect(() => {

        props.listData.sort((a: any, b: any) => a.labelValueCount - b.labelValueCount);
        props.listData.sort((a: any, b: any) => a.opTime.replace(/\./g, '') - b.opTime.replace(/\./g, ''));

        const chart = new Chart({
            container: 'histogram',
            autoFit: true,
        });

        if (props.selectedChart === 1) {

            const a = _.maxBy(props.listData, function (o: any) { return o.labelValueCount })
            chart
                .data(props.listData)
                .encode('x', 'opTime')
                .encode('y', 'labelValueCount')
                .scale('y', {
                    domain: [0, a.labelValueCount > 0 ? a.labelValueCount : 100],
                    nice: true,
                })
                .encode('color', 'labelValueName')
                .legend('color', { maxRows: '3' })
                .axis('y', { title: false })
                .axis('x', { title: false, labelTransform: 'rotate(0)' })
        } else {
            chart
                .data(props.listData)
                .encode('x', 'opTime')
                .encode('y', 'labelValueCount')
                .encode('color', 'labelValueName')
                .legend('color', { maxRows: '3' })
                .axis('y', { title: false })
                .axis('x', { title: false, labelTransform: 'rotate(0)' })
        }







        if (props.selectedChart === 0) {
            chart
                .interval()
                .transform({ type: 'stackY' })
                .interaction('tooltip', { shared: true, sort: (d: any) => -d.value })
                .tooltip((data) => ({
                    name: data.labelValueName,
                    value: data.labelValueCount
                }))
                .style('maxWidth', 100)
                .interaction('elementHighlight', { background: true });
        } else {
            chart.line().encode('shape', 'smooth');
            chart.point().encode('shape', 'point').tooltip(false);
        }

        chart.render();
    }, [props.listData, props.selectedChart])

    return <>
        <div id="histogram"></div>
    </>
})
export default Histogram
