/*
 * @Descripttion:
 * @Author: yang chenglin
 * @LastEditors: yang chenglin
 */
// 营销子系统
import SubApp from '../pages/SubApp/index'

const marketPath = [
    { url: '/private/canvas/activities', menu: true },
    { url: '/private/canvas/activity', menu: true, key: '/private/canvas/activities' },
    { url: '/private/canvas/activities/data/analysis', menu: true },
    { url: '/private/canvas/activities/results/data', menu: true },
    { url: '/private/member/life/cycle/integral/change', menu: true },
    { url: '/private/member/life/cycle/integral/change/create', menu: true, key: '/private/member/life/cycle/integral/change' },
    { url: '/private/member/life/cycle/join/care', menu: true },
    { url: '/private/member/life/cycle/join/care/create', menu: true, key: '/private/member/life/cycle/join/care' },
    { url: '/private/member/life/cycle/upgrade/care', menu: true },
    { url: '/private/member/life/cycle/upgrade/care/create', menu: true, key: '/private/member/life/cycle/upgrade/care' },
    { url: '/private/member/life/cycle/demotion/care', menu: true },
    { url: '/private/member/life/cycle/demotion/care/create', menu: true, key: '/private/member/life/cycle/demotion/care' },
    { url: '/private/sms/account/recharge', menu: true },
    { url: '/private/sms/sign/list', menu: true },
    { url: '/private/sms/charge/records', menu: true },
    { url: '/private/sms/consumption/records', menu: true },
    { url: '/private/sms/phone/send/records', menu: true },
    { url: '/private/sms/intelligent/send/records', menu: true },
    { url: '/private/sms/intelligent/assistant', menu: true },
]

const marketRouteList = marketPath.map(p => (
    { path: '/market' + p.url, name: p, component: < SubApp />, auth: true, layout: true, menu: p.menu, parentKey: p.key ? `/market${p.key}` : '' }))

export default marketRouteList
