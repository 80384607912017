import React from 'react';
import { Button, Progress, Dropdown } from "antd";
import type { MenuProps } from "antd";
import MiniLabel from "../../../../../../component/MiniLabel";
import styles from './index.module.scss';

const DataCard = (props: {
  cardData?: any,
  tableItemdata: any
}) => {
  const { cardData, tableItemdata } = props;


  // 1追加历史数据, 2停用后补齐数据
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.itemText}>
          <div>平台类型：{tableItemdata.platformTypeLabel}</div>
          <div>店铺ID：{tableItemdata.shopId}</div>
          <div>数据表名称：{cardData.datatableNames}</div>
          <div>追加数据方式：{cardData.useType === '0' ? '追加全部历史数据' : '自定义追加历史数据'}</div>
          <div>追加数量：{cardData.totalNum} 条</div>
          <div>追加数据时间：{`${cardData.startTime}-${cardData.endTime}`}</div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.cardItems}>
      <div className={styles.headBox}>
        <div className={styles.baseInfo}>
          <div className={styles.dataName}>{cardData.jobName}</div>
        </div>
        <div className={styles.operate}>
          <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
            <Button type="link">查看详情</Button>
          </Dropdown>
        </div>
      </div>
      <div className={styles.bodyBox}>
        <div className={styles.progressBox}>
          <Progress percent={cardData.progressPercentage} status="exception" />
        </div>
        <div className={styles.timeText} style={{ marginBottom: 8 }}>任务开始时间：{cardData.startTime}</div>
        <div className={styles.timeText}>任务结束时间：{cardData.endTime}</div>
      </div>
    </div>
  );
};

export default DataCard