export const getQueryParam = (url: string, param: string) => {
    // 创建URL对象
    const urlObj = new URL(url);
    // 使用searchParams获取查询参数
    const searchParams = new URLSearchParams(urlObj.search);
    // 获取指定参数的值
    return searchParams.get(param);
}

export const removeElementByKey = (arr: any[], keyToRemove: string) => {
    arr.map(item => {
        if (item.children && item.children.length > 0) {
            item.children = removeElementByKey(item.children, keyToRemove)
        }
    })
    const a = arr.filter(item => {
        return item.key.toString() !== keyToRemove.toString()
    })
    return a
}

export const setPositionNumber = (arr: any[], str: string) => {
    arr.map((item, index) => {
        if (str === '') {
            item.position = `${index}`
        } else {
            item.position = `${str}-${index}`
        }
        if (item.children && item.children.length > 0) {
            item.children = setPositionNumber(item.children, item.position)
        }
    })
    return arr
}