import React from 'react';
// import './index.css'
import style from './index.module.scss'
import Iconfont from '../../../Icon';
import { state } from '../../../../store';
import { useNavigate } from 'react-router-dom';



const MenuItem = (props: {
    menuItemObj: any,
    selected: Boolean,
    index: number
},) => {
    const navigate = useNavigate();
    //选择主菜单，跳转到第三级菜单的首个菜单页面
    const handleMenuItemClick = () => {
        //跳转菜单的时候 清空客户洞察或者分群的查询条件
        localStorage.removeItem('pageNum')
        localStorage.removeItem('pageSize')
        localStorage.removeItem('searchListData')
        localStorage.removeItem('phoneorId')
        const a = state.menu[props.index].children;
        if (a && a.length > 0) {
            const b = a[0].children
            if (b && b.length > 0) {
                navigate(b[0].key.toString())
            }
        } else {
            navigate(state.menu[props.index].key.toString())
        }
    }

    console.log(props.menuItemObj.icon);

    const Icon = props.menuItemObj.icon
    return (
        <div className={`${style['menu-item']} click-element ${props.selected ? style['lz_main-menu-selected'] : ''} `} onClick={handleMenuItemClick}>
            {Icon && <Iconfont className={style["menu-icon"]} icon={Icon}></Iconfont>}

            {/* {props.menuItemObj.key !== 'systemSettingManagement' && <span className={style['memu-text']}>{props.menuItemObj.ezName}</span>} */}
            <span className={style['memu-text']}>{props.menuItemObj.ezName}</span>
        </div>
    );
}

export default MenuItem;