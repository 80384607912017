import { useContext } from "react";
import style from "./index.module.scss";
import {
  Input,
  Radio,
  Table,
  Switch,
  Button,
  Badge,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import {
  ContainerOutlined,
  RadarChartOutlined,
  SlidersOutlined,
  PlusOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import {
  PageTitle,
  PageItem,
  RadioDatePicker,
  TextWithQuestion,
  CardDatePicker,
  CardSelect,
} from "../../../../../../component/MiniStyles";
import { MyContext } from "../../index";
import ImageUpload from "../../../../../../component/ImageUpload";

// 等级计算数组
const gradeRulesList = [
  {
    type: 1,
    icon: <RadarChartOutlined style={{ color: "var(--text-font-color7)" }} />,
    title: "按成长值计算",
    text: "按成长值计算会员等级，可以考虑多种因素对会员的影响，包括消费、活跃度、参与度、社交影响等，更加全面。",
    disabled: false,
  },
  {
    type: 2,
    icon: <ContainerOutlined style={{ color: "var(--text-font-color7)" }} />,
    title: "按订单计算",
    text: "按订单交易计算会员等级，可以根据会员的实际消费行为进行计算，更加贴近会员的实际需求和购买行为。",
    disabled: false,
  },
  {
    type: 3,
    icon: <SlidersOutlined style={{ color: "var(--text-font-color7)" }} />,
    title: "按<动态值>计算",
    text: "按<动态值>计算会员等级，可以根据品牌的具体需求，更加定制化的计算会员等级。",
    disabled: true,
  },
];

// 赠送类型下拉框
const giveTypeList = [
  { label: "不赠送", value: 3 },
  { label: "交易倍数", value: 2 },
  { label: "定额", value: 1 },
];

// 默认数字输入框参数
const inputNumberProps = {
  min: 1,
  maxLength: 7,
  controls: false,
  max: 9999999.99,
};

// 时间选择下拉
const expireYearOptions = [
  { value: 0, label: "每" },
  { value: 1, label: "每隔1" },
  { value: 2, label: "每隔2" },
  { value: 3, label: "每隔3" },
  { value: 4, label: "每隔4" },
  { value: 5, label: "每隔5" },
  { value: 6, label: "每隔6" },
  { value: 7, label: "每隔7" },
  { value: 8, label: "每隔8" },
  { value: 9, label: "每隔9" },
  { value: 10, label: "每隔10" },
];

const PageTwo = ({ readOnly = false }: { readOnly?: boolean | undefined }) => {
  const {
    data: pageInfo,
    pageStatus,
    myContextChange,
    myContextChangeObj,
    myContextChangeArr,
    myContextChangeArrLength,
  } = useContext(MyContext);
  const {
    calculateType,
    growthValueConfig,
    orderComputeConfig,
    keepGradeConfig,
    gradeList,
    keepGradeFlag,
  } = pageInfo;
  const updateStatus = pageStatus === "update";

  // 成长值
  const {
    growthValueOtherName,
    expireType,
    expireNum,
    expireYear,
    expireDate,
    dealGrowthValue,
    refundFlag,
    oneMaxFlag,
    oneMaxValue,
  } = growthValueConfig;

  // 订单
  const { computeType, computeStartTime } = orderComputeConfig;

  // 保级
  const {
    amountDays,
    orderAmount,
    goodsDays,
    goodsName,
    amountFlag,
    goodsFlag,
  } = keepGradeConfig;
  // 获得上传图片地址
  const getImageUrl = (url: string, index: number | undefined) => {
    if (readOnly) return;
    myContextChangeArr("gradeList", index, "gradeImgUrl", url || "");
  };

  // 删除图片
  const handDeleteImg = (index: number) => {
    if (readOnly) return;
    myContextChangeArr("gradeList", index, "gradeImgUrl", "");
  };

  // 等级计算规则
  const GradeRulesSection = () => {
    return (
      <div className={style["section-bgd"]}>
        <PageTitle>等级计算规则</PageTitle>
        <div className={style["card-list-bgd"]}>
          {gradeRulesList.map((item) => (
            <div
              key={item.type}
              className={`${
                style[
                  `grade-rule-card-${
                    calculateType === item.type
                      ? true
                      : readOnly || updateStatus
                      ? "disabled"
                      : "false"
                  }`
                ]
              } 
              ${item.disabled ? style["grade-rule-card-disabled"] : ""}`}
              onClick={() => {
                if (item.type === 3 || readOnly || updateStatus) return;
                myContextChange("calculateType", item.type);
              }}>
              <div className={style["card-title"]}>
                {item.icon}
                <span>{item.title}</span>
              </div>
              <div className={style["card-text"]}>{item.text}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // 有效期选择框
  const getGrowthChoose = () => {
    const month = expireDate?.split("-")[0] || "--";
    const day = expireDate?.split("-")[1] || "--";
    return (
      <Radio.Group
        value={expireType}
        disabled={readOnly || updateStatus}
        onChange={(e) =>
          myContextChangeObj(
            "growthValueConfig",
            "expireType",
            e.target.value,
            "expireNum",
            null,
            "expireYear",
            0,
            "expireDate",
            ""
          )
        }>
        <Radio value={1}>
          获得后
          <InputNumber
            className={style["input-short"]}
            value={expireNum || ""}
            min={0}
            maxLength={3}
            max={730}
            disabled={readOnly || updateStatus || expireType !== 1}
            controls={false}
            precision={0}
            onChange={(e) =>
              myContextChangeObj("growthValueConfig", "expireNum", e)
            }
          />
          天内有效
        </Radio>
        <div className={style["tips"]}>
          客户获取当天计算为第一天，{Math.floor(expireNum)}天内有效
        </div>
        <br />
        <Radio value={2} style={{ margin: "16px 0" }}>
          <CardSelect
            options={expireYearOptions}
            disabled={readOnly || updateStatus || expireType !== 2}
            value={expireYear}
            onChange={(e) =>
              myContextChangeObj("growthValueConfig", "expireYear", e)
            }
          />
          年{expireYear === 0 ? "" : "的"}
          <CardDatePicker
            expireYear={expireYear}
            expireDate={expireDate}
            disabled={readOnly || updateStatus || expireType !== 2}
            onChange={(e) =>
              myContextChangeObj("growthValueConfig", "expireDate", e)
            }
          />
          内有效
        </Radio>
        <div className={style["tips"]} style={{ margin: "-12px 0 12px 0" }}>
          <div>
            每笔成长值在{expireYear === 0 ? "今" : expireYear}年
            {expireYear === 0 ? "" : "后"}的{month}月{day}
            日内有效。
            <br />
            举例：2024年1月1日~
            {expireYear === 0
              ? `${expireYear + 2024}年${month}月
            ${day}
            日`
              : "2024年12月31日"}
            获得的成长值，在 {(Math.floor(expireYear) || 0) + 2024}年{month}月
            {day}
            日有效。
          </div>
        </div>
        <br />
        <Radio value={3}>永久有效</Radio>
      </Radio.Group>
    );
  };

  // 成长值设置
  const getGrowthSettingSection = () => {
    return (
      <div className={style["section-bgd"]}>
        <PageTitle>成长值设置</PageTitle>
        <div
          className={style["section-content-bgd"]}
          // style={{ marginLeft: "calc(50% - 50px)" }}
          >
          <PageItem title="成长值设置别称" require={false}>
            <Input
              value={growthValueOtherName}
              className={style["input-long"]}
              maxLength={10}
              showCount
              disabled={readOnly}
              onChange={(e) =>
                myContextChangeObj(
                  "growthValueConfig",
                  "growthValueOtherName",
                  e.target.value
                )
              }
            />
          </PageItem>
          <PageItem title="成长值有效期">{getGrowthChoose()}</PageItem>
          <PageItem title="基础规则">
            成功交易1元获得
            <InputNumber
              {...inputNumberProps}
              className={style["input-short"]}
              disabled={readOnly || updateStatus}
              value={dealGrowthValue || ""}
              precision={0}
              onChange={(e) =>
                myContextChangeObj("growthValueConfig", "dealGrowthValue", e)
              }
            />
            成长值
          </PageItem>
          <PageItem title="退款扣除">
            <Switch
              checkedChildren="开"
              unCheckedChildren="关"
              disabled={readOnly || updateStatus}
              checked={refundFlag === 1}
              onChange={(e) =>
                myContextChangeObj("growthValueConfig", "refundFlag", e ? 1 : 0)
              }
              style={{ marginTop: "5px" }}
            />
          </PageItem>
          <PageItem title="单次发放上限">
            <Radio.Group
              disabled={readOnly || updateStatus}
              value={oneMaxFlag}
              onChange={(e) =>
                myContextChangeObj(
                  "growthValueConfig",
                  "oneMaxFlag",
                  e.target.value,
                  "oneMaxValue",
                  null
                )
              }>
              <Radio value={1}>
                单笔交易最高获得
                <InputNumber
                  {...inputNumberProps}
                  precision={0}
                  className={style["input-short"]}
                  disabled={readOnly || updateStatus || oneMaxFlag !== 1}
                  value={oneMaxValue || ""}
                  onChange={(e) =>
                    myContextChangeObj("growthValueConfig", "oneMaxValue", e)
                  }
                />
                成长值
              </Radio>
              <br />
              <Radio value={0}>关闭</Radio>
            </Radio.Group>
          </PageItem>
        </div>
      </div>
    );
  };

  // 订单设置
  const getOrderSettingSection = () => {
    return (
      <div className={style["section-bgd"]}>
        <PageTitle>订单设置</PageTitle>
        <div className={style["section-content-bgd"]}>
          <PageItem title="等级计算时间">
            <Radio.Group
              disabled={readOnly || updateStatus}
              value={computeType}
              onChange={(e) =>
                myContextChangeObj(
                  "orderComputeConfig",
                  "computeType",
                  e.target.value,
                  "computeStartTime",
                  ""
                )
              }>
              <Radio value={1}>
                从
                <RadioDatePicker
                  disabled={readOnly || updateStatus || computeType !== 1}
                  value={computeStartTime}
                  onChange={(e) =>
                    myContextChangeObj(
                      "orderComputeConfig",
                      "computeStartTime",
                      e
                    )
                  }
                />
                开始计算
              </Radio>
              <Radio value={2}>
                <TextWithQuestion
                  title="近一年"
                  tips="选择从固定时间开始计算，会员等级永久保留；选择近一年计算，会员等级将按照一年内完成订单计算。"
                />
              </Radio>
            </Radio.Group>
          </PageItem>
        </div>
      </div>
    );
  };

  // 等级设置
  const getGradeSettingSection = () => {
    const UploadButton = ({ src }: { src: string }) => (
      <div>
        {src ? (
          <img src={src} alt="" />
        ) : (
          <button
            disabled={readOnly}
            style={{
              border: 0,
              background: "none",
              cursor: `${readOnly ? "not-allowed" : "pointer"}`,
            }}
            type="button">
            <PlusOutlined style={{ color: "var(--text-font-color28)" }} />
            <div style={{ color: "var(--text-font-color28)" }}>上传</div>
          </button>
        )}
      </div>
    );

    return (
      <div className={style["section-bgd"]}>
        <PageTitle>等级设置</PageTitle>
        <Table
          dataSource={gradeList}
          style={{ width: "825px", marginBottom: "16px" }}
          pagination={false}>
          <Table.Column
            title="级别"
            render={(value, record, index) => <span>VIP{index + 1}</span>}
          />
          <Table.Column
            title={
              <TextWithQuestion
                title="图标"
                tips="请上传大小为44×44px，格式为PNG、JPG的图片"
              />
            }
            render={(value, record: any, index) => {
              return (
                <Badge
                  count={
                    record.gradeImgUrl && !readOnly ? (
                      <CloseCircleTwoTone
                        onClick={() => handDeleteImg(index)}
                      />
                    ) : (
                      ""
                    )
                  }>
                  <div className={style["upload"]}>
                    <ImageUpload
                      disabled={readOnly}
                      imgIndex={index}
                      getImageUrl={getImageUrl}>
                      <UploadButton src={record.gradeImgUrl} />
                    </ImageUpload>
                  </div>
                </Badge>
              );
            }}
          />
          <Table.Column
            title="等级名称"
            dataIndex="gradeName"
            render={(value, record, index) => (
              <Input
                className={style["input-short"]}
                maxLength={10}
                disabled={readOnly}
                value={value}
                style={{ width: "120px" }}
                onChange={(e) =>
                  myContextChangeArr(
                    "gradeList",
                    index,
                    "gradeName",
                    e.target.value
                  )
                }
              />
            )}
          />
          <Table.Column
            title="升级规则"
            render={(value, record: any, index) => {
              if (index === 0) {
                return "初次开卡";
              } else {
                if (calculateType === 1) {
                  return (
                    <div>
                      成长值≥
                      <InputNumber
                        {...inputNumberProps}
                        precision={0}
                        className={style["input-short"]}
                        disabled={readOnly || updateStatus}
                        value={record.growthValue || ""}
                        onChange={(e) =>
                          myContextChangeArr(
                            "gradeList",
                            index,
                            "growthValue",
                            e
                          )
                        }
                      />
                    </div>
                  );
                }
                return (
                  <div>
                    消费金额满
                    <InputNumber
                      min={0}
                      max={999999999}
                      controls={false}
                      precision={0}
                      className={style["input-short"]}
                      style={{ width: "70px" }}
                      disabled={readOnly || updateStatus}
                      value={record.orderAmount || ""}
                      onChange={(e) =>
                        myContextChangeArr("gradeList", index, "orderAmount", e)
                      }
                    />
                    元 或者 完成订单量满
                    <InputNumber
                      {...inputNumberProps}
                      precision={0}
                      className={style["input-short"]}
                      style={{ width: "70px" }}
                      disabled={readOnly || updateStatus}
                      value={record.orderCount || ""}
                      onChange={(e) =>
                        myContextChangeArr("gradeList", index, "orderCount", e)
                      }
                    />
                    笔
                  </div>
                );
              }
            }}
          />
          <Table.Column
            title="操作"
            render={(value, record, index) => {
              return index > 0 ? (
                <Button
                  type="link"
                  disabled={readOnly || updateStatus}
                  onClick={() =>
                    myContextChangeArrLength("gradeList", "sub", index)
                  }>
                  删除
                </Button>
              ) : (
                ""
              );
            }}
          />
        </Table>
        <Button
          type="primary"
          disabled={gradeList.length >= 5 || readOnly || updateStatus}
          onClick={() => myContextChangeArrLength("gradeList", "add")}>
          添加等级（{gradeList.length}/5）
        </Button>
      </div>
    );
  };

  // 成长值赠送规则
  const getGrowthGiveRules = () => {
    return (
      <div className={style["section-bgd"]}>
        <PageTitle>成长值赠送规则</PageTitle>
        <Table
          dataSource={gradeList}
          style={{ width: "825px", marginBottom: "16px" }}
          pagination={false}>
          <Table.Column
            title="级别"
            render={(value, record, index) => <span>VIP{index + 1}</span>}
          />
          <Table.Column
            title="等级名称"
            dataIndex="gradeName"
            render={(value) => value}
          />
          <Table.Column
            title="赠送类型"
            dataIndex="giveType"
            render={(value, record, index) => (
              <div>
                每笔交易额外赠送
                <Select
                  className={style["input-short"]}
                  style={{ width: "100px" }}
                  options={giveTypeList}
                  disabled={readOnly}
                  value={value}
                  onChange={(e) => {
                    myContextChangeArr(
                      "gradeList",
                      index,
                      "giveType",
                      e,
                      "giveNum",
                      null
                    );
                  }}
                />
              </div>
            )}
          />
          <Table.Column
            title="成长值"
            width={250}
            render={(value, record: any, index) => {
              if (record.giveType !== 3) {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <InputNumber
                      {...inputNumberProps}
                      precision={record.giveType === 2 ? 1 : 0}
                      maxLength={record.giveType === 2 ? 5 : 7}
                      max={record.giveType === 2 ? 200 : 9999999}
                      disabled={readOnly}
                      value={record.giveNum || ""}
                      className={style["input-short"]}
                      style={record.giveType === 2 ? { width: "100px" } : {}}
                      addonAfter={record.giveType === 2 ? "%" : ""}
                      onChange={(e) =>
                        myContextChangeArr("gradeList", index, "giveNum", e)
                      }
                    />
                    <TextWithQuestion
                      title="成长值"
                      tips={
                        record.giveType === 2
                          ? `客户实付100元(不含运费)，实得${
                              100 + record.giveNum
                            }成长值`
                          : ""
                      }
                    />
                  </div>
                );
              }
            }}
          />
        </Table>
      </div>
    );
  };

  // 保级逻辑
  const getProtectionLogic = () => {
    return (
      <div className={style["section-bgd"]}>
        <PageTitle>保级逻辑</PageTitle>
        <div className={style["section-content-bgd"]}>
          <div
            className={style["ProtectionLogic-text"]}
            style={{ fontSize: "var(--text-font5)", width: "568px" }}>
            是否开启保级
            <Switch
              disabled={readOnly || updateStatus}
              checkedChildren="开"
              unCheckedChildren="关"
              style={{ margin: "-4px 0 0 8px" }}
              checked={keepGradeFlag === 1}
              onChange={(e) => myContextChange("keepGradeFlag", e ? 1 : 0)}
            />
          </div>
          {keepGradeFlag === 1 && (
            <>
              <div className={style["ProtectionLogic-text"]}>
                <Checkbox
                  disabled={readOnly || updateStatus}
                  checked={amountFlag === 1}
                  onChange={(e) =>
                    myContextChangeObj(
                      "keepGradeConfig",
                      "amountFlag",
                      e.target.checked ? 1 : 0
                    )
                  }>
                  订单金额保级
                </Checkbox>
                <div>
                  客户触发降级时，
                  <InputNumber
                    {...inputNumberProps}
                    precision={0}
                    min={30}
                    max={730}
                    maxLength={3}
                    className={style["input-short"]}
                    disabled={readOnly || updateStatus}
                    value={amountDays || ""}
                    onChange={(e) =>
                      myContextChangeObj("keepGradeConfig", "amountDays", e)
                    }
                  />
                  天内，存在单笔订单金额满
                  <InputNumber
                    min={1}
                    max={999999999.99}
                    controls={false}
                    precision={2}
                    className={style["input-short"]}
                    style={{ width: "110px" }}
                    disabled={readOnly || updateStatus}
                    value={orderAmount || ""}
                    onChange={(e) =>
                      myContextChangeObj("keepGradeConfig", "orderAmount", e)
                    }
                  />
                  元的订单，则保级成功
                </div>
              </div>
              <div className={style["ProtectionLogic-text"]}>
                <Checkbox
                  disabled={readOnly || updateStatus}
                  checked={goodsFlag === 1}
                  onChange={(e) =>
                    myContextChangeObj(
                      "keepGradeConfig",
                      "goodsFlag",
                      e.target.checked ? 1 : 0
                    )
                  }>
                  订单商品保级
                </Checkbox>
                <div>
                  <div>
                    客户触发降级时，
                    <InputNumber
                      {...inputNumberProps}
                      precision={0}
                      min={30}
                      max={730}
                      maxLength={3}
                      className={style["input-short"]}
                      disabled={readOnly || updateStatus}
                      value={goodsDays || ""}
                      onChange={(e) =>
                        myContextChangeObj("keepGradeConfig", "goodsDays", e)
                      }
                    />
                    天内，存在购买以下商品的订单，则保级成功
                  </div>
                  <div>
                    <Input.TextArea
                      maxLength={10000}
                      placeholder="请输入全渠道商品sku 并以“,”连接"
                      disabled={readOnly || updateStatus}
                      style={{ marginTop: "12px" }}
                      value={goodsName}
                      onChange={(e) => {
                        const isValid = /^[0-9,]*$/.test(e.target.value);
                        isValid &&
                          myContextChangeObj(
                            "keepGradeConfig",
                            "goodsName",
                            e.target.value
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={style["memberCard-create-pageTwo"]}>
      {/* 等级计算规则 */}
      <GradeRulesSection />
      {/* 成长值设置 */}
      {calculateType === 1 && getGrowthSettingSection()}
      {/* 订单设置 */}
      {calculateType === 2 && getOrderSettingSection()}
      {/* 等级设置 */}
      {getGradeSettingSection()}
      {/* 成长值赠送规则 */}
      {calculateType === 1 && getGrowthGiveRules()}
      {/* 保级逻辑 */}
      {getProtectionLogic()}
    </div>
  );
};

export default PageTwo;
