import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Space, Select, message, Pagination } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { AppendListApi } from "../../../../../../api/collect/collect";
import {
  TaskCenterListVO
} from "../../../../../../api/collect/types";
import SearchIconInput from "../../../../../../component/Input/SearchIconInput/searchIconInput";
import DataCard from "../DataCard";
import type { DrawerProps } from "antd";
import styles from "./index.module.scss";

const TaskCenter = (props: {
  tableItemdata: any,
  taskCenterOpen: boolean;
  getOpenStatus: Function;
}) => {
  const { tableItemdata, taskCenterOpen, getOpenStatus = () => {} } = props;

  console.log('888888999999999', tableItemdata);

  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");

  // 查询参数
  const searcParamInt: { name: string; status: null } = {
    name: "",
    status: null,
  };
  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  // 展示查询内容
  const [show, setShow] = useState(false);

  // 查询
  const searchList = () => {
    getAppendList({ pageSize: 10, pageNum: 1 }, searcParam);
  };

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange = (e: any) => {
    getAppendList({ pageSize: e.pageSize, pageNum: e.current }, searcParam);
  };

  const [listData, setListData] = useState([] as TaskCenterListVO[]);
  const getAppendList = async (
    page: { pageSize: number; pageNum: number },
    data: any
  ) => {
    const param = {
      ...page,
      ...data,
    };
    const { total, rows } = await AppendListApi(param);
    setListData(rows);

    setPageQuery({
      pageSize: page.pageSize,
      pageNum: page.pageNum,
      total: total,
    });
  };

  useEffect(() => {
    getAppendList({
      pageSize: pageQuery.pageSize,
      pageNum: pageQuery.pageNum,
    }, searcParam);
  }, []);
  
  return (
    <div className={styles.taskCenter}>
      <Drawer
        title="任务中心"
        className={styles.drawerStyle}
        placement={placement}
        width={400}
        onClose={() => {
          getOpenStatus(false);
          setSearcParam({
            name: '',
            status: null
          });
          setShow(false);

        }}
        open={taskCenterOpen}
      >
        <div>
          <div className={styles.buttonBox}>
            <SearchIconInput
              placeholder="请输入数据接入名称"
              onSearch={(e: string) => {
                const store = {
                  ...searcParam,
                  name: e,
                };
                setSearcParam(store);
                getAppendList({ pageSize: 10, pageNum: 1 }, store);
              }}
            ></SearchIconInput>
            <FilterOutlined
              onClick={() => {
                setShow(!show);
              }}
              style={{
                color: "var(--text-font-color4)",
                fontSize: 'var(--text-font7)',
                marginLeft: "16px",
              }}
            />
          </div>
          {show && (
            <div className={styles.filterBox}>
              <div>
                <span>任务状态：</span>
                <Select
                  placeholder="请选择任务状态"
                  value={searcParam.status}
                  style={{ width: 170 }}
                  allowClear
                  onChange={(e) =>
                    setSearcParam({
                      ...searcParam,
                      status: e,
                    })
                  }
                  options={[
                    { value: "0", label: "进行中" },
                    { value: "1", label: "已完成" },
                    { value: "2", label: "失败" },
                  ]}
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <Button type="primary" onClick={searchList}>
                  查询
                </Button>
              </div>
            </div>
          )}
          <div>
              {
                listData.map((val: TaskCenterListVO, idx: number) => {
                  return <DataCard cardData={val} tableItemdata={tableItemdata} key={idx} />
                })
              }
          </div>
          <div style={{textAlign: 'right'}}>
            <Pagination
              total={pageQuery.total}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `共 ${total} 条`}
              onChange={handleTableChange}
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default TaskCenter;
