import styles from "./index.module.scss";
import {useEffect, useRef, useState} from "react";
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  type TableProps,
  Tooltip
} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";
import SearchIconInput from "@/pages/LuZhiBI/components/SearchIconInput/searchIconInput";
import ModuleTitle from "@/pages/LuZhiBI/components/ModuleTitle";
import FilterIcon from "@/pages/LuZhiBI/components/FilterIcon/filterIcon";
import SystemButton from "@/pages/LuZhiBI/components/Button/SystemButton";
import FilterView from "@/pages/LuZhiBI/components/FilterView";
import api from "@/pages/LuZhiBI/api";
import {IAddIndicator, IEditIndicator, IGetIndicatorManagementList} from "@/pages/LuZhiBI/api/types";
import dayjs from "dayjs";

interface DataType {
  id: string
  classify: string
  indexType: string
  indexName: string
  createdAt: string
  atomicIndexId: string
  atomicIndexName: string
  dimension: string
}

interface IFormData {
  indicatorName: string,
  fieldExpression: string,
  classificationOfIndicators: string,
  atomicIndicators: string,
  overrideDimensions: string[],
}

interface ISearch {
  indexName?: string
  classifyList?: Array<string>
  indexType?: string
  searchInfo?: string
  startTime?: string
  endTime?: string
  modelType?: string
}

type PageQuery = null | number

type DrawerType = "create" | 'view' | 'edit'

const DerivedIndicators = () => {
  const {confirm} = Modal;

  const [form] = Form.useForm();

  const [options, setOptions] = useState<Record<any, Array<{ label: string, value: string, atomicIndexId?: string }>>>({
    classificationOfIndicatorsOptions: [],
    atomicIndicatorsOptions: [],
    overrideDimensionsOptions: []
  })

  const defaultSearchInfo = {
    // 查询参数
    indicatorName: "",
    indicatorType: options.classificationOfIndicatorsOptions.map(item => item.value),
    createdAt: ''
  };

  const [listParams, setListParams] = useState(defaultSearchInfo);

  const filterViewRef = useRef<any>(null);

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
    total: 0,
  });

  const [tableData, setTableData] = useState([])

  const [openDrawer, setOpenDrawer] = useState(false)

  const [drawerType, setDrawerType] = useState<DrawerType>('create')

  const drawerTitle: Record<DrawerType, string> = {
    create: '新建衍生指标',
    view: '查看衍生指标',
    edit: '编辑衍生指标'
  }

  const defaultFormData = {
    indicatorName: '',
    classificationOfIndicators: '',
    atomicIndicators: '',
    overrideDimensions: [],
  }

  const [viewId, setViewId] = useState('')

  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i: any) => {
    setCheckUserId(i.id)
    setIconColorPointBack('#f3f3f3');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('')
    setIconColorPointBack('');
  };

  // 搜索
  const searchList: any[] = [
    {
      searchType: "string",
      searchComponentType: "input",
      placeHolder: "请输入指标名称",
      title: "指标名称",
      key: "indicatorName",
      defaultValue: ''
    },
    {
      searchType: "string",
      searchComponentType: "multipleSelect",
      placeHolder: "请选择指标分类",
      title: "指标分类",
      key: "indicatorType",
      options: options.classificationOfIndicatorsOptions,
      mode: "multiple",
      defaultValue: options.classificationOfIndicatorsOptions.map(item => item.value),
    },
    {
      searchType: "text",
      searchComponentType: "dateRangeTime",
      showTime: true,
      title: "创建时间",
      key: "createdAt",
    },
  ];

  const getTableOperationButtonList = (item: DataType) => {
    const store = [];

    store.push({
      key: "view",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onView(item)}
        >
          查看
        </a>
      ),
    });

    store.push({
      key: "edit",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onEdit(item)}
        >
          编辑
        </a>
      ),
    });

    store.push({
      key: "delete",
      label: (
        <a
          type="link"
          className={styles.normalHref}
          onClick={() => onDelete(item)}
        >
          删除
        </a>
      ),
    });
    return store;
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "序号",
      key: "index",
      width: "88px",
      render: (_value, _record, index) => (
        <span>{(pageInfo.page - 1) * pageInfo.pageSize + index + 1}</span>
      ),
    },
    {
      title: "指标分类",
      key: "classify",
      dataIndex: "classify",
    },
    {
      title: "指标名称",
      key: "indexName",
      dataIndex: "indexName",
    },
    {
      title: "原子指标",
      key: "atomicIndexName",
      dataIndex: "atomicIndexName",
    },
    {
      title: "覆盖维度",
      key: "dimension",
      dataIndex: "dimension",
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{items: getTableOperationButtonList(record)}}
            trigger={["click"]}
          >
            <Popconfirm
              title=''
              placement="right"
              open={false}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                onMouseLeave={handleMouseLeavePoint}
                onMouseEnter={() => handleMouseEnterPoint(record)}
                className={styles[`line-three-point`]}
                style={{backgroundColor: checkUserId == record.id ? iconColorPointBack : ''}}
              >
                <EllipsisOutlined
                  style={{
                    color: checkUserId == record.id ? '#1e1e1e' : '#979797',
                    cursor: 'pointer'
                  }}
                />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const onDelete = (item: DataType) => {
    confirm({
      title: "提示",
      content: '该指标被删除后，会影响上游应用该表的所有表及后续统计数据，确认删除吗？',
      okText: "确定",
      onOk() {
        confirmDelete(item.id);
      },
    });
  }

  const confirmDelete = async (id: string) => {
    const params: { idList: Array<string>, indexType: '2' } = {
      idList: [id],
      indexType: '2'
    }
    api.deleteIndicator(params).then((res: any) => {
      if (res?.code === 200) {
        message.success('删除成功')

        let page = pageInfo.page

        if (tableData.length === 1 && page > 1) {
          page = page - 1
        }

        onSearch({page: page})
      } else {
        message.error('删除失败')
      }
    })
  }

  const getViewInfo = async(id: string, type: DrawerType) => {
    const res = await  api.viewIndicator(id)

      if (res?.code === 200) {
        const result = {
          indicatorName: res.data.indexName ?? '',
          classificationOfIndicators: res.data.classify ?? '',
          overrideDimensions: res.data.dimensionId?.split(',') ?? [],
        }

        const atomicIndexListPromise = getAtomicIndexList(res.data.classify ?? '', false, res.data.atomicIndexId?.toString() ?? '')
        const dimensionListPromise = getDimensionList(res.data.classify ?? '')

        await Promise.all([atomicIndexListPromise, dimensionListPromise]);

        form.setFieldsValue(result)

        setDrawerType(type)

        setOpenDrawer(true)
      }
  }

  const onView = (_record: DataType) => {
    setViewId(_record.id ?? '')
    getViewInfo(_record.id, 'view')
  }

  const onEdit = (_record: DataType) => {
    setViewId(_record.id ?? '')
    getViewInfo(_record.id, 'edit')
  }

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  const error = (errMsg: string) => {
    Modal.error({
      title: '校验失败',
      content: errMsg,
      // onOk: onClose
      okText: '确认'
    });
  };

  const onFinish = (data: any) => {
    const {createdAt, indicatorName, indicatorType} = data

    const params: ISearch = {
      indexName: indicatorName || '',
      classifyList: indicatorType ?? [],
      indexType: '2',
      startTime: Array.isArray(createdAt) ? dayjs(createdAt[0]).format('YYYY-MM-DD HH:mm:ss') : '',
      endTime: Array.isArray(createdAt) ? dayjs(createdAt[1]).format('YYYY-MM-DD HH:mm:ss') : ''
    }

    onSearch({page: 1}, params)
  }

  const onClose = () => {
    setOpenDrawer(false)

    setViewId('')

    form.resetFields()

    setOptions(prevState => {
      return {
        ...prevState,
        atomicIndicatorsOptions: [],
        overrideDimensionsOptions: []
      }
    })
  }

  const onFormFinish = (values: IFormData) => {
    if (drawerType === 'edit') {
      confirm({
        title: "提示",
        content: '当前修改会影响上游应用该表的所有表及后续统计数据，确认修改吗？',
        okText: "确定",
        onOk() {
          editIndicator(values)
        },
      });
      return
    }
    addIndicator(values)
  }

  const addIndicator = (formData: IFormData) => {
    const params: IAddIndicator = {
      indexType: '2',
      indexName: formData.indicatorName,
      classify: formData.classificationOfIndicators,
      atomicIndexId: options.atomicIndicatorsOptions.find(item => item.value == formData.atomicIndicators)?.atomicIndexId ?? '',
      dimensionId: formData.overrideDimensions.join(','),
    }
    api.addIndicator(params).then((res: any) => {
      if (res?.code === 200) {
        if (res?.msg) {
          error(res?.msg)
          return
        }

        message.success('新建成功')

        onClose()

        onSearch({page: 1})
      } else {
        message.error('新建失败')

        // onClose()
      }
    })
  }

  const editIndicator = (formData: IFormData) => {
    const params: IEditIndicator = {
      id: viewId,
      indexType: '2',
      indexName: formData.indicatorName,
      classify: formData.classificationOfIndicators,
      atomicIndexId: options.atomicIndicatorsOptions.find(item => item.value == formData.atomicIndicators)?.atomicIndexId ?? '',
      dimensionId: formData.overrideDimensions.join(','),
    }
    api.editIndicator(params).then((res: any) => {
      if (res?.code === 200) {
        if (res?.msg) {
          error(res?.msg)
          return
        }

        message.success('编辑成功')

        onClose()

        onSearch({page: 1})
      } else {
        message.error('编辑失败')

        // onClose()
      }
    })
  }

  const onSearch = async (query?: { page?: PageQuery, size?: PageQuery }, params?: ISearch) => {
    const data: IGetIndicatorManagementList = {
      query: {
        pageNo: query?.page || pageInfo.page,
        pageSize: query?.size || pageInfo.pageSize
      },
      params: {
        indexName: params?.indexName || '',
        classifyList: params?.classifyList ?? [],
        indexType: '2',
        searchInfo: params?.searchInfo || '',
        startTime: params?.startTime || '',
        endTime: params?.endTime || '',
        modelType: 'indicator_category'
      }
    }

    const res = await api.getIndicatorManagementList(data)

    setTableData(res?.data?.records ?? [])

    setPageInfo({
      page: res?.data?.current ?? 1,
      pageSize: res?.data?.size ?? 10,
      total: res?.data?.total ?? 0,
    })
  }

  const getAtomicIndexList = async (classify: string, reset: boolean = true, atomicIndexId?: string) => {
    reset && form.setFieldValue('atomicIndicators', '')

    if (!classify) {
      return
    }

    const res = await api.getAtomicIndexList({classify})

    let result: any = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { indexName: string, atomicIndexId: string }) => {
        return {
          label: item.indexName,
          value: item.indexName,
          atomicIndexId: item.atomicIndexId,
        }
      }) ?? []
    }

    setOptions(prevState => {
      return {
        ...prevState,
        atomicIndicatorsOptions: result
      }
    })
    console.log( result.find((item: {
      atomicIndexId: string;
    }) => item.atomicIndexId === atomicIndexId?.toString())?.value,'------->')
    atomicIndexId && form.setFieldValue('atomicIndicators', result.find((item: {
      atomicIndexId: string;
    }) => item.atomicIndexId === atomicIndexId.toString())?.value)
  }

  const getIndicatorCategory = async () => {

    const res = await api.getIndicatorCategory({modelType: 'indicator_category'})

    let result: any = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { dictLabel: string; dictValue: string; }) => {
        return {
          label: item.dictLabel,
          value: item.dictValue,
        }
      }) ?? []
    }

    setListParams(prevState => {
      return {
        ...prevState,
        indicatorType: result.map((item: { value: string; }) => item.value)
      }
    })

    filterViewRef.current.setFormInfo('indicatorType',result.map((item: { value: string; }) => item.value))

    setOptions(prevState => {
      return {
        ...prevState,
        classificationOfIndicatorsOptions: result
      }
    })
  }

  const getDimensionList = async (id: string) => {
    form.setFieldValue('overrideDimensions', [])

    setOptions(prevState => {
      return {
        ...prevState,
        overrideDimensionsOptions: []
      }
    })

    if (!id) {
      return
    }

    const res = await api.getDimensionList(id)

    let result: any = []

    if (res?.code === 200) {
      result = res?.data?.map((item: { dimensionName: any; dimensionId: any; }) => {
        return {
          label: item.dimensionName,
          value: item.dimensionId,
        }
      }) ?? []
    }

    setOptions(prevState => {
      return {
        ...prevState,
        overrideDimensionsOptions: result
      }
    })
  }

  useEffect(() => {
    onSearch();
    getIndicatorCategory()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headBox}>
          <div className={styles.titleText}>
            <ModuleTitle mark="large" title="衍生指标"/>
          </div>

          <div className={styles.searchBox}>
            <SearchIconInput
              placeholder="请输入指标名称或指标分类进行搜索"
              width={300}
              onSearch={(e: string) => {
                setListParams({
                  ...listParams,
                  indicatorName: e,
                });
                onSearch({page: 1}, {searchInfo: e})
              }}
            />
            <FilterIcon
              handleFilterClick={handleFilterClick}
              filterTitle="过滤器"
            />
            <span
              style={{
                width: "0",
                height: "16px",
                border: "var(--border3)",
                marginRight: 16,
              }}
            />
            <SystemButton
              title='新建衍生指标'
              type="primary"
              onClick={() => {
                setOpenDrawer(true);
                setDrawerType('create')
              }
              }
            />
          </div>
        </div>

        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={onFinish}
          defaultFormValue={defaultSearchInfo}
        />

        <Table
          style={{marginTop: 38}}
          dataSource={tableData}
          columns={columns}
          pagination={{
            total: pageInfo.total,
            pageSize: pageInfo.pageSize,
            current: pageInfo.page,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showTotal: (total) => `共 ${total} 条`,
            onChange: (page, pageSize) => {
              onSearch({page, size: pageSize})
              setPageInfo(prevState => {
                return {
                  ...prevState,
                  page,
                  pageSize
                }
              })
            }
          }}
          scroll={{x: columns.reduce((pre, cur) => pre + (cur.width as number ?? 200), 0)}}
        />
      </div>
      <Drawer
        title={drawerTitle[drawerType]}
        onClose={onClose}
        open={openDrawer}
        extra={
          <Space>
            <Button
              onClick={onClose}
            >
              取消
            </Button>
            <Button
              type="primary"
              onClick={() => {
                if (drawerType === 'view') {
                  setDrawerType('edit')
                } else {
                  form.submit()
                }
              }
              }
            >
              {drawerType === 'view' ? '编辑' : '确定'}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          disabled={drawerType === 'view'}
          onFinish={onFormFinish}
          initialValues={defaultFormData}
        >
          <Form.Item
            name="indicatorName"
            label="指标名称"
            rules={[{required: true, message: '请输入指标名称'}]}
          >
            <Input
              showCount
              maxLength={50}
              placeholder="请输入指标名称"
              onChange={(e) => {
                const value = e.target.value.replace(/\s/g, '');
                form.setFieldValue('indicatorName', value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="classificationOfIndicators"
            label="指标分类"
            rules={[{required: true, message: '请选择指标分类'}]}
          >
            <Select
              placeholder='请选择指标分类'
              options={options.classificationOfIndicatorsOptions}
              onChange={(val) => {
                getAtomicIndexList(val)
                getDimensionList(val)
              }}
            />
          </Form.Item>
          <Form.Item
            name="atomicIndicators"
            label="原子指标"
            rules={[{required: true, message: '请选择原子指标'}]}
          >
            <Select
              placeholder='请选择原子指标'
              options={options.atomicIndicatorsOptions}
            />
          </Form.Item>
          <Form.Item
            name="overrideDimensions"
            label="覆盖维度"
            rules={[{required: true, message: '请选择覆盖维度'}]}
          >
            <Select
              mode='multiple'
              placeholder='请选择覆盖维度'
              options={options.overrideDimensionsOptions}
              defaultValue={options.overrideDimensionsOptions.map((item: { value: any; }) => item.value)}
              maxTagCount="responsive"
              maxTagPlaceholder={(omittedValues) => (
                <Tooltip title={omittedValues.map(({label}) => <p style={{margin: '6px 0'}}>{label}</p>)}>
                  <span>+ {omittedValues.length} ...</span>
                </Tooltip>
              )}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}
export default DerivedIndicators
