/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, useState, useEffect, useRef } from 'react';
import { Tabs, Button, Table, type TableColumnsType, Drawer, Space, message } from 'antd';
import { TextWithQuestion } from '../../../../../../component/MiniStyles';
import ChangeF from '../ChangeF';
import ChangeR from '../ChangeR';
import { getRFReport as getRFReportApi, updateRFReport as updateRFReportApi } from '@/crm/api/dataBoardApi';
import styles from './index.module.scss';

const formatNum = (num: number | string, options?: { digit?: number; isRatio?: boolean; nullSymbol?: string; }) => {
  const { digit = 2, isRatio = false, nullSymbol = '-' } = options || {};
  const newNum = Number(num);
  if (num === null || num === undefined || isNaN(newNum)) {
    return nullSymbol;
  }
  if (isRatio && newNum === 0) {
    return '0%';
  }
  if (isRatio && newNum === 1) {
    return '100%';
  }
  if (isRatio) {
    return `${(newNum * 100).toFixed(digit)}%`;
  }
  if (newNum === 0) {
    return 0;
  }
  return newNum.toFixed(2) === '-0.00' ? '0' : newNum.toLocaleString('en-US', {
    minimumFractionDigits: digit,
    maximumFractionDigits: digit,
  });
};

// 后端真实数据
const mockData = {
  "rfAnalysisData": [
    {
      "recencyType": null,
      "frequencyType": null,
      "crowdNum": 9,
      "crowdRatio": "1",
      "memberAtv": "1349.67",
      "memberAov": "134.97",
      "memberAur": "129.22"
    },
    {
      "recencyType": null,
      "frequencyType": "1",
      "crowdNum": 3,
      "crowdRatio": "0.3333",
      "memberAtv": "27",
      "memberAov": "27",
      "memberAur": "27"
    },
    {
      "recencyType": null,
      "frequencyType": "2",
      "crowdNum": 1,
      "crowdRatio": "0.1111",
      "memberAtv": "6",
      "memberAov": "3",
      "memberAur": "3"
    },
    {
      "recencyType": null,
      "frequencyType": "6",
      "crowdNum": 5,
      "crowdRatio": "0.5556",
      "memberAtv": "2412",
      "memberAov": "141.88",
      "memberAur": "135.51"
    },
    {
      "recencyType": "0.00,18.44",
      "frequencyType": null,
      "crowdNum": 7,
      "crowdRatio": "0.7778",
      "memberAtv": "1724",
      "memberAov": "137.14",
      "memberAur": "131.17"
    },
    {
      "recencyType": "0.00,18.44",
      "frequencyType": "1",
      "crowdNum": 1,
      "crowdRatio": "0.1111",
      "memberAtv": "2",
      "memberAov": "2",
      "memberAur": "2"
    },
    {
      "recencyType": "0.00,18.44",
      "frequencyType": "2",
      "crowdNum": 1,
      "crowdRatio": "0.1111",
      "memberAtv": "6",
      "memberAov": "3",
      "memberAur": "3"
    },
    {
      "recencyType": "0.00,18.44",
      "frequencyType": "6",
      "crowdNum": 5,
      "crowdRatio": "0.5556",
      "memberAtv": "2412",
      "memberAov": "141.88",
      "memberAur": "135.51"
    },
    {
      "recencyType": "55.32,73.76",
      "frequencyType": null,
      "crowdNum": 2,
      "crowdRatio": "0.2222",
      "memberAtv": "39.5",
      "memberAov": "39.5",
      "memberAur": "39.5"
    },
    {
      "recencyType": "55.32,73.76",
      "frequencyType": "1",
      "crowdNum": 2,
      "crowdRatio": "0.2222",
      "memberAtv": "39.5",
      "memberAov": "39.5",
      "memberAur": "39.5"
    }
  ],
  "rfAnalysisField": {
    "recencyInterval": {
      "intervals": [
        {
          "start": "0",
          "end": "18.44"
        },
        {
          "start": "18.44",
          "end": "36.88"
        },
        {
          "start": "36.88",
          "end": "55.32"
        },
        {
          "start": "55.32",
          "end": "73.76"
        },
        {
          "start": "73.76",
          "end": "92.2"
        }
      ],
      "deadline": "92.2"
    },
    "frequencyInterval": {
      "intervals": [
        1,
        2,
        3,
        4,
        5
      ],
      "finalFrequency": 6
    }
  }
};
type FetchData = typeof mockData;

const tableTabList = [
  {
    key: '1',
    label: '客户数（占比）',
  },
  {
    key: '2',
    label: '客单价',
  },
  {
    key: '3',
    label: '笔单价',
  },
  {
    key: '4',
    label: '件单价',
  },
];

const defaultColumns: TableColumnsType<{
  [propsName: string]: string | number;
  frequencyType: string;
  recentThreeYearsPurchaseCustomerNum: string;
}> = [
    {
      title: '购买频次',
      key: 'frequencyType',
      width: 150,
      fixed: 'left',
      render: (_, record) => {
        if (typeof record === 'object') {
          if ('isTotal' in record) {
            return <TextWithQuestion
              black
              title="合计"
            />;
          }
          return <TextWithQuestion
            black
            title={`F≥${record.value}`}
            tips={`统计日期内（最近3年 / 历史全量）购买大于等于${record.value}次`}
          />;
        }
        return <TextWithQuestion
          black
          title={`F=${record}`}
          tips={`统计日期内（最近3年 / 历史全量）购买${record}次`}
        />;
      },
    },
    {
      title: '购买客户数',
      dataIndex: 'recentThreeYearsPurchaseCustomerNum',
      key: 'recentThreeYearsPurchaseCustomerNum',
      width: 180,
      fixed: 'left',
    },
    {
      title: '购买间隔',
    },
  ];

const getFrequencyType = (value: number | { isFinal: boolean; value: number | null }) => {
  if (typeof value === 'object') {
    return value?.value === null ? null : String(value?.value);
  }
  return String(value);
}

const getResRecencyType = (v: string | null) => {
  if (v === null) {
    return null;
  }
  if (v.includes(',')) {
    return `${Number(v.split(',')[0]).toFixed(2)},${Number(v.split(',')[1]).toFixed(2)}`;
  }
  return Number(v).toFixed(2);
}

const isNullORundefined = (v: any) => {
  return v === null || v === undefined;
};

const RF: FC<{ id: number }> = (props) => {
  const [tableTabKey, seTableTabKey] = useState('1');
  const [columns, setColums] = useState(defaultColumns);
  const [fetchData, setFetchData] = useState<FetchData>();
  const [loading, setLoaidng] = useState(false);
  const [drawerFVisible, setDrawerFVisible] = useState(false);
  const [drawerRVisible, setDrawerRVisible] = useState(false);
  const [dataSource, setDataSource] = useState<any[]>();
  const [fData, setFData] = useState<(number | { isFinal: boolean; value: number })[]>(); // 仅用于修改F
  const [rData, setRData] = useState<(number | { start: number; end: number })[]>(); // 仅用于修改R
  const changeFRef = useRef<{ value: any }>(null);
  const changeRRef = useRef<{ value: any }>(null);

  const getColumns = () => {
    const { rfAnalysisField, rfAnalysisData } = fetchData!;
    const { recencyInterval } = rfAnalysisField;
    let { intervals } = recencyInterval;
    if (!intervals) {
      intervals = [];
    }
    const newColumnsChildren = [...intervals, recencyInterval.deadline].map((i) => {
      if (typeof i === 'object') {
        return {
          title: <TextWithQuestion
            black
            title={`${formatNum(i.start)}天≤R＜${formatNum(i.end)}天`}
            tips={`最近一次消费间隔大于等于${formatNum(i.start)}天小于${formatNum(i.end)}天`}
          />,
          key: `${formatNum(i.start)}天<R≤${formatNum(i.end)}天`,
          render: (_: string, record: any) => {
            const dataIndex = `${Number(i.start).toFixed(2)},${Number(i.end).toFixed(2)}`;
            const dataIndexF = getFrequencyType(record);
            const cur = rfAnalysisData.find((j) => {
              return getResRecencyType(j.recencyType) === dataIndex && j.frequencyType === dataIndexF;
            });
            if (!cur) {
              return <div>
                {tableTabKey === '1' && <p>0人</p>}
                {tableTabKey === '1' && <p>占比0%</p>}
                {tableTabKey === '2' && <p>0元</p>}
                {tableTabKey === '3' && <p>0元</p>}
                {tableTabKey === '4' && <p>0元</p>}
              </div>
            }
            return <div>
              {tableTabKey === '1' && <p>{formatNum(cur.crowdNum, { digit: 0 })}人</p>}
              {tableTabKey === '1' && <p>占比{formatNum(cur.crowdRatio, { isRatio: true })}</p>}
              {tableTabKey === '2' && <p>{formatNum(cur.memberAtv)}元</p>}
              {tableTabKey === '3' && <p>{formatNum(cur.memberAov)}元</p>}
              {tableTabKey === '4' && <p>{formatNum(cur.memberAur)}元</p>}
            </div>;
          },
        };
      }
      return {
        title: <TextWithQuestion
          black
          title={`R≥${formatNum(i)}天`}
          tips={`最近一次消费间隔大于等于${formatNum(i)}天`}
        />,
        key: `${i}`,
        render: (_: string, record: any) => {
          const dataIndex = `${Number(i).toFixed(2)}`;
          const dataIndexF = getFrequencyType(record);
          const cur = rfAnalysisData.find((j) => {
            return getResRecencyType(j.recencyType) === dataIndex && j.frequencyType === dataIndexF;
          });
          if (!cur) {
            return <div>
              {tableTabKey === '1' && <p>0人</p>}
              {tableTabKey === '1' && <p>占比0%</p>}
              {tableTabKey === '2' && <p>0元</p>}
              {tableTabKey === '3' && <p>0元</p>}
              {tableTabKey === '4' && <p>0元</p>}
            </div>
          }
          return <div>
            {tableTabKey === '1' && <p>{formatNum(cur.crowdNum, { digit: 0 })}人</p>}
            {tableTabKey === '1' && <p>占比{formatNum(cur.crowdRatio, { isRatio: true })}</p>}
            {tableTabKey === '2' && <p>{formatNum(cur.memberAtv)}元</p>}
            {tableTabKey === '3' && <p>{formatNum(cur.memberAov)}元</p>}
            {tableTabKey === '4' && <p>{formatNum(cur.memberAur)}元</p>}
          </div>;
        },
      };
    });
    const newColumns: any = columns.map((i) => {
      if (i.title === '购买间隔') {
        return {
          ...i,
          children: newColumnsChildren,
        };
      }
      return i;
    });
    // 修改 最近 3 年购买客户数
    newColumns[1].render = (_: any, record: any) => {
      const cur = rfAnalysisData.find((j) => {
        return j.recencyType === null && j.frequencyType === getFrequencyType(record);
      });
      if (!cur) {
        return <div>
          {<p>0人</p>}
          {<p>占比0%</p>}
          {/* {tableTabKey === '2' && <p>0元</p>}
          {tableTabKey === '3' && <p>0元</p>}
          {tableTabKey === '4' && <p>0元</p>} */}
        </div>
      }
      return <div>
        {<p>{formatNum(cur.crowdNum, { digit: 0 })}人</p>}
        {<p>占比{formatNum(cur.crowdRatio, { isRatio: true })}</p>}
        {/* {tableTabKey === '2' && <p>{formatNum(cur.memberAtv)}元</p>}
        {tableTabKey === '3' && <p>{formatNum(cur.memberAov)}元</p>}
        {tableTabKey === '4' && <p>{formatNum(cur.memberAur)}元</p>} */}
      </div>;
    };
    setRData([...intervals.map((i) => ({
      start: Number(i.start),
      end: Number(i.end),
    })), Number(recencyInterval.deadline)]);
    setColums(newColumns);
  };
  const getDataSource = () => {
    const { rfAnalysisField } = fetchData!;
    const { frequencyInterval } = rfAnalysisField;
    const newDataSource: any = [...frequencyInterval.intervals, { isFinal: true, value: frequencyInterval.finalFrequency }].map((i) => i);
    setFData(newDataSource);
    setDataSource(newDataSource.length >= 1 ? [...newDataSource, { isTotal: true, value: null }] : newDataSource);
  };
  const getData = () => {
    setLoaidng(true);
    getRFReportApi(props.id).then((res) => {
      // setFetchData(mockData);
      if (res?.code !== 200) {
        setFetchData([] as any);
        return;
      }
      setFetchData(res.data);
    }).finally(() => {
      setLoaidng(false);
    });
  };

  const onCloseDrawerF = () => {
    setDrawerFVisible(false);
  };
  const updateRFReport = (r: typeof rData, f: typeof fData) => {
    if (!r || !f) {
      return;
    }
    updateRFReportApi({
      frequencyInterval: {
        finalFrequency: (f.find((i) => typeof i === 'object' && i.isFinal)! as any).value,
        intervals: f.filter((i) => typeof i === 'number').map((i) => i as number)
      },
      recencyInterval: {
        deadline: r?.find((i) => typeof i === 'number')! as number,
        intervals: r?.filter((i) => typeof i === 'object').map((i) => ({
          start: (i as { start: number; end: number }).start,
          end: (i as { start: number; end: number }).end,
        })) as any,
      },
      subReportId: String(props.id),
    }).then((res) => {
      if (res?.code !== 200) {
        return;
      }
      message.success('修改成功');
      setDrawerFVisible(false);
      setDrawerRVisible(false);
      getData();
    });
  };
  const onSubmitF = () => {
    const value: (number | { isFinal: boolean; value: number })[] = changeFRef.current?.value;
    if (value.some((i: any) => typeof i === 'object' ? !i.value : !i)) {
      message.error('购买频次不能为空');
      return;
    }
    updateRFReport(rData, value);
  };

  const onCloseDrawerR = () => {
    setDrawerRVisible(false);
  };
  const onSubmitR = () => {
    const value: (number | { start: number; end: number })[] = changeRRef.current?.value;
    if (value.some((i: any) => typeof i === 'object' ? (isNullORundefined(i.start) || isNullORundefined(i.end)) : isNullORundefined(i))) {
      message.error('起始时间和截止时间不能为空');
      return;
    }
    updateRFReport(value, fData);
  };

  useEffect(() => {
    if (!fetchData) {
      return;
    }
    getColumns();
    getDataSource()
  }, [fetchData, tableTabKey]);

  useEffect(() => {
    if (!props?.id) {
      return;
    }
    getData();
  }, [props?.id]);

  return (
    <div className={styles['RF']}>
      {/* 表格底层tab */}
      <Tabs
        activeKey={tableTabKey}
        items={tableTabList}
        onChange={(e) => seTableTabKey(e)}
        type="card"
        style={{ marginTop: 12 }}
      />
      <div className={styles['btn-box']}>
        <Button onClick={() => { setDrawerFVisible(true); }} style={{ marginRight: 8 }}>调F</Button>
        <Button onClick={() => { setDrawerRVisible(true); }}>调R</Button>
      </div>
      <Table
        rowKey={(record) => record?.value ?? record}
        pagination={false}
        style={{ marginTop: 8 }}
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        scroll={{
          x: columns.reduce((pre, cur) => {
            if ((cur as any).children) {
              return pre + (cur as any).children.length * 220;
            }
            return pre + (cur?.width as number ?? 220);
          }, 0)
        }}
      />
      <Drawer
        width={450}
        title={<TextWithQuestion black title="编辑自定义分布" tips={<div>
          <p style={{ margin: 0 }}>提示：</p>
          <p style={{ margin: 0 }}>1：输入数值区间范围（次）：1-20</p>
          <p style={{ margin: 0 }}>2：数值区间输入int型。如4、5、6...</p>
        </div>} />}
        onClose={onCloseDrawerF}
        open={drawerFVisible}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={onCloseDrawerF}>取消</Button>
            <Button type="primary" onClick={onSubmitF}>确定</Button>
          </Space>
        }
      >
        <ChangeF ref={changeFRef} />
      </Drawer>
      <Drawer
        width={450}
        title={<TextWithQuestion black title="编辑自定义分布" tips={<div>
          <p style={{ margin: 0 }}>提示：</p>
          <p style={{ margin: 0 }}>1：输入数值区间范围（天）：0.0-1095.0</p>
          <p style={{ margin: 0 }}>2：数值区间输入int型。如4、5、6....</p>
          <p style={{ margin: 0 }}>3：建议您输入的时间区间为连续区间，所分析出来的数据 更有研究价值。</p>
          <p style={{ margin: 0 }}>如：[0.0，21.0）天、[21.0，42.0)天、[42.0，63.0）天、≥63天。</p>
        </div>} />}
        onClose={onCloseDrawerR}
        open={drawerRVisible}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={onCloseDrawerR}>取消</Button>
            <Button type="primary" onClick={onSubmitR}>确定</Button>
          </Space>
        }
      >
        <ChangeR ref={changeRRef} />
      </Drawer>
    </div>
  );
};

export default RF;