import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
// import type { Dayjs } from 'dayjs';
import { CalculatorTwoTone, DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Space,
} from "antd";
import type { DrawerProps } from "antd";
import { state } from "../../../../store";
import BackList from '../../../../component/BackList/backList';
import { useNavigate , useLocation } from "react-router-dom";
import ModuleTitle from "../../../../component/ModuleTitle";
import styles from "./index.module.scss";
import { itemsOf } from "@antv/g2/lib/interaction/legendFilter";


const CreateChooseGroup = (props:{
  chooseGroup: boolean;
  colseChooseGroup: Function;
  chooseGroupData:any;
}) => {
  const navigate = useNavigate();
  // let location = useLocation();
  const [open, setOpen] = useState(false);
  const { chooseGroup, colseChooseGroup = () => { } } = props;
  const [placement, setPlacement] = useState<DrawerProps["placement"]>("right");
  //创建还是编辑
  const [flag, setFlag] = useState(props.chooseGroupData?.flag);
  //1 标签 2分群
  const type = props.chooseGroupData?.type;
  //标签传值
  const [catgoryIdStore, setCatgoryIdStore] = useState(props.chooseGroupData?.catgoryIdStore);
  const [listData, setListData] = useState([] as any);
  const [chooseIndex, setChooseIndex] = useState(null as any);
  //新建分群类型
  const groupType = [
    {
      title:'规则类分群',
      label:'自定义规则创建',
      detail:'使用客户属性及行为数据，筛选出符合条件的客户。如"过去7天下单"的客户，划分为"近期下单客户"客户分群'
    },
    {
      title:'导入分群',
      label:'导入创建',
      detail:'上传包含客户属性的文件与客户列表进行匹配，筛选出符合条件的客户。如：将"线下导入"的客户手机号上传与客户列表电话号进行匹配，匹配结果保留为客户分群'
    }
  ];
  //新建标签类型
  const tabelType = [
    {
      title:'规则类标签',
      label:'自定义规则创建',
      detail:'自定义每个标签值的名称及计算规则，将人群划分为多个分层'
    },
    {
      title:'导入标签',
      label:'导入创建',
      detail:'使用上传文件计算结果作为标签值，为客户进行标记'
    }
  ];
  // 新建用户组
  const creatGroupType = (item:any) => {
    if (item.title == '规则类分群') {
      navigate('/userGrouping/groupingManagement/createUserGrouping', { state: { flag: flag } })
    }else if (item.title == '规则类标签') {
      navigate('/tages/ruleTags/tags/createLabel', { state: { flag: flag, catgoryIdStore: catgoryIdStore } });
    }else if (item.title == '导入标签' ){
       navigate('/tages/ruleTags/tags/CreateExportLabel', { state: { flag: flag ,type: type} })
    }else {
      navigate('/userGrouping/groupingManagement/CreateExportMain', { state: { flag: flag ,type: type} })
    }
  }
  const [iconColor, setIconColor] = useState('1px solid var(--text-font-color8)');
  const handleMouseEnter = (index:number) => {
    // console.log('鼠标移入')
    setChooseIndex(index)
    setIconColor('1px solid var(--text-font-color7)');
  };
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setChooseIndex(null)
    setIconColor('1px solid var(--text-font-color8)');
  };
  
   // 关闭当前抽屉
   const onClose = () => {
    colseChooseGroup(false);
    setListData([]);
  };
  useEffect(() => {
    setOpen(chooseGroup);
    if (chooseGroup) {
      setListData(type == 1 ? tabelType : groupType)
    }
  }, [chooseGroup]);

  return (
    <div>
      <Drawer
        title={type == 1 ? '请选择新建标签类型' : "请选择新建分群类型"}
        placement={placement}
        width={400}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              取消
            </Button>
          </Space>
        }
      >
        <div className={styles.CreateChooseGroup}>
          {listData.map((item:any,index:number)=>(
            <div key={index} style={{width:'100%'}}>
              <div className={styles.ruleGroup} 
                  onMouseLeave={()=>handleMouseLeave()}
                  onMouseEnter={()=>handleMouseEnter(index)} 
                  style={{border:chooseIndex == index ?  iconColor :''}} 
                  onClick={()=>{creatGroupType(item) }}
              >
                <div>
                  <div className={styles.createChooseLabel} style={{color:chooseIndex == index ? 'var(--text-font-color7)' :'var(--text-font-color3)'}} >
                    {item.label == '自定义规则创建' && <CalculatorTwoTone className={styles.createChooseIcon}/>}
                    {item.label == '导入创建' && <DownloadOutlined className={styles.createChooseIcon}/>}
                    {item.label}
                  </div>
                  <div className={styles.createChooseDetailText}>{item.detail}</div>
                </div>
              </div>
            </div>
          ))
          }
        </div>
      </Drawer>
    </div>
  );
};

export default CreateChooseGroup;
