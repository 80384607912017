import React, { useContext } from "react";
import PageOne from "../PageOne";
import PageTwo from "../PageTwo";
import PageThree from "../PageThree";

import { MyContext } from "../../index";

const PageFour = () => {
  const { data: pageInfo } = useContext(MyContext);
  const { computeType } = pageInfo;

  return (
    <div>
      <PageOne readOnly />
      {computeType === 1 && (
        <>
          <PageTwo readOnly />
          <PageThree readOnly />
        </>
      )}
    </div>
  );
};

export default PageFour;
