import React, { useState, useEffect, useRef } from 'react';

import { Layout, Steps, Form, Input, Button, Result, message, ConfigProvider } from 'antd';
import './index.css';
import { MailOutlined, SafetyCertificateOutlined, LockOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons';
import { getQueryParam } from '../../../utils/tools';
import style from './index.module.scss';
import { forgetPswApi, getPhoneOrEmailApi, getForgetPasswordCaptchaApi, checkCaptchaApi, resetPasswordAfterCheckApi } from '../../../api/auth/auth';
import { useNavigate } from 'react-router-dom';
import { state } from './../../../store/index';
import ButtonCountdown from '../../../component/ButtonCountdown/buttonCountdown';
import VerifyPasswordStrength from '../../../component/CheckPassword/checkPassword';

const { Content } = Layout;

const fortgetSteps = [
  {
    key: '验证身份',
    title: '验证身份',
    content: 'First-content',
  },
  {
    title: '录入新密码',
    key: '录入新密码',
    content: 'Second-content',
  },
  {
    title: '完成',
    key: '完成',
    content: 'Second-content',
  },
];

type FieldType = {
  username?: '';
  email?: string;
  mobile?: string;
  code?: string;
  password?: string;
  newPassword?: string;
  newPasswordAgain?: string;
};
const LoginBind = () => {
  const [validationType, setValidationType] = useState(getQueryParam(window.location.href, 'type'));
  const name = getQueryParam(window.location.href, 'username');
  const [userName, setUserName] = useState<any>(name === 'undefined' ? '' : name);
  const navigate = useNavigate();
  //当前步骤
  const [current, setCurrent] = useState(0);
  //忘记密码验证方式 0手机  1验证码
  const [validationWay, setValidationWay] = useState(0);
  //是否绑定过手机号
  const [hasBindMobile, setHasBindMobile] = useState(false);
  const [inputDefText, setInputDefText] = useState('');
  //获取验证码 能不能点击
  const [handelgetcode, setHandelgetcode] = useState(false);
  //是否绑定过邮箱
  const [hasBindEmail, setHasBindEmail] = useState(false);
  // 忘记密码页下一步的状态是否不可点击
  const [forgetDisabled, setForgetDisabled] = useState(false);
  // 增加了一个页面,其他页面已经写好了不想改,和之后的所有逻辑不产生冲突
  const [account, setAccount] = useState(true);
  const [pageContent, setPageContent] = useState({
    title: '',
    step: [] as any[],
    prompt: '',
    leftBtnText: '',
    rightBtnText: '',
  });
  // 表单
  const [forgetForm] = Form.useForm();
  const [isValueChanged, setIsValueChanged] = useState(userName === '' ? true : false);
  const [typeNum, setTypeNum] = useState(0);

  // 忘记密码接口
  const forgetPsw = async () => {
    await forgetPswApi(isValueChanged ? forgetForm.getFieldValue('username') : userName).then(res => {
      if (res) {
        setAccount(false);
        const keyValuePairs = res.msg.split('\n').map(pair => pair.split(':'));
        const result: any = {};
        keyValuePairs.forEach(pair => {
          const key = pair[0];
          const value = pair[1];
          result[key] = value === '1' ? true : false;
        });
        setHasBindMobile(result.phone);
        setHasBindEmail(result.email);
      }
    });
  };
  // 获取获取手机/邮箱 脱敏
  const getPhoneOrEmail = async (type: number) => {
    await getPhoneOrEmailApi(isValueChanged ? forgetForm.getFieldValue('username') : userName, type).then(res => {
      if (res) {
        if (type === 2) {
          setValidationType('verifyMobile');
        }
        if (type === 1) {
          setValidationType('verifyEmail');
        }
        // setCurrent(1);
        setInputDefText(res.msg);
        var status = res.msg == '请输入有效的手机号或邮箱' || !res.msg
        setHandelgetcode(status)
      }
    });
  };

  // 切换手机和邮箱
  const handleValidationWarChoose = (index: number) => {
    setValidationWay(index);
  };

  // 返回按钮
  const handleGoBack = () => {
    // 从state里取出存取的path路径
    const path = state.currentPath || '/login';
    navigate(path);
  };
  // 下一步
  const handleNextClick = () => {
    switch (validationType) {
      case 'forget':
        if (account) {
          forgetPsw();
          return;
        }
        if (validationWay === 0) {
          getPhoneOrEmail(2);
          setTypeNum(2);
        } else {
          getPhoneOrEmail(1);
          setTypeNum(1);
        }
        break;
      case 'verifyEmail':
      case 'verifyMobile':
        checkCaptcha();

        break;
      case 'reset':
        resetPasswordAfterCheck();
        break;

      default:
        break;
    }
  };
  // 上一步||返回
  const handlePublicClick = () => {
    switch (validationType) {
      case 'verifyEmail':
      case 'verifyMobile':
        setCountdownStatus(false);
        setValidationType('forget');
        setCurrent(0);
        break;
      case 'reset':
        var text = validationWay == 1 ? 'verifyEmail' : 'verifyMobile'
        setValidationType(text);
        setCurrent(0);
        break;
      case 'forget'://account 为true 是输入账号  false 是 选择验证方式
        if (account) {
          handleGoBack();
        }else {
          setCountdownStatus(false);
          setAccount(true)
          setCurrent(0);
        }
        break;
      default:
        handleGoBack();
        break;
    }
  };
  // 倒计时秒数
  const [deadline, setDeadline] = useState(0);
  const [countdownStatus, setCountdownStatus] = useState(false); //倒计时状态
  // 验证码倒计时结束触发
  const finishCountDown = () => {
    setCountdownStatus(false);
  };

  // 发送验证码
  const getCode = async (type: number) => {
    await getForgetPasswordCaptchaApi(isValueChanged ? forgetForm.getFieldValue('username') : userName, type).then(res => {
      if (res) {
        setCountdownStatus(true);
        setDeadline(Date.now() + 1000 * 60);
      }
    });
  };
  const [accessType, setaccessType] = useState('');
  const [loading, setLoading] = useState(false);
  // 验证验证码
  const checkCaptcha = async () => {
    await checkCaptchaApi(isValueChanged ? forgetForm.getFieldValue('username') : userName, forgetForm.getFieldValue('code'), typeNum).then(res => {
      if (res && res.data) {
        setaccessType(res.data.accessType);
        setValidationType('reset');
        setCurrent(1);
        localStorage.setItem('userToken', res.data);
      } 
    });
  };
  // 重置密码
  const resetPasswordAfterCheck = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    await resetPasswordAfterCheckApi(
      isValueChanged ? forgetForm.getFieldValue('username') : userName,
      forgetForm.getFieldValue('newPassword'),
      localStorage.getItem('userToken'),
    ).then(res => {
      if (res) {
        setValidationType('forget');
        setCurrent(2);
      }
      setLoading(false);
    });
  };

  const onFinish = () => {
    var status = 1;
    if (isValueChanged && !forgetForm.getFieldValue('username')) {
      message.warning('账号名称不能为空');
      return;
    }

    if (forgetForm.getFieldValue('newPassword') !== forgetForm.getFieldValue('newPasswordAgain')) {
      message.warning('请保持两次新密码一致');
      status = 0;
      return;
    }
    if (forgetForm.getFieldValue('newPassword') && forgetForm.getFieldValue('newPassword').length < 8) {
      message.warning('密码至少需要8个字符');
      return;
    }
    if (!status) {
      return;
    }

    handleNextClick();
  };
  // 在父组件中调用子组件暴露的方法
  const newPasswordRef = useRef<any>(null);
  const newPasswordAgainRef = useRef<any>(null);
  // 存值
  const fillLoginForm = (e: any, type?: any) => {
    forgetForm.setFieldsValue(e.value);
    if (type === 1) {
      newPasswordRef.current.checkPassWord(e.target.value);
    }
    if (type === 2) {
      newPasswordAgainRef.current.checkPassWord(e.target.value);
    }
    if (type === 3) {
      setIsValueChanged(true);
    }
  };
  useEffect(() => {
    let pageContentObj = {} as any;
    switch (validationType) {
      case 'reset':
        pageContentObj.title = '忘记密码';
        pageContentObj.step = fortgetSteps;
        pageContentObj.prompt = '请重新设置密码';
        pageContentObj.leftBtnText = '上一步';
        pageContentObj.rightBtnText = '下一步';
        setPageContent(pageContentObj);
        break;
      case 'forget':
        pageContentObj.title = '忘记密码';
        pageContentObj.step = fortgetSteps;
        pageContentObj.prompt = '请选择验证方式';
        pageContentObj.leftBtnText = account?'返回':'上一步';
        pageContentObj.rightBtnText = '下一步';
        setPageContent(pageContentObj);
        break;

      case 'verifyEmail':
        pageContentObj.title = '忘记密码';
        pageContentObj.step = fortgetSteps;
        pageContentObj.prompt = '邮箱验证';
        pageContentObj.leftBtnText = '上一步';
        pageContentObj.rightBtnText = '下一步';
        setPageContent(pageContentObj);
        break;
      case 'verifyMobile':
        pageContentObj.title = '忘记密码';
        pageContentObj.step = fortgetSteps;
        pageContentObj.prompt = '手机验证';
        pageContentObj.leftBtnText = '上一步';
        pageContentObj.rightBtnText = '下一步';
        setPageContent(pageContentObj);
        break;

      default:
        break;
    }

    // 忘记密码页的显示逻辑
    if (hasBindMobile !== hasBindEmail) {
      if (hasBindMobile) {
        setValidationWay(0);
      } else {
        setValidationWay(1);
      }
    }
    if (hasBindMobile === hasBindEmail && !hasBindMobile) {
      setForgetDisabled(true); //置灰
    } else {
      setForgetDisabled(false); //不置灰
    }
  }, [setHasBindMobile, hasBindEmail, validationType, current, account, isValueChanged, userName, loading]);

  return (
    <Layout>
      <Content className={style['container']}>
        <div className={style['box']}>
          <div className={style['logo']} style={{ backgroundImage: `url(${state.styleStoreData.loginLogo})` }}></div>
          <div className={style['content']}>
            <div className={style['title']}>{pageContent.title}</div>
            <ConfigProvider 
                    theme={{
                      token:{
                          fontSizeLG: 14
                      }
                    }}
            >
              <Steps current={current} className='stepFont' items={pageContent.step} style={{ width: '600px', fontSize: 'var(--text-font5)' }}></Steps>
            </ConfigProvider>
            {current < 2 && (
              <div
                className="lz_login-bind-text"
                style={{ marginTop: '33px', marginLeft: validationType === 'forget' && current === 0 ? '31px' : '60px' }}>
                {account ? '请输入账号' : pageContent.prompt}
              </div>
            )}
            {/* 账号 */}
            {validationType === 'forget' && account && (
              <div>
                <Form
                  form={forgetForm}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  onFinish={onFinish}
                  style={{ width: '459px', marginTop: '24px', marginLeft: '60px' }}>
                  <Form.Item<FieldType> name="username" rules={isValueChanged ? [{ required: true, message: '请输入账号' }] : []}>
                    <Input
                      placeholder="请输入账号"
                      onPressEnter={(e)=> e.preventDefault()}
                      defaultValue={userName}
                      prefix={<UserOutlined style={{ fontSize: 'var(--text-font5)', color: 'var(--text-font-color4)' }} />}
                      onChange={e => fillLoginForm(e, 3)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="lz_loginbind-operation">
                      <div className="lz_loginbind-operation" style={{ marginTop: '48px' }}>
                        <Button htmlType="submit" style={{ marginRight: '8px' }} onClick={handlePublicClick}>
                          {pageContent.leftBtnText}
                        </Button>
                        <Button type="primary" htmlType="submit">
                          {pageContent.rightBtnText}
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            )}
            {/* 忘记密码---第一步 */}
            {validationType === 'forget' && !account && current === 0 && (
              <div className="lz_login-bind-forget">
                <div className="lz_login-bind-forget-validation" style={{ width: '519px' }}>
                  {hasBindMobile ? (
                    <div
                      onClick={() => handleValidationWarChoose(0)}
                      className={`lz_login-forget-validation-way click-element ${validationWay === 0 && 'lz_login-forget-validation-way-selected'}`}>
                      <div className="lz_login-forget-validation-way-top">
                        <MobileOutlined style={{ marginRight: '8px', color: 'var(--text-font-color7)' }} />
                        <span className="lz_login-bind-way-title">手机号验证</span>
                      </div>
                    </div>
                  ) : (
                    <div className={'lz_login-forget-validation-way click-element lz_login-forget-validation-way-disable'}>
                      <div className="lz_login-forget-validation-way-top">
                        <MobileOutlined style={{ marginRight: '8px', color: 'var(--text-font-color4)' }} />
                        <span className="lz_login-bind-way-title">手机号验证</span>
                      </div>
                      <span className="lz_login-forget-prompt-disable">账号尚未绑定手机号，请联系管理员</span>
                    </div>
                  )}
                  {hasBindEmail ? (
                    <div
                      onClick={() => handleValidationWarChoose(1)}
                      style={{ marginLeft: '24px' }}
                      className={`lz_login-forget-validation-way click-element ${validationWay === 1 && 'lz_login-forget-validation-way-selected'}`}>
                      <div className="lz_login-forget-validation-way-top">
                        <MailOutlined style={{ marginRight: '8px', color: 'var(--text-font-color7)' }} />
                        <span className="lz_login-bind-way-title">邮箱验证</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ marginLeft: '24px' }}
                      className="lz_login-forget-validation-way click-element lz_login-forget-validation-way-disable">
                      <div className="lz_login-forget-validation-way-top">
                        <MailOutlined style={{ marginRight: '8px', color: 'var(--text-font-color4)' }} />
                        <span className="lz_login-bind-way-title">邮箱验证</span>
                      </div>
                      <span className="lz_login-forget-prompt-disable">账号尚未绑定邮箱，请联系管理员</span>
                    </div>
                  )}
                </div>
                <div className="lz_loginbind-operation" style={{ marginTop: '48px' }}>
                  <Button htmlType="submit" style={{ marginRight: '8px' }} onClick={handlePublicClick}>
                    {pageContent.leftBtnText}
                  </Button>
                  <Button type="primary" htmlType="submit" disabled={forgetDisabled} onClick={handleNextClick}>
                    {pageContent.rightBtnText}
                  </Button>
                </div>
              </div>
            )}
            {/*  邮箱验证||手机验证 */}
            {(validationType === 'verifyEmail' || validationType === 'verifyMobile') && (
              <div>
                <Form
                  form={forgetForm}
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  style={{ width: '459px', marginTop: '24px', marginLeft: '60px' }}>
                  <Form.Item<FieldType>
                    name="email"
                    rules={[{ required: false, message: 'Please input your email!' }]}
                    validateStatus="error"
                    help={validationWay === 0 ? '若手机号有误，请联系管理员' : '若邮箱有误，请联系管理员'}>
                    {validationWay === 0 ? (
                      <Input
                        placeholder="请输入手机号"
                        onPressEnter={(e)=> e.preventDefault()}
                        disabled
                        defaultValue={inputDefText}
                        prefix={<MobileOutlined style={{ fontSize: 'var(--text-font5)', color: 'var(--text-font-color4)' }} />}
                      />
                    ) : (
                      <Input
                        placeholder="请输入邮箱"
                        onPressEnter={(e)=> e.preventDefault()}
                        disabled
                        defaultValue={inputDefText}
                        prefix={<MailOutlined style={{ fontSize: 'var(--text-font5)', color: 'var(--text-font-color4)' }} />}
                      />
                    )}
                  </Form.Item>
                  <Form.Item<FieldType> name="code" rules={[{ required: true, message: '请输入验证码' }]}>
                    <Input
                      placeholder="请输入验证码"
                      onPressEnter={(e)=> e.preventDefault()}
                      prefix={<SafetyCertificateOutlined style={{ fontSize: 'var(--text-font5)', color: 'var(--text-font-color4)' }} />}
                      onChange={fillLoginForm}
                      suffix={
                        !countdownStatus ? (
                          <Button
                            disabled={handelgetcode}
                            type="link"
                            style={{ fontSize: 'var(--text-font2)', height: 'auto', padding: '0' }}
                            onClick={() => {
                              getCode(validationWay === 0 ? 2 : 1);
                            }}>
                            获取验证码
                          </Button>
                        ) : (
                          <ButtonCountdown deadline={deadline} color='var(--text-font-color10)' size="14px" finishFun={finishCountDown} />
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <div className="lz_loginbind-operation">
                      <Button htmlType="submit" style={{ marginRight: '8px' }} onClick={handlePublicClick}>
                        {pageContent.leftBtnText}
                      </Button>
                      <Button type="primary" htmlType="submit">
                        {pageContent.rightBtnText}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            )}
            {/* 重置密码 */}
            {validationType === 'reset' && (
              <div>
                <Form
                  form={forgetForm}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  onFinish={onFinish}
                  style={{ width: '459px', marginTop: '24px', marginLeft: '60px' }}>
                  <Form.Item<FieldType> name="newPassword" rules={[{ required: true, message: '请输入新密码' }]}>
                    <Input.Password placeholder="请输入新密码" onPressEnter={(e)=> e.preventDefault()} prefix={<LockOutlined />} onChange={e => fillLoginForm(e, 1)}></Input.Password>
                  </Form.Item>
                  <VerifyPasswordStrength
                    ref={newPasswordRef}
                    hideText={true}
                    showInfo={true}
                    viewStyle={{ marginTop: '-24px' }}></VerifyPasswordStrength>
                  <Form.Item<FieldType> name="newPasswordAgain" rules={[{ required: true, message: '请再次输入新密码' }]}>
                    <Input.Password placeholder="请再次输入新密码" onPressEnter={(e)=> e.preventDefault()} prefix={<LockOutlined />} onChange={e => fillLoginForm(e, 2)}></Input.Password>
                  </Form.Item>
                  <Form.Item>
                    <div className="lz_loginbind-operation">
                      <Button style={{ marginRight: '8px' }} onClick={handlePublicClick}>
                        {pageContent.leftBtnText}
                      </Button>
                      <Button type="primary" htmlType="submit">
                        {pageContent.rightBtnText}
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            )}
            {/* 密码重置完成 */}
            {validationType === 'forget' && current === 2 && (
              <Result
                style={{ marginTop: '22px', width: '600px' }}
                status="success"
                title="密码重置完成"
                extra={[
                  <Button type="primary" key="console" onClick={handleGoBack}>
                    重新登录
                  </Button>,
                ]}
              />
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default LoginBind;
