/* eslint-disable react-hooks/exhaustive-deps */
import { type FC, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Tree, Dropdown, Table, Tag, Drawer, Space, Modal, Flex, message } from 'antd';
import type { TreeDataNode, MenuProps, TableProps, TreeProps } from 'antd';
import { PlusOutlined, FolderOpenOutlined, DashOutlined, FolderOutlined, CloseCircleFilled } from '@ant-design/icons';
import { getToolTipText } from '../../utils';
import DatasetApi from '@/pages/LuZhiBI/api/dataset';
import DatasetAction from './DatasetAction';
import Datasetcategory from './DatasetCategory';
import styles from './index.module.scss';

const { Search } = Input;
const datasetActionNameMap = {
  create: '新建数据集',
  edit: '编辑数据集',
};
const datasetCategoryNameMap = {
  create: '新建分类',
  edit: '编辑分类',
};

const leafItems: MenuProps['items'] = [
  {
    key: 'edit',
    label: '编辑',
  },
  {
    key: 'delete',
    label: '删除',
  },
];
const disableStatusItems: MenuProps['items'] = [
  {
    label: '查看',
    key: 'view',
  },
  {
    label: '启用',
    key: 'enable',
  },
  {
    label: '编辑',
    key: 'edit',
  },
  {
    label: '添加数据源',
    key: 'addDataSource',
  },
];
const enableStatusItems: MenuProps['items'] = [
  {
    label: '查看',
    key: 'view',
  },
  {
    label: '禁用',
    key: 'disable',
  },
];

const statusMap: { [propsName: string]: { color: string; text: string; items: MenuProps['items'] } } = {
  0: {
    color: 'success',
    text: '启用',
    items: enableStatusItems,
  },
  1: {
    color: 'default',
    text: '禁用',
    items: disableStatusItems,
  },
};
interface DataType {
  uid: string; // 唯一标识 从 0 开始递增
  id: string; // 用于操作的id
  datasetName: string;
  createUser: string;
  createTime: number;
  parent: string;
  disableFlag: string;
}
const initColumns: TableProps<DataType>['columns'] = [
  {
    title: '名称',
    dataIndex: 'datasetName',
    key: 'datasetName',
    width: 270,
    fixed: 'left',
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '创建人',
    dataIndex: 'createUser',
    key: 'createUser',
    width: 190,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 190,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '所属父类',
    dataIndex: 'parent',
    key: 'parent',
    width: 190,
    render: text => getToolTipText(text, { placeholder: '-' }),
  },
  {
    title: '状态',
    key: 'disableFlag',
    dataIndex: 'disableFlag',
    width: 80,
    render: (_, { disableFlag }) => <Tag color={statusMap[disableFlag]?.color}>{statusMap[disableFlag]?.text}</Tag>,
  },
  {
    title: '操作',
    width: 80,
    key: 'action',
  },
];

interface ITreeActionOptions {
  key: number;
  isLeaf: boolean;
  isSourced?: boolean;
  categoryName?: string;
  datasetName?: string;
  category?: string;
  description?: string;
}

const DatasetManagement: FC = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageHolder] = message.useMessage();

  const onSearch = (value: string) => {
    const search = value.trim();
    if (search === '') {
      setTreeData(cacheTreeData);
      setSelectedKeys(selectedKeys[0] ? selectedKeys : cacheTreeData.length > 0 ? [cacheTreeData[0].key] : []);
      setExpandAll(new Date().getTime());
      return;
    }
    // 创建一个 result 用于存放搜索结果
    const result: TreeDataNode[] = [];
    // 遍历 cacheTreeData， 如果节点的 strTitle 包含 search， 则保留该节点，并将其子节点的 strTitle 包含 search 的节点也保留，否则，只保留子节点的 strTitle 包含 search 的节点
    cacheTreeData.forEach((item: any) => {
      const children = item.children.filter((child: any) => child.strTitle.includes(search));
      if (item.strTitle.includes(search) || children.length > 0) {
        result.push({
          ...item,
          children,
        });
      }
    });
    setTreeData(result);
    setSelectedKeys(result.length > 0 ? [result[0].key] : []);
    setExpandAll(new Date().getTime());
  };
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [datesetActionType, setDatesetActionType] = useState<'create' | 'edit'>('create');
  const [currentDataset, setCurrentDataset] = useState({ datasetName: '', id: 0, category: '', description: '' });
  const [datasetActionVisable, setDatasetActionVisable] = useState(false);
  const openDatasetActionDrawer = (type: 'create' | 'edit') => {
    setDatesetActionType(type);
    setDatasetActionVisable(true);
  };
  const closeDatasetActionDrawer = (reload?: boolean) => {
    if (reload) {
      messageApi.success('操作成功');
      getTreeData({
        defaultSelectedKey: selectedKeys[0],
      });
    }
    setDatasetActionVisable(false);
  };
  const datasetActionRef = useRef<any>(null);
  const onDatasetActionSubmit = () => {
    datasetActionRef.current.onSubmit();
  };
  const onDatasetTreeAction = async (menuInfo: any, options: ITreeActionOptions) => {
    const { key, isLeaf, isSourced, ...rest } = options;
    menuInfo.domEvent.stopPropagation();
    if (menuInfo.key === 'edit') {
      if (isLeaf) {
        openDatasetActionDrawer('edit');
        setCurrentDataset({
          id: Number(key),
          datasetName: rest.datasetName ?? '',
          category: rest.category ?? '',
          description: rest.description ?? '',
        });
        return;
      }
      setCurrentCategory({
        categoryName: rest.categoryName ?? '',
        id: Number(key),
      });
      openDatasetCategoryDrawer('edit');
      return;
    }
    if (menuInfo.key === 'delete') {
      if (isLeaf) {
        const confirmed = await modal.confirm({
          title: '提示',
          content: '是否确认删除该数据集？',
          okText: '确定',
        });
        if (confirmed) {
          DatasetApi.deleteDataset({ id: Number(key) }).then(res => {
            if (!res) {
              return;
            }
            messageApi.success('删除数据集成功');
            getTreeData({
              defaultSelectedKey: selectedKeys[0],
            });
          });
        }
        return;
      }
      const confirmed = await modal.confirm({
        title: '提示',
        content: <span style={{ fontWeight: 'bold' }}>该分类被删除后，会影响上游应用该表的所有表及后续统计数据，确认删除吗？</span>,
        okText: '确定',
        icon: <CloseCircleFilled style={{ color: 'red' }} />,
      });
      if (confirmed) {
        DatasetApi.deleteDatasetCategory({ id: Number(key) }).then(res => {
          if (!res) {
            return;
          }
          messageApi.success('删除分类成功');
          getTreeData({
            defaultSelectedKey: selectedKeys[0],
          });
        });
      }
    }
    if (menuInfo.key === 'view') {
      if (!isSourced) {
        messageApi.error('请添加数据源后再查看');
        return;
      }
      // 查看,跳转展示数据源页面，如果未配置数据源，提示：请添加数据源后再查看
      DatasetApi.getDatasetViewData({ id: key, }).then(res => {
        if (!res) {
          return;
        }
        navigate('/files/LuZhiBI/DatasetManagement/DatasetView', {
          state: {
            id: key,
            datasetName: rest.datasetName,
            data: res.data,
          },
        });
      });
      return;
    }
    if (menuInfo.key === 'addDataSource') {
      // 添加数据源
      navigate('/files/LuZhiBI/DatasetManagement/DataSourceAdd', {
        state: { id: key },
      });
    }
    if (menuInfo.key === 'enable' || menuInfo.key === 'disable') {
      // 启用禁用
      const status = menuInfo.key === 'enable' ? '0' : '1';
      DatasetApi.updateDatasetStatus({ id: Number(key), disableFlag: status }).then(res => {
        if (!res) {
          return;
        }
        messageApi.success('操作成功');
        getTreeData({
          defaultSelectedKey: selectedKeys[0],
        });
      });
    }
  };

  const [datasetCategoryType, setDatasetCategoryType] = useState<'create' | 'edit'>('create');
  const [currentCategory, setCurrentCategory] = useState<{ categoryName: string; id: number }>({ categoryName: '', id: 0 });
  const [datasetcategoryVisable, setDatasetcategoryVisable] = useState(false);
  const openDatasetCategoryDrawer = (type: 'create' | 'edit') => {
    setDatasetCategoryType(type);
    setDatasetcategoryVisable(true);
  };
  const closeDatasetCategoryDrawer = (reload?: boolean) => {
    if (reload) {
      messageApi.success('操作成功');
      getTreeData({
        defaultSelectedKey: selectedKeys[0],
      });
    }
    setDatasetcategoryVisable(false);
  };
  const datasetCategoryRef = useRef<any>(null);
  const onDatasetCategorySubmit = () => {
    datasetCategoryRef.current.onSubmit(datasetActionRef.current?.getDatasetCategorySelectList ?? (() => { }));
  };

  const TitleRender = (props: { title: string; nodeKey: number; isLeaf: boolean; category?: string; description?: string }) => {
    const { title, nodeKey, isLeaf, category = '', description = '' } = props;
    return (
      <Flex className={styles.treeNodeBox} style={{ cursor: isLeaf ? 'default' : 'pointer' }}>
        <span className="ant-tree-iconEle ant-tree-icon__customize">
          {isLeaf ? <FolderOutlined /> : <FolderOpenOutlined />}
        </span>
        <span className="ant-tree-title">{getToolTipText(title)}</span>
        <Dropdown
          trigger={['click']}
          menu={{
            items: leafItems,
            onClick: (menuInfo) => {
              onDatasetTreeAction(menuInfo, {
                datasetName: title,
                categoryName: title,
                category,
                description,
                key: nodeKey,
                isLeaf,
              });
            },
          }}
        >
          <DashOutlined onClick={(e) => { e.stopPropagation(); }} className={styles.DashOutlinedIcon} />
        </Dropdown>
      </Flex>
    );
  };
  const [treeData, setTreeData] = useState<TreeDataNode[]>([]);
  const [cacheTreeData, setCacheTreeData] = useState<TreeDataNode[]>([]); // 缓存数据
  const [expandAll, setExpandAll] = useState(new Date().getTime());
  const onSelectTree: TreeProps['onSelect'] = (target, info) => {
    if (info.node?.isLeaf) {
      return;
    }
    if (target.length === 0) {
      return;
    }
    navigate(`?curCategory=${target[0]}`);
  };
  const getTreeData = (options?: { search?: string, defaultSelectedKey?: React.Key }) => {
    const { search = '', defaultSelectedKey = 'first' } = options ?? {};
    const setDataNull = () => {
      setTreeData([]);
      setSelectedKeys([]);
    };
    const params = {
      search: search,
    };
    DatasetApi.getDatasetCategoryList(params).then((res) => {
      if (!res) {
        setDataNull();
        return;
      }
      // 添加 uid 字段，用于唯一标识, 从 0 开始递增（因为设计只有两层因此并不需要递归）
      let uid = 0;
      res.data.forEach((item: any) => {
        item.uid = `${uid}`;
        uid++;
        item.datasets.forEach((child: any) => {
          child.uid = `${uid}`;
          uid++;
        });
      });
      const result = res.data.map((item: any) => {
        return {
          ...item,
          title: <TitleRender isLeaf={false} title={item.categoryName} nodeKey={item.id} />,
          strTitle: item.categoryName,
          key: item.uid,
          children: item.datasets.map((child: any) => {
            return {
              ...child,
              title: <TitleRender isLeaf title={child.dsName} category={child.categoryName} description={child.remark} nodeKey={child.id} />,
              strTitle: child.dsName,
              key: child.uid,
              isLeaf: true,
              selectable: false,
              parentUid: item.uid,
            };
          }),
        };
      })
      setTreeData(result);
      setCacheTreeData(result);
      if (defaultSelectedKey === 'first') {
        navigate(`?curCategory=${result[0].key}`);
      } else {
        // 判断 result 中是否存在 defaultSelectedKey, 可能后端返回的数据中没有这个分类
        const defaultSelectedKeyNode = result.find((item: any) => item.key === defaultSelectedKey);
        if (!defaultSelectedKeyNode) {
          navigate(`?curCategory=${result[0].key}`);
          return;
        }
        setSelectedKeys([defaultSelectedKeyNode.key]);
      }
    }).catch(() => {
      setDataNull();
    });
  };

  const [columns, setColumns] = useState(initColumns);

  const tableDataSource = useMemo(() => {
    if (treeData.length === 0) {
      return [];
    }
    // 根据选中的分类id，获取对应的数据集
    const selectedKey = selectedKeys[0];
    const selectedCategory = treeData.find(item => item.key === selectedKey);
    if (!selectedCategory) {
      return [];
    }
    const datasets = selectedCategory.children;
    if (!datasets) {
      return [];
    }
    return datasets.map((item: any) => {
      return {
        ...item,
        uid: item.key,
        id: item.id,
        datasetName: item.dsName,
        createUser: item.createUser,
        createTime: item.createTime,
        parent: item.categoryName,
        disableFlag: item.disableFlag,
      };
    });
  }, [treeData, selectedKeys]);

  useEffect(() => {
    setColumns(
      initColumns.map(column => {
        if (column.key === 'action') {
          return {
            ...column,
            render: (_: any, record: any) => (
              <Dropdown
                trigger={['click']}
                menu={{
                  items: (() => {
                    const result = JSON.parse(JSON.stringify(statusMap[record.disableFlag]?.items));
                    // 如果是禁用状态，且未配置数据源，则禁用启用按钮
                    if (record.disableFlag === '1' && record.isSourced === '0') {
                      const enableItem: any = result.find((item: any) => item?.key === 'enable');
                      if (enableItem) {
                        enableItem.disabled = true;
                      }
                    }
                    // 如果是禁用状态，且已经配置了数据源，则将添加数据源改为修改数据源
                    if (record.disableFlag === '1' && record.isSourced === '1') {
                      const addDataSourceItem: any = result.find((item: any) => item?.key === 'addDataSource');
                      if (addDataSourceItem) {
                        addDataSourceItem.label = '修改数据源';
                      }
                    }
                    return result;
                  })(),
                  onClick: menuInfo => {
                    onDatasetTreeAction(menuInfo, {
                      category: record.parent,
                      datasetName: record.datasetName,
                      description: record.remark,
                      key: record.id,
                      isLeaf: true,
                      isSourced: String(record.isSourced) === '1',
                    });
                  },
                }}
              >
                <DashOutlined />
              </Dropdown>
            ),
          };
        }
        return column;
      }),
    );
  }, [tableDataSource])

  useEffect(() => {
    const curCategory = searchParams.get('curCategory') || 'first';
    getTreeData({
      defaultSelectedKey: curCategory,
    });
  }, []);

  useEffect(() => {
    setSelectedKeys([searchParams.get('curCategory')!]);
  }, [searchParams.get('curCategory')]);
  return (
    <div className={styles.DatasetManagementList}>
      {contextHolder}
      {messageHolder}
      <div className={styles.list}>
        <Button
          onClick={() => {
            openDatasetActionDrawer('create');
          }}
          type="primary"
          block
          icon={<PlusOutlined />}
        >
          新建数据集
        </Button>
        <Search style={{ marginTop: 16 }} placeholder="请输入关键词搜索" onSearch={onSearch} />
        <Button onClick={() => { openDatasetCategoryDrawer('create') }} style={{ marginTop: 16 }} block icon={<PlusOutlined />}>
          新建分类
        </Button>
        {
          treeData.length > 0 && <Tree
            key={expandAll}
            style={{ marginTop: 16 }}
            defaultExpandAll
            onSelect={onSelectTree}
            selectedKeys={selectedKeys}
            treeData={treeData}
            showIcon
            blockNode
          />
        }
      </div>
      <div className={styles.table}>
        <div className={styles.titleBox}>
          <span className={styles.title}>数据集管理</span>
        </div>
        <div style={{ padding: 24 }}>
          <Table rowKey='uid' columns={columns} dataSource={tableDataSource} scroll={{ x: 1000 }} />
        </div>
      </div>
      <Drawer
        title={datasetActionNameMap[datesetActionType]}
        onClose={() => { closeDatasetActionDrawer(false) }}
        open={datasetActionVisable}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={() => { closeDatasetActionDrawer(false) }}>取消</Button>
            <Button type="primary" htmlType="submit" onClick={onDatasetActionSubmit}>
              {datesetActionType === 'create' ? '新建' : '保存'}
            </Button>
          </Space>
        }
      >
        <DatasetAction
          ref={datasetActionRef}
          type={datesetActionType}
          defaultData={currentDataset}
          onEmitOpenCreateCategory={() => { openDatasetCategoryDrawer('create') }}
          onEmitCloseDrawer={() => { closeDatasetActionDrawer(true) }}
        />
      </Drawer>
      <Drawer
        title={datasetCategoryNameMap[datasetCategoryType]}
        onClose={() => { closeDatasetCategoryDrawer(false) }}
        open={datasetcategoryVisable}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={() => { closeDatasetCategoryDrawer(false) }}>取消</Button>
            <Button type="primary" htmlType="submit" onClick={onDatasetCategorySubmit}>
              {datasetCategoryType === 'create' ? '新建' : '保存'}
            </Button>
          </Space>
        }
      >
        <Datasetcategory
          ref={datasetCategoryRef}
          type={datasetCategoryType}
          defaultData={currentCategory}
          onEmitCloseDrawer={() => { closeDatasetCategoryDrawer(true) }}
        />
      </Drawer>
    </div>
  );
};

export default DatasetManagement;
